import { Container, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FrequentQuestion } from '../../model/FrequentQuestion';
import AlertService from '../../services/AlertService';
import HelpCenterService from '../../services/HelpCenterService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import AppTheme from '../../utils/AppTheme';
import { Debouncer } from '../../utils/Debouncer';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import AppHeader from '../commons/AppHeader';
import AppMenuList, { Menu } from '../commons/AppMenuList';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import { SearchBar } from '../commons/SearchBar';
import { AppLayout } from '../core/AppLayout';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

const logger = new Logger('HelpCenter');

const HelpCenter = () => {
  const navigate = useNavigate();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [frequentQuestions, setFrequentQuestions] = useState<FrequentQuestion[]>([]);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    getHelpCenterItems();
  }, []);

  useEffect(() => {
    if (search !== '') {
      setRequestStatus(RequestStatus.LOADING);
      Debouncer.debounce('searchContent', getHelpCenterItems, 700);
    } else {
      getHelpCenterItems();
    }
  }, [search]);

  const getHelpCenterItems = async () => {
    setRequestStatus(RequestStatus.LOADING);

    const response = await HelpCenterService.getAllFrequentQuestions(0, 30, search);

    response
      .onSuccess((response) => {
        setFrequentQuestions(response.getContent().content);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to fetch FAQs. ', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getMenus = () => {
    let menus: Menu[] = [];

    frequentQuestions.forEach((item) => {
      menus.push({
        text: item.title,
        action: () => appNavigate(navigate, AppRoutes.HELP_CENTER_DETAIL, { id: item.externalId! }),
      });
    });

    return menus;
  };

  const getContent = (): JSX.Element => {
    return (
      <Container sx={muiStyles.container}>
        <SearchBar
          placeholder={'Type to search our FAQs'}
          value={search}
          onChange={(newValue) => setSearch(newValue)}
          buttonDisabled
        />
        <div style={{ marginBottom: '0.625em', marginTop: '2.5em' }}>
          <AppTypography type={PDLTypography.largeHeading} children={'FAQs'} />
        </div>

        {requestStatus !== RequestStatus.SUCCESS ? (
          <div style={{ height: '50%' }}>
            <RequestStatusFeedBack status={requestStatus} onRetry={getHelpCenterItems} />
          </div>
        ) : (
          <AppMenuList menus={getMenus()} />
        )}
      </Container>
    );
  };

  return (
    <AppLayout header={() => <AppHeader content={'Help center'} />} content={() => getContent()} />
  );
};

export default HelpCenter;

const muiStyles = {
  container: {
    minHeight: '100vh',
    backgroundColor: AppTheme.colors.white_EEEEEC,
    paddingTop: '2.5em',
  } as SxProps,
};
