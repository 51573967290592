import AppHeader from '../commons/AppHeader';
import { CircularProgress, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { SearchBar } from '../commons/SearchBar';
import { AppLayout } from '../core/AppLayout';
import { useState, useEffect } from 'react';
import { Content } from '../../model/Content';
import ContentService from '../../services/ContentService';
import Logger from '../../external/pdl-common/utils/Logger';
import { ContentCard } from '../ContentLibrary/ContentCard';
import { FluidContainer } from '../commons/FluidContainer';
import AppTheme from '../../utils/AppTheme';
import { useNavigate } from 'react-router-dom';
import { SxStyles } from '../../model/utils/SxStyles';
import { RequestStatus } from '../../utils/RequestStatus';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { Debouncer } from '../../utils/Debouncer';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { makeStyles } from '@mui/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlertService from '../../services/AlertService';

const logger = new Logger('ContentLibrary');
const PAGE_SIZE = 20;

export const ContentLibrary = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [content, setContent] = useState<Content[]>([]);
  const [search, setSearch] = useState<string>('');
  const [pageData, setPageData] = useState<{ number: number; totalPages: number }>({
    number: 0,
    totalPages: 0,
  });

  useEffect(() => {
    document.body.style.backgroundColor = AppTheme.colors.white_EEEEEC;
    return () => {
      document.body.style.backgroundColor = AppTheme.colors.white_FFFFFF;
    };
  }, []);

  useEffect(() => {
    if (search !== '') {
      setRequestStatus(RequestStatus.LOADING);
      Debouncer.debounce('searchContent', getContentLibrary, 700);
    } else {
      getContentLibrary();
    }
  }, [search]);

  const loadMoreContent = () => {
    Debouncer.debounce(
      'loadMoreContent',
      async () => {
        if (pageData.number < pageData.totalPages) {
          (await ContentService.getAllContent(pageData.number + 1, PAGE_SIZE, search))
            .onSuccess((response) => {
              setContent([...content, ...response.getContent().content]);
              setPageData({ ...pageData, number: pageData.number + 1 });
            })
            .onError((response) => {
              logger.error('Error fetching more content. ', response.getContent());
              AlertService.showSnackCustomError(response.getContent());
            });
        }
      },
      100
    );
  };

  const getContentLibrary = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await ContentService.getAllContent(0, PAGE_SIZE, search))
      .onSuccess((response) => {
        setContent(response.getContent().content);
        setPageData({
          number: response.getContent().number,
          totalPages: response.getContent().totalPages,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to get articles/tutorials', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getHeader = () => {
    return (
      <AppHeader
        content="Articles and tutorials"
        bottomElement={
          <Container
            sx={{
              my: breakpoint ? '0em' : '1.5em',
              pr: breakpoint ? '0em' : '1.5em',
              maxWidth: 'md',
            }}
          >
            <SearchBar
              placeholder={'Search articles and tutorials..'}
              value={search}
              onChange={(newValue) => setSearch(newValue)}
              buttonDisabled
            />
          </Container>
        }
      />
    );
  };

  const getBody = () => {
    return (
      <FluidContainer style={{ backgroundColor: AppTheme.colors.white_EEEEEC }}>
        {requestStatus === RequestStatus.LOADING ? (
          <div className={classes.loader} style={{ paddingTop: '5em' }}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Container maxWidth="md">
            <Grid container gap={3} sx={styles.container}>
              {/* Feedback is the list is empty */}
              {content.length === 0 && search !== '' && (
                <AppTypography
                  type={PDLTypography.smallHeading}
                  children={`No content found with title: ${search}`}
                />
              )}
              <InfiniteScroll
                dataLength={content.length}
                hasMore={pageData.number < pageData.totalPages}
                next={loadMoreContent}
                scrollThreshold={1}
                loader={
                  <div className={classes.loader}>
                    <CircularProgress color="inherit" />
                  </div>
                }
                className={classes.articlesList}
              >
                {content.map((c, index) => (
                  <ContentCard
                    key={`${c.externalId}-${index}`}
                    title={c.title}
                    onClick={() =>
                      appNavigate(navigate, AppRoutes.CONTENT_LIBRARY_DETAILS, {
                        externalId: c.externalId!,
                      })
                    }
                    imageUrl={c.imageUrl}
                    style={{ width: '100%', marginBottom: '1em' }}
                  />
                ))}
              </InfiniteScroll>
            </Grid>
          </Container>
        )}
      </FluidContainer>
    );
  };

  return <AppLayout header={getHeader} content={getBody} fromHome />;
};

const styles: SxStyles = {
  container: {
    py: '2.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const useStyles = makeStyles({
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: AppTheme.colors.persianBlue_2238CB,
    paddingTop: '1em',
  },
  articlesList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1em',
  },
});
