import FormControl from '@mui/material/FormControl/FormControl';
import { makeStyles } from '@mui/styles';
import { AppTextField } from '../commons/AppTextField';
import {useEffect, useState} from "react";
import {useForm} from "../../hooks/useForm";
import {RequestStatus} from "../../utils/RequestStatus";
import UserAdditionalInformation from "../commons/UserAdditionalInformation";
import {AdditionalPersonalInformation} from "../../external/pdl-common/model/AdditionalPersonalInformation";
import {AppTypography} from "../Typography/AppTypography";
import {PDLTypography} from "../Typography/PDLTypography";
import {AppFontFamily} from "../../utils/AppFontFamily";

interface Props {
    handleCustomChange: (name: string, value: string, isValid: boolean) => void;
    disabled?: boolean;
    phoneNumber: string;
    streetAddress: string;
    city: string;
    stateCode: string;
    zipCode: string;
    countryCode: string;
    setForm: (updatedForm: {}) => void;
    setValidations: (updatedValidations: {}) => void;
    editMode?: boolean;
    displayPhoneNumberField: boolean;
}

const userStyles = makeStyles({
    container: {},
    fullWidth: {
        width: '100%',
    },
    footerSections: {
        marginBottom: '1em',
    },
});

export const ReservationTemporaryAdditionalInformation = (props: Props) => {
    const classes = userStyles();

    return (
        <>
            <FormControl className={classes.fullWidth}>
                <div className={`${classes.container} ${classes.fullWidth}`}>
                    <div className={classes.footerSections}>
                        <AppTypography type={PDLTypography.smallHeading}>US Temporary Location</AppTypography>
                    </div>
                    <div>
                        <AppTypography type={PDLTypography.paragraph} customStyles={{ fontFamily: AppFontFamily.SHAPE_SEMI_BOLD}}>Please Note: The provision of a US temporary address is mandatory only for PDL CLUB members whose city of residence is outside the US.</AppTypography>
                    </div>
                    <UserAdditionalInformation
                        displayPhoneNumberField={props.displayPhoneNumberField}
                        handleCustomChange={props.handleCustomChange}
                        phoneNumber={props.phoneNumber}
                        streetAddress={props.streetAddress}
                        stateCode={props.stateCode}
                        city={props.city}
                        countryCode={props.countryCode}
                        zipCode={props.zipCode}
                        setForm={props.setForm}
                        setValidations={props.setValidations}
                        editMode
                        USOnly
                    />
                </div>
            </FormControl>
        </>
    );
};
