import { Container, Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppAssets } from '../../../../assets';
import { RenterReview } from '../../../../model/RenterReview';
import Reservation from '../../../../model/Reservation';
import reservationService from '../../../../services/ReservationService';
import reviewService from '../../../../services/ReviewService';
import { appNavigate, AppRoutes } from '../../../../utils/AppNavigation';
import { COLORS, IAppTheme } from '../../../../utils/AppTheme';
import Logger from '../../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../../utils/RequestStatus';
import StringUtils from '../../../../utils/StringUtils';
import AppHeader from '../../../commons/AppHeader';
import { ImageCarousel } from '../../../commons/ImageCarousel';
import RequestStatusFeedBack from '../../../commons/RequestStatusFeedback';
import SimpleButton from '../../../commons/SimpleButton';
import { AppLayout } from '../../../core/AppLayout';
import { AppTypography } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';
import { CallToActionWithIcon } from '../Common/CallToActionWithIcon';
import { RenterReviewSection } from '../Common/RenterReviewSection';
import { ReservationDetailsPerson } from '../Common/ReservationDetailsPerson';
import InboxService from '../../../../services/InboxService';
import AlertService from '../../../../services/AlertService';
import ChatUtils from '../../../../utils/ChatUtils';
import { SimpleCellText } from '../../../../external/pdl-common/components/SimpleCellText';
import { ReservationDetailsHeader } from '../../../../external/pdl-common/components/ReservationDetailsHeader';
import { ClaimInitiator } from '../../../../utils/TintUtils';
import InsuranceClaim from '../Common/InsuranceClaim';
import DateUtils from '../../../../external/pdl-common/utils/DateUtils';
import { useFeatureToggle } from '../../../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../../../external/pdl-common/utils/enums/Features';
import { CancelReservationBottomSheet } from '../../../commons/CancelReservationBottomSheet';
import { AlertDialog } from '../../../commons/AlertDialog';

const logger = new Logger('BikeReservationDetailsHost');
const PROTECTION_CASE_HOUR_LIMIT = 24;

interface Props {
  setCustomNavBar: (navBar?: () => JSX.Element) => void;
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  detailSection: {
    marginTop: '2em',
    marginBottom: '1em',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  title: {
    marginTop: '1.5em',
    marginBottom: '1em',
  },
  mapPinIcon: {
    '& path': {
      stroke: theme.colors.persianBlue_2238CB,
    },
  },
  navBar: {
    padding: '0.62em 0em 69px 0em',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: COLORS.gray_E3E5E4,
  },
}));

export function BikeReservationDetailsHost(props: Props) {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const mounted = useRef(false);

  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [searchDialogModalOpen, setSearchDialogModalOpen] = useState<boolean>(false);
  const [cancelReservationModalOpen, setCancelReservationModalOpen] = useState<boolean>(false);
  const [isInsuranceFeatureEnabled] = useFeatureToggle(Features.INSURANCE);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [requestRenterReviewStatus, setRequestRenterReviewStatus] = useState<RequestStatus>(
    RequestStatus.LOADING
  );
  const [renterReview, setRenterReview] = useState<RenterReview>();

  useEffect(() => {
    mounted.current = true;
    load();
    return () => {
      props.setCustomNavBar(undefined);
      mounted.current = false;
    };
  }, []);

  const load = async () => {
    const reservationExternalId = params['externalId'];
    if (!reservationExternalId) {
      appNavigate(navigate, AppRoutes.MAIN, null);
      return;
    }

    try {
      setRequestStatus(RequestStatus.LOADING);
      const reservation = (
        await reservationService.getSemiPublicReservation(reservationExternalId)
      ).getContent();

      setReservation(reservation);

      // TODO: change checks. We need to check many more conditions to determine the button to show
      if (mounted.current && reservation.pickedUpAt && !reservation.droppedOffAt) {
        props.setCustomNavBar(() => <DropOffButton reservation={reservation} />);
      }
      setRequestStatus(RequestStatus.SUCCESS);

      loadRenterReview(reservation);
      loadHostReview(reservation);
    } catch (error) {
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const canStartProtectionCase = (reservation: Reservation): boolean => {
    if ( reservation.pickedUpAt != undefined && reservation.hostInsurance != undefined) {
      return (reservation.droppedOffAt == undefined) || (DateUtils.getHoursBetweenDates(new Date(reservation.droppedOffAt), new Date()) <= PROTECTION_CASE_HOUR_LIMIT)
    }

    return false;
  }

  const loadHostReview = async (reservation: Reservation) => {
    try {
      setRequestStatus(RequestStatus.LOADING);
      if (reservation.droppedOffAt) {
        await reviewService.getHostReviewForReservation(reservation.externalId!);
      }
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      if (error.response.status == 404) {
        if (mounted.current) {
          props.setCustomNavBar(LeaveReviewButton);
        }
        setRequestStatus(RequestStatus.SUCCESS);
      } else {
        logger.error('Error fetching host review', error);
        setRequestStatus(RequestStatus.ERROR);
      }
    }
  };

  const loadRenterReview = async (reservation: Reservation) => {
    try {
      setRequestRenterReviewStatus(RequestStatus.LOADING);
      if (reservation.droppedOffAt) {
        const response = await reviewService.getRenterReviewForReservation(reservation.externalId!);
        setRenterReview(response.getContent());
      }
      setRequestRenterReviewStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      if (error.response.status == 404) {
        setRequestRenterReviewStatus(RequestStatus.SUCCESS);
      } else {
        setRequestRenterReviewStatus(RequestStatus.ERROR);
      }
    }
  };

  const DropOffButton = (props: { reservation: Reservation }) => (
    <div className={classes.navBar}>
      <Container>
        <SimpleButton
          onClick={() =>
            appNavigate(navigate, AppRoutes.RESERVATION_DROP_OFF, {
              externalId: props.reservation.externalId!,
            })
          }
        >
          Start Drop-off
        </SimpleButton>
      </Container>
    </div>
  );

  const LeaveReviewButton = () => (
    <div className={classes.navBar}>
      <Container>
        <SimpleButton
          onClick={() =>
            appNavigate(navigate, AppRoutes.HOST_RESERVATION_LEAVE_REVIEW, {
              externalId: params['externalId']!,
            })
          }
        >
          Leave A Review
        </SimpleButton>
      </Container>
    </div>
  );

  const navigateToRenterChatChannel = async () => {
    const response = await InboxService.getByReservationExternalId(reservation?.externalId!);
    const { user: receiver } = ChatUtils.getReceiverMember(response.getContent().channel);
    response
      .onSuccess((response) => {
        appNavigate(
          navigate,
          AppRoutes.CHAT,
          { externalId: response.getContent().externalId || '' },
          { state: { pdlChatChannel: response.getContent(), receiver } }
        );
      })
      .onError((response) => {
        AlertService.showSnackCustomError(response.getContent());
      });
  };

  const checkReservationCancellation = (): boolean => {
    // We check if today is not greater than the reservation end date
    // and the bike has not been picked up
    return (
      !DateUtils.greaterThanTodayInTimezone(
        new Date(reservation?.endDate!),
        reservation?.bike.location.timezoneId!
      ) && reservation?.pickedUpAt === undefined
    );
  };
  const cancelReservation = () => {
    appNavigate(
      navigate,
      AppRoutes.HOST_RESERVATION_CANCEL,
      {
        externalId: reservation!.externalId!,
      },
      { state: reservation }
    )
  }

  return (
    <AppLayout
      fromHome
      header={() => <AppHeader
        content="Reservation details"
        onBack={() => {
          if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
          } else {
            appNavigate(navigate, AppRoutes.HOST, null)
          }
        }}
      />}
      content={() => {
        if (requestStatus !== RequestStatus.SUCCESS) {
          return (
            <div style={{ height: '80vh' }}>
              <RequestStatusFeedBack status={requestStatus} onRetry={load} />
            </div>
          );
        }

        return (
          <>
            <Container
              className={classes.container}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {/** Bike image with title */}
              <ReservationDetailsHeader
                imageSrc={
                  reservation && reservation.bike.images && reservation.bike.images.length > 0
                    ? reservation!.bike.images[0].url
                    : ''
                }
                title={reservation!.bike.title}
              />

              {/** Listing */}
              <Grid container columnSpacing={2} columns={2} sx={{ mt: '1em' }}>
                <Grid item xs={1}>
                  <SimpleCellText
                    label="Rental Dates"
                    valueProps={{
                      type: PDLTypography.smallParagraph,
                      customStyles: { textDecoration: 'underline' },
                    }}
                    value={DateUtils.formattedRangeDates(
                      new Date(reservation!.startDate),
                      new Date(reservation!.endDate),
                      true
                    )}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />

              {/** Renter Details */}
              <ReservationDetailsPerson
                title="Renter details"
                imageSrc={reservation!.renter.user.avatarUrl}
                imageSmallTitle="Rented by"
                name={StringUtils.formatShortUserName(reservation!.renter.user)}
                callToActionText="Message Renter"
                callToActionIcon={<AppAssets.ChatBubble />}
                onClick={navigateToRenterChatChannel}
              />

              <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />

              {/** Payment info */}
              <AppTypography type={PDLTypography.mediumHeading}>Payment info</AppTypography>
              <Grid container columnSpacing={2} columns={2} sx={{ mt: '1em' }}>
                <Grid item xs={1}>
                  <SimpleCellText
                    label="Total Paid"
                    value={`$${Math.round(reservation!.totalPrice * 100) / 100}`}
                  />
                </Grid>
                <Grid item xs={1}>
                  <SimpleCellText
                    label="Paid on"
                    value={DateUtils.stringToShortDate(reservation?.createdAt!)}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />

              {/** Review */}
              {/** TODO: conditional render if the user did the review */}
              <RenterReviewSection
                title="Review"
                requestRenterReviewStatus={requestRenterReviewStatus}
                renterReview={renterReview}
                onRetry={() => loadRenterReview(reservation!)}
              />

              {/** Rental images */}
              {/** TODO: conditional render if the user did the pick up */}
              {reservation?.droppedOffAt && (
                <>
                  <AppTypography type={PDLTypography.mediumHeading}>Rental images</AppTypography>
                  <div style={{ marginTop: '1em' }}>
                    <ImageCarousel images={reservation!.dropOffImages} />
                  </div>
                  <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                </>
              )}

              {/** Support */}
              <AppTypography type={PDLTypography.mediumHeading}>Support</AppTypography>

              {checkReservationCancellation() && (
                <CallToActionWithIcon
                  text={'Cancel Trip'}
                  asset={<AppAssets.CrossCircle />}
                  style={{ marginTop: '1.5em' }}
                  onClick={() => setCancelReservationModalOpen(true)}
                />
              )}

              {/* Insurance claim */}
              {isInsuranceFeatureEnabled && reservation && canStartProtectionCase(reservation) && (
                <InsuranceClaim reservation={reservation} claimInitiator={ClaimInitiator.HOST} />
              )}

              <CallToActionWithIcon
                text={'Report an issue'}
                asset={<AppAssets.FlagIcon />}
                style={{ marginTop: '1.5em', marginBottom: '5em' }}
                onClick={() => setSearchDialogModalOpen(true)}
              />

              <AlertDialog
                  title="Report an Issue"
                  content={"Report an issue if you find any small changes or disagreements in relation to what stated in the original reservation. Reporting an issue implies that you accept that, despite the finding, you will move forward with your business."}
                  open={searchDialogModalOpen}
                  confirmLabel={"Continue"}
                  onConfirm={() => {
                    setSearchDialogModalOpen(false);
                    appNavigate(navigate, AppRoutes.REPORT_ISSUE, null, { state: reservation })
                  }}
                  onCancel={() => {
                    setSearchDialogModalOpen(false);
                  }}
              />
            </Container>
            <CancelReservationBottomSheet
              fromHost
              reservation={reservation}
              open={cancelReservationModalOpen}
              onClose={() => setCancelReservationModalOpen(false)}
              onCancelReservation={cancelReservation}
              refundAmount={0}
            />
          </>
        );
      }}
    />
  );
}
