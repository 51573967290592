import { useMemo, useRef, useState } from 'react';
import { Marker as LeafletMarker } from 'react-leaflet';
import { AppAssets } from '../../../assets';
import { MapCoordinates } from '../../../model/utils/MapCoordinates';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { CustomMarker } from './CustomMarker';
import { Box } from '@mui/material';
import AppTheme from '../../../utils/AppTheme';
import { SxStyles } from '../../../model/utils/SxStyles';
import { Marker as GoogleMarker, OverlayView } from '@react-google-maps/api';
import environment from '../../../environment.json';
import { useFeatureToggle } from '../../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../../external/pdl-common/utils/enums/Features';

interface Props {
  lat: number;
  lng: number;
  value?: string;
  isActive?: boolean;
  draggable: boolean;
  type: 'IconText' | 'OnlyIcon';
  onClick?: () => void;
  onDrag?: (lat: number, lng: number) => void;
  isAvailable?: boolean;
  multiPoint?: string;
  hasDiscount?: boolean;
}

export const MapMarker = (props: Props) => {
  const [position, setPosition] = useState<MapCoordinates>({ lat: props.lat, lng: props.lng });
  const [isGoogleMapAvailable] = useFeatureToggle(Features.IS_GOOGLE_MAP_AVAILABLE);
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;
        if (marker != null) {
          // @ts-ignore
          setPosition(marker.getLatLng());
          props.onDrag && props.onDrag(marker.getLatLng().lat, marker.getLatLng().lng);
        }
      },
      click: (e: any) => {
        props.onClick && props.onClick();
      },
    }),
    []
  );

  const leafletRenderIcon = () => {
    if (props.type === 'IconText') {
      return L.divIcon({
        html: ReactDOMServer.renderToString(
          <CustomMarker text={props.multiPoint ? props.multiPoint : props.value!} isActive={props.isActive} isAvailable={props.isAvailable}
            hasDiscount={props.hasDiscount} />
        ),
      });
    } else {
      return L.divIcon({
        html: ReactDOMServer.renderToString(
          <Box sx={styles.mapPinIcon}>
            <AppAssets.MapPinIcon />
          </Box>
        ),
      });
    }
  };

  if (isGoogleMapAvailable == undefined) {
    return <></>;
  }

  if (isGoogleMapAvailable && environment.env != 'dev') {
    if (props.type === 'IconText') {
      return (
        <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          <CustomMarker text={props.multiPoint ? props.multiPoint : props.value!} isActive={props.isActive} onClick={props.onClick} isAvailable={props.isAvailable}
            hasDiscount={props.hasDiscount} />
        </OverlayView>
      );
    }

    return (
      <GoogleMarker
        position={position}
        draggable={props.draggable}
        onDragEnd={(marker) =>
          props.onDrag && props.onDrag(marker.latLng!.lat(), marker.latLng!.lng())
        }
      />
    );
  } else {
    return (
      <LeafletMarker
        draggable={props.draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
        icon={leafletRenderIcon()}
      />
    );
  }
};

const styles: SxStyles = {
  mapPinIcon: {
    backgroundColor: AppTheme.colors.persianBlue_2238CB,
    width: 39,
    height: 39,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  },
};
