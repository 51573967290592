import { useEffect, useState } from 'react';
import { Features } from '../utils/enums/Features';
import { ToggleItem } from '../model/ToggleItem';
import FeatureToggleService from '../service/FeatureToggle';
import Logger from '../utils/Logger';

const logger = new Logger('useFeatureToggle');

export function useFeatureToggle(feature: Features): [boolean | undefined] {
  const [featureToggle, setFeatureToggle] = useState<ToggleItem | undefined>(undefined);

  useEffect(() => {
    getFeatureToggleItem();
  }, []);

  const getFeatureToggleItem = async () => {
    try {
      const response = await FeatureToggleService.getFeatureToggleItem(feature);
      setFeatureToggle(response.getContent());
    } catch (e) {
      logger.error(`Error trying to fetch ${feature} toggle`, e);
    }
  };

  return [featureToggle?.enabled];
}
