import { Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FullUser } from '../../external/pdl-common/model/FullUser';
import { RenterReview } from '../../model/RenterReview';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import StringUtils from '../../utils/StringUtils';
import AppHeader from '../commons/AppHeader';
import { AppLayout } from '../core/AppLayout';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { Debouncer } from '../../utils/Debouncer';
import { AppSimpleList } from '../commons/AppSimpleList';
import ReviewService from '../../services/ReviewService';
import AlertService from '../../services/AlertService';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import StarsAndRides from '../commons/StarsAndRides';
import AccountRatingComment from '../../external/pdl-common/components/commons/Ratings/AccountRatingComment';

const logger = new Logger('AccountRatings');
const PAGE_SIZE = 5;

const AccountRatings = () => {
  const location = useLocation().state as any;
  const user = location?.user as FullUser | undefined;
  const classes = useStyles();

  const [review, setReview] = useState<RenterReview[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [pageData, setPageData] = useState<{ number: number; totalPages: number }>({
    number: 0,
    totalPages: 0,
  });

  useEffect(() => {
    getReviews();
  }, []);

  const getReviews = async () => {
    setRequestStatus(RequestStatus.LOADING);

    const response = await ReviewService.getReviewsAsRenter(0, PAGE_SIZE);

    response
      .onSuccess((response) => {
        const content = response.getContent();
        setReview(content.content);
        setPageData({
          number: content.number,
          totalPages: content.totalPages,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to fetch reviews', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const loadMoreRatings = async () => {
    Debouncer.debounce(
      'loadMoreRatings',
      async () => {
        if (pageData.number + 1 >= pageData.totalPages) {
          return;
        }

        const response = await ReviewService.getReviewsAsRenter(pageData.number + 1, PAGE_SIZE);

        response
          .onSuccess((response) => {
            setReview([...review, ...response.getContent().content]);
            setPageData({ ...pageData, number: pageData.number + 1 });
          })
          .onError((response) => AlertService.showSnackCustomError(response.getContent()));
      },
      100
    );
  };

  const renderItem = (rating: RenterReview, index: number) => {
    return (
      <AccountRatingComment rating={rating} key={`account-rating-comment-${rating.externalId}`} />
    );
  };

  const getContent = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <RequestStatusFeedBack onRetry={getReviews} status={requestStatus} />
        </div>
      );
    }
    return user && review.length > 0 ? (
      <Container>
        <StarsAndRides user={user} />

        <Divider sx={{ mb: 2.5 }} />

        <AppTypography type={PDLTypography.largeHeading} children={'Renter ratings and reviews'} />

        <div style={{ flex: 1, display: 'flex' }}>
          <div style={{ flex: 1, position: 'relative' }}>
            <AppSimpleList
              className={classes.list}
              data={review}
              loadMore={loadMoreRatings}
              renderItem={renderItem}
            />
          </div>
        </div>
      </Container>
    ) : (
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <p style={{ textAlign: 'center' }}>You don't have reviews yet.</p>
      </div>
    );
  };

  return (
    <>
      <AppLayout
        header={() => (
          <AppHeader
            content={user ? StringUtils.formatShortUserName(user.user) : 'Account ratings'}
          />
        )}
        content={() => getContent()}
      />
    </>
  );
};

export default AccountRatings;

const useStyles = makeStyles({
  userName: {
    margin: 0,
    marginTop: '0.625em',
    marginBottom: '0.313em',
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1.875em',
    paddingBottom: '1.875em',
  },
  userInfoTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  verifiedContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.938em',
  },
  verifiedIcon: {
    marginRight: '0.625em',
    height: '0.938em',
    width: '0.938em',
  },
  list: {
    position: 'relative',
    width: '100%',
    overflow: 'scroll',
    height: '95%',
  },
});
