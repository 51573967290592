import { Box, Grid } from '@mui/material';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

interface Props {
  title: string;
  description: string;
  icon: any; // TODO: Type
}

export const FeatureItem = (props: Props) => (
  <Grid item xl={5} lg={6} sm={6}>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ height: 35 }}>{props.icon}</Box>

      <AppTypography type={PDLTypography.largeHeading} children={props.title} />
      <AppTypography type={PDLTypography.paragraph} children={props.description} />
    </Box>
  </Grid>
);
