import { Box, SxProps } from '@mui/system';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

export interface TextWithIconProps {
  text: string;
  icon: JSX.Element;
  onClick?: (data: string) => void;
  containerSx?: SxProps;
}

const muiStyle = {
  boxStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '80vw',
    fontFamily: AppFontFamily.SHAPE_MEDIUM,

    marginLeft: '1.6em',
    marginBottom: '1em',
    marginTop: '1em',
  } as SxProps,
  text: {
    marginLeft: '1em',
    fontFamily: AppFontFamily.SHAPE_MEDIUM,
    fontSize: '0.863em',
    maxWidth: '80%',
  },
};

export default function TextWithIcon(props: TextWithIconProps) {
  const containerSxProps = { ...muiStyle.boxStyle, ...props.containerSx };

  return (
    <Box onClick={() => props.onClick!(props.text)} sx={containerSxProps}>
      {props.icon}
      <AppTypography type={PDLTypography.smallParagraph} customStyles={muiStyle.text}>
        {props.text}
      </AppTypography>
    </Box>
  );
}
