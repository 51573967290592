import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';

interface Props {
  text: string;
  asset: JSX.Element;
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const useStyles = makeStyles({
  spacing: {
    marginLeft: '0.3em',
  },
  container: {
    display: 'flex',
  },
});

export const CallToActionWithIcon: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const handleClick = props.onClick && !props.disabled ? props.onClick : () => null;

  return (
    <Grid className={classes.container} style={props.style}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          userSelect: 'none',
          cursor: props.disabled ? 'not-allowed' : 'pointer',
          alignItems: 'center',
        }}
      >
        {props.asset}

        <div onClick={() => handleClick()} className={classes.spacing}>
          <AppTypography type={PDLTypography.callToAction}>{props.text}</AppTypography>
        </div>
      </div>
    </Grid>
  );
};
