import HostBikeSecondStep from '../HostBikeSecondStep';
import Logger from '../../../../external/pdl-common/utils/Logger';
import BikeService from '../../../../services/BikeService';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Bike } from '../../../../external/pdl-common/model/Bike';
import { BikeDetails } from '../../../../model/BikeDetails';
import { RequestStatus } from '../../../../utils/RequestStatus';
import RequestStatusFeedBack from '../../../commons/RequestStatusFeedback';
import { Box } from '@mui/material';
import FormOptions from '../../../../model/FormOptions';
import FormOptionsService from '../../../../services/FormOptionsService';
import AlertService from '../../../../services/AlertService';
import { EditionType } from '../../../../model/enums/EditionType';

const logger = new Logger('HostBikeEditDetails');

export default function HostBikeEditDetails() {
  const { externalId } = useParams();

  const [bike, setBike] = useState<Bike | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [saveChangesStatus, setSaveChangesStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [options, setOptions] = useState<FormOptions | undefined>(undefined);

  useEffect(() => {
    getBike();
    FormOptionsService.getFormOptions().then((res) => setOptions(res.getContent()));
  }, []);

  const getBike = async () => {
    if (externalId) {
      setRequestStatus(RequestStatus.LOADING);

      (await BikeService.getBike(externalId))
        .onSuccess((response) => {
          setBike(response.getContent());
          setRequestStatus(RequestStatus.SUCCESS);
        })
        .onError((response) => {
          logger.error('Error fetching bike detail', response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    }
  };

  const onSaveChanges = async (newDetails: BikeDetails) => {
    if (!externalId && !bike) {
      return;
    }

    setSaveChangesStatus(RequestStatus.LOADING);

    const updatedBike = newDetails as Bike;

    if (!options) {
      return;
    }

    updatedBike.images = newDetails.images.filter((img) => img.url !== '');

    // Determine if there are custom values
    const isCustomMake = !options.makes.find((o) => o === updatedBike.make);
    const isCustomColor = !options.colors.find((o) => o === updatedBike.color);
    const isCustomCondition = !options.conditions.find((o) => o === updatedBike.condition);

    // Updated custom values
    updatedBike.externalId = bike?.externalId;

    if (isCustomMake) {
      updatedBike.customMake = newDetails.make;
      updatedBike.make = '';
    } else {
      updatedBike.customMake = '';
    }

    if (isCustomColor) {
      updatedBike.customColor = newDetails.color;
      updatedBike.color = 'Other';
    } else {
      updatedBike.customColor = '';
    }

    if (isCustomCondition) {
      updatedBike.customCondition = newDetails.condition;
      updatedBike.condition = 'Other';
    } else {
      updatedBike.customCondition = '';
    }

    BikeService.updateActive(updatedBike, EditionType.BIKE_DETAIL)
      .then(() => {
        setRequestStatus(RequestStatus.SUCCESS);
        AlertService.showSuccessMessage('Bike details edited successfully');
      })
      .catch((error) => {
        if (error.response.data.message) {
          logger.error(error.response.data.message, error);
          AlertService.showSnackCustomError(error.response.data.message);
        } else {
          const err = 'Error updating the bike';
          logger.error(err, error);
          AlertService.showSnackCustomError(err);
        }
      });

    setSaveChangesStatus(RequestStatus.SUCCESS);
  };

  if (requestStatus !== RequestStatus.SUCCESS) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <RequestStatusFeedBack status={requestStatus} onRetry={getBike} />
      </Box>
    );
  }

  return bike ? (
    <>
      <HostBikeSecondStep
        editMode
        editingBike={bike}
        onSaveChanges={onSaveChanges}
        isLoading={saveChangesStatus == RequestStatus.LOADING}
      />
    </>
  ) : (
    <></>
  );
}
