import { Grid, Typography, Divider, Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';
import { GenericCard } from '../core/GenericCard';
import AppTheme from '../../utils/AppTheme';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { SxStyles } from '../../model/utils/SxStyles';
import { BikeRating } from './BikeRating';
import { BikeAttribute } from './BikeSize';
import Price from '../commons/Price';
import BikeService from '../../services/BikeService';
import Logger from '../../external/pdl-common/utils/Logger';
import { useState } from 'react';
import AlertService from '../../services/AlertService';
import { RequestStatus } from '../../utils/RequestStatus';
import TopIcons from '../../external/pdl-common/components/commons/TopIcons';
import DiscountIcon from '@mui/icons-material/Discount';

const logger = new Logger('BikeCard');

interface Props {
  externalId?: string;
  title: string;
  size?: string;
  type?: string;
  rating?: number;
  price?: number;
  totalPrice?: number;
  totalRides?: number;
  style?: CSSProperties;
  status?: string;
  imageUrl?: string;
  hideDivider?: boolean;
  hostedBy?: string;
  hostedDates?: string;
  onClick: () => void;
  rightIcon?: JSX.Element;
  rightIconsContainerStyles?: CSSProperties;
  favourite?: boolean;
  className?: string;
  notAvailable?: boolean;
  notDates?: boolean;
  discountIcon?: boolean;
  customPrice?: number;
}

export const BikeCard = (props: Props) => {
  const classes = useStyles();
  const [favorite, setFavorite] = useState(props.favourite);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);

  const handleOnClickFav = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeService.addToWishList(props.externalId!))
      .onSuccess((response) => {
        setFavorite(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to add bike to wishlist', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  return (
    <GenericCard
      {...props}
      imageHeight={200}
      className={`${classes.cardContainer} ${props.className}`}
      titleClassName={classes.cardTitle}
      contentClassName={classes.cardContent}
      chipText={props.status}
      notAvailable={props.notAvailable ? "Reserved - Check other dates" : undefined}
      rightIcons={
        props.rightIcon
          ? () => <>{props.rightIcon}</>
          : () => <TopIcons favourite={favorite} onClickFav={() => handleOnClickFav()} />
      }
      rightIconsContainerStyles={props.rightIconsContainerStyles}
      onClick={props.onClick}
    >
      <Grid container direction={'column'} justifyContent={'space-around'} mb={3}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          {props.size && <BikeAttribute label={'Size:'} value={props.size} />}
          {props.type && <BikeAttribute label={'Type:'} value={props.type} useDefaultTypography />}
        </div>

        {(props.hostedBy || props.hostedDates) && (
          <Grid container justifyContent={'space-between'} mt={'.7em'}>
            <Grid item xs={6}>
              <Box display={'flex'} flexDirection={'column'} textAlign={'left'} gap={0.5}>
                <Typography sx={styles.itemTitle}>Hosted by</Typography>
                <Typography sx={styles.itemContent}>{props.hostedBy}</Typography>
              </Box>
            </Grid>

            {!props.notDates && (
              <Grid item xs={5}>
                <Box display={'flex'} flexDirection={'column'} textAlign={'left'} gap={0.5}>
                  <Typography sx={styles.itemTitle}>Dates</Typography>
                  <Typography sx={styles.itemContent}>{props.hostedDates}</Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        )}

        {!props.hideDivider && <Divider variant="middle" sx={styles.divider} />}

        <Grid container justifyContent={'space-between'} mt={1}>
          <Grid>
            {(props.rating !== undefined || props.totalRides !== undefined) && (
              <Grid item>
                <BikeRating rating={props.rating!} totalRides={props.totalRides} />
              </Grid>
            )}
            {props.discountIcon && (
              <Grid item>
                <Tooltip title='Multi day discount' placement='bottom-end'>
                  <DiscountIcon color={'success'} />
                </Tooltip>
              </Grid>
            )}
          </Grid>
          {props.price && (
            <Grid item>
              <Price customPrice={props.customPrice} price={props.price} finalPrice={props.totalPrice} direction={'column'} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </GenericCard >
  );
};

const useStyles = makeStyles({
  cardContainer: {
    maxWidth: 300,
    minWidth: 300,
    borderRadius: '30px !important',
  },
  cardTitle: {
    color: AppTheme.colors.black_01000E,
    fontSize: '1.5em !important',
    fontFamily: AppFontFamily.SHAPE_BOLD + ' !important',
    textAlign: 'left',
    marginBottom: 8,
    lineHeight: '1.2em !important',
  },
  cardContent: {
    marginTop: 15,
  },
});

const styles: SxStyles = {
  rightIconsContainer: {
    mt: 2.5,
    mr: 6,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  itemTitle: {
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC + ' !important',
    fontSize: '0.85em',
  },
  itemContent: {
    fontSize: '0.75em',
    fontFamily: AppFontFamily.SHAPE_MEDIUM,
  },
  divider: {
    width: '100%',
    p: 0,
    m: 0,
  },
  price: {
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC + ' !important',
    fontSize: '1em',
  },
  totalPrice: {
    color: AppTheme.colors.black_01000E,
    fontSize: '0.75em',
    fontFamily: AppFontFamily.SHAPE + ' !important',
  },
};
