import AuthService from '../external/fox-typescript/services/AuthService';
import DateUtils from '../external/pdl-common/utils/DateUtils';
import { ChatChannel } from '../model/ChatChannel';
import { ChatChannelMembership } from '../model/ChatChannelMembership';
import Reservation from '../model/Reservation';

class ChatUtils {
  public getReceiverMember = (channel: ChatChannel): ChatChannelMembership => {
    let member: ChatChannelMembership | undefined = undefined;

    member = channel.members.find((m) => m.user.externalId !== AuthService.getExternalId());

    if (!member) {
      member = channel.lastMessage.sender;
    }

    return member;
  };

  public getLastAccessed = (members: ChatChannelMembership[]): Date | undefined => {
    const member = members.find((m) => {
      if (m.user.externalId === AuthService.getExternalId()) {
        return m;
      }
    });

    return member && member.lastAccessed ? new Date(member.lastAccessed) : undefined;
  };

  public replaceStringReservationDates = (message: string, rentalDates: string): string => {
    return message.replace('$DATE1 - $DATE2', rentalDates);
  };

  public replaceReservationDates = (message: string, reservation: Reservation): string => {
    const rentalDates = DateUtils.formattedRangeDates(
      new Date(reservation.startDate),
      new Date(reservation.endDate)
    );

    return this.replaceStringReservationDates(message, rentalDates);
  };
}

export default new ChatUtils();
