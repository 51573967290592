import { Box, Divider, Grid } from '@mui/material';
import BikePartDetailItem from './BikePartDetailItem';
import { Bike } from '../model/Bike';

interface Props {
  bike: Bike;
}

const BikePartDetailList = ({ bike }: Props) => {
  return (
    <>
      <Grid container display={'flex'} direction={'column'} gap={1} mb={5}>
        {bike.parts?.map((part, index) => (
          <Box key={`${bike.externalId}-${index}`}>
            <BikePartDetailItem
              key={`${bike.externalId}-${index}`}
              part={part}
            />
            {bike?.parts && bike.parts.length - 1 !== index && <Divider />}
          </Box>
        ))}
      </Grid>
    </>
  );
};

export default BikePartDetailList;
