import { CSSProperties, makeStyles } from '@mui/styles';

interface Props {
  style?: CSSProperties;
  className?: string;
}

export const FluidContainer: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.fluidContainer} ${props.className || ''}`} style={props.style}>
      {props.children}
    </div>
  );
};

const useStyles = makeStyles({
  fluidContainer: {
    maxWidth: '100vw',
  },
});
