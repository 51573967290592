import { AllTimeEarnings } from '../model/AllTimeEarnings';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import Reservation from '../model/Reservation';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import environment from '../environment.json';
import { MonthlyBreakdown } from '../model/utils/MonthlyBreakdown';

class EarningService extends GenericCrudService<Reservation> {
  async getAllTimeEarnings(): Promise<ApiResponse<AllTimeEarnings>> {
    return await HttpClient.get(`${this.basePath}/all-time`);
  }

  async getMonthlyBreakdown(): Promise<ApiResponse<MonthlyBreakdown[]>> {
    return await HttpClient.get(`${this.basePath}/monthly-breakdown`);
  }

  async getMonthlyBreakdownByBikeExternalId(
    externalId: string
  ): Promise<ApiResponse<MonthlyBreakdown[]>> {
    return await HttpClient.get(`${this.basePath}/bike/${externalId}`);
  }
}

export default new EarningService(`${environment.baseURL}/reservations/earnings`);
