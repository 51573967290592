export enum ChannelType {
  BIKE = 'bike',
  RESERVATION = 'reservation',
  REPORT_ISSUE = 'report_issue',
  REQUEST_SUPPORT = 'request_support',
  DIRECT_MESSAGE = 'direct_message',
}

export const isPDLTeamChannel = (channelType: string) => {
  return channelType == ChannelType.DIRECT_MESSAGE ||
    channelType == ChannelType.REPORT_ISSUE || channelType == ChannelType.REQUEST_SUPPORT;
}
