import { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, SxProps } from '@mui/material';
import { AppAssets } from '../../assets';
import { IAppTheme } from '../../utils/AppTheme';
import { makeStyles } from '@mui/styles';
import { AppTypography, AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { Close } from '@mui/icons-material';
import Logger from '../../external/pdl-common/utils/Logger';
import { AddPhotoFromCamera } from '../Camera/AddPhotoFromCamera';
import { RequestStatus } from '../../utils/RequestStatus';
import AppImage from '../../external/pdl-common/components/commons/AppImage';

export interface FileData {
  file: File;
  fileLocalUrl: string;
}

interface Props {
  label?: string;
  labelProps?: Omit<AppTypographyProps, 'type'>;
  data?: FileData;
  setData: (file: FileData | undefined) => void;
  onRemove: () => void;
}

const logger = new Logger('UploadImage');

export function FileSelection(props: Props) {
  const classes = useStyles();

  const inputFile = useRef<HTMLInputElement>(null);
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);

  const { customClasses: inputClasses, ...typographyProps } = props.labelProps || {};

  const handleOnChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    if (event.target.files?.length > 0) {
      if (event.target.files[0]) {
        props.setData({
          file: event.target.files[0],
          fileLocalUrl: URL.createObjectURL(event.target.files[0]),
        });
      }
    }
  };

  const CloseButton = (props: { sx: SxProps; onClick: () => void }) => (
    <Box className={classes.closeBtn} onClick={props.onClick} sx={props.sx}>
      <Close sx={{ color: 'white' }} fontSize="small" />
    </Box>
  );

  const FileButton = (props: { onClick: () => void; placeholder: string; icon: JSX.Element }) => {
    return (
      <Box onClick={props.onClick} className={classes.uploadImageContainer}>
        {props.icon}
        <AppTypography type={PDLTypography.smallParagraph} customClasses={classes.placeHolder}>
          {props.placeholder}
        </AppTypography>
        {requestStatus === RequestStatus.LOADING && (
          <CircularProgress color="inherit" size={30} sx={{ marginLeft: '1em' }} />
        )}
      </Box>
    );
  };

  return (
    <>
      {props.label && (
        <AppTypography
          type={PDLTypography.subHeading}
          customClasses={`${classes.inputLabel} ${inputClasses}`}
          {...typographyProps}
        >
          {props.label}
        </AppTypography>
      )}
      <Box>
        {props.data ? (
          <>
            <div className={classes.imageContainer}>
              <AppImage
                src={props.data.fileLocalUrl}
                alt="Preview"
                height={'100%'}
                width={'100%'}
                className={classes.image}
              />
              <CloseButton sx={{ top: '0.8em', right: '0.6em' }} onClick={props.onRemove} />
            </div>
          </>
        ) : (
          <>
            <FileButton
              onClick={() => setShowCamera(true)}
              placeholder="Take photo"
              icon={<AppAssets.Camera />}
            />

            <input
              type={'file'}
              accept={'image/*'}
              ref={inputFile}
              onChange={handleOnChangeImage}
              className={classes.hide}
            />

            <div style={{ height: '1rem' }} />

            <FileButton
              onClick={() => inputFile.current!.click()}
              placeholder="Select photo"
              icon={<AppAssets.Image />}
            />
          </>
        )}
      </Box>

      {/* Camera */}
      <AddPhotoFromCamera
        show={showCamera}
        collectionOfImages={[]}
        onClose={() => setShowCamera(false)}
        onImageUploaded={() => {}}
        handleSetImage={() => {}}
        callToService={async (file) => {
          props.setData({ file: file, fileLocalUrl: URL.createObjectURL(file) });
          return undefined;
        }}
      />
    </>
  );
}

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  closeBtn: {
    borderStyle: 'solid',
    borderColor: theme.colors.white_FFFFFF,
    borderWidth: 1,
    borderRadius: 100,
    backgroundColor: 'rgba(1, 0, 14, 0.45)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 2,
    cursor: 'pointer',
    zIndex: 5,
    position: 'absolute',
  },
  inputLabel: {
    marginBottom: '1.1em',
  },
  uploadImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.white_EEEEEC,
    border: `0.063em dashed ${theme.colors.gray_C9CBCA}`,
    boxSizing: 'border-box',
    borderRadius: '0.25em',
    height: '2.65em',
    padding: '0.625em 0.75em',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
  },
  hide: {
    display: 'none',
  },
  placeHolder: {
    color: theme.colors.gray_A8ACAD,
    marginLeft: 5,
  },
  image: {
    maxHeight: '800vh',
    objectFit: 'contain',
    backgroundColor: theme.colors.white_FFFFFF,
    borderRadius: '0.4em',
  },
  imageContainer: {
    position: 'relative',
    margin: '1em 0em',
  },
}));
