import { Box, Container, Divider } from '@mui/material';
import { SxStyles } from '../../model/utils/SxStyles';
import AppTheme from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppCheckbox } from './AppCheckbox';

interface Props {
  label: string;
  info: string;
  value: boolean;
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const CheckboxWithInfo = (props: Props) => {
  return (
    <Box sx={styles.container}>
      <Container>
        <AppCheckbox value={props.value} checked={props.checked} onClick={props.onClick} disabled={props.disabled}/>
        <AppTypography
          type={PDLTypography.subHeading}
          children={props.label}
          customStyles={{ marginLeft: '.7em' }}
        />
      </Container>

      <Divider sx={{ my: '.7em' }} />

      <Container>
        <AppTypography type={PDLTypography.smallParagraph} children={props.info} />
      </Container>
    </Box>
  );
};

const styles: SxStyles = {
  container: {
    backgroundColor: AppTheme.colors.white_FFFFFF,
    mb: '1em',
    py: '.7em',
    borderRadius: '0.25em',
  },
};
