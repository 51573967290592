import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RenterReview } from '../../model/RenterReview';
import BikeRatingService from '../../services/ReviewService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import AppHeader from '../commons/AppHeader';
import { AppSimpleList } from '../commons/AppSimpleList';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import { AppLayout } from '../core/AppLayout';
import BikeRatingComment from '../../external/pdl-common/components/commons/Ratings/BikeRatingComment';

const logger = new Logger('BikeRatings');
const PAGE_SIZE = 10;

const BikeRatings = () => {
  const { externalId } = useParams();
  const navigate = useNavigate();

  const [ratings, setRatings] = useState<RenterReview[]>([]);
  const [pageData, setPageData] = useState<{ number: number; totalPages: number } | undefined>();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);

  useEffect(() => {
    if (externalId) {
      getRatings();
    } else {
      appNavigate(navigate, AppRoutes.MAIN, null);
    }
  }, []);

  const getRatings = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeRatingService.getRenterReviewsForBike(externalId!, 0, PAGE_SIZE))
      .onSuccess((response) => {
        const content = response.getContent();
        setRatings(content.content);
        setPageData({
          number: content.number,
          totalPages: content.totalPages,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error fetching bike ratings', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const loadMoreRatings = async () => {
    if (pageData!.number + 1 >= pageData!.totalPages) {
      return;
    }

    (await BikeRatingService.getRenterReviewsForBike(externalId!, pageData!.number + 1, PAGE_SIZE))
      .onSuccess((response) => {
        setRatings([...ratings, ...response.getContent().content]);
        setPageData({ ...pageData!, number: pageData!.number + 1 });
      })
      .onError((response) => {
        logger.error('Error fetching bike reviews', response.getContent());
      });
  };

  const getContent = () => {
    if (requestStatus === RequestStatus.LOADING || requestStatus === RequestStatus.ERROR) {
      return (
        <div
          style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <RequestStatusFeedBack status={requestStatus} onRetry={getRatings} />
        </div>
      );
    }

    return (
      <div style={{ flex: 1, display: 'flex', padding: '1.875em 0em' }}>
        <div style={{ flex: 1, position: 'relative' }}>
          <AppSimpleList
            style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'scroll' }}
            data={ratings}
            loadMore={loadMoreRatings}
            renderItem={(rating, index) => {
              return (
                <div style={{ margin: '0em 1.875em' }}>
                  <BikeRatingComment rating={rating} key={`rating-${rating.externalId}`} />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content={'All ratings and reviews'} />}
      content={() => getContent()}
    />
  );
};

export default BikeRatings;
