import { useEffect } from 'react';
import Navbar from './Navbar';

interface Props {
  header: () => JSX.Element;
  content: () => JSX.Element;
  style?: React.CSSProperties;
  className?: string;
  fromHome?: boolean;
  noNavBar?: boolean;
}

export const AppLayout = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (props.style || props.className) {
    return (
      <div>
        <div style={{...props.style, paddingBottom: props.fromHome ? 0 : 59 }} className={props.className}>
          {props.header()}
          {props.content()}
        </div>
        {!props.fromHome && <Navbar />}
      </div>
    );
  }

  // Some componens behave weird when being wrapped inside a div.
  // If this is the case, render AppLayout with noNavBar and implement navbar manually inside the component.
  if (props.noNavBar) {
    return (
      <>
        {props.header()}
        {props.content()}
      </>
    );
  }

  return (
    <div>
      <div style={{ paddingBottom: props.fromHome ? 0 : 59 }}>
        {props.header()}
        {props.content()}
      </div>
      {!props.fromHome && <Navbar />}
    </div>
  );


};

AppLayout.defaultProps = {
  header: () => <></>,
};
