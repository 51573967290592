import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';

interface Props {
  bankLast4: string | undefined;
}
const PayoutDetails = ({ bankLast4 }: Props) => {
  const classes = useStyles();

  return bankLast4 ? (
    <div className={classes.flexColumn}>
      <AppTypography type={PDLTypography.largeHeading} children={'Current bank information'} />

      <AppTypography
        type={PDLTypography.paragraph}
        children={`Account ending in ${bankLast4}`}
        customStyles={{ marginTop: '1.25em' }}
      />
    </div>
  ) : (
    <></>
  );
};

export default PayoutDetails;

const useStyles = makeStyles({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
});
