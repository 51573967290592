import { useParams } from 'react-router-dom';
import AppHeader from '../commons/AppHeader';
import { AppLayout } from '../core/AppLayout';

export function ReceiptDetailComponent() {
  const { url } = useParams();
  return (
    <AppLayout
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
      header={() => <AppHeader content="Receipt" />}
      content={() => {
        return <iframe style={{ borderStyle: 'none', flex: 1 }} src={url}></iframe>;
      }}
    />
  );
}
