import { PedalUser } from '../model/PedalUser';
import User from '../../fox-typescript/core/User';
import { CoreUser } from '../model/CoreUser';
import { Bike } from '../model/Bike';

class StringUtils {
  public replaceAllOccurrences(mainText: string, searched: string, replaceFor: string) {
    return mainText.replaceAll(searched, replaceFor);
  }

  public getLastNCharacters(text: string, numberOfLastCharacters: number): string {
    return text.slice(numberOfLastCharacters * -1);
  }

  private formatBikeLocation(bike: Bike): string {
    return [bike.location.city, bike.location.state].filter(Boolean).join(', ');
  }

  public formatCompleteUserNameAndLocation = (user: PedalUser, bike: Bike) => {
    return `${this.formatCompleteUserName(user.user)} in ${this.formatBikeLocation(bike)}`;
  };

  public formatShortUserNameAndLocation = (user: PedalUser, bike: Bike) => {
    return `${this.formatShortUserName(user.user)} in ${this.formatBikeLocation(bike)}`;
  };

  /**
   * Returns name in the following format:
   * Jerry M.
   * Cristiano R.
   */
  public formatShortUserName = (user: User | CoreUser) =>
    `${user.firstName} ${user.lastName.charAt(0)}.`;

  /**
   * Returns name in the following format:
   * Lionel Messi
   * Cristiano Ronaldo
   */
  public formatCompleteUserName = (user: User | CoreUser) =>
    this.capitalizeText(`${user.firstName} ${user.lastName}`);

  /**
   * Removes underscore and capitalize the text.
   */
  public formatPartDetailType = (text: string): string => {
    return this.capitalizeText(this.removeUnderscore(text));
  };

  public removeUnderscore = (text: string): string => {
    return text.replace(/_/g, ' ');
  };

  public capitalizeText = (text: string): string => {
    return text.toLowerCase().replace(/\b./g, function (c) {
      return c.toUpperCase();
    });
  };

  public sliceText = (text: string, maxCharacters: number): string => {
    if (text.length > maxCharacters) {
      return text.substring(0, maxCharacters) + '...';
    }
    return text;
  };

  public formatPrice = (price: number = 0): string => {
    return `$${price}`;
  };
}

export default new StringUtils();
