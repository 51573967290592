import { InputBaseProps } from '@mui/material';
import { Control, Controller, UseControllerProps } from 'react-hook-form';
import { AppTypographyProps } from '../Typography/AppTypography';
import { AppTextField } from './AppTextField';

type HookFormCustomTypes = {
  label?: string;
  options?: any;
  isMulti?: boolean;
  type?: string;
  restrict?:
    | 'onlyNumbers'
    | 'onlyNumbersAndDots'
    | 'onlyLetters'
    | 'onlyNumbersAndLetters'
    | 'onlyNumbersLettersAndSpaces'
    | 'onlyLettersAndSpecialCharacters'
    | 'strongPassword'
    | 'email'
    | 'phoneNumber'
    | 'stateCode'
    | '';
  inputProps?: Omit<InputBaseProps, 'name' | 'onChange' | 'value'>;
  labelProps?: AppTypographyProps;
  customOnChange?: () => void;
};

interface UseControllerPropsWithCustomTypes extends UseControllerProps, HookFormCustomTypes {
  control: Control<any>;
}

const AppTextFieldHook = ({
  name,
  control,
  defaultValue,
  rules,
  label = '',
  restrict = '',
  inputProps = undefined,
  labelProps = undefined,
  customOnChange
}: UseControllerPropsWithCustomTypes) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <AppTextField
          label={label}
          name={name}
          value={value}
          onInputChange={(name, value, isValid) => {
            onChange(value)
            customOnChange && customOnChange()
          }
          }
          restrict={restrict}
          inputProps={inputProps}
          labelProps={labelProps}
        />
      )}
    />
  );
};

export default AppTextFieldHook;
