import { Box, Divider, IconButton, List, ListItem } from '@mui/material';
import { AppTypography } from '../Typography/AppTypography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { PDLTypography } from '../Typography/PDLTypography';
import { SxStyles } from '../../model/utils/SxStyles';

export interface Menu {
  text: string;
  action: () => void;
  extraElement?: JSX.Element;
  disabled?: boolean;
}

interface Props {
  menus: Menu[];
}

const AppMenuList = ({ menus }: Props) => {
  return (
    <List sx={{ width: '100%' }}>
      {menus
        .filter((menu) => !menu.disabled)
        .map((menu, index) => (
          <Box key={index}>
            <ListItem
              sx={styles.listItem}
              onClick={menu.action}
              key={index}
              disableGutters
              secondaryAction={
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              }
            >
              <AppTypography
                type={PDLTypography.smallHeading}
                customStyles={{ margin: '0.625em' }}
                children={menu.text}
              />
            </ListItem>
            {menu.extraElement}
            {index + 1 < menus.length && <Divider />}
          </Box>
        ))}
    </List>
  );
};

export default AppMenuList;

const styles: SxStyles = {
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(1, 0, 14, 0.05)',
    },
    cursor: 'pointer',
  },
};
