import { Container, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { ChatMessage } from '../../model/ChatMessage';
import AuthService from '../../external/fox-typescript/services/AuthService';
import Logger from '../../external/pdl-common/utils/Logger';
import AppHeader from '../commons/AppHeader';
import { AppLayout } from '../core/AppLayout';
import ChatInput from './ChatInput';
import ChatMessageComponent from './ChatMessageComponent';
import { useLocation, useParams } from 'react-router-dom';
import InboxService from '../../services/InboxService';
import AlertService from '../../services/AlertService';
import Reservation from '../../model/Reservation';
import ChatMessageCreation from '../../model/creation/ChatMessageCreation';
import { PdlChatChannel } from '../../model/PdlChatChannel';
import User from '../../external/fox-typescript/core/User';
import StringUtils from '../../utils/StringUtils';
import { Debouncer } from '../../utils/Debouncer';
import { AppSimpleList } from '../commons/AppSimpleList';
import useInterval from '../../hooks/useInterval';
import { RequestStatus } from '../../utils/RequestStatus';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { ReportIssueStatus } from '../../external/pdl-common/utils/enums/ReportIssueStatus';
import AppTheme from '../../utils/AppTheme';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { ChannelType, isPDLTeamChannel } from '../../model/enums/ChannelType';
import pdlTeamImage from '../../assets/img/pdlTeamLogo.png';

const logger = new Logger('ChatComponent');

const PAGE_SIZE = 20;
const REFRESH_TIME = 10 * 1000; // 10 seconds

const ChatComponent = (): JSX.Element => {
  const classes = useStyles();
  const { externalId } = useParams();
  const location = useLocation().state as any;

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesStartRef = useRef<HTMLDivElement>(null);

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [message, setMessage] = useState<string>('');
  const [submitStatus, setSubmitStatus] = useState<RequestStatus | undefined>(undefined);
  const [pageData, setPageData] = useState<{ number: number; totalPages: number }>({
    number: 0,
    totalPages: 0,
  });
  const [newMessagesLength, setNewMessagesLength] = useState<number>(0);

  // State params
  const pdlChannel: PdlChatChannel = location?.pdlChatChannel;
  const receiver: User | undefined = location?.receiver;

  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);

  useEffect(() => {
    if (pdlChannel.reservation || pdlChannel.reportIssue) {
      setReservation(pdlChannel.reservation || pdlChannel.reportIssue.reservation);
    }
  }, []);

  useEffect(() => {
    getMessages();
  }, [externalId]);

  // Refresh chat messages every certain time.
  useInterval(() => {
    getMessages();
  }, REFRESH_TIME);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };
  const scrollToMiddle = () => {
    messagesStartRef.current?.scrollIntoView();
  };

  const getMessages = async () => {
    if (externalId) {
      (await InboxService.getChatHistory(pdlChannel.externalId!, 0, PAGE_SIZE))
        .onSuccess((response) => {
          const newMessages = response.getContent().content;

          if (
            messages.length > 0 &&
            newMessages[0].externalId === messages[messages.length - 1].externalId
          ) {
            // It's a refresh and there are no new messages, so we don't update the state.
            return;
          }

          setMessages(newMessages.reverse());
          setNewMessagesLength(newMessages.length);
          setPageData({
            number: response.getContent().number,
            totalPages: response.getContent().totalPages,
          });
          scrollToBottom();
        })
        .onError((response) => {
          logger.error('Error getting chat messages', response.getContent());
          AlertService.showSnackCustomError(response.getContent());
        });
    }
  };

  const sendMessage = async (message: string) => {
    if (message && externalId) {
      setSubmitStatus(RequestStatus.LOADING);

      const response = await InboxService.sendMessage(externalId, new ChatMessageCreation(message));
      response
        .onSuccess((response) => {
          setSubmitStatus(RequestStatus.SUCCESS);
          setMessages([...messages, response.getContent()]);
          setMessage('');
          scrollToBottom();
        })
        .onError((response) => {
          setSubmitStatus(RequestStatus.ERROR);
          logger.error('Error sending chat message', response.getContent());
          AlertService.showSnackCustomError(response.getContent());
        });
    }
  };

  const loadMoreMessages = async () => {
    Debouncer.debounce(
      'loadMoreMessages',
      async () => {
        if (pageData.number + 1 >= pageData.totalPages) {
          return;
        }

        const response = await InboxService.getChatHistory(
          pdlChannel.externalId!,
          pageData.number + 1,
          PAGE_SIZE
        );
        response
          .onSuccess((response) => {
            const newMessages = response.getContent().content;

            setMessages([...newMessages.reverse(), ...messages]);
            setNewMessagesLength(newMessages.length);
            setPageData({ ...pageData, number: pageData.number + 1 });
            scrollToMiddle();
          })
          .onError((response) => {
            logger.error('Error loading more channels', response.getContent());
            AlertService.showSnackCustomError(response.getContent());
          });
      },
      250
    );
  };

  const isRenter = (): boolean => {
    return reservation?.bike.owner.user.externalId !== AuthService.getExternalId();
  };

  const getReceiverName = (): string => isPDLTeamChannel(pdlChannel.channelType) ? 'PDL Team' : StringUtils.formatShortUserName(receiver!);

  const renderItem = (message: ChatMessage, index: number) => {
    return (
      <div>
        {index === newMessagesLength && <div ref={messagesStartRef} />}

        <ChatMessageComponent
          key={`${message.externalId!}-${index}`}
          message={message.body!}
          date={new Date(message.createdAt!)}
          rentalDates={
            reservation
              ? DateUtils.formattedRangeDates(
                new Date(reservation?.startDate),
                new Date(reservation?.endDate)
              )
              : undefined
          }
          reservation={reservation}
          profilePic={isPDLTeamChannel(pdlChannel.channelType) ? pdlTeamImage : message.sender.user.avatarUrl}
          isOwnMessage={message.sender.user.externalId === AuthService.getExternalId()}
          isFirstMessage={index === 0}
          isRenter={reservation ? (reservation ? isRenter() : false) : false}
          channelType={pdlChannel.channelType}
        />

        {messages.length - 1 === index && <div ref={messagesEndRef} />}
      </div>
    );
  };

  const getContent = () => {
    return (
      <AppLayout
        noNavBar
        header={() => <AppHeader content={getReceiverName()} />}
        content={() => (
          <>
            <Container sx={muiStyles.mainContainer} style={{ padding: 0 }}>
              <div className={classes.innerContainer}>
                <div style={{ margin: '0% 5%' }}>
                  <AppSimpleList
                    className={classes.list}
                    data={messages}
                    loadMore={loadMoreMessages}
                    renderItem={renderItem}
                    topScroll
                  />
                </div>
              </div>
            </Container>
            {pdlChannel.reportIssue && pdlChannel.reportIssue.status === ReportIssueStatus.CLOSE && (<Container >
              <div className={classes.closeInfoContainer}>
                <p className={classes.closeInfoText}>Closed</p>
              </div>
            </Container>
            )}
            <ChatInput
              message={message}
              setMessage={setMessage}
              sendMessage={sendMessage}
              submitStatus={submitStatus}
              closed={pdlChannel?.reportIssue?.status === ReportIssueStatus.CLOSE}
            />
          </>
        )}
      />
    );
  };

  return getContent();
};

export default ChatComponent;

const muiStyles = {
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 1,
    position: 'relative',
  } as SxProps,
};

const useStyles = makeStyles({
  innerContainer: {
    position: 'absolute',
    width: '100%',
    height: '95%',
  },
  list: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxWidth: '90%',
  },
  closeInfoContainer: {
    borderRadius: '3.125em',
    border: `0.06em solid ${AppTheme.colors.red_FF0000}`,
    paddingInline: '1.875em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1em',
    maxWidth: '90%',
    marginLeft: '1%',
    marginRight: '1%'
  },
  closeInfoText: {
    color: AppTheme.colors.red_FF0000,
    fontSize: '0.813em',
    fontFamily: AppFontFamily.SHAPE_BOLD,
    marginTop: '0.6em',
    marginBottom: '0.6em',
  },
});
