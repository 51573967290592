import { Box } from '@mui/material';
import { SxStyles } from '../../model/utils/SxStyles';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

interface Props {
  label: string;
  value: string;
  useDefaultTypography?: boolean;
}

export const BikeAttribute = ({
  label,
  value,
  useDefaultTypography: useDefaultTypography,
}: Props) => {
  return (
    <Box sx={styles.detailsContainer}>
      <AppTypography
        type={useDefaultTypography ? PDLTypography.smallParagraph : PDLTypography.subHeadingSerif}
        children={label}
        customStyles={{ marginRight: 3 }}
      />
      <AppTypography type={PDLTypography.smallParagraph} children={value} />
    </Box>
  );
};

const styles: SxStyles = {
  detailsContainer: {
    display: 'flex',
    alignItems: 'center',
    my: '1em',
  },
};
