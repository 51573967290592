import Image from '../../external/fox-typescript/core/Image';
import { BikeDropOff } from '../../model/utils/BikeDropOff';
import ReservationService from '../../services/ReservationService';
import { ReservationConfirmation } from '../commons/ReservationConfirmation';

export function ReservationDropOff() {
  const handleOnConfirm = async (externalId: string, images: Image[], checks: BikeDropOff) =>
    await ReservationService.dropOffCheckList(externalId, images, checks);

  return <ReservationConfirmation confirmationType="drop-off" onConfirm={handleOnConfirm} />;
}
