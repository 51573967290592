import NotificationService from '../services/NotificationService';
import ApiResponseV2 from './ApiResponseV2';

/**
 * Class used in `ApiResponseV2` to throw an exception if the response is not sucessfull
 */
export default class ApiError<T> extends Error {
  public errorCode: string;
  public apiResponse: ApiResponseV2<T>;

  constructor(errorCode: string, message: string, apiResponse: ApiResponseV2<T>) {
    super(message);

    this.errorCode = errorCode;
    this.apiResponse = apiResponse;
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = ApiError.name; // stack traces display correctly now
  }

  /**
   * Sends the error message to the `NotificationService`
   */
  public notifyError() {
    NotificationService.error(this.message);
  }
}
