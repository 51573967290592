import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { AppAssets } from '../../../assets';
import { WaitListUserForm } from '../../../types/WaitListUserForm';
import { COLORS, IAppTheme } from '../../../utils/AppTheme';
import { AppTextField } from '../../commons/AppTextField';
import { AppLayout } from '../../core/AppLayout';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import environment from '../../../environment.json';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box } from '@mui/system';
import SimpleButton from '../../commons/SimpleButton';
import { RequestStatus } from '../../../utils/RequestStatus';
import AlertService from '../../../services/AlertService';
import { ErrorCode } from '../../../types/ErrorCode';
import WaitlistService from '../../../services/WaitlistService';
import { useForm } from '../../../hooks/useForm';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { WelcomeToPdlClub } from '../../commons/WelcomeToPdlClub';
import { FooterPdlClub } from '../../commons/FooterPdlClub';
import { SxStyles } from '../../../model/utils/SxStyles';
import TopNavBar from '../../core/LandingPageNavBar';

export function BlackSignUpWaitList() {
  const classes = useStyles();
  const navigate = useNavigate();

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);

  const [form, validations, handleCustomChange, formIsValid, clearForm] = useForm<WaitListUserForm>(
    {
      firstname: '',
      lastname: '',
      email: '',
      bike: '',
      recaptchaToken: '',
    }
  );

  const handleOnCaptchaTokenChange = (token: string | null) => {
    handleCustomChange('recaptchaToken', token || '', !!token);
  };

  const handleOnSubmit = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const response = await WaitlistService.create(form);

      setRequestStatus(RequestStatus.SUCCESS);
      setShowSuccessMsg(!!response);
      clearForm();
    } catch (error: any) {
      let err = '';

      if (error?.response?.status === 409 || error?.response?.status === 423) {
        err = 'The email is already registered';
      } else if (
        error?.response?.status === 400 &&
        error?.response?.data?.errorCode == ErrorCode.INVALID_CAPTCHA
      ) {
        err = error?.response?.data?.message;
      } else {
        err = 'Failed to join waitlist';
      }

      setRequestStatus(RequestStatus.ERROR);
      AlertService.showSnackCustomError(err);
    } finally {
      (window as any).grecaptcha.reset();
    }
  };

  return (
    <AppLayout
      noNavBar
      content={() => {
        if (showSuccessMsg) {
          return (
            <Box
              style={{
                flexDirection: 'column',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: COLORS.black_000000,
              }}
            >
              <AppAssets.PDL_CLUB
                fill="white"
                style={{ position: 'absolute', top: '0%', marginTop: '3.75em' }}
              />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <AppAssets.Checkmark />
                <AppTypography
                  type={PDLTypography.largeHeading}
                  customStyles={{
                    marginTop: '1.5em',
                    marginBottom: '1em',
                    color: COLORS.white_FFFFFF,
                  }}
                >
                  You're on the waitlist!
                </AppTypography>
                <AppTypography
                  type={PDLTypography.paragraph}
                  customStyles={{ textAlign: 'center', color: COLORS.white_FFFFFF }}
                >
                  We'll send you an email when we <br /> launch to the public, and invite you <br />
                  to create your profile.
                </AppTypography>
                <AppTypography
                  type={PDLTypography.callToAction}
                  customStyles={{ cursor: 'pointer', marginTop: '2.5em' }}
                  onClick={() => appNavigate(navigate, AppRoutes.SPLASH_SCREEN, null)}
                >
                  Return to home page
                </AppTypography>
              </div>
            </Box>
          );
        }
        return (
          <Box sx={{ backgroundColor: COLORS.black_000000 }}>
            <TopNavBar showBackButton/>
            <WelcomeToPdlClub />
            <Grid container direction="column" alignItems={'center'}>
              <Grid container direction="column" xs={10} md={8} lg={6}>
                <div className={classes.headerWrapper}>
                  <AppTypography
                    type={PDLTypography.largeHeading}
                    customStyles={{ color: COLORS.white_FFFFFF }}
                  >
                    Join the waitlist
                  </AppTypography>
                </div>
                <div className={classes.mainContainer}>
                  <AppTextField
                    label={'First name'}
                    name={'firstname'}
                    value={form.firstname}
                    inputProps={{ sx: muiStyle.input }}
                    onInputChange={(inputName: string, inputValue: string, isValid: boolean) =>
                      handleCustomChange(inputName, inputValue, isValid)
                    }
                    labelProps={{
                      type: PDLTypography.subHeading,
                      customStyles: {
                        marginBottom: '0.5em',
                        marginTop: '1.5em',
                        color: COLORS.white_FFFFFF,
                      },
                    }}
                    realTimeValidation
                    required
                    restrict="onlyLettersAndSpecialCharacters"
                  />
                  <AppTextField
                    label={'Last name'}
                    name={'lastname'}
                    value={form.lastname}
                    onInputChange={(inputName: string, inputValue: string, isValid: boolean) =>
                      handleCustomChange(inputName, inputValue, isValid)
                    }
                    inputProps={{ sx: muiStyle.input }}
                    labelProps={{
                      type: PDLTypography.subHeading,
                      customStyles: {
                        marginBottom: '0.5em',
                        marginTop: '1.5em',
                        color: COLORS.white_FFFFFF,
                      },
                    }}
                    realTimeValidation
                    required
                    restrict="onlyLettersAndSpecialCharacters"
                  />
                  <AppTextField
                    label={'Email'}
                    name={'email'}
                    value={form.email}
                    onInputChange={(inputName: string, inputValue: string, isValid: boolean) =>
                      handleCustomChange(inputName, inputValue, isValid)
                    }
                    labelProps={{
                      type: PDLTypography.subHeading,
                      customStyles: {
                        marginBottom: '0.5em',
                        marginTop: '1.5em',
                        color: COLORS.white_FFFFFF,
                      },
                    }}
                    inputProps={{ type: 'email', sx: muiStyle.input }}
                    realTimeValidation
                    required
                    restrict="email"
                  />
                  <AppTextField
                    label={'What bike do you own?'}
                    name={'bike'}
                    value={form.bike}
                    onInputChange={(inputName: string, inputValue: string, isValid: boolean) =>
                      handleCustomChange(inputName, inputValue, isValid)
                    }
                    inputProps={{ sx: muiStyle.input }}
                    labelProps={{
                      type: PDLTypography.subHeading,
                      customStyles: {
                        marginBottom: '0.5em',
                        marginTop: '1.5em',
                        color: COLORS.white_FFFFFF,
                      },
                    }}
                    realTimeValidation
                    required
                  />
                  <Box style={{ display: 'flex', justifyContent: 'center', margin: '1.5em 0em' }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={environment.recaptchaSiteKey}
                      onChange={handleOnCaptchaTokenChange}
                    />
                  </Box>
                  <SimpleButton
                    sx={{
                      marginBottom: '0.5em',
                      marginTop: '2em',
                      width: '37vh',
                      textAlign: 'center',
                    }}
                    children={'Join The Waitlist'}
                    onClick={handleOnSubmit}
                    isLoading={requestStatus === RequestStatus.LOADING}
                    disabled={!formIsValid()}
                  />
                </div>
              </Grid>
            </Grid>
            <FooterPdlClub dense />
          </Box>
        );
      }}
    />
  );
}

const muiStyle = {
  input: {
    '.MuiInputBase-input': {
      backgroundColor: COLORS.white_FFFFFF,
      width: '34vh',
      height: '3vh',
    },
  },
} as SxStyles;

const useStyles = makeStyles((theme: IAppTheme) => ({
  mainContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1.5em',
    marginBottom: '1em',
    color: COLORS.white_FFFFFF,
  },
  headerIcon: {
    marginTop: '3.75em',
    marginBottom: '1.66em',
  },
}));
