export default class ObservableService<Events extends string> {
  private mapObservers: Map<string, Function[]> = new Map();

  public subscribe(event: Events, callback: Function) {
    const observers = this.mapObservers.get(event);
    if (observers) {
      observers.push(callback);
    } else {
      this.mapObservers.set(event, [callback]);
    }
  }

  public unsubscribe(event: Events, callback: Function) {
    const observers = this.mapObservers.get(event);
    if (observers) {
      const newObservers = observers.filter(
        (observer) => observer !== callback
      );
      this.mapObservers.set(event, newObservers);
    }
  }

  public subscribeAll(events: Events[], callback: Function) {
    events.forEach((e) => this.subscribe(e, callback));
  }

  public unsubscribeAll(events: Events[], callback: Function) {
    events.forEach((e) => this.unsubscribe(e, callback));
  }

  protected notifyObservers(event: Events, ...args: any[]) {
    const observers = this.mapObservers.get(event);
    observers && observers.forEach((observer) => observer(...args));
  }
}
