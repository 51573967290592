import { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { useNavigate } from 'react-router';
import TextWithIcon from '../commons/TextWithIcon';
import { AppAssets } from '../../assets';
import { AppFontFamily } from '../../utils/AppFontFamily';
import Logger from '../../external/pdl-common/utils/Logger';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { InputWithIcon } from '../commons/InputWithIcon';
import GeolocationService from '../../services/GeolocationService';
import RecentLocationService from '../../services/RecentLocationService';
import Location from '../../external/fox-typescript/core/Location';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { Debouncer } from '../../utils/Debouncer';
import AlertService from '../../services/AlertService';
import { AppLayout } from '../core/AppLayout';

const logger = new Logger('SearchView');

export default function SearchView() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [form, setForm] = useState({ search: '' });
  const [locations, setLocations] = useState<RawResult[]>([]);
  const [recentLocations, setRecentLocations] = useState<Location[]>([]);
  const [requestState, setRequestState] = useState(RequestStatus.SUCCESS);

  useEffect(() => {
    getRecentSearches();
  }, []);

  useEffect(() => {
    getLocations();
  }, [form]);

  const getUserCurrentLocation = () => {
    setRequestState(RequestStatus.LOADING);

    navigator.geolocation.getCurrentPosition(
      async (position: GeolocationPosition) => {
        const resultResponse = await GeolocationService.getAddressFromCoordinates(
          {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          true
        );
        resultResponse
          .onSuccess((resultResponse) => {
            setForm({ search: resultResponse.getContent().display_name });
            setRequestState(RequestStatus.SUCCESS);
          })
          .onError((resultResponse) => {
            setRequestState(RequestStatus.ERROR);
            AlertService.showSnackCustomError(resultResponse.getContent());
          });
      },
      () => {
        setRequestState(RequestStatus.SUCCESS);
        AlertService.showSnackInternalError();
      }
    );
  };

  const getRecentSearches = () => {
    try {
      const locationsResponse = RecentLocationService.getRecentLocations();
      setRecentLocations(locationsResponse);
    } catch (error) {
      logger.error('Could not get recent searchs', error);
    }
  };

  const getLocations = async () => {
    if (!form.search || form.search.length === 0) return;

    setRequestState(RequestStatus.LOADING);

    Debouncer.debounce(
      'getLocations',
      async () => {
        (await GeolocationService.getLocationsFromAddress(form.search))
          .onSuccess((response) => {
            setLocations(response.getContent());
            setRequestState(RequestStatus.SUCCESS);
          })
          .onError((response) => {
            logger.error('Error fetching locations. ', response.getContent());
            AlertService.showSnackCustomError(response.getContent());
            setRequestState(RequestStatus.ERROR);
          });
      },
      1000
    );
  };

  const handleCustomChange = (inputName: string, inputValue: string) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue || '';
    setForm(updatedForm);
  };

  const setLocation = async (selectedLocation: RawResult) => {
    try {
      let latitude: number = 0;
      let longitude: number = 0;

      setForm({ search: selectedLocation.display_name });

      if (!selectedLocation.lat || !selectedLocation.lon) {
        const response = await GeolocationService.getPlaceCoordinates(selectedLocation.place_id);
        response.onError((res) => {
          AlertService.showSnackCustomError(res.getContent());
        });

        const location = response.getContent();

        // We generate another session token since the search has ended.
        GeolocationService.generateNewSessionToken();

        setLocation(location);

        latitude = +location.lat;
        longitude = +location.lon;
      } else {
        latitude = +selectedLocation.lat;
        longitude = +selectedLocation.lon;
      }

      const location = {
        geocode: selectedLocation.display_name,
        latitude,
        longitude,
      } as Location;

      RecentLocationService.save(location);
      appNavigate(navigate, AppRoutes.EXPLORE_CALENDAR, null, { state: { location } });
    } catch (error) {
      logger.error('Error trying to create recent search', error);
    }
  };

  const getBody = () => {
    return (
      <Grid item container xs={12} className={classes.gridContainer}>
        <Grid className={classes.gridHeader}>
          <Box className={classes.textBox}>
            <InputWithIcon
              name={'search'}
              value={form.search}
              placeholder={'Enter a city or zip code'}
              icon={
                <ChevronLeftIcon
                  onClick={() => {
                    if (form.search !== '') {
                      setForm({ search: '' });
                      return;
                    }
                    appNavigate(navigate, AppRoutes.EXPLORE, null);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              }
              onInputChange={handleCustomChange}
              disableAutoFill
            />
          </Box>
          <Divider variant="middle" sx={{ marginBottom: '0.5em' }} />

          {requestState === RequestStatus.LOADING || requestState === RequestStatus.ERROR ? (
            <RequestStatusFeedBack
              status={requestState}
              onRetry={() => {
                getLocations();
              }}
            />
          ) : form.search === '' ? (
            <>
              <Grid item xs={12} sx={{ marginTop: '1.5em' }}>
                <TextWithIcon
                  text={'Current Location'}
                  icon={<AppAssets.CurrentLocation />}
                  onClick={getUserCurrentLocation}
                  containerSx={{ cursor: 'pointer' }}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.textContainer}>
                  <AppTypography
                    type={PDLTypography.smallHeading}
                    customClasses={classes.recentSearches}
                  >
                    Recent Searches
                  </AppTypography>
                </div>

                {/* Feedback if no recent locations founds */}
                {recentLocations.length === 0 ? (
                  <AppTypography
                    type={PDLTypography.smallParagraph}
                    customStyles={{ marginLeft: '2.3em' }}
                    children={'No recent searches found'}
                  />
                ) : (
                  recentLocations.map((location, index) => (
                    <TextWithIcon
                      text={location.geocode || ''}
                      icon={<AppAssets.Recent />}
                      onClick={(text: string) => setForm({ search: text })}
                      containerSx={{ cursor: 'pointer' }}
                      key={location.geocode || '' + index}
                    />
                  ))
                )}
              </Grid>
            </>
          ) : (
            <>
              {locations.map((location, index) => (
                <TextWithIcon
                  text={location.display_name}
                  icon={<AppAssets.Location />}
                  onClick={() => setLocation(location)}
                  containerSx={{ cursor: 'pointer' }}
                  key={location.display_name + index}
                />
              ))}
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  return <AppLayout content={getBody} />;
}

const useStyles = makeStyles({
  gridContainer: {
    width: '100vw',
    backgroundColor: 'black',
  },
  gridHeader: {
    background: 'white',
    width: '100vw',
    height: '100vh',
    borderRadius: '1.8em 1.8em 0 0',
    marginTop: '2em',
  },
  textBox: {
    marginTop: '1.5em',
    marginBottom: '1em',
  },
  textContainer: {
    marginTop: '2.2em',
    marginBottom: '1em',
  },
  recentSearches: {
    fontFamily: AppFontFamily.INTER,
    fontWeight: 'bold',
    fontSize: '0.89em',
    marginTop: '2.45em',
    marginLeft: '2.05em',
  },
});
