import { ResponseData } from "./ResponseData";

/**
 * @deprecated Use ApiResponseV2 instead
 */
export default class ApiResponse<T> {
  constructor(
    private statusCode: number, // HTTP status code of the request
    private success: boolean, // true if request succeeded, false otherwise
    private content: T, // content of the response (if successful) or more detail about failure (if error)
    private responseCode: string // code (enum-like) identifying the response
  ) {}

  /**
   * Creates and instance from a JSON or another instance
   * @param data
   */
  public static fromData<T>(data: ResponseData<T>) {
    return new ApiResponse<T>(
      data.statusCode,
      data.success,
      data.content,
      data.responseCode
    );
  }

  public onSuccess(callback: (response: ApiResponse<T>) => void) {
    if (this.success) {
      callback(this);
    }
    return this;
  }

  public toSafePromise(fallbackValue: T): Promise<T> {
    if (this.succeeded()) {
      return Promise.resolve(this.content);
    } else {
      return Promise.resolve(fallbackValue);
    }
  }

  public succeeded(): boolean {
    return this.success;
  }

  static success<T>(content: T) {
    return new ApiResponse<T>(200, true, content, "OK");
  }

  static error(statusCode: number, responseCode: string, errorDetail: string) {
    return new ApiResponse<string>(
      statusCode,
      false,
      errorDetail,
      responseCode
    );
  }

  public onError(callback: (response: ApiResponse<string>) => void) {
    if (!this.success) {
      callback(this as any);
    }
    return this;
  }

  public getContent(): T {
    return this.content;
  }

  public getResponseCode() {
    return this.responseCode;
  }

  public getStatusCode() {
    return this.statusCode;
  }
}
