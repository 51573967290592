import environment from '../../../environment.json';

export default class Logger {
  private env: string = '';

  constructor(private prefix: string) {
    this.env = environment.env;
  }

  info(message: string, ...args: any[]) {
    if (this.env === 'dev' || this.env === 'qa') {
      console.log(`[INFO] [${this.prefix}]: ${message}`, ...args);
    }
  }

  warn(message: string, ...args: any[]) {
    if (this.env === 'dev' || this.env === 'qa') {
      console.log(`[WARN] [${this.prefix}]: ${message}`, ...args);
    }
  }

  error(message: string, error: any) {
    if (this.env === 'dev' || this.env === 'qa') {
      console.log(`[ERROR] [${this.prefix}]: ${message}`, error);
    }
  }
}
