import ApiResponseV2 from "../external/fox-typescript/core/ApiResponseV2";
import HttpClientV2 from "../external/fox-typescript/utils/HttpClientV2";

class DynamicPropertyService {
    async getCurrentDayReservationEnabled(): Promise<ApiResponseV2<boolean>> {
        return await HttpClientV2.get(`/dynamic-property/current-day-reservation-enabled`)
    }
    async getCameraProEnabled(): Promise<ApiResponseV2<boolean>> {
        return await HttpClientV2.get(`/dynamic-property/camera-pro-enabled`)
    }
}

export default new DynamicPropertyService();