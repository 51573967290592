import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { IAppTheme } from '../../utils/AppTheme';
import { CircularProgress } from '@mui/material';

interface SimpleButtonProps extends Omit<ButtonProps, 'variant'> {
  theme: 'persianBlue' | 'white';
  isLoading?: boolean;
  fontFamily?: AppFontFamily;
}

SimpleButton.defaultProps = {
  theme: 'persianBlue',
  isLoading: false,
  fontFamily: AppFontFamily.INTER,
};

export default function SimpleButton(props: SimpleButtonProps) {
  const { theme: buttonTheme, isLoading, fontFamily, ...btnProps } = props;

  const CustomButton = styled(Button)<ButtonProps>((context) => {
    const theme = context.theme as IAppTheme;

    const buttonThemes = {
      persianBlue: {
        color: theme.colors.white_FFFFFF,
        backgroundColor: theme.colors.persianBlue_2238CB,
        '&:hover': {
          color: theme.colors.white_FFFFFF,
          backgroundColor: theme.colors.persianBlue_2238CB,
        },
        '&:disabled': {
          backgroundColor: theme.colors.gray_E0E0E0,
        },
      },
      white: {
        color: theme.colors.persianBlue_2238CB,
        backgroundColor: theme.colors.white_FFFFFF,
        '&:hover': {
          color: theme.colors.persianBlue_2238CB,
          backgroundColor: theme.colors.white_FFFFFF,
        },
        '&:disabled': {
          backgroundColor: theme.colors.gray_E0E0E0,
        },
      },
    };

    return {
      borderRadius: 100,
      display: 'flex',
      width: '100%',
      paddingTop: '0.875em',
      paddingBottom: '0.875em',
      fontFamily,
      fontWeight: 500,
      fontSize: '1em',
      textTransform: 'none',
      ...buttonThemes[buttonTheme],
    };
  });

  return (
    <CustomButton disableElevation variant="contained" {...btnProps}>
      {props.isLoading ? <CircularProgress color="inherit" size={30} /> : props.children}
    </CustomButton>
  );
}
