import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { RequestStatus } from '../../external/pdl-common/utils/RequestStatus';
import AlertService from '../../services/AlertService';
import ReservationService from '../../services/ReservationService';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { BikeReservationManagePayments } from './BikeReservationManagePayments';

interface Props {
  totalPrice: number;
  bikeDailyPrice: number;
  insuranceFee: number;
  startDate: Date;
  endDate: Date;
  paymentMethod: string;
  onNewPaymentMethod: (paymentMethod: string) => void;
}

const userStyles = makeStyles({
  paymentMethods: {
    display: 'flex',
    flexDirection: 'row',
  },
  footerSections: {
    marginBottom: '1em',
  },
  container: {
    marginBottom: '1.4em',
  },
  margins: {
    marginBottom: '1.4em',
    marginTop: '1.4em',
  },
});

export const BikeReservationPaymentSection = (props: Props) => {
  const classes = userStyles();

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [fee, setFee] = useState<number>(0);

  const formattedCancellationDate = DateUtils.formattedShortDate(
    DateUtils.getDateWithDifference(props.startDate, -1)
  );

  useEffect(() => {
    getRenterRefund();
  }, [props.totalPrice, props.bikeDailyPrice]);

  const getRenterRefund = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (
      await ReservationService.getRenterCancellationFee(
        props.totalPrice,
        props.bikeDailyPrice,
        props.insuranceFee
      )
    )
      .onSuccess((response) => {
        setRequestStatus(RequestStatus.SUCCESS);
        setFee(response.getContent());
      })
      .onError((response) => {
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getCancelationMessage = (): string => {
    const daysBeforeStartDate = DateUtils.getDaysBetweenDates(new Date(), props.startDate);
    const tripLength = DateUtils.getFullDaysBetweenDates(props.startDate, props.endDate);

    // If 24hs or less before start date
    if (daysBeforeStartDate === 0) {
      return `Cancel before ${DateUtils.formattedShortDate(
        props.startDate
      )} and get a full refund minus the $${fee} cancellation fee.`;
    }
    return `Cancel by ${formattedCancellationDate} and get a full refund. Cancel after ${formattedCancellationDate} and get a full refund minus the $${fee} cancellation fee.`;
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          <div className={classes.footerSections}>
            <AppTypography type={PDLTypography.smallHeading}>Payment Method</AppTypography>
          </div>
          <div>
            <div className={classes.margins}>
              <BikeReservationManagePayments
                paymentMethod={props.paymentMethod}
                onNewPaymentMethod={props.onNewPaymentMethod}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={classes.footerSections}>
            <AppTypography type={PDLTypography.smallHeading}>Cancellation policy</AppTypography>
          </div>
          <div>
            <AppTypography type={PDLTypography.paragraph}>{getCancelationMessage()}</AppTypography>
          </div>
        </div>
      </div>
    </>
  );
};
