import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RequestStatus } from '../../external/pdl-common/utils/RequestStatus';
import { useForm } from '../../hooks/useForm';
import AlertService from '../../services/AlertService';
import UserService from '../../services/UserService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import AppHeader from '../commons/AppHeader';
import FormContainer from '../commons/FormContainer';
import SimpleButton from '../commons/SimpleButton';
import UserAdditionalInformation from '../commons/UserAdditionalInformation';
import { AppLayout } from '../core/AppLayout';
import postalCodes from 'postal-codes-js';
import Logger from '../../external/pdl-common/utils/Logger';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { Box } from '@mui/system';
import Navbar from '../core/Navbar';

export interface UserAdditionalInfo {
  phoneNumber: string;
  streetAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
  countryCode: string;
  zoneId: string;
}

type StateProps = {
  userAdditionalInfo?: boolean;
  navigateTo?: AppRoutes;
};

type LocationProps = {
  state: StateProps;
};

const logger = new Logger('CompleteAdditionalInformation');

const CompleteAdditionalInformation = () => {
  const navigate = useNavigate();
  const location = useLocation() as unknown as LocationProps;
  const userAdditionalInfo = location.state?.userAdditionalInfo;
  const navigateTo = location.state?.navigateTo;

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [form, validations, handleCustomChange, formIsValid, , setValidations, setForm] =
    useForm<UserAdditionalInfo>({
      phoneNumber: '',
      streetAddress: '',
      city: '',
      stateCode: '',
      zipCode: '',
      countryCode: '',
      zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

  useEffect(() => {
    if (!userAdditionalInfo) {
      getUserAdditionalInfo();
    }
  }, []);

  const getUserAdditionalInfo = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await UserService.checkUserAdditionalInfo())
      .onSuccess((response) => {
        setRequestStatus(RequestStatus.SUCCESS);
        setForm(response.getContent() as UserAdditionalInfo);
      })
      .onError((response) => {
        setRequestStatus(RequestStatus.ERROR);
        logger.error('Failed to load user additional information. ', response.getContent());
      });
  };

  const onSave = async () => {

    /* library failing with correct country and postal code combination, eg AR, BG2930
        * disabling this for now shouldn't be a problem since country/zip code are selected automatically?
    const zipCodeValidation = postalCodes.validate(form.countryCode, form.zipCode);

    if (zipCodeValidation !== true) {
      setRequestStatus(RequestStatus.ERROR);
      AlertService.showSnackCustomError(zipCodeValidation as string);
      return;
    }
       */

    setRequestStatus(RequestStatus.LOADING);

    (await UserService.completeUserAdditionalInfo(form))
      .onSuccess((res) => {
        setRequestStatus(RequestStatus.SUCCESS);

        AlertService.showSuccessMessage(res.getContent());

        setTimeout(() => {
          if (navigateTo) {
            appNavigate(navigate, navigateTo, null, { state: { ...location.state } });
          } else {
            appNavigate(navigate, AppRoutes.EXPLORE, null);
          }
        }, 1500);
      })
      .onError((err) => {
        logger.error('Error saving additional information', err.getContent());
        AlertService.showSnackCustomError(err.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getContent = () => {
    return (
      <>
        <FormContainer
          bottomElementStyle={{ marginBottom: '59px' }}
          BottomElement={
            <SimpleButton
              children={'Save'}
              onClick={onSave}
              disabled={!formIsValid() || requestStatus === RequestStatus.LOADING}
              isLoading={requestStatus === RequestStatus.LOADING}
            />
          }
        >
          <Box sx={{ mt: '0.8em' }}>
            <AppTypography type={PDLTypography.paragraph}>
              Please make sure to enter valid information, as this will later be validated in the
              reservation process.
            </AppTypography>
          </Box>

          <UserAdditionalInformation
              displayPhoneNumberField
            handleCustomChange={handleCustomChange}
            phoneNumber={form.phoneNumber}
            streetAddress={form.streetAddress}
            stateCode={form.stateCode}
            city={form.city}
            countryCode={form.countryCode}
            zipCode={form.zipCode}
            setForm={(updatedForm) =>
              setForm((previousForm) => {
                return { ...previousForm, ...updatedForm };
              })
            }
            setValidations={(updatedValidations) =>
              setValidations((previousValidations) => {
                return { ...previousValidations, ...updatedValidations };
              })
            }
          />
        </FormContainer>
        <Navbar/>
      </>
    );
  };

  return (
    <AppLayout
      noNavBar
      header={() => <AppHeader content="Complete additional information" showBackButton={false} />}
      content={() => getContent()}
    />
  );
};

export default CompleteAdditionalInformation;
