import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import environment from '../environment.json';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import { PdlChatChannel } from '../model/PdlChatChannel';
import { IPage } from '../external/fox-typescript/core/IPage';
import { ChatMessage } from '../model/ChatMessage';
import ChatMessageCreation from '../model/creation/ChatMessageCreation';

class InboxService extends GenericCrudService<PdlChatChannel> {
  async getAllChannelsAsUser(
    page: number,
    pageSize: number,
    userName?: string
  ): Promise<ApiResponse<IPage<PdlChatChannel>>> {
    return await HttpClient.get<IPage<PdlChatChannel>>(
      `${this.basePath}/channels/as-user?userName=${userName}&page=${page}&pageSize=${pageSize}`
    );
  }

  async getChatHistory(
    channelExternalId: string,
    page?: number,
    pageSize?: number
  ): Promise<ApiResponse<IPage<ChatMessage>>> {
    return await HttpClient.get<IPage<ChatMessage>>(
      `${this.basePath}/chat-history/${channelExternalId}?page=${page}&pageSize=${pageSize}`,
      true
    );
  }

  async sendMessage(
    channelExternalId: string,
    chatMessage: ChatMessageCreation
  ): Promise<ApiResponse<ChatMessage>> {
    return await HttpClient.post(
      `${this.basePath}/send-message/${channelExternalId}`,
      chatMessage,
      true
    );
  }

  async getByReservationExternalId(externalId: string): Promise<ApiResponse<PdlChatChannel>> {
    return await HttpClient.get<PdlChatChannel>(`${this.basePath}/channel/${externalId}`, true);
  }

  async getUnreadCount(): Promise<ApiResponse<boolean>> {
    return await HttpClient.get<boolean>(`${this.basePath}/unread-count`);
  }
}

export default new InboxService(`${environment.baseURL}/inbox`);
