import { ReactComponent as PDL_CLUB } from './icons/PDL_club.svg';
import { ReactComponent as PDL_ICON } from './icons/PDL_club_icon.svg';
import { ReactComponent as Checkmark } from './icons/checkmark.svg';
import { ReactComponent as Account } from './icons/account.svg';
import { ReactComponent as Bike } from './icons/bike.svg';
import { ReactComponent as Inbox } from './icons/inbox.svg';
import { ReactComponent as Map } from './icons/map.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as Image } from './icons/image.svg';
import { ReactComponent as Camera } from './icons/camera.svg';
import { ReactComponent as UploadIcon } from './icons/upload.svg';
import { ReactComponent as HeartIcon } from './icons/heart.svg';
import { ReactComponent as FilterIcon } from './icons/filters.svg';
import { ReactComponent as ListIcon } from './icons/list.svg';
import { ReactComponent as ListActiveIcon } from './icons/list_active.svg';
import { ReactComponent as MapIcon } from './icons/map.svg';
import { ReactComponent as MapActiveIcon } from './icons/map_active.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as CloseWhite } from './icons/close_white.svg';
import { ReactComponent as BookOpenIcon } from './icons/book_open.svg';
import { ReactComponent as Location } from './icons/location.svg';
import { ReactComponent as Recent } from './icons/recent.svg';
import { ReactComponent as CurrentLocation } from './icons/current_location.svg';
import { ReactComponent as ImageIcon } from './icons/image.svg';
import { ReactComponent as MapPinIcon } from './icons/map_pin.svg';
import { ReactComponent as CheckCircle } from './icons/check-circle.svg';
import { ReactComponent as PlusCircleIcon } from './icons/plus_circle.svg';
import { ReactComponent as RectangleIcon } from './icons/rectangle.svg';
import { ReactComponent as EyeIcon } from './icons/eye.svg';
import { ReactComponent as FlagIcon } from './icons/flag.svg';
import { ReactComponent as ChatBubble } from './icons/chat_bubble_icon.svg';
import { ReactComponent as Receipt } from './icons/receipt_icon.svg';
import { ReactComponent as CrossCircle } from './icons/cross_circle.svg';
import { ReactComponent as PaperPlane } from './icons/paper_plane.svg';
import { ReactComponent as Trash } from './icons/trash.svg';

// Waitlist
import { ReactComponent as BikeActive } from './icons/bikeActive.svg';
import { ReactComponent as Dollar } from './icons/dollar.svg';
import { ReactComponent as Message } from './icons/message.svg';
import { ReactComponent as User } from './icons/user.svg';

import HostPresentation from './img/host_presentation.png';
import InviteFriends from './img/invite_friends.png';
import WaitlistBike from './img/waitlist_bike.png';
import WaitlistAboutCardBike from './img/WaitlistOrAboutPage/Bike.png';
import WaitlistCardDestinations from './img/WaitlistOrAboutPage/Destinations.png';
import WaitlistCardListing from './img/WaitlistOrAboutPage/Listing.png';
import WaitlistAboutCardProfile from './img/WaitlistOrAboutPage/Profile.png';
import WaitlistAboutCardSearch from './img/WaitlistOrAboutPage/Search.png';
import AboutTeam1 from './img/AboutPage/Team/AndreasHeuser.png';
import AboutTeam2 from './img/AboutPage/Team/MartinCrupi.jpg';
import AboutTeam3 from './img/AboutPage/Team/DemianBellumio.png';
import AboutTeam4 from './img/AboutPage/Team/MartinPombo.png';
import AboutPageInbox from './img/AboutPage/Inbox.png';
import AboutBoard1 from './img/AboutPage/Board/MarceloClaure.jpeg';
import AboutBoard2 from './img/AboutPage/Board/AdrianaCisneros.jpeg';
import AboutBoard3 from './img/AboutPage/Board/JonOringer.jpeg';
import AboutBoard4 from './img/AboutPage/Board/CarlosDomingo.jpeg';
import AboutBoard5 from './img/AboutPage/Board/DavidGarcia.jpeg';
import AboutBoard6 from './img/AboutPage/Board/MikeByrne.jpeg';

import LargeLogo from './img/pdl_large_logo.png';

export const AppAssets = {
  PDL_CLUB,
  PDL_ICON,
  Checkmark,
  Account,
  Bike,
  Inbox,
  Map,
  Search,
  Image,
  Camera,
  UploadIcon,
  HeartIcon,
  FilterIcon,
  ListIcon,
  ListActiveIcon,
  MapIcon,
  MapActiveIcon,
  SearchIcon,
  CalendarIcon,
  BookOpenIcon,
  Location,
  Recent,
  CurrentLocation,
  ImageIcon,
  HostPresentation,
  Close,
  CloseWhite,
  MapPinIcon,
  InviteFriends,
  CheckCircle,
  PlusCircleIcon,
  RectangleIcon,
  EyeIcon,
  FlagIcon,
  ChatBubble,
  Receipt,
  CrossCircle,
  BikeActive,
  Dollar,
  Message,
  User,
  WaitlistBike,
  WaitlistAboutCardBike,
  WaitlistCardDestinations,
  WaitlistCardListing,
  WaitlistAboutCardProfile,
  WaitlistAboutCardSearch,
  PaperPlane,
  Trash,
  LargeLogo,
  AboutPageInbox,
  AboutTeam1,
  AboutTeam2,
  AboutTeam3,
  AboutTeam4,
  AboutBoard1,
  AboutBoard2,
  AboutBoard3,
  AboutBoard4,
  AboutBoard5,
  AboutBoard6,
};
