import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import { Content } from '../model/Content';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import environment from '../environment.json';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import { IPage } from '../external/fox-typescript/core/IPage';
import HttpClientV2 from '../external/fox-typescript/utils/HttpClientV2';
import ApiResponseV2 from '../external/fox-typescript/core/ApiResponseV2';

class ContentService extends GenericCrudService<Content> {
  async getAllContent(
    page: number,
    pageSize: number,
    filter?: string
  ): Promise<ApiResponse<IPage<Content>>> {
    return await HttpClient.get<IPage<Content>>(
      `${this.basePath}/all?page=${page}&size=${pageSize}&contentTitle=${filter}`,
      true
    );
  }

  async getContentForHomePage(): Promise<ApiResponseV2<Content[]>> {
    return await HttpClientV2.get<Content[]>(`${this.basePath}/for-home`);
  }

  async getContentDetails(externalId: string): Promise<ApiResponse<Content>> {
    return await HttpClient.get(`${this.basePath}/${externalId}`, true);
  }

  async getTermsAndConditionsDetails(title: string): Promise<ApiResponseV2<Content>> {
    return await HttpClientV2.get(`${this.basePath}/terms-and-conditions?title=${title}`);
  }

  create(instance: Content): Promise<ApiResponse<Content>> {
    throw new Error('Method not implemented.');
  }

  delete(instance: Content): Promise<ApiResponse<Content>> {
    throw new Error('Method not implemented.');
  }
}

export default new ContentService(`${environment.baseURL}/pdl-content`);
