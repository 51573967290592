import { styled, Table, TableBody, TableCell, TableRow } from '@mui/material';
import AppTheme from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import BottomSheet from '../commons/BottomSheet';
import { Flexibility } from '../../model/measurements/Flexibility';

interface Props {
  open: boolean;
  onClose: () => void;
  flexibilityTypes: Flexibility[];
}

const FlexibilityTableBottomSheet = ({ open, onClose, flexibilityTypes }: Props) => {
  const CustomTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
      backgroundColor: AppTheme.colors.white_EEEEEC,
    },
    '&:nth-of-type(even)': {
      backgroundColor: AppTheme.colors.white_FFFFFF,
    },
  });

  return (
    <BottomSheet open={open} onClose={onClose} bottomSpace="5em">
      <AppTypography type={PDLTypography.largeHeading} children={'Flexibility guide'} />
      <Table sx={{ mt: 4 }} aria-label="simple table">
        <TableBody>
          {flexibilityTypes.map((row) => (
            <CustomTableRow key={row.value}>
              <TableCell
                align="center"
                width={'35%'}
                sx={{
                  backgroundColor: AppTheme.colors.persianBlue_2238CB,
                  color: AppTheme.colors.white_FFFFFF,
                }}
              >
                <AppTypography
                  type={PDLTypography.subHeading}
                  children={row.value}
                  customStyles={{ color: AppTheme.colors.white_FFFFFF }}
                />
              </TableCell>
              <TableCell align="left">
                <AppTypography type={PDLTypography.smallParagraph} children={row.description} />
              </TableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </BottomSheet>
  );
};

export default FlexibilityTableBottomSheet;
