import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { styled } from '@mui/styles';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

const TitleCell = styled(TableCell)(({ theme }) => ({
  color: 'white !important',
  fontFamily: AppFontFamily.SHAPE_BOLD + ' !important',
  borderColor: 'white',
  borderRight: '0.08em solid',
  '&:last-child': {
    borderRadius: '0 0.5em 0 0',
    borderRight: '0',
  },
  '&:first-child': {
    borderRadius: '0.5em 0 0 0',
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: AppTheme.colors.white_EEEEEC,
  },
  '&:last-child td, &:last-child th': {
    borderBottom: 0,
  },
}));

const createRow = (inches: string, centimeters: string, size: string) => {
  return { inches, centimeters, size };
};

const rows = [
  createRow(`4'8" - 5'1"`, `142 - 155`, `44, X-Small`),
  createRow(`5'1" - 5'4"`, `155 - 163`, `49, Small`),
  createRow(`5'4" - 5'7"`, `163 - 170`, `52, Small`),
  createRow(`5'7" - 5'9"`, `170 - 175`, `54, Medium`),
  createRow(`5'9" - 5'11"`, `175 - 180`, `56, Medium`),
  createRow(`5'11" - 6'2"`, `180 - 188`, `58, Large`),
  createRow(`6'2" - 6'5"`, `188 - 196`, `61, Large`),
];

export default function SizeGuideTable() {
  return (
    <Table>
      <TableHead>
        <TableRow sx={{ bgcolor: AppTheme.colors.persianBlue_2238CB }}>
          <TitleCell align="center">Inches</TitleCell>
          <TitleCell align="center">Centimeters</TitleCell>
          <TitleCell align="center">Size</TitleCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <StyledTableRow key={row.size}>
            <TableCell
              align="center"
              sx={{ borderRight: '0.1em solid ' + AppTheme.colors.gray_E3E5E4 }}
            >
              <AppTypography type={PDLTypography.paragraph}>{row.inches}</AppTypography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ borderRight: '0.1em solid ' + AppTheme.colors.gray_E3E5E4 }}
            >
              <AppTypography type={PDLTypography.paragraph}>{row.centimeters}</AppTypography>
            </TableCell>
            <TableCell align="center">
              <AppTypography type={PDLTypography.paragraph}>{row.size}</AppTypography>
            </TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
}
