import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppAssets } from '../../assets';
import CustomAuthService from '../../external/fox-typescript/services/CustomAuthService';
import { useForm } from '../../hooks/useForm';
import { SxStyles } from '../../model/utils/SxStyles';
import AlertService from '../../services/AlertService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import { AppTextField } from '../commons/AppTextField';
import SimpleButton from '../commons/SimpleButton';
import { AppTypography, AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { PasswordHelper } from '../commons/PasswordHelper';

export interface ValidateForgotPasswordForm {
  password: string;
  confirmPassword: string;
  code: string;
  email: string;
}

const logger = new Logger('ValidateForgotPassword');

const ValidateForgotPassword = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const code: string = searchParams.get('code') || '';
  const email: string = searchParams.get('email') || '';

  const confirmPasswordInput = useRef<{ validate: () => void }>(null);
  const [submitStatus, setSubmitStatus] = useState<RequestStatus | undefined>(undefined);
  const [passwordHelperOpen, setPasswordHelperOpen] = useState<boolean>(false);

  const [form, validations, handleCustomChange, formIsValid, clearForm, setValidations] =
    useForm<ValidateForgotPasswordForm>({
      password: '',
      confirmPassword: '',
      code,
      email,
    });

  useEffect(() => {
    if (form.confirmPassword) {
      const isValid = confirmPasswordInput?.current?.validate();

      let updatedValidations = { ...validations };
      (updatedValidations as any).confirmPassword = isValid;
      setValidations(updatedValidations);
    }
  }, [form.password]);

  const onSubmit = async () => {
    try {
      setSubmitStatus(RequestStatus.LOADING);

      await CustomAuthService.validateForgotPassword(form.email, form.code, form.password);

      setSubmitStatus(RequestStatus.SUCCESS);

      appNavigate(navigate, AppRoutes.SIGN_IN, null);
    } catch (error: any) {
      logger.error('Error validating forgot password', error);

      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }

      setSubmitStatus(RequestStatus.ERROR);
    }
  };

  return (
    <>
      <Grid container spacing={8} columns={1} sx={muiStyle.gridHeader}>
        <Grid item xs={12} sm={6} sx={muiStyle.gridHeader}>
          <AppAssets.PDL_CLUB fill="black" />
          <AppTypography type={PDLTypography.largeHeading} customClasses={classes.title}>
            Generate a new password
          </AppTypography>
        </Grid>

        <Grid item xs={12} sm={12} sx={{ mx: 4 }}>
          <Grid sx={muiStyle.gridBody}>
            <AppTextField
              label={'New Password'}
              name={'password'}
              value={form.password}
              onInputChange={handleCustomChange}
              inputProps={{ sx: { width: '80vw' }, type: 'password' }}
              labelProps={labelProps}
              realTimeValidation
              required
              restrict="strongPassword"
              bottomRightElement={
                <AppTypography
                  type={PDLTypography.smallParagraph}
                  children={'Valid format'}
                  customStyles={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    textAlign: 'right',
                  }}
                  onClick={() => setPasswordHelperOpen(true)}
                />
              }
            />

            <AppTextField
              ref={confirmPasswordInput}
              label={'Confirm New Password'}
              name={'confirmPassword'}
              value={form.confirmPassword}
              onInputChange={handleCustomChange}
              inputProps={{ sx: { width: '80vw' }, type: 'password' }}
              labelProps={labelProps}
              realTimeValidation
              required
              customValidation={(text) => {
                if (text !== form.password) {
                  return {
                    showError: true,
                    isValid: false,
                    message: 'Password do not match',
                  };
                }
                return { showError: false, isValid: true, message: '' };
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} sx={muiStyle.gridBottom}>
          <div className={classes.confirmBtn}>
            <SimpleButton
              children={'Confirm'}
              onClick={onSubmit}
              disabled={submitStatus === RequestStatus.LOADING || !formIsValid()}
              isLoading={submitStatus === RequestStatus.LOADING}
            />
          </div>
        </Grid>
      </Grid>
      {/* Password Helper */}
      <PasswordHelper open={passwordHelperOpen} onClose={() => setPasswordHelperOpen(false)} />
    </>
  );
};

export default ValidateForgotPassword;

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.5em',
    marginTop: '2em',
  },
};

const useStyles = makeStyles({
  title: {
    marginTop: '1.7em',
  },
  confirmBtn: {
    width: '80vw',
    marginTop: '6em',
  },
});

const muiStyle: SxStyles = {
  gridHeader: {
    marginTop: '0em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  gridBottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '2em',
  },
};
