import { SnackbarSeverity } from '../components/core/SnackbarComponent';
import ObservableService from '../external/fox-typescript/core/ObservableService';
import AlertEvents from './Events/AlertEvents';

class AlertService extends ObservableService<AlertEvents> {
  showSnackInternalError(): void {
    this.notifyObservers(AlertEvents.SNACK_BAR_SHOW_ALERT, {
      severity: SnackbarSeverity.ERROR,
      text: 'An internal error occurred, please try again later',
    });
  }

  showSnackCustomError(message: string): void {
    this.notifyObservers(AlertEvents.SNACK_BAR_SHOW_ALERT, {
      severity: SnackbarSeverity.ERROR,
      text: message,
    });
  }

  showInformationMessage(message: string): void {
    this.notifyObservers(AlertEvents.SNACK_BAR_SHOW_ALERT, {
      severity: SnackbarSeverity.INFO,
      text: message,
    });
  }

  showSuccessMessage(message: string): void {
    this.notifyObservers(AlertEvents.SNACK_BAR_SHOW_ALERT, {
      severity: SnackbarSeverity.SUCCESS,
      text: message,
    });
  }

  showWarningMessage(message: string): void {
    this.notifyObservers(AlertEvents.SNACK_BAR_SHOW_ALERT, {
      severity: SnackbarSeverity.WARNING,
      text: message,
    });
  }
}

export default new AlertService();
