import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import environment from '../environment.json';
import { PartDetail } from '../external/pdl-common/model/PartDetail';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import httpClient from '../external/fox-typescript/utils/HttpClient';
import BikePartCreation from '../model/creation/BikePartCreation';

class BikePartService extends GenericCrudService<PartDetail> {
  async createBikeParts(
    bikeParts: BikePartCreation[],
    bikeExternalId: string
  ): Promise<ApiResponse<PartDetail[]>> {
    return httpClient.post(`${this.basePath}/create-bike-parts/${bikeExternalId}`, bikeParts);
  }
  async createBikePart(bikePart: BikePartCreation): Promise<ApiResponse<PartDetail>> {
    return httpClient.post(`${this.basePath}/create-bike-part`, bikePart);
  }
  async deleteBikePart(bikeExternalId: string, bikePart: string): Promise<ApiResponse<PartDetail>> {
    return httpClient.delete(`${this.basePath}/delete-bike-part/${bikeExternalId}/${bikePart}`);
  }
}

export default new BikePartService(`${environment.baseURL}/bike-parts`);
