import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { AppLayout } from '../../core/AppLayout';
import AppHeader from '../../commons/AppHeader';
import { Container, Divider, Grid } from '@mui/material';
import ListingLink from '../../commons/ListingLink';
import { AppTextField } from '../../commons/AppTextField';
import { useEffect, useState } from 'react';
import SimpleButton from '../../commons/SimpleButton';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import { useLocation, useNavigate } from 'react-router-dom';
import Reservation from '../../../model/Reservation';
import { Bike } from '../../../external/pdl-common/model/Bike';
import reservationService from '../../../services/ReservationService';
import NotificationModal from '../../commons/NotificationModal';
import AlertService from '../../../services/AlertService';
import { useForm } from '../../../hooks/useForm';
import { ReservationCancellationAsHost } from '../../../model/update/reservation/ReservationCancellationAsHost';
import Logger from '../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../utils/RequestStatus';
import { CoreUser } from '../../../external/pdl-common/model/CoreUser';
import DateUtils from '../../../external/pdl-common/utils/DateUtils';
import { BikeReservationManagePayments } from '../../Reservation/BikeReservationManagePayments';
import StripeService from '../../../services/StripeService';

const logger = new Logger('CancelReservation');

const CancelReservation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);
  const [form, validations, handleCustomChange, formIsValid] =
    useForm<ReservationCancellationAsHost>({
      message: '',
    });
  const [submitStatus, setSubmitStatus] = useState<RequestStatus | undefined>(undefined);
  const [cancellationPenalty, setCancellationPenalty] = useState<number | undefined>(undefined);

  const reservation: Reservation | undefined = location?.state as any;

  useEffect(() => {
    load();
  }, []);

  if (!reservation) {
    return <></>;
  }

  const load = async () => {
    if (reservation.externalId) {
      try {

        const response = await StripeService.getCurrentCreditCard();
        setPaymentMethod(response.getContent());

      } catch (err: any) {
      if (err?.response?.status === 404) {
        logger.error('User doesnt have cc information, expected error', err);
      } else {
        logger.error('Error getting stripe credit card', err);
      }
    }

      (await reservationService.getHostCancellationPenalty(reservation.externalId))
        .onSuccess((response) => {
          setCancellationPenalty(response.getContent());
        })
        .onError((response) => {
          logger.error('Error fetching cancellation penalty', response.getContent());
          AlertService.showSnackInternalError();
        });
    }
  };

  const bike: Bike = reservation.bike;
  const user: CoreUser = reservation.renter.user;

  const onSubmit = async () => {
    if (reservation.externalId) {
      try {
        setSubmitStatus(RequestStatus.LOADING);

        await reservationService.cancelReservationAsHost(reservation.externalId, form);

        setSubmitStatus(RequestStatus.SUCCESS);
        setIsNotificationModalOpen(true);
      } catch (error: any) {
        logger.error('Error cancelling reservation', error);

        if (error?.response?.data?.message) {
          AlertService.showSnackCustomError(error?.response?.data?.message);
        } else {
          AlertService.showSnackInternalError();
        }

        setSubmitStatus(RequestStatus.ERROR);
      }
    }
  };

  return (
    <AppLayout
      header={() => (
        <AppHeader
          content="Cancel Reservation"
          showBackButton={submitStatus !== RequestStatus.LOADING}
        ></AppHeader>
      )}
      content={() => {
        return (
          <>
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <Container sx={{ marginTop: '1.5em', width: '95%' }}>
                <ListingLink
                  title={bike.title}
                  imageUrl={bike.images && bike.images.length > 0 ? bike.images[0].url : ''}
                  cells={[
                    {
                      label: 'Rented by',
                      value: `${user.firstName} ${user.lastName}`,
                    },
                    {
                      label: 'Dates',
                      value: `${DateUtils.formattedRangeDates(
                        new Date(reservation.startDate),
                        new Date(reservation.endDate)
                      )}`,
                    },
                  ]}
                />
                <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <AppTypography
                      type={PDLTypography.paragraph}
                      customStyles={{ marginTop: '1em' }}
                    >
                      The renter will receive a full refund of
                    </AppTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <AppTypography
                      type={PDLTypography.smallHeading}
                      customStyles={{ marginTop: '1em' }}
                    >
                      {`$${Math.round(reservation.totalPrice * 100) / 100}`}
                    </AppTypography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <AppTypography
                      type={PDLTypography.paragraph}
                      customStyles={{ marginTop: '1em' }}
                    >
                      Host will be charge with a penalty of
                    </AppTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <AppTypography
                      type={PDLTypography.smallHeading}
                      customStyles={{ marginTop: '1em' }}
                    >
                      {`$${cancellationPenalty}`}
                    </AppTypography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item xs={12}>
                    <div style={{ marginBottom: '1em' }}>
                      <AppTypography
                        type={PDLTypography.smallHeading}
                        customStyles={{ marginTop: '1em', marginBottom: '1em' }}
                      >
                        Payment Method
                      </AppTypography>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <BikeReservationManagePayments
                      paymentMethod={paymentMethod}
                      onNewPaymentMethod={setPaymentMethod}
                    />
                    </Grid>
                </Grid>
                <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                <form>
                  <AppTextField
                    name={'message'}
                    label={
                      'Please provide a reason for the trip cancellation to send to the renter'
                    }
                    value={form.message}
                    inputProps={{ rows: 10, multiline: true }}
                    onInputChange={handleCustomChange}
                    realTimeValidation
                    required
                    minLength={10}
                  />
                </form>
              </Container>
            </div>

            <Container sx={{ marginTop: '1em', marginBottom: '1.5em', width: '95%' }}>
              <SimpleButton
                type="submit"
                children={'Cancel Reservation'}
                onClick={onSubmit}
                disabled={
                  submitStatus === RequestStatus.LOADING ||
                  submitStatus === RequestStatus.SUCCESS ||
                  paymentMethod === '' ||
                  !formIsValid()
                }
                isLoading={submitStatus === RequestStatus.LOADING}
              />

              <NotificationModal
                open={isNotificationModalOpen}
                text="Your reservation has been canceled"
                onClose={() => {
                  setIsNotificationModalOpen(false);
                  appNavigate(navigate, AppRoutes.HOST, null);
                }}
              />
            </Container>
          </>
        );
      }}
    />
  );
};

export default CancelReservation;
