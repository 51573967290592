import { makeStyles } from '@mui/styles';
import { IAppTheme } from '../../../../utils/AppTheme';
import BottomSheet from '../../../commons/BottomSheet';
import SimpleButton from '../../../commons/SimpleButton';
import { AppTypography } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmBikeEditionBottomSheet = (props: Props) => {
  const classes = useStyles();

  return (
    <BottomSheet open={props.open} onClose={props.onClose} bottomSpace="5em">
      <div className={classes.container}>
        <AppTypography type={PDLTypography.largeHeading}>
          Are you sure you want to edit your bike?
        </AppTypography>
        <AppTypography type={PDLTypography.paragraph} customClasses={classes.paragraph}>
          When editing your bike, the changes must go through a re-approval process. A new bike will
          be created. The current one will be deactivated.
        </AppTypography>

        <div className={classes.buttonContainer}>
          <SimpleButton
            onClick={() => {
              props.onClose();
              props.onConfirm();
            }}
          >
            Yes, save the changes
          </SimpleButton>
        </div>
      </div>
    </BottomSheet>
  );
};

export default ConfirmBikeEditionBottomSheet;

const useStyles = makeStyles((theme: IAppTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paragraph: {
    marginTop: '1em',
    textAlign: 'justify',
  },
  buttonContainer: {
    margint: '0px auto',
    marginTop: '2em',
  },
}));
