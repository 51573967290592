import { MapCoordinates } from '../model/utils/MapCoordinates';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { v4 as uuidv4 } from 'uuid';

export interface OpenStreetMapGeoLocationResponse {
  display_name: string;
  lat: number;
  lon: number;
  address: {
    country: string;
    country_code: string;
    house_number: string;
    postcode: string;
    road: string;
    state: string;
    village?: string;
    suburb?: string;
    town?: string;
    city?: string;
    county?: string;
    state_district: string;
    state_code: string;
  };
}

class GeolocationService {
  private basePath: string;
  private sessionToken: string;

  constructor(basePath: string, sessionToken: string) {
    this.basePath = basePath;
    this.sessionToken = sessionToken;
  }

  public generateNewSessionToken() {
    /**
     * Place Autocomplete uses session tokens to group the query and selection phases of a user autocomplete search into
     * a discrete session for billing purposes. The session begins when the user starts typing a query, and concludes
     * when they select a place and a call to Place Details is made.
     *
     * https://developers.google.com/maps/documentation/places/web-service/session-tokens
     */
    this.sessionToken = uuidv4();
  }

  async getLocationsFromAddress(query: string): Promise<ApiResponse<RawResult[]>> {
    return await HttpClient.get(
      `${this.basePath}/search/?location=${encodeURIComponent(query)}&sessionToken=${
        this.sessionToken
      }`,
      true
    );
  }

  async getAddressFromCoordinates(
    coords: MapCoordinates,
    isCurrentLocation?: boolean
  ): Promise<ApiResponse<OpenStreetMapGeoLocationResponse>> {
    return await HttpClient.get(
      `${this.basePath}?lat=${coords.lat}&lon=${coords.lng}${
        isCurrentLocation ? '&currentLocation=true' : ''
      }`,
      true
    );
  }

  async getPlaceCoordinates(placeId: string): Promise<ApiResponse<RawResult>> {
    return await HttpClient.get(
      `${this.basePath}/${placeId}/coordinates?sessionToken=${this.sessionToken}`,
      true
    );
  }
}

export default new GeolocationService('/geocode', uuidv4());
