import BottomSheet from '../../commons/BottomSheet';
import { PDLTypography } from '../../Typography/PDLTypography';
import { makeStyles } from '@mui/styles';
import { CardForm } from '../../commons/CardForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { IAppTheme } from '../../../utils/AppTheme';
import { AppTypography } from '../../Typography/AppTypography';
import environment from '../../../environment.json';

interface Props {
  show: boolean;
  onClose: () => void;
  onNewPaymentMethod: (paymentMethod: string) => void;
}

const stripePromise = loadStripe(environment.stripeKey);

export const AddPaymentMethodModal: React.FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <>
      <BottomSheet open={props.show} onClose={() => props.onClose()} bottomSpace={'5em'}>
        <>
          <div className={classes.title}>
            <AppTypography type={PDLTypography.largeHeading}>New card information</AppTypography>
          </div>
          <div className={classes.title}>
            <AppTypography type={PDLTypography.paragraph}>
              Add a card to your account and save it as a payment method.
            </AppTypography>
          </div>
          <Elements stripe={stripePromise}>
            <CardForm
              containerStyle={{ padding: 0 }}
              buttonContainerClass={classes.buttonContainer}
              bottomSpace={'1.5em'}
              buttonText="Save"
              onNewPaymentMethod={props.onNewPaymentMethod}
            />
          </Elements>
        </>
      </BottomSheet>
    </>
  );
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  inputSpace: {
    marginBottom: '2em',
  },
  buttonContainer: {
    '& div': {
      padding: 0,
    },
  },
  title: {
    marginTop: '1em',
    marginBottom: '1em',
  },
}));
