export enum AppFontFamily {
  INTER = 'Inter',

  FREIGHT_TEXT_PRO = 'Baskerville',
  FREIGHT_TEXT_PRO_ITALIC = 'Baskerville Italic',

  SHAPE = 'Shape',
  SHAPE_BOLD = 'Shape Bold',
  SHAPE_SEMI_BOLD = 'Shape SemiBold',
  SHAPE_ITALIC = 'Shape Italic',
  SHAPE_MEDIUM = 'Shape Medium',
  SHAPE_LIGHT = 'Shape Light',
}
