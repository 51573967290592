import BottomSheet from './BottomSheet';
import { Bike } from '../../external/pdl-common/model/Bike';
import BikePartDetailList from '../../external/pdl-common/components/BikePartDetailList';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

interface PartDetailsBottomSheetProps {
  bike: Bike;
  open: boolean;
  onClose: () => void;
}

export default function PartDetailsBottomSheet(props: PartDetailsBottomSheetProps) {
  const { open, bike, onClose } = props;

  return (
    <BottomSheet open={open} onClose={onClose}>
      <AppTypography
        type={PDLTypography.largeHeading}
        children={'Part Details'}
        customStyles={{ marginBottom: '0.5em' }}
      />
      <br />
      <BikePartDetailList bike={bike} />
    </BottomSheet>
  );
}
