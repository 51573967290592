import { Avatar, Grid, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import AppTheme from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { makeStyles } from '@mui/styles';
import StringUtils from '../../utils/StringUtils';
import ChatUtils from '../../utils/ChatUtils';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { PdlChatChannel } from '../../model/PdlChatChannel';
import AuthService from '../../external/fox-typescript/services/AuthService';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { ChannelType } from '../../model/enums/ChannelType';
import pdlTeamImage from '../../assets/img/pdlTeamLogo.png';
import { isPDLTeamChannel } from '../../model/enums/ChannelType';

interface Props {
  pdlChatChannel: PdlChatChannel;
}

export default function InboxItem({ pdlChatChannel }: Props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  const { channel, reservation, reportIssue, channelType } = pdlChatChannel;
  const { user } = ChatUtils.getReceiverMember(channel);

  const isMessageUnread = (): boolean => {
    const lastAccessed: Date | undefined = ChatUtils.getLastAccessed(channel.members);

    if (lastAccessed === undefined) {
      return true;
    }

    return (
      channel.lastMessage.sender.user.externalId !== AuthService.getExternalId() &&
      DateUtils.isBeforeThan(lastAccessed, new Date(channel.lastMessage.notificationSentAt))
    );
  };

  const isReservationOrReportIssueChannel = (): boolean => {
    return channelType === ChannelType.RESERVATION || channelType === ChannelType.REPORT_ISSUE;
  };

  return (
    <div
      onClick={() =>
        appNavigate(
          navigate,
          AppRoutes.CHAT,
          { externalId: pdlChatChannel.externalId! },
          { state: { pdlChatChannel, receiver: user } }
        )
      }
      className={classes.div}
    >
      <Grid container item spacing={0} sm={true} justifyContent="space-evenly">
        <div style={{ marginRight: breakpoint ? 0 : '0.8em' }}>
          <UnreadBadge style={{ opacity: isMessageUnread() ? 1 : 0 }} />
        </div>
        <Grid item sx={{ mr: 1, mt: 1 }} sm={1} xs={0}>
          <Avatar style={{ height: '3em', width: '3em' }}>
            <AppImage
              alt={StringUtils.inboxShortUserName(user, pdlChatChannel.channelType)}
              src={isPDLTeamChannel(pdlChatChannel.channelType) ? pdlTeamImage : user.avatarUrl}
              style={{ width: '3em', height: '3em', objectFit: 'cover' }}
            />
          </Avatar>
        </Grid>
        <Grid item container xs={8} sm={true} sx={{ pt: '0.2em' }} rowSpacing={0}>
          <Grid item container justifyContent="space-between">
            <Grid item sm={8}>
              <AppTypography type={PDLTypography.smallHeading}>
                {StringUtils.inboxShortUserName(user, pdlChatChannel.channelType)}
              </AppTypography>
            </Grid>

            <Grid item sm={2} textAlign="right">
              <AppTypography
                type={PDLTypography.subHeadingSerif}
                customStyles={{ color: AppTheme.colors.gray_A8ACAD }}
              >
                {DateUtils.getTimeDistance(new Date(channel.lastMessage.createdAt))}
              </AppTypography>
            </Grid>
          </Grid>

          {isReservationOrReportIssueChannel() && (
            <Grid item xs={12}>
              <AppTypography
                type={PDLTypography.smallParagraph}
                customStyles={{ color: AppTheme.colors.gray_A8ACAD }}
              >
                {reservation ? reservation.bike.title : reportIssue.reservation.bike.title}
              </AppTypography>
            </Grid>
          )}

          <Grid item xs={12}>
            <AppTypography
              type={isMessageUnread() ? PDLTypography.subHeading : PDLTypography.smallParagraph}
              charLimit={59}
            >
              {isReservationOrReportIssueChannel()
                ? ChatUtils.replaceReservationDates(
                  channel.lastMessage.body,
                  reservation ? reservation : reportIssue.reservation
                )
                : channel.lastMessage.body}
            </AppTypography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const UnreadBadge = styled('div')({
  width: '0.5em',
  height: '0.5em',
  backgroundColor: AppTheme.colors.persianBlue_2238CB,
  borderRadius: '100%',
  position: 'relative',
  top: '50%',
  zIndex: 1,
});

const useStyles = makeStyles({
  div: {
    minHeight: '6em',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
