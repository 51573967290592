import { useState, useEffect } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import { ContentCard } from './ContentCard';
import Logger from '../../external/pdl-common/utils/Logger';
import { Content } from '../../model/Content';
import ContentService from '../../services/ContentService';
import { SxStyles } from '../../model/utils/SxStyles';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import AlertService from '../../services/AlertService';
import { CallToActionWithIcon } from '../Reservation/Details/Common/CallToActionWithIcon';
import { AppAssets } from '../../assets';
import { RequestStatus } from '../../utils/RequestStatus';
import { COLORS } from '../../utils/AppTheme';

const logger = new Logger('ContentList');

export const ContentList = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState<Content[]>([]);

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);

  useEffect(() => {
    getContentLibrary();
  }, []);

  const getContentLibrary = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await ContentService.getContentForHomePage())
      .onSuccess((response) => {
        setRequestStatus(RequestStatus.SUCCESS);
        setContent(response);
      })
      .onError((response) => {
        setRequestStatus(RequestStatus.ERROR);
        logger.error('Error trying to get articles/tutorials', response);
        AlertService.showSnackCustomError(response);
      });
  };

  return (
    <Container sx={styles.topDestinationContainer}>
      <Box sx={{ py: 4 }}>
        <AppTypography
          type={PDLTypography.largeHeading}
          children={'Articles and tutorials'}
          onClick={() => appNavigate(navigate, AppRoutes.CONTENT_LIBRARY, null)}
          customStyles={{ cursor: 'pointer' }}
        />
      </Box>

      <Box>
        {requestStatus === RequestStatus.LOADING ? (
          <Box sx={styles.loader}>
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Carousel
            itemsToShow={3}
            slidesToShow={{
              sm: 1,
            }}
            infinite={true}
            data={content}
            renderItem={(content, index) => (
              <ContentCard
                key={`${content.externalId}-${index}`}
                title={content.title}
                onClick={() =>
                  appNavigate(navigate, AppRoutes.CONTENT_LIBRARY_DETAILS, {
                    externalId: content.externalId!,
                  })
                }
                imageUrl={content.imageUrl}
              />
            )}
          />
        )}
      </Box>
      <CallToActionWithIcon
        text={'Full content library'}
        asset={<AppAssets.EyeIcon />}
        style={{ marginTop: '1em' }}
        onClick={() => appNavigate(navigate, AppRoutes.CONTENT_LIBRARY, null)}
      />
    </Container>
  );
};

const styles: SxStyles = {
  topDestinationContainer: {
    paddingBottom: 5,
    paddingRight: 0
  },
  loader: {
    color: COLORS.persianBlue_2238CB,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
  },
};
