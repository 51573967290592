import { makeStyles } from '@mui/styles';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { useState } from 'react';
import { AddPaymentMethodModal } from './Payment/AddPaymentMethodModal';
import { Grid } from '@mui/material';

interface Props {
  paymentMethod: string;
  onNewPaymentMethod: (paymentMethod: string) => void;
}

const userStyles = makeStyles({
  textButton: {
    textDecorationLine: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
  },
  margins: {
    marginBottom: '1.4em',
    marginTop: '1.4em',
  },
});

export const BikeReservationManagePayments: React.FunctionComponent<Props> = (props: Props) => {
  const classes = userStyles();
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const onNewPaymentMethod = (paymentMethod: string) => {
    setModalVisibility(false);
    props.onNewPaymentMethod(paymentMethod);
  };

  return (
    <>
      {props.paymentMethod ? (
        <>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={6} md={6}>
              <AppTypography type={PDLTypography.paragraph}>
                {`Card ending in ${props.paymentMethod}`}
              </AppTypography>
            </Grid>

            <Grid item xs={6} md={6}>
              <div onClick={() => setModalVisibility(true)}>
                <AppTypography type={PDLTypography.paragraph} customClasses={classes.textButton}>
                  Change
                </AppTypography>
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <div>
          <AppTypography
            type={PDLTypography.paragraph}
            customClasses={classes.textButton}
            onClick={() => setModalVisibility(true)}
          >
            Add a payment method
          </AppTypography>
        </div>
      )}
      <AddPaymentMethodModal
        show={modalVisibility}
        onClose={() => setModalVisibility(false)}
        onNewPaymentMethod={onNewPaymentMethod}
      />
    </>
  );
};
