import { Navigate, useLocation } from 'react-router-dom';
import { AppRoutes } from './utils/AppNavigation';
import environment from './environment.json';
import { useEffect } from 'react';

interface Props {
  isLogged: boolean;
  setPath: (state: string) => void;
  children: React.ReactElement<any, any>;
}

export default function PrivateRoute({ isLogged, setPath, children }: Props) {
  const pathName = useLocation().pathname;

  useEffect(() => {
    setPath(pathName);
  }, [pathName]);

  // @ts-ignore
  const navigateTo = environment.splashScreen ? (
    <Navigate to={AppRoutes.SIGN_IN} state={{ previousPath: pathName }} />
  ) : (
    <Navigate to={AppRoutes.WAITLIST_HOME} />
  );

  return isLogged ? children : navigateTo;
}
