import { MenuItem, SelectProps, TextFieldProps } from '@mui/material';
import { Control, Controller, UseControllerProps } from 'react-hook-form';
import AppSelect from './AppSelect';

type HookFormCustomTypes = {
  props: SelectProps;
  RightElement?: JSX.Element;
  children?: React.ReactNode;
  labelContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  includeOther?: boolean;
  selectOne?: boolean;
  options: { value: string | number; label: string | number }[];
};

interface UseControllerPropsWithCustomTypes extends UseControllerProps, HookFormCustomTypes {
  control: Control<any>;
}

const AppSelectHook = ({
  name,
  control,
  defaultValue,
  rules,
  options,
  props,
}: UseControllerPropsWithCustomTypes) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <AppSelect props={{ ...props, onChange, value }}>
          {options &&
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </AppSelect>
      )}
    />
  );
};

export default AppSelectHook;
