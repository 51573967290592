export enum Features {
  TOP_DESTINATIONS_SECTION = 'topDestinationsSection',
  INSURANCE = 'insuranceFeatures',
  IS_RENTER_INSURANCE_MANDATORY = 'isRenterInsuranceMandatory',
  IS_GOOGLE_MAP_AVAILABLE = 'isGoogleMapAvailable',
  IS_SHARE_ICON_AVAILABLE = 'isShareIconAvailable',
  IS_PORTAL_DETAIL_VIEW_AVAILABLE = 'isPortalDetailViewAvailable',
  IS_UTC_FORMAT_ENABLED = 'isUtcFormatEnabled',
  IS_CREDIT_CARD_MANDATORY_ENABLED = 'isCreditCardMandatoryEnabled',
}
