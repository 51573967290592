import { createTheme, Theme } from '@mui/material';

export interface IAppTheme extends Theme {
  colors: typeof COLORS;
}

const COLORS = {
  persianBlue_2238CB: '#2238CB',
  white_FFFFFF: '#ffffff',
  white_EEEEEC: '#EEEEEC',
  black_000000: '#000000',
  black_01000E: '#01000E',
  gray_01000E1A: '#01000E1A',
  gray_A8ACAD: '#A8ACAD',
  gray_E3E5E4: '#E3E5E4',
  gray_C9CBCA: '#C9CBCA',
  gray_E0E0E0: '#E0E0E0',
  red_FF0000: '#FF0000',
  green_008000: '#008000',
  light_green_CCE7C9: '#CCE7C9'
};
export { COLORS };

const AppTheme = createTheme({
  //@ts-ignore
  colors: COLORS,
}) as IAppTheme;

export default AppTheme;
