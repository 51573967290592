import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { AppAssets } from '../../assets';
import { AppTextField } from '../commons/AppTextField';
import SimpleButton from '../commons/SimpleButton';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import { COLORS, IAppTheme } from '../../utils/AppTheme';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { PDLTypography } from '../Typography/PDLTypography';
import { SxStyles } from '../../model/utils/SxStyles';
import UserService from '../../services/UserService';
import { RequestStatus } from '../../utils/RequestStatus';
import Logger from '../../external/pdl-common/utils/Logger';
import AlertService from '../../services/AlertService';
import { AppTypography } from '../Typography/AppTypography';
import { useForm } from '../../hooks/useForm';
import { WelcomeToPdlClub } from '../commons/WelcomeToPdlClub';
import { FooterPdlClub } from '../commons/FooterPdlClub';
import TopNavBar from '../core/LandingPageNavBar';

const logger = new Logger('InviteCode');

interface InviteCodeForm {
  inviteCode: string;
}

export default function InviteCode() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [form, validations, handleCustomChange, formIsValid] = useForm<InviteCodeForm>({
    inviteCode: '',
  });
  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const response = await UserService.getByInviteCode(form.inviteCode);

      setRequestStatus(RequestStatus.SUCCESS);

      appNavigate(navigate, AppRoutes.CREATE_PROFILE, null, {
        state: { referral: response.getContent(), inviteCode: form.inviteCode },
      });
    } catch (error: any) {
      setRequestStatus(RequestStatus.ERROR);
      logger.error('Error trying to get pedal user by invite code', error);

      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }
    }
  };

  return (
    <Box sx={{ backgroundColor: COLORS.black_000000 }}>
      <TopNavBar showBackButton />
      <WelcomeToPdlClub />
      <Grid container spacing={8} columns={1} sx={muiStyle.gridContainer}>
        <Grid item xs={12} sm={6} sx={muiStyle.gridHeader}>
          <AppAssets.PDL_CLUB fill="black" />
          <AppTypography type={PDLTypography.largeHeading} customClasses={classes.title}>
            Become a community member
          </AppTypography>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ marginTop: '-1.87em' }}>
          <Grid>
            <AppTextField
              label={'Enter Your Invite Code'}
              name={'inviteCode'}
              value={form.inviteCode}
              onInputChange={handleCustomChange}
              inputProps={{ sx: muiStyle.input }}
              labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
              required
              realTimeValidation
              minLength={6}
              restrict={'onlyNumbersAndLetters'}
            />
          </Grid>
          <Grid sx={{ textAlign: 'center', marginTop: '1.2em' }}>
            <AppTypography
              type={PDLTypography.subHeading}
              customClasses={classes.waitListLink}
              onClick={() => appNavigate(navigate, AppRoutes.WAITLIST_JOIN, null)}
            >
              I don't have an invite code
            </AppTypography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} sx={muiStyle.gridBottom}>
          <div>
            <SimpleButton
              sx={{ marginBottom: '3em', marginTop: '2em', width: '37vh', textAlign: 'center' }}
              children={'Submit'}
              onClick={onSubmit}
              isLoading={requestStatus === RequestStatus.LOADING}
              disabled={requestStatus === RequestStatus.LOADING || !formIsValid()}
            />
          </div>
          <div style={{ marginTop: '1.2em' }}>
            <AppTypography
              type={PDLTypography.smallParagraph}
              customClasses={classes.signInLink}
              onClick={() => appNavigate(navigate, AppRoutes.SIGN_IN, null)}
              children={'Already a member?  Sign In'}
            />
          </div>
        </Grid>
      </Grid>
      <FooterPdlClub dense />
    </Box>
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  title: {
    textAlign: 'center',
    marginTop: '1.7em',
    color: COLORS.white_FFFFFF,
  },
  waitListLink: {
    color: COLORS.white_FFFFFF,
    cursor: 'pointer',
    userSelect: 'none',
  },
  signInLink: {
    color: COLORS.white_FFFFFF,
    cursor: 'pointer',
    fontSize: '1em',
    textAlign: 'center',
    marginTop: '0',
    marginBottom: '5em',
  },
  label: {
    color: COLORS.white_FFFFFF,
    marginBottom: '0.5em',
    fontSize: '.813em',
  },
}));

const muiStyle = {
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridBottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '2em',
  },
  input: {
    '.MuiInputBase-input': {
      backgroundColor: COLORS.white_FFFFFF,
      width: '34vh',
      height: '3vh',
    },
  },
} as SxStyles;
