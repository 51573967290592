import AuthService from '../../fox-typescript/services/AuthService';
import { ChatChannel } from '../model/ChatChannel';
import { ChatChannelMembership } from '../model/ChatChannelMembership';
import Reservation from '../model/Reservation';
import DateUtils from './DateUtils';

class ChatUtils {
  public getReceiverMember = (
    members: ChatChannelMembership[]
  ): ChatChannelMembership | undefined => {
    return members.find((m) => {
      if (m.user.externalId === AuthService.getUser().externalId) {
        return m;
      }
      return undefined;
    });
  };

  public getLastAccessed = (members: ChatChannelMembership[]): Date => {
    const member = this.getReceiverMember(members);
    return member ? new Date(member.lastAccessed) : new Date();
  };

  public replaceStringReservationDates = (message: string, rentalDates: string): string => {
    return message.replace('$DATE1 - $DATE2', rentalDates);
  };

  public replaceReservationDates = (message: string, reservation: Reservation): string => {
    const rentalDates = DateUtils.formattedRangeDates(
      new Date(reservation.startDate),
      new Date(reservation.endDate)
    );

    return this.replaceStringReservationDates(message, rentalDates);
  };

  public limitChars = (text: string, charLimit: number): string => {
    return text.length > charLimit ? `${text.slice(0, charLimit)}...` : text;
  };
}

export default new ChatUtils();
