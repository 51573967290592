import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import AlertService from '../../services/AlertService';
import StripeService from '../../services/StripeService';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { IAppTheme } from '../../utils/AppTheme';
import { RequestStatus } from '../../utils/RequestStatus';
import { AppTypography, AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTextField } from './AppTextField';
import SimpleButton from './SimpleButton';

interface Props {
  containerStyle?: React.CSSProperties;
  buttonContainerClass?: string;
  bottomSpace?: number | string;
  buttonText: string;
  onNewPaymentMethod: (paymentMethod: string) => void;
}

const CARD_INPUT_OPTIONS = {
  style: {
    base: {
      lineHeight: '27px',
      color: '#212529',
      fontSize: '1em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
  },
};

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  cardInput: {
    padding: '.375rem .75rem',
    fontSize: '1rem',
    color: '#212529',
    backgroundColor: '#fff',
    border: '1px solid #C9CBCA',
    borderRadius: 4,
    fontFamily: AppFontFamily.SHAPE + ' !important',
  },
  textFieldContainer: {
    marginTop: '0.625em',
  },
}));

export function CardForm(props: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);
  const [countryCode] = useState<string>(localStorage.getItem('country_code') || 'US');

  const [validations, setValidations] = useState({
    cardNumber: false,
    expiration: false,
    cvv: false,
  });

  const handleSubmit = async () => {
    setRequestStatus(RequestStatus.LOADING);

    try {
      const card = elements!.getElement(CardNumberElement)!;
      const { error, token } = await stripe!.createToken(card);

      if (error) {
        error.message
          ? AlertService.showSnackCustomError(error?.message)
          : AlertService.showSnackInternalError();
      } else {
        if (!token || !token.card) {
          AlertService.showSnackInternalError();
          return;
        }

        await StripeService.createCard(token.id);
        
        card.clear();
        elements!.getElement(CardExpiryElement)!.clear();
        elements!.getElement(CardCvcElement)!.clear();
        setValidations({ cardNumber: false, expiration: false, cvv: false});
        AlertService.showSuccessMessage('Payment details updated successfully');
        props.onNewPaymentMethod(token.card.last4);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }
    } finally {
      setRequestStatus(RequestStatus.SUCCESS);
    }
  };

  return (
    <>
      <Container style={{ display: 'flex', flexDirection: 'column', ...props.containerStyle }}>
        <AppTypography
          type={PDLTypography.subHeading}
          customStyles={{
            marginBottom: '0.7em',
            marginTop: '1.5em',
          }}
        >
          Card number
        </AppTypography>
        <CardNumberElement
          id="cc-number"
          className={classes.cardInput}
          options={CARD_INPUT_OPTIONS}
          onChange={(e) => {
            setValidations({ ...validations, cardNumber: e.complete });
          }}
        />

        <AppTypography
          type={PDLTypography.subHeading}
          customStyles={{
            marginBottom: '0.7em',
            marginTop: '1.5em',
          }}
        >
          Expiration
        </AppTypography>
        <CardExpiryElement
          id="expiry"
          className={classes.cardInput}
          options={CARD_INPUT_OPTIONS}
          onChange={(e) => {
            setValidations({ ...validations, expiration: e.complete });
          }}
        />

        <AppTypography
          type={PDLTypography.subHeading}
          customStyles={{
            marginBottom: '0.7em',
            marginTop: '1.5em',
          }}
        >
          CVV
        </AppTypography>
        <CardCvcElement
          id="cvc"
          className={classes.cardInput}
          options={{ ...CARD_INPUT_OPTIONS, placeholder: 'CVV' }}
          onChange={(e) => {
            setValidations({ ...validations, cvv: e.complete });
          }}
        />
        <div style={{ height: props.bottomSpace ? props.bottomSpace : '7em' }} />
      </Container>

      <div className={props.buttonContainerClass}>
        <Container>
          <SimpleButton
            onClick={handleSubmit}
            disabled={
              !stripe ||
              !elements ||
              Object.values(validations).includes(false) ||
              requestStatus == RequestStatus.LOADING
            }
            isLoading={requestStatus == RequestStatus.LOADING}
          >
            {props.buttonText}
          </SimpleButton>
        </Container>
      </div>
    </>
  );
}
