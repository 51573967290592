import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import { FullUser } from '../external/pdl-common/model/FullUser';
import { PedalUser } from '../external/pdl-common/model/PedalUser';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import environment from '../environment.json';
import { MyMeasurementsForm } from '../components/Account/MyMeasurements';
import { UserAdditionalInfo } from '../components/views/CompleteAdditionalInformation';
import { UserAdditionalInfoCheck } from '../model/UserAdditionalInfoCheck';
import {AdditionalPersonalInformation} from "../external/pdl-common/model/AdditionalPersonalInformation";

class UserService extends GenericCrudService<FullUser> {
  getAll(page: number, pageSize: number): Promise<ApiResponse<PagedResult<FullUser>>> {
    throw new Error('Method not implemented.');
  }
  create(instance: PedalUser): Promise<ApiResponse<FullUser>> {
    throw new Error('Method not implemented.');
  }

  updatePersonalInformation(form: FullUser): Promise<ApiResponse<FullUser>> {
    return HttpClient.put(`${this.basePath}/personal-information/`, form, true);
  }

  delete(instance: PedalUser): Promise<ApiResponse<FullUser>> {
    throw new Error('Method not implemented.');
  }

  getCurrentUser(): Promise<ApiResponse<FullUser>> {
    return HttpClient.get(`${this.basePath}/current-user`, true);
  }

  getByInviteCode = (code: string): Promise<ApiResponse<PedalUser>> => {
    return HttpClient.get(`${this.basePath}/invite-code/${code}`);
  };

  updateMeasurements(form: MyMeasurementsForm): Promise<ApiResponse<FullUser>> {
    return HttpClient.put(`${this.basePath}/measurements`, form);
  }

  updateAvatar(avatarExternalId: string): Promise<ApiResponse<String>> {
    return HttpClient.put(`${this.basePath}/avatar/${avatarExternalId}`, {}, true);
  }

  checkUserAdditionalInfo(): Promise<ApiResponse<UserAdditionalInfoCheck>> {
    return HttpClient.get(`${this.basePath}/additional-information/check`, true);
  }

  completeUserAdditionalInfo(form: UserAdditionalInfo): Promise<ApiResponse<string>> {
    return HttpClient.put(`${this.basePath}/additional-information`, form, true);
  }

  getOptOffSerachDialog(): Promise<ApiResponse<boolean>> {
    return HttpClient.get(`${this.basePath}/opt-off-alert-dialog/`);
  }

  updateSearchDialogPreference(): Promise<ApiResponse<string>> {
    return HttpClient.put(`${this.basePath}/opt-off-alert-dialog`, {});
  }

  getTermsAndConditionsAgreed(): Promise<ApiResponse<boolean>> {
    return HttpClient.get(`${this.basePath}/terms-and-conditions-agreed/`);
  }

  updateTermsAndConditionAgreed(): Promise<ApiResponse<string>> {
    return HttpClient.put(`${this.basePath}/terms-and-conditions-agreed`, {});
  }

  getAdditionalPersonalInformation(): Promise<ApiResponse<AdditionalPersonalInformation>> {
    return HttpClient.get(`${this.basePath}/additional-personal-information`);
  }

  getTemporaryAdditionalPersonalInformation(): Promise<ApiResponse<AdditionalPersonalInformation>> {
    return HttpClient.get(`${this.basePath}/temporary-additional-personal-information`);
  }

}

export default new UserService(`${environment.baseURL}/pdl-user`);
