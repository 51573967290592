import { makeStyles } from '@mui/styles';
import React from 'react';
import ChatUtils from '../../external/pdl-common/utils/ChatUtils';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { IAppTheme } from '../../utils/AppTheme';
import { PDLTypography } from './PDLTypography';

export interface AppTypographyProps {
  id?: string;
  type: PDLTypography;
  customClasses?: string;
  customStyles?: React.CSSProperties;
  charLimit?: number;
  onClick?: () => void;
}

export const AppTypography: React.FunctionComponent<AppTypographyProps> = (props) => {
  const { id, customClasses, customStyles } = props;
  const appClasses = useStyles();

  return (
    <span
      id={id}
      className={`${appClasses[props.type]} ${customClasses ? customClasses : ''}`}
      style={customStyles}
      onClick={props.onClick}
    >
      {props.charLimit ? ChatUtils.limitChars(props.children?.toString()!, props.charLimit) : props.children}
    </span>
  );
};

const useStyles = makeStyles<IAppTheme, {}, PDLTypography>((theme) => ({
  largeHeading: {
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontSize: '1.5em',
    lineHeight: '120%',
    color: theme.colors.black_01000E,
    fontWeight: '700',
  },
  mediumHeading: {
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontSize: '1.2em',
    lineHeight: '120%',
    color: theme.colors.black_01000E,
    fontWeight: '700',
  },
  smallHeading: {
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontSize: '1em',
    lineHeight: '120%',
    color: theme.colors.black_01000E,
    fontWeight: '700',
  },
  subHeading: {
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontSize: '0.8em',
    lineHeight: '120%',
    color: theme.colors.black_01000E,
    fontWeight: '700',
  },
  subHeadingSerif: {
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC,
    fontSize: '0.9em',
    lineHeight: '120%',
    color: theme.colors.black_01000E,
    fontWeight: '400',
    textAlign: 'center',
  },
  paragraph: {
    fontFamily: AppFontFamily.SHAPE,
    fontSize: '1em',
    lineHeight: '140%',
    color: theme.colors.black_01000E,
    fontWeight: '400',
  },
  smallParagraph: {
    fontFamily: AppFontFamily.SHAPE,
    fontSize: '0.8em',
    lineHeight: '140%',
    color: theme.colors.black_01000E,
    fontWeight: '400',
  },
  callToAction: {
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontSize: '1em',
    lineHeight: '140%',
    color: theme.colors.persianBlue_2238CB,
    fontWeight: '700',
  },
  labelsSmallSerif: {
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC,
    fontWeight: 400,
    fontSize: '0.87em',
  },
  none: {},
}));
