import StarIcon from '@mui/icons-material/Star';
import { makeStyles } from '@mui/styles';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';

interface Props {
  avgStars: string | number;
  totalRides: number;
  textStyles?: React.CSSProperties;
  textClassName?: string;
}

const TotalRides = ({ avgStars, totalRides, textStyles, textClassName = '' }: Props) => {
  const classes = useStyles();

  return (
    <p className={`${classes.text} ${textClassName}`} style={textStyles}>
      {avgStars}
      <StarIcon sx={muiStyles.smallStarIcon} />({totalRides} rides)
    </p>
  );
};

export default TotalRides;

const muiStyles = {
  smallStarIcon: {
    height: '0.625em',
    color: AppTheme.colors.persianBlue_2238CB,
  },
};

const useStyles = makeStyles({
  text: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: AppFontFamily.INTER,
    fontSize: '0.8em',
  },
});
