import { Avatar, Box } from '@mui/material';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { COLORS } from '../../utils/AppTheme';

interface Props {
  size?: string;
  avatar: string;
  name: string;
  position: string;
  mt?: string;
}

export function AboutCard(props: Props) {
  return (
    <Box
      sx={{
        borderRadius: '2.5em 2.5em 2.5em 2.5em',
        border: 'solid black 2px',
        backgroundColor: COLORS.white_FFFFFF,
        width: props.size || '12.5em',
        height: props.size || '12.5em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '2.5em',
        marginTop: props.mt || '0',
      }}
    >
      <Avatar sx={{ width: '40%', height: '40%' }}>
        <AppImage src={props.avatar} alt={props.name} />
      </Avatar>
      <AppTypography
        type={PDLTypography.smallHeading}
        children={props.name}
        customStyles={{
          color: COLORS.black_000000,
          textAlign: 'center',
          paddingTop: '10%',
          fontSize: '130%',
        }}
      />
      <AppTypography
        type={PDLTypography.smallParagraph}
        children={props.position}
        customStyles={{
          color: COLORS.black_000000,
          textAlign: 'center',
          paddingTop: '10%',
          fontSize: '95%',
          fontStyle: 'Book Italic',
        }}
      />
    </Box>
  );
}
