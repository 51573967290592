import { Avatar, Tooltip, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppAssets } from '../../assets';
import { FullUser } from '../../external/pdl-common/model/FullUser';
import StringUtils from '../../utils/StringUtils';
import TotalRides from '../commons/TotalRides';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { AppRoutes, appNavigate } from '../../utils/AppNavigation';
import { useNavigate } from 'react-router-dom';

interface Props {
    user: FullUser;
    hostInfo?: boolean;
    tooltip?: boolean;
}

const StarsAndRides = ({ user, hostInfo, tooltip }: Props) => {
    const navigate = useNavigate();
    const classes = useStyles();

    return (
        <div className={classes.userInfoContainer}>
            {tooltip ?
                <Tooltip title={'Update Profile Picture'}>
                    <Button
                        onClick={() => {
                            appNavigate(navigate, AppRoutes.EDIT_AVATAR, null);
                        }}
                    >
                        <Avatar sx={{ width: '6.25em', height: '6.25em' }}>
                            <AppImage
                                src={user.user.avatarUrl}
                                alt={StringUtils.formatShortUserName(user.user)}
                                style={{ width: '6.25em', height: '6.25em', objectFit: 'cover' }}
                            />
                        </Avatar>
                    </Button>
                </Tooltip>
                :
                <Avatar sx={{ width: '6.25em', height: '6.25em' }}>
                    <AppImage
                        src={user.user.avatarUrl}
                        alt={StringUtils.formatShortUserName(user.user)}
                        style={{ width: '6.25em', height: '6.25em', objectFit: 'cover' }}
                    />
                </Avatar>
            }
            <div className={classes.userInfoTextContainer}>
                <AppTypography
                    type={PDLTypography.largeHeading}
                    customClasses={classes.userName}
                    children={StringUtils.formatShortUserName(user.user)}
                />
                <TotalRides
                    avgStars={hostInfo ? Math.round(user.avgRatingAsHost!) || 0 : Math.round(user.avgRating!) || 0}
                    totalRides={hostInfo ? user.totalRidesAsHost || 0 : user.totalRides || 0}
                    textStyles={{ margin: 0 }}
                />
                {user.isVerified && (
                    <div className={classes.verifiedContainer}>
                        <AppAssets.Checkmark className={classes.verifiedIcon} />
                        <AppTypography type={PDLTypography.paragraph} children={'Identity Verified'} />
                    </div>
                )}
            </div>
        </div>
    );
}
export default StarsAndRides;
const useStyles = makeStyles({
    userName: {
        margin: 0,
        marginTop: '0.625em',
        marginBottom: '0.313em',
    },
    userInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '1.875em',
        paddingBottom: '1.875em',
    },
    userInfoTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    verifiedContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '0.938em',
    },
    verifiedIcon: {
        marginRight: '0.625em',
        height: '0.938em',
        width: '0.938em',
    },
    list: {
        position: 'relative',
        width: '100%',
        overflow: 'scroll',
        height: '95%',
    },
});
