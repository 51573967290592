import Button from '@mui/material/Button';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { Box, ButtonGroup } from '@mui/material';
import { COLORS } from '../../external/pdl-common/utils/AppTheme';

interface DoubleButtonsProps {
  leftBtnLabel: string;
  rigthBtnLabel: string;
  onLeftClick: () => void;
  onRightClick: () => void;
  separatedButtons?: boolean;
}

export default function DoubleButtons({
  leftBtnLabel,
  rigthBtnLabel,
  onLeftClick,
  onRightClick,
  separatedButtons,
}: DoubleButtonsProps) {
  return separatedButtons ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Button sx={styles.button} onClick={onLeftClick}>
        {leftBtnLabel}
      </Button>
      <Button sx={{ ...styles.button, marginTop: '.75em' }} onClick={onRightClick}>
        {rigthBtnLabel}
      </Button>
    </Box>
  ) : (
    <ButtonGroup variant="contained" disableElevation>
      <Button sx={styles.button} onClick={onLeftClick}>
        {leftBtnLabel}
      </Button>
      <Button sx={styles.button} onClick={onRightClick}>
        {rigthBtnLabel}
      </Button>
    </ButtonGroup>
  );
}

const styles = {
  button: {
    backgroundColor: COLORS.persianBlue_2238CB,
    borderColor: 'white !important',
    color: COLORS.white_FFFFFF,
    px: 5,
    minHeight: '4em',
    borderRadius: '2em',
    fontSize: '.9em',
    fontFamily: AppFontFamily.SHAPE_BOLD,
    '&': {
      textTransform: 'none',
    },
    '&:hover': {
      color: COLORS.persianBlue_2238CB,
      backgroundColor: COLORS.white_FFFFFF,
    },
  },
};
