import { makeStyles } from '@mui/styles';
import { useRef } from 'react';
import { AppAssets } from '../../assets';
import AppTheme from '../../utils/AppTheme';
import { AppTextField } from '../commons/AppTextField';
import { ButtonBase, Container, InputAdornment } from '@mui/material';
import { RequestStatus } from '../../utils/RequestStatus';

const SHOW_IMAGE_UPLOAD_ICON = false;

interface Props {
  message: string;
  setMessage: (data: string) => void;
  sendMessage: (data: string) => void;
  submitStatus?: RequestStatus;
  closed?: boolean;
}

const ChatInput = ({ message, setMessage, sendMessage, submitStatus, closed }: Props) => {
  const classes = useStyles();
  const imageInputRef = useRef(null);

  const isButtonDisabled = (): boolean =>
    submitStatus === RequestStatus.LOADING || message.split(' ').join('').length === 0;

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      !isButtonDisabled() && sendMessage(message);
    }
  };

  const getSubmitIcon = () => {
    return (
      <ButtonBase onClick={() => sendMessage(message)} disabled={isButtonDisabled()}>
        <InputAdornment position="end">
          <AppAssets.PaperPlane
            fill={
              isButtonDisabled() ? AppTheme.colors.gray_A8ACAD : AppTheme.colors.persianBlue_2238CB
            }
          />
        </InputAdornment>
      </ButtonBase>
    );
  };

  return (
    <Container className={classes.mainContainer}>
      {SHOW_IMAGE_UPLOAD_ICON && (
        <ButtonBase
          onClick={() => {
            // @ts-ignore valid prop
            imageInputRef.current.click();
          }}
        >
          <AppAssets.ImageIcon style={{ marginRight: '0.9em' }} />
          <input type="file" accept="image/*" hidden onChange={() => { }} ref={imageInputRef} />
        </ButtonBase>
      )}
      <AppTextField
        submitIcon={getSubmitIcon()}
        name={'message'}
        value={message}
        onInputChange={(name, val) => setMessage(val)}
        inputProps={{
          placeholder: closed ? "Closed" : 'Write a message...',
          sx: { '& .MuiInputBase-input': { borderRadius: '3.125em' } },
        }}
        onKeyPress={(e) => onKeyDown(e)}
        disabled={closed}
      />
    </Container>
  );
};

export default ChatInput;

const useStyles = makeStyles({
  mainContainer: {
    marginTop: '1em',
    borderTop: `0.063em solid ${AppTheme.colors.gray_E3E5E4}`,
    display: 'flex !important',
    flexDirection: 'row',
    padding: '1em 5%',
  },
});
