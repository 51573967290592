import ReservationService from '../../services/ReservationService';
import {ReservationDropOffHostConfirmation} from "../commons/ReservationDropOffHostConfirmation";

export function ReservationDropOffConfirmation() {
    const handleOnConfirm = async (reservationExternalId: string, checkListExternalId: string) =>
        await ReservationService.dropOff(reservationExternalId, checkListExternalId);

    const handleOnCancel = async (reservationExternalId: string, checkListExternalId: string) =>
        await ReservationService.dropOff(reservationExternalId, checkListExternalId);

    return <ReservationDropOffHostConfirmation confirmationType="drop-off" onConfirm={handleOnConfirm} onCancel={handleOnCancel}/>;
}
