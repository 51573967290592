import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import { WaitlistUser } from '../model/WaitlistUser';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import environment from '../environment.json';
import { WaitListUserForm } from '../types/WaitListUserForm';

class WaitlistService extends GenericCrudService<WaitlistUser> {
  create(instance: WaitListUserForm): Promise<ApiResponse<WaitlistUser>> {
    return HttpClient.post(`${this.basePath}/create`, instance);
  }
}

export default new WaitlistService(`${environment.baseURL}/account-wait-list`);
