import { ButtonBase, CircularProgress, InputAdornment } from '@mui/material';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import { useRef, useState } from 'react';
import { AppAssets } from '../../assets';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../external/pdl-common/utils/RequestStatus';
import AlertService from '../../services/AlertService';
import GeolocationService from '../../services/GeolocationService';
import { Debouncer } from '../../utils/Debouncer';
import SearchResults from '../Map/SearchResults';
import { AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTextField } from './AppTextField';

const ALLOWED_COUNTRIES = ['US'];

interface Props {
  handleCustomChange: (name: string, value: string, isValid: boolean) => void;
  disabled?: boolean;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
  countryCode: string;
  setForm?: (updatedForm: {}) => void;
  setValidations?: (updatedValidations: {}) => void;
  editMode?: boolean;
  displayPhoneNumberField?: boolean;
  USOnly?: boolean; // refactor this
}

interface DisabledFields {
  city: boolean;
  stateCode: boolean;
  zipCode: boolean;
  countryCode: boolean;
}

const logger = new Logger('UserAdditionalInformation');

export default function UserAdditionalInformation(props: Props) {
  const { handleCustomChange, disabled } = props;

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [results, setResults] = useState<RawResult[]>([]);
  const [showSearchContainer, setShowSearchContainer] = useState<boolean>(false);
  const [searchLocationInput, setSearchLocationInput] = useState<string>('');
  const [disabledFields, setDisabledFields] = useState<DisabledFields>({
    countryCode: props.editMode || false,
    zipCode: props.editMode || false,
    city: props.editMode || false,
    stateCode: props.editMode || false,
  });

  // Field refs: used to validate fields
  const cityRef = useRef<{ validate: () => boolean }>(null);
  const zipCodeRef = useRef<{ validate: () => boolean }>(null);
  const stateCodeRef = useRef<{ validate: () => boolean }>(null);

  const searchLocation = async () => {
      setRequestStatus(RequestStatus.LOADING);

    (await GeolocationService.getLocationsFromAddress(searchLocationInput))
      .onSuccess((response) => {
        setShowSearchContainer(true);
        setResults(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error searching location', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getAddressFromCoordinates = async (lat: number, lng: number, houseNumber: string) => {
    setRequestStatus(RequestStatus.LOADING);

    (await GeolocationService.getAddressFromCoordinates({ lat, lng }, true))
      .onSuccess((response) => {
        const { address } = response.getContent();
          console.log(address);

          /* We won't be limiting country codes for now
          if (!ALLOWED_COUNTRIES.includes(address.country_code.toUpperCase())) {
            AlertService.showSnackCustomError(
              `At the moment, the app is limited to ${ALLOWED_COUNTRIES} users only`
            );
            setRequestStatus(RequestStatus.ERROR);

            return;
          }
           */

          if (props.setForm) {
            props.setForm({
            streetAddress: houseNumber, // Watch out for this, google changes the way they return the address, so this might not work in the future, eg: previously it was the house number, and now it's the street name and house number
            city: address.city || address.town || '',
            stateCode: (address.state_code || address.state) || '',
            zipCode: address.postcode || '',
            countryCode: address.country_code.toUpperCase(),
          });

          // Validate the fields and disable the inputs (if they are valid) so that the user cannot change them
          setDisabledFields((current) => {
            return {
              ...current,
              city: !!cityRef.current?.validate(),
              stateCode: !!stateCodeRef.current?.validate(),
              zipCode: !!zipCodeRef.current?.validate(),
            };
          });

          // Update the validations
          if (props.setValidations) {
            props.setValidations({
              streetAddress: !!address.road,
              city: !!cityRef.current?.validate(),
              stateCode: address.country_code == 'us' ? !!stateCodeRef.current?.validate() : true,
              zipCode:  !!zipCodeRef.current?.validate(),
              countryCode: props.USOnly ? address.country_code.trim().toUpperCase()  == 'US' : !!address.country_code,
            });
          }
        }
          if (props.USOnly && address.country_code.trim().toUpperCase() != 'US') {
            AlertService.showSnackCustomError(
              `Renter temporary address must be in the US`
            );
            setRequestStatus(RequestStatus.ERROR);
          }

        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error getting address from coordinates', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const onClickLocation = async (result: RawResult) => {
      let houseNumber = result.display_name.split(',')[0];
      if (!result.lat || !result.lon) {
          setRequestStatus(RequestStatus.LOADING);

          (await GeolocationService.getPlaceCoordinates(result.place_id))
            .onSuccess((response) => {
              const { lat, lon } = response.getContent();

              result.lat = lat;
              result.lon = lon;

              setRequestStatus(RequestStatus.SUCCESS);
            })
            .onError((response) => {
              logger.error('Error getting place coordinates', response.getContent());
              AlertService.showSnackCustomError(response.getContent());
              setRequestStatus(RequestStatus.ERROR);
            });
    }

    await getAddressFromCoordinates(+result.lat, +result.lon, houseNumber);
  };

  const getSubmitIcon = () => {
    return (
      <ButtonBase
        onClick={() => {
          if (requestStatus === RequestStatus.LOADING) return;

          if (searchLocationInput.length > 0) {
            Debouncer.debounce('searchLocation', searchLocation, 700);
          } else {
            setShowSearchContainer(false);
          }
        }}
      >
        <InputAdornment position="end">
          {requestStatus === RequestStatus.LOADING ? (
            <CircularProgress size={25} />
          ) : (
            <AppAssets.SearchIcon />
          )}
        </InputAdornment>
      </ButtonBase>
    );
  };

  return (
    <>
        {props.displayPhoneNumberField && (
          <AppTextField
            label={'Phone Number'}
            name={'phoneNumber'}
            value={props.phoneNumber}
            onInputChange={handleCustomChange}
            inputProps={{ disabled }}
            labelProps={labelProps}
            required
            realTimeValidation
            restrict="phoneNumber"
          />
        )}
      <AppTextField
        label={'Street Address'}
        name={'streetAddress'}
        value={props.streetAddress}
        onInputChange={(inputName: string, inputValue: string, isValid: boolean) => {
          setSearchLocationInput(inputValue);
          handleCustomChange(inputName, inputValue, !isValid);
          if (props.setValidations) {
            props.setValidations({ streetAddress: !!inputValue });
          }
        }}
        inputProps={{ disabled }}
        labelProps={labelProps}
        realTimeValidation
        required
        submitIcon={getSubmitIcon()}
      />

      {showSearchContainer && (
        <>
          {results.length !== 0 ? (
            <SearchResults
              results={results}
              onClick={(result) => {
                setShowSearchContainer(false);
                onClickLocation(result);
              }}
              isLoading={requestStatus === RequestStatus.LOADING}
            />
          ) : (
            <AppTypography type={PDLTypography.smallParagraph} customStyles={{ color: 'red' }}>
              Insert a street address and select a valid one from the list
            </AppTypography>
          )}
        </>
      )}

      <AppTextField
        ref={cityRef}
        label={'City'}
        name={'city'}
        value={props.city}
        onInputChange={handleCustomChange}
        inputProps={{ type: 'text', disabled: disabled || disabledFields.city }}
        labelProps={labelProps}
        restrict={'onlyLettersAndSpecialCharacters'}
        realTimeValidation
        required
      />

      <AppTextField
        ref={stateCodeRef}
        label={'State Code'}
        name={'stateCode'}
        value={props.stateCode}
        onInputChange={handleCustomChange}
        inputProps={{ type: 'text', disabled: disabled || disabledFields.stateCode }}
        labelProps={labelProps}
        realTimeValidation
        //restrict={'stateCode'}
      />

      <AppTextField
        ref={zipCodeRef}
        label={'Zip Code'}
        name={'zipCode'}
        value={props.zipCode}
        onInputChange={handleCustomChange}
        inputProps={{ type: 'text', disabled: disabled || disabledFields.zipCode }}
        labelProps={labelProps}
        required
        realTimeValidation
      />

      <AppTextField
        label={'Country Code'}
        name={'countryCode'}
        value={props.countryCode!}
        onInputChange={handleCustomChange}
        inputProps={{ type: 'text', disabled: disabled || disabledFields.countryCode }}
        labelProps={labelProps}
        customValidation={(value) => {
            // This ended up being unnecessary
            console.log(value);
            console.log(props.USOnly);
            console.log(value?.trim() !== 'US');
            if ((props.USOnly) && value && (value.trim() !== 'US')) {
                return {
                    showError: true,
                    isValid: false,
                    message: 'Renter temporary address must be in the US',
                };
            }
            return {showError: false, isValid: true, message: ''};
        }}
        required
      />
    </>
  );
}

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: { marginBottom: '0.5em', marginTop: '1.4em' },
};
