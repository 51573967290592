import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { SxStyles } from '../../model/utils/SxStyles';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';
import DiscountIcon from '@mui/icons-material/Discount';

interface Props {
  price: string | number;
  byDay?: boolean;
  finalPrice?: number;
  direction?: 'row' | 'column';
  customPrice?: number;
  discountIcon?: boolean
}

const Price = ({ price, byDay = true, finalPrice, direction = 'row', customPrice, discountIcon }: Props) => {
  return (
    <Grid container display={'flex'} alignItems={'left'} flexDirection={direction} width={'100%'}>
      <Grid item xs={direction === 'column' ? 12 : 6}>
        {customPrice === price || customPrice === undefined ?
          (<Box display={'flex'} alignItems={'center'} width={'100%'}>
            {discountIcon && <Tooltip title='Multi day discount' placement='bottom-end'>
              <DiscountIcon color={'success'} />
            </Tooltip>}
            <Typography sx={styles.priceItem}>{`$${price}`}</Typography>
            <Typography fontFamily={AppFontFamily.FREIGHT_TEXT_PRO_ITALIC} fontSize={'0.85em'}>
              {byDay ? '/day' : ''}
            </Typography>
          </Box>) :
          (<Box display={'flex'} alignItems={'center'} width={'100%'}>
            {discountIcon && <Tooltip title='Multi day discount' placement='bottom-end'>
              <DiscountIcon color={'success'} />
            </Tooltip>}
            <Typography sx={styles.dailyPrice}>{`$${price}`}</Typography>
            <Typography sx={styles.customPriceItem}>{`$${customPrice}`}</Typography>
            <Typography fontFamily={AppFontFamily.FREIGHT_TEXT_PRO_ITALIC} fontSize={'0.85em'}>
              {byDay ? '/day' : ''}
            </Typography>
          </Box>)
        }
      </Grid>

      {finalPrice && (
        <Grid item xs={direction === 'column' ? 12 : 6}>
          <Typography sx={styles.priceFinalItem}>{`$${Math.round(finalPrice * 100) / 100} est.total`}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Price;

const styles: SxStyles = {
  dailyPrice: {
    color: AppTheme.colors.green_008000,
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC,
    marginRight: '.3em',
    marginBottom: '.7em',
  },
  customPriceItem: {
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC,
    marginRight: '.3em',
    textDecoration: 'line-through',
    textDecorationColor: AppTheme.colors.persianBlue_2238CB
  },
  priceItem: {
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC,
    marginRight: '.3em',
  },
  priceFinalItem: {
    color: AppTheme.colors.black_01000E,
    fontSize: '0.85em',
  },
};
