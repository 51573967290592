import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { COLORS, IAppTheme } from '../../utils/AppTheme';
import { RequestStatus } from '../../utils/RequestStatus';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

const useStyles = makeStyles((theme: IAppTheme) => ({
  feedBackContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: COLORS.persianBlue_2238CB,
  },
  errorFeedBackContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

export default function RequestStatusFeedBack(props: {
  status: RequestStatus;
  onRetry: () => void;
}) {
  const classes = useStyles();

  return (
    <div className={classes.feedBackContainer}>
      {props.status === RequestStatus.LOADING && <CircularProgress color="inherit" />}

      {props.status !== RequestStatus.LOADING && props.status === RequestStatus.ERROR && (
        <div className={classes.errorFeedBackContainer} onClick={props.onRetry}>
          <AppTypography type={PDLTypography.mediumHeading}>An error ocurred</AppTypography>
          <AppTypography type={PDLTypography.callToAction}>Retry</AppTypography>
        </div>
      )}
    </div>
  );
}
