import FormControl from '@mui/material/FormControl/FormControl';
import { makeStyles } from '@mui/styles';
import { AppTextField } from '../commons/AppTextField';
import { PDLTypography } from '../Typography/PDLTypography';

interface Props {
  message: string;
  onInputChange: (inputName: string, inputValue: string) => void;
}

const userStyles = makeStyles({
  container: {},
  fullWidth: {
    width: '100%',
  },
});

export const BikeReservationMessageSection = (props: Props) => {
  const classes = userStyles();

  return (
    <>
      <FormControl className={classes.fullWidth}>
        <div className={`${classes.container} ${classes.fullWidth}`}>
          <AppTextField
            name="message"
            label="Send Message"
            value={props.message}
            containerClasses={classes.fullWidth}
            inputProps={{ multiline: true, minRows: 5, fullWidth: true }}
            labelProps={{
              type: PDLTypography.mediumHeading,
              customStyles: { marginBottom: '1em' },
            }}
            onInputChange={(inputName: string, inputValue: string) =>
              props.onInputChange(inputName, inputValue)
            }
          />
        </div>
      </FormControl>
    </>
  );
};
