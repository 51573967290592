import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import environment from '../../../environment.json';
import AuthService from '../services/AuthService';
import AsyncAuthService from '../services/AsyncAuthService';
import EnvironmentService from '../services/EnvironmentService';
import ApiResponseV2 from '../core/ApiResponseV2';
import { ApiResultDTO } from '../core/ApiResultDTO';

export interface HttpClientV2Config {
  /**
   * Used to override axios default config
   */
  axiosConfig?: AxiosRequestConfig;
}

class HttpClientV2 {
  private client: AxiosInstance;

  constructor(baseURL: string, requestTimeout?: number) {
    this.client = axios.create({
      baseURL: baseURL,
      timeout: requestTimeout || 5000,
    });

    this.generateConfig().then((config) => {
      if (!!config.headers?.Authorization) {
        axios.defaults.headers.common['Authorization'] = config.headers!.Authorization;
      }
    });
  }

  private addBaseConfig(config: AxiosRequestConfig, token: any) {
    if (token) {
      if (!config.headers) {
        config.headers = {}
      }
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  }

  private async generateConfig(extraConfig?: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    var config: AxiosRequestConfig = extraConfig || {};

    if (EnvironmentService.usesAsyncStorageStrategy()) {
      this.addBaseConfig(config, await AsyncAuthService.getToken());
    } else {
      this.addBaseConfig(config, AuthService.getToken());
    }

    return config;
  }

  private handleAxiosRequestError<T>(error: {
    response: AxiosResponse<ApiResultDTO<T>> | undefined;
    request: XMLHttpRequest | undefined;
  }): ApiResponseV2<T> {
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      return ApiResponseV2.fromAxiosResponse(error.response);
    }

    if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      return ApiResponseV2.noResponseError();
    }

    // Something happened in setting up the request that triggered an Error
    return ApiResponseV2.requestError();
  }

  async get<T>(url: string, config?: HttpClientV2Config): Promise<ApiResponseV2<T>> {
    return this.client
      .get(url, await this.generateConfig(config?.axiosConfig))
      .then((response: AxiosResponse<ApiResultDTO<T>>) => ApiResponseV2.fromAxiosResponse(response))
      .catch((error) => this.handleAxiosRequestError(error));
  }

  async post<T, R>(url: string, payload: T, config?: HttpClientV2Config): Promise<ApiResponseV2<R>> {
    return this.client
      .post(url, payload, await this.generateConfig(config?.axiosConfig))
      .then((response: AxiosResponse<ApiResultDTO<R>>) => ApiResponseV2.fromAxiosResponse(response))
      .catch((error) => this.handleAxiosRequestError(error));
  }

  async put<T, R>(url: string, payload: T, config?: HttpClientV2Config): Promise<ApiResponseV2<R>> {
    return this.client
      .put(url, payload, await this.generateConfig(config?.axiosConfig))
      .then((response: AxiosResponse<ApiResultDTO<R>>) => ApiResponseV2.fromAxiosResponse(response))
      .catch((error) => this.handleAxiosRequestError(error));
  }

  async delete<T>(url: string, config?: HttpClientV2Config): Promise<ApiResponseV2<T>> {
    return this.client
      .delete(url, await this.generateConfig(config?.axiosConfig))
      .then((response: AxiosResponse<ApiResultDTO<T>>) => ApiResponseV2.fromAxiosResponse(response))
      .catch((error) => this.handleAxiosRequestError(error));
  }
}

export default new HttpClientV2(environment.baseURL, (environment as any).requestTimeout);