import { BikePartType } from '../BikePartType';

export default class BikePartCreation {
  public type: BikePartType;
  public brand: string;
  public component: string;
  public model: string;
  public size: string;
  public imagesExternalIds: string[] = [];
  public bikeExternalId: string;
  public material: string;
  public category: string;
  public additionalCategory: string;
  public measurement: number;
  public additionalInfo: string;

  constructor(
    type: BikePartType,
    brand: string,
    component: string,
    model: string,
    size: string,
    bikeExternalId: string,
    material: string,
    category: string,
    additionalCategory: string,
    measurement: number,
    additionalInfo: string
  ) {
    this.type = type;
    this.brand = brand;
    this.component = component;
    this.model = model;
    this.size = size;
    this.bikeExternalId = bikeExternalId;
    this.material = material;
    this.category = category;
    this.additionalCategory = additionalCategory;
    this.measurement = measurement;
    this.additionalInfo = additionalInfo;
  }
}
