import { Img, ImgProps } from 'react-image';
import placeholder from '../../assets/img/pdl-placeholder.png';
import error_placeholder from '../../assets/img/error_placeholder.png';
import { CSSProperties } from 'react';

interface AppImg extends ImgProps {
  loaderstyles?: CSSProperties;
  unloaderstyles?: CSSProperties;
  carousel?: boolean;
}

const AppImage = (props: AppImg) => {
  return (
    <Img
      loader={
        <img
          src={placeholder}
          alt={''}
          height={props.height}
          width={'100%'}
          style={{ ...props.style, ...props.loaderstyles }}
          className={props.className}
        />
      }
      unloader={
        <img
          src={error_placeholder}
          alt={''}
          height={props.height}
          width={'100%'}
          style={{ ...props.style, ...props.unloaderstyles }}
          className={props.className}
        />
      }
      style={{ width: '100%', objectFit: props.carousel ? 'contain' : 'cover', ...props.style }}
      {...props}
      src={props.src || placeholder}
    />
  );
};

export default AppImage;
