import './App.css';
import AppTheme from './utils/AppTheme';
import { CircularProgress, ThemeProvider } from '@mui/material';
import { AppRoutes } from './utils/AppNavigation';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Search from './components/views/Search';
import BikeDetail from './components/views/BikeDetail';
import BikeRatings from './components/views/BikeRatings';
import Home from './components/views/Home';
import ExploreSearch from './components/views/ExploreSearch';
import ExploreCalendar from './components/views/ExploreCalendar';
import ChatComponent from './components/Chat/ChatComponent';
import BlackInviteCode from './components/views/BlackInviteCode';
import BlackCreateProfile from './components/views/BlackCreateProfile';
import AccountRatings from './components/Account/AccountRatings';
import { HostBikeFirstStep } from './components/views/Host/HostBikeFirstStep';
import { BikeReservation } from './components/Reservation/BikeReservation';
import HostBikeSecondStep from './components/views/Host/HostBikeSecondStep';
import { BikeActivation } from './components/views/Host/BikeActivation';
import { ReservationDropOff } from './components/views/ReservationDropOff';
import MyMeasurements from './components/Account/MyMeasurements';
import PersonalInformation from './components/Account/PersonalInformation';
import { HostBikeEditImages } from './components/views/Host/Edit/HostBikeEditImages';
import HostBikeEditDetails from './components/views/Host/Edit/HostBikeEditDetails';
import HelpCenter from './components/Account/HelpCenter';
import HelpCenterDetail from './components/Account/HelpCenterDetail';
import PaymentDetails from './components/Account/PaymentDetails';
import InviteFriends from './components/Account/InviteFriends';
import Earnings from './components/views/Host/Earnings';
import HostRatings from './components/views/Host/HostRatings';
import EarningDetails from './components/views/Host/EarningDetails';
import HostBikeThirdStep from './components/views/Host/HostBikeThirdStep';
import PayoutMethod from './components/views/Host/PayoutMethod';
import { ReceiptDetailComponent } from './components/views/Receipt';
import PrivateRoute from './PrivateRoute';
import { useIsLogged } from './hooks/useIsLogged';
import { HostBikeEdit } from './components/views/Host/Edit/HostBikeEdit';
import ReservationHistory from './components/views/Host/ReservationHistory';
import HostBikeEditParts from './components/views/Host/Edit/HostBikeEditParts';
import HostBikeEditLocation from './components/views/Host/Edit/HostBikeEditLocation';
import { WaitlistHome } from './components/views/Waitlist/WaitlistHome';
import { ReportIssue } from './components/Reservation/Details/Renter/ReportIssue';
import { ReservationPickUp } from './components/views/ReservationPickUp';
import { LeaveReviewRenter } from './components/views/LeaveReviewRenter';
import CancelReservation from './components/views/Host/CancelReservation';
import { LeaveReviewHost } from './components/views/LeaveReviewHost';
import HostBikeEditBlockoutDates from './components/views/Host/Edit/HostBikeEditBlockoutDates';
import { SnackbarComponent } from './components/core/SnackbarComponent';
import { HostBikeConfirmLocation } from './components/views/Host/HostBikeConfirmLocation';
import { BlackSignUpWaitList } from './components/views/Waitlist/BlackSignUpWaitList';
import ValidateForgotPassword from './components/Account/ValidateForgotPassword';
import BlackValidateAccount from './components/Account/BlackValidateAccount';
import { BlackWaitListCreateProfile } from './components/views/BlackWaitListCreateProfile';
import { EditAvatar } from './components/Account/EditAvatar';
import Insurance from './components/views/Host/Insurance';
import CompleteAdditionalInformation from './components/views/CompleteAdditionalInformation';
import { useState } from 'react';
import { LandingPageHome } from './components/views/LandingPage/LandingPageHome';
import BlackSignIn from './components/views/BlackSignIn';
import About from './components/views/About';
import { TermsAndConditions } from './components/views/TermsAndConditions';
import SignInAsOther from './components/views/SignInAsOther';
import HostBikeEditTieredPrice from './components/views/Host/Edit/HostTieredPrice';
import { DisablePickUpIssue } from './components/Reservation/Details/Renter/DisablePickUpIssue';
import {ReservationDropOffConfirmation} from "./components/views/ReservationDropOffConfirmation";
import {ReservationPickUpConfirmation} from "./components/views/ReservationPickUpConfirmation";

function App() {
  const [path, setPath] = useState<string>('');
  const [isLogged, setIsLogged] = useIsLogged(path);

  if (isLogged === undefined) {
    // Still waiting for token response, it's not known if there's a session yet.
    return <CircularProgress color="inherit" />;
  }

  const getPrivateElement = (element: React.ReactElement<any, any>): JSX.Element => {
    return (
      <PrivateRoute isLogged={isLogged} setPath={setPath}>
        {element}
      </PrivateRoute>
    );
  };

  return (
    <ThemeProvider theme={AppTheme}>
      <BrowserRouter>
        <Routes>
          {/* -------- PUBLIC ROUTES -------- */}

          <Route
            path={AppRoutes.HOME}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <LandingPageHome />}
          />

          <Route
            path={AppRoutes.SIGN_IN}
            element={
              isLogged ? (
                <Home setIsLogged={setIsLogged} />
              ) : (
                <BlackSignIn setIsLogged={setIsLogged} />
              )
            }
          />
          <Route
            path={AppRoutes.SIGN_IN_AS_OTHER}
            element={
              isLogged ? (
                <Home setIsLogged={setIsLogged} />
              ) : (
                <SignInAsOther setIsLogged={setIsLogged} />
              )
            }
          />

          {/* TEMPORARILY DISABLED  
          <Route
            path={AppRoutes.SPLASH_SCREEN}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <SplashScreen />}
          />
          */}

          <Route path={AppRoutes.ABOUT} element={<About />} />

          <Route
            path={AppRoutes.INVITE_CODE}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <BlackInviteCode />}
          />
          <Route
            path={AppRoutes.CREATE_PROFILE}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <BlackCreateProfile />}
          />
          <Route
            path={AppRoutes.WAITLIST_HOME}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <WaitlistHome />}
          />
          <Route
            path={AppRoutes.WAITLIST_JOIN}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <BlackSignUpWaitList />}
          />

          <Route
            path={AppRoutes.WAIT_LIST_CREATE_PROFILE}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <BlackWaitListCreateProfile />}
          />
          <Route
            path={AppRoutes.VALIDATE_FORGOT_PASSWORD}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <ValidateForgotPassword />}
          />
          <Route
            path={AppRoutes.VALIDATE_ACCOUNT}
            element={isLogged ? <Home setIsLogged={setIsLogged} /> : <BlackValidateAccount />}
          />

          <Route path={AppRoutes.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />

          {/* -------- PRIVATE ROUTES -------- */}
          <Route
            path={AppRoutes.COMPLETE_ADDITIONAL_INFORMATION}
            element={getPrivateElement(<CompleteAdditionalInformation />)}
          />
          <Route path={AppRoutes.SEARCH} element={getPrivateElement(<Search />)} />
          <Route path={AppRoutes.BIKE_DETAIL} element={getPrivateElement(<BikeDetail />)} />
          <Route path={AppRoutes.BIKE_RATINGS} element={getPrivateElement(<BikeRatings />)} />
          <Route
            path={AppRoutes.TRIPS_LEAVE_REVIEW}
            element={getPrivateElement(<LeaveReviewRenter />)}
          />
          <Route
            path={AppRoutes.RECEIPT_DETAIL}
            element={getPrivateElement(<ReceiptDetailComponent />)}
          />
          <Route path={AppRoutes.CHAT} element={getPrivateElement(<ChatComponent />)} />

          {/* Reservation */}
          <Route
            path={AppRoutes.CONFIRM_AND_PAY}
            element={getPrivateElement(<BikeReservation />)}
          />

          {/* Account */}
          <Route path={AppRoutes.EDIT_AVATAR} element={getPrivateElement(<EditAvatar />)} />
          <Route path={AppRoutes.ACCOUNT_RATINGS} element={getPrivateElement(<AccountRatings />)} />
          <Route path={AppRoutes.MY_MEASUREMENTS} element={getPrivateElement(<MyMeasurements />)} />
          <Route
            path={AppRoutes.PERSONAL_INFORMATION}
            element={getPrivateElement(<PersonalInformation />)}
          />
          <Route path={AppRoutes.HELP_CENTER} element={getPrivateElement(<HelpCenter />)} />
          <Route
            path={AppRoutes.HELP_CENTER_DETAIL}
            element={getPrivateElement(<HelpCenterDetail />)}
          />
          <Route path={AppRoutes.PAYMENT_DETAILS} element={getPrivateElement(<PaymentDetails />)} />
          <Route path={AppRoutes.INVITE_FRIENDS} element={getPrivateElement(<InviteFriends />)} />

          {/* Host */}
          <Route
            path={AppRoutes.HOST_ACTIVATE_BIKE}
            element={getPrivateElement(<BikeActivation />)}
          />
          <Route
            path={AppRoutes.RESERVATION_PICK_UP}
            element={getPrivateElement(<ReservationPickUp />)}
          />
          <Route
            path={AppRoutes.RESERVATION_DROP_OFF}
            element={getPrivateElement(<ReservationDropOff />)}
          />
          <Route
              path={AppRoutes.RESERVATION_DROP_OFF_CONFIRMATION}
              element={getPrivateElement(<ReservationDropOffConfirmation />)}
          />
          <Route
              path={AppRoutes.RESERVATION_PICK_UP_CONFIRMATION}
              element={getPrivateElement(<ReservationPickUpConfirmation />)}
          />
          <Route path={AppRoutes.EARNINGS} element={getPrivateElement(<Earnings />)} />
          <Route path={AppRoutes.EARNING_DETAILS} element={getPrivateElement(<EarningDetails />)} />
          <Route
            path={AppRoutes.HOST_BIKE_FIRST_STEP}
            element={getPrivateElement(<HostBikeFirstStep />)}
          />
          <Route
            path={AppRoutes.HOST_BIKE_CONFIRM_LOCATION}
            element={getPrivateElement(<HostBikeConfirmLocation />)}
          />
          <Route
            path={AppRoutes.HOST_BIKE_SECOND_STEP}
            element={getPrivateElement(<HostBikeSecondStep />)}
          />
          <Route
            path={AppRoutes.HOST_BIKE_THIRD_STEP}
            element={getPrivateElement(<HostBikeThirdStep />)}
          />
          <Route path={AppRoutes.INSURANCE} element={getPrivateElement(<Insurance />)} />
          <Route path={AppRoutes.PAYOUT_METHOD} element={getPrivateElement(<PayoutMethod />)} />
          <Route path={AppRoutes.HOST_BIKE_EDIT} element={getPrivateElement(<HostBikeEdit />)} />
          <Route
            path={AppRoutes.HOST_BIKE_EDIT_IMAGES}
            element={getPrivateElement(<HostBikeEditImages />)}
          />
          <Route
            path={AppRoutes.HOST_BIKE_EDIT_DETAILS}
            element={getPrivateElement(<HostBikeEditDetails />)}
          />
          <Route
            path={AppRoutes.HOST_BIKE_EDIT_PARTS}
            element={getPrivateElement(<HostBikeEditParts />)}
          />
          <Route
            path={AppRoutes.HOST_BIKE_EDIT_BLOCKOUT_DATES}
            element={getPrivateElement(<HostBikeEditBlockoutDates />)}
          />
          <Route
            path={AppRoutes.HOST_BIKE_EDIT_LOCATION}
            element={getPrivateElement(<HostBikeEditLocation />)}
          />
          <Route
            path={AppRoutes.HOST_BIKE_EDIT_TIERED_PRICE}
            element={getPrivateElement(<HostBikeEditTieredPrice />)}
          />
          <Route path={AppRoutes.HOST_RATINGS} element={getPrivateElement(<HostRatings />)} />
          <Route
            path={AppRoutes.HOST_RESERVATION_HISTORY}
            element={getPrivateElement(<ReservationHistory />)}
          />

          <Route
            path={AppRoutes.HOST_RESERVATION_CANCEL}
            element={getPrivateElement(<CancelReservation />)}
          />

          <Route
            path={AppRoutes.HOST_RESERVATION_LEAVE_REVIEW}
            element={getPrivateElement(<LeaveReviewHost />)}
          />
          {/* Explore */}
          <Route path={AppRoutes.EXPLORE_SEARCH} element={getPrivateElement(<ExploreSearch />)} />
          <Route
            path={AppRoutes.EXPLORE_CALENDAR}
            element={getPrivateElement(<ExploreCalendar />)}
          />

          {/* TRIPS */}
          <Route path={AppRoutes.REPORT_ISSUE} element={getPrivateElement(<ReportIssue />)} />
          <Route path={AppRoutes.DISABLE_PICKUP_ISSUE} element={getPrivateElement(<DisablePickUpIssue />)} />

          <Route path={'/*'} element={getPrivateElement(<Home setIsLogged={setIsLogged} />)} />
        </Routes>
      </BrowserRouter>
      <SnackbarComponent />
    </ThemeProvider>
  );
}

export default App;
