import { Box, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bike } from '../../../external/pdl-common/model/Bike';
import { useForm } from '../../../hooks/useForm';
import { InsurancePolicyCreation } from '../../../model/insurance/InsurancePolicyCreation';
import { TintPolicyCreationResponse } from '../../../model/insurance/Tint/TintPolicyCreationResponse';
import { InsuranceProduct } from '../../../model/InsurancePolicy';
import AlertService from '../../../services/AlertService';
import InsuranceService from '../../../services/InsuranceService';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import Logger from '../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../utils/RequestStatus';
import AppHeader from '../../commons/AppHeader';
import AppSelect from '../../commons/AppSelect';
import FormContainer from '../../commons/FormContainer';
import SimpleButton from '../../commons/SimpleButton';
import { AppLayout } from '../../core/AppLayout';
import InsuranceQuote from './commons/InsuranceQuote';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import { makeStyles } from '@mui/styles';
import InsuranceDescription from './InsuranceDescription';
import Navbar from '../../core/Navbar';
import { useFeatureToggle } from '../../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../../external/pdl-common/utils/enums/Features';
import { TintCoverages } from '../../../model/insurance/Tint/TintCoverages';
import DateUtils from '../../../external/pdl-common/utils/DateUtils';
import {AlertDialog} from "../../commons/AlertDialog";

const logger = new Logger('Insurance');

interface StateProps {
  bike?: Bike;
  startDate?: Date;
  endDate?: Date;
}

type LocationProps = {
  state: StateProps;
};

const Insurance = () => {
  const classes = useStyles();
  const location = useLocation() as unknown as LocationProps;
  const navigate = useNavigate();

  // Location state
  const bike = location.state?.bike;
  const startDate = location.state?.startDate;
  const endDate = location.state?.endDate;

  // React state
  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [form, , handleCustomChange, formIsValid] = useForm<InsurancePolicyCreation>({
    insuranceProductId: '-1',
    bikeExternalId: bike?.externalId || '',
    startDate,
    endDate,
    zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone, // TODO: remove this from here, already in CompleteAdditionalInformation
  });
  const [draftInsurancePolicy, setDraftInsurancePolicy] = useState<
    TintPolicyCreationResponse | undefined
  >(undefined);
  const [insuranceProducts, setInsuranceProducts] = useState<InsuranceProduct[]>([]);
  const [includeInsurance, setIncludeInsurance] = useState<boolean>(false);
  const [isInsuranceFeatureEnabled] = useFeatureToggle(Features.INSURANCE);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  const getBikePrice = (bike: Bike, days: number) => {
    if (days == 2 && bike.twoDayPrice != undefined) {
      return bike.twoDayPrice;
    } else if (days >= 3 && bike.threePlusDayPrice != undefined) {
      return bike.threePlusDayPrice;
    } else {
      return bike.customPrice;
    }
  }

  const getInsuranceCostForPeriod = () => {
    if (startDate && endDate && bike) {
      const days = DateUtils.getFullDaysBetweenDates(startDate, endDate);
      const bikePrice = getBikePrice(bike, days);
      return ((days * bikePrice) / 10);
    } else {
      return 0;
    }
  }

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    if (!form.startDate || !form.endDate || !form.bikeExternalId) {
      return;
    }
    if (isInsuranceFeatureEnabled) {
      try {
        setRequestStatus(RequestStatus.LOADING);

        // We get the insurance products
        const response = await InsuranceService.getRenterInsuranceProducts();
        const products = response.getContent();

        products.push({ id: '0', name: 'No Protection Plan', slug: '' });

        setInsuranceProducts(products);

        setRequestStatus(RequestStatus.SUCCESS);
      } catch (error: any) {
        const errorMessage =
          error?.response?.data?.message || 'Error creating draft insurance policy';

        AlertService.showSnackCustomError(errorMessage);
        logger.error(errorMessage, error);

        setRequestStatus(RequestStatus.ERROR);
      }

    } else {
      setRequestStatus(RequestStatus.LOADING);
      const products = [
        { id: '0', name: 'No Protection Plan', slug: '' },
        { id: '1', name: 'Standard', slug: 'standard' }
      ]
      setInsuranceProducts(products);
      setRequestStatus(RequestStatus.SUCCESS);
    }
  };

  const selectProduct = async (id: string) => {
    // We set the selected product
    const selectedProduct = insuranceProducts.find((p) => p.id === id);

    if (selectedProduct) {
      setRequestStatus(RequestStatus.LOADING);
      if (isInsuranceFeatureEnabled) {
        (
          await InsuranceService.createDraftInsurancePolicy({
            ...form,
            insuranceProductId:
              selectedProduct.id === '0' ? insuranceProducts[0].id : selectedProduct.id,
          })
        )
          .onSuccess((response) => {
            setDraftInsurancePolicy(response.getContent());
            setIncludeInsurance(selectedProduct.id !== '0');
            setRequestStatus(RequestStatus.SUCCESS);
          })
          .onError((response) => {
            logger.error('Error trying to fetch insurance policy. ', response.getContent());
            AlertService.showSnackCustomError(response.getContent());
            setRequestStatus(RequestStatus.ERROR);
          });
      } else {
        setIncludeInsurance(selectedProduct.id !== '0');
        setRequestStatus(RequestStatus.SUCCESS);
      }
    }
  };

  const navigateToNextScreen = (includeInsurance: boolean) => {
    if (draftInsurancePolicy) {
      const { id, quotes, policy_details } = draftInsurancePolicy;

      appNavigate(navigate, AppRoutes.CONFIRM_AND_PAY, null, {
        state: {
          ...location.state,
          renterInsurancePolicyId: id,
          renterSubscribedQuote: quotes.find(Boolean),
          reservationExternalId: policy_details.reservation.id,
          includeInsurance,
        },
      });
    } else {
      appNavigate(navigate, AppRoutes.CONFIRM_AND_PAY, null, {
        state: {
          ...location.state,
          includeInsurance,
          protectionPlanPrice: includeInsurance ? getInsuranceCostForPeriod() : 0
        },
      });
    }
  };

  const isInsuranceInfoComplete = (): boolean => formIsValid() && form.insuranceProductId !== '-1';

  const getContent = () => {
    return (
      <>
        <FormContainer
          bottomElementStyle={{ marginBottom: '59px' }}
          BottomElement={
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <SimpleButton
                isLoading={requestStatus === RequestStatus.LOADING}
                children={'Continue'}
                disabled={requestStatus === RequestStatus.LOADING || !isInsuranceInfoComplete()}
                //onClick={() => navigateToNextScreen((!!draftInsurancePolicy || !isInsuranceFeatureEnabled ) && includeInsurance)}
                onClick={() => {
                  if ((!!draftInsurancePolicy || !isInsuranceFeatureEnabled) && includeInsurance) {
                    navigateToNextScreen(true);
                  } else {
                    setOpenConfirmationModal(true);
                  }
                }}

                sx={{ maxWidth: '18em' }}
              />
            </Box>
          }
          bottomElementFullWidth
        >
          <>
            <Box className={classes.box}>
              <AppTypography
                type={PDLTypography.largeHeading}
                children={'Protection Plans'}
                customClasses={classes.header}
              />

              <AppSelect
                props={{
                  name: 'insuranceProductId',
                  value: form.insuranceProductId,
                  onChange: (event) => {
                    const { name, value } = event.target;
                    handleCustomChange(name, value as string, !!value && value !== '-1');
                    selectProduct(value as string);
                  },
                }}
              >
                {insuranceProducts.map((insurancePolicy) => (
                  <MenuItem value={insurancePolicy.id}>{`${insurancePolicy.name}`}</MenuItem>
                ))}
              </AppSelect>
            </Box>

            {/* Messages to user - No Insurance Plan */}
            {isInsuranceInfoComplete() && form.insuranceProductId === '0' && (
              <div className={classes.container}>
                <AppTypography
                  type={PDLTypography.paragraph}
                  customClasses={classes.paragraph}
                  children={
                    'As the renter you are responsible for all physical damage to, loss, or theft of a booked bicycle that occurs during a trip, plus any additional costs and fees resulting from or incidental to damage of any kind to the bicycle.'
                  }
                />
                <AppTypography
                  type={PDLTypography.paragraph}
                  customClasses={classes.paragraph}
                  children={
                    'By confirming your rental, you acknowledge that PDL Club will hold an amount on your credit card equal to $500. This amount will remain on hold and will be released upon successful confirmation of business finalization.'
                  }
                />
              </div>
            )}

            {/* Messages to user - Insurance Plan selected*/}
            {(draftInsurancePolicy || !isInsuranceFeatureEnabled) && form.insuranceProductId !== '0' &&  form.insuranceProductId !== '-1' && (
              <>
                {draftInsurancePolicy ? 
                  <Box>
                    {draftInsurancePolicy.quotes.map((quote) => (
                      <InsuranceQuote coverages={quote.coverages} key={quote.id} />
                    ))}
                  </Box>
                :
                  <Box>
                      <InsuranceQuote coverages={{liability: {premium: getInsuranceCostForPeriod().toString(), limit: "3000", deductible: "200" }}as TintCoverages} />
                  </Box>
              }
              <>
                <InsuranceDescription reservingPhase />
              </>
              </>

            )}
          </>
        </FormContainer>

        {/* No insurance verification modal */}
        <AlertDialog
            title='No Protection Plan'
            confirmLabel='Continue'
            cancelLabel='Back'
            content={`You are declining the Protection Plan for your current rental. PDL CLUB will process a hold charge on your credit card in the amount of $500 as a security. You acknowledge and agree that the total amount of loss in case of an event may exceed this amount and that you will be fully accountable for any additional costs.`}
            open={openConfirmationModal}
            onConfirm={() => {
              setOpenConfirmationModal(false);
              navigateToNextScreen(false)
            }}
            onCancel={() => {
              setOpenConfirmationModal(false);
            }}
        />
        <Navbar/>
      </>
    );
  };

  return (
    <AppLayout
      noNavBar
      header={() => <AppHeader content={'Add Protection Plan'} />}
      content={() => getContent()}
    />
  );
};

export default Insurance;
const useStyles = makeStyles(() => ({
  box: {
    marginTop: '1em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '2em',
  },
  header: {
    marginTop: '2em',
  },
  paragraph: {
    marginLeft: '0em',
    marginTop: '1em',
    textAlign: 'left',
  },
  subparagraph: {
    margin: '0.5em',
    textAlign: 'left',
  },
}));
