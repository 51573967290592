export enum BikePartType {
  frameMaterial = 'frameMaterial',
  bottomBracket = 'bottomBracket',
  brake = 'brake',
  cassette = 'cassette',
  crankset = 'crankset',
  frontDerailleur = 'frontDerailleur',
  rearDerailleur = 'rearDerailleur',
  handlebar = 'handlebar',
  seatpost = 'seatpost',
  stem = 'stem',
  wheelset = 'wheelset',
  tires = 'tires',
  saddle = 'saddle',
  powerMeter = 'powerMeter',
  pedals = 'pedals',
}
