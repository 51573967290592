import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import { RenterReview } from '../model/RenterReview';
import { CrudService } from '../external/fox-typescript/services/CrudService';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import { HostReview } from '../model/HostReview';
import { IPage } from '../external/fox-typescript/core/IPage';
import { ReviewCreation } from '../model/creation/ReviewCreation';

class BikeRatingService implements CrudService<RenterReview> {
  getAll(page: number, pageSize: number): Promise<ApiResponse<PagedResult<RenterReview>>> {
    throw new Error('Method not implemented.');
  }

  create(instance: RenterReview): Promise<ApiResponse<RenterReview>> {
    throw new Error('Method not implemented.');
  }

  async createReviewFromRenter(
    reservationExternalId: string,
    reviewCreation: ReviewCreation
  ): Promise<ApiResponse<RenterReview>> {
    return await HttpClient.post(`/reviews/create/renter`, {
      ...reviewCreation,
      reservationExternalId: reservationExternalId,
    });
  }

  async createReviewFromHost(
    reservationExternalId: string,
    reviewCreation: ReviewCreation
  ): Promise<ApiResponse<RenterReview>> {
    return await HttpClient.post(`/reviews/create/host`, {
      rating: reviewCreation.rating,
      userComment: reviewCreation.userComment,
      reservationExternalId: reservationExternalId,
    });
  }

  delete(instance: RenterReview): Promise<ApiResponse<RenterReview>> {
    throw new Error('Method not implemented.');
  }

  async getRenterReviewsForBike(
    externalId: string,
    page: number,
    pageSize: number
  ): Promise<ApiResponse<IPage<RenterReview>>> {
    return await HttpClient.getWithParams(
      `/reviews/bike/${externalId}/renter`,
      {
        page: page,
        pageSize: pageSize,
      },
      true
    );
  }

  async getReviewsAsHost(
    page: number,
    pageSize: number
  ): Promise<ApiResponse<IPage<RenterReview>>> {
    return await HttpClient.getWithParams(
      `/reviews/as-host`,
      {
        page: page,
        pageSize: pageSize,
      },
      true
    );
  }

  async getRenterReviewForReservation(externalId: string): Promise<ApiResponse<RenterReview>> {
    return await HttpClient.get(`/reviews/reservation/${externalId}/renter`);
  }

  async getHostReviewForReservation(externalId: string): Promise<ApiResponse<HostReview>> {
    return await HttpClient.get(`/reviews/reservation/${externalId}/host`);
  }

  async getReviewsAsRenter(
    page: number,
    pageSize: number
  ): Promise<ApiResponse<IPage<RenterReview>>> {
    return await HttpClient.getWithParams(
      `/reviews/as-renter`,
      {
        page: page,
        pageSize: pageSize,
      },
      true
    );
  }
}

export default new BikeRatingService();
