import { Box } from '@mui/material';
import { COLORS } from '../../utils/AppTheme';
import { CircularProgress } from '@mui/material';

interface Props {
  color?: string;
}

const LoadingSpinner = ({ color }: Props) => {
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress size={30} sx={{ color: color || COLORS.persianBlue_2238CB }} />
      </Box>
    </>
  );
};

export default LoadingSpinner;
