import { Avatar, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { ChannelType } from '../../model/enums/ChannelType';
import Reservation from '../../model/Reservation';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';
import ChatUtils from '../../utils/ChatUtils';

interface Props {
  message: string;
  date: Date;
  rentalDates?: string;
  reservation?: Reservation;
  profilePic: string;
  isOwnMessage?: boolean;
  isFirstMessage?: boolean;
  isRenter?: boolean;
  channelType: string;
}

const ChatMessageComponent = ({
  message,
  date,
  rentalDates,
  reservation,
  profilePic,
  isOwnMessage,
  isFirstMessage,
  isRenter,
  channelType,
}: Props) => {
  const classes = useStyles();

  const getReservationMessage = () => {
    if (reservation) {
      return isRenter
        ? `You rented ${reservation?.bike.owner.user.firstName}'s bike: ${rentalDates}.`
        : `${reservation?.renter.user.firstName} rented your bike: ${rentalDates}.`;
    }
    return channelType === ChannelType.DIRECT_MESSAGE ? 'Message from PDL support team' : "Support requested";
  };

  if (isFirstMessage && reservation) {
    message = ChatUtils.replaceStringReservationDates(message, rentalDates!);
  }

  return (
    <div className={classes.mainContainer}>
      {/* Date */}
      <div className={classes.dateContainer}>
        <p className={classes.date}>{DateUtils.formattedChatDate(date, false)}</p>
      </div>

      {/* Rental info */}
      {isFirstMessage && (
        <div className={classes.rentalInfoContainer}>
          <p className={classes.rentalInfoText}>{getReservationMessage()}</p>
        </div>
      )}

      {/* Message */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: isOwnMessage ? 'flex-end' : 'flex-start',
        }}
      >
        <div style={{ display: 'flex' }}>
          {/* Profile pic */}
          {!isOwnMessage && (
            <Avatar sx={muiStyles.profileImage}>
              <AppImage src={profilePic} />
            </Avatar>
          )}
          {/* Message content */}
          <div
            className={`${classes.messageContainer} ${
              isOwnMessage ? classes.ownMessage : classes.notOwnMessage
            }`}
          >
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessageComponent;

const muiStyles = {
  profileImage: {
    width: '1.563em',
    height: '1.563em',
    marginRight: '0.5em',
  } as SxProps,
};

const useStyles = makeStyles({
  mainContainer: {
    marginTop: '1em',
  },
  messageContainer: {
    width: '13.875em',
    padding: '0.625em',
    borderRadius: '0.5em',
    fontFamily: AppFontFamily.SHAPE,
    fontSize: '1em',
  },
  notOwnMessage: {
    backgroundColor: AppTheme.colors.white_EEEEEC,
    color: AppTheme.colors.black_000000,
  },
  ownMessage: {
    backgroundColor: AppTheme.colors.persianBlue_2238CB,
    color: AppTheme.colors.white_FFFFFF,
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  date: {
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC,
    fontSize: '0.875em',
    color: AppTheme.colors.gray_A8ACAD,
    margin: '0.5em',
  },
  rentalInfoContainer: {
    borderRadius: '3.125em',
    border: `0.06em solid ${AppTheme.colors.persianBlue_2238CB}`,
    paddingInline: '1.875em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '0.875em',
  },
  rentalInfoText: {
    color: AppTheme.colors.persianBlue_2238CB,
    fontSize: '0.813em',
    fontFamily: AppFontFamily.SHAPE_BOLD,
    marginTop: '0.6em',
    marginBottom: '0.6em',
  },
});
