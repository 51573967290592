import React, { useState } from 'react';
import { AppAssets } from '../../../../assets';
import { FullUser } from '../../../../external/pdl-common/model/FullUser';
import Reservation from '../../../../external/pdl-common/model/Reservation';
import Logger from '../../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../../external/pdl-common/utils/RequestStatus';
import UserService from '../../../../services/UserService';
import TintUtils, { ClaimInitiator } from '../../../../utils/TintUtils';
import { CallToActionWithIcon } from './CallToActionWithIcon';

interface Props {
  reservation: Reservation;
  claimInitiator: ClaimInitiator;
}

const logger = new Logger('InsuranceClaim');

const InsuranceClaim = ({ reservation, claimInitiator }: Props) => {
  const [pdlUser, setPdlUser] = useState<FullUser | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);

  const onFormClaim = async () => {
    if (!pdlUser) {
      setRequestStatus(RequestStatus.LOADING);

      (await UserService.getCurrentUser())
        .onSuccess((response) => {
          setRequestStatus(RequestStatus.SUCCESS);
          const user = response.getContent();

          setPdlUser(user);

          window.open(TintUtils.getClaimFormUrl(reservation!, user, claimInitiator), '_blank');
        })
        .onError((response) => {
          logger.error('Error fetching user', response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    } else {
      window.open(TintUtils.getClaimFormUrl(reservation!, pdlUser, claimInitiator), '_blank');
    }
  };

  return (
    <CallToActionWithIcon
      text={'Start a Protection Case'}
      asset={<AppAssets.FlagIcon />}
      style={{ marginTop: '1.5em' }}
      onClick={onFormClaim}
      disabled={requestStatus === RequestStatus.LOADING}
    />
  );
};

export default InsuranceClaim;
