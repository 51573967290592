import { Box, ButtonBase, Grid, useMediaQuery, useTheme } from '@mui/material';
import { AppAssets } from '../../assets';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { makeStyles } from '@mui/styles';
import { IAppTheme } from '../../external/pdl-common/utils/AppTheme';
import SimpleButton from './SimpleButton';
import { COLORS } from '../../utils/AppTheme';
import { Link, useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { TermsAndConditionsType } from '../../model/enums/TermsAndConditionsType';

interface Props {
  showTopSection?: boolean;
  dense?: boolean;
}

export const FooterPdlClub = ({ showTopSection, dense }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const navigate = useNavigate();

  const smallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Grid container className={classes.root}>
      {showTopSection && (
        <Grid
          item
          xs={12}
          className={classes.topSection}
          sx={{
            flexDirection: mediumBreakpoint ? 'row' : 'column',
            height: smallBreakpoint ? '28em' : '20em',
            padding: '1em',
          }}
        >
          <Box sx={{ alignItems: 'center', textAlign: smallBreakpoint ? 'left' : 'center' }}>
            <AppTypography
              type={PDLTypography.mediumHeading}
              customStyles={{
                cursor: 'pointer',
                color: COLORS.black_000000,
                fontSize: largeBreakpoint
                  ? '3.125em'
                  : mediumBreakpoint
                    ? '2.8em'
                    : smallBreakpoint
                      ? '2.5em'
                      : '1.5em',
              }}
            >
              Explore the first <span style={{ whiteSpace: 'nowrap'}}>peer-to-peer</span><br />
              performance bike rental <br />
              marketplace
            </AppTypography>
          </Box>
          <Box>
            <SimpleButton
              children={'Join PDL Club'}
              style={{
                width: largeBreakpoint ? '16em' : mediumBreakpoint ? '14em' : '16em',
              }}
              onClick={() => appNavigate(navigate, AppRoutes.INVITE_CODE, null)}
            />
          </Box>
        </Grid>
      )}

      {/* BOTTOM SECTION */}
      <Grid
        item
        xs={12}
        className={classes.bottomSection}
        sx={{
          height: dense ? '11em' : smallBreakpoint ? '16em' : '12em',
          paddingLeft: '.5em',
        }}
      >
        <Grid container xs={12} md={6} className={classes.description}>
          <Grid item xs={12} md={6}>
            <AppAssets.PDL_CLUB
              fill={COLORS.white_FFFFFF}
              width={smallBreakpoint ? '10em' : '8em'}
            />
          </Grid>
          <Grid item xs={12} md={6} mt={'1em'}>
            <Box
              pl={'1.8em'}
              sx={{
                color: COLORS.white_FFFFFF,
                fontSize: smallBreakpoint ? '0.8em' : '0.7em',
              }}
            >
              {`© 2021 PDL Club. All rights reserved.`}
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={6} sx={{ textAlign: mediumBreakpoint ? 'right' : 'center' }}>
            {/*}
            <ButtonBase
              title="Linkedin"
              color={color}
              onClick={() => window.open('', '_blank')}
              sx={{ width: '1.5em' }}
            >
              <LinkedInIcon sx={{ color: color }} />
            </ButtonBase>
            */}
            <ButtonBase
              title="Twitter"
              color={COLORS.white_FFFFFF}
              onClick={() => window.open('https://twitter.com/joinpdl', '_blank')}
              sx={{ width: '1.5em', marginRight: '1em' }}
            >
              <TwitterIcon htmlColor={COLORS.white_FFFFFF} />
            </ButtonBase>
            <ButtonBase
              title="Instagram"
              color={COLORS.white_FFFFFF}
              onClick={() => window.open('https://instagram.com/joinpdl', '_blank')}
              sx={{ width: '1.5em', marginRight: mediumBreakpoint ? '2em' : '0' }}
            >
              <InstagramIcon htmlColor={COLORS.white_FFFFFF} />
            </ButtonBase>
            {/*}
            <ButtonBase
              title="Facebook"
              color={color}
              onClick={() => window.open('', '_blank')}
              sx={{ width: '1.5em' }}
            >
              <FacebookIcon sx={{ color: color }} />
            </ButtonBase>
          */}
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            mt="1.5em"
            sx={{ textAlign: mediumBreakpoint ? 'right' : 'center' }}
          >
            <Box>
              <Link
                target={'_blank'}
                to={`${AppRoutes.TERMS_AND_CONDITIONS}?terms=${TermsAndConditionsType.PRIVACY_TERMS}`}
                style={{ textDecoration: 'none' }}
              >
                <AppTypography
                  type={PDLTypography.mediumHeading}
                  customStyles={{
                    color: COLORS.white_FFFFFF,
                    cursor: 'pointer',
                    fontSize: '1.125em',
                  }}
                  children={'Privacy  terms'}
                />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  root: {
    backgroundColor: COLORS.black_000000,
    display: 'flex',
    width: '100vw',
    maxWidth: '100%',
  },
  topSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: COLORS.white_FFFFFF,
    borderRadius: '0 0 2.125em 2.125em',
    width: '100%',
    maxWidth: '100%',
  },
  bottomSection: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
