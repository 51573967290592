import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import SimpleButton from '../commons/SimpleButton';
import { makeStyles } from '@mui/styles';
import AppTheme, { COLORS, IAppTheme } from '../../utils/AppTheme';
import { AppTextField } from '../commons/AppTextField';
import { PDLTypography } from '../Typography/PDLTypography';
import { UploadImage } from '../commons/UploadImage';
import { SxStyles } from '../../model/utils/SxStyles';
import PdlAuthService from '../../services/PdlAuthService';
import AlertService from '../../services/AlertService';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import { AppTypography } from '../Typography/AppTypography';
import Image from '../../external/fox-typescript/core/Image';
import ReCAPTCHA from 'react-google-recaptcha';
import enviroment from '../../environment.json';
import { useLocation } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import { VerifyEmailAlertBottomSheet } from '../commons/VerifyEmailAlertBottomSheet';
import TermsAndConditionsCheckbox from '../commons/TermsAndConditionsCheckbox';
import { WelcomeToPdlClub } from '../commons/WelcomeToPdlClub';
import { FooterPdlClub } from '../commons/FooterPdlClub';
import TopNavBar from '../core/LandingPageNavBar';
import { PasswordHelper } from '../commons/PasswordHelper';

export interface CreateProfileForm {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  password: string;
  confirmPassword: string;
  profileImageExternalId?: string;
  recaptchaToken: string;
  inviteCode?: string;
  renterTermsAccepted: boolean;
}

export enum SheetType {
  NONE = 'none',
  MAILSENT = 'mail sent',
  PASSWORDHELPER = 'helper',
}
const logger = new Logger('BlackCreateProfile');

export default function BlackCreateProfile() {
  const classes = useStyles();
  const location = useLocation().state as any;

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const inviteCode = location?.inviteCode as string | undefined;

  const [form, validations, handleCustomChange, formIsValid, , setValidations] =
    useForm<CreateProfileForm>({
      firstName: '',
      lastName: '',
      email: '',
      birthDate: '',
      password: '',
      confirmPassword: '',
      profileImageExternalId: '',
      recaptchaToken: '',
      inviteCode,
      renterTermsAccepted: false,
    });
  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [bottomSheetOpen, setBottomSheetOpen] = useState<SheetType>(SheetType.NONE);
  const [image, setImage] = useState<Image | undefined>(undefined);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const confirmPasswordInput = useRef<{ validate: () => void }>(null);

  useEffect(() => {
    if (form.confirmPassword) {
      const isValid = confirmPasswordInput?.current?.validate();

      let updatedValidations = { ...validations };
      (updatedValidations as any).confirmPassword = isValid;
      setValidations(updatedValidations);
    }
  }, [form.password]);

  const handleSetImage = (profileImage: Image) => {
    setImage(profileImage);
    handleCustomChange('profileImageExternalId', profileImage.externalId!, true);
  };

  const handleRemoveImage = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      setImage(undefined);
      setImageFile(undefined);
      handleCustomChange('profileImageExternalId', '', false);
    }
  };

  const handleOnCaptchaTokenChange = (token: string | null) => {
    handleCustomChange('recaptchaToken', token || '', !!token);
  };

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: boolean = e.target.value === 'true';
    handleCustomChange(e.target.name, !value, !value);
  };

  const onSubmit = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      await PdlAuthService.signUp(form);

      setRequestStatus(RequestStatus.SUCCESS);

      setBottomSheetOpen(SheetType.MAILSENT);
    } catch (error: any) {
      setRequestStatus(RequestStatus.ERROR);
      logger.error('Error trying to create profile', error);

      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }
    } finally {
      (window as any).grecaptcha.reset();
    }
  };

  const fieldIsDisabled = () => {
    return requestStatus === RequestStatus.SUCCESS;
  };

  return (
    <>
      <Box sx={{ backgroundColor: COLORS.black_000000 }}>
        <TopNavBar />
        <WelcomeToPdlClub />
        <Grid container direction="column" alignItems={'center'} sx={{ padding: '0em 2em' }}>
          <Grid container direction="column" sm={10} md={6}>
            <Grid item xs={12} sm={6} sx={muiStyle.gridHeader}>
              <AppTypography
                type={PDLTypography.largeHeading}
                customStyles={{
                  marginTop: '1.66em',
                  marginBottom: '1em',
                  textAlign: 'center',
                  color: COLORS.white_FFFFFF,
                }}
              >
                Create Your Profile
              </AppTypography>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: '0.95em' }}>
              <div className={classes.divFlex}>
                <AppTextField
                  label={'First name'}
                  name={'firstName'}
                  value={form.firstName}
                  onInputChange={handleCustomChange}
                  inputProps={{ disabled: fieldIsDisabled(), sx: muiStyle.input }}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  realTimeValidation
                  required
                  restrict="onlyLettersAndSpecialCharacters"
                  minLength={3}
                />

                <AppTextField
                  label={'Last name'}
                  name={'lastName'}
                  value={form.lastName}
                  onInputChange={handleCustomChange}
                  inputProps={{ disabled: fieldIsDisabled(), sx: muiStyle.input }}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  realTimeValidation
                  required
                  restrict="onlyLettersAndSpecialCharacters"
                  minLength={3}
                />

                <AppTextField
                  label={'Email'}
                  name={'email'}
                  value={form.email}
                  onInputChange={handleCustomChange}
                  inputProps={{ type: 'email', disabled: fieldIsDisabled(), sx: muiStyle.input }}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  realTimeValidation
                  required
                  restrict={'email'}
                  disableAutoComplete
                />

                <AppTextField
                  label={'Date of birth'}
                  name={'birthDate'}
                  value={form.birthDate}
                  onInputChange={handleCustomChange}
                  inputProps={{ type: 'date', disabled: fieldIsDisabled(), sx: muiStyle.input }}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  realTimeValidation
                  required
                />

                <AppTextField
                  label={'Password'}
                  name={'password'}
                  value={form.password}
                  onInputChange={handleCustomChange}
                  inputProps={{
                    type: 'password',
                    disabled: fieldIsDisabled(),
                    autoComplete: 'new-password',
                    sx: muiStyle.input,
                  }}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  realTimeValidation
                  required
                  restrict="strongPassword"
                  bottomRightElement={
                    <AppTypography
                      type={PDLTypography.smallParagraph}
                      children={'Valid format'}
                      customStyles={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        textAlign: 'right',
                        color: COLORS.white_FFFFFF,
                      }}
                      onClick={() => setBottomSheetOpen(SheetType.PASSWORDHELPER)}
                    />
                  }
                />

                <AppTextField
                  ref={confirmPasswordInput}
                  label={'Confirm Password'}
                  name={'confirmPassword'}
                  value={form.confirmPassword}
                  onInputChange={handleCustomChange}
                  inputProps={{ type: 'password', disabled: fieldIsDisabled(), sx: muiStyle.input }}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  realTimeValidation
                  required
                  customValidation={(text) => {
                    if (text !== form.password) {
                      return {
                        showError: true,
                        isValid: false,
                        message: 'Password do not match',
                      };
                    }
                    return { showError: false, isValid: true, message: '' };
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6} sx={muiStyle.gridBottom}>
              <div className={classes.profileImage}>
                <UploadImage
                  image={image}
                  imageUrls={image ? [image.url] : undefined}
                  label="Profile image"
                  labelProps={{
                    customStyles: {
                      marginBottom: '0.5em',
                      marginTop: '1.42em',
                      color: COLORS.white_FFFFFF,
                    },
                  }}
                  setImageFiles={(files) => {
                    if (files) {
                      setImageFile(files[0]);
                    }
                  }}
                  handleSetImage={handleSetImage}
                  imageFiles={imageFile ? [imageFile] : undefined}
                  onRemove={handleRemoveImage}
                />
              </div>
              <Box sx={{ textAlign: 'center' }}>
                <TermsAndConditionsCheckbox
                  name={'renterTermsAccepted'}
                  checked={form.renterTermsAccepted}
                  onChange={onCheckboxChange}
                />
              </Box>

              <Box sx={muiStyle.recaptchaBox}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={enviroment.recaptchaSiteKey}
                  onChange={handleOnCaptchaTokenChange}
                />
              </Box>

              <Box sx={muiStyle.signUp}>
                <SimpleButton
                  sx={{
                    marginBottom: '0.5em',
                    marginTop: '2em',
                    width: '37vh',
                    textAlign: 'center',
                  }}
                  children={'Sign Up'}
                  onClick={onSubmit}
                  disabled={
                    !formIsValid() ||
                    requestStatus === RequestStatus.LOADING ||
                    requestStatus === RequestStatus.SUCCESS // To prevent user register twice
                  }
                  isLoading={requestStatus === RequestStatus.LOADING}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <FooterPdlClub dense />
      </Box>
      {/* Verify your email bottom sheet */}
      <VerifyEmailAlertBottomSheet
        open={bottomSheetOpen === SheetType.MAILSENT}
        onClose={() => setBottomSheetOpen(SheetType.NONE)}
        email={form.email}
        waitlist={false}
      />
      {/* Password Helper */}
      <PasswordHelper
        open={bottomSheetOpen === SheetType.PASSWORDHELPER}
        onClose={() => setBottomSheetOpen(SheetType.NONE)}
      />
    </>
  );
}

const muiStyle = {
  gridHeader: {
    marginTop: '3.75em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridBottom: {
    marginBottom: '2em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  recaptchaBox: {
    display: 'flex',
    justifyContent: 'center',
    my: '1.2em',
  },
  input: {
    '.MuiInputBase-input': {
      backgroundColor: COLORS.white_FFFFFF,
      width: '34vh',
      height: '3vh',
    },
  },
  signUp: {
    marginTop: '1em',
    marginBottom: '2em',
    itemAlign: 'center',
  },
} as SxStyles;

const useStyles = makeStyles((theme: IAppTheme) => ({
  divFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  profileImage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    minWidth: '37vh',
    maxWidth: '37vh',
  },
  bodyInvite: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '-0.7em',
  },
  renterTerms: {
    color: AppTheme.colors.persianBlue_2238CB,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  label: {
    color: COLORS.white_FFFFFF,
    marginTop: '1.5em',
    marginBottom: '0.5em',
    fontSize: '.813em',
  },
}));
