import { Box, Button, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Content } from '../../model/Content';
import { TermsAndConditionsType } from '../../model/enums/TermsAndConditionsType';
import AlertService from '../../services/AlertService';
import ContentService from '../../services/ContentService';
import { RequestStatus } from '../../utils/RequestStatus';
import AppHeader from '../commons/AppHeader';
import { AppLayout } from '../core/AppLayout';
import DOMPurify from 'dompurify';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import { AppAssets } from '../../assets';
import SimpleButton from '../commons/SimpleButton';

export const TermsAndConditions = () => {
  const location = useLocation();

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [termsAndConditions, setTermsAndConditions] = useState<Content | undefined>();

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  const fetchTermsAndConditions = async () => {
    const terms = new URLSearchParams(location.search).get('terms')!;

    setRequestStatus(RequestStatus.LOADING);

    (await ContentService.getTermsAndConditionsDetails(terms))
      .onSuccess((response) => {
        setTermsAndConditions(response);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        setRequestStatus(RequestStatus.ERROR);
        AlertService.showSnackCustomError(response);
      });
  };

  const getTitle = () => {
    const terms = new URLSearchParams(location.search).get('terms')!;
    switch (terms) {
      case TermsAndConditionsType.PRIVACY_TERMS:
        return 'Privacy terms and conditions'
      case TermsAndConditionsType.HOST_TERMS:
        return 'Host terms and conditions'
      case TermsAndConditionsType.RENTER_TERMS:
        return 'Renter terms and conditions'
      default:
        break;
    }
  };

  const getContent = () => {
    if (requestStatus === RequestStatus.LOADING) {
      return (
        <Container style={{ marginTop: '3em' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={fetchTermsAndConditions} />
        </Container>
      );
    }

    return (
      <>
        <Box sx={{ margin: '1em 5vw 2em 5vw', textAlign: 'justify' }}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(termsAndConditions?.description!),
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <SimpleButton
            children={'Close'}
            sx={{ width: '8em', marginBottom: '1em' }}
            onClick={() => closeTerm()}
          />
        </Box>
      </>
    );
  };

  const closeTerm = () => {
    window.close();
  }
  return (
    <AppLayout
      header={() => (
        <AppHeader
          content={getTitle()!}
          showBackButton={false}
          rightButton={
            <Button
              onClick={() => closeTerm()}>
              <AppAssets.CloseWhite />
            </Button >
          }
          minMarginTop
        />
      )}
      content={() => getContent()}
    />
  );
};
