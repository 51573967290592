import { useNavigate, useParams } from 'react-router-dom';
import reviewService from '../../services/ReviewService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { LeaveReview } from './LeaveReview';

export function LeaveReviewHost() {
  const navigate = useNavigate();
  const { externalId } = useParams();

  return (
    <LeaveReview
      userReviewTitle="Review the renter"
      checkExistingReview={async (externalId) => {
        await reviewService.getHostReviewForReservation(externalId);
      }}
      onSubmit={async (form) => await reviewService.createReviewFromHost(externalId!, form)}
      handleGoBack={() => {
        appNavigate(navigate, AppRoutes.HOST_RESERVATION_DETAIL, {
          externalId: externalId!,
        });
      }}
      bikeReview={false}
    />
  );
}
