import { v4 as uuidv4 } from "uuid";
import Resizer from "react-image-file-resizer";
import { ImageResizingProperties } from "../core/ImageResizingProperties";

class ImageUtils {
  createFileUsingBase64(dataUrl: string): File {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      extension = mime.split("/")[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    const filename = `${uuidv4()}-${extension}`;
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  resizeFile = (file: File, imageResizingProperties: ImageResizingProperties) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        imageResizingProperties.maxWidth,
        imageResizingProperties.maxHeight,
        imageResizingProperties.compressFormat,
        imageResizingProperties.quality,
        imageResizingProperties.rotation,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  }

}

export default new ImageUtils();
