import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';
import { COLORS } from '../../../external/pdl-common/utils/AppTheme';
import fourthSectionBackground from '../../../assets/img/LandingPage/fourthSection/background_fourth_section.jpeg';
import card1 from '../../../assets/img/LandingPage/fourthSection/card_a.png';
import card2 from '../../../assets/img/LandingPage/fourthSection/card_b.png';
import card3 from '../../../assets/img/LandingPage/fourthSection/card_c.png';

export default function FourthSection() {
  const theme = useTheme();
  const classes = useStyles();

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const customMedium = useMediaQuery(theme.breakpoints.up(1200));

  const descriptionSection = () => {
    return (
      <Grid className={classes.flex} justifyContent="center" alignItems="center">
        <Grid className={classes.flex} alignItems="flex-start" mt="1em" md={12} sm={12} xs={10}>
          <AppTypography
            type={PDLTypography.largeHeading}
            customStyles={{
              fontSize: md ? '3.125em' : '2em',
              color: COLORS.white_FFFFFF,
            }}
          >
            Rent your bike and <br />
            make money hosting
          </AppTypography>

          <Grid className={classes.titleDescription} mt="2.5em">
            <Box className={classes.flex}>
              <AppTypography
                type={PDLTypography.largeHeading}
                customStyles={{
                  fontSize: '1.5em',
                  color: COLORS.white_FFFFFF,
                }}
              >
                Extra income
              </AppTypography>
              <AppTypography
                type={PDLTypography.smallParagraph}
                customStyles={{
                  fontSize: '1em',
                  marginTop: '1em',
                  color: COLORS.white_FFFFFF,
                }}
              >
                Not using your bike? Got an extra <br />
                one to spare? Rent it out to other <br />
                performance riders in your area.
              </AppTypography>
            </Box>

            <Box className={classes.flex} ml="3em">
              <AppTypography
                type={PDLTypography.largeHeading}
                customStyles={{
                  fontSize: '1.5em',
                  color: COLORS.white_FFFFFF,
                }}
              >
                Guaranteed protection
              </AppTypography>
              <AppTypography
                type={PDLTypography.smallParagraph}
                customStyles={{
                  fontSize: '1em',
                  marginTop: '1em',
                  color: COLORS.white_FFFFFF,
                }}
              >
                We know the care that <br />
                performance bikes require, and <br />
                we offer a guaranteed protection.
              </AppTypography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const imageSection = () => {
    return customMedium ? (
      <Grid width="50%">
        <Box
          sx={{
            position: 'absolute',
            maxWidth: '100%',
            top: '167em',
            left: '0%',
            backgroundImage: `url(${fourthSectionBackground})`,
            backgroundPosition: md ? 'center' : 'left',
            backgroundSize: 'cover',
            width: '30vw',
            height: '33.7em',
            border: 'solid black 2px',
            borderRadius: '0 1.5em 1.5em 0',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            maxWidth: '100%',
            top: '164.8em',
            left: '22%',
            backgroundImage: `url(${card1})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '12em',
            height: '6.5em',
            border: 'solid white 2px',
            borderRadius: '1.5em',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            maxWidth: '100%',
            top: '174em',
            left: '24%',
            backgroundImage: `url(${card2})`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            width: '11em',
            height: '13.5em',
            border: 'solid white 2px',
            borderRadius: '1.5em',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            maxWidth: '100%',
            top: '190em',
            left: '15%',
            backgroundImage: `url(${card3})`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            width: '14em',
            height: '14.5em',
            border: 'solid white 2px',
            borderRadius: '1.5em',
          }}
        />
      </Grid>
    ) : (
      <Box
        sx={{
          backgroundImage: `url(${fourthSectionBackground})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          width: '80%',
          height: '50%',
          borderRadius: '1.5em',
        }}
      />
    );
  };

  return (
    <Grid
      item
      className={classes.fourthSection}
      flexDirection={customMedium ? 'row' : 'column'}
      justifyContent={customMedium ? 'center' : 'space-around'}
      minHeight={md ? '51em' : '60em'}
    >
      {imageSection()}
      {descriptionSection()}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleDescription: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  fourthSection: {
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    backgroundColor: COLORS.black_000000,
    padding: '2em 0 2em 0',
    overflow: 'hidden',
  },
}));
