import { styled, Typography } from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system';
import { AppFontFamily } from '../../utils/AppFontFamily';

interface Props {
  content: string;
  styles?: React.CSSProperties;
  sx?: SxProps;
}

const CustomTypography = styled(Typography)({
  color: 'black',
  textAlign: 'left',
  fontFamily: AppFontFamily.SHAPE_BOLD,
  fontSize: '1.5em',
});

const AppTitle = ({ content, styles, sx }: Props) => {
  return (
    <CustomTypography sx={sx} style={styles}>
      {content}
    </CustomTypography>
  );
};

export default AppTitle;
