import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';
import { COLORS } from '../../../external/pdl-common/utils/AppTheme';
import secondSectionImage from '../../../assets/img/LandingPage/secondSection/second_section.png';
import secondSectionImageA from '../../../assets/img/LandingPage/secondSection/second_section_a.png';
import secondSectionImageB from '../../../assets/img/LandingPage/secondSection/second_section_b.png';
import secondSectionImageC from '../../../assets/img/LandingPage/secondSection/second_section_c.png';

export default function SecondSection() {
  const theme = useTheme();
  const classes = useStyles();

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const customMedium = useMediaQuery(theme.breakpoints.up(1200));

  const sectionDescription = () => {
    return (
      <Grid className={classes.flex} width="100%" alignItems="center">
        <Grid md={8} sm={12} xs={9}>
          <AppTypography
            type={PDLTypography.largeHeading}
            customStyles={{ fontSize: md ? '3.125em' : '2em' }}
          >
            Peer-to-peer <br />
            performance bike rentals
          </AppTypography>

          <Grid className={classes.titleDescription} mt="1.5em">
            <Box className={classes.flex}>
              <AppTypography type={PDLTypography.largeHeading} customStyles={{ fontSize: '1.5em' }}>
                Explore new cities
              </AppTypography>
              <AppTypography
                type={PDLTypography.smallParagraph}
                customStyles={{ fontSize: '1em', marginTop: '1em' }}
              >
                Keep your cadence, no matter <br />
                where the road takes you.
              </AppTypography>
            </Box>

            <Box className={classes.flex} ml="3em">
              <AppTypography type={PDLTypography.largeHeading} customStyles={{ fontSize: '1.5em' }}>
                Meet other cyclists
              </AppTypography>
              <AppTypography
                type={PDLTypography.smallParagraph}
                customStyles={{ fontSize: '1em', marginTop: '1em' }}
              >
                Make conections, swap routes, <br />
                and make life-long friends.
              </AppTypography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const imageSection = () => {
    return customMedium ? (
      <Grid width="50%">
        <Box
          sx={{
            position: 'absolute',
            zIndex: -100,
            maxWidth: '100%',
            top: '64em',
            right: '0%',
            backgroundImage: `url(${secondSectionImage})`,
            backgroundPosition: md ? '25%' : '40%',
            backgroundSize: 'cover',
            width: '30vw',
            height: '42em',
            borderRadius: '2.5em 0 0 2.5em',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            zIndex: -80,
            maxWidth: '100%',
            top: '68em',
            right: '24%',
            backgroundImage: `url(${secondSectionImageA})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '13.5em',
            height: '25em',
            border: 'solid black 2px',
            borderRadius: '1.5em',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            zIndex: -80,
            maxWidth: '100%',
            top: '98em',
            right: '22%',
            backgroundImage: `url(${secondSectionImageB})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '13em',
            height: '9.5em',
            border: 'solid white 2px',
            borderRadius: '1.5em',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            zIndex: -80,
            maxWidth: '100%',
            top: '92em',
            left: '84%',
            backgroundImage: `url(${secondSectionImageC})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '11em',
            height: '18em',
            border: 'solid black 2px',
            borderRadius: '1.5em',
          }}
        />
      </Grid>
    ) : (
      <Box
        sx={{
          backgroundImage: `url(${secondSectionImage})`,
          backgroundPosition: sm ? 'right' : 'center',
          backgroundSize: 'cover',
          width: '80%',
          height: '50%',
          borderRadius: '1.5em',
        }}
      />
    );
  };

  return (
    <Grid
      item
      className={classes.secondSection}
      flexDirection={customMedium ? 'row' : 'column-reverse'}
      justifyContent={customMedium ? 'center' : 'space-around'}
    >
      {sectionDescription()}
      {imageSection()}
    </Grid>
  );
}

const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  secondSection: {
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    height: '56.5em',
    borderRadius: '2.5em',
    backgroundColor: COLORS.white_FFFFFF,
    zIndex: '-1',
    padding: '2em 0 2em 0',
  },
  titleDescription: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
}));
