import { Grid, Container, Divider, ButtonBase, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BikeService from '../../services/BikeService';
import Logger from '../../external/pdl-common/utils/Logger';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AppLayout } from '../core/AppLayout';
import BikeRatingCarousel from '../Ratings/BikeRatingCarousel';
import Price from '../commons/Price';
import SimpleButton from '../commons/SimpleButton';
import PartDetailsBottomSheet from '../commons/PartDetailsBottomSheet';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import AppTheme from '../../utils/AppTheme';
import { PDLTypography } from '../Typography/PDLTypography';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { Bike } from '../../external/pdl-common/model/Bike';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import StringUtils from '../../utils/StringUtils';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import RoundedImageWithText from '../../external/pdl-common/components/RoundedImageWithText';
import { BikeFilters } from '../../model/utils/BikeFilters';
import Location from '../../external/fox-typescript/core/Location';
import UserService from '../../services/UserService';
import { UserAdditionalInfoCheck } from '../../model/UserAdditionalInfoCheck';
import { useFeatureToggle } from '../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../external/pdl-common/utils/enums/Features';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { BikeStatus } from '../../external/pdl-common/utils/enums/BikeStatus';
import BikeUtils from '../../external/pdl-common/utils/BikeUtils';

const logger = new Logger('BikeDetail');

const BikeDetail = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation().state as any;
  const { externalId } = useParams();

  const startDate = location?.startDate as Date | undefined;
  const endDate = location?.endDate as Date | undefined;
  const searchLocation = location?.searchLocation as Location | undefined;
  const filters = location?.filters as BikeFilters | undefined;

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [open, setOpen] = useState<boolean>(false);
  const [bike, setBike] = useState<Bike | undefined>(undefined);
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [isInsuranceFeatureEnabled] = useFeatureToggle(Features.INSURANCE);
  const [userAdditionalInfo, setUserAdditionalInfo] = useState<boolean | undefined>(
    undefined
  );

  const areRentalDatesAvailable: boolean = startDate !== undefined && endDate !== undefined;
  const bikeActive: boolean = bike?.status === BikeStatus.ACTIVE;
  const breakpointWidth = useMediaQuery('(max-width: 1100px)');

  useEffect(() => {
    load();
    loadUserAdditionalInfo();

    setTimeout(() => {
      /*
       * We add the bike to the recently viewed list after 5 seconds have passed.
       * This is used to avoid multiple requests (such as if the user reloads the
       * page multiple times in a short period of time).
       */
      addToRecentlyViewed();
    }, 5000);
  }, [externalId]);

  const handleClickOpenModal = () => {
    setOpen(true);
  };

  const addToRecentlyViewed = () => {
    if (externalId) {
      try {
        // Add the bike to the recently viewed list.
        BikeService.addToRecentlyViewed(externalId);
      } catch (e) {
        logger.error('Error adding bike to recently viewed', e);
      }
    }
  };

  const loadUserAdditionalInfo = async () => {
    const stored = localStorage.getItem("additionalInformationComplete")
    const additionalInformationComplete = stored ? JSON.parse(stored) : false;
    if (additionalInformationComplete) {
      setUserAdditionalInfo(JSON.parse(additionalInformationComplete));
    } else {
      (await UserService.checkUserAdditionalInfo())
        .onSuccess((response) => {
          const completed = response.getContent().complete;
          localStorage.setItem("additionalInformationComplete", JSON.stringify(completed));
          setUserAdditionalInfo(completed);
        })
        .onError((response) => {
          logger.error('Error fetching additional information from user', response.getContent());
          localStorage.setItem("additionalInformationComplete", "false");
        });
    }
  };

  const goToCalendar = () => {
    if (bike?.status === BikeStatus.ACTIVE) {
      appNavigate(navigate, AppRoutes.EXPLORE_CALENDAR, null, {
        state: { externalId, requestFromBikeDetail: true, bike, userAdditionalInfo },
      });
    }
  }
  const load = async () => {
    if (externalId) {
      setRequestStatus(RequestStatus.LOADING);

      (await BikeService.getPublicBike(externalId))
        .onSuccess((response) => {
          setBike(response.getContent());
          setRequestStatus(RequestStatus.SUCCESS);
        })
        .onError((response) => {
          logger.error('Error fetching bike detail', response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    }
  };

  const renderRentalDatesText = () => {
    return areRentalDatesAvailable ? (
      <p style={{ textDecoration: 'underline' }}>
        {DateUtils.formattedRangeDates(startDate!, endDate!)}
      </p>
    ) : (
      <p
        style={{
          textDecoration: bikeActive ? 'underline' : 'none',
          color: bikeActive ? AppTheme.colors.black_000000 : AppTheme.colors.red_FF0000
        }}
        onClick={() => goToCalendar()}
      >
        {bikeActive ? "View availability" : "Bike not active"}
      </p >
    );
  };

  const handleSubmitButton = () => {
    if (areRentalDatesAvailable) {
      const defaultState = { startDate, endDate, bike };

      if (userAdditionalInfo) {
        const route: AppRoutes = AppRoutes.INSURANCE

        appNavigate(navigate, route, null, {
          state: {
            startDate,
            endDate,
            bike,
          },
        });
      } else {
        appNavigate(navigate, AppRoutes.COMPLETE_ADDITIONAL_INFORMATION, null, {
          state: {
            ...defaultState,
            userAdditionalInfo,
            navigateTo: AppRoutes.INSURANCE,
          },
        });
      }
    } else {
      appNavigate(navigate, AppRoutes.EXPLORE_CALENDAR, null, {
        state: { externalId, requestFromBikeDetail: true, bike, userAdditionalInfo },
      });
    }
  };

  const getContent = (): JSX.Element => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={load} />
        </div>
      );
    }

    const handleAddToWishList = async () => {
      if (bike?.externalId === undefined) return;
      try {
        const response = await BikeService.addToWishList(bike.externalId!);
        setBike({ ...bike, favourite: response.getContent() });
      } catch (error) {
        logger.error('Error trying to add bike to wishlist. ', error);
      }
    };

    const handleOnBack = () => {
      if (filters) {
        appNavigate(navigate, AppRoutes.SEARCH, null, {
          state: {
            searchLocation: searchLocation,
            startDate: startDate,
            endDate: endDate,
            searchFilters: filters,
          },
        });
        return;
      }
      navigate(-1);
    };

    return bike ? (
      <>
        <Container sx={{ p: 0, textAlign: 'left' }} className={classes.mainContainer}>
          <Grid item xl={12} xs={12}>
            {/* Bike images */}
            <Carousel
              bike={bike}
              addToWishList={handleAddToWishList}
              showTopBar
              handleOnBack={handleOnBack}
              showIndexIndicator
              slidesToShow={{
                lg: 1,
                md: 1,
                sm: 1,
              }}
              data={bike.images}
              renderItem={(img) => (
                <AppImage
                  carousel={!breakpointWidth}
                  key={img.externalId}
                  src={img.url}
                  alt={bike.title}
                  className={classes.mainImage}
                  onClick={() => {
                    setOpenGallery(true);
                  }}
                  loaderstyles={{ maxHeight: '45em' }}
                  unloaderstyles={{ maxHeight: '45em' }}
                />
              )}
            />

            <Container sx={muiStyles.innerContainer}>
              {/* Title */}
              <p className={classes.title}>{bike.title}</p>
              <Divider />

              {/* Hosted By */}
              <p className={classes.subtitle}>Hosted by</p>
              <RoundedImageWithText
                src={bike.owner.user.avatarUrl}
                alt={StringUtils.formatShortUserName(bike.owner.user)}
                title={StringUtils.formatShortUserName(bike.owner.user)}
                titleTypography={{
                  type: PDLTypography.smallHeading,
                  customStyles: { fontSize: '1em' },
                  customClasses: classes.mpZero,
                }}
                subtitleTypography={{
                  type: PDLTypography.smallParagraph,
                  customStyles: { fontSize: '0.813em', color: 'grey' },
                  customClasses: classes.mpZero,
                }}
                subtitle={
                  bike.owner.avgResponseTime
                    ? `Typically responds in ${DateUtils.formatDistance(
                      bike.owner.avgResponseTime
                    )}`
                    : ''
                }
              />
              <div style={{ display: 'flex' }}>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Rental dates</p>
                  {renderRentalDatesText()}
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Pick-up and Return</p>
                  <p>
                    {[bike.location.city, bike.location.state, bike.location.zipCode]
                      .filter(Boolean)
                      .join(', ')}
                  </p>
                </Grid>
              </div>
              <Divider />

              {/* About this bike */}
              <p className={classes.subtitle}>About this bike</p>
              <p>{bike.description}</p>
              <div style={{ display: 'flex' }}>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Make</p>
                  <p>{bike.customMake || bike.make}</p>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Model</p>
                  <p>{bike.model}</p>
                </Grid>
              </div>
              <div style={{ display: 'flex' }}>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Type</p>
                  <p>{bike.type}</p>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Frame Material</p>
                  <p>{bike.frameMaterial}</p>
                </Grid>
              </div>
              <div style={{ display: 'flex' }}>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Year</p>
                  <p>{bike.year}</p>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Color</p>
                  <p>{bike.customColor || bike.color}</p>
                </Grid>
              </div>
              <div style={{ display: 'flex' }}>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Condition</p>
                  <p>{BikeUtils.getDefaultOrCustomProperty(bike, 'condition')}</p>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Size</p>
                  <p>{bike.size}</p>
                </Grid>
              </div>
              <div style={{ display: 'flex' }}>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Saddle Height</p>
                  <p>{bike.topSaddleHeight} cm</p>
                </Grid>
                <Grid item xs={6}>
                  <p className={classes.italicText}>Reach</p>
                  <p>{bike.bottomSaddleHeight} cm</p>
                </Grid>
              </div>

              <ButtonBase sx={muiStyles.partDetailsButton} onClick={handleClickOpenModal}>
                <VisibilityIcon />
                <p style={{ marginLeft: '0.3em' }}>View Part Details</p>
              </ButtonBase>
              <Divider />

              {/* Tier Pricing */}
              {BikeUtils.hasTierPricing(bike) && (
                <div>
                  <Divider />
                  <p className={classes.subtitle}>Tier Pricing</p>
                  <div style={{ display: 'flex' }}>
                    <Grid item xs={6}>
                      <p className={classes.italicText}>Duration</p>
                      <p>1 day</p>
                      <p>2 days</p>
                      <p>3+ days</p>
                    </Grid>
                    <Grid item xs={6}>
                      <p className={classes.italicText}>Cost per day</p>
                      <p>${bike.customPrice}</p>
                      <p>${bike.twoDayPrice}</p>
                      <p>${bike.threePlusDayPrice}</p>
                    </Grid>
                  </div>
                  <Divider />
                </div>
              )}
              {/* Ratings and reviews */}
              <p className={classes.subtitle}>Ratings and reviews</p>
              <BikeRatingCarousel
                externalId={externalId!}
                totalRides={bike.totalRides}
                avgStars={Math.round(bike.avgStars)}
              />
              <Divider />

              {/* Reserve */}
              <Grid
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={muiStyles.reserveContainer}
              >
                <Grid item xs={areRentalDatesAvailable ? 6 : 4}>
                  <Price
                    customPrice={areRentalDatesAvailable ? bike.customPrice : undefined}
                    price={areRentalDatesAvailable ? BikeUtils.dailyPrice(bike, startDate!, endDate!) : bike.customPrice} />
                </Grid>
                <Grid item xs={areRentalDatesAvailable ? 6 : 8}>
                  <SimpleButton onClick={() => handleSubmitButton()} disabled={!bikeActive} >
                    {areRentalDatesAvailable ? <> Reserve </> : <> View availability </>}
                  </SimpleButton>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Container >
        <PartDetailsBottomSheet bike={bike} open={open} onClose={() => setOpen(false)} />
      </>
    ) : (
      <></>
    );
  };

  return <AppLayout content={() => getContent()} />;
};

export default BikeDetail;

const muiStyles = {
  innerContainer: {
    fontFamily: 'Opens Sans',
    paddingLeft: '2.5em',
    paddingRight: '2.5em',
  },
  partDetailsButton: {
    color: AppTheme.colors.persianBlue_2238CB,
    fontFamily: 'Opens Sans Bold',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1em',
  },
  reserveContainer: {
    marginTop: '0.5em',
    marginBottom: '0.7em',
  },
};

const useStyles = makeStyles({
  mainImage: {
    width: '100%',
    maxHeight: '45em',
    objectFit: "contain",
  },
  title: {
    color: 'black',
    fontSize: '1.5em',
    fontFamily: 'Opens Sans Bold',
  },
  subtitle: {
    color: 'black',
    fontSize: '1.188em',
    fontFamily: 'Opens Sans Bold',
  },
  name: {
    fontSize: '1em',
    fontFamily: 'Opens Sans Bold',
  },
  italicText: {
    fontSize: '0.875em',
    fontFamily: 'Opens Sans Italic',
  },
  mpZero: {
    margin: 0,
    padding: 0,
  },
  mainContainer: {
    '@media ': {
      maxWidth: '1100px'
    },
  }
});
