import React, { FormEvent, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { AppTextField } from '../commons/AppTextField';
import SimpleButton from '../commons/SimpleButton';
import { useNavigate } from 'react-router';
import { makeStyles } from '@mui/styles';
import { COLORS, IAppTheme } from '../../utils/AppTheme';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import CustomAuthService from '../../external/fox-typescript/services/CustomAuthService';
import AuthService from '../../external/fox-typescript/services/AuthService';
import { SxStyles } from '../../model/utils/SxStyles';
import { RequestStatus } from '../../utils/RequestStatus';
import ForgotPasswordBottomSheet from '../Account/ForgotPasswordBottomSheet';
import AlertService from '../../services/AlertService';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { useForm } from '../../hooks/useForm';
import UserService from '../../services/UserService';
import { WelcomeToPdlClub } from '../commons/WelcomeToPdlClub';
import { FooterPdlClub } from '../commons/FooterPdlClub';
import TopNavBar from '../core/LandingPageNavBar';
import { useLocation } from 'react-router-dom';
import { UserAdditionalInfoCheck } from '../../model/UserAdditionalInfoCheck';

interface Props {
  setIsLogged: (value: boolean) => void;
}

export interface SignInForm {
  email: string;
  password: string;
}

export default function BlackSignIn({ setIsLogged }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const [form, , handleCustomChange, formIsValid] = useForm<SignInForm>({
    email: '',
    password: '',
  });
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);

  const onKeyDown = async (e: React.KeyboardEvent) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      if (formIsValid()) {
        await handleSignIn();
      }
    }
  };

  const handleSignIn = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const result = await CustomAuthService.validateLoginWithEmailAndPassword(
        form.email,
        form.password
      );

      const { token, expiresAt, userExternalId } = result.getContent();

      AuthService.storeToken(token, expiresAt);
      AuthService.storeExternalId(userExternalId || '');

      setIsLogged(true);

      setRequestStatus(RequestStatus.SUCCESS);

      // We remove variables from localstorage on login, in case multiple users log in from the same device.
      localStorage.removeItem("opt-off");
      localStorage.removeItem("termsAndConditions");
      const userAdditionalInfo = (await UserService.checkUserAdditionalInfo()).getContent();
      if (userAdditionalInfo.complete) {
        localStorage.setItem("additionalInformationComplete", "true");
      } else {
        localStorage.removeItem("additionalInformationComplete");
      }
      redirection(userAdditionalInfo);

    } catch (error: any) {
      if (!error || !error.response) {
        AlertService.showSnackCustomError('Internal Server Error');
      } else if (error?.response?.status === 401) {
        AlertService.showSnackCustomError('The email or password does not match.');
      } else if (error.response.status === 500) {
        AlertService.showSnackCustomError('You have not activated your account.');
      } else {
        AlertService.showSnackCustomError(error.response.data.message);
      }

      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const redirection = (userAdditionalInfo: UserAdditionalInfoCheck) => {
    const state = location?.state as any;

    // If the user is trying to access a specific path, we navigate to that path after the login
    if (state?.previousPath) {
      appNavigate(navigate, state.previousPath, null);
    }

    // If the user hasn't completed the additional information, we navigate to that view to complete it
    if (!userAdditionalInfo.complete) {
      appNavigate(navigate, AppRoutes.COMPLETE_ADDITIONAL_INFORMATION, null, {
        state: { userAdditionalInfo },
      });
    }
  };

  const handleOnSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (formIsValid()) {
      handleSignIn();
    }
  }

  return (
    <form onSubmit={async(event) => {
      handleOnSubmit(event);
    }}>
      <Box sx={{ backgroundColor: COLORS.black_000000 }}>
        <TopNavBar showBackButton />
        <WelcomeToPdlClub />
        <Grid container spacing={8} columns={1} sx={muiStyle.gridHeader}>
          <Grid item xs={12} sx={muiStyle.gridHeader}>
            <AppTypography type={PDLTypography.largeHeading} customClasses={classes.title}>
              Login to your account
            </AppTypography>
          </Grid>
          <Grid item xs={12} sx={{ mx: 4 }}>
            <Grid sx={muiStyle.gridBody}>
              <AppTextField
                label={'Email'}
                name={'email'}
                value={form.email}
                onInputChange={handleCustomChange}
                inputProps={{ sx: muiStyle.input, type: 'email' }}
                labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                restrict={'email'}
                realTimeValidation
                required
                onKeyPress={(e) => onKeyDown(e)}
              />

              <AppTextField
                label={'Password'}
                name={'password'}
                value={form.password}
                onInputChange={handleCustomChange}
                inputProps={{ sx: muiStyle.input, type: 'password' }}
                labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                realTimeValidation
                required
                minLength={4}
                onKeyPress={(e) => onKeyDown(e)}
              />

              <AppTypography
                type={PDLTypography.subHeading}
                customClasses={classes.clickableSubHeading}
                customStyles={{ marginTop: '1em' }}
                onClick={() => setShowForgotPassword(true)}
              >
                Forgot password?
              </AppTypography>

              <SimpleButton
                sx={{ marginBottom: '3em', marginTop: '2em', width: '37vh' }}
                children={'Sign In'}
                onClick={handleSignIn}
                type={'submit'}
                disabled={requestStatus === RequestStatus.LOADING || !formIsValid()}
                isLoading={requestStatus === RequestStatus.LOADING}
              />

              <AppTypography
                type={PDLTypography.smallParagraph}
                customClasses={classes.signUp}
                customStyles={{ width: '37vh' }}
                onClick={() => appNavigate(navigate, AppRoutes.INVITE_CODE, null)}
                children={'Need an account? Sign Up'}
              />
            </Grid>
          </Grid>
          {/* Forgot password  */}
          <ForgotPasswordBottomSheet
            open={showForgotPassword}
            onClose={() => setShowForgotPassword(false)}
          />
        </Grid>
        <FooterPdlClub dense />
      </Box>
    </form>
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  title: {
    color: COLORS.white_FFFFFF,
  },
  clickableSubHeading: {
    color: COLORS.white_FFFFFF,
    cursor: 'pointer',
    userSelect: 'none',
  },
  signUp: {
    color: COLORS.white_FFFFFF,
    cursor: 'pointer',
    fontSize: '1em',
    textAlign: 'center',
    marginTop: '0',
    marginBottom: '5em',
  },
  label: {
    color: COLORS.white_FFFFFF,
    marginTop: '1.5em',
    marginBottom: '0.5em',
    fontSize: '.813em',
  },
}));

const muiStyle: SxStyles = {
  gridHeader: {
    marginTop: '0em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: COLORS.black_000000,
  },
  gridBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  gridBottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: COLORS.black_000000,
  },
  input: {
    '.MuiInputBase-input': {
      backgroundColor: COLORS.white_FFFFFF,
      width: '34vh',
      height: '3vh',
    },
  },
};
