import { useState, useEffect } from 'react';
import { AppLayout } from '../../core/AppLayout';
import { Box, ButtonBase, Container } from '@mui/material';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import { makeStyles } from '@mui/styles';
import { IAppTheme } from '../../../utils/AppTheme';
import { SxProps } from '@mui/system';
import { FluidContainer } from '../../commons/FluidContainer';
import { BikeCard } from '../../Bikes/BikeCard';
import { AppAssets } from '../../../assets';
import { HostCustomPriceModal } from '../../Host/HostCustomPriceModal';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationModal from '../../commons/NotificationModal';
import Logger from '../../../external/pdl-common/utils/Logger';
import BikeService from '../../../services/BikeService';
import { useLocation } from 'react-router-dom';
import BikePriceInfoCreation from '../../../model/update/bike/BikePriceInfoCreation';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { RequestStatus } from '../../../utils/RequestStatus';
import { Bike } from '../../../external/pdl-common/model/Bike';
import { BikeStatus } from '../../../model/BikeStatus';
import RequestStatusFeedBack from '../../commons/RequestStatusFeedback';
import AlertService from '../../../services/AlertService';
import { BikeReservationManagePayments } from '../../Reservation/BikeReservationManagePayments';
import StripeService from '../../../services/StripeService';
import DoubleButtons from '../../commons/DoubleButtons';
import { useFeatureToggle } from '../../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../../external/pdl-common/utils/enums/Features';
import { AlertDialog } from '../../commons/AlertDialog';

const logger = new Logger('HostApproved');

export const BikeActivation = () => {
  // States we pass between views
  const location = useLocation().state as any;
  const params = useParams<'externalId'>();

  const classes = useStyles();
  const navigate = useNavigate();

  const [showCustomPrice, setShowCustomPrice] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showTieredPriceModal, setShowTieredPriceModal] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState(RequestStatus.SUCCESS);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [bike, setBike] = useState<Bike | undefined>(location?.bike);
  const [paymentMethod, setPaymentMethod] = useState<string>('');

  const [isCreditCardMandatory] = useFeatureToggle(Features.IS_CREDIT_CARD_MANDATORY_ENABLED);

  // In this way we intercept the default behavior when the user goes backwards
  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onBack);

    return () => {
      window.removeEventListener('popstate', onBack);
    };
  }, []);

  useEffect(() => {
    if (!bike) {
      loadBike();
    } else {
      setRequestStatus(RequestStatus.SUCCESS);
    }

    if (isCreditCardMandatory) {
      loadPaymentMethod();
    }
  }, []);

  const loadBike = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const res = await BikeService.getByExternalId(params.externalId!);
      const bikeFromRes = res.getContent();

      if (bikeFromRes.status != BikeStatus.APPROVED) {
        appNavigate(navigate, AppRoutes.HOST, null);
        return;
      }

      setBike(bikeFromRes);
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error) {
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const loadPaymentMethod = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const response = await StripeService.getCurrentCreditCard();
      setPaymentMethod(response.getContent());

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setRequestStatus(RequestStatus.SUCCESS);
      } else {
        logger.error('Error getting stripe credit card', err);
        setRequestStatus(RequestStatus.ERROR);
      }
    }
  };

  const handleListBike = async (price?: number | string) => {
    try {
      setSubmitStatus(RequestStatus.LOADING);

      const bikePriceInfoCreation = new BikePriceInfoCreation(Number(price));
      const result = await BikeService.activateBike(bike!.externalId!, bikePriceInfoCreation);

      setSubmitStatus(RequestStatus.SUCCESS);
      setShowSuccessModal(!!result);
    } catch (error: any) {
      setSubmitStatus(RequestStatus.ERROR);
      logger.error('Error when listing the bike', error);
      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }
    } finally {
      setShowCustomPrice(false);
    }
  };

  const onBack = () => {
    appNavigate(navigate, AppRoutes.HOST_BIKE_LISTINGS, null);
  };

  return (
    <AppLayout
      content={() => {
        if (requestStatus != RequestStatus.SUCCESS) {
          return (
            <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
              <RequestStatusFeedBack status={requestStatus} onRetry={loadBike} />
            </div>
          );
        }

        return (
          <FluidContainer className={classes.fluidContainer}>
            <Box sx={styles.container}>
              <Container maxWidth={'md'} sx={styles.baseLayout}>
                <ButtonBase
                  sx={styles.closeButton}
                  onClick={() => appNavigate(navigate, AppRoutes.HOST, null, {})}
                >
                  <AppAssets.Close />
                </ButtonBase>

                <BikeCard
                  title={bike?.title!}
                  size={bike?.size}
                  imageUrl={bike?.images && bike.images.length > 0 ? bike.images[0].url : ''}
                  hideDivider
                  onClick={() => {}}
                  rightIcon={<></>}
                />

                <Container maxWidth={'md'} sx={styles.descriptionContainer}>
                  <AppTypography
                    type={PDLTypography.largeHeading}
                    customStyles={{ textAlign: 'center', marginBottom: 10 }}
                  >
                    Your bike has been approved!
                  </AppTypography>

                  <Container sx={styles.textContainer}>
                    <AppTypography
                      type={PDLTypography.paragraph}
                      customStyles={{ textAlign: 'center', marginBottom: 20 }}
                    >
                      Based on your bike's details, we suggest listing it for this price.
                    </AppTypography>

                    <AppTypography
                      type={PDLTypography.largeHeading}
                      customStyles={{ marginBottom: 10 }}
                    >
                      {`$${bike?.listPrice}`}
                    </AppTypography>

                    {isCreditCardMandatory && !paymentMethod && (
                      <Container sx={styles.paymentContainer}>
                        <AppTypography
                          type={PDLTypography.mediumHeading}
                          customStyles={{ paddingBottom: '0.5em' }}
                        >
                          Payment method to apply penalties
                        </AppTypography>
                        <BikeReservationManagePayments
                          paymentMethod={paymentMethod}
                          onNewPaymentMethod={setPaymentMethod}
                        />
                      </Container>
                    )}

                    <DoubleButtons
                      leftBtnLabel="Accept price"
                      rigthBtnLabel="Custom price"
                      onLeftClick={() => handleListBike(bike!.listPrice)}
                      onRightClick={() => setShowCustomPrice(true)}
                      separatedButtons
                    />
                  </Container>
                </Container>

                <HostCustomPriceModal
                  open={showCustomPrice}
                  suggestedPrice={bike?.listPrice!}
                  onClick={(newPrice) => handleListBike(newPrice)}
                  onClose={() => setShowCustomPrice(false)}
                  disabled={
                    (isCreditCardMandatory && !paymentMethod) ||
                    requestStatus !== RequestStatus.SUCCESS
                  }
                />

                <AlertDialog
                  title="One more step"
                  content="Set the tiered price for reservations of more than one day"
                  open={showTieredPriceModal}
                  onConfirm={() =>
                    appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT_TIERED_PRICE, {
                      externalId: params.externalId!,
                    })
                  }
                  confirmLabel={'Show me'}
                  cancelLabel={'Not now'}
                  onCancel={() => {
                    setShowTieredPriceModal(false);
                    appNavigate(navigate, AppRoutes.HOST_BIKE_LISTINGS, null);
                  }}
                />

                <NotificationModal
                  open={showSuccessModal}
                  text={'Your bike has been listed'}
                  onClose={() => {
                    setShowSuccessModal(false);
                    setShowTieredPriceModal(true);
                  }}
                />
              </Container>
            </Box>
          </FluidContainer>
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  fluidContainer: {
    backgroundColor: theme.colors.white_EEEEEC,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const styles = {
  container: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  } as SxProps,
  baseLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    flex: 1,
    height: '100%',
    marginTop: 5,
    marginBottom: 2,
  } as SxProps,
  closeButton: {
    alignSelf: 'flex-end',
    marginBottom: 2,
  } as SxProps,
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    flex: 1,
    my: 5,
  } as SxProps,
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  } as SxProps,
  paymentContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    flex: 1,
    marginTop: '1em',
    marginBottom: '1em',
  } as SxProps,
};
