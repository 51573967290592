import { ResponseData } from '../../fox-typescript/core/ResponseData';
import ApiResponse from '../../fox-typescript/core/ApiResponse';
import toggleConfig from '../toggleConfig.json';
import { ToggleItem } from '../model/ToggleItem';

const featureToggleList: ToggleItem[] = toggleConfig;

class FeatureToggleService {
  getFeatureToggleItem = async (id: string): Promise<ApiResponse<ToggleItem | undefined>> => {
    const item = featureToggleList.find((i) => i.id === id);

    const response: ResponseData<ToggleItem | undefined> = {
      content: item,
      statusCode: item ? 200 : 404,
      success: !!item,
      responseCode: item ? 'OK' : 'NOT FOUND',
    };

    return Promise.resolve(ApiResponse.fromData(response));
  };
}
export default new FeatureToggleService();
