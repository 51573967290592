import { Container, Divider } from '@mui/material';
import { useState } from 'react';
import {useLocation, useNavigate, } from 'react-router-dom';
import { Bike } from '../../../../external/pdl-common/model/Bike';
import Reservation from '../../../../model/Reservation';
import { COLORS } from '../../../../utils/AppTheme';
import AppHeader from '../../../commons/AppHeader';
import { AppTextField } from '../../../commons/AppTextField';
import ListingLink from '../../../commons/ListingLink';
import NotificationModal from '../../../commons/NotificationModal';
import SimpleButton from '../../../commons/SimpleButton';
import { AppLayout } from '../../../core/AppLayout';
import ReportIssueService from '../../../../services/ReportIssueService';
import ReportIssueCreation from '../../../../model/creation/ReportIssueCreation';
import Logger from '../../../../external/pdl-common/utils/Logger';
import AlertService from '../../../../services/AlertService';
import DateUtils from '../../../../external/pdl-common/utils/DateUtils';
import RequestSupportCreation from '../../../../model/creation/RequestSupportCreation';
import { RequestStatus } from '../../../../utils/RequestStatus';
import { appNavigate, AppRoutes } from '../../../../utils/AppNavigation';
import AuthService from '../../../../external/fox-typescript/services/AuthService';
import Navbar from '../../../core/Navbar';

const logger = new Logger('ReportIssue');

export function ReportIssue() {
  const location = useLocation();
  const navigate = useNavigate();

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [requestStatusPickUp, setRequestStatusPickUp] = useState<RequestStatus>(
    RequestStatus.SUCCESS
  );

  const reservation = location?.state as Reservation;
  const bike: Bike = reservation?.bike;

  const handleInputChange = (inputName: string, inputValue: string) => {
    setMessage(inputValue);
  };

  const checkPickUpValidations = (reservation: Reservation): boolean => {
    const isRenter = reservation?.renter?.user?.externalId === AuthService.getExternalId();

    return (
      isRenter &&
      DateUtils.greaterThanTodayMinusHoursInTimezone(
        new Date(reservation.startDate),
        reservation.bike.location.timezoneId!,
          12
      ) &&
      !DateUtils.greaterThanTodayInTimezone(
        new Date(reservation.endDate),
        reservation.bike.location.timezoneId!
      ) &&
      !reservation.pickedUpAt
    );
  };

  const createReportIssue = async () => {
    setRequestStatus(RequestStatus.LOADING);
    const reportIssue = new ReportIssueCreation(message, reservation.externalId!);

    (await ReportIssueService.createIssue(reportIssue))
      .onSuccess(() => {
        setNotificationOpen(true);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to report an issue', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
        AlertService.showSnackCustomError(response.getContent());
      });
  };

  const createRequestSupport = async () => {
    setRequestStatus(RequestStatus.LOADING);
    const requestSupport = new RequestSupportCreation(message);

    (await ReportIssueService.createSupport(requestSupport))
      .onSuccess(() => {
        setNotificationOpen(true);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to requesting support', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
        AlertService.showSnackCustomError(response.getContent());
      });
  };

  const startPickUp = async () => {
    setRequestStatusPickUp(RequestStatus.LOADING);
    const reportIssue = new ReportIssueCreation(message, reservation.externalId!);
    (await ReportIssueService.createIssue(reportIssue))
      .onSuccess(() => {
        setNotificationOpen(true);
        setTimeout(() => {
          setNotificationOpen(false);
          setRequestStatusPickUp(RequestStatus.SUCCESS);
          appNavigate(navigate, AppRoutes.RESERVATION_PICK_UP, {
            externalId: reservation.externalId!,
          });
        }, 2000);
      })
      .onError((response) => {
        logger.error('Error trying to report an issue', response.getContent());
        setRequestStatusPickUp(RequestStatus.ERROR);
        AlertService.showSnackCustomError(response.getContent());
      });
  };

  return (
    <AppLayout
      noNavBar
      fromHome
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
      content={() => {
        return (
          <>
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <AppHeader
                content={reservation ? 'Report an issue' : 'Contact PDL Team'}
                onBack={() =>
                  navigate.length == 0
                    ? appNavigate(navigate, AppRoutes.ACCOUNT, null)
                    : navigate(-1)
                }
              />
              <Container sx={{ marginTop: '1.5em', width: '95%' }}>
                {reservation && (
                  <ListingLink
                    title={bike.title}
                    imageUrl={bike.images && bike.images.length > 0 ? bike.images[0].url : ''}
                    cells={[
                      {
                        label: 'Hosted by',
                        value: `${bike.owner.user.firstName} ${bike.owner.user.lastName}`,
                      },
                      {
                        label: 'Dates',
                        value: `${DateUtils.stringToShortDate(
                          reservation.startDate
                        )} - ${DateUtils.stringToShortDate(reservation.endDate)}`,
                      },
                    ]}
                  />
                )}
                {reservation && <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />}
                <form>
                  <AppTextField
                    label={
                      reservation
                        ? 'Please provide a detailed description of the issue'
                        : 'Please provide a detailed description of your request'
                    }
                    value={message}
                    name={'name'}
                    inputProps={{ rows: 10, multiline: true }}
                    onInputChange={(inputName: string, inputValue: string) =>
                      handleInputChange(inputName, inputValue)
                    }
                  />
                </form>
                <NotificationModal
                  open={notificationOpen}
                  text="Confirmed"
                  onClose={() => setNotificationOpen(false)}
                />
              </Container>
            </div>

            <div
              style={{
                padding: '1em 0em',
                borderTopWidth: '0.1em',
                borderTopStyle: 'solid',
                borderTopColor: COLORS.gray_E3E5E4,
                paddingBottom: 69
              }}
            >
              <Container sx={{ width: '95%' }}>
                <SimpleButton
                  type="submit"
                  children={reservation ? 'Report issue' : 'Request Support'}
                  disabled={message === '' || requestStatusPickUp == RequestStatus.LOADING}
                  onClick={reservation ? createReportIssue : createRequestSupport}
                  isLoading={requestStatus == RequestStatus.LOADING}
                />
                {reservation && checkPickUpValidations(reservation) && (
                  <SimpleButton
                    type="submit"
                    children={'Report issue and Start Pick-up'}
                    disabled={message === '' || requestStatus == RequestStatus.LOADING}
                    onClick={startPickUp}
                    isLoading={requestStatusPickUp == RequestStatus.LOADING}
                    sx={{ marginTop: '1em' }}
                  />
                )}
              </Container>
            </div>

            <NotificationModal
              open={notificationOpen}
              text={
                reservation ? 'Your issue has been reported.' : 'Your request has been submitted.'
              }
              onClose={() => {
                setNotificationOpen(false);
                navigate.length == 0
                  ? appNavigate(navigate, AppRoutes.ACCOUNT, null)
                  : navigate(-1)
              }}
            />
          <Navbar/>
          </>
        );
      }}
    />
  );
}
