import { Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import StripeService from '../../services/StripeService';
import { IAppTheme } from '../../utils/AppTheme';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import AppHeader from '../commons/AppHeader';
import { CardForm } from '../commons/CardForm';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import { AppLayout } from '../core/AppLayout';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import enviroment from '../../environment.json';
import AlertService from '../../services/AlertService';

const logger = new Logger('PaymentDetails');

const stripePromise = loadStripe(enviroment.stripeKey);

const PaymentDetails = () => {
  const classes = useStyles();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [paymentMethod, setPaymentMethod] = useState<string>('');

  useEffect(() => {
    getPaymentMethod();
  }, []);

  const getPaymentMethod = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const response = await StripeService.getCurrentCreditCard();
      setPaymentMethod(response.getContent());

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (e: any) {
      if (e?.response?.status != 404) {
        logger.error('Error fetching payment method', e);
        AlertService.showSnackCustomError(e?.response?.data?.message);
        setRequestStatus(RequestStatus.ERROR);
      } else {
        setRequestStatus(RequestStatus.SUCCESS);
      }
    }
  };

  const getContent = (): JSX.Element => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={getPaymentMethod} />
        </div>
      );
    }

    return (
      <Elements stripe={stripePromise}>
        <Container className={classes.container}>
          {paymentMethod && (
            <>
              <div className={classes.flexColumn}>
                <AppTypography
                  type={PDLTypography.largeHeading}
                  children={'Current payment method'}
                />
                <AppTypography
                  type={PDLTypography.paragraph}
                  children={`Card ending in ${paymentMethod}`}
                  customStyles={{ marginTop: '1.25em' }}
                />
              </div>
              <Divider sx={{ my: 3 }} />
            </>
          )}

          <div className={classes.flexColumn}>
            <AppTypography type={PDLTypography.largeHeading} children={'New card information'} />
            <AppTypography
              type={PDLTypography.paragraph}
              children={'Add a new card to replace the current payment method.'}
              customStyles={{ marginTop: '1.25em' }}
            />
          </div>
        </Container>

        <CardForm
          containerStyle={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
          buttonContainerClass={classes.buttonContainer}
          buttonText="Save Changes"
          onNewPaymentMethod={(paymentMethod) => {
            setPaymentMethod(paymentMethod);
          }}
        />
      </Elements>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content={'Payment details'} />}
      content={() => getContent()}
    />
  );
};

export default PaymentDetails;

const useStyles = makeStyles((theme: IAppTheme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
    },
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    position: 'fixed',
    bottom: 59,
    width: '100%',
    borderTop: '1px solid',
    borderTopColor: theme.colors.gray_E3E5E4,
    backgroundColor: theme.colors.white_FFFFFF,
    padding: '1em 0em',
  },
}));
