import { useState, useEffect } from 'react';
import { AppLayout } from '../../../core/AppLayout';
import AppHeader from '../../../commons/AppHeader';
import { useParams, useNavigate } from 'react-router-dom';
import BikeService from '../../../../services/BikeService';
import Logger from '../../../../external/pdl-common/utils/Logger';
import { AppTypography } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';
import { Alert, Box, Container, Divider, useMediaQuery } from '@mui/material';
import AppMenuList from '../../../commons/AppMenuList';
import { Menu } from '../../../commons/AppMenuList';
import { Carousel } from '../../../../external/pdl-common/components/commons/Carousel';
import { Bike } from '../../../../external/pdl-common/model/Bike';
import { appNavigate, AppRoutes } from '../../../../utils/AppNavigation';
import { RequestStatus } from '../../../../utils/RequestStatus';
import RequestStatusFeedBack from '../../../commons/RequestStatusFeedback';
import { BikeStatus } from '../../../../model/BikeStatus';
import AppImage from '../../../../external/pdl-common/components/commons/AppImage';
import SimpleButton from '../../../commons/SimpleButton';
import AlertService from '../../../../services/AlertService';
import { AlertDialog } from '../../../commons/AlertDialog';
import { makeStyles } from '@mui/styles';

const logger = new Logger('HostBikeEdit');


export const HostBikeEdit = () => {
  const { externalId } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const breakpointWidth = useMediaQuery('(max-width: 1200px)');

  const [bike, setBike] = useState<Bike | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  useEffect(() => {
    getBikeDetails();
  }, []);

  const getBikeDetails = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeService.getByExternalId(externalId!))
      .onSuccess((response) => {
        const bikeResponseContent = response.getContent();

        if (bikeResponseContent.status == BikeStatus.APPROVED) {
          appNavigate(navigate, AppRoutes.HOST_ACTIVATE_BIKE, { externalId: externalId! });
          return;
        }

        setBike(bikeResponseContent);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error fetching bike detail', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const onBack = () => {
    appNavigate(navigate, AppRoutes.HOST_BIKE_LISTINGS, null);
  };

  const renderImageCarousel = () => {
    return (
      <Carousel
        slidesToShow={{
          md: 1,
          sm: 1,
        }}
        data={bike?.images!}
        renderItem={(img, index) => (
          <AppImage
            carousel={!breakpointWidth}
            key={index}
            src={img.url}
            alt={bike?.title}
            className={classes.mainImage}
          />
        )}
      />
    );
  };

  const handleDeactivateBike = async () => {
    try {
      const res = await BikeService.deactivateBike(externalId!);
      AlertService.showSuccessMessage('Bike disabled successfully');
      let bikeUpdated: any = { ...bike, status: BikeStatus.DISABLED };
      setBike(bikeUpdated);
    } catch (error: any) {
      logger.error('Error deactivating bike: ', error);
      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }
    }
  };

  const menus: Menu[] = [
    {
      text: 'Images',
      action: () =>
        appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT_IMAGES, { externalId: externalId! }),
      extraElement: renderImageCarousel(),
    },
    {
      text: 'Bike details',
      action: () =>
        appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT_DETAILS, { externalId: externalId! }),
    },
    {
      text: 'Bike parts',
      action: () =>
        appNavigate(
          navigate,
          AppRoutes.HOST_BIKE_EDIT_PARTS,
          { externalId: externalId! },
          { state: { bikeStatus: bike?.status } }
        ),
    },
    {
      text: 'Blockout dates',
      action: () =>
        appNavigate(
          navigate,
          AppRoutes.HOST_BIKE_EDIT_BLOCKOUT_DATES,
          { externalId: externalId! },
          { state: bike?.blockoutDates }
        ),
    },
    {
      text: 'Location',
      action: () =>
        appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT_LOCATION, { externalId: externalId! }),
    },
    {
      text: 'Tiered price',
      action: () =>
        appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT_TIERED_PRICE, { externalId: externalId! }),
    },
  ];

  const renderHeader = () => {
    return <AppHeader content={bike?.title!} onBack={onBack} />;
  };

  const renderContent = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={getBikeDetails} />
        </div>
      );
    }

    return (
      <Container maxWidth={'md'} sx={{ my: 5 }}>
        <Box sx={{ mb: 2.5 }}>
          <AppTypography type={PDLTypography.largeHeading}>Edit listing</AppTypography>
        </Box>

        <Divider sx={{ mb: 1.6 }} />
        <AppMenuList menus={menus} />

        {(bike?.status == BikeStatus.ACTIVE ||
          bike?.status == BikeStatus.PENDING ||
          bike?.status == BikeStatus.APPROVED) && (
            <SimpleButton children="Deactivate Bike" onClick={() => setOpenAlertDialog(true)} />
          )}
        <AlertDialog
          title=""
          content={`Are you sure you want to deactivate bike: ${bike?.title}?`}
          open={openAlertDialog}
          onConfirm={() => {
            setOpenAlertDialog(false);
            handleDeactivateBike();
          }}
          onCancel={() => {
            setOpenAlertDialog(false);
          }}
        />
      </Container>
    );
  };


  return <AppLayout header={renderHeader} content={renderContent} />;

};

  const useStyles = makeStyles({
    mainImage: {
      width: '100%',
      maxHeight: '40em',
    },
  })