import { BikeCard } from '../Bikes/BikeCard';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import StringUtils from '../../utils/StringUtils';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTypography } from '../Typography/AppTypography';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { Bike } from '../../external/pdl-common/model/Bike';
import DateUtils from '../../external/pdl-common/utils/DateUtils';

interface Props {
  title: string;
  bikeList: Bike[];
}

export const TripWishlist = (props: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div>
      <Container maxWidth="lg">
        <Box sx={{ m: 3 }}>
          <AppTypography type={PDLTypography.largeHeading} children={props.title} />
        </Box>

        <Grid
          container
          display={'flex'}
          direction={breakpoint ? 'row' : 'column'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={4}
          mb={5}
        >
          {props.bikeList.map((bike, index) => (
            <BikeCard
              key={`${bike.externalId}-${index}`}
              title={bike.title}
              imageUrl={bike.images && bike.images.length > 0 ? bike.images[0].url : ''}
              hideDivider={true}
              hostedBy={StringUtils.formatShortUserNameAndLocation(bike.owner, bike)}
              hostedDates={DateUtils.formattedRangeDates(new Date(), new Date())}
              onClick={() =>
                appNavigate(navigate, AppRoutes.BIKE_DETAIL, {
                  externalId: bike.externalId!,
                })
              }
              style={{ cursor: 'pointer' }}
              favourite={bike.favourite}
              externalId={bike.externalId}
              notDates
            />
          ))}
        </Grid>
      </Container>
    </div>
  );
};
