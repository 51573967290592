import { Navigate, Route, Routes } from 'react-router-dom';
import { AppRoutes } from '../../utils/AppNavigation';
import Navbar from '../core/Navbar';
import Account from './Account';
import Explore from './Explore';
import Host from './Host';
import { HostBikeListings } from './Host/HostBikeListings';
import { HostBikeEdit } from './Host/Edit/HostBikeEdit';
import Inbox from './Inbox';
import Trips from './Trips';
import { ContentLibrary } from './ContentLibrary';
import { ContentLibraryDetail } from './ContentLibraryDetail';
import BikeReservationDetailsRenter from '../Reservation/Details/Renter/BikeReservationDetailsRenter';
import { useState } from 'react';
import { BikeReservationDetailsHost } from '../Reservation/Details/Host/BikeReservationDetailsHost';
import { Box } from '@mui/material';

interface Props {
  setIsLogged: (value: boolean) => void;
}

export default function Home({ setIsLogged }: Props) {
  const [customNavBar, setCustomNavBar] = useState<() => JSX.Element | undefined>();
  return (
    <div >
      <Box pb={7}>
        <Routes>
          <Route path={AppRoutes.EXPLORE} element={<Explore />} />
          <Route path={AppRoutes.HOST} element={<Host />} />
          <Route path={AppRoutes.HOST_BIKE_LISTINGS} element={<HostBikeListings />} />
          <Route path={AppRoutes.HOST_BIKE_EDIT} element={<HostBikeEdit />} />
          <Route path={AppRoutes.INBOX} element={<Inbox />} />
          <Route path={AppRoutes.ACCOUNT} element={<Account setIsLogged={setIsLogged} />} />
          <Route path={AppRoutes.TRIPS} element={<Trips />} />
          <Route path={AppRoutes.CONTENT_LIBRARY} element={<ContentLibrary />} />
          <Route path={AppRoutes.CONTENT_LIBRARY_DETAILS} element={<ContentLibraryDetail />} />
          <Route
            path={AppRoutes.TRIPS_RESERVATION_DETAIL}
            element={<BikeReservationDetailsRenter setCustomNavBar={setCustomNavBar} />}
          />
          <Route
            path={AppRoutes.HOST_RESERVATION_DETAIL}
            element={<BikeReservationDetailsHost setCustomNavBar={setCustomNavBar} />}
          />

          <Route path="*" element={<Navigate to={AppRoutes.EXPLORE} />} />
        </Routes>
      </Box>
      {customNavBar ? 
      <>
       {customNavBar}
       <Navbar/>
      </>
      :
       <Navbar/>
      }
    </div>
  );
}
