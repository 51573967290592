import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RoundedImageWithText from '../../../../external/pdl-common/components/RoundedImageWithText';
import { AppTypography } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';
import { CallToActionWithIcon } from './CallToActionWithIcon';

interface Props {
  title: string;
  imageSrc: string;
  imageSmallTitle: string;
  name: string;
  callToActionText?: string;
  callToActionIcon?: JSX.Element;
  onClick?: () => void;
}

const useStyles = makeStyles({
  title: {
    marginBottom: '1em',
  },
  spacing: {
    marginBottom: '0.3em',
  },
});

export const ReservationDetailsPerson: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const handleClick = () => {
    if (onClick) onClick();
  };

  const { title, imageSrc, name, imageSmallTitle, callToActionText, callToActionIcon, onClick } =
    props;
  return (
    <>
      <Grid container direction="column">
        <div className={classes.title}>
          <AppTypography type={PDLTypography.mediumHeading}>{title}</AppTypography>
        </div>
        <Grid item>
          <RoundedImageWithText
            src={imageSrc}
            title={imageSmallTitle}
            subtitle={name}
            titleTypography={{
              type: PDLTypography.labelsSmallSerif,
              customClasses: classes.spacing,
            }}
            subtitleTypography={{
              type: PDLTypography.smallHeading,
            }}
          />
        </Grid>

        {callToActionText && callToActionIcon && (
          <CallToActionWithIcon
            text={callToActionText}
            asset={callToActionIcon}
            style={{ marginTop: '1em' }}
            onClick={() => handleClick()}
          />
        )}
      </Grid>
    </>
  );
};
