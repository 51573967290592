import { Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { COLORS } from '../../utils/AppTheme';

export const WelcomeToPdlClub = () => {
  const theme = useTheme();
  const classes = useStyles();

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      className={classes.root}
      textAlign={md ? 'left' : 'center'}
      padding={md ? '5em 0 3em 0' : sm ? '3em 0 1.5em 0' : '1.5em 1.7em 0em 1.7em'}
    >
      <AppTypography
        type={PDLTypography.largeHeading}
        customStyles={{
          color: COLORS.white_FFFFFF,
          fontSize: md ? '5em' : sm ? '3.3em' : '1.6em',
        }}
      >
        Welcome to PDL Club
      </AppTypography>
      <AppTypography
        type={PDLTypography.paragraph}
        customStyles={{
          color: COLORS.white_FFFFFF,
          marginTop: '.5em',
          fontSize: md ? '1em' : '.9em',
        }}
      >
        Browse hundreds of performance bikes owned by cycling enthusiasts and be part {sm && <br />}
        of a community that cares about the sport as much as you.
      </AppTypography>
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.black_000000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    heigth: '10em',
  },
});
