import { Box } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { SxStyles } from '../../model/utils/SxStyles';

interface Props {
  styles?: CSSProperties;
}

export const AppSticky: React.FunctionComponent<Props> = (props) => {
  return (
    <Box sx={styles.sticky}>
      <Box sx={styles.container} style={props.styles}>
        {props.children}
      </Box>
    </Box>
  );
};

const styles: SxStyles = {
  sticky: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    mt: 2,
    position: 'sticky',
    top: 10,
    zIndex: 999,
  },
  container: {
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
    width: 200,
    height: 40,
    borderRadius: 200,
  },
};
