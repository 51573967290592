import { useState, useEffect } from 'react';
import { AppLayout } from '../../core/AppLayout';
import AppHeader from '../../commons/AppHeader';
import { Box, Button, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { AppAssets } from '../../../assets/index';
import { SearchBar } from '../../commons/SearchBar';
import { BikeCard } from '../../Bikes/BikeCard';
import { Bike } from '../../../external/pdl-common/model/Bike';
import BikeService from '../../../services/BikeService';
import SimpleButton from '../../commons/SimpleButton';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import Logger from '../../../external/pdl-common/utils/Logger';
import { useNavigate } from 'react-router-dom';
import { SxStyles } from '../../../model/utils/SxStyles';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { RequestStatus } from '../../../utils/RequestStatus';
import RequestStatusFeedBack from '../../commons/RequestStatusFeedback';
import { Debouncer } from '../../../utils/Debouncer';
import { AppSimpleList } from '../../commons/AppSimpleList';
import StringUtils from '../../../utils/StringUtils';
import { BikeStatus } from '../../../model/BikeStatus';

const logger = new Logger('HostBikeListings');
const PAGE_SIZE = 10;

export const HostBikeListings = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up('sm'));

  const [bikeListing, setBikeListing] = useState<Bike[]>([]);
  const [form, setForm] = useState({ inputListings: '' });
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [pageData, setPageData] = useState<{ number: number; totalPages: number } | undefined>();

  useEffect(() => {
    if (form.inputListings !== '') {
      setRequestStatus(RequestStatus.LOADING);
      Debouncer.debounce('searchBikes', getBikes, 700);
    } else {
      getBikes();
    }
  }, [form]);

  const getBikes = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeService.getBikeListing(0, PAGE_SIZE, form.inputListings))
      .onSuccess((response) => {
        setBikeListing(response.getContent().content);
        setPageData({
          number: response.getContent().number,
          totalPages: response.getContent().totalPages,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error getting bike list', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const loadMoreBikes = async () => {
    Debouncer.debounce(
      'loadMoreBikes',
      async () => {
        if (
          pageData?.number === undefined ||
          pageData?.totalPages === undefined ||
          pageData.number + 1 >= pageData.totalPages
        ) {
          return;
        }

        try {
          const response = (
            await BikeService.getBikeListing(pageData.number + 1, PAGE_SIZE, form.inputListings)
          ).getContent();

          setBikeListing([...bikeListing, ...response.content]);
          setPageData({ ...pageData, number: pageData.number + 1 });
        } catch (e) {
          logger.error('Error loading more bikes', e);
          setRequestStatus(RequestStatus.ERROR);
        }
      },
      150
    );
  };

  const handleCustomChange = (inputName: string, inputValue: string) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);
  };

  const handleOnClickListing = (bike: Bike) => {
    if (bike.status == BikeStatus.APPROVED) {
      appNavigate(
        navigate,
        AppRoutes.HOST_ACTIVATE_BIKE,
        { externalId: bike.externalId! },
        { state: { bike } }
      );
      return;
    }

    //TODO: SHOULD A HOST BE ABLE TO EDIT PENDING BIKE?
    appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT, {
      externalId: bike.externalId!,
    });
  };

  const renderHeader = () => {
    return (
      <AppHeader
        content={'My listings'}
        rightButton={
          <Button onClick={() => appNavigate(navigate, AppRoutes.HOST_BIKE_FIRST_STEP, null)}>
            <AppAssets.PlusCircleIcon />
          </Button>
        }
        bottomElement={
          <Box sx={{ width: '90%', m: 'auto', my: 2, p: 1 }}>
            <SearchBar
              placeholder={'Search listings'}
              onChange={(newValue) => handleCustomChange('inputListings', newValue)}
              buttonDisabled
            />
          </Box>
        }
        onBack={() => appNavigate(navigate, AppRoutes.HOST, null)}
      />
    );
  };

  const renderContent = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ height: '70vh' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={getBikes} />
        </div>
      );
    }

    return (
      <Box sx={styles.container}>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={4}
            mb={5}
          >
            {/* Feedback is the list is empty */}
            {bikeListing.length === 0 && form.inputListings !== '' ? (
              <AppTypography
                type={PDLTypography.smallHeading}
                children={`No bikes found with title: ${form.inputListings}`}
              />
            ) : (
              bikeListing.length === 0 && (
                <AppTypography
                  type={PDLTypography.smallHeading}
                  children={'At the moment you do not have any approved bike'}
                />
              )
            )}

            <div style={{ flex: 1, display: 'flex' }}>
              <div style={{ flex: 1, position: 'relative' }}>
                <AppSimpleList
                  data={bikeListing}
                  loadMore={loadMoreBikes}
                  sx={{ ...styles.listContainer, maxHeight: breakpoint ? '55vh' : '50vh' }}
                  renderItem={(bike, index) => (
                    <BikeCard
                      key={`${bike.externalId}-${index}`}
                      title={bike.title}
                      size={bike.size}
                      imageUrl={bike.images && bike.images.length > 0 ? bike.images[0].url : ''}
                      hideDivider={true}
                      onClick={() => handleOnClickListing(bike)}
                      rightIcon={
                        <Box sx={styles.chip}>
                          <AppTypography type={PDLTypography.subHeading}>
                            {StringUtils.capitalizeText(bike.status)}
                          </AppTypography>
                        </Box>
                      }
                      rightIconsContainerStyles={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        height: '100%',
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                />
              </div>
            </div>
          </Grid>
        </Container>

        <Box sx={styles.floatBtn}>
          <Box sx={{ width: '80%', margin: 'auto' }}>
            <SimpleButton
              onClick={() => appNavigate(navigate, AppRoutes.HOST_BIKE_FIRST_STEP, null)}
            >
              Add a New Listing
            </SimpleButton>
          </Box>
        </Box>
      </Box>
    );
  };

  return <AppLayout header={renderHeader} content={renderContent} />;
};

const styles: SxStyles = {
  container: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  chip: {
    backgroundColor: '#FFF',
    mt: 1,
    mr: 2,
    px: 1.2,
    py: 0.4,
    borderRadius: 4,
  },
  floatBtn: {
    width: '100%',
    position: 'fixed',
    bottom: '6em',
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '2em',
    overflowY: 'scroll',
    paddingBottom: '4em',
  },
};
