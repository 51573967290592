import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppAssets } from '../../../assets';
import { BikePartType } from '../../../model/BikePartType';
import { PartDetail } from '../../../external/pdl-common/model/PartDetail';
import BikeService from '../../../services/BikeService';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { COLORS, IAppTheme } from '../../../utils/AppTheme';
import BikePartDetailForm from '../../Bikes/BikePartDetailForm';
import AppHeader from '../../commons/AppHeader';
import SimpleButton from '../../commons/SimpleButton';
import { AppLayout } from '../../core/AppLayout';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import Logger from '../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../utils/RequestStatus';
import { Bike } from '../../../external/pdl-common/model/Bike';
import BikeSvgComponent from '../../../assets/img/bike_frame_component';
import AlertService from '../../../services/AlertService';
import ConfirmBikeEditionBottomSheet from './commons/ConfirmBikeEditionBottomSheet';
import BikePartService from '../../../services/BikePartService';
import BikePartCreation from '../../../model/creation/BikePartCreation';
import { PartsDetailForm } from '../../../model/parts/PartsDetailForm';
import { PartDetailForm } from '../../../model/parts/PartDetailForm';
import { PartToEditInfo } from '../../../model/parts/PartToEditInfo';
import { AlertDialog } from '../../commons/AlertDialog';
import { BikeStatus } from '../../../external/pdl-common/utils/enums/BikeStatus';
import { EditionType } from '../../../model/enums/EditionType';
import Navbar from '../../core/Navbar';

const logger = new Logger('HostBikeThirdStep');

interface Props {
  editMode?: boolean;
  editingBike?: Bike;
}

function initBikePartList(form: PartsDetailForm) {
  if (form) {
    let bikePartList: BikePartCreation[] = [];

    Object.entries(form).forEach((p) => {
      if (p[1] !== undefined) {
        bikePartList.push(
          new BikePartCreation(
            p[1].type,
            p[1].brand,
            p[1].component || '',
            p[1].model || '',
            p[1].size,
            p[1].externalId,
            p[1].material,
            p[1].category,
            p[1].additionalCategory,
            p[1].measurement,
            p[1].additionalInfo
          )
        );
      }
    });
    return bikePartList;
  }
}

export default function HostBikeThirdStep(props: Props) {
  // States we pass between views
  const location = useLocation().state as any;
  const bike: Bike | undefined = location?.bike;
  const partDetails: PartsDetailForm | undefined = location?.partDetails;
  const payoutMethod = location?.payoutMethod;
  const draftMode: boolean | undefined = location?.draftMode;
  const partsToCreate: BikePartCreation[] | undefined =
    location?.partsToCreate || initBikePartList(location?.partDetails);

  const classes = useStyles();
  const navigate = useNavigate();

  const [partDetailFormOpen, setPartDetailFormOpen] = useState(false);
  const [form, setForm] = useState<Partial<PartsDetailForm>>(
    partDetails
      ? partDetails
      : {
        frameMaterial: undefined,
        bottomBracket: undefined,
        brake: undefined,
        cassette: undefined,
        crankset: undefined,
        frontDerailleur: undefined,
        rearDerailleur: undefined,
        handlebar: undefined,
        seatpost: undefined,
        stem: undefined,
        wheelset: undefined,
        tires: undefined,
        saddle: undefined,
        powerMeter: undefined,
        pedals: undefined,
      }
  );
  const [partToEditInfo, setPartToEditInfo] = useState<PartToEditInfo | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);
  const [bikePartsToUpdate, setBikePartsToUpdate] = useState<PartDetail[]>(
    props.editingBike?.parts || []
  );
  const [bikePartsToCreate, setBikePartsToCreate] = useState<BikePartCreation[]>(
    partsToCreate || []
  );
  const [showConfirmEdition, setShowConfirmEdition] = useState<boolean>(false);
  const [havePowerMeter, setHavePowerMeter] = useState<boolean | undefined>(
    !props.editMode ? location?.havePowerMeter : false
  );
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  // In this way we intercept the default behavior when the user goes backwards
  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onBack);
    if (!props.editMode && !bike) {
      appNavigate(navigate, AppRoutes.HOST_BIKE_FIRST_STEP, null);
    }

    return () => {
      window.removeEventListener('popstate', onBack);
    };
  }, []);

  useEffect(() => {
    if (partToEditInfo) {
      setPartDetailFormOpen(true);
    } else {
      setPartDetailFormOpen(false);
    }
  }, [partToEditInfo]);

  useEffect(() => {
    if (!props.editMode && !partDetailFormOpen && bikePartsToCreate.length != 0) {
      BikePartService.createBikeParts(bikePartsToCreate, bike?.externalId!);
    }
  }, [partDetailFormOpen]);

  // If we are updating, we transform the bike parts array to an object of type PartsDetailForm
  useEffect(() => {
    if (props.editMode || draftMode) {
      let bikeParts;

      if (props.editingBike) {
        bikeParts = props.editingBike?.parts;
      } else {
        bikeParts = bike?.parts;
      }

      if (bikeParts) {
        const bikePartsParsed = bikeParts.map((part) => {
          const { type } = part;
          return { [type]: part as PartDetailForm };
        });

        const partDetails = Object.assign({}, ...bikePartsParsed) as PartsDetailForm;
        setForm(partDetails);
      }
    }
  }, [props.editMode, props.editingBike, draftMode, bike]);

  const onBack = async () => {
    if (props.editMode) {
      if (isChanged) {
        setOpenAlertDialog(true);
      } else {
        appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT, {
          externalId: props.editingBike?.externalId!,
        });
      }
    } else {
      try {
        if (bikePartsToCreate.length != 0) {
          setRequestStatus(RequestStatus.LOADING);
          await BikePartService.createBikeParts(bikePartsToCreate, bike?.externalId!);
          setRequestStatus(RequestStatus.SUCCESS);
        }
      } catch (error: any) {
        setRequestStatus(RequestStatus.ERROR);
        AlertService.showSnackCustomError(
          error?.response?.data.message || 'Failed to create bike parts'
        );
        logger.error('Error when listing the bike', error);
      }
      appNavigate(navigate, AppRoutes.HOST_BIKE_SECOND_STEP, null, {
        state: {
          bike,
          partDetails: form,
          payoutMethod,
          draftMode,
          partsToCreate: bikePartsToCreate,
        },
      });
    }
  };

  const handleOnFormClose = (name: string, partDetail?: PartDetailForm) => {
    if (partDetail) {
      let updatedForm = { ...form };
      (updatedForm as any)[name] = partDetail;
      setForm(updatedForm);
    }
    setPartToEditInfo(undefined);
  };

  const onBeforeUpdateActiveOrPendingBike = () => {
    if (location.bikeStatus === BikeStatus.PENDING) {
      updateActiveBike();
      return;
    }

    setShowConfirmEdition(true);
  };

  const updateActiveBike = async () => {
    if (!props.editingBike) {
      return;
    }

    try {
      setRequestStatus(RequestStatus.LOADING);

      props.editingBike.parts = bikePartsToUpdate;

      await BikeService.updateActive(props.editingBike, EditionType.BIKE_PARTS);

      AlertService.showSuccessMessage('Bike parts edited successfully');

      setRequestStatus(RequestStatus.SUCCESS);

      setTimeout(() => {
        // Return to previous screen automatically after 3 seconds
        navigate(-1);
      }, 2000);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'Failed to update bike parts';

      logger.error(errorMessage, error);
      AlertService.showSnackCustomError(errorMessage);

      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const deletePart = async (part: PartDetailForm) => {
    try {
      const formStatus = form;
      if (!props.editMode) {
        setRequestStatus(RequestStatus.LOADING);
        await BikePartService.deleteBikePart(bike?.externalId!, part.type);
        formStatus[part.type] = undefined;
        setForm(formStatus);
        setBikePartsToCreate(bikePartsToCreate.filter((item) => item.type !== part.type));
        setRequestStatus(RequestStatus.SUCCESS);
      } else {
        formStatus[part.type] = undefined;
        setIsChanged(true);
        setForm(formStatus);
        setBikePartsToUpdate(bikePartsToUpdate.filter((item) => item.type !== part.type));
      }
    } catch (error: any) {
      setRequestStatus(RequestStatus.ERROR);
      AlertService.showSnackCustomError(
        error?.response?.data.message || 'Failed to delete bike part'
      );
      logger.error('Error when deleting the bike part', error);
    }
  };

  const PartComponent = (props: PartToEditInfo) => {
    return props.part ? (
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        <div className={classes.bikePartAdded} onClick={() => setPartToEditInfo(props)}>
          {!props.mandatory && (
            <div
              className={classes.bikePartDelete}
              onClick={(e) => {
                e.stopPropagation();
                deletePart(props.part!);
              }}
            >
              <AppAssets.Trash style={{ marginRight: '0.8em' }} />
            </div>
          )}
          <div className={classes.bikePartAddedLegend}>
            <AppAssets.CheckCircle style={{ marginBottom: '0.7em' }} />
            <AppTypography
              type={PDLTypography.subHeading}
              customStyles={{ color: COLORS.white_FFFFFF }}
            >
              {props.label}
            </AppTypography>
            <AppTypography
              type={PDLTypography.smallParagraph}
              customStyles={{ color: COLORS.white_FFFFFF }}
            >
              Details added
            </AppTypography>
          </div>
        </div>
      </Grid>
    ) : (
      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
        <div className={classes.bikePart} onClick={() => setPartToEditInfo(props)}>
          <AppAssets.PlusCircleIcon className={classes.plusCircleIcon} />
          <AppTypography type={PDLTypography.subHeading}>{props.label}</AppTypography>
          <AppTypography type={PDLTypography.smallParagraph}>Add details</AppTypography>
        </div>
      </Grid>
    );
  };

  const handleOnSubmit = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      await BikePartService.createBikeParts(bikePartsToCreate, bike?.externalId!);

      const bikeResponse = await BikeService.thirdStepCreationBike(bike?.externalId!);

      setRequestStatus(RequestStatus.SUCCESS);

      appNavigate(navigate, AppRoutes.PAYOUT_METHOD, null, {
        state: {
          partDetails: form,
          bike: bikeResponse.getContent(),
          payoutMethod,
          draftMode,
          havePowerMeter: havePowerMeter,
        },
      });
    } catch (error: any) {
      setRequestStatus(RequestStatus.ERROR);
      AlertService.showSnackCustomError(
        error?.response?.data.message || 'Failed to create bike parts'
      );
      logger.error('Error when listing the bike', error);
    }
  };

  const handleOnClickComponent = (value: PartToEditInfo) => {
    setPartToEditInfo(value);
  };

  const isSubmitButtonDisabled = (): boolean => {
    return !(
      form.brake &&
      form.cassette &&
      form.crankset &&
      form.handlebar &&
      form.stem &&
      form.wheelset &&
      (form.powerMeter || !havePowerMeter)
    );
  };

  return (
    <AppLayout
      fromHome
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
      content={() => {
        return (
          <>
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <AppHeader
                content={props.editMode ? 'Edit bike parts' : 'Add bike parts'}
                onBack={onBack}
              />
              <div className={classes.bikeMapContainer}>
                <BikeSvgComponent
                  onClick={handleOnClickComponent}
                  form={form}
                  havePowerMeter={havePowerMeter}
                />
              </div>
              <Container>
                <div className={classes.paddingVertical}>
                  <Grid container spacing={3} columns={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}>
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      part={form.bottomBracket}
                      label="Bottom bracket"
                      name={BikePartType.bottomBracket}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      dropdown={{ label: 'Type', name: 'brake', required: true }}
                      part={form.brake}
                      label="Brakes *"
                      mandatory
                      name={BikePartType.brake}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      dropdown={{ label: 'Size', name: 'cassette', required: true }}
                      part={form.cassette}
                      label="Cassette *"
                      mandatory
                      name={BikePartType.cassette}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      dropdown={{ label: 'Length (millimeters)', name: 'crankset', required: true }}
                      additionalDropdown={{
                        label: 'Chainring size',
                        name: 'chainring-size',
                        required: true,
                      }}
                      part={form.crankset}
                      label="Crankset *"
                      mandatory
                      name={BikePartType.crankset}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      part={form.frontDerailleur}
                      label="Front Derailleur"
                      name={BikePartType.frontDerailleur}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      part={form.rearDerailleur}
                      label="Rear Derailleur"
                      name={BikePartType.rearDerailleur}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        { name: 'material', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      dropdown={{ label: 'Width (centimeters)', name: 'handlebar', required: true }}
                      part={form.handlebar}
                      label="Handlebar *"
                      mandatory
                      name={BikePartType.handlebar}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        { name: 'material', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                        {
                          label: 'Diameter (millimeters)',
                          name: 'measurement',
                          inputType: 'number',
                        },
                      ]}
                      part={form.seatpost}
                      label="Seatpost"
                      name={BikePartType.seatpost}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        { name: 'material', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      dropdown={{ label: 'Length (millimeters)', name: 'stem', required: false }}
                      part={form.stem}
                      label="Stem *"
                      mandatory
                      name={BikePartType.stem}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: true },
                        { name: 'model', required: false },
                        { name: 'material', required: false },
                        {
                          label: 'Depth (millimeters)',
                          name: 'measurement',
                          required: false,
                          inputType: 'number',
                        },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      part={form.wheelset}
                      label="Wheelset *"
                      mandatory
                      name={BikePartType.wheelset}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        {
                          label: 'Width (millimeters)',
                          name: 'measurement',
                          required: false,
                          inputType: 'number',
                        },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      part={form.tires}
                      label="Tires"
                      name={BikePartType.tires}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        {
                          label: 'Width (millimeters)',
                          name: 'measurement',
                          required: false,
                          inputType: 'number',
                        },
                        { name: 'material', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      part={form.saddle}
                      label="Saddle"
                      name={BikePartType.saddle}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: true },
                        { name: 'model' },
                        { name: 'material', displayAfterDropDown: true },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      dropdown={{ label: 'Type', name: 'power-meter', required: havePowerMeter }}
                      part={form.powerMeter}
                      mandatory={havePowerMeter}
                      label={havePowerMeter ? 'Power Meter *' : 'Power Meter'}
                      name={BikePartType.powerMeter}
                    />
                    <PartComponent
                      fields={[
                        { name: 'brand', required: false },
                        { name: 'model', required: false },
                        { name: 'material', required: false },
                        {
                          label: 'Additional Information',
                          name: 'additionalInfo',
                          displayAfterDropDown: true,
                        },
                      ]}
                      part={form.pedals}
                      label="Pedals"
                      name={BikePartType.pedals}
                    />
                  </Grid>
                </div>
              </Container>
            </div>
            <div className={classes.bottomBar}>
              <Container sx={{ display: 'flex', flexDirection: 'row' }}>
                {props.editMode ? (
                  <>
                    <SimpleButton
                      isLoading={requestStatus === RequestStatus.LOADING}
                      onClick={onBeforeUpdateActiveOrPendingBike}
                    >
                      Save Changes
                    </SimpleButton>
                  </>
                ) : (
                  <>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                      <AppTypography type={PDLTypography.subHeadingSerif}>
                        Step 3 of 3
                      </AppTypography>
                    </div>
                    <div className={classes.bottomBarButtonContainer}>
                      <SimpleButton
                        isLoading={requestStatus === RequestStatus.LOADING}
                        onClick={handleOnSubmit}
                        disabled={isSubmitButtonDisabled()}
                      >
                        {Object.values(form).some((part) => part !== undefined)
                          ? 'Submit Listing'
                          : 'Next'}
                      </SimpleButton>
                    </div>
                  </>
                )}
              </Container>
            </div>

            {partToEditInfo && (
              <BikePartDetailForm
                open={partDetailFormOpen}
                onClose={handleOnFormClose}
                partToEditInfo={partToEditInfo}
                bike={props.editMode ? props.editingBike! : bike!}
                editMode={props.editMode}
                bikePartsToUpdate={bikePartsToUpdate}
                setBikePartsToUpdate={setBikePartsToUpdate}
                bikePartsToCreate={bikePartsToCreate}
                setBikePartsToCreate={setBikePartsToCreate}
                setIsChanged={setIsChanged}
              />
            )}

            <ConfirmBikeEditionBottomSheet
              open={showConfirmEdition}
              onClose={() => setShowConfirmEdition(false)}
              onConfirm={updateActiveBike}
            />

            {props.editMode && (
              <AlertDialog
                title="Leave edit"
                content="Are you sure to leave edit? Your changes will be discarted"
                open={openAlertDialog}
                onConfirm={() => {
                  setOpenAlertDialog(false);
                  appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT, {
                    externalId: props.editingBike?.externalId!,
                  });
                }}
                onCancel={() => {
                  setOpenAlertDialog(false);
                }}
              />
            )}

            <AlertDialog
              title="Power meter"
              content="Does your bike have a power meter?"
              cancelLabel="NO"
              confirmLabel="YES"
              open={havePowerMeter === undefined}
              onConfirm={() => {
                setHavePowerMeter(true);
              }}
              onCancel={() => {
                setHavePowerMeter(false);
              }}
            />
            <Navbar/>
          </>
        );
      }}
    />
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  paddingVertical: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  bikeMapContainer: {
    backgroundColor: COLORS.black_000000,
    color: COLORS.white_FFFFFF,
    display: 'flex',
    justifyContent: 'center',
    height: '300px',
    paddingBottom: '1em',
    paddingLeft: '0.8em',
  },
  bikePart: {
    backgroundColor: theme.colors.white_FFFFFF,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.gray_C9CBCA,
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    height: '13em',
    position: 'relative',
    userSelect: 'none',
    '&:active': {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
  },
  bikePartDelete: {
    width: '5%',
    marginTop: '0.2em',
    marginRight: '0.7em',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderWidth: 1,
    borderColor: theme.colors.black_01000E,
    cursor: 'pointer',
    alignSelf: 'end',
    textAlign: 'right',
    height: '1.5em',
    position: 'absolute',
    userSelect: 'none',
    '&:active': {
      backgroundColor: 'rgba(0,0,0,0.85)',
    },
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
  },
  bikePartAdded: {
    backgroundColor: theme.colors.black_01000E,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.black_01000E,
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '13em',
    position: 'relative',
    userSelect: 'none',
    '&:active': {
      backgroundColor: 'rgba(0,0,0,0.85)',
    },
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
  },
  bikePartAddedLegend: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plusCircleIcon: {
    marginBottom: '0.7em',
    '& path': {
      stroke: theme.colors.persianBlue_2238CB,
    },
  },
  bottomBar: {
    padding: '1em 0em',
    marginBottom: 59,
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.colors.gray_E3E5E4,
  },
  bottomBarButtonContainer: {
    flex: 2,
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
  },
}));
