import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Bike } from '../../../../external/pdl-common/model/Bike';
import Logger from '../../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../../utils/RequestStatus';
import { useNavigate, useParams } from 'react-router-dom';
import BikeService from '../../../../services/BikeService';
import { PDLTypography } from '../../../Typography/PDLTypography';
import { AppTypography } from '../../../Typography/AppTypography';
import AppHeader from '../../../commons/AppHeader';
import SimpleButton from '../../../commons/SimpleButton';
import { MapWrapper } from '../../../Map/MapWrapper';
import { MapMarker } from '../../../Map/Markers/MapMarker';
import { AppLayout } from '../../../core/AppLayout';
import FormContainer from '../../../commons/FormContainer';
import RequestStatusFeedBack from '../../../commons/RequestStatusFeedback';
import AlertService from '../../../../services/AlertService';
import { LocationForm } from '../../../commons/LocationForm';
import { GeoSearch } from '../../../Map/GeoSearch';
import GeolocationService from '../../../../services/GeolocationService';
import { PdlLocation } from '../../../../model/PdlLocation';
import ConfirmBikeEditionBottomSheet from '../commons/ConfirmBikeEditionBottomSheet';
import { AlertDialog } from '../../../commons/AlertDialog';
import { BikeStatus } from '../../../../external/pdl-common/utils/enums/BikeStatus';
import { EditionType } from '../../../../model/enums/EditionType';
import Navbar from '../../../core/Navbar';

const logger = new Logger('HostBikeEditLocation');

export default function HostBikeEditLocation() {
  const { externalId } = useParams();

  const [currentBikeLocation, setCurrentBikeLocation] = useState<PdlLocation | undefined>(
    undefined
  );

  const [bike, setBike] = useState<Bike | undefined>(undefined);
  const [form, setForm] = useState<LocationForm>({
    streetAddress: '',
    suite: '',
    city: '',
    state: '',
    countryCode: '',
    zipCode: '',
  });
  const [validations, setValidations] = useState<{ [P in keyof LocationForm]: boolean }>({
    streetAddress: false,
    suite: false,
    city: false,
    state: false,
    countryCode: false,
    zipCode: false,
  });

  const navigate = useNavigate();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [mapSearchStatus, setMapSearchStatus] = useState(RequestStatus.SUCCESS);
  const [saveChangesStatus, setSaveChangesStatus] = useState(RequestStatus.SUCCESS);

  const [isEditing, setIsEditing] = useState(false);
  const [confirmLocation, setConfirmLocation] = useState(false);
  const [showMarker, setShowMarker] = useState(true);
  const [showConfirmEdition, setShowConfirmEdition] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  useEffect(() => {
    getBike();
  }, []);

  useEffect(() => {
    if (isEditing && currentBikeLocation) {
      setForm({
        streetAddress: currentBikeLocation.streetAddress || '',
        suite: currentBikeLocation.streetAddressDetails || '',
        city: currentBikeLocation.city || '',
        state: currentBikeLocation.state || '',
        countryCode: currentBikeLocation.countryCode || '',
        zipCode: currentBikeLocation.zipCode || '',
      });

      setValidations({
        streetAddress: !!currentBikeLocation.streetAddress,
        suite: !!currentBikeLocation.streetAddressDetails,
        city: !!currentBikeLocation.city,
        state: !!currentBikeLocation.state,
        countryCode: !!currentBikeLocation.countryCode,
        zipCode: !!currentBikeLocation.zipCode,
      });
    }
  }, [currentBikeLocation]);

  const getBike = async () => {
    if (externalId) {
      setRequestStatus(RequestStatus.LOADING);
      (await BikeService.getBike(externalId))
        .onSuccess((response) => {
          setBike(response.getContent());
          setCurrentBikeLocation(response.getContent().location);

          setRequestStatus(RequestStatus.SUCCESS);
        })
        .onError((response) => {
          logger.error('Error fetching bike detail', response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    }
  };

  const handleCustomChange = (inputName: string, inputValue: string, isValid: boolean) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);

    let updatedValidations = { ...validations };
    (updatedValidations as any)[inputName] = isValid;
    setValidations(updatedValidations);
  };

  const handleOnBeforeUpdate = () => {
    if (bike?.status === BikeStatus.PENDING) {
      handleUpdate();
      return;
    }

    setShowConfirmEdition(true);
  };

  const handleUpdate = async () => {
    try {
      if (!bike) return;

      setSaveChangesStatus(RequestStatus.LOADING);

      const location: PdlLocation = {
        latitude: currentBikeLocation!.latitude,
        longitude: currentBikeLocation!.longitude,
        geocode: currentBikeLocation!.geocode,
        streetAddress: form.streetAddress,
        streetAddressDetails: form.suite,
        city: form.city,
        state: form.state,
        countryCode: form.countryCode,
        zipCode: form.zipCode,
      };

      bike.location = location;

      const response = await BikeService.updateActive(bike, EditionType.BIKE_LOCATION);
      setBike(response.getContent());
      setCurrentBikeLocation(bike.location);
      setIsEditing(false);
      setConfirmLocation(false);

      AlertService.showSuccessMessage('Location edited successfully');
      setTimeout(() => {
        // Return to previous screen automatically after 3 seconds
        navigate(-1);
      }, 2000);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        logger.error(error?.response?.data?.message, error);
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        const err = 'Failed to update location';
        logger.error(err, error);
        AlertService.showSnackCustomError(err);
      }
    } finally {
      setSaveChangesStatus(RequestStatus.SUCCESS);
    }
  };

  const getAddressFromCoordinates = async (lat: number, lon: number) => {
    setMapSearchStatus(RequestStatus.LOADING);
    const response = await GeolocationService.getAddressFromCoordinates({ lat: lat, lng: lon });
    response
      .onSuccess((response) => {
        const newLocation = response.getContent();
        setCurrentBikeLocation({
          geocode: newLocation.display_name,
          latitude: newLocation.lat,
          longitude: newLocation.lon,
          streetAddress: newLocation.address.road,
          streetAddressDetails: '',
          city:
            newLocation.address.city ||
            newLocation.address.town ||
            newLocation.address.suburb ||
            newLocation.address.village ||
            newLocation.address.county ||
            newLocation.address.state_district,
          state: newLocation.address.state,
          countryCode: newLocation.address.country_code,
          zipCode: newLocation.address.postcode,
        });
        setMapSearchStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        setMapSearchStatus(RequestStatus.ERROR);
        AlertService.showSnackCustomError(response.getContent());
      });
  };

  const handleOnClickMap = async (e: { latlng: { lat: number; lng: number } }) => {
    setShowMarker(false);

    await getAddressFromCoordinates(e.latlng.lat, e.latlng.lng);

    setShowMarker(true);
  };

  const onBack = () => {
    if (isChanged) {
      setOpenAlertDialog(true);
    } else {
      navigate(-1);
    }
  };

  const getContent = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={getBike} />
        </Box>
      );
    }

    return (
      <>
        <FormContainer
          bottomElementStyle={{ marginBottom: '59px' }}
          BottomElement={
            isEditing ? (
              <SimpleButton
                children={confirmLocation ? 'Save changes' : 'Continue'}
                onClick={confirmLocation ? handleOnBeforeUpdate : () => setConfirmLocation(true)}
                isLoading={saveChangesStatus == RequestStatus.LOADING}
                disabled={
                  confirmLocation
                    ? saveChangesStatus == RequestStatus.LOADING ||
                    !(
                      validations.city &&
                      validations.state &&
                      validations.streetAddress &&
                      validations.zipCode
                    )
                    : !!!currentBikeLocation ||
                    mapSearchStatus == RequestStatus.LOADING ||
                    mapSearchStatus == RequestStatus.ERROR
                }
              />
            ) : undefined
          }
          divider={!isEditing}
          childrenContainerStyle={{ height: '100%', display: 'flex', flexDirection: 'column'  }}
          innerContainerStyle={{position: 'absolute' }}
        >
          {confirmLocation ? (
            <>
              <Box mb={2.4} mt={3}>
                <AppTypography type={PDLTypography.largeHeading} children={'Confirm location'} />
              </Box>

              <Box>
                <LocationForm onInputChange={handleCustomChange} form={form} />
              </Box>
            </>
          ) : (
            <>
              <Box mb={1.4} mt={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <AppTypography
                  type={PDLTypography.largeHeading}
                  children={`${isEditing ? 'Edit' : 'Current'} location`}
                />
                {!isEditing && (
                  <AppTypography
                    type={PDLTypography.callToAction}
                    customStyles={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsChanged(true);
                      setIsEditing(true);
                      setCurrentBikeLocation(undefined);
                    }}
                    children={'Edit'}
                  />
                )}
              </Box>

              <Box mb={3.2}>
                {isEditing && !currentBikeLocation ? (
                  <GeoSearch
                    placeholder={'Where is your bike located?'}
                    onClickLocation={(res) => getAddressFromCoordinates(+res.lat, +res.lon)}
                    showBorder
                  />
                ) : (
                  <AppTypography
                    type={PDLTypography.paragraph}
                    children={`${currentBikeLocation!.streetAddress} ${currentBikeLocation!.streetAddressDetails
                      ? currentBikeLocation!.streetAddressDetails
                      : ' '
                      } , ${currentBikeLocation!.city}, ${currentBikeLocation!.state}, ${currentBikeLocation!.zipCode
                      }`}
                  />
                )}
              </Box>

              <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <MapWrapper
                  style={{ borderRadius: '.5em', zIndex: 10, marginBottom: '20px' }}
                  coordinates={
                    currentBikeLocation
                      ? { lat: currentBikeLocation.latitude, lng: currentBikeLocation.longitude }
                      : undefined
                  }
                  onClickMap={isEditing ? handleOnClickMap : undefined}
                  isLoading={!showMarker || mapSearchStatus == RequestStatus.LOADING}
                >
                  {currentBikeLocation && showMarker && (
                    <MapMarker
                      lat={currentBikeLocation.latitude}
                      lng={currentBikeLocation.longitude}
                      draggable={isEditing}
                      type={'OnlyIcon'}
                      onDrag={(lat, lng) => {
                        getAddressFromCoordinates(lat, lng);
                      }}
                    />
                  )}
                </MapWrapper>
              </div>
            </>
          )}
        </FormContainer>
        <Navbar/>

        <AlertDialog
          title="Leave edit"
          content="Are you sure to leave edit? Your changes will be discarted"
          open={openAlertDialog}
          onConfirm={() => {
            setOpenAlertDialog(false);
            navigate(-1);
          }}
          onCancel={() => {
            setOpenAlertDialog(false);
          }}
        />

        <ConfirmBikeEditionBottomSheet
          open={showConfirmEdition}
          onClose={() => setShowConfirmEdition(false)}
          onConfirm={handleUpdate}
        />
      </>
    );
  };

  return (
    <>
      <AppLayout
        noNavBar
        header={() => <AppHeader content={'Bike location'} onBack={onBack} />}
        content={() => getContent()}
      />
    </>
  );
}
