import { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, SxProps } from '@mui/material';
import { AppAssets } from '../../assets';
import { IAppTheme } from '../../utils/AppTheme';
import { makeStyles } from '@mui/styles';
import { AppTypography, AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { Close } from '@mui/icons-material';
import { AppTextField } from './AppTextField';
import Image from '../../external/fox-typescript/core/Image';
import { v4 as uuidv4 } from 'uuid';
import ImageService from '../../external/fox-typescript/services/ImageService';
import Logger from '../../external/pdl-common/utils/Logger';
import { AddPhotoFromCamera } from '../Camera/AddPhotoFromCamera';
import AlertService from '../../services/AlertService';
import { RequestStatus } from '../../utils/RequestStatus';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { ImageResizingProperties } from '../../external/fox-typescript/core/ImageResizingProperties';
import ApiResponse from '../../external/fox-typescript/core/ApiResponse';

interface Props {
  readOnly?: boolean;
  label?: string;
  labelProps?: Omit<AppTypographyProps, 'type'>;
  imageUrls?: string[];
  imageFiles?: File[];
  setImageFiles?: (file: File[] | undefined) => void;
  handleSetImage?: (image: Image) => void;
  multiple?: boolean;
  // If it is true it will show an input to add the caption
  caption?: boolean;
  // Function used to change the caption value
  onCaptionChange?: (inputName: string, inputValue: string) => void;
  // Object used to set the new url of the image, and determine its caption
  image?: Image;
  // Boolean that tells us if the component should render images, useful for editing cases
  defaultImages?: boolean;
  // Auxiliary function used when deleting an image
  onRemove?: (index: number) => void;
  onImageUploaded?: (image: Image) => void;
  imageIndex?: number;
  maxImage?: number;
  onImagLoading?: () => void;
  onImagLoadComplete?: () => void;
}

const logger = new Logger('UploadImage');

export function UploadImage(props: Props) {
  const MAX_SIZE_IMAGE = 1024;  // KBs

  const classes = useStyles();

  const inputFile = useRef<HTMLInputElement>(null);
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);

  const { customClasses: inputClasses, ...typographyProps } = props.labelProps || {};

  useEffect(() => {
    if (!props.imageFiles) {
      return;
    }

    const newImageUrls = props.imageFiles.map((imageFile) => URL.createObjectURL(imageFile));

    if (props.image) {
      props.image.url = newImageUrls[0];
    }
  }, [props.imageFiles]);

  const callToService = async (file: File) => {
    try {
      setRequestStatus(RequestStatus.LOADING);
      if (props.onImagLoading) {
        props.onImagLoading();
      }

      let response;

      if (file.size > MAX_SIZE_IMAGE * 1024) {

        console.log('uploadImage - compression');
        const imageResizingProperties: ImageResizingProperties = {
          maxWidth: 1920,
          maxHeight: 1920,
          compressFormat: 'JPEG',
          quality: 90, // vallue 0-100. 100 is not compression.
          rotation: 0
        }

        response = await ImageService.uploadCompressImage(file, uuidv4(), null, imageResizingProperties);

      } else {

        response = await ImageService.uploadImage(file, uuidv4(), null);

      }

      setRequestStatus(RequestStatus.SUCCESS);
      if (props.onImagLoadComplete) {
        props.onImagLoadComplete();
      }
      return response.getContent();
    } catch (error) {
      const errorMessage = 'Error uploading image.';

      logger.error(errorMessage, error);
      AlertService.showSnackCustomError(errorMessage);
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const handleOnChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    if (event.target.files?.length > 0) {
      // Upload Image Logic
      const image = await callToService(event.target.files[0]);

      if (image) {
        image.description = '';
        props.onImageUploaded && props.onImageUploaded(image);

        let uploadedImages = [] as any[];
        Array.from(event.target.files).forEach((file: File) => {
          /* // Send ImageCreationDTO to backend, get Image with url to display
          ImageService.upload(file).then((image: any) => {
            uploadedImages.push(image);
          });
          */
          // const mockImage = {
          //   externalId: uuidv4(),
          //   url: 'https://surlybikes.com/uploads/bikes/_large_image/Surly-Bridge-Club-BK1967-Gray-Background-2000x1333.jpg',
          //   description: '',
          // } as Image;
          uploadedImages.push(image);
        });
        //props.setImageFiles(Array.from(event.target.files));
        uploadedImages.forEach((image) => props.handleSetImage!(image));
      }
    }
  };

  const removeImage = () => {
    const index = props.imageIndex || 0;

    if (props.imageFiles) {
      const newImageFiles = [...props.imageFiles];
      newImageFiles.splice(index, 1);
      props.setImageFiles?.(newImageFiles.length === 0 ? undefined : newImageFiles);
    }

    props.onRemove && props.onRemove(index);
  };

  const CloseButton = (props: { sx: SxProps; onClick: () => void }) => (
    <Box className={classes.closeBtn} onClick={props.onClick} sx={props.sx}>
      <Close sx={{ color: 'white' }} fontSize="small" />
    </Box>
  );

  const FileButton = (props: { onClick: () => void; placeholder: string; icon: JSX.Element }) => {
    return (
      <Box onClick={props.onClick} className={classes.uploadImageContainer}>
        {props.icon}
        <AppTypography type={PDLTypography.smallParagraph} customClasses={classes.placeHolder}>
          {props.placeholder}
        </AppTypography>
        {requestStatus === RequestStatus.LOADING && (
          <CircularProgress color="inherit" size={30} sx={{ marginLeft: '1em' }} />
        )}
      </Box>
    );
  };

  return (
    <>
      {props.label && (
        <AppTypography
          type={PDLTypography.subHeading}
          customClasses={`${classes.inputLabel} ${inputClasses}`}
          {...typographyProps}
        >
          {props.label}
        </AppTypography>
      )}
      <Box>
        {props.imageUrls ? (
          props.imageUrls.map((imageURL, index) => (
            <>
              <div className={classes.imageContainer}>
                <AppImage
                  key={`${index}-${imageURL}`}
                  src={imageURL}
                  alt="Preview"
                  height={'100%'}
                  width={'100%'}
                  className={classes.image}
                />
                {
                  !props.readOnly &&
                  <CloseButton sx={{ top: '0.8em', right: '0.6em' }} onClick={() => removeImage()} />
                }
              </div>

              {props.caption && props.onCaptionChange && (
                <Box sx={{ mb: 5 }}>
                  <AppTextField
                    label={'Image caption'}
                    labelProps={labelProps}
                    name={'caption'}
                    value={props.image?.description || ''}
                    onInputChange={props.onCaptionChange}
                  />
                </Box>
              )}
            </>
          ))
        ) : (!props.maxImage || props.imageIndex! < props.maxImage) && (
          <>
            <FileButton
              onClick={() => setShowCamera(true)}
              placeholder="Take photo"
              icon={<AppAssets.Camera />}
            />

            <input
              type={'file'}
              accept={'image/*'}
              ref={inputFile}
              onChange={handleOnChangeImage}
              className={classes.hide}
              multiple={props.multiple}
            />

            <div style={{ height: '1rem' }} />

            <FileButton
              onClick={() => inputFile.current!.click()}
              placeholder="Select photo"
              icon={<AppAssets.Image />}
            />
          </>
        )}
      </Box>

      {/* Camera */}
      <AddPhotoFromCamera
        show={showCamera}
        collectionOfImages={[]}
        onClose={() => setShowCamera(false)}
        onImageUploaded={props.onImageUploaded}
        handleSetImage={props.handleSetImage}
        callToService={callToService}
      />
    </>
  );
}

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  closeBtn: {
    borderStyle: 'solid',
    borderColor: theme.colors.white_FFFFFF,
    borderWidth: 1,
    borderRadius: 100,
    backgroundColor: 'rgba(1, 0, 14, 0.45)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 2,
    cursor: 'pointer',
    zIndex: 5,
    position: 'absolute',
  },
  inputLabel: {
    marginBottom: '1.1em',
  },
  uploadImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.white_EEEEEC,
    border: `0.063em dashed ${theme.colors.gray_C9CBCA}`,
    boxSizing: 'border-box',
    borderRadius: '0.25em',
    height: '2.65em',
    padding: '0.625em 0.75em',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'relative',
  },
  hide: {
    display: 'none',
  },
  placeHolder: {
    color: theme.colors.gray_A8ACAD,
    marginLeft: 5,
  },
  image: {
    maxHeight: '800vh',
    objectFit: 'contain',
    backgroundColor: theme.colors.white_FFFFFF,
    borderRadius: '0.4em',
  },
  imageContainer: {
    position: 'relative',
    margin: '1em 0em',
  },
}));
