import { Container, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FrequentQuestion } from '../../model/FrequentQuestion';
import AlertService from '../../services/AlertService';
import HelpCenterService from '../../services/HelpCenterService';
import AppTheme from '../../utils/AppTheme';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import AppHeader from '../commons/AppHeader';
import { AppLayout } from '../core/AppLayout';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

const logger = new Logger('HelpCenterDetail');

const HelpCenterDetail = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [frequentQuestion, setFrequentQuestion] = useState<FrequentQuestion | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);

  useEffect(() => {
    if (id) {
      getHelpCenterDetail(id);
    }
  }, [id]);

  const getHelpCenterDetail = async (externalId: string) => {
    setRequestStatus(RequestStatus.LOADING);

    const response = await HelpCenterService.getFrequentQuestionById(externalId);

    response
      .onSuccess((response) => {
        setFrequentQuestion(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to fecth FAQ detail. ', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getBodyMessage = (content: string) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  };
  const getContent = (): JSX.Element => {
    return (
      <Container sx={muiStyles.container}>
        <div className={classes.innerContainer}>
          <AppTypography
            type={PDLTypography.paragraph}
            children={getBodyMessage(frequentQuestion ? frequentQuestion?.body : '')}
            customStyles={{ marginTop: '0.625em', marginBottom: '1.25em' }}
          />
        </div>
      </Container>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content={frequentQuestion ? frequentQuestion.title : ''} />}
      content={() => getContent()}
    />
  );
};

export default HelpCenterDetail;

const muiStyles = {
  container: {
    backgroundColor: AppTheme.colors.white_EEEEEC,
    height: 'calc(100vh - 6.25em)',
  } as SxProps,
};

const useStyles = makeStyles({
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.875em',
  },
  paragraph: {
    marginTop: '0.625em',
    marginBottom: '1.25em',
  },
});
