import environment from "../../../environment.json";

export enum StorageStrategy {
  ASYNC = "Async",
  SYNC = "Sync",
}

export enum EnviromentType {
  DEV = "dev",
  QA = "qa",
  PREPROD = "preprod",
  PROD = "prod"
}

interface Environment {
  storageStrategy?: string;
  baseURL?: string;
  enableMainBarService?: boolean;
}

class EnvironmentService {
  storageStrategy: StorageStrategy;
  enableMainBarService: boolean;

  constructor() {
    const env: Environment = environment;
    this.storageStrategy = env.storageStrategy
      ? (env.storageStrategy as StorageStrategy)
      : StorageStrategy.SYNC;
    this.enableMainBarService = !!env.enableMainBarService;
  }

  getStorageStrategy(): StorageStrategy {
    return this.storageStrategy;
  }

  usesAsyncStorageStrategy(): boolean {
    return this.storageStrategy === StorageStrategy.ASYNC;
  }

  isMainBarServiceEnabled(): boolean {
    return this.enableMainBarService;
  }

}

export default new EnvironmentService();
