import { AppFontFamily } from '../../../utils/AppFontFamily';
import AppTheme from '../../../utils/AppTheme';
import { Box } from '@mui/material';
import { SxStyles } from '../../../model/utils/SxStyles';

interface Props {
  text: string;
  lat?: number;
  lng?: number;
  onClick?: () => void;
  isActive?: boolean;
  isAvailable?: boolean;
  hasDiscount?: boolean
}

export const CustomMarker = (props: Props) => {
  const { text, isActive = false, isAvailable = false } = props;

  return (
    <div
      onClick={(e) => {
        props.onClick && props.onClick();
        e.stopPropagation();
      }}
      style={{ display: 'flex' }}
    >
      <Box
        sx={styles.marker}
        style={
          isActive
            ? {
              backgroundColor: AppTheme.colors.persianBlue_2238CB,
              color: props.hasDiscount ? AppTheme.colors.light_green_CCE7C9 : AppTheme.colors.white_EEEEEC,
              border: 'none',
            }
            :
            {
              backgroundColor: (isAvailable ? AppTheme.colors.white_FFFFFF : AppTheme.colors.gray_01000E1A),
              color: props.hasDiscount ? AppTheme.colors.green_008000 : AppTheme.colors.black_000000,
            }
        }
      >
        {text}
      </Box>
    </div>
  );
};

const styles: SxStyles = {
  marker: {
    height: '20px',
    cursor: 'pointer',
    padding: '0.2em',
    borderRadius: 40,
    border: '1px solid #01000E',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '0.5em',
    paddingLeft: '0.5em',
  },
  defaultMarker: {
    backgroundColor: AppTheme.colors.white_FFFFFF,
  },
  activeMarker: {
    backgroundColor: AppTheme.colors.persianBlue_2238CB,
    color: 'white',
    border: 'none',
  },
};
