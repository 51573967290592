import { CrudService } from '../external/fox-typescript/services/CrudService';
import Location from '../external/fox-typescript/core/Location';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PagedResult from '../external/fox-typescript/core/PagedResult';

class RecentLocationService implements CrudService<Location> {
  locationsKey: string;

  constructor() {
    this.locationsKey = 'recent_searches';
  }

  save(newLocation: Location) {
    const recentSearches = localStorage.getItem(this.locationsKey);

    if (!recentSearches) {
      localStorage.setItem(this.locationsKey, JSON.stringify([newLocation]));
    } else {
      const locations: Location[] = JSON.parse(recentSearches);
      const exist = locations.find((location) => location.geocode === newLocation.geocode);

      if (!exist) {
        locations.unshift(newLocation);
        localStorage.setItem(this.locationsKey, JSON.stringify(locations));
      }
    }
  }

  getRecentLocations() {
    const recentSearchesStorage = localStorage.getItem(this.locationsKey);
    const recentSearches: Location[] = JSON.parse(
      recentSearchesStorage ? recentSearchesStorage : '[]'
    );
    return recentSearches;
  }

  getAll(page: number, pageSize: number): Promise<ApiResponse<PagedResult<Location>>> {
    throw new Error('Method not implemented.');
  }

  create(instance: Location): Promise<ApiResponse<Location>> {
    throw new Error('Method not implemented.');
  }

  async delete(instance: Location): Promise<ApiResponse<Location>> {
    throw new Error('Method not implemented.');
  }
}

export default new RecentLocationService();
