import { makeStyles } from '@mui/styles';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

interface Props {
  src: string;
  acceptOrRejectImage: (accepted: boolean) => void;
}

const useStyles = makeStyles({
  acceptOrRejectSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacing: {
    marginRight: '2em',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  imagePreview: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const ImagePreview: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const isLandscape = () => {
    return window.innerWidth > window.innerHeight;
  };

  return (
    <>
      <div className={'demo-image-preview ' + classes.imagePreview}>
        <AppImage
          src={props.src}
          style={{ width: '300px', height: isLandscape() ? '200px' : 'auto' }}
          alt="Preview"
        />

        <div className={classes.acceptOrRejectSection}>
          <div
            className={classes.spacing + ' ' + classes.cursorPointer}
            onClick={() => props.acceptOrRejectImage(true)}
          >
            <AppTypography type={PDLTypography.paragraph}>Accept</AppTypography>
          </div>

          <div className={classes.cursorPointer} onClick={() => props.acceptOrRejectImage(false)}>
            <AppTypography type={PDLTypography.paragraph}>Reject</AppTypography>
          </div>
        </div>
      </div>
    </>
  );
};
