import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { useForm } from '../../hooks/useForm';
import PasswordUpdate from '../../model/update/PdlUser/PasswordUpdate';
import AlertService from '../../services/AlertService';
import PdlAuthService from '../../services/PdlAuthService';
import { RequestStatus } from '../../utils/RequestStatus';
import { AppTextField } from '../commons/AppTextField';
import BottomSheet from '../commons/BottomSheet';
import NotificationModal from '../commons/NotificationModal';
import { PasswordHelper } from '../commons/PasswordHelper';
import SimpleButton from '../commons/SimpleButton';
import { AppTypography, AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

interface Props {
  open: boolean;
  onClose: () => void;
  oldPassword?: string;
}
interface ChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePasswordBottomSheet = ({ open, onClose }: Props) => {
  const classes = useStyles();

  const [notificationModalOpen, setNotificationModalOpen] = useState<boolean>(false);
  const [passwordHelperOpen, setPasswordHelperOpen] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const confirmNewPasswordInput = useRef<{ validate: () => void }>(null);

  const [form, validations, handleCustomChange, formIsValid, clearForm, setValidations] =
    useForm<ChangePassword>({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });

  useEffect(() => {
    if (form.confirmNewPassword) {
      const isValid = confirmNewPasswordInput?.current?.validate();

      let updatedValidations = { ...validations };
      (updatedValidations as any).confirmNewPassword = isValid;
      setValidations(updatedValidations);
    }
  }, [form.newPassword]);

  useEffect(() => {
    if (requestStatus) {
      setRequestStatus(undefined);
    }
  }, [form.oldPassword, form.newPassword, form.confirmNewPassword]);

  const handleSubmitButton = async () => {
    setRequestStatus(RequestStatus.LOADING);

    const response = await PdlAuthService.changePassword(
      new PasswordUpdate(form.oldPassword, form.newPassword)
    );
    response
      .onSuccess(() => {
        setRequestStatus(RequestStatus.SUCCESS);
        setNotificationModalOpen(true);
        clearForm();
        onClose();
      })
      .onError((response) => {
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  return (
    <>
      <BottomSheet open={open} onClose={onClose} bottomSpace="5em">
        <div className={classes.titleContainer}>
          <AppTypography type={PDLTypography.largeHeading} children={'Change Password'} />
        </div>
        <AppTextField
          name={'oldPassword'}
          label={'Old Password'}
          inputProps={{ type: 'password' }}
          value={form.oldPassword}
          labelProps={labelProps}
          onInputChange={handleCustomChange}
          containerClasses={classes.textFieldContainer}
          realTimeValidation
          required
        />
        <AppTextField
          name={'newPassword'}
          label={'New Password'}
          inputProps={{ type: 'password' }}
          value={form.newPassword}
          labelProps={labelProps}
          onInputChange={handleCustomChange}
          containerClasses={classes.textFieldContainer}
          realTimeValidation
          required
          restrict="strongPassword"
          bottomRightElement={
            <AppTypography
              type={PDLTypography.smallParagraph}
              children={'Valid format'}
              customStyles={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textAlign: 'right',
              }}
              onClick={() => setPasswordHelperOpen(open)}
            />
          }
        />
        <AppTextField
          ref={confirmNewPasswordInput}
          name={'confirmNewPassword'}
          label={'Confirm New Password'}
          inputProps={{ type: 'password' }}
          value={form.confirmNewPassword}
          labelProps={labelProps}
          onInputChange={handleCustomChange}
          containerClasses={classes.textFieldContainer}
          realTimeValidation
          required
          customValidation={(text) => {
            if (text !== form.newPassword) {
              return {
                showError: true,
                isValid: false,
                message: 'Confirm password does not match',
              };
            }
            return { showError: false, isValid: true, message: '' };
          }}
        />
        <SimpleButton
          children={'Confirm Password'}
          sx={{ mt: 4 }}
          onClick={handleSubmitButton}
          isLoading={requestStatus === RequestStatus.LOADING}
          disabled={
            requestStatus === RequestStatus.LOADING ||
            requestStatus === RequestStatus.ERROR ||
            !formIsValid()
          }
        />
      </BottomSheet>
      <NotificationModal
        open={notificationModalOpen}
        onClose={() => setNotificationModalOpen(false)}
        text={'Your password has been updated'}
      />
      {/* Password Helper */}
      <PasswordHelper open={passwordHelperOpen} onClose={() => setPasswordHelperOpen(false)} />
    </>
  );
};

export default ChangePasswordBottomSheet;

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1.25em',
  },
  textFieldContainer: {
    marginTop: '0.625em',
  },
  confirmButtonContainer: {
    marginTop: '0.5em',
    marginBottom: '0.7em',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
  },
});
