import { makeStyles } from '@mui/styles';
import AppTheme, { COLORS } from '../../utils/AppTheme';
import CloseIcon from '@mui/icons-material/Close';
import { Container, SxProps } from '@mui/material';
import { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import AppImage from './AppImage';
import Image from '../../../fox-typescript/core/Image';

interface Props<T> {
  onClose: () => void;
  imgIndex?: number;
  data: T[];
  renderItem?: (item: T, index: number) => JSX.Element;
  settings?: Settings;
  currentIndex?: number;
  initialSlide?: number;
  itemsToShow?: number;
  showIndexIndicator?: boolean;
  infinite?: boolean;
}

export default function ImageGallery<T>(props: Props<T>) {
  const classes = userStyles();
  const sliderRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState<number>(props.initialSlide || 0);

  const defaultRender = (img: Image) => {
    return (
      <div key={img.externalId}>
        <div className={classes.imageCarouselContainer} onClick={(e) => e.stopPropagation()}>
          <AppImage className={classes.img} src={img.url} />
        </div>

        <AppTypography type={PDLTypography.paragraph} customClasses={classes.description}>
          {img.description}
        </AppTypography>
      </div>
    );
  };

  return (
    <div className={classes.mainContainer}>
      <Container>
        <Slider
          {...props.settings}
          afterChange={(index: number) => setCurrentIndex(index)}
          ref={sliderRef}
          className={classes.slider}
          initialSlide={props.initialSlide}
          infinite={props.infinite}
        >
          {props.data.map((v, i) =>
            props.renderItem ? props.renderItem(v, i) : defaultRender(v as unknown as Image)
          )}
        </Slider>
        <div className={classes.icon} onClick={props.onClose}>
          Close
        </div>
        {props.showIndexIndicator && (
          <div className={classes.indexImageContainer}>
            <AppTypography
              type={PDLTypography.smallParagraph}
              customStyles={{
                color: COLORS.white_FFFFFF,
              }}
            >
              {currentIndex + 1} of {props.data.length}
            </AppTypography>
          </div>
        )}
      </Container>
    </div>
  );
}

const userStyles = makeStyles(() => ({
  slider: {
    textAlign: 'center',
    '& .slick-list': {
      paddingLeft: '0em',
      paddingBottom: '0.3em',
    },
  },

  mainContainer: {
    position: 'fixed',
    height: '100%',
    top: '0',
    left: '0',
    width: '100%',
    zIndex: 1200,
    backgroundColor: 'black',
    alignItems: 'center',
    display: 'flex',
    '& .MuiContainer-root': {
      padding: '0em',
    },
  },
  imageCarouselContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  indexImageContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    color: 'white',
    backgroundColor: 'rgba(1, 0, 14, 0.5)',
    borderRadius: 100,
    border: '2px solid white',
    display: 'flex',
    padding: '1px 8px',
  },
  img: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  icon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    padding: '5px',
    color: AppTheme.colors.white_FFFFFF,
    cursor: 'pointer',
  },
  sliderContainer: {
    height: '100vh',
    width: '100vw',
    position: 'relative',
  },
  description: {
    color: COLORS.white_FFFFFF,
    textAlign: 'center',
    marginTop: '0.5em',
  },
}));
