import { CircularProgress, List, SxProps } from '@mui/material';
import { useState } from 'react';
import { COLORS } from '../../utils/AppTheme';
import { RequestStatus } from '../../utils/RequestStatus';

interface Props<T> {
  style?: React.CSSProperties;
  data: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  loadMore: () => Promise<void>;
  className?: string;
  sx?: SxProps;
  topScroll?: boolean;
}
export function AppSimpleList<T>(props: Props<T>) {
  const [loadMoreStatus, setLoadMoreStatus] = useState(RequestStatus.SUCCESS);

  const handleListScroll = (event: any) => {
    const currentScrollPosition = Math.abs(event.target.scrollTop + event.target.clientHeight) + 2;
    const maxScrollHeight = event.target.scrollHeight - event.target.clientHeight * 2;

    const shouldLoadMore = props.topScroll
      ? event.target.scrollTop <= 200
      : currentScrollPosition >= maxScrollHeight;

    if (shouldLoadMore) {
      setLoadMoreStatus(RequestStatus.LOADING);
      props.loadMore().then(() => setLoadMoreStatus(RequestStatus.SUCCESS));
    }
  };

  return (
    <List
      sx={props.sx}
      style={props.style}
      className={props.className}
      onScroll={loadMoreStatus === RequestStatus.SUCCESS ? handleListScroll : undefined}
    >
      {props.data.map(props.renderItem)}
      {loadMoreStatus === RequestStatus.LOADING && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: COLORS.persianBlue_2238CB,
          }}
        >
          <CircularProgress size={30} color="inherit" />
        </div>
      )}
    </List>
  );
}
