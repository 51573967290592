import { useEffect, useState } from 'react';
import ContentService from '../../services/ContentService';
import Logger from '../../external/pdl-common/utils/Logger';
import { Content, ContentCategory } from '../../model/Content';
import AppHeader from '../commons/AppHeader';
import { Box, Container, Divider, Grid } from '@mui/material';
import { AppLayout } from '../core/AppLayout';
import { useParams } from 'react-router-dom';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { SxStyles } from '../../model/utils/SxStyles';
import { makeStyles } from '@mui/styles';
import { RequestStatus } from '../../utils/RequestStatus';
import DOMPurify from 'dompurify';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';

const logger = new Logger('ContentLibraryDetail');

export const ContentLibraryDetail = () => {
  const classes = useStyles();
  const { externalId } = useParams();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [content, setContent] = useState<Content | undefined>(undefined);

  useEffect(() => {
    getContentDetails();
  }, []);

  const getContentDetails = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await ContentService.getContentDetails(externalId!))
      .onSuccess((response) => {
        setContent(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        setRequestStatus(RequestStatus.ERROR);
        logger.error('Error trying to get article/tutorial ', response.getContent());
      });
  };

  const getHeader = () => {
    return <AppHeader content={content?.title!} />;
  };

  const getBody = () => {
    window.scrollTo(0, 0);
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ height: '100vh' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={getContentDetails} />
        </div>
      );
    }

    return (
      <Container maxWidth="md">
        <Grid container gap={3} sx={styles.container}>
          {content?.videoUrl && (
            <video src={content.videoUrl} controls className={classes.multimedia} />
          )}

          <AppTypography type={PDLTypography.largeHeading} children={content?.title} />

          {content?.category === ContentCategory.TUTORIAL && (
            <>
              <Divider />
              <AppTypography type={PDLTypography.smallHeading} children={'About this video'} />
            </>
          )}

          <AppTypography
            type={PDLTypography.paragraph}
            children={
              content?.description && (
                <Box sx={{ maxWidth: '90vw', textAlign: 'justify', textJustify: 'inter-word' }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(content.description),
                    }}
                  />
                </Box>
              )
            }
          />
        </Grid>
      </Container>
    );
  };

  return <AppLayout header={getHeader} content={getBody} fromHome />;
};

const styles: SxStyles = {
  container: {
    py: 5,
    display: 'flex',
    flexDirection: 'column',
  },
};

const useStyles = makeStyles({
  multimedia: {
    width: '100%',
    height: '100%',
    borderRadius: '.5em',
  },
});
