import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { AppAssets } from '../../assets/index';
import AppTheme from '../../utils/AppTheme';
import { SxStyles } from '../../model/utils/SxStyles';

interface Props {
  placeholder: string;
  onChange?: (newValue: string) => void;
  onClick?: () => void;
  value?: string;
  buttonDisabled?: boolean;
  inputDisabled?: boolean;
}

// TODO: Fix ICON size
export const SearchBar = (props: Props) => {
  return (
    <Paper component="form" sx={styles.searchContainer}>
      <IconButton type="submit" aria-label="search" size="large" disabled={props.buttonDisabled}>
        <AppAssets.SearchIcon style={{ height: '16px', width: '16px' }} />
      </IconButton>
      <InputBase
        sx={styles.searchInput}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => {
          props.onChange && props.onChange(e.target.value);
        }}
        onClick={props.onClick}
        disabled={props.inputDisabled}
      />
    </Paper>
  );
};

const styles: SxStyles = {
  searchContainer: {
    height: 40,
    backgroundColor: '#FFFFFF',
    borderRadius: 200,
    boxShadow: 'none',
    display: 'flex',
    marginLeft: '0.5em',
  },
  searchInput: {
    width: '100%',
    color: AppTheme.colors.black_01000E,
    fontSize: 13,
    fontFamily: 'Opens Sans',
  },
};
