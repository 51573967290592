import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import AppTheme from '../../utils/AppTheme';
import SimpleButton from './SimpleButton';
import { SxProps } from '@mui/system';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { useEffect } from 'react';

interface Props {
  title: string;
  subTitle: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}

export const EmptyList = (props: Props) => {
  useEffect(() => {
    // to avoid scrolling
    document.body.style.backgroundColor = 'black';
    return () => {
      document.body.style.backgroundColor = 'white';
    };
  }, []);

  return (
    <Grid container>
      <Box sx={styles.container}>
        <Box sx={styles.contentContainer}>
          <AppTypography
            type={PDLTypography.largeHeading}
            customStyles={{ color: AppTheme.colors.white_FFFFFF }}
            children={props.title}
          />
          <AppTypography
            type={PDLTypography.paragraph}
            customStyles={{ color: AppTheme.colors.white_FFFFFF }}
            children={props.subTitle}
          />

          {props.buttonText && (
            <SimpleButton theme="white" onClick={props.buttonOnClick} sx={styles.button}>
              {props.buttonText}
            </SimpleButton>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

const styles = {
  container: {
    backgroundColor: AppTheme.colors.black_01000E,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '60vh',
  } as SxProps,
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 2,
    px: 5,
    textAlign: 'center',
  } as SxProps,
  button: {
    textTransform: 'none',
    width: '60%',
    m: '0 auto',
  } as SxProps,
};
