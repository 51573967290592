import { BankInformationForm } from '../components/views/Host/PayoutMethod';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import HttpClient from '../external/fox-typescript/utils/HttpClient';

class StripeService {
  async getCurrentCreditCard(): Promise<ApiResponse<string>> {
    return await HttpClient.get('/stripe/card');
  }

  async getDefaultBankAccount(): Promise<ApiResponse<string>> {
    return await HttpClient.get('/stripe/account/bank', true);
  }

  async checkIfHasAccount(): Promise<ApiResponse<boolean>> {
    return await HttpClient.get('/stripe/check-account');
  }

  async createCard(cardToken: string): Promise<ApiResponse<string>> {
    return await HttpClient.post('/stripe/card', { cardToken });
  }

  async createConnectAccount(data: BankInformationForm): Promise<ApiResponse<string>> {
    const formData = new FormData();
    formData.append('document', data.document!.file, data.document!.file.name);

    data.document = undefined;
    formData.append('data', JSON.stringify(data));

    return HttpClient.post('stripe/account', formData);
  }

  async createBankAccount(data: BankInformationForm): Promise<ApiResponse<string>> {
    return HttpClient.post('stripe/account/bank', data);
  }
}

export default new StripeService();
