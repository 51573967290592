import { Box } from '@mui/material';
import { useState } from 'react';
import { UploadImage } from '../commons/UploadImage';
import Image from '../../external/fox-typescript/core/Image';
import { RequestStatus } from '../../utils/RequestStatus';
import AppHeader from '../commons/AppHeader';
import { AppLayout } from '../core/AppLayout';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import Logger from '../../external/pdl-common/utils/Logger';
import AlertService from '../../services/AlertService';
import FormContainer from '../commons/FormContainer';
import SimpleButton from '../commons/SimpleButton';
import { setTimeout } from 'timers';
import Navbar from '../core/Navbar';

const logger = new Logger('EditAvatar');

export function EditAvatar() {
  const navigate = useNavigate();

  const [image, setImage] = useState<Image | undefined>(undefined);
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [imageExternalId, setImageExternalId] = useState<string>('');
  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);

  const onSubmit = async () => {
    setRequestStatus(RequestStatus.LOADING);
    (await UserService.updateAvatar(imageExternalId!))
      .onSuccess((response) => {
        setRequestStatus(RequestStatus.SUCCESS);
        AlertService.showSuccessMessage('Profile picture updated successfully');
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .onError((response) => {
        logger.error('Error trying to update profile picture', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const handleSetImage = (profileImage: Image) => {
    setImage(profileImage);
    setImageExternalId(profileImage.externalId || '');
  };

  const handleRemoveImage = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      setImage(undefined);
      setImageFile(undefined);
      setImageExternalId('');
    }
  };

  const getContent = () => {
    return (
      <>
        <FormContainer
          bottomElementStyle={{ marginBottom: '59px' }}
          BottomElement={
            <SimpleButton
              children={'Update Profile Picture'}
              onClick={onSubmit}
              isLoading={requestStatus === RequestStatus.LOADING}
              disabled={imageExternalId === '' || requestStatus === RequestStatus.LOADING}
            />
          }
        >
          <Box sx={{ mt: 2 }}>
            <UploadImage
              image={image}
              imageUrls={image ? [image.url] : undefined}
              label="New Profile Picture"
              onImageUploaded={handleSetImage}
              labelProps={{ customStyles: { marginBottom: '1em', marginTop: '3em' } }}
              setImageFiles={(files) => {
                if (files) {
                  setImageFile(files[0]);
                }
              }}
              handleSetImage={handleSetImage}
              imageFiles={imageFile ? [imageFile] : undefined}
              onRemove={handleRemoveImage}
            />
          </Box>
        </FormContainer>
        <Navbar/>
      </>
    );
  };
  // Render
  return (
    <AppLayout
      noNavBar
      header={() => <AppHeader content="Update Profile Picture" />}
      content={() => getContent()}
    />
  );
}
