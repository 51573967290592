import { Box, CheckboxProps, Container, SxProps } from '@mui/material';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppCheckbox } from './AppCheckbox';

interface Props extends CheckboxProps {
  label: React.ReactNode;
  extrainfo?: React.ReactNode;
  containerSx?: SxProps;
}

export const CheckboxWithLabel = (props: Props) => {
  return (
    <Box style={{ marginTop: '.8em' }} sx={props.containerSx}>
      <AppCheckbox value={props.value || props.checked} {...props} />

      <AppTypography type={PDLTypography.subHeading} customStyles={{ marginLeft: '.7em' }}>
        {props.label}
      </AppTypography>

      {props.extrainfo && <Container>{props.extrainfo}</Container>}
    </Box>
  );
};
