import { useState, useEffect } from 'react';
import { AppLayout } from '../../../core/AppLayout';
import AppHeader from '../../../commons/AppHeader';
import { useParams } from 'react-router-dom';
import BikeService from '../../../../services/BikeService';
import Logger from '../../../../external/pdl-common/utils/Logger';
import { AppTypography } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';
import { Box, Container } from '@mui/material';
import { SxStyles } from '../../../../model/utils/SxStyles';
import SimpleButton from '../../../commons/SimpleButton';
import { UploadImage } from '../../../commons/UploadImage';
import Image from '../../../../external/fox-typescript/core/Image';
import { Bike } from '../../../../external/pdl-common/model/Bike';
import { RequestStatus } from '../../../../utils/RequestStatus';
import RequestStatusFeedBack from '../../../commons/RequestStatusFeedback';
import { AlertDialog } from '../../../commons/AlertDialog';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../../../utils/AppNavigation';
import AlertService from '../../../../services/AlertService';
import ConfirmBikeEditionBottomSheet from '../commons/ConfirmBikeEditionBottomSheet';
import { BikeStatus } from '../../../../external/pdl-common/utils/enums/BikeStatus';
import { EditionType } from '../../../../model/enums/EditionType';

const logger = new Logger('HostBikeEditImages');

export const HostBikeEditImages = () => {
  const { externalId } = useParams();

  const [bike, setBike] = useState<Bike | undefined>(undefined);
  const [images, setImages] = useState<Image[]>([]);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [saveChangesStatus, setSaveChangesStatus] = useState(RequestStatus.SUCCESS);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [ischanged, setIsChanged] = useState<boolean>(false);
  const [showConfirmEdition, setShowConfirmEdition] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    getBikeDetails();
  }, []);

  useEffect(() => {
    const hasImages = images.some((i) => i.url !== '');
    setIsValid(hasImages);
  }, [images]);

  const navigate = useNavigate();

  const onBack = () => {
    if (ischanged) {
      setOpenAlertDialog(true);
    } else {
      appNavigate(navigate, AppRoutes.HOST_BIKE_EDIT, { externalId: externalId! });
    }
  };

  const getBikeDetails = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeService.getByExternalId(externalId!))
      .onSuccess((response) => {
        setBike(response.getContent());
        setImages([
          ...response.getContent().images,
          { externalId: 'temporalId', url: '', width: 0, height: 0, type: '', description: '' },
        ]);

        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error fetching bike images', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const handleCustomChange = (inputValue: string, image: Image) => {
    const imageToUpdate = images.find((img) => img.externalId === image.externalId);

    if (imageToUpdate) {
      imageToUpdate.description = inputValue;
      setIsChanged(true);
    }

    setImages([...images]);
  };

  const handleRemoveImages = (imageIdx: number) => {
    const newImages = images.filter((img, idx) => imageIdx !== idx);
    setImages(newImages);
    setIsChanged(true);
  };

  const handleUploadMoreImages = () => {
    if (!!images[images.length - 1] && !images[images.length - 1].url) {
      logger.warn('The previous image is empty');
      return;
    }

    setImages([
      ...images,
      {
        url: '',
        type: '',
        height: 0,
        width: 0,
        description: '',
      },
    ]);
  };

  const handleSetImage = (image: Image) => {
    images.pop();
    setImages([...images, image]);
    setIsChanged(true);
  };

  const handleonBeforeUpdateBike = () => {
    if (bike?.status === BikeStatus.PENDING) {
      handleUpdateBike();
      return;
    }

    setShowConfirmEdition(true);
  };

  const handleUpdateBike = async () => {
    try {
      if (!bike) return;

      setSaveChangesStatus(RequestStatus.LOADING);

      let bikeUpdated = bike;
      bikeUpdated.images = images.filter((img) => img.externalId !== 'temporalId');

      const result = await BikeService.updateActive(bikeUpdated, EditionType.BIKE_IMAGES);
      setBike(result.getContent());
      setImages(result.getContent().images);

      setSaveChangesStatus(RequestStatus.SUCCESS);

      AlertService.showSuccessMessage('Bike images edited successfully');
      setTimeout(() => {
        // Return to previous screen automatically after 3 seconds
        navigate(-1);
      }, 2000);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        logger.error(error?.response?.data?.message, error);
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        const err = 'Error when trying to update the images';
        logger.error(err, error);
        AlertService.showSnackCustomError(err);
      }
    } finally {
      setSaveChangesStatus(RequestStatus.SUCCESS);
    }
  };

  const renderHeader = () => {
    return <AppHeader content={'Edit listing images'} onBack={onBack} />;
  };

  const renderContent = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <RequestStatusFeedBack
            status={requestStatus}
            onRetry={!bike ? getBikeDetails : handleonBeforeUpdateBike}
          />
        </Box>
      );
    }

    return (
      <>
        <Container maxWidth={'md'} sx={styles.container}>
          <Box sx={styles.textContainer}>
            <AppTypography type={PDLTypography.smallParagraph}>
              Upload images of the whole bike. You can upload images of specific bike parts in the
              next step.
            </AppTypography>
          </Box>

          <Box sx={{ mb: 5 }}>
            {images.map((image, index) => (
              <UploadImage
                key={`${image.externalId}-${index}`}
                imageUrls={image.url ? [image.url] : undefined}
                handleSetImage={handleSetImage}
                caption
                onCaptionChange={(inputName, inputValue) => handleCustomChange(inputValue, image)}
                image={image}
                defaultImages
                onRemove={handleRemoveImages}
                imageIndex={index}
              />
            ))}
          </Box>

          <SimpleButton onClick={handleUploadMoreImages} sx={{ width: '70%', m: 'auto' }}>
            Upload New Photos
          </SimpleButton>
        </Container>

        <Box sx={styles.saveBtnContainer}>
          <Box sx={{ width: '85vw', margin: 'auto' }}>
            <SimpleButton
              onClick={handleonBeforeUpdateBike}
              disabled={saveChangesStatus === RequestStatus.LOADING || !isValid}
              isLoading={saveChangesStatus === RequestStatus.LOADING}
            >
              Save Changes
            </SimpleButton>
          </Box>
        </Box>

        <AlertDialog
          title="Leave edit"
          content="Are you sure to leave edit? Your changes will be discarted"
          open={openAlertDialog}
          onConfirm={() => {
            setOpenAlertDialog(false);
            navigate(-1);
          }}
          onCancel={() => {
            setOpenAlertDialog(false);
          }}
        />

        <ConfirmBikeEditionBottomSheet
          open={showConfirmEdition}
          onClose={() => setShowConfirmEdition(false)}
          onConfirm={handleUpdateBike}
        />
      </>
    );
  };

  return <AppLayout header={renderHeader} content={renderContent} />;
};

const styles: SxStyles = {
  container: {
    mt: 3,
    mb: 12,
    width: '95vw',
  },
  textContainer: {
    mb: '1em',
    ml: '0.1em',
    display: 'flex',
    justifyContent: 'center',
  },
  saveBtnContainer: {
    position: 'fixed',
    bottom: 69,
    width: '100%',
  },
};
