import { makeStyles, CSSProperties } from '@mui/styles';
import { Bike } from '../../external/pdl-common/model/Bike';
import Grid from '@mui/material/Grid';
import { Divider, Box } from '@mui/material';
import ImageWithTopIcons from '../commons/ImageWithTopIcons';
import Price from '../commons/Price';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';
import { BikeRating } from './BikeRating';
import { BikeAttribute } from './BikeSize';
import BikeService from '../../services/BikeService';
import Logger from '../../external/pdl-common/utils/Logger';
import Reservation from '../../model/Reservation';
import { useState } from 'react';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import BikeUtils from '../../external/pdl-common/utils/BikeUtils';

interface Props {
  bike: Bike;
  startDate?: Date;
  endDate?: Date;
  totalPrice?: number;
  withSize?: boolean;
  withRating?: boolean;
  withFinalPrice?: boolean;
  withLocation?: boolean;
  showDivider?: boolean;
  containerStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  imgClassName?: string;
  iconsClassName?: string;
  showReservationDates?: boolean;
  hidePrice?: boolean;
  bikeWishlist?: Bike[];
  setWishlist?: (bikes: Bike[]) => void;
  setRecentlyViewed?: (bike: Bike) => void;
  handleOnClickEvent: () => void;
  reservation?: Reservation;
  label?: string;
}

const logger = new Logger('BikeItem');

const BikeItem = ({
  bike,
  startDate,
  endDate,
  totalPrice,
  withSize = false,
  withRating = false,
  withFinalPrice = false,
  withLocation = true,
  showDivider = true,
  containerStyle,
  imageStyle,
  imgClassName,
  iconsClassName,
  showReservationDates = false,
  hidePrice = false,
  bikeWishlist,
  setWishlist,
  setRecentlyViewed,
  handleOnClickEvent,
  reservation,
  label
}: Props) => {
  const classes = useStyles();
  const [favorite, setFavorite] = useState(bike.favourite);

  const handleOnClickFav = async () => {
    (await BikeService.addToWishList(bike.externalId!))
      .onSuccess((response) => {
        setFavorite(response.getContent());

        if (setWishlist && bikeWishlist) {
          bike.favourite = response.getContent();
          if (response.getContent()) {
            setWishlist([...bikeWishlist, bike]);
          } else {
            const newWishlist = bikeWishlist.filter((b) => b.externalId !== bike.externalId);
            setWishlist(newWishlist);
          }
          if (setRecentlyViewed) {
            setRecentlyViewed(bike);
          }
        }
      })
      .onError((response) => {
        logger.error('Error trying to get bike wishlist ', response.getContent());
      });
  };

  // Render
  return (
    <>
      <Grid container maxWidth={450} mx={1} sx={muiStyles.grid} style={containerStyle}>
        <Grid item xs={5}>
          <ImageWithTopIcons
            src={bike.images && bike.images.length > 0 ? bike.images[0].url : ''}
            alt={bike.title}
            imgStyles={imageStyle}
            imgClassName={imgClassName}
            iconsClassName={iconsClassName}
            favourite={favorite}
            chipLabel={label}
            onClick={() => handleOnClickEvent()}
            onClickFav={() => handleOnClickFav()}
          />
        </Grid>

        <Grid item xs={7} onClick={() => handleOnClickEvent()}>
          <div className={classes.bikeInfo}>
            <p className={classes.title}>{bike.title}</p>

            {withSize && <BikeAttribute label={'Size:'} value={bike.size} />}
            {withRating && <BikeRating rating={Math.round(bike.avgStars)} totalRides={bike.totalRides} />}
            {!hidePrice && (
              <Box mt={1}>
                <Price
                  customPrice={bike.customPrice}
                  price={startDate ? BikeUtils.dailyPrice(bike, startDate!, endDate!) : bike.customPrice}
                  finalPrice={withFinalPrice ? totalPrice : undefined}
                  direction="column"
                  discountIcon={bike.twoDayPrice != undefined || bike.threePlusDayPrice != undefined}
                />
              </Box>
            )}

            {showReservationDates && (
              <p className={classes.reservation}>
                {DateUtils.formattedRangeDates(
                  new Date(reservation!.startDate),
                  new Date(reservation!.endDate)
                )}
              </p>
            )}

            {withLocation && (
              <p className={classes.location}>
                {bike.location?.city && `${bike.location?.city},`} {bike.location?.state}
              </p>
            )}
          </div>
        </Grid>
      </Grid>
      {showDivider && <Divider />}
    </>
  );
};

export default BikeItem;

const muiStyles = {
  grid: {
    borderRadius: '0.8em',
    marginBottom: '0.8em',
    marginTop: '0.8em',
  },
};

const useStyles = makeStyles({
  gridContent: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: 'black',
    textAlign: 'left',
    fontSize: '1.125em',
    fontFamily: AppFontFamily.SHAPE_BOLD,
    marginTop: 0,
    marginBottom: 0,
    paddingRight: '0.2em',
  },
  location: {
    marginTop: '0.3em',
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.SHAPE,
  },
  bikeInfo: {
    padding: '0.5em',
    textAlign: 'left',
    marginLeft: '0.625em',
    fontFamily: AppFontFamily.SHAPE,
    cursor: 'pointer',
  },
  reservation: {
    marginTop: '.5em',
    marginBottom: 1,
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.SHAPE,
  },
});
