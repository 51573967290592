import { Box } from '@mui/material';
import { useState } from 'react';
import Carousel from 'react-simply-carousel';
import BubbleTextAndLabel from '../../commons/BubbleTextAndLabel';

interface Props {
  // Margin left
  ml?: number;
}

export const ArticleCarousel = ({ ml }: Props) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const getArrayOfArticleMotives = () => {
    return [
      'bikes',
      'renting',
      'maintenance',
      'cycling tips',
      'bikes',
      'renting',
      'maintenance',
      'cycling tips',
      'bikes',
      'renting',
      'maintenance',
      'cycling tips',
    ];
  };

  return (
    <Box style={{ cursor: 'pointer', marginLeft: `${ml}em` }}>
      <Carousel
        containerProps={{
          style: {
            width: '100%',
            justifyContent: 'space-between',
            userSelect: 'text',
          },
        }}
        activeSlideIndex={activeSlide}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{ show: false }}
        backwardBtnProps={{ show: false }}
        dotsNav={{ show: false }}
        itemsToShow={10}
      >
        {getArrayOfArticleMotives().map((item) => (
          <BubbleTextAndLabel motive={item} />
        ))}
      </Carousel>
    </Box>
  );
};
