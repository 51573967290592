import { v4 as uuidv4 } from 'uuid';
import { BikeDropOff } from '../../model/utils/BikeDropOff';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppTheme, { IAppTheme } from '../../utils/AppTheme';
import { SxStyles } from '../../model/utils/SxStyles';
import Reservation from '../../model/Reservation';
import Image from '../../external/fox-typescript/core/Image';
import ReservationService from '../../services/ReservationService';
import Logger from '../../external/pdl-common/utils/Logger';
import { AppLayout } from '../core/AppLayout';
import AppHeader from './AppHeader';
import { ButtonBase, Container } from '@mui/material';
import { ReservationDetailsHeader } from '../../external/pdl-common/components/ReservationDetailsHeader';
import { BikeAttribute } from '../Bikes/BikeSize';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTypography } from '../Typography/AppTypography';
import { AppAssets } from '../../assets';
import { CheckboxWithInfo } from './CheckboxWithInfo';
import { UploadImage } from './UploadImage';
import PartDetailsBottomSheet from './PartDetailsBottomSheet';
import { Box } from '@mui/system';
import SimpleButton from './SimpleButton';
import NotificationModal from './NotificationModal';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from './RequestStatusFeedback';
import AlertService from '../../services/AlertService';
import { AppTextField } from './AppTextField';
import ConfirmReservationDropOffBottomSheet from '../Reservation/common/ConfirmReservationDropOffBottomSheet';
import TintUtils, { ClaimInitiator } from '../../utils/TintUtils';
import UserService from '../../services/UserService';
import { FullUser } from '../../external/pdl-common/model/FullUser';

interface Props {
  confirmationType: 'drop-off' | 'pick-up';
  onConfirm: (externalId: string, images: Image[], checks: BikeDropOff) => Promise<void>;
}

const DEFAULT_ITEMS: BikeDropOff = {
  makeAndModel: false,
  bikeCondition: false,
  damage: false,
  parts: false,
  userComments: '',
};

const dummyImage = () => {
  return {
    externalId: uuidv4(),
    url: '',
    width: 0,
    height: 0,
    type: '',
    description: '',
  };
};

const logger = new Logger('HostBikeDropOff');

const holdOnInsurance = 200;
const holdOnNoInsurance = 500;

const descriptionConfig = {
  'drop-off': {
    title: 'Drop-off',
    text: 'drop-off',
    textWithSpace: 'drop off',
    makeAndModelDescription:
    'Click on the checkbox if the bike is the same Make and Model as the one rented and picked up by renter.',
    conditionDescription: "Click on the checkbox if the bike’s condition matches the condition in which was picked up.",
    damageDescription: 'Click on the checkbox if the bicycle does not have new damages in relation to it’s condition at time of Pick-Up.',
    partsDescription: 'Click on the checkbox if the bike includes all the same parts and components included at time of Pick-Up.',
    notificationMessage: 'Bike drop-off in progress',
  },
  'pick-up': {
    title: 'Pick-up',
    text: 'pick-up',
    textWithSpace: 'pick up',
    makeAndModelDescription: 'The bike is the same make and model as described in the listing.',
    conditionDescription: "The bike's condition matches the description in the listing.",
    damageDescription: 'The bike does not have any undisclosed damage.',
    partsDescription: 'The bike includes all parts described in the listing.',
    notificationMessage: 'Pick-up in progress.',
  },
};

export function ReservationConfirmation(props: Props) {
  const classes = useStyles();
  const { externalId } = useParams();

  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [detailsItems, setDetailsItems] = useState<BikeDropOff>(DEFAULT_ITEMS);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showPartDetails, setShowPartDetails] = useState<boolean>(false);
  const [imagesBike, setImagesBike] = useState<Image[]>([dummyImage()]);
  const [imagesIdentity, setImagesIdentity] = useState<Image[]>([dummyImage()]);

  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [confirmationRequestStatus, setConfirmationRequestStatus] = useState(RequestStatus.SUCCESS);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [pdlUser, setPdlUser] = useState<FullUser | undefined>(undefined);

  useEffect(() => {
    getReservationDetail();
  }, []);

  const getReservationDetail = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);
      const reservationResponse = await ReservationService.getPublicReservation(externalId!);
      setReservation(reservationResponse.getContent());
      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error) {
      setRequestStatus(RequestStatus.ERROR);
      logger.error('Error fetching the reservation detail', error);
    }
  };

  const onFormClaim = async () => {
    if (!pdlUser) {
      setRequestStatus(RequestStatus.LOADING);

      (await UserService.getCurrentUser())
        .onSuccess((response) => {
          setRequestStatus(RequestStatus.SUCCESS);
          const user = response.getContent();

          setPdlUser(user);

          window.open(TintUtils.getClaimFormUrl(reservation!, user, ClaimInitiator.HOST), '_blank');
        })
        .onError((response) => {
          logger.error('Error fetching user', response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    } else {
      window.open(TintUtils.getClaimFormUrl(reservation!, pdlUser, ClaimInitiator.HOST), '_blank');
    }
  };

  const onBeforeHandleConfirm = () => {

    if (props.confirmationType === 'drop-off' && !checkAllCheckboxes()) {
      // If there is one checkbox that is not checked (and is a drop-off) inform the host what is gonna happen.
      setShowConfirmModal(true);
    } else {
      handleOnConfirm();
    }
  };

  const handleOnConfirm = async () => {
    try {
      setConfirmationRequestStatus(RequestStatus.LOADING);
      const imagesToSendBike = [...imagesBike];
      imagesToSendBike.pop();
      const imagesToSendIdentity = [...imagesIdentity];
      imagesToSendBike.pop();
      const imagesToSend = [...imagesBike, ...imagesIdentity];

      await props.onConfirm(reservation!.externalId!, imagesToSend, detailsItems);
      setShowSuccessModal(true);
      setConfirmationRequestStatus(RequestStatus.SUCCESS);

      if (props.confirmationType === 'drop-off' && !checkAllCheckboxes()) {
        // We redirect the user to complete the Tint claim form
        onFormClaim();
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'Error in reservation confirmation';

      logger.error('Error when executing the operation', errorMessage);
      AlertService.showSnackCustomError(errorMessage);

      setConfirmationRequestStatus(RequestStatus.SUCCESS);
    }
  };

  const handleSetImageBike = (image: Image) => {
    imagesBike.pop();
    setImagesBike([...imagesBike, image, dummyImage()]);
  };

  const handleSetImageIdentity = (image: Image) => {
    imagesIdentity.pop();
    setImagesIdentity([...imagesIdentity, image, dummyImage()]);
  };

  console.log(reservation?.renterInsurance);
  console.log(reservation?.protectionPlanPrice);

  const getHoldOnMessage = (): string => {
    return `A hold on in the amount of $${(reservation?.renterInsurance || reservation?.protectionPlanPrice) ? holdOnInsurance : holdOnNoInsurance
      } will be charged to your credit card and will be released within 24 hours after drop-off as long as no issues have been reported.`;
  };

  if (requestStatus == RequestStatus.LOADING || requestStatus == RequestStatus.ERROR) {
    return (
      <>
        <AppHeader content="" />
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={getReservationDetail} />
        </div>
      </>
    );
  }

  const checkAllCheckboxes = (): boolean => {
    const d = detailsItems;
    return d.bikeCondition && d.damage && d.makeAndModel && d.parts;
  };

  const pendingImages = () => {
    return imagesBike[0].url == '' ||
      (props.confirmationType === 'pick-up' && (imagesIdentity[0].url == '' || imagesIdentity[1].url == ''));
  }
  const { bike } = reservation!;

  return (
    <AppLayout
      header={() => (
        <AppHeader content={`Bike ${descriptionConfig[props.confirmationType].text}`} />
      )}
      content={() => (
        <>
          {/** Bike image with title and size */}
          <Container
            className={classes.container}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <ReservationDetailsHeader
              imageSrc={bike.images && bike.images.length > 0 ? bike.images[0].url : ''}
              title={bike.title}
            />

            <BikeAttribute label={'Size:'} value={bike?.size!} />
          </Container>

          {/** Checklist */}
          <Container
            className={classes.container}
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: AppTheme.colors.white_EEEEEC,
            }}
          >
            <AppTypography
              type={PDLTypography.largeHeading}
              children={`${descriptionConfig[props.confirmationType].title} checklist`}
              customStyles={{ marginBottom: '0.4em' }}
            />

            <AppTypography
              type={PDLTypography.paragraph}
              children={`Please confirm the following details to complete your ${descriptionConfig[props.confirmationType].text
                }.`}
            />

            <ButtonBase
              disableRipple
              sx={styles.listingButton}
              onClick={() => setShowPartDetails(true)}
            >
              <AppAssets.EyeIcon />

              <AppTypography
                type={PDLTypography.callToAction}
                children={'View Listing Details'}
                customStyles={{ color: AppTheme.colors.persianBlue_2238CB }}
              />
            </ButtonBase>

            <CheckboxWithInfo
              value={detailsItems.makeAndModel}
              checked={detailsItems.makeAndModel}
              onClick={() => {
                setDetailsItems({
                  ...detailsItems,
                  makeAndModel: Boolean(!detailsItems.makeAndModel),
                });
              }}
              label={'Make and model'}
              info={descriptionConfig[props.confirmationType].makeAndModelDescription}
            />

            <CheckboxWithInfo
              value={detailsItems.bikeCondition}
              checked={detailsItems.bikeCondition}
              onClick={() => {
                setDetailsItems({
                  ...detailsItems,
                  bikeCondition: Boolean(!detailsItems.bikeCondition),
                });
              }}
              label={'Condition'}
              info={descriptionConfig[props.confirmationType].conditionDescription}
            />

            <CheckboxWithInfo
              value={detailsItems.damage}
              checked={detailsItems.damage}
              onClick={() => {
                setDetailsItems({ ...detailsItems, damage: Boolean(!detailsItems.damage) });
              }}
              label={'Damage'}
              info={descriptionConfig[props.confirmationType].damageDescription}
            />

            <CheckboxWithInfo
              value={detailsItems.parts}
              checked={detailsItems.parts}
              onClick={() => {
                setDetailsItems({ ...detailsItems, parts: Boolean(!detailsItems.parts) });
              }}
              label={'Parts'}
              info={descriptionConfig[props.confirmationType].partsDescription}
            />
            {props.confirmationType === 'pick-up' && (
              <AppTypography
                type={PDLTypography.smallParagraph}
                children={
                  'Please take time to evaluate the bicycle for any damages or discrepancies that could pose a controversy at the time of drop off. In case of any issues that defer from the listing description, please enter these in the Additional Comments section below. '
                }
              />
            )}
          </Container>

          {/** User comments */}
          <Container
            className={classes.container}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <AppTypography
              type={PDLTypography.largeHeading}
              children={`Additional Comments ${checkAllCheckboxes() ? '' : '*'}`}
              customStyles={{ marginBottom: '0.4em' }}
            />

            <AppTextField
              name="userComments"
              value={detailsItems.userComments}
              onInputChange={(inputName, inputValue, isValid) => {
                setDetailsItems({ ...detailsItems, userComments: inputValue });
              }}
              required={!checkAllCheckboxes()}
              inputProps={{ multiline: true, rows: 3 }}
            />
          </Container>

          {/* Rental bike images */}
          <Container
            className={classes.container}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '2em',
            }}
          >
            <AppTypography type={PDLTypography.largeHeading} children={'Rental bike images *'} />
            <AppTypography
              type={PDLTypography.smallParagraph}
              children={`Upload images of the bike to confirm its condition at ${descriptionConfig[props.confirmationType].textWithSpace}.`}
              customStyles={{ marginTop: '1em', marginBottom: '1em' }}
            />
            {imagesBike.map((image, index) => (
              <UploadImage
                key={`${image.externalId}-${index}`}
                imageUrls={image.url ? [image.url] : undefined}
                handleSetImage={handleSetImageBike}
                image={image}
                defaultImages
                onRemove={(imageIdx) => {
                  const newImages = imagesBike.filter((img, idx) => imageIdx !== idx);
                  setImagesBike([...newImages]);
                }}
                imageIndex={index}
                maxImage={3}
              />
            ))}
          </Container>
          {/* Rental identity images */}
          {props.confirmationType === 'pick-up' && (<Container
            className={classes.container}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '2em',
            }}
          >
            <AppTypography type={PDLTypography.largeHeading} children={'Rental identity images *'} />
            <AppTypography
              type={PDLTypography.smallParagraph}
              children={`Upload 1 image of your identification and 1 of you holding it.`}
              customStyles={{ marginTop: '1em', marginBottom: '1em' }}
            />
            {imagesIdentity.map((image, index) => (
              <UploadImage
                key={`${image.externalId}-${index}`}
                imageUrls={image.url ? [image.url] : undefined}
                handleSetImage={handleSetImageIdentity}
                image={image}
                defaultImages
                onRemove={(imageIdx) => {
                  const newImages = imagesIdentity.filter((img, idx) => imageIdx !== idx);
                  setImagesIdentity([...newImages]);
                }}
                imageIndex={index}
                maxImage={2}
              />
            ))}

          </Container>
          )}
          <Container
            className={classes.container}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: '5em',
            }}
          >
            {props.confirmationType === 'pick-up' && (
              <Box sx={{ paddingTop: '1.5em' }}>
                <AppTypography type={PDLTypography.smallParagraph} children={getHoldOnMessage()} />
              </Box>
            )}
          </Container>
          <PartDetailsBottomSheet
            bike={reservation!.bike}
            open={showPartDetails}
            onClose={() => setShowPartDetails(!showPartDetails)}
          />

          <Box sx={styles.confirmDropContainer}>
            <Box sx={{ m: 'auto', width: '70%' }}>
              <SimpleButton
                onClick={onBeforeHandleConfirm}
                disabled={
                  (!checkAllCheckboxes() && !detailsItems.userComments) ||
                  pendingImages() ||
                  confirmationRequestStatus == RequestStatus.LOADING
                }
                isLoading={confirmationRequestStatus == RequestStatus.LOADING}
              >
                Confirm {descriptionConfig[props.confirmationType].title}
              </SimpleButton>
            </Box>
          </Box>

          <NotificationModal
            text={descriptionConfig[props.confirmationType].notificationMessage}
            open={showSuccessModal}
          />

          {props.confirmationType === 'drop-off' && (
            <ConfirmReservationDropOffBottomSheet
              open={showConfirmModal}
              onClose={() => setShowConfirmModal(false)}
              onConfirm={handleOnConfirm}
            />
          )}
        </>
      )}
    />
  );
}

const styles: SxStyles = {
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    py: '1.8em',
  },
  listingButton: {
    mt: '1em',
    mb: '1.5em',
    gap: 0.5,
    justifyContent: 'flex-start',
  },
  reportIssueButton: {
    mt: '1.5em',
    gap: 0.5,
    justifyContent: 'flex-start',
  },
  confirmDropContainer: {
    position: 'fixed',
    backgroundColor: AppTheme.colors.white_FFFFFF,
    bottom: 59,
    width: '100%',
    py: 1.2,
    borderTop: '1px solid #E3E5E4',
  },
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  bikeImage: {
    width: '100%',
    height: '100%',
    borderRadius: 30,
    marginBottom: '1.2em',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
}));
