import { useState, useEffect } from 'react';
import { AppTextField } from '../commons/AppTextField';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import GeolocationService from '../../services/GeolocationService';
import { CircularProgress, Grid, IconButton, Paper } from '@mui/material';
import { AppAssets } from '../../assets/index';
import { SxStyles } from '../../model/utils/SxStyles';
import { COLORS } from '../../utils/AppTheme';
import Logger from '../../external/pdl-common/utils/Logger';
import { Debouncer } from '../../utils/Debouncer';
import { RequestStatus } from '../../utils/RequestStatus';
import SearchResults from './SearchResults';
import AlertService from '../../services/AlertService';

interface Props {
  placeholder?: string;
  onClickLocation?: (location: RawResult) => void;
  showBorder?: boolean;
}

const logger = new Logger('GeoSearch');

export const GeoSearch = (props: Props) => {
  const [results, setResults] = useState<RawResult[]>([]);
  const [form, setForm] = useState({ location: '' });
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);

  const handleCustomChange = (inputName: string, inputValue: string) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);
  };

  useEffect(() => {
    Debouncer.debounce('searchLocation', searchLocation, 700);
  }, [form]);

  const searchLocation = async () => {
    if (form.location.length === 0) {
      return;
    }

    setRequestStatus(RequestStatus.LOADING);
    (await GeolocationService.getLocationsFromAddress(form.location))
      .onSuccess((response) => {
        setResults(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error searching location', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const onBeforeClickLocation = async (result: RawResult) => {
    if (!props.onClickLocation) {
      return;
    }

    if (!result.lat || !result.lon) {
      try {
        setRequestStatus(RequestStatus.LOADING);

        const response = await GeolocationService.getPlaceCoordinates(result.place_id);
        response.onError((res) => {
          AlertService.showSnackCustomError(res.getContent());
        });

        const location = response.getContent();

        result.lat = location.lat;
        result.lon = location.lon;

        setRequestStatus(RequestStatus.SUCCESS);
      } catch (error: any) {
        logger.error('Error getting place coordinates', error);
        setRequestStatus(RequestStatus.ERROR);
      }
    }

    props.onClickLocation(result);
  };

  return (
    <Paper
      component="form"
      sx={styles.searchContainer}
      style={props.showBorder ? { border: '1px solid #C9CBCA' } : undefined}
    >
      <IconButton aria-label="search" size="large" onClick={() => {}}>
        <AppAssets.SearchIcon />
      </IconButton>

      <Grid maxWidth={'md'} width={'100%'}>
        <Grid item sx={{ width: '90%' }}>
          <AppTextField
            name={'location'}
            value={form.location}
            onInputChange={(inputName: string, inputValue: string) =>
              handleCustomChange(inputName, inputValue)
            }
            inputProps={{
              placeholder: props.placeholder,
              inputProps: { style: { border: 'none' } },
            }}
          />
        </Grid>

        {results.length > 0 && (
          <SearchResults results={results} onClick={(result) => onBeforeClickLocation(result)} />
        )}
      </Grid>
      {requestStatus === RequestStatus.LOADING && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '0.5em',
            color: COLORS.persianBlue_2238CB,
          }}
        >
          <CircularProgress size={20} color="inherit" />
        </div>
      )}
    </Paper>
  );
};

const styles: SxStyles = {
  searchContainer: {
    height: 40,
    backgroundColor: '#FFFFFF',
    borderRadius: 200,
    boxShadow: 'none',
    display: 'flex',
  },
};
