import AsyncStorage from "@react-native-async-storage/async-storage";

//TODO we must establish a protocol and save and save parsed objects
class StorageService {
  /**
   * Store data in the storage
   * @param key : string
   * @param value : string
   */
  storeData = async (key: string, value: string) => {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (e) {
      console.log("Error in save data in local storage:", e);
    }
  };

  /**
   * Get data from the storage
   * @param key : string
   * @returns
   */
  getData = async (key: string) => {
    try {
      return await AsyncStorage.getItem(key);
    } catch (e) {
      console.log("Error in fetching data from local storage:", e);
      return null;
    }
  };

  /**
   * Remove data from the storage
   * @param key : stiing
   */
  removeData = async (key: string) => {
    try {
      await AsyncStorage.removeItem(key);
    } catch (e) {
      console.log("Error in removing data from local storage:", e);
    }
  };
}

export default new StorageService();
