import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import BottomSheet from './BottomSheet';

export function VerifyEmailAlertBottomSheet(props: {
  open: boolean;
  onClose: () => void;
  email: string;
  waitlist: boolean;
}) {
  const navigate = useNavigate();

  const handleOnClickClose = () => {
    appNavigate(navigate, AppRoutes.SPLASH_SCREEN, null);
  };
  return (
    <BottomSheet open={props.open} onClose={handleOnClickClose} bottomSpace={'5em'}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <AppTypography type={PDLTypography.largeHeading} customStyles={{ marginBottom: '1em' }}>
          Verify your email address
        </AppTypography>
        {props.waitlist ? (
          <AppTypography type={PDLTypography.paragraph} customStyles={{ marginBottom: '1em' }}>
            Please verify the email you provided when joining the waitlist to have full access to
            our community.
          </AppTypography>
        ) : (
          <>
            <AppTypography type={PDLTypography.paragraph} customStyles={{ marginBottom: '1em' }}>
              Please verify your email
            </AppTypography>
            <AppTypography type={PDLTypography.smallHeading} customStyles={{ marginBottom: '1em' }}>
              {props.email}
            </AppTypography>
            <AppTypography type={PDLTypography.paragraph}>
              to have full access to our community.
            </AppTypography>
          </>
        )}
      </div>
    </BottomSheet>
  );
}
