import { MonthlyBreakdown } from '../model/utils/MonthlyBreakdown';

export default class PaymentUtils {
  public static getTotalRevenue = (monthlyBreakdown?: MonthlyBreakdown[]): number => {
    if (monthlyBreakdown) {
      return monthlyBreakdown.reduce((previous, current) => previous + current.revenue, 0);
    }
    return 0;
  };

  public static getTotalRentals = (monthlyBreakdown?: MonthlyBreakdown[]): number => {
    if (monthlyBreakdown) {
      return monthlyBreakdown.reduce((previous, current) => previous + current.rentals, 0);
    }
    return 0;
  };
}
