import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';
import BottomSheet from '../../commons/BottomSheet';
import SimpleButton from '../../commons/SimpleButton';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmReservationDropOffBottomSheet = (props: Props) => {
  const classes = useStyles();

  return (
    <BottomSheet open={props.open} onClose={props.onClose} bottomSpace="5em">
      <div className={classes.container}>
        <AppTypography type={PDLTypography.largeHeading}>
          Are you sure you want to confirm Drop-off?
        </AppTypography>
        <AppTypography type={PDLTypography.paragraph} customClasses={classes.paragraph}>
          There are some unchecked items on the checklist. If you confirm Drop-off, we will report
          an issue.
        </AppTypography>

        <div className={classes.buttonContainer}>
          <SimpleButton
            onClick={() => {
              props.onClose();
              props.onConfirm();
            }}
          >
            Yes, confirm Drop-off
          </SimpleButton>
        </div>
      </div>
    </BottomSheet>
  );
};

export default ConfirmReservationDropOffBottomSheet;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paragraph: {
    marginTop: '1em',
    textAlign: 'justify',
  },
  buttonContainer: {
    margin: '0px auto',
    marginTop: '2em',
  },
}));
