import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { Bike } from '../../external/pdl-common/model/Bike';
import BikeService from '../../services/BikeService';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import AppTitle from '../commons/AppTitle';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import BikeItem from './BikeItem';

const logger = new Logger('BikeList');

export const BikeList = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [recentlyViewedBikes, setRecentlyViewedBikes] = useState<Bike[]>([]);
  const [bikeWishlist, setBikeWishlist] = useState<Bike[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);

  useEffect(() => {
    load();
    getBikeWishlist();
  }, []);

  const load = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeService.getRecentlyViewedBikesForHomePage())
      .onSuccess((response) => {
        setRecentlyViewedBikes(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error fetching recently viewed bikes', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getBikeWishlist = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeService.getWishListForHomePage())
      .onSuccess((response: Bike[]) => {
        setBikeWishlist(response);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to get bike wishlist ', response);
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const handleRecentlyView = (bike: Bike) => {
    const newArray = recentlyViewedBikes.map((b) => {
      if (b.externalId === bike.externalId) return bike;
      else return b;
    });
    setRecentlyViewedBikes(newArray);
  };

  const noResultMessage = (discriminator: string) => {
    return <p>You don't have {discriminator} bikes.</p>;
  };

  if (requestStatus === RequestStatus.ERROR || requestStatus === RequestStatus.LOADING) {
    return (
      <Box sx={{ my: 2 }}>
        <RequestStatusFeedBack status={requestStatus} onRetry={load} />
      </Box>
    );
  }

  // Render
  return (
    <Container maxWidth="lg">
      <>
        {/* Recently viewed */}
        <div className={classes.bikeListContainer}>
          <AppTitle content={'Recently viewed'} />
          {recentlyViewedBikes.length > 0
            ? recentlyViewedBikes.map((bike) => (
                <BikeItem
                  containerStyle={{ marginLeft: 0, marginRight: 0 }}
                  bike={bike}
                  bikeWishlist={bikeWishlist}
                  setWishlist={(bikes: Bike[]) => setBikeWishlist(bikes)}
                  key={`rv-bike-${bike.externalId}`}
                  imageStyle={{ cursor: 'pointer' }}
                  imgClassName={classes.bikeItem}
                  handleOnClickEvent={() =>
                    appNavigate(navigate, AppRoutes.BIKE_DETAIL, {
                      externalId: bike.externalId!,
                    })
                  }
                />
              ))
            : noResultMessage('recently viewed')}
        </div>

        {/* My wishlist */}
        <div className={classes.bikeListContainer}>
          <AppTitle content={'My wishlist'} />
          {bikeWishlist.length > 0
            ? bikeWishlist.map((bike) => (
                <BikeItem
                  bike={bike}
                  bikeWishlist={bikeWishlist}
                  setWishlist={(bikes: Bike[]) => setBikeWishlist(bikes)}
                  setRecentlyViewed={(bike: Bike) => handleRecentlyView(bike)}
                  key={`wishlist-bike-${bike.externalId}`}
                  imageStyle={{ cursor: 'pointer' }}
                  imgClassName={classes.bikeItem}
                  handleOnClickEvent={() =>
                    appNavigate(navigate, AppRoutes.BIKE_DETAIL, {
                      externalId: bike.externalId!,
                    })
                  }
                />
              ))
            : noResultMessage('wishlist')}
        </div>
      </>
    </Container>
  );
};

const useStyles = makeStyles({
  bikeListContainer: {
    marginTop: '1.875em',
    marginBottom: '1.875em',
  },
  bikeItem: {
    borderRadius: '0.3em',
    width: '100%',
    height: '6.5em',
    objectFit: 'cover',
  },
});
