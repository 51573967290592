import { useEffect, useState } from 'react';
import AppHeader from '../../commons/AppHeader';
import { AppLayout } from '../../core/AppLayout';
import { LocationForm } from '../../commons/LocationForm';
import { RequestStatus } from '../../../utils/RequestStatus';
import { PdlLocation } from '../../../model/PdlLocation';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import BikeService from '../../../services/BikeService';
import Logger from '../../../external/pdl-common/utils/Logger';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bike } from '../../../external/pdl-common/model/Bike';
import { Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppTheme, { IAppTheme } from '../../../utils/AppTheme';
import { Box } from '@mui/system';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import SimpleButton from '../../commons/SimpleButton';
import { SxStyles } from '../../../model/utils/SxStyles';
import AlertService from '../../../services/AlertService';
import { PartDetailForm } from '../../../model/parts/PartDetailForm';

const logger = new Logger('HostBikeConfirmLocation');

export function HostBikeConfirmLocation() {
  const state: {
    bikeLocation?: PdlLocation;
    bike?: Bike;
    partDetails?: PartDetailForm;
    payoutMethod?: any;
    draftMode?: boolean;
  } = (useLocation().state as any) || {};

  const navigate = useNavigate();
  const classes = useStyles();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);

  useEffect(() => {
    if (!state.bikeLocation) {
      appNavigate(navigate, AppRoutes.HOST_BIKE_FIRST_STEP, null);
    }
  }, []);

  const [form, setForm] = useState<LocationForm>({
    streetAddress: state.bikeLocation?.streetAddress || '',
    suite: state.bikeLocation?.streetAddressDetails || '',
    city: state.bikeLocation?.city || '',
    state: state.bikeLocation?.state || '',
    countryCode: state.bikeLocation?.countryCode || '',
    zipCode: state.bikeLocation?.zipCode || '',
  });

  const [validations, setValidations] = useState<{ [P in keyof LocationForm]: boolean }>({
    streetAddress: !!state.bikeLocation?.streetAddress,
    suite: !!state.bikeLocation?.streetAddressDetails,
    city: !!state.bikeLocation?.city,
    state: !!state.bikeLocation?.state,
    countryCode: !!state.bikeLocation?.countryCode,
    zipCode: !!state.bikeLocation?.zipCode,
  });

  const handleCustomChange = (inputName: string, inputValue: string, isValid: boolean) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);

    let updatedValidations = { ...validations };
    (updatedValidations as any)[inputName] = isValid;
    setValidations(updatedValidations);
  };

  const startCreationNewBike = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const location: PdlLocation = {
        latitude: state.bikeLocation!.latitude,
        longitude: state.bikeLocation!.longitude,
        geocode: state.bikeLocation!.geocode,
        streetAddress: form.streetAddress,
        streetAddressDetails: form.suite,
        city: form.city,
        state: form.state,
        countryCode: form.countryCode,
        zipCode: form.zipCode,
        timezoneId: state.bikeLocation?.timezoneId
      };

      if (state.bike) {
        // We set the new location
        state.bike.location = location;

        const response = await BikeService.updateDraftLocation(state.bike);
        setRequestStatus(RequestStatus.SUCCESS);

        appNavigate(navigate, AppRoutes.HOST_BIKE_SECOND_STEP, null, {
          state: {
            bike: response.getContent(),
            partDetails: state.partDetails,
            payoutMethod: state.payoutMethod,
            draftMode: state.draftMode,
          },
        });
        return;
      }

      const response = await BikeService.startCreationBike(location);
      setRequestStatus(RequestStatus.SUCCESS);

      appNavigate(navigate, AppRoutes.HOST_BIKE_SECOND_STEP, null, {
        state: { bike: response.getContent() },
      });
    } catch (error: any) {
      logger.error('Error when creating the bike', error);
      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackCustomError('Error when creating the bike');
      }
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  return (
    <AppLayout
      header={() => <AppHeader content="Confirm location" />}
      content={() => {
        return (
          <>
            <Container className={classes.container}>
              <LocationForm form={form} onInputChange={handleCustomChange} />
            </Container>
            <Box sx={styles.stepsBox}>
              <Grid container maxWidth={'md'} sx={styles.stepsContainer}>
                <Grid item xs={7} xl={7}>
                  <AppTypography type={PDLTypography.subHeadingSerif} children={'Step 1 of 3'} />
                </Grid>
                <Grid item xs={5} xl={5}>
                  <SimpleButton
                    isLoading={requestStatus === RequestStatus.LOADING}
                    disabled={
                      requestStatus === RequestStatus.LOADING ||
                      !(
                        validations.city &&
                        validations.state &&
                        validations.streetAddress &&
                        validations.zipCode
                      )
                    }
                    onClick={startCreationNewBike}
                  >
                    Next
                  </SimpleButton>
                </Grid>
              </Grid>
            </Box>
          </>
        );
      }}
    />
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
    },
    display: 'flex',
    flexDirection: 'column',
  },
}));

const styles: SxStyles = {
  stepsBox: {
    position: 'fixed',
    bottom: 59,
    zIndex: 999,
    width: '100%',
    backgroundColor: AppTheme.colors.white_FFFFFF,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: AppTheme.colors.gray_E3E5E4,
  },
  stepsContainer: {
    m: '0 auto',
    alignItems: 'center',
    justifyContent: 'space-around',
    px: 5,
    py: 1,
  },
};
