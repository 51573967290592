import { AxiosResponse } from "axios";
import NotificationService from "../services/NotificationService";
import ApiError from "./ApiError";
import { ApiResultDTO } from "./ApiResultDTO";

export default class ApiResponseV2<T> {
    /**
     * HTTP status code of the request
     */
    private httpStatusCode: number | null;
    /**
     * true if request succeeded, false otherwise
     */
    private success: boolean;
    /**
     * Content of the response (if successful) or more detail about failure (if error)
     */
    private content: T;
    /**
     * Code (enum-like) identifying the error code
     */
    private errorCode: string;
    /**
     * Message identifying the error code. "Operation successful" otherwise
     */
    private message: string;

    private constructor(
        httpStatusCode: number | null,
        success: boolean,
        content: T,
        errorCode: string,
        message: string
    ) {
        this.httpStatusCode = httpStatusCode;
        this.success = success;
        this.content = content;
        this.errorCode = errorCode;
        this.message = message;
    }

    /**
     * Creates and instance from an axios response from backend
     */
    public static fromAxiosResponse<T>(
        response: AxiosResponse<ApiResultDTO<T>>
    ) {
        return new ApiResponseV2<T>(
            response.status,
            response.data.success,
            response.data.content,
            response.data.errorCode || "",
            response.data.message
        );
    }

    /**
     * Creates a successfull response for a particular
     */
    public static ok<T>(content: T) {
        return new ApiResponseV2<T>(200, true, content, "", "OK");
    }

    /**
     * Used to create an instance for the cases when a request was made but no response was received
     */
    public static noResponseError<T>() {
        return new ApiResponseV2<T>(
            500,
            false,
            null as any,
            "NO_RESPONSE_RECEIVED",
            "An internal error occur, please try again later"
        );
    }

    /**
     * Used to create an instance for the cases when an error occurs setting up the request
     */
    public static requestError<T>() {
        return new ApiResponseV2<T>(
            null,
            false,
            null as any,
            "REQUEST_ERROR",
            "An internal error occur, please try again later"
        );
    }

    /**
     * Executes the callback if response is success
     * @param callback to execute
     */
    public onSuccess(
        callback: (content: T, response: ApiResponseV2<T>) => void
    ) {
        if (this.success) {
            callback(this.content, this);
        }

        return this;
    }

    /**
     * Executes the callback if response is not success
     * @param callback to execute
     */
    public onError(
        callback: (errorMessage: string, response: ApiResponseV2<T>) => void
    ) {
        if (!this.success) {
            callback(this.message, this);
        }

        return this;
    }

    /**
     * Returns the content of the response if successful, otherwise throws an error
     * @returns The content of the responses
     * @throws Error if the response is not success
     */
    public getContentOrThrowError(): T {
        if (this.success) {
            return this.content;
        } else {
            throw new ApiError(this.errorCode, this.message, this);
        }
    }

    /**
     * Returns the content of the response if successful, otherwise run callback and returns T.
     * @param callback to execute if the response is not successful
     * @returns The content of the responses or T
     */
    public getContentOrElse(callback: () => T): T {
        if (this.success) {
            return this.content;
        } else {
            return callback();
        }
    }

    /**
     * Executes the callback if response is success otherwise notifies the error message
     * @param callback to execute if the response is successful
     */
    public getContentOrNotifyError(callback: (content: T) => void) {
        if (this.success) {
            callback(this.content);
        } else {
            this.notifyErrorIfPresent();
        }
    }

    /**
     * **Warning:** We recomend to use `getContentOrThrowError`, `getContentOrNotifyError` or `onSucess().onError()` instead.
     *
     * Returns the content of the response if successful, otherwise returns `null`
     */
    public getContentUnchecked(): T | null {
        if (this.success) {
            return this.content;
        } else {
            return null;
        }
    }

    public getHttpStatusCode() {
        return this.httpStatusCode;
    }

    public isSuccess() {
        return this.success;
    }

    public getErrorCode() {
        return this.errorCode;
    }

    public getMessage() {
        return this.message;
    }

    /**
     * Calls the `NotificationService` if the response is not successful
     */
    public notifyErrorIfPresent() {
        if (!this.success) {
            NotificationService.error(this.message);
        }
    }
}
