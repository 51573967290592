import { makeStyles } from '@mui/styles';
import BottomSheet from '../commons/BottomSheet';
import SimpleButton from '../commons/SimpleButton';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

interface Props {
  open: boolean;
  onClose: () => void;
  onClick?: () => void;
}

const LogOutBottomSheet = ({ open, onClose, onClick }: Props) => {
  const classes = useStyles();

  return (
    <BottomSheet open={open} onClose={onClose} bottomSpace="5em">
      <div className={classes.innerContainer}>
        <AppTypography type={PDLTypography.largeHeading} children={'Log out'} />
        <AppTypography
          type={PDLTypography.paragraph}
          children={'Are you sure you want to log out?'}
        />
        <SimpleButton onClick={onClick}>Yes, Log Out</SimpleButton>
      </div>
    </BottomSheet>
  );
};

export default LogOutBottomSheet;

const useStyles = makeStyles({
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '12.5em',
  },
});
