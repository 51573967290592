import { ButtonBase, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenterReview } from '../../model/RenterReview';
import BikeRatingService from '../../services/ReviewService';
import AppTheme from '../../utils/AppTheme';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import { GenericCard } from '../core/GenericCard';
import { AppFontFamily } from '../../utils/AppFontFamily';
import TotalRides from '../commons/TotalRides';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { RequestStatus } from '../../utils/RequestStatus';
import Logger from '../../external/pdl-common/utils/Logger';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import BikeRatingComment from '../../external/pdl-common/components/commons/Ratings/BikeRatingComment';

interface Props {
  externalId: string;
  totalRides: number;
  avgStars: number;
}

const logger = new Logger('BikeRatingCarousel');

const BikeRatingCarousel = ({ externalId, totalRides, avgStars }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [ratings, setRatings] = useState<RenterReview[] | undefined>();
  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);

  useEffect(() => {
    getRatings();
  }, []);

  const getRatings = async () => {
    setRequestStatus(RequestStatus.LOADING);
    (await BikeRatingService.getRenterReviewsForBike(externalId, 0, 5))
      .onSuccess((response) => {
        setRatings(response.getContent().content);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        setRequestStatus(RequestStatus.ERROR);
        logger.error('Error trying to get renter reviews for bike', response.getContent());
      });
  };

  if (requestStatus === RequestStatus.LOADING || requestStatus === RequestStatus.ERROR) {
    return (
      <div style={{ marginBottom: '1em' }}>
        <RequestStatusFeedBack status={requestStatus} onRetry={getRatings} />
      </div>
    );
  }

  if (ratings!.length === 0) {
    return (
      <div className={classes.noReviewsText}>
        <p>No reviews yet.</p>
        <p>Rent this bike and be the first to leave a review!</p>
      </div>
    );
  }

  return (
    <div style={{ marginBottom: '2.5em' }}>
      <>
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ fontSize: '0.8em' }}
        >
          <TotalRides avgStars={avgStars} totalRides={totalRides} />
          <ButtonBase
            onClick={() =>
              appNavigate(navigate, AppRoutes.BIKE_RATINGS, { externalId: externalId! })
            }
          >
            <p style={{ textDecoration: 'underline' }}>View all</p>
          </ButtonBase>
        </Grid>

        <Carousel
          itemsToShow={3}
          slidesToShow={{ sm: 1.2 }}
          data={ratings!}
          renderItem={(rating) => (
            <GenericCard
              onClick={() => { }}
              className={classes.container}
              sx={{ borderRadius: '2em', overflowY: 'auto' }}
              elevation={0}
              hideImage
            >
              <BikeRatingComment rating={rating} showDivider={false} showImage={true} />
            </GenericCard>
          )}
        />
      </>
    </div>
  );
};

export default BikeRatingCarousel;

const useStyles = makeStyles({
  container: {
    maxWidth: '15em',
    minHeight: '12em',
    border: `0.063em solid ${AppTheme.colors.gray_01000E1A}`,
    paddingTop: '0.5em',
    paddingBottom: '1em',
    boxShadow: `0.125em 0.125em 0.625em 0em ${AppTheme.colors.gray_01000E1A} !important`,
  },
  noReviewsText: {
    fontSize: '1em',
    fontFamily: AppFontFamily.INTER,
    color: AppTheme.colors.black_01000E,
  },
});
