import { AppLayout } from '../core/AppLayout';
import AppHeader from '../commons/AppHeader';
import { Grid, ButtonBase, Box, useMediaQuery, useTheme } from '@mui/material';
import { AppAssets } from '../../assets';
import { AppSticky } from '../commons/AppSticky';
import BikeService from '../../services/BikeService';
import { useState, useEffect, ReactElement } from 'react';
import { Bike } from '../../external/pdl-common/model/Bike';
import { FluidContainer } from '../commons/FluidContainer';
import AppTheme from '../../utils/AppTheme';
import { SearchList } from '../Search/SearchList';
import { SxStyles } from '../../model/utils/SxStyles';
import { SearchType } from '../../types/SearchType';
import { useLocation, useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { MapWrapper } from '../Map/MapWrapper';
import { MapMarker } from '../Map/Markers/MapMarker';
import BikeItem from '../Bikes/BikeItem';
import { FilterComponent } from '../Filters/FilterComponent';
import { BikeFilters } from '../../model/utils/BikeFilters';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import Location from '../../external/fox-typescript/core/Location';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import formOptionsService from '../../services/FormOptionsService';
import { Debouncer } from '../../utils/Debouncer';
import AlertService from '../../services/AlertService';
import FormUtils from '../../utils/FormUtils';
import { makeStyles } from '@mui/styles';
import HomeIcon from '@mui/icons-material/Home';
import { MapCoordinates } from '../../model/utils/MapCoordinates';
import { DEFAULT_COORDINATES, toMapCoordinates } from '../../utils/MapUtils';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import Navbar from '../core/Navbar';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import BikeUtils from '../../external/pdl-common/utils/BikeUtils';

const logger = new Logger('Search');
const PAGE_SIZE: number = 50;

const DEFAULT_SELECTED_FILTERS: BikeFilters = {
  bikeSizes: [],
  bikeTypes: [],
  hideUnavailable: false,
};

export default function Search() {
  const navigate = useNavigate();
  const classes = useStyles();

  const nearCoordinates = 0.00000001;
  const state: {
    searchLocation?: Location;
    startDate?: Date;
    endDate?: Date;
    searchFilters?: BikeFilters;
  } = (useLocation().state as any) || {};

  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentBike, setCurrentBike] = useState<Bike | null>(null);
  const [searchType, setSearchType] = useState<SearchType>(SearchType.LIST);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [bikesAvailable, setBikesAvailable] = useState<Bike[]>([]);
  const [filtersToShow, setFiltersToShow] = useState<BikeFilters | undefined>();
  const [selectedFilters, setSelectedFilters] = useState<BikeFilters>(
    state?.searchFilters ? state.searchFilters : DEFAULT_SELECTED_FILTERS
  );
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [pageData, setPageData] = useState<{
    number: number;
    totalPages: number;
    totalElements: number;
  }>({
    number: 0,
    totalPages: 0,
    totalElements: 0,
  });
  const [selectedBikeList, setSelectedBikeList] = useState<Bike[]>([]);
  const [activeMultiBike, setActiveMultiBike] = useState<boolean>(false);

  useEffect(() => {
    const clearFilters = () => {
      if (
        selectedFilters.bikeSizes.length === 0 &&
        selectedFilters.bikeTypes.length === 0 &&
        selectedFilters.hideUnavailable === false
      ) {
        setSelectedFilters(DEFAULT_SELECTED_FILTERS);
      }
    };
    clearFilters();
  }, [selectedFilters]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await formOptionsService.getFormOptions())
      .onSuccess(async (response) => {
        setFiltersToShow(FormUtils.convertToBikeFilters(response.getContent()));

        await getBikes();
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Failed to load', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getBikes = async () => {
    setBikesAvailable([]);
    setRequestStatus(RequestStatus.LOADING);

    const response = await BikeService.getBikesAvailableToReserve(
      0,
      PAGE_SIZE,
      state.searchLocation!,
      state?.startDate!,
      state?.endDate!,
      selectedFilters
    );
    response
      .onSuccess((response) => {
        const content = response.getContent();
        setBikesAvailable(content.content);
        setPageData({
          number: content.number,
          totalPages: content.totalPages,
          totalElements: content.totalElements,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const loadMoreBikes = async () => {
    Debouncer.debounce(
      'loadMoreBikes',
      async () => {
        setRequestStatus(RequestStatus.LOADING);
        const response = await BikeService.getBikesAvailableToReserve(
          pageData.number + 1,
          PAGE_SIZE,
          state.searchLocation!,
          state.startDate!,
          state.endDate!,
          selectedFilters
        );
        response
          .onSuccess((response) => {
            setBikesAvailable([...bikesAvailable, ...response.getContent().content]);
            setPageData({ ...pageData, number: pageData.number + 1 });
            setRequestStatus(RequestStatus.SUCCESS);
          })
          .onError((response) => {
            logger.error('Error trying to load more Bikes', response.getContent());
            AlertService.showSnackCustomError(response.getContent());
            setRequestStatus(RequestStatus.ERROR);
          });
      },
      100
    );
  };

  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick}>
        <ArrowCircleRightIcon
          style={{
            ...style,
            display: 'block',
            position: 'absolute',
            fill: AppTheme.colors.persianBlue_2238CB,
          }}
          fontSize={'large'}
          className={className}
        />
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick}>
        <ArrowCircleLeftIcon
          style={{
            ...style,
            display: 'block',
            position: 'absolute',
            fill: AppTheme.colors.persianBlue_2238CB,
          }}
          fontSize={'large'}
          className={className}
        />
      </div>
    );
  };

  const getMapCoordinates = (): MapCoordinates => {
    // We get the first available bike
    const firstAvailableBike = bikesAvailable.find(Boolean);

    if (currentBike) {
      // We set the current bike location as default
      return toMapCoordinates(currentBike.location);
    } else if (firstAvailableBike) {
      // We set the first available bike location as default
      return toMapCoordinates(firstAvailableBike.location);
    } else if (state.searchLocation) {
      // We set the state location as default
      return toMapCoordinates(state.searchLocation);
    }

    return DEFAULT_COORDINATES;
  };

  const selectBikeFromList = (bikeSameLocation: Bike[]) => {
    setSelectedBikeList(bikeSameLocation);
    setCurrentBike(bikeSameLocation[0]);
    setActiveMultiBike(bikeSameLocation.length > 1);
  };

  const nearBikes = (bike1: Bike, bike2: Bike): boolean => {
    if (!bike1 || !bike2) {
      return false;
    }
    return (
      Math.abs(bike1.location.latitude - bike2.location.latitude) < nearCoordinates &&
      Math.abs(bike1.location.longitude - bike2.location.longitude) < nearCoordinates
    );
  };

  return (
    <AppLayout
      noNavBar
      header={() => (
        <AppHeader
          onBack={() =>
            appNavigate(navigate, AppRoutes.EXPLORE_CALENDAR, null, {
              state: {
                location: state.searchLocation,
                startDate: state.startDate,
                endDate: state.endDate,
              },
            })
          }
          content={`${pageData.totalElements} bikes available`}
          bottomElement={
            <Grid
              container
              sx={styles.bottomElementContainer}
              columns={8}
              justifyContent="space-between"
            >
              <Grid item xs={7} display={'inline-flex'} alignItems={'center'}>
                <AppTypography
                  type={PDLTypography.subHeadingSerif}
                  customStyles={{
                    color: AppTheme.colors.white_FFFFFF,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '45vw',
                  }}
                >
                  {state.searchLocation?.geocode || ''}
                </AppTypography>

                <AppTypography
                  type={PDLTypography.none}
                  children={' | '}
                  customStyles={{ color: AppTheme.colors.white_FFFFFF }}
                />

                <AppTypography
                  type={PDLTypography.subHeadingSerif}
                  customStyles={{ color: AppTheme.colors.white_FFFFFF, minWidth: '10em' }}
                >
                  {state.startDate && state.endDate
                    ? DateUtils.formattedRangeDates(state.startDate, state.endDate)
                    : ''}
                </AppTypography>
              </Grid>

              <Grid
                item
                xs="auto"
                sm={1}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}
              >
                <ButtonBase onClick={() => setShowFilters(!showFilters)}>
                  <AppAssets.FilterIcon
                    style={{
                      color:
                        selectedFilters === DEFAULT_SELECTED_FILTERS
                          ? AppTheme.colors.white_FFFFFF
                          : AppTheme.colors.persianBlue_2238CB,
                    }}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
          }
          rightButton={
            <HomeIcon
              onClick={() => {
                appNavigate(navigate, AppRoutes.EXPLORE, null);
              }}
              sx={{ marginRight: '0.9em' }}
            />
          }
        />
      )}
      content={() => (
        <FluidContainer
          style={{
            backgroundColor: AppTheme.colors.white_EEEEEC,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: searchType === SearchType.LIST ? '100vh' : 'auto',
          }}
        >
          <AppSticky>
            <Grid container justifyContent={'center'} height={'100%'}>
              <Grid
                item
                xs={5}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                <ButtonBase
                  centerRipple
                  onClick={() => setSearchType(SearchType.MAP)}
                  sx={{ width: '100%' }}
                >
                  {searchType === SearchType.MAP ? (
                    <AppAssets.MapActiveIcon />
                  ) : (
                    <AppAssets.MapIcon height={17} />
                  )}
                  <AppTypography
                    type={PDLTypography.subHeading}
                    children={'Map'}
                    customStyles={{ marginLeft: 10 }}
                  />
                </ButtonBase>
              </Grid>

              <Grid item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <AppTypography
                  type={PDLTypography.none}
                  children={'|'}
                  customStyles={{ color: '#E3E5E4' }}
                />
              </Grid>

              <Grid
                item
                xs={5}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                <ButtonBase
                  centerRipple
                  onClick={() => setSearchType(SearchType.LIST)}
                  sx={{ width: '100%' }}
                >
                  {searchType === SearchType.LIST ? (
                    <AppAssets.ListActiveIcon />
                  ) : (
                    <AppAssets.ListIcon />
                  )}
                  <AppTypography
                    type={PDLTypography.subHeading}
                    children={'List'}
                    customStyles={{ marginLeft: 10 }}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
          </AppSticky>

          {searchType === SearchType.LIST ? (
            <SearchList
              filters={selectedFilters}
              searchLocation={state.searchLocation}
              bikes={bikesAvailable}
              resultsContainer={styles.resultsContainer}
              startDate={state.startDate!}
              endDate={state.endDate!}
              loadMoreBikes={loadMoreBikes}
              hasMorePages={pageData.number + 1 < pageData.totalPages}
              status={requestStatus}
            />
          ) : (
            <Box mt={-7} style={{ flex: 1, display: 'flex' }}>
              <MapWrapper onClickMap={() => setCurrentBike(null)} coordinates={getMapCoordinates()}>
                {bikesAvailable.map((bike, index) => {
                  const { location } = bike;
                  const bikeSameLocation: Bike[] = bikesAvailable.filter((bikeSameLoc) =>
                    nearBikes(bikeSameLoc, bike)
                  );
                  // If the bike has latitude and longitude we draw it on the map
                  if (location.latitude && location.longitude) {
                    return (
                      <MapMarker
                        key={index}
                        lat={location.latitude}
                        lng={location.longitude}
                        value={`$${BikeUtils.dailyPrice(bike, state.startDate!, state.endDate!)}`}
                        isActive={
                          currentBike?.externalId === bike.externalId ||
                          (activeMultiBike && nearBikes(bike, currentBike!))
                        }
                        draggable={false}
                        isAvailable={bike.available}
                        type={'IconText'}
                        multiPoint={
                          bikeSameLocation.length > 1 ? '*' + bikeSameLocation.length : undefined
                        }
                        onClick={() => selectBikeFromList(bikeSameLocation)}
                        hasDiscount={BikeUtils.dailyPrice(bike, state.startDate!, state.endDate!) !== bike.customPrice}
                      />
                    );
                  }

                  return <></>;
                })}
              </MapWrapper>
              {currentBike && (
                <Box sx={{ ...styles.bikePopUp, left: breakpoint ? 0 : 40 }}>
                  <Carousel
                    showArrows={breakpoint && selectedBikeList.length > 1 ? false : true}
                    prevArrow={<PrevArrow />}
                    nextArrow={<NextArrow />}
                    showIndexIndicator={selectedBikeList.length > 1}
                    infinite={true}
                    itemsToShow={1}
                    slidesToShow={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                    noOpenGallery
                    data={selectedBikeList}
                    renderItem={(bike, index) => (
                      <BikeItem
                        label={bike.available ? '' : 'Reserved - Check other dates'}
                        bike={bike}
                        startDate={state.startDate}
                        endDate={state.endDate}
                        totalPrice={
                          DateUtils.getFullDaysBetweenDates(state.startDate!, state.endDate!) *
                          BikeUtils.dailyPrice(bike, state.startDate!, state.endDate!)
                        }
                        handleOnClickEvent={() =>
                          appNavigate(
                            navigate,
                            AppRoutes.BIKE_DETAIL,
                            {
                              externalId: bike.externalId!,
                            },
                            bike.available
                              ? {
                                state: {
                                  startDate: state.startDate,
                                  endDate: state.endDate,
                                },
                              }
                              : {}
                          )
                        }
                        withSize
                        withRating
                        withFinalPrice
                        withLocation={false}
                        containerStyle={{
                          backgroundColor: AppTheme.colors.white_FFFFFF,
                          borderRadius: 30,
                          width: '96%',
                          cursor: 'pointer',
                          minHeight: '10.5em',
                        }}
                        imageStyle={{
                          borderRadius: '1.875em 0 0 1.875em',
                          height: '100%',
                          objectFit: 'cover',
                          opacity: bike.available ? '1' : '0.5',
                        }}
                        imgClassName={classes.bikeItem}
                        iconsClassName={classes.bikeItem}
                      />
                    )}
                  />
                </Box>
              )}
            </Box>
          )}
          <Navbar />

          <FilterComponent
            show={showFilters}
            selectedFilters={selectedFilters}
            filtersToShow={filtersToShow}
            setShow={(show) => {
              if (show === false) {
                getBikes();
              }
              setShowFilters(show);
            }}
            onFilterChange={setSelectedFilters}
            onClearFilters={() => setSelectedFilters(DEFAULT_SELECTED_FILTERS)}
          />
        </FluidContainer>
      )}
    />
  );
}

const styles: SxStyles = {
  bottomElementContainer: {
    px: 3.5,
    mt: 2,
    mb: 4,
  },
  resultsContainer: {
    pt: 1,
  },
  bikePopUp: {
    position: 'fixed',
    bottom: 49,
    width: '100%',
    maxWidth: '25em',
    justifyContent: 'center',
    zIndex: 999,
  },
};

const useStyles = makeStyles({
  bikeItem: {
    borderRadius: '1.875em 0 0 0',
    width: '100%',
  },
});
