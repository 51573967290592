import { NavigateFunction, NavigateOptions } from 'react-router-dom';

export enum AppRoutes {
  MAIN = '/',
  EXPLORE = '/explore',
  HOST = '/host',
  HOST_BIKE_LISTINGS = '/host/listings',
  HOST_ACTIVATE_BIKE = '/host/listings/:externalId/activate',
  HOST_BIKE_EDIT = '/host/listings/:externalId/edit',
  HOST_BIKE_EDIT_IMAGES = '/host/listings/:externalId/edit/images',
  HOST_BIKE_EDIT_DETAILS = '/host/listings/:externalId/edit/details',
  HOST_BIKE_EDIT_PARTS = '/host/listings/:externalId/edit/parts',
  HOST_BIKE_EDIT_BLOCKOUT_DATES = '/host/listings/:externalId/edit/blockout-dates',
  HOST_BIKE_EDIT_LOCATION = '/host/listings/:externalId/edit/location',
  HOST_BIKE_EDIT_TIERED_PRICE = '/host/listings/:externalId/edit/tiered-price',
  RESERVATION_DROP_OFF = '/reservation/:externalId/drop-off',
  RESERVATION_DROP_OFF_CONFIRMATION = '/reservation/:reservationExternalId/drop-off/:dropOffExternalId',
  RESERVATION_PICK_UP_CONFIRMATION = '/reservation/:reservationExternalId/pick-up/:dropOffExternalId',
  INBOX = '/inbox',
  ACCOUNT = '/account',
  TRIPS = '/trips',
  RESERVATION_PICK_UP = '/reservation/:externalId/pick-up',
  SPLASH_SCREEN = '/welcome',
  SIGN_IN = '/sign-in',
  SIGN_IN_AS_OTHER = '/sign-in-as',
  INVITE_CODE = '/invite-code',
  CREATE_PROFILE = '/create-profile',
  WAIT_LIST_CREATE_PROFILE = '/waitlist/:externalId/create-profile',
  SEARCH = '/search',
  EXPLORE_SEARCH = '/explore/search',
  EXPLORE_CALENDAR = '/explore/calendar',
  BIKE_DETAIL = '/bike-detail/:externalId',
  BIKE_RATINGS = '/bike-ratings/:externalId',
  TRIPS_LEAVE_REVIEW = '/trips/leave-review/:externalId',
  CHAT = '/chat/:externalId',
  ACCOUNT_RATINGS = '/account-ratings',
  CONFIRM_AND_PAY = '/confirm-and-pay',
  MY_MEASUREMENTS = '/my-measurements',
  PERSONAL_INFORMATION = '/personal-information',
  HELP_CENTER = '/help-center',
  HELP_CENTER_DETAIL = '/help-center/:id',
  PAYMENT_DETAILS = '/payment-details',
  INVITE_FRIENDS = '/invite-friends',
  EARNINGS = '/earnings',
  EARNING_DETAILS = '/earnings/:externalId',
  PAYOUT_DETAILS = '/payout-details',
  HOST_BIKE_FIRST_STEP = '/list-bike/location',
  HOST_BIKE_CONFIRM_LOCATION = '/list-bike/confirm-location',
  HOST_BIKE_SECOND_STEP = '/list-bike/bike-details',
  HOST_BIKE_THIRD_STEP = '/list-bike/part-details',
  INSURANCE = '/insurance',
  PAYOUT_METHOD = '/payout-method',
  HOST_RATINGS = '/host-ratings',
  HOST_RESERVATION_HISTORY = '/reservation-history',
  CONTENT_LIBRARY = '/library',
  CONTENT_LIBRARY_DETAILS = '/library/:externalId',
  TRIPS_RESERVATION_DETAIL = '/trips/reservation/:externalId',
  HOST_RESERVATION_DETAIL = '/host/reservation/:externalId',
  HOST_RESERVATION_LEAVE_REVIEW = '/host/reservation/leave-review/:externalId',
  RECEIPT_DETAIL = '/receipt/:url',
  REPORT_ISSUE = '/report-issue',
  DISABLE_PICKUP_ISSUE = '/disable-pickup-issue',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',

  // Landing page
  HOME = '/',

  // Waitlist
  WAITLIST_JOIN = '/waitlist/join',
  WAITLIST_HOME = '/waitlist',

  HOST_RESERVATION_CANCEL = '/host/reservation/:externalId/cancel',
  VALIDATE_FORGOT_PASSWORD = '/validate-forgot-password',
  VALIDATE_ACCOUNT = '/validate-account',
  ABOUT = '/about',

  EDIT_AVATAR = '/account/edit-avatar',
  COMPLETE_ADDITIONAL_INFORMATION = '/additional-information',
}

type RouteParams = {
  [AppRoutes.MAIN]: null;
  [AppRoutes.EXPLORE]: null;
  [AppRoutes.HOST]: null;
  [AppRoutes.HOST_ACTIVATE_BIKE]: { externalId: string };
  [AppRoutes.HOST_BIKE_LISTINGS]: null;
  [AppRoutes.HOST_BIKE_EDIT]: { externalId: string };
  [AppRoutes.HOST_BIKE_EDIT_IMAGES]: { externalId: string };
  [AppRoutes.HOST_BIKE_EDIT_DETAILS]: { externalId: string };
  [AppRoutes.HOST_BIKE_EDIT_PARTS]: { externalId: string };
  [AppRoutes.HOST_BIKE_EDIT_BLOCKOUT_DATES]: { externalId: string };
  [AppRoutes.HOST_BIKE_EDIT_LOCATION]: { externalId: string };
  [AppRoutes.HOST_BIKE_EDIT_TIERED_PRICE]: { externalId: string };
  [AppRoutes.RESERVATION_DROP_OFF]: { externalId: string };
  [AppRoutes.RESERVATION_DROP_OFF_CONFIRMATION]: { reservationExternalId: string; dropOffExternalId: string };
  [AppRoutes.RESERVATION_PICK_UP_CONFIRMATION]: { reservationExternalId: string; dropOffExternalId: string };
  [AppRoutes.INBOX]: null;
  [AppRoutes.ACCOUNT]: null;
  [AppRoutes.TRIPS]: null;
  [AppRoutes.RESERVATION_PICK_UP]: { externalId: string };
  [AppRoutes.SPLASH_SCREEN]: null;
  [AppRoutes.SIGN_IN]: null;
  [AppRoutes.SIGN_IN_AS_OTHER]: null;
  [AppRoutes.INVITE_CODE]: null;
  [AppRoutes.CREATE_PROFILE]: null;
  [AppRoutes.WAIT_LIST_CREATE_PROFILE]: { externalId: string };
  [AppRoutes.SEARCH]: null;
  [AppRoutes.EXPLORE_SEARCH]: null;
  [AppRoutes.EXPLORE_CALENDAR]: null;
  [AppRoutes.BIKE_DETAIL]: { externalId: string };
  [AppRoutes.BIKE_RATINGS]: { externalId: string };
  [AppRoutes.TRIPS_LEAVE_REVIEW]: { externalId: string };
  [AppRoutes.HOST_RESERVATION_LEAVE_REVIEW]: { externalId: string };
  [AppRoutes.CHAT]: { externalId: string };
  [AppRoutes.ACCOUNT_RATINGS]: null;
  [AppRoutes.CONFIRM_AND_PAY]: null;
  [AppRoutes.MY_MEASUREMENTS]: null;
  [AppRoutes.PERSONAL_INFORMATION]: null;
  [AppRoutes.HELP_CENTER]: null;
  [AppRoutes.HELP_CENTER_DETAIL]: { id: string };
  [AppRoutes.PAYMENT_DETAILS]: null;
  [AppRoutes.INVITE_FRIENDS]: null;
  [AppRoutes.EARNINGS]: null;
  [AppRoutes.EARNING_DETAILS]: { externalId: string };
  [AppRoutes.PAYOUT_DETAILS]: null;
  [AppRoutes.HOST_BIKE_FIRST_STEP]: null;
  [AppRoutes.HOST_BIKE_CONFIRM_LOCATION]: null;
  [AppRoutes.HOST_BIKE_SECOND_STEP]: null;
  [AppRoutes.HOST_BIKE_THIRD_STEP]: null;
  [AppRoutes.PAYOUT_METHOD]: null;
  [AppRoutes.HOST_RATINGS]: null;
  [AppRoutes.HOST_RESERVATION_HISTORY]: null;
  [AppRoutes.CONTENT_LIBRARY]: null;
  [AppRoutes.CONTENT_LIBRARY_DETAILS]: { externalId: string };
  [AppRoutes.TRIPS_RESERVATION_DETAIL]: { externalId: string };
  [AppRoutes.HOST_RESERVATION_DETAIL]: { externalId: string };
  [AppRoutes.RECEIPT_DETAIL]: { url: string };
  [AppRoutes.REPORT_ISSUE]: null;
  [AppRoutes.DISABLE_PICKUP_ISSUE]: null;
  [AppRoutes.TERMS_AND_CONDITIONS]: null;

  // Landing page
  [AppRoutes.HOME]: null;

  // Waitlist
  [AppRoutes.WAITLIST_HOME]: null;
  [AppRoutes.WAITLIST_JOIN]: null;

  [AppRoutes.HOST_RESERVATION_CANCEL]: { externalId: string };

  [AppRoutes.EDIT_AVATAR]: null;
  [AppRoutes.INSURANCE]: null;
  [AppRoutes.VALIDATE_FORGOT_PASSWORD]: null;
  [AppRoutes.VALIDATE_ACCOUNT]: null;
  [AppRoutes.COMPLETE_ADDITIONAL_INFORMATION]: null;

  [AppRoutes.ABOUT]: null;
};

export function appNavigate<Routes extends keyof RouteParams>(
  nav: NavigateFunction,
  route: Routes,
  params: RouteParams[Routes],
  options?: NavigateOptions
) {
  var fullRoute: string = route;

  if (params != null) {
    for (let paramName in params) {
      // @ts-ignore
      let paramValue: string = params[paramName];

      fullRoute = fullRoute.replace(`:${paramName}`, paramValue);
    }
  }

  nav(fullRoute, options);
}
