import { useEffect, useState } from 'react';
import { FileData } from '../components/commons/FileSelection';

export function useForm<T>(
  formFields: T
): [
  typeof form,
  typeof validations,
  typeof handleCustomChange,
  typeof formIsValid,
  typeof clearForm,
  typeof setValidations,
  typeof setForm
] {
  const [form, setForm] = useState<T>(formFields);
  const [validations, setValidations] = useState<{ [P in keyof T]: boolean } | {}>({});

  useEffect(() => {
    fillValidations();
  }, []);

  const handleCustomChange = (
    inputName: string,
    inputValue: string | FileData | boolean | undefined,
    isValid: boolean
  ) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);

    let updatedValidations: typeof validations = { ...validations };
    (updatedValidations as any)[inputName] = isValid;
    setValidations(updatedValidations);
  };

  const fillValidations = () => {
    const auxForm: typeof validations = Object.assign({}, form);

    Object.keys(auxForm).forEach((key) => {
      // @ts-ignore
      auxForm[key] = !!auxForm[key];
    });

    setValidations(auxForm);
  };

  const formIsValid = () => {
    return !Object.values(validations).includes(false);
  };

  const clearForm = () => {
    const newForm: typeof form = Object.assign({}, form);

    Object.keys(newForm).forEach((key) => {
      // @ts-ignore
      newForm[key] = '';
    });

    const newValidations: typeof validations = Object.assign({}, validations);
    Object.keys(newValidations).forEach((key) => {
      // @ts-ignore
      newValidations[key] = !!newForm[key];
    });

    setForm(newForm);
    setValidations(newValidations);
  };

  return [form, validations, handleCustomChange, formIsValid, clearForm, setValidations, setForm];
}
