import { MenuItem, Select, SelectProps, styled } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
  props: SelectProps;
  topRightElement?: JSX.Element;
  bottomRightElement?: JSX.Element;
  children?: React.ReactNode;
  labelContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  includeOther?: boolean;
  selectOne?: boolean;
}

const AppSelect = ({
  props,
  bottomRightElement,
  topRightElement,
  children,
  labelContainerProps,
  includeOther,
  selectOne,
}: Props) => {
  const classes = useStyles();

  const CustomSelect = styled(Select)({
    '& .MuiInputBase-input': {
      border: `0.0625em solid ${AppTheme.colors.gray_C9CBCA}`,
      borderRadius: '0.25em',
      width: '100%',
      padding: '0.625em 0.75em',
      fontFamily: AppFontFamily.SHAPE,
      fontSize: '0.81em',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.0625em solid ${AppTheme.colors.gray_C9CBCA}`,
    },
  });

  const { className, ...labelDivProps } = labelContainerProps || {};

  return (
    <>
      {props.label && (
        <div className={`${classes.labelContainer} ${className || ''}`} {...labelDivProps}>
          <AppTypography type={PDLTypography.subHeading}>{props.label}</AppTypography>
          {topRightElement}
        </div>
      )}
      <CustomSelect
        {...props}
        fullWidth
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
      >
        <MenuItem
          value={'-1'}
          disabled={selectOne === undefined ? true : selectOne}
          children={'Select one'}
        />
        {children}
        {includeOther ? <MenuItem value={'other'} children={'Other'} /> : null}
      </CustomSelect>
      <div style={{ textAlign: 'right' }}>{bottomRightElement}</div>
    </>
  );
};

export default AppSelect;

const useStyles = makeStyles({
  labelContainer: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
