import ObservableService from "../core/ObservableService";
import qs from "qs";
import axios from "axios";
import StorageService from "./StorageService";
import User from "../core/User";

export enum AuthEvents {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

// this class can be extended to support more errors
export enum LoginError {
  NOT_AUTHORIZED,
}

const loginErrorMap: { [key: string]: LoginError } = {
  "not-authorized": LoginError.NOT_AUTHORIZED,
};

// variable in the QS that will be used to pass the token from other part of the app
// e.g. OAuth2 flow
const TOKEN_QUERY_STRING_PROPERTY = "t";

// if the user is logged already, it will be redirected to this path
const PATH_TO_REDIRECT_LOGGED_USER = "/home";

// if the user is not authorized, it will be redirected to this path
const NOT_AUTHORIZED_URL = "/?error=not-authorized";

// if true, we redirect the user to HTTPS if HTTP is used
const FORCE_HTTPS = true;

// paths that will be allowed is the user is not logged
const PUBLIC_PATHS = ["/", "/register"];

const inDevMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

class AsyncAuthService extends ObservableService<AuthEvents> {
  public getToken(): Promise<string | null> {
    return StorageService.getData("t");
  }

  public async storeToken(token: string, tokenExpiration?: Date) {
    await StorageService.storeData("t", token);

    if (tokenExpiration) {
      await StorageService.storeData(
        "t_expiration",
        tokenExpiration.toString()
      );
    }

    if (!!token) {
      this.notifyObservers(AuthEvents.LOGIN);
    }
  }

  //TODO: remove this method from here
  public getExternalId(): Promise<string | null> {
    return StorageService.getData("e");
  }

  //TODO: remove this method from here
  public setExternalId(externalId: string): Promise<void> {
    return StorageService.storeData("e", externalId);
  }

  //TODO: remove this method from here
  public getSelectedRole(): Promise<string | null> {
    return StorageService.getData("selectedRole");
  }

  //TODO: remove this method from here
  public setSelectedRole(selectedRole: string) {
    StorageService.storeData("selectedRole", selectedRole);
  }

  public async getTokenExpiration(): Promise<Date | null> {
    const tokenExpiration: string | null = await StorageService.getData(
      "t_expiration"
    );

    if (tokenExpiration) {
      return new Date(tokenExpiration);
    }

    return null;
  }

  public async getUser(): Promise<User | null> {
    const JSONUser: string | null = await StorageService.getData("r");
    let user: User | null = null;

    if (!!JSONUser) {
      user = JSON.parse(JSONUser);
    }

    return user;
  }

  public storeUser(user: User) {
    StorageService.storeData("r", JSON.stringify(user));
  }

  public storeExternalId(externalId: string): Promise<void> {
    return StorageService.storeData("e", externalId);
  }

  public logOut() {
    delete axios.defaults.headers.common["Authorization"];
    this.removeData(["t", "r", "t_expiration"]);
    this.notifyObservers(AuthEvents.LOGOUT);
  }

  public removeData(keys: string[]) {
    keys.forEach((key: string) => {
      StorageService.removeData(key);
    })
  }

  public isLogged = async () => {
    const tokenExpiration = await this.getTokenExpiration();
    const token = await this.getToken();

    if (tokenExpiration) {
      return !!token && tokenExpiration.getTime() > new Date().getTime();
    }

    return !!token;
  };

  //TODO refactor to async
  /**
   * This method takes care of processing external tokens, redirecting the user properly depending on their
   * login state, etc. The result of executing this method in most cases results in a redirect or a noop
   */
  public async redirectUserIfNeededDependingOnLoginState(
    extraNotLoggedPath: string[] = []
  ) {
    // Auth token is provided, we store the token and navigate to user home screen
    const qsParams = qs.parse(window.location.search.substring(1));
    const token = qsParams[TOKEN_QUERY_STRING_PROPERTY] as string;

    var accessError = false;

    if (FORCE_HTTPS && window.location.protocol === "http:" && !inDevMode) {
      window.location.href = window.location.href.replace("http:", "https:");
    }

    if (token !== undefined) {
      if (token.length === 0 || token === "null") {
        accessError = true;
      } else {
        await this.storeToken(token);
      }
    }

    if (accessError) {
      window.location.href = NOT_AUTHORIZED_URL;
    } else {
      // User is not logged and tried to access a non-public part of the app, we navigate to
      // home to let the user log in
      if (
        !PUBLIC_PATHS.concat(extraNotLoggedPath).includes(
          window.location.pathname
        ) &&
        !this.isLogged()
      ) {
        window.location.href = "/";
      }

      // If user is logged in and it is in the home screen, then we redirect it to the
      // personal data UI
      if (PUBLIC_PATHS.includes(window.location.pathname) && await this.isLogged()) {
        window.location.href = PATH_TO_REDIRECT_LOGGED_USER;
      }
    }
  }

  /**
   * Detects if, depending on the URL or other state, a login error just ocurred, so it can be managed properly
   */
  public loginErrorJustHappened(): LoginError | undefined {
    const qsParams = qs.parse(window.location.search.substring(1));
    const error = qsParams.error as string;

    if (error) {
      return loginErrorMap[error];
    }

    return undefined;
  }
}

export default new AsyncAuthService();
