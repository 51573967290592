import { Box, Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { AppFontFamily } from '../../../utils/AppFontFamily';
import { IAppTheme } from '../../../utils/AppTheme';
import AppHeader from '../../commons/AppHeader';
import AppTextFieldHook from '../../commons/AppTextFieldHook';
import SimpleButton from '../../commons/SimpleButton';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import { makeStyles } from '@mui/styles';
import FormOptionsService from '../../../services/FormOptionsService';
import FormOptions from '../../../model/FormOptions';
import BottomSheet from '../../commons/BottomSheet';
import SizeGuideTable from '../../Bikes/SizeGuideTable';
import { UploadImage } from '../../commons/UploadImage';
import { v4 as uuidv4 } from 'uuid';
import Image from '../../../external/fox-typescript/core/Image';
import { Bike } from '../../../external/pdl-common/model/Bike';
import { useLocation, useNavigate } from 'react-router-dom';
import { BikeDetails } from '../../../model/BikeDetails';
import Logger from '../../../external/pdl-common/utils/Logger';
import BikeSecondStepCreation from '../../../model/update/bike/BikeSecondStepCreation';
import BikeService from '../../../services/BikeService';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { RequestStatus } from '../../../utils/RequestStatus';
import { AlertDialog } from '../../commons/AlertDialog';
import AlertService from '../../../services/AlertService';
import RequestStatusFeedBack from '../../commons/RequestStatusFeedback';
import FormUtils from '../../../utils/FormUtils';
import ConfirmBikeEditionBottomSheet from './commons/ConfirmBikeEditionBottomSheet';
import { useForm } from 'react-hook-form';
import AppAutocompleteHook from '../../commons/AppAutocompleteHook';
import AppSelectHook from '../../commons/AppSelectHook';
import { PartsDetailForm } from '../../../model/parts/PartsDetailForm';
import reachHandlebar from '../../../assets/img/reach_handlebar.jpeg';
import saddleHeight from '../../../assets/img/saddle_height.jpeg';
import { BikeStatus } from '../../../external/pdl-common/utils/enums/BikeStatus';
import Navbar from '../../core/Navbar';
import BikeUtils from '../../../external/pdl-common/utils/BikeUtils';

interface Props {
  editMode?: boolean;
  editingBike?: Bike;
  onSaveChanges?: (formData: BikeDetails) => Promise<void>;
  isLoading?: boolean;
}

const dummyImage = () => {
  return {
    externalId: uuidv4(),
    url: '',
    width: 0,
    height: 0,
    type: '',
    description: '',
  };
};

const defaultForm = {
  title: '',
  images: [dummyImage()],
  description: '',
  serialNumber: '',
  make: '',
  type: '',
  model: '',
  frameMaterial: '',
  year: '',
  color: '',
  condition: '',
  size: '',
  marketPrice: '',
  topSaddleHeight: '',
  bottomSaddleHeight: '',
};

const logger = new Logger('HostBikeSecondStep');

export default function HostBikeSecondStep(props: Props) {
  // States we pass between views
  const location = useLocation().state as any;
  const bike: Bike | undefined = location?.bike;
  const partDetails: PartsDetailForm | undefined = location?.partDetails;
  const payoutMethod = location?.payoutMethod;
  const draftMode: boolean | undefined = location?.draftMode;

  const classes = useStyles();
  const navigate = useNavigate();

  const [imagesExternalIds, setImagesExternalIds] = useState<string[]>([]);
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false);
  const [options, setOptions] = useState<FormOptions | undefined>(undefined);
  const [images, setImages] = useState<Image[]>([dummyImage()]);
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [showConfirmEdition, setShowConfirmEdition] = useState<boolean>(false);

  const getFormDefaultValues = (): BikeDetails => {
    if (props.editMode && props.editingBike) {
      return BikeUtils.toBikeDetails(props.editingBike);
    }

    if (bike) {
      return BikeUtils.toBikeDetails(bike);
    }

    return defaultForm;
  };

  const {
    handleSubmit,
    control,
    formState: { isValid },
    getValues,
  } = useForm({
    mode: 'all',
    defaultValues: getFormDefaultValues(),
  });

  useEffect(() => {
    getFormOptions();

    const actualBike = props.editMode ? props.editingBike : bike;

    if (actualBike) {
      setImages(
        actualBike.images && actualBike.images.length > 0
          ? [...actualBike.images, dummyImage()]
          : [dummyImage()]
      );
    }
  }, []);

  useEffect(() => {
    if (submitStatus === RequestStatus.ERROR) {
      // If there is no dummy image we add one, to let the user keep uploading images
      const isDummyImagePresent = images.find((i) => !i.url);
      !isDummyImagePresent && setImages([...images, dummyImage()]);
    }
  }, [submitStatus]);

  useEffect(() => {
    const hasImages = images.filter((i) => i.url !== '').length > 0;
    setIsFormComplete(hasImages && isValid);
  }, [images, isValid]);

  const getFormOptions = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const response = await FormOptionsService.getFormOptions();
      setOptions(response.getContent());

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      logger.error('Error getting form options', error);

      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }

      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const removeEmptyImage = () => {
    const validImages = images.filter((img) => img.url !== '');
    setImages(validImages);
    return validImages;
  };

  const handleCaptionChange = (inputValue: string, image: Image) => {
    const imageToUpdate = images.find((img) => img.externalId === image.externalId);

    if (imageToUpdate) {
      imageToUpdate.description = inputValue;
      setIsChanged(true);
    }

    setImages([...images]);
  };

  const handleSetImage = (image: Image) => {
    images.pop();
    setImages([...images, image, dummyImage()]);
    setIsChanged(true);
  };

  const handleImageUpload = (image: Image) => {
    // set external id to bike details
    const imagesExternalIdsUpdated = [...imagesExternalIds];
    imagesExternalIdsUpdated.push(image.externalId!);
    setImagesExternalIds(imagesExternalIdsUpdated);
    setIsChanged(true);
  };

  const onNextStep = async (data: BikeDetails) => {
    setSubmitStatus(RequestStatus.LOADING);

    try {
      if (!options) {
        return;
      }

      const isCustomMake = !options.makes.find((o) => o === data.make);
      const isCustomColor = !options.colors.find((o) => o === data.color);
      const isCustomCondition = !options.conditions.find((o) => o === data.condition);

      const bikeDetailCreation = new BikeSecondStepCreation(
        data.title,
        data.description,
        data.serialNumber,
        isCustomMake ? '' : data.make,
        isCustomMake ? data.make : '',
        data.type,
        data.model,
        data.frameMaterial,
        Number(data.year),
        isCustomColor ? 'Other' : data.color,
        isCustomColor ? data.color : '',
        isCustomCondition ? 'Other' : data.condition,
        isCustomCondition ? data.condition : '',
        data.size !== '-1' ? data.size : 'Unknown',
        Number(data.topSaddleHeight),
        Number(data.bottomSaddleHeight)
      );

      bikeDetailCreation.marketPrice = Number(data.marketPrice);
      bikeDetailCreation.images = removeEmptyImage();

      const response = await BikeService.secondStepCreationBike(
        bike?.externalId!,
        bikeDetailCreation
      );

      setSubmitStatus(RequestStatus.SUCCESS);
      appNavigate(navigate, AppRoutes.HOST_BIKE_THIRD_STEP, null, {
        state: {
          bike: response.getContent(),
          partDetails,
          payoutMethod,
          draftMode,
          partsToCreate: location?.partsToCreate,
        },
      });
    } catch (error: any) {
      if (error.response.status === 400) {
        AlertService.showSnackCustomError('Make sure to complete all mandatory fields');
      } else {
        AlertService.showSnackCustomError('Error submitting bike information');
      }
      logger.error('Failed to go to step three.', error.response.data.message);
      setSubmitStatus(RequestStatus.ERROR);
    }
  };

  const onBack = () => {
    if (props.editMode) {
      if (isChanged) {
        setOpenAlertDialog(true);
        return;
      } else {
        navigate(-1);
      }
    } else {
      appNavigate(navigate, AppRoutes.HOST_BIKE_FIRST_STEP, null, {
        state: { bike, partDetails, payoutMethod, draftMode },
      });
    }
  };

  const onBeforeEdit = () => {
    if (props.editingBike?.status === BikeStatus.PENDING) {
      onEdit(getValues());
      return;
    }

    setShowConfirmEdition(true);
  };

  const onEdit = async (data: BikeDetails) => {
    if (props.onSaveChanges) {
      setSubmitStatus(RequestStatus.LOADING);
      setIsChanged(false);
      removeEmptyImage();
      await props.onSaveChanges!({ ...data, images: images });
      setSubmitStatus(RequestStatus.SUCCESS);
      setTimeout(() => {
        // Return to previous screen automatically after 3 seconds
        navigate(-1);
      }, 2000);
    }
  };

  if (requestStatus !== RequestStatus.SUCCESS) {
    return (
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <RequestStatusFeedBack status={requestStatus} onRetry={getFormOptions} />
      </div>
    );
  }

  const handleInputChange = () => {
    if (!isChanged) setIsChanged(true);
  };

  return (
    <>
      {/* Bottom Sheets */}
      <BottomSheet open={showBottomSheet} onClose={() => setShowBottomSheet(false)}>
        <Box className={classes.subtitle}>
          <AppTypography type={PDLTypography.largeHeading}>Size guide</AppTypography>
        </Box>
        <SizeGuideTable />
      </BottomSheet>

      <ConfirmBikeEditionBottomSheet
        open={showConfirmEdition}
        onClose={() => setShowConfirmEdition(false)}
        onConfirm={handleSubmit(onEdit)}
      />

      {/* Content */}
      <AppHeader
        content={props.editMode ? 'Edit bike details' : 'About your bike'}
        onBack={onBack}
      />
      <form onSubmit={handleSubmit((data) => console.log(data))} style={{ paddingBottom: 59 }}>
        <Grid container direction="column" alignItems={'center'} sx={{ padding: '0em 2em' }}>
          <Grid item sm={10} md={6}>
            <Grid item xs={12} sx={{ marginTop: '0.95em' }}>
              <div className={classes.divFlex}>
                <Box className={classes.subtitle}>
                  <AppTypography type={PDLTypography.largeHeading}>Listing name</AppTypography>
                </Box>
                <AppTextFieldHook
                  customOnChange={handleInputChange}
                  name={'title'}
                  label={'Add a name *'}
                  control={control}
                  inputProps={{
                    sx: { marginBottom: '1.42em' },
                  }}
                  labelProps={{
                    type: PDLTypography.subHeading,
                    customStyles: { marginBottom: '0.5em' },
                  }}
                  rules={{ required: true }}
                />

                <Divider variant="middle" className={classes.divider} />

                <Box sx={{ marginBottom: '1.42em' }}>
                  <Box className={classes.subtitle}>
                    <AppTypography type={PDLTypography.largeHeading}>
                      Listing images *
                    </AppTypography>
                  </Box>
                  <Box className={classes.subtitle}>
                    <AppTypography
                      type={PDLTypography.smallParagraph}
                      customClasses={classes.instruction}
                    >
                      Upload images of the whole bike. You can upload images of specific bike parts
                      in the next step.
                    </AppTypography>
                  </Box>
                  <Box sx={{ mb: 5 }}>
                    {images.map((image, index) => (
                      <UploadImage
                        key={`${image.externalId}-${index}`}
                        imageUrls={image.url ? [image.url] : undefined}
                        handleSetImage={handleSetImage}
                        caption
                        onCaptionChange={(inputName, inputValue) =>
                          handleCaptionChange(inputValue, image)
                        }
                        onImageUploaded={handleImageUpload}
                        image={image}
                        defaultImages
                        onRemove={(imageIdx) => {
                          const newImages = images.filter((img, idx) => imageIdx !== idx);
                          const newImageExternalIds = imagesExternalIds.filter(
                            (externalId, idx) => imageIdx !== idx
                          );

                          setImages([...newImages]);
                          setImagesExternalIds(newImageExternalIds);
                          setIsChanged(true);
                        }}
                        imageIndex={index}
                      />
                    ))}
                  </Box>
                </Box>

                <Divider variant="middle" className={classes.divider} />

                <Box className={classes.subtitle}>
                  <AppTypography type={PDLTypography.largeHeading}>
                    Listing description
                  </AppTypography>
                </Box>

                <AppTextFieldHook
                  name={'description'}
                  label={'Add a description *'}
                  control={control}
                  customOnChange={handleInputChange}
                  inputProps={{ sx: { marginBottom: '1.42em' }, rows: 3, multiline: true }}
                  labelProps={{
                    type: PDLTypography.subHeading,
                    customStyles: { marginBottom: '0.5em' },
                  }}
                  rules={{ required: true }}
                />

                <Divider variant="middle" className={classes.divider} />

                <Box className={classes.subtitle}>
                  <AppTypography type={PDLTypography.largeHeading}>Listing details</AppTypography>
                </Box>

                <AppTextFieldHook
                  name={'serialNumber'}
                  label={'Serial number (will not be publicly displayed)'}
                  control={control}
                  customOnChange={handleInputChange}
                  inputProps={{ sx: { marginBottom: '1.42em' } }}
                  labelProps={{
                    type: PDLTypography.subHeading,
                    customStyles: { marginBottom: '0.5em' },
                  }}
                />

                <AppAutocompleteHook
                  name={'make'}
                  control={control}
                  label={'Make *'}
                  customOnChange={handleInputChange}
                  options={options ? FormUtils.convertToAppAutocompleteOptions(options.makes) : []}
                  rules={{ required: true }}
                  freeSolo
                />

                <AppTextFieldHook
                  name={'model'}
                  label={'Model'}
                  control={control}
                  inputProps={{ sx: { marginBottom: '0.5em' } }}
                  customOnChange={handleInputChange}
                  labelProps={{
                    type: PDLTypography.subHeading,
                    customStyles: { marginBottom: '0.5em', marginTop: '1.4em' },
                  }}
                />

                <AppSelectHook
                  name={'type'}
                  control={control}
                  props={{
                    label: 'Type *',
                    name: 'type',
                    sx: { marginBottom: '1.42em' },
                  }}
                  options={options ? options.types.map((o) => ({ value: o, label: o })) : []}
                  rules={{ required: true }}
                />

                <AppSelectHook
                  name={'frameMaterial'}
                  control={control}
                  props={{
                    label: 'Frame material *',
                    name: 'frameMaterial',
                    sx: { marginBottom: '1.42em' },
                  }}
                  options={
                    options ? options.frameMaterials.map((o) => ({ value: o, label: o })) : []
                  }
                  rules={{ required: true }}
                />
                <AppSelectHook
                  name={'year'}
                  control={control}
                  props={{
                    label: 'Year *',
                    name: 'Year',
                    sx: { marginBottom: '1.42em' },
                  }}
                  options={
                    options
                      ? options.years
                        .sort((a, b) => b.localeCompare(a))
                        .map((o) => ({ value: o, label: o }))
                      : []
                  }
                  rules={{ required: true }}
                />
                <AppAutocompleteHook
                  name={'color'}
                  control={control}
                  customOnChange={handleInputChange}
                  label={'Color *'}
                  options={
                    options
                      ? FormUtils.convertToAppAutocompleteOptions(
                        options.colors.sort((a, b) => a.localeCompare(b))
                      )
                      : []
                  }
                  rules={{ required: true }}
                  freeSolo
                />

                <AppAutocompleteHook
                  customOnChange={handleInputChange}
                  name={'condition'}
                  control={control}
                  label={'Condition *'}
                  options={
                    options ? FormUtils.convertToAppAutocompleteOptions(options.conditions) : []
                  }
                  rules={{ required: true }}
                  freeSolo
                />

                <AppSelectHook
                  name={'size'}
                  control={control}
                  props={{
                    label: 'Size *',
                    name: 'size',
                    sx: { marginBottom: '1.42em' },
                  }}
                  options={options ? options.sizes.map((o) => ({ value: o, label: o })) : []}
                  rules={{ required: true }}
                  RightElement={
                    <Box onClick={(e) => setShowBottomSheet(true)}>
                      <AppTypography
                        type={PDLTypography.smallParagraph}
                        children={'View Size Guide'}
                        customStyles={{ textDecoration: 'underline', cursor: 'pointer' }}
                      />
                    </Box>
                  }
                />

                <AppTextFieldHook
                  name={'marketPrice'}
                  label={'Market Price (in current condition) *'}
                  customOnChange={handleInputChange}
                  control={control}
                  inputProps={{
                    sx: { marginBottom: '1.42em' },
                    type: 'price',
                  }}
                  labelProps={{
                    type: PDLTypography.subHeading,
                    customStyles: { marginBottom: '0.5em' },
                  }}
                  rules={{ required: true }}
                />

                <Box className={classes.subtitle}>
                  <AppTypography type={PDLTypography.largeHeading}>Saddle height</AppTypography>
                </Box>
                <img src={saddleHeight} alt={'Saddle to height'} height={'100%'} width={'100%'} />
                <AppTextFieldHook
                  label={'Top of saddle to middle of bottom bracket (centimeters) *'}
                  name={'topSaddleHeight'}
                  customOnChange={handleInputChange}
                  control={control}
                  inputProps={{ sx: { marginBottom: '1.42em' }, type: 'decimal' }}
                  labelProps={{
                    type: PDLTypography.subHeading,
                    customStyles: { margin: '1em 0 0.5em 0' },
                  }}
                  rules={{ required: true }}
                />
                <Box className={classes.subtitle}>
                  <AppTypography type={PDLTypography.largeHeading}>Reach</AppTypography>
                </Box>
                <img src={reachHandlebar} alt={'Reach'} height={'100%'} width={'100%'} />
                <AppTextFieldHook
                  customOnChange={handleInputChange}
                  label={'Tip of saddle to middle of handlebar (centimeters) *'}
                  name={'bottomSaddleHeight'}
                  control={control}
                  inputProps={{ sx: { marginBottom: '1.42em' }, type: 'decimal' }}
                  labelProps={{
                    type: PDLTypography.subHeading,
                    customStyles: { margin: '1em 0 0.5em 0' },
                  }}
                  rules={{ required: true }}
                />
              </div>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sx={muiStyle.gridBottom}
              alignItems="center"
              justifyContent="space-between"
            >
              <Divider className={classes.divider} />
              {props.editMode ? (
                <Grid item xs={12}>
                  <div className={classes.signUp}>
                    <SimpleButton
                      children={'Save Changes'}
                      isLoading={submitStatus === RequestStatus.LOADING}
                      onClick={onBeforeEdit}
                      disabled={!isFormComplete}
                    />
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid item xs={4}>
                    <div className={classes.step}>Step 2 of 3</div>
                  </Grid>
                  <Grid item xs={8}>
                    <div className={classes.signUp}>
                      <SimpleButton
                        isLoading={submitStatus === RequestStatus.LOADING || props.isLoading}
                        children={'Next'}
                        onClick={handleSubmit(onNextStep)}
                        disabled={!isFormComplete || props.isLoading}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {props.editMode && (
            <AlertDialog
              title="Leave edit"
              content="Are you sure to leave edit? Your changes will be discarted"
              open={openAlertDialog}
              onConfirm={() => {
                setOpenAlertDialog(false);
                navigate(-1);
              }}
              onCancel={() => {
                setOpenAlertDialog(false);
              }}
            />
          )}
        </Grid>
      </form>
      <Navbar />
    </>
  );
}

const muiStyle = {
  gridHeader: {
    marginTop: '3.75em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridBottom: {
    marginBottom: '2em',
  },
} as const;

const useStyles = makeStyles((theme: IAppTheme) => ({
  divider: {
    marginBottom: '2em !important',
  },
  subtitle: {
    marginBottom: '1.42em',
  },
  step: {
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC,
    fontSize: '0.9em',
  },
  divFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  signUp: {
    marginTop: '1em',
  },
  bodyInvite: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  invite: {
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontWeight: 'bold',
    fontSize: '0.93em',
    marginLeft: '0.5em',
  },
  imagesSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  instruction: {
    fontFamily: AppFontFamily.SHAPE,
    fontSize: '0.88em',
    marginBottom: '0',
    marginTop: -3,
  },
  imageBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.white_EEEEEC,
    border: `0.063em dashed ${theme.colors.gray_C9CBCA}`,
    boxSizing: 'border-box',
    borderRadius: '0.25em',
    height: '2.65em',
    padding: '0.625em 0.75em',
    marginTop: '1.1em',
  },
  textImageBox: {
    color: theme.colors.gray_A8ACAD,
    fontFamily: AppFontFamily.SHAPE,
    fontSize: '0.8em',
    marginLeft: 5,
  },
}));
