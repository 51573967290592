import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountType } from '../../../model/AccountType';
import BikeService from '../../../services/BikeService';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import Logger from '../../../external/pdl-common/utils/Logger';
import AppHeader from '../../commons/AppHeader';
import FormContainer from '../../commons/FormContainer';
import NotificationModal from '../../commons/NotificationModal';
import SimpleButton from '../../commons/SimpleButton';
import { AppLayout } from '../../core/AppLayout';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import BankInformationComponent from './commons/BankInformationComponent';
import { Bike } from '../../../external/pdl-common/model/Bike';
import { RequestStatus } from '../../../utils/RequestStatus';
import PayoutDetails from './PayoutDetails';
import AlertService from '../../../services/AlertService';
import { FileData } from '../../commons/FileSelection';
import StripeService from '../../../services/StripeService';
import RequestStatusFeedBack from '../../commons/RequestStatusFeedback';

const logger = new Logger('PayoutMethod');

export interface BankInformationForm {
  accountType: AccountType | '-1';
  firstName: string;
  lastName: string;
  routingNumber: string;
  accountNumber: string;
  confirmAccountNumber: string;
  accountCountry: string;
  bankCountry: string;
  socialSecurityLast4: string;
  currency: string;
  document?: FileData;
  documentShowsLegalName: boolean;
  documentIsInColor: boolean;
  agreeWithStripeTOS: boolean;
}

const PayoutMethod = () => {
  // States we pass between views
  const location = useLocation().state as any;
  const bike: Bike = location?.bike;
  const partDetails = location?.partDetails;

  const isCreatingBike = bike || partDetails;
  const classes = useStyles();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [bankLast4, setBankLast4] = useState<string | undefined>();
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const [form, setForm] = useState<BankInformationForm>({
    accountType: '-1',
    firstName: '',
    lastName: '',
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    bankCountry: '-1',
    accountCountry: '',
    socialSecurityLast4: '',
    currency: '-1',
    documentShowsLegalName: false,
    documentIsInColor: false,
    agreeWithStripeTOS: false,
    document: undefined,
  });
  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [submitStatus, setSubmitStatus] = useState(RequestStatus.SUCCESS);

  const [validations, setValidations] = useState<{ [P in keyof BankInformationForm]: boolean }>({
    accountType: form.accountType !== '-1',
    firstName: false,
    lastName: false,
    routingNumber: false,
    accountNumber: false,
    confirmAccountNumber: false,
    bankCountry: form.bankCountry === '-1',
    accountCountry: false,
    socialSecurityLast4: false,
    currency: form.currency === '-1',
    document: false,
    documentShowsLegalName: false,
    documentIsInColor: false,
    agreeWithStripeTOS: false,
  });

  useEffect(() => {
    load();
  }, []);

  const checkIfHasAccount = async () => {
    try {
      const checkAccountResponse = await StripeService.checkIfHasAccount();
      setHasAccount(checkAccountResponse.getContent());
    } catch (error) {
      logger.error('Error checking if has account', error);
    }
  };

  const load = async () => {
    // Fetch current user's bank info
    setRequestStatus(RequestStatus.LOADING);

    try {
      var bankAccountResponse = await StripeService.getDefaultBankAccount();
      bankAccountResponse
        .onSuccess((response) => {
          setBankLast4(response.getContent());
        })
        .onError((error) => {
          switch (error.getStatusCode()) {
            case 404:
              return;
            default:
              throw new Error('Error getting bank account last 4');
          }
        });

      checkIfHasAccount();

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error) {
      logger.error('Error loading content', error);
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const isBankEdited = () => {
    return (
      form.accountType !== '-1' ||
      form.bankCountry !== '-1' ||
      !!form.firstName ||
      !!form.lastName ||
      form.currency !== '-1' ||
      !!form.routingNumber ||
      !!form.accountNumber ||
      !!form.confirmAccountNumber
    );
  };

  const formIsValid = () => {
    if (bankLast4) {
      if (isBankEdited()) {
        return (
          validations.accountType &&
          validations.bankCountry &&
          validations.firstName &&
          validations.lastName &&
          validations.currency &&
          validations.routingNumber &&
          validations.accountNumber &&
          validations.confirmAccountNumber
        );
      } else {
        return isCreatingBike;
      }
    }

    if (hasAccount) {
      return (
        validations.accountType &&
        validations.bankCountry &&
        validations.firstName &&
        validations.lastName &&
        validations.currency &&
        validations.routingNumber &&
        validations.accountNumber &&
        validations.confirmAccountNumber
      );
    }

    return !Object.values(validations).includes(false);
  };

  // In this way we intercept the default behavior when the user goes backwards
  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onBack);

    return () => {
      window.removeEventListener('popstate', onBack);
    };
  }, []);

  const handleOnSubmit = async () => {
    try {
      setSubmitStatus(RequestStatus.LOADING);

      if (!hasAccount) {
        // User does not have a connected account.
        const response = await StripeService.createConnectAccount(form);
        setBankLast4(response.getContent());
      } else if (!bankLast4) {
        // User does not have a bank account.
        const response = await StripeService.createBankAccount(form);
        setBankLast4(response.getContent());
      } else if (isBankEdited()) {
        // User have connected account and bank account. But is adding a new bank account.
        const response = await StripeService.createBankAccount(form);
        setBankLast4(response.getContent());
      }

      if (isCreatingBike) {
        // Finish bike creation only when reached view from host third step
        const bikeResponse = await BikeService.submitBike(bike.externalId!);
        setModalOpen(!!bikeResponse);
      } else {
        AlertService.showSuccessMessage('Bank information updated successfully');
      }

      setSubmitStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      // It may happen that the account creation suceeded but the bank account creation not. So we check it:
      if (!hasAccount) {
        checkIfHasAccount();
      }

      logger.error('Error when listing the bike', error);

      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }
    } finally {
      setSubmitStatus(RequestStatus.SUCCESS);
    }
  };

  const getContent = (): JSX.Element => {
    if (requestStatus != RequestStatus.SUCCESS) {
      return (
        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={load} />
        </div>
      );
    }

    return (
      <FormContainer
        BottomElement={
          <SimpleButton
            isLoading={submitStatus === RequestStatus.LOADING}
            children={
              bankLast4
                ? isCreatingBike
                  ? 'Save Changes and Submit'
                  : 'Save Changes'
                : 'Add Bank Information and Submit'
            }
            disabled={!formIsValid()}
            onClick={handleOnSubmit}
          />
        }
      >
        <Box mt={'2em'}>
          {bankLast4 ? (
            <PayoutDetails bankLast4={bankLast4} />
          ) : (
            <div className={classes.flexColumn}>
              <AppTypography type={PDLTypography.mediumHeading}>
                You're almost ready to list!
              </AppTypography>

              <AppTypography
                type={PDLTypography.paragraph}
                customStyles={{ marginTop: '0.8em', marginBottom: '1.25em' }}
              >
                Add a bank account to receive the money you make from this and other listings.
              </AppTypography>

              <AppTypography type={PDLTypography.smallHeading}>How payouts work</AppTypography>

              <AppTypography type={PDLTypography.paragraph} customStyles={{ marginTop: '0.8em' }}>
                As a Host you are requested to provide your Bank account information. After a
                successful rental transaction, your funds will be deposited into your account within
                48 hours of drop off minus the PDL Club fee.
              </AppTypography>
            </div>
          )}
        </Box>

        <Divider sx={{ my: 3 }} />

        <BankInformationComponent
          form={form}
          setForm={setForm}
          validations={validations}
          setValidations={setValidations}
          replace={bankLast4 !== undefined}
          hasAccount={hasAccount}
        />
      </FormContainer>
    );
  };

  const onBack = () => {
    if (isCreatingBike) {
      // Return to third step screen
      appNavigate(navigate, AppRoutes.HOST_BIKE_THIRD_STEP, null, {
        state: { ...location, payoutMethod: form, partsToCreate: bike.parts },
      });
    } else {
      // Reached view from Host -> Payment details
      appNavigate(navigate, AppRoutes.HOST, null, {});
    }
  };

  return (
    <>
      <AppLayout
        header={() => (
          <AppHeader
            content={bankLast4 ? 'Payout Details' : 'Add payout method'}
            onBack={onBack}
            showBackButton={submitStatus !== RequestStatus.LOADING}
          />
        )}
        content={() => getContent()}
      />

      <NotificationModal
        text={'Your bike has been submitted and is under review. You will receive an email once the review process is complete.'}
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          appNavigate(navigate, AppRoutes.HOST, null);
        }}
      />
    </>
  );
};

export default PayoutMethod;

const useStyles = makeStyles({
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
});
