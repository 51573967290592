import { MapCoordinates } from '../model/utils/MapCoordinates';
import Location from '../external/fox-typescript/core/Location';

export const DEFAULT_MAP_LAYER =
  'https://api.mapbox.com/styles/v1/rodrigo-leon-mdp/ckx7w1lpg36pi15qwi6qbxsqu/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoicm9kcmlnby1sZW9uLW1kcCIsImEiOiJja3g3dzc5ZHMyMGNpMnBtdXN4eTJyNzhnIn0.95Bheg0iMviv0HNPHRqp2A';

export const DEFAULT_COORDINATES: MapCoordinates = {
  lat: 25.8408447,
  lng: -80.1922588,
};

export const googleMapsOptions = {
  disableDefaultUI: false,
  scrollwheel: false,
  maxZoom: 18,
  minZoom: 10,
  disableDoubleClickZoom: true,
  streetViewControl: false,
  fullscreenControl: false,
  rotateControl: false,
  clickableIcons: false,
  zoomControlOptions: { position: 3.0 },
  mapTypeControl: false,
};

export const toMapCoordinates = (location: Location): MapCoordinates => {
  const { latitude: lat, longitude: lng } = location;
  return { lat, lng };
};
