import AppTheme from '../../utils/AppTheme';
import { CheckboxWithLabel } from './CheckboxWithLabel';
import { makeStyles } from '@mui/styles';
import { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../utils/AppNavigation';
import { TermsAndConditionsType } from '../../model/enums/TermsAndConditionsType';

export interface TermsAndConditionsCheckboxProps {
  name: string;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  hostTerm?: boolean;
}

export default function TermsAndConditionsCheckbox(props: TermsAndConditionsCheckboxProps) {
  const classes = useStyles();

  return (
    <div style={{ marginTop: '1em' }}>
      <CheckboxWithLabel
        label={
          <span style={{ color: AppTheme.colors.gray_A8ACAD }}>
            I agree to the{' '}
            <Link
              className={classes.anchor}
              target={'_blank'}
              to={`${AppRoutes.TERMS_AND_CONDITIONS}?terms=${
                props.hostTerm ? TermsAndConditionsType.HOST_TERMS : TermsAndConditionsType.RENTER_TERMS
              }`}
            >
              terms and conditions
            </Link>
          </span>
        }
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled ? true : false}
      />
    </div>
  );
}

const useStyles = makeStyles({
  anchor: {
    color: AppTheme.colors.persianBlue_2238CB,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});
