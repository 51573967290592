import { Bike } from '../external/pdl-common/model/Bike';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import { BikeFilters } from '../model/utils/BikeFilters';
import environment from '../environment.json';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import httpClient from '../external/fox-typescript/utils/HttpClient';
import Location from '../external/fox-typescript/core/Location';
import BikeSecondStepCreation from '../model/update/bike/BikeSecondStepCreation';
import BikePriceInfoCreation from '../model/update/bike/BikePriceInfoCreation';
import { IPage } from '../external/fox-typescript/core/IPage';
import { PdlLocation } from '../model/PdlLocation';
import BlockoutDateCreation from '../model/creation/BlockoutDateCreation';
import { BlockoutDates } from '../external/pdl-common/model/BlockoutDates';
import { EditionType } from '../model/enums/EditionType';
import ApiResponseV2 from '../external/fox-typescript/core/ApiResponseV2';
import HttpClientV2 from '../external/fox-typescript/utils/HttpClientV2';
import { TieredPriceForm } from '../components/views/Host/Edit/HostTieredPrice';

class BikeService extends GenericCrudService<Bike> {
  create(instance: Bike): Promise<ApiResponse<Bike>> {
    throw new Error('Method not implemented.');
  }

  delete(instance: Bike): Promise<ApiResponse<Bike>> {
    throw new Error('Method not implemented.');
  }

  async getBike(externalId: string): Promise<ApiResponse<Bike>> {
    return await httpClient.get(`${this.basePath}/${externalId}`, true);
  }

  async getPublicBike(externalId: string): Promise<ApiResponse<Bike>> {
    return await httpClient.get(`${this.basePath}/public/${externalId}`, true);
  }

  updateActive(instance: Bike, editionType: EditionType): Promise<ApiResponse<Bike>> {
    return httpClient.put(
      `${this.basePath}/update-active/${instance.externalId}/${editionType}`,
      instance
    );
  }

  updateDraftLocation(instance: Bike): Promise<ApiResponse<Bike>> {
    return httpClient.put(
      `${this.basePath}/update/draft-location/${instance.externalId}`,
      instance
    );
  }
  getBikesAvailableToReserve(
    page: number,
    pageSize: number,
    location: Location,
    startDate: Date,
    endDate: Date,
    filters: BikeFilters
  ): Promise<ApiResponse<IPage<Bike>>> {
    const bikeTypes = filters.bikeTypes.map((o) => o.values).flat();
    const bikeSizes = filters.bikeSizes.map((o) => o.values).flat();
    const hideUnavailable = filters.hideUnavailable;

    return httpClient.post(
      `bikes/search`,
      {
        page: page,
        pageSize: pageSize,
        latitude: location.latitude,
        longitude: location.longitude,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        hideUnavailable: hideUnavailable,
        bikeTypes: bikeTypes.length > 0 ? bikeTypes : undefined,
        bikeSizes: bikeSizes.length > 0 ? bikeSizes : undefined,
      },
      true
    );
  }

  getBikeListing(
    page: number,
    pageSize: number,
    filter: string
  ): Promise<ApiResponse<IPage<Bike>>> {
    return httpClient.get<IPage<Bike>>(
      `${this.basePath}/listings?page=${page}&pageSize=${pageSize}&bikeTitle=${filter}`,
      true
    );
  }

  checkIfUserHasListedBikes(): Promise<ApiResponse<boolean>> {
    return httpClient.get(`${this.basePath}/listings/exists`);
  }

  startCreationBike(location: PdlLocation): Promise<ApiResponse<Bike>> {
    return httpClient.post(`${this.basePath}/start-creation-bike`, location);
  }

  secondStepCreationBike(
    externalId: string,
    bikeDetails: BikeSecondStepCreation
  ): Promise<ApiResponse<Bike>> {
    return httpClient.put(`${this.basePath}/${externalId}/second-step-creation-bike`, bikeDetails);
  }

  thirdStepCreationBike(externalId: string): Promise<ApiResponse<Bike>> {
    return httpClient.put(`${this.basePath}/${externalId}/third-step-creation-bike`, {});
  }

  submitBike(externalId: string): Promise<ApiResponse<Bike>> {
    return httpClient.put(`${this.basePath}/${externalId}/submit`, {});
  }

  activateBike(
    externalId: string,
    bikePriceInfoCreation: BikePriceInfoCreation
  ): Promise<ApiResponse<Bike>> {
    return httpClient.put(`${this.basePath}/${externalId}/activate`, bikePriceInfoCreation);
  }

  getDraftBike(): Promise<ApiResponse<Bike>> {
    return httpClient.get(`${this.basePath}/draft-bike`);
  }

  deleteDraftBike(): Promise<ApiResponse<Boolean>> {
    return httpClient.post(`${this.basePath}/draft-bike/disable`, Boolean, true);
  }

  async addToWishList(bikeExternalId: string): Promise<ApiResponse<boolean>> {
    return await httpClient.post(`${this.basePath}/wishlist/${bikeExternalId}`, Boolean, true);
  }

  async getWishList(page: number, pageSize: number): Promise<ApiResponse<PagedResult<Bike>>> {
    return await httpClient.get<PagedResult<Bike>>(
      `${this.basePath}/wishlist?page=${page}&pageSize=${pageSize}`
    );
  }

  async getWishListForHomePage(): Promise<ApiResponseV2<Bike[]>> {
    return await HttpClientV2.get<Bike[]>(`${this.basePath}/wishlist-for-home`);
  }

  getRecentlyViewedBikes(): Promise<ApiResponse<Bike[]>> {
    return httpClient.get(`${this.basePath}/recently-viewed`, true);
  }

  getRecentlyViewedBikesForHomePage(): Promise<ApiResponse<Bike[]>> {
    return httpClient.get(`${this.basePath}/recently-viewed-for-home`, true);
  }

  addToRecentlyViewed(externalId: string): Promise<ApiResponse<boolean>> {
    return httpClient.post(`${this.basePath}/recently-viewed/${externalId}`, {});
  }

  async getBlockoutDates(externalId: string): Promise<ApiResponse<BlockoutDates[]>> {
    return await httpClient.get(`${this.basePath}/blockout-dates/${externalId}`, true);
  }

  addBlockoutDates(externalId: string, dates: BlockoutDateCreation): Promise<ApiResponse<String>> {
    return httpClient.post(`${this.basePath}/blockout-dates/${externalId}`, dates, true);
  }

  deactivateBike(externalId: string): Promise<ApiResponse<Bike>> {
    return httpClient.post(`${this.basePath}/${externalId}/deactivate`, {});
  }

  updateTieredPrice(
    bikeExternalId: string,
    bikeTieredPriceData: TieredPriceForm
  ): Promise<ApiResponseV2<string>> {
    return HttpClientV2.put(`${this.basePath}/tiered-price/${bikeExternalId}`, bikeTieredPriceData);
  }
}

export default new BikeService(`${environment.baseURL}/bikes`);
