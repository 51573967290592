import ObservableService from "../core/ObservableService";
import qs from "qs";
import axios from "axios";
import User from "../core/User";
import environment from "../../../environment.json";

export enum AuthEvents {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
}

// this class can be extended to support more errors
export enum LoginError {
  NOT_AUTHORIZED,
}

const loginErrorMap: { [key: string]: LoginError } = {
  "not-authorized": LoginError.NOT_AUTHORIZED,
};

// variable in the QS that will be used to pass the token from other part of the app
// e.g. OAuth2 flow
const TOKEN_QUERY_STRING_PROPERTY = "t";

// variable in the QS that will be used to pass metadata related to the user that is logged 
// or just logged in - e.g. via the OAuth2 flow
const USER_METADATA_PROPERTY = "u";

// if the user is not logged already, it will be redirected to this path
const PATH_TO_REDIRECT_NOT_LOGGED_USER = (environment as any).pathToRedirectNotLoggedUser || "/";

// if the user is logged already, it will be redirected to this path
const PATH_TO_REDIRECT_LOGGED_USER = "/home";

// if the user is not authorized, it will be redirected to this path
const NOT_AUTHORIZED_URL = "/?error=not-authorized";

// if true, we redirect the user to HTTPS if HTTP is used
const FORCE_HTTPS = (environment as any).forceHTTPS == undefined ? true : (environment as any).forceHTTPS;

// paths that will be allowed is the user is not logged
const NOT_LOGGED_PATHS = ["/", "/register", (environment as any).pathToRedirectNotLoggedUser];

const inDevMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

class AuthService extends ObservableService<AuthEvents> {
  public getToken(): string | null {
    return localStorage.getItem("t");
  }

  public storeToken(token: string, tokenExpiration?: Date) {
    localStorage.setItem("t", token);

    if (tokenExpiration) {
      localStorage.setItem("t_expiration", tokenExpiration.toString());
    }

    if (!!token) {
      this.notifyObservers(AuthEvents.LOGIN);
    }
  }

  public storeUserMetadata(metadata: string) {
    if (!!metadata && metadata != "null") {
      // metadata is a base64-encoded JSON
      // warning! using `atob` to decode the base64 string may broke special characters
      // we use this workaround to convert base64 strings to UTF8 without any charset problem
      // from: https://stackoverflow.com/a/30106551
      // Going backwards: from bytestream, to percent-encoding, to original string.
      const decodedMetadata = decodeURIComponent(atob(metadata).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      localStorage.setItem("r", decodedMetadata);
    }
  }

  public getExternalId(): string | null {
    return localStorage.getItem("e");
  }

  public getUser(): User {
    const userStored: string | null = localStorage.getItem("r");
    return userStored ? JSON.parse(userStored) : undefined;
  }

  public hasRole(roleName: string): boolean {
    const user: User | undefined = this.getUser();

    if (user) {
      return user.roles.some((role) => role.name === roleName);
    }

    return false;
  }

  public hasAnyRole(roleNames: string[], loggedUser?: User): boolean {
    const user: User | undefined = loggedUser || this.getUser();

    if (user) {
      return user.roles.some((role) => roleNames.includes(role.name));
    }

    return false;
  }

  public getTokenExpiration(): Date | null {
    const tokenExpiration: string | null = localStorage.getItem("t_expiration");

    if (tokenExpiration) {
      return new Date(tokenExpiration);
    }

    return null;
  }

  public storeExternalId(externalId: string) {
    localStorage.setItem("e", externalId);
  }

  public storeUser(user: User) {
    localStorage.setItem("r", JSON.stringify(user));
  }

  public logOut() {
    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("t");
    localStorage.removeItem("r");
    localStorage.removeItem("t_expiration");
    this.notifyObservers(AuthEvents.LOGOUT);
  }

  public isLogged = () => {
    const tokenExpiration = this.getTokenExpiration();

    if (tokenExpiration) {
      return (
        !!this.getToken() && tokenExpiration.getTime() > new Date().getTime()
      );
    }

    return !!this.getToken();
  };

  /**
   * This method takes care of processing external tokens, redirecting the user properly depending on their
   * login state, etc. The result of executing this method in most cases results in a redirect or a noop
   */
  public redirectUserIfNeededDependingOnLoginState() {
    // Auth token is provided, we store the token and navigate to user home screen
    const qsParams = qs.parse(window.location.search.substring(1));
    const newToken = qsParams[TOKEN_QUERY_STRING_PROPERTY] as string;
    const userMetadata = qsParams[USER_METADATA_PROPERTY] as string;

    var accessError = false;

    if (FORCE_HTTPS && window.location.protocol === "http:" && !inDevMode) {
      window.location.href = window.location.href.replace("http:", "https:");
    }

    if (newToken !== undefined) {
      if (newToken.length === 0 || newToken === "null") {
        accessError = true;
      } else {
        this.storeUserMetadata(userMetadata)
        this.storeToken(newToken);
      }
    }

    if (accessError) {
      window.location.href = NOT_AUTHORIZED_URL;
    } else {
      // User is not logged and tried to access a non-public part of the app, we navigate to
      // home to let the user log in
      if (
        !NOT_LOGGED_PATHS.includes(window.location.pathname) &&
        !this.isLogged()
      ) {
        window.location.href = PATH_TO_REDIRECT_NOT_LOGGED_USER;
      }

      // If user is logged in and it is in the home screen, then we redirect it to the
      // personal data UI
      if (
        NOT_LOGGED_PATHS.includes(window.location.pathname) &&
        this.isLogged()
      ) {
        window.location.href = PATH_TO_REDIRECT_LOGGED_USER;
      }
    }
  }

  /**
   * Detects if, depending on the URL or other state, a login error just ocurred, so it can be managed properly
   */
  public loginErrorJustHappened(): LoginError | undefined {
    const qsParams = qs.parse(window.location.search.substring(1));
    const error = qsParams.error as string;

    if (error) {
      return loginErrorMap[error];
    }

    return undefined;
  }
}

export default new AuthService();
