import React, { useState, useRef } from 'react';
import { FluidContainer } from '../../commons/FluidContainer';
import AppTheme from '../../../utils/AppTheme';
import { AppAssets } from '../../../assets/index';
import { Box, ButtonBase, Container, Grid, Grow } from '@mui/material';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import SimpleButton from '../../commons/SimpleButton';
import { IAppTheme } from '../../../utils/AppTheme';
import { makeStyles } from '@mui/styles';
import WaitlistService from '../../../services/WaitlistService';
import { AppTextField } from '../../commons/AppTextField';
import { FeatureItem } from '../../Waitlist/FeatureItem';
import { SxStyles } from '../../../model/utils/SxStyles';
import { RequestStatus } from '../../../utils/RequestStatus';
import useMediaQuery from '@mui/material/useMediaQuery';
import AlertService from '../../../services/AlertService';
import ReCAPTCHA from 'react-google-recaptcha';
import { ErrorCode } from '../../../types/ErrorCode';
import environment from '../../../environment.json';
import { WaitListUserForm } from '../../../types/WaitListUserForm';
import { AppFontFamily } from '../../../utils/AppFontFamily';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import AppImage from '../../../external/pdl-common/components/commons/AppImage';
import LandingPageNavBar from '../../core/LandingPageNavBar';

export const WaitlistHome = () => {
  const classes = useStyles();
  const waitlistRef = useRef<any>();

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const breakpointCardHeight = useMediaQuery('(min-height: 880px)');
  const showTopCard = useMediaQuery('(min-height: 770px)');
  const showBottomCard = useMediaQuery('(min-height: 490px)');

  const breakpointHeight = useMediaQuery('(min-width: 568px)');
  const breakpointCardWidth = useMediaQuery('(max-width: 1200px)');
  const breakpointCardSizes = useMediaQuery('(min-width: 600px) and (max-width: 1200px)');

  const [form, setForm] = useState<WaitListUserForm>({
    firstname: '',
    lastname: '',
    email: '',
    bike: '',
    recaptchaToken: '',
  });
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);
  const [hideErrorText, setHideErrorText] = useState<boolean>(true);

  const [validations, setValidations] = useState<{ [P in keyof WaitListUserForm]: boolean }>({
    firstname: false,
    lastname: false,
    email: false,
    bike: false,
    recaptchaToken: false,
  });

  const handleOnCaptchaTokenChange = (token: string | null) => {
    setForm({ ...form, recaptchaToken: token || '' });
    setValidations({ ...validations, recaptchaToken: !!token });
  };

  const formIsValid = () => {
    return !Object.values(validations).includes(false);
  };

  const handleCustomChange = (inputName: string, inputValue: string, isValid: boolean) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);

    let updatedValidations = { ...validations };
    (updatedValidations as any)[inputName] = isValid;
    setValidations(updatedValidations);
  };

  const handleOnSubmit = async () => {
    if (!formIsValid()) {
      setHideErrorText(false);
      AlertService.showSnackCustomError('Please complete all the fields');
      return;
    }

    try {
      setRequestStatus(RequestStatus.LOADING);

      const response = await WaitlistService.create(form);

      setRequestStatus(RequestStatus.SUCCESS);
      setShowSuccessMsg(!!response);
      setForm({ firstname: '', lastname: '', email: '', bike: '', recaptchaToken: '' });
    } catch (error: any) {
      let err = '';

      if (error?.response?.status === 409) {
        err = 'The email is already registered';
      } else if (
        error?.response?.status === 400 &&
        error?.response?.data?.errorCode == ErrorCode.INVALID_CAPTCHA
      ) {
        err = error?.response?.data?.message;
      } else {
        err = 'Failed to join waitlist';
      }

      setRequestStatus(RequestStatus.ERROR);
      AlertService.showSnackCustomError(err);
    } finally {
      (window as any).grecaptcha.reset();
    }
  };

  const getHeroSection = () => (
    <FluidContainer className={`${classes.darkContainer} ${classes.mainModule}`}>
      <Container maxWidth={'xl'} style={{ position: 'relative', overflow: 'hidden' }}>
        {/* Content */}
        <Box className={`${classes.container} ${classes.mainModule}`}>
          <Box mb={'2.5em'} sx={{ zIndex: 999 }}>
            <AppAssets.PDL_CLUB fill="white" />
          </Box>

          <Box mb={'1.5em'} textAlign={'center'}>
            <AppTypography
              type={PDLTypography.largeHeading}
              children={'Keep Riding.'}
              customStyles={{
                color: AppTheme.colors.white_FFFFFF,
                fontSize: breakpointHeight ? '5em' : '3.125em',
              }}
            />
          </Box>

          <Box textAlign={'center'} mb={'2.5em'} sx={muiStyles.container}>
            <AppTypography
              type={PDLTypography.largeHeading}
              children={'Peer-to-peer performance bike rentals.'}
              customStyles={{
                color: AppTheme.colors.white_FFFFFF,
                fontSize: '1.5em',
              }}
            />

            <SimpleButton
              children={'Hop On The Waitlist'}
              onClick={() => waitlistRef.current?.scrollIntoView({ behavior: 'smooth' })}
              sx={{ width: breakpointHeight ? '60%' : '85%', mt: 4 }}
              fontFamily={AppFontFamily.SHAPE}
            />
          </Box>
        </Box>

        {/* Cards: With absolute position, for sizes larger than 1200px */}
        {!breakpointCardWidth && (
          <>
            <Grow in={true} timeout={2100}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '28%',
                  left: '8.47%',
                }}
              >
                <AppImage
                  src={AppAssets.WaitlistAboutCardBike}
                  alt="Bike card"
                  style={{ height: 515, width: 256 }}
                />
              </Box>
            </Grow>

            {showTopCard && (
              <Grow in={true} timeout={2100}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: breakpointCardHeight ? '-6.77%' : '-15%',
                    left: '32.60%',
                  }}
                >
                  <AppImage
                    src={AppAssets.WaitlistAboutCardSearch}
                    alt="Search card"
                    style={{ height: 311, width: 255 }}
                  />
                </Box>
              </Grow>
            )}

            {showBottomCard && (
              <Grow in={true} timeout={2100}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '80.33%',
                    left: '37.64%',
                  }}
                >
                  <AppImage
                    src={AppAssets.WaitlistCardDestinations}
                    alt="Search card"
                    style={{ height: 249, width: 256 }}
                  />
                </Box>
              </Grow>
            )}

            <Grow in={true} timeout={2100}>
              <Box sx={{ position: 'absolute', top: '66%', left: '70.63%' }}>
                <AppImage
                  src={AppAssets.WaitlistAboutCardProfile}
                  alt="Search card"
                  style={{ height: 445, width: 256 }}
                />
              </Box>
            </Grow>

            <Grow in={true} timeout={2100}>
              <Box sx={{ position: 'absolute', top: '9.11%', left: '78.68%' }}>
                <AppImage
                  src={AppAssets.WaitlistCardListing}
                  alt="Search card"
                  style={{ height: 324, width: 251 }}
                />
              </Box>
            </Grow>
          </>
        )}

        {/* Cards: We change the layout from 1200 px wide */}
        {breakpointCardWidth && (
          <Box display={'flex'} justifyContent={'center'}>
            <Grid maxWidth={'sm'} container spacing={2}>
              <Grow in={true} timeout={2100}>
                <Grid item xs={6}>
                  <AppImage
                    src={AppAssets.WaitlistAboutCardBike}
                    alt="Bike card"
                    style={{
                      width: breakpointCardSizes ? '80%' : '100%',
                      position: 'relative',
                      top: '25%',
                    }}
                  />
                </Grid>
              </Grow>

              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grow in={true} timeout={2100}>
                    <Grid item>
                      <AppImage
                        src={AppAssets.WaitlistAboutCardSearch}
                        alt="Search card"
                        style={{ width: breakpointCardSizes ? '80%' : '100%' }}
                      />
                    </Grid>
                  </Grow>
                  <Grow in={true} timeout={2100}>
                    <Grid item>
                      <AppImage
                        src={AppAssets.WaitlistCardDestinations}
                        alt="Search card"
                        style={{
                          width: breakpointCardSizes ? '80%' : '100%',
                          marginLeft: '.6em',
                          marginBottom: '.5em',
                        }}
                      />
                    </Grid>
                  </Grow>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </FluidContainer>
  );

  const getFeaturesSection = () => (
    <FluidContainer className={classes.lightContainer}>
      <Container maxWidth={'lg'} className={classes.container}>
        <Grid container px={{ xs: '2.5em' }}>
          <Grid
            item
            xl={4}
            lg={4}
            sm={12}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', xl: 'flex-start' },
              mb: { xs: '2.5em' },
            }}
          >
            <AppImage src={AppAssets.WaitlistBike} alt="Feature" style={{ width: '256px' }} />
          </Grid>

          <Grid item xl={8} lg={8} sm={12} xs={12}>
            <Box sx={{ mb: '2.5em' }}>
              <AppTypography
                type={PDLTypography.smallHeading}
                children={'Features'}
                customStyles={{ fontSize: '3em' }}
              />
            </Box>

            <Grid container spacing={6}>
              <FeatureItem
                title={'Explore new cities'}
                description={'Keep your cadence, no matter where the road takes you.'}
                icon={<AppAssets.Message />}
              />

              <FeatureItem
                title={'Make money hosting'}
                description={
                  'Rent out your bike and share your local expertise with other passionate riders who visit your city.'
                }
                icon={<AppAssets.Dollar />}
              />

              <FeatureItem
                title={'Test ride bikes'}
                description={`Pedal a mile on someone else's bike and find your perfect ride.`}
                icon={<AppAssets.BikeActive />}
              />

              <FeatureItem
                title={'Meet other cyclists'}
                description={'Make connections, swap routes, and make life-long friends.'}
                icon={<AppAssets.User />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </FluidContainer>
  );

  const getJoinWaitlist = () => (
    <FluidContainer className={classes.darkContainer}>
      <Container
        maxWidth={'md'}
        className={classes.container}
        ref={waitlistRef}
        sx={{
          pb: { xs: '0', xl: '8em' },
          pt: { xl: '6em', lg: '6em', xs: '2em' },
          px: { xs: '2.5em' },
        }}
      >
        {showSuccessMsg ? (
          <>
            <Box mb={'2.2em'} textAlign={'center'}>
              <AppTypography
                type={PDLTypography.largeHeading}
                children={'Thanks for registering!'}
                customStyles={{
                  color: AppTheme.colors.white_FFFFFF,
                  fontSize: breakpointHeight ? '3.125em' : '2.2em',
                }}
              />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Box sx={{ mb: '1.3em' }}>
                <AppTypography
                  type={PDLTypography.largeHeading}
                  children={'Get your ride on, sooner than you think.'}
                  customStyles={{
                    color: AppTheme.colors.white_FFFFFF,
                    fontSize: breakpointHeight ? '1.5em' : '1.4em',
                  }}
                />
              </Box>

              <Box sx={{ pb: { xs: '15em' } }}>
                <>
                  <br />
                  <AppTypography
                    type={PDLTypography.paragraph}
                    children={"We'll be in touch as soon as the app is live,"}
                    customStyles={{ color: AppTheme.colors.white_FFFFFF }}
                  />
                  <br />
                  <AppTypography
                    type={PDLTypography.paragraph}
                    children={"and you'll be one of the first to sign up!"}
                    customStyles={{ color: AppTheme.colors.white_FFFFFF }}
                  />
                </>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box mb={'2.2em'} textAlign={'center'}>
              <AppTypography
                type={PDLTypography.largeHeading}
                children={'Join the waitlist'}
                customStyles={{
                  color: AppTheme.colors.white_FFFFFF,
                  fontSize: breakpointHeight ? '3.75em' : '2.2em',
                }}
              />
            </Box>
            <Box textAlign={'center'} mb={'2.8em'}>
              <AppTypography
                type={PDLTypography.largeHeading}
                children={'Be the first to create an account.'}
                customStyles={{
                  color: AppTheme.colors.white_FFFFFF,
                  fontSize: breakpointHeight ? '1.5em' : '1.4em',
                }}
              />
            </Box>

            <Box className={classes.formContainer}>
              <Box sx={{ mb: '1.5em' }}>
                <AppTextField
                  name={'firstname'}
                  label={'First Name'}
                  value={form.firstname}
                  onInputChange={handleCustomChange}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  inputProps={{ sx: muiStyles.input }}
                  realTimeValidation
                  required
                  minLength={3}
                  hideErrorText={hideErrorText}
                  validateOnSubmit
                  restrict="onlyLettersAndSpecialCharacters"
                />
              </Box>

              <Box sx={{ mb: '1.5em' }}>
                <AppTextField
                  name={'lastname'}
                  label={'Last Name'}
                  value={form.lastname}
                  onInputChange={handleCustomChange}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  inputProps={{ sx: muiStyles.input }}
                  realTimeValidation
                  required
                  minLength={3}
                  hideErrorText={hideErrorText}
                  validateOnSubmit
                  restrict="onlyLettersAndSpecialCharacters"
                />
              </Box>

              <Box sx={{ mb: '1.5em' }}>
                <AppTextField
                  name={'email'}
                  label={'Email'}
                  value={form.email}
                  onInputChange={handleCustomChange}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  inputProps={{ sx: muiStyles.input, type: 'email' }}
                  realTimeValidation
                  required
                  restrict="email"
                  hideErrorText={hideErrorText}
                  validateOnSubmit
                />
              </Box>

              <Box sx={{ mb: '1.5em' }}>
                <AppTextField
                  name={'bike'}
                  label={'What bike do you own?'}
                  value={form.bike}
                  onInputChange={handleCustomChange}
                  labelProps={{ customClasses: classes.label, type: PDLTypography.smallHeading }}
                  inputProps={{ sx: muiStyles.input }}
                  realTimeValidation
                  required
                  hideErrorText={hideErrorText}
                  validateOnSubmit
                />
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '1.5em',
                  flexDirection: 'column',
                }}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={environment.recaptchaSiteKey}
                  onChange={handleOnCaptchaTokenChange}
                />

                {!hideErrorText && !validations.recaptchaToken && (
                  <AppTypography
                    type={PDLTypography.smallParagraph}
                    customClasses={classes.errorText}
                  >
                    Required field
                  </AppTypography>
                )}
              </Box>

              <SimpleButton
                children={'Join The Waitlist'}
                onClick={handleOnSubmit}
                isLoading={requestStatus === RequestStatus.LOADING}
                fontFamily={AppFontFamily.SHAPE}
                style={{ zIndex: 999 }}
              />
            </Box>
          </>
        )}

        <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
            <AppTypography
              type={PDLTypography.paragraph}
              children={`© 2021 PDL Club. All rights reserved.`}
              customStyles={{ color: AppTheme.colors.white_FFFFFF }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <ButtonBase
            title="Instagram"
            onClick={() => window.open('https://instagram.com/joinpdl', '_blank')}
            sx={{ width: '60px' }}
          >
            <InstagramIcon sx={{ color: 'white' }} />
          </ButtonBase>
          <ButtonBase
            title="Twitter"
            onClick={() => window.open('https://twitter.com/joinpdl', '_blank')}
            sx={{ width: '60px' }}
          >
            <TwitterIcon sx={{ color: 'white' }} />
          </ButtonBase>
        </Box>
      </Container>
    </FluidContainer>
  );

  return (
    <>
      <LandingPageNavBar />
      {getHeroSection()}
      {getFeaturesSection()}
      {getJoinWaitlist()}
    </>
  );
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    [theme.breakpoints.down('xl')]: {
      minHeight: 'auto',
      height: 'auto',
      marginTop: '5em',
      marginBottom: '5em',
    },
  },
  mainModule: {
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
      justifyContent: 'flex-start',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: '1em',
      overflow: 'hidden',
    },
  },
  darkContainer: {
    backgroundColor: theme.colors.black_01000E,
  },
  lightContainer: {
    backgroundColor: theme.colors.white_FFFFFF,
  },
  formContainer: {
    width: '55%',
    maxWidth: '20.38em',
    marginBottom: '4em',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.down(415)]: {
      width: '100%',
    },
  },
  label: {
    color: AppTheme.colors.white_FFFFFF,
    margin: 0,
    marginBottom: '.5em',
    fontSize: '.813em',
  },
  errorText: {
    marginTop: theme.spacing(1),
    color: 'red',
    alignSelf: 'flex-start',
    marginLeft: '1em',
  },
}));

const muiStyles: SxStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    '.MuiInputBase-input': {
      backgroundColor: AppTheme.colors.white_FFFFFF,
    },
  },
};
