import { Check, Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import AppImage from '../../external/pdl-common/components/commons/AppImage';

interface Props {
  rotateMessage: boolean;
  fullScreen: boolean;
  src: string;
  acceptOrRejectImage: (accepted: boolean) => void;
}

const useStyles = makeStyles({
  acceptOrRejectSection: {
    display: 'flex',
    flexDirection: 'row',
    background: 'white',
  },
  spacing: {
    marginRight: '1em',
  },
  button: {
    textTransform: 'none',
    width: '60%',
    m: '0 auto',
  },
  control: {
    position: 'fixed',
    display: 'flex',
    right: '0',
    width: '20%',
    minWidth: '130px',
    minHeight: '130px',
    height: '100%',
    background: 'black',
    zIndex: '10',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '50px',
    boxSizing: 'border-box',
    flexDirection: 'column-reverse',
    '@media (max-aspect-ratio: 1/1)': {
      flexDirection: 'row',
      bottom: '0',
      width: '100%',
      height: '20%',
    },
    '@media (max-width: 400px)': {
      padding: '5em'
    }
  },
  mainContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '80%',
    height: '100%',
    zIndex: 1,
    '@media (max-aspect-ratio: 1/1)': {
      flexDirection: 'row',
      bottom: '0',
      width: '100%',
      height: '80%',
    },
  },
  imgPreview: {
    width: '80%',
    '@media (max-aspect-ratio: 1/1)': {
      width: '100%',
    },
  },
  rotateMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'grey',
    color: 'white',
    minHeight: '100%',
    fontSize: '2em',
  }
});

export const ImagePreviewPro: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  let classNameFullscreen = props.fullScreen ? '' : '';

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.control}>
          <div
            onClick={() => props.acceptOrRejectImage(true)}
          >
            <Check style={{ fill: 'white' }} fontSize="large" />
          </div>
          <div
            onClick={() => props.acceptOrRejectImage(false)}
          >
            <Close style={{ fill: 'white' }} fontSize="large" />
          </div>
        </div>
        {props.rotateMessage ?
          (<div className={classes.rotateMessage}>
            Please rorate your device
          </div>) : (
            <div className={'demo-image-preview ' + classNameFullscreen}>
              < AppImage src={props.src} className={classes.imgPreview} alt="Preview" />
            </div>
          )}
      </div>

    </>
  );
};