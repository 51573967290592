import { useState, useEffect } from 'react';
import { Alert, Snackbar } from '@mui/material';
import AlertService from '../../services/AlertService';
import AlertEvents from '../../services/Events/AlertEvents';

export enum SnackbarSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface SnackBarData {
  text: string;
  severity: SnackbarSeverity;
}

export const SnackbarComponent = () => {
  const [open, setOpen] = useState(false);
  const [snackData, setSnackData] = useState<SnackBarData>({
    text: '',
    severity: SnackbarSeverity.ERROR,
  });

  useEffect(() => {
    AlertService.subscribe(AlertEvents.SNACK_BAR_SHOW_ALERT, showAlert);
    return () => AlertService.unsubscribe(AlertEvents.SNACK_BAR_SHOW_ALERT, showAlert);
  }, []);

  const showAlert = (data: SnackBarData) => {
    setSnackData(data);
    setOpen(true);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={() => setOpen(false)}
    >
      <Alert elevation={6} variant="filled" severity={snackData.severity}>
        {snackData.text}
      </Alert>
    </Snackbar>
  );
};
