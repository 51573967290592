import { Cameraswitch } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { ImagePreview } from './ImagePreview';

interface Props {
  show: boolean;
  onImageUploaded: (base64Image: string) => void;
  onClose: () => void;
}

export const AppCamera: React.FunctionComponent<Props> = (props) => {
  const [dataURI, setDataURI] = useState('');
  const [error, setError] = useState(false);
  const [toggleCamera, setToggleCamera] = useState(false);

  const [isLandscape, setIsLandscape] = useState<boolean>();

  useEffect(() => {
    if (!!props.show && dataURI === '') {
      const elem = document.querySelectorAll('.react-html5-camera-photo>video')[0];

      elem.setAttribute('style', isLandscape ? 'width: 100%; height: 200px' : 'width: 100%');
    }
  }, [props.show, dataURI, isLandscape]);

  useEffect(() => {
    window.addEventListener('resize', viewportOrientation);
    return () => window.removeEventListener('resize', viewportOrientation);
  }, []);

  const viewportOrientation = () => {
    setIsLandscape(document.body.clientWidth > document.body.clientHeight);
  };

  function handleTakePhoto(dataUri: any) {
    setDataURI(dataUri);
  }

  function addContent(accepted: boolean) {
    if (accepted) {
      props.onImageUploaded(dataURI);
      setDataURI('');
      props.onClose();
    } else {
      setDataURI('');
    }
  }

  if (!props.show) {
    return null;
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', margin: '2em 0em' }}>
        <AppTypography type={PDLTypography.smallHeading}>An error occurred</AppTypography>
      </div>
    );
  }

  switch (dataURI) {
    case '':
      return (
        <>
          <Camera
            onTakePhoto={(dataUri) => {
              handleTakePhoto(dataUri);
            }}
            onCameraError={(e) => {
              setError(true);
            }}
            idealFacingMode={toggleCamera ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT}
            isImageMirror={false}
          //idealResolution={{ width: 1280, height: 1280 }}
          //imageType={'jpg'}
          //imageCompression={0.94}
          />
          <div
            style={{
              position: 'absolute',
              bottom: 30,
              left: 'calc(50% + 65px)',
              cursor: 'pointer',
            }}
            onClick={() => setToggleCamera(!toggleCamera)}
          >
            <Cameraswitch style={{ fill: 'white' }} fontSize="large" />
          </div>
        </>
      );
    default:
      return (
        <ImagePreview
          src={dataURI}
          acceptOrRejectImage={(accepted: boolean) => addContent(accepted)}
        />
      );
  }
};
