import { Divider, Container, Avatar, Tooltip, Button } from '@mui/material';
import AppHeader from '../commons/AppHeader';
import { AppLayout } from '../core/AppLayout';
import { makeStyles } from '@mui/styles';
import UserService from '../../services/UserService';
import { useEffect, useState } from 'react';
import Logger from '../../external/pdl-common/utils/Logger';
import AuthService from '../../external/fox-typescript/services/AuthService';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import AppMenuList, { Menu } from '../commons/AppMenuList';
import LogOutBottomSheet from '../Account/LogOutBottomSheet';
import { FullUser } from '../../external/pdl-common/model/FullUser';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import StarsAndRides from '../commons/StarsAndRides';

interface Props {
  setIsLogged: (value: boolean) => void;
}

const logger = new Logger('Account');

export default function Account({ setIsLogged }: Props) {
  const navigate = useNavigate();
  const classes = useStyles();

  const [user, setUser] = useState<FullUser | undefined>(undefined);
  const [bottomSheetVisible, setBottomSheetVisible] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await UserService.getCurrentUser())
      .onSuccess((response) => {
        setUser({ ...response.getContent(), password: 'dfoioprkewpokfpoiewoe' });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error fetching user', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const logOut = () => {
    AuthService.logOut();
    setIsLogged(false);
    appNavigate(navigate, AppRoutes.HOME, null);
  };

  const menus: Menu[] = [
    {
      text: 'View reviews',
      action: () => appNavigate(navigate, AppRoutes.ACCOUNT_RATINGS, null, { state: { user } }),
    },
    {
      text: 'Personal information',
      action: () =>
        appNavigate(navigate, AppRoutes.PERSONAL_INFORMATION, null, { state: { user } }),
    },
    {
      text: 'My measurements',
      action: () => appNavigate(navigate, AppRoutes.MY_MEASUREMENTS, null, { state: { user } }),
    },
    {
      text: 'Invite friends',
      action: () =>
        appNavigate(navigate, AppRoutes.INVITE_FRIENDS, null, {
          state: { inviteCode: user?.inviteCode },
        }),
    },
    {
      text: 'Payment details',
      action: () => appNavigate(navigate, AppRoutes.PAYMENT_DETAILS, null),
    },
    {
      text: 'Help center',
      action: () => appNavigate(navigate, AppRoutes.HELP_CENTER, null),
    },
    {
      text: 'Contact PDL Team',
      action: () => appNavigate(navigate, AppRoutes.REPORT_ISSUE, null, { state: null }),
    },
    {
      text: 'Log out',
      action: () => setBottomSheetVisible(true),
    },
  ];

  const getContent = (): JSX.Element => {
    if (requestStatus && requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ height: '70vh' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={getUser} />
        </div>
      );
    }

    return user ? (
      <>
        <Container>
          <StarsAndRides user={user} tooltip />
          <Divider />
          <AppMenuList menus={menus} />
        </Container>
        <LogOutBottomSheet
          open={bottomSheetVisible}
          onClose={() => setBottomSheetVisible(false)}
          onClick={logOut}
        />
      </>
    ) : (
      <></>
    );
  };

  // Render
  return <AppLayout header={() => <AppHeader content="Account" showBackButton={false} />} content={() => getContent()} fromHome />;
}

const useStyles = makeStyles({
  userName: {
    margin: 0,
    marginTop: '0.625em',
    marginBottom: '0.313em',
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '1.875em',
    paddingBottom: '1.875em',
  },
  userInfoTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
