import { InputAdornment, InputBaseProps } from '@mui/material';
import InputBase from '@mui/material/InputBase/InputBase';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { IAppTheme } from '../../utils/AppTheme';
import { AppTypographyProps, AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import environment from '../../environment.json';

export interface AppTextFieldProps {
  label?: string;
  name: string;
  value: string;
  onInputChange: (inputName: string, inputValue: string, isValid: boolean) => void;
  inputProps?: Omit<InputBaseProps, 'name' | 'onChange' | 'value'>;
  labelProps?: AppTypographyProps;
  containerClasses?: string;
  submitIcon?: React.ReactNode;
  helperText?: string;
  helperTextProps?: AppTypographyProps;
  realTimeValidation?: boolean;
  customLengthValidationMessage?: string;
  required?: boolean;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  restrict?:
    | 'onlyNumbers'
    | 'onlyNumbersAndDots'
    | 'onlyLetters'
    | 'onlyNumbersAndLetters'
    | 'onlyNumbersLettersAndSpaces'
    | 'onlyLettersAndSpecialCharacters'
    | 'strongPassword'
    | 'email'
    | 'phoneNumber'
    | 'stateCode'
    | '';
  /** Should return a object that specify if a error occurs (dont passed the validation) and a message for display */
  customValidation?(text?: string): { showError: boolean; message: string; isValid: boolean };
  hideErrorText?: boolean;
  validateOnSubmit?: boolean;
  disableAutoComplete?: boolean;
  /**
   * Function to handle a custom event when a key is pressed
   */
  onKeyPress?: (e: React.KeyboardEvent) => void;
  bottomRightElement?: JSX.Element;
}

const BasicInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #C9CBCA',
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    fontFamily: AppFontFamily.SHAPE + ' !important',
    fontSize: '0.81em',
    fontWeight: 400,
  },
}));

const useStyles = makeStyles((theme: IAppTheme) => ({
  label: {
    marginBottom: theme.spacing(3),
  },
  helperText: {
    marginTop: theme.spacing(1),
  },
  errorText: {
    marginTop: theme.spacing(1),
    color: 'red',
    maxWidth: '25em',
  },
  error: {
    '& input': {
      borderRadius: 20,
      border: '1px solid red !important',
    },
  },
}));

const AppTextField = React.forwardRef<{ validate: () => void }, AppTextFieldProps>(
  (
    {
      labelProps = { type: PDLTypography.smallHeading },
      helperTextProps = { type: PDLTypography.smallParagraph },
      realTimeValidation = false,
      required = false,
      ...props
    }: AppTextFieldProps,
    ref
  ) => {
    const classes = useStyles();

    const [validationStatus, setValidationStatus] = useState({ error: false, message: '' });

    const { label, name, value, inputProps, helperText, restrict } = props;

    const { customClasses: typographyClasses, ...typographyProps } = labelProps;

    useImperativeHandle(ref, () => ({
      validate: validate,
    }));

    useEffect(() => {
      if (!props.hideErrorText && props.validateOnSubmit) {
        validate();
      }
    }, [props.hideErrorText, props.value]);

    const handleInputChange = (inputElement: React.ChangeEvent<HTMLInputElement>) => {
      let { name: inputName, value: inputValue } = inputElement.target;

      if (props.inputProps?.type === 'number' || props.inputProps?.type === 'price') {
        inputValue = inputValue.replace(/\D/g, '');
      }

      if (props.inputProps?.type === 'decimal') {
        inputValue = inputValue.replace(/[^\d.]/g, '');
        inputValue = inputValue
          .split('.')
          .reduce((prev, curr, i) => prev + (i == 1 ? '.' : '') + curr);
      }

      if (props.inputProps?.type === 'email' || restrict === 'email') {
        inputValue = inputValue.toLowerCase();
      }

      let isValid = true;
      if (realTimeValidation || validationStatus.error) {
        isValid = validate({ text: inputValue });
      }

      props.onInputChange(inputName, inputValue, isValid);
    };

    const validate = (data?: { text: string }) => {
      const {
        customValidation,
        minLength,
        maxLength,
        customLengthValidationMessage: customValidationMessage,
      } = props;
      const textValue = data ? data.text : props.value;
      let isValid = true;

      if (required && !textValue) {
        setValidationStatus({
          error: true,
          message: 'Required field',
        });
        return false;
      }

      if (restrict && textValue) {
        switch (restrict) {
          case 'onlyNumbers':
            if (!/^\d+$/.test(textValue)) {
              setValidationStatus({
                error: true,
                message: 'Invalid value',
              });
              return false;
            }
            break;
          case 'onlyNumbersAndDots':
            if (!/^[0-9]*\.?([0-9]{1,2}$)*$/.test(textValue)) {
              setValidationStatus({
                error: true,
                message: 'Invalid value',
              });
              return false;
            }
            break;
          case 'onlyLetters':
            if (!/^[a-zA-Z][a-zA-Z\s]+$/.test(textValue)) {
              setValidationStatus({
                error: true,
                message: 'Invalid value',
              });
              return false;
            }
            break;
          case 'onlyNumbersAndLetters':
            if (!/^[0-9-a-zA-Z]+$/.test(textValue)) {
              setValidationStatus({
                error: true,
                message: 'Invalid value',
              });
              return false;
            }
            break;
          case 'onlyNumbersLettersAndSpaces':
            if (!/^[0-9-a-zA-Z\s]+$/.test(textValue)) {
              setValidationStatus({
                error: true,
                message: 'Invalid value',
              });
              return false;
            }
            break;
          case 'onlyLettersAndSpecialCharacters':
            // Mostly used for names
            if (
              !/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
                textValue
              )
            ) {
              setValidationStatus({
                error: true,
                message: 'Invalid value',
              });
              return false;
            }
            break;
          case 'email':
            // If env != prod add "+" to allowed characters
            const regex =
              environment.env != 'prod'
                ? /^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/
                : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;

            if (!regex.test(textValue)) {
              setValidationStatus({
                error: true,
                message: 'Invalid email',
              });
              return false;
            }
            break;
          case 'strongPassword':
            if (
              !new RegExp('((?=.*[a-z])(?=.*\\d)(?=.*[A-Z]).{8,40})').test(textValue)
            ) {
              setValidationStatus({
                error: true,
                message: 'Invalid Password',
              });
              return false;
            }
            break;
          case 'phoneNumber':
              // This is not good enough, we should be validating mobile phones with a library, see validators.js or react-phone-number-input
             if(!new RegExp('^\\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$').test(textValue)) {
              setValidationStatus({
                error: true,
                message:
                  'Should be a valid phone number, formatted as +XXXXXXXXXX',
              });
              return false;
            }
            break;
          case 'stateCode':
            if (!/^[A-Z][A-Z]$/.test(textValue)) {
              setValidationStatus({
                error: true,
                message: 'State code should be two capital letters',
              });
              return false;
            }
            break;
          default:
            break;
        }
      } else if (restrict && required) {
        isValid = false;
      }

      if (minLength !== undefined && textValue && textValue.length < minLength) {
        setValidationStatus({
          error: true,
          message: customValidationMessage ? customValidationMessage : 'Text too short',
        });
        return false;
      } else if (minLength !== undefined && required && !textValue) {
        isValid = false;
      }

      if (maxLength !== undefined && textValue && textValue.length > maxLength) {
        setValidationStatus({
          error: true,
          message: customValidationMessage ? customValidationMessage : 'Text too long',
        });
        return false;
      } else if (maxLength !== undefined && required && !textValue) {
        isValid = false;
      }

      if (customValidation) {
        const result = customValidation(textValue);
        setValidationStatus({ error: result.showError, message: result.message });
        return result.isValid;
      }

      setValidationStatus({ error: false, message: '' });

      return isValid;
    };

    let basicInputProps = inputProps || {};
    if (props.inputProps?.type === 'number' || props.inputProps?.type === 'price') {
      basicInputProps.inputProps = {
        ...basicInputProps.inputProps,
        inputMode: 'numeric',
        type: 'text',
      };
    }
    if (props.disableAutoComplete) {
      basicInputProps.inputProps = {
        ...basicInputProps.inputProps,
        autoComplete: 'nope',
      };
    }

    return (
      <div
        className={props.containerClasses || ''}
        style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
      >
        {label && (
          <AppTypography
            {...typographyProps}
            customClasses={`${classes.label} ${typographyClasses || ''}`}
          >
            {label}
          </AppTypography>
        )}

        <BasicInput
          disabled={props.disabled}
          autoComplete={'off'}
          name={name}
          onChange={handleInputChange}
          value={value}
          {...basicInputProps}
          classes={{ error: classes.error }}
          startAdornment={
            props.inputProps?.type === 'price' && (
              <InputAdornment position="start">$</InputAdornment>
            )
          }
          error={!props.hideErrorText && validationStatus.error}
          endAdornment={props.submitIcon}
          onKeyDown={(e) => props.onKeyPress && props.onKeyPress(e)}
        />
        {props.bottomRightElement && props.bottomRightElement}
        {validationStatus.error && !props.hideErrorText ? (
          <AppTypography type={PDLTypography.smallParagraph} customClasses={classes.errorText}>
            {validationStatus.message}
          </AppTypography>
        ) : (
          helperText && (
            <AppTypography {...helperTextProps} customClasses={classes.helperText}>
              {helperText}
            </AppTypography>
          )
        )}
      </div>
    );
  }
);

const Memo = React.memo(AppTextField, (oldProps, newProps) => {
  if (
    oldProps.label === newProps.label &&
    oldProps.value === newProps.value &&
    oldProps.name === newProps.name &&
    oldProps.onInputChange === newProps.onInputChange &&
    oldProps.inputProps === newProps.inputProps &&
    oldProps.labelProps === newProps.labelProps &&
    oldProps.containerClasses === newProps.containerClasses &&
    oldProps.helperText === newProps.helperText &&
    oldProps.helperTextProps === newProps.helperTextProps &&
    oldProps.realTimeValidation === newProps.realTimeValidation &&
    oldProps.required === newProps.required &&
    oldProps.minLength === newProps.minLength &&
    oldProps.restrict === newProps.restrict &&
    oldProps.customValidation === newProps.customValidation &&
    oldProps.hideErrorText === newProps.hideErrorText &&
    oldProps.validateOnSubmit === newProps.validateOnSubmit
  ) {
    return true;
  }
  return false;
});
export { Memo as AppTextField };
