import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import {Bike} from "../../external/pdl-common/model/Bike";
import DateUtils from "../../external/pdl-common/utils/DateUtils";
import ListingLink from "./ListingLink";


interface Props {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    title: string;
    bike: Bike | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
    includeInsurance: boolean;
    price: number;
    taxes: number;
}

const getRentalDurationValue = (startDate: Date, endDate: Date) => {
    const amountOfDays = DateUtils.getFullDaysBetweenDates(startDate, endDate);

    if (amountOfDays == 1) {
        return `1 day`
    } else {
        return `${amountOfDays} days`
    }
}

export function ReservationSummaryDialog(props: Props) {
    return (
        <Dialog open={props.open} onClose={props.onCancel}>
            <DialogTitle>
                <AppTypography type={PDLTypography.subHeading}>Reservation Summary</AppTypography>
            </DialogTitle>
            <DialogContent>
                <ListingLink title={props.bike!.title} imageUrl={props.bike!.images && props.bike!.images.length > 0 ? props.bike!.images[0].url : ''}
                     cells={
                        [
                         {
                             label: 'Bike size',
                             value: props.bike!.size,
                         }
                        ]
                     }/>
                <DialogContentText>
                    <AppTypography type={PDLTypography.labelsSmallSerif}>Rental duration: </AppTypography>
                    <AppTypography type={PDLTypography.paragraph}>{getRentalDurationValue(props.startDate!, props.endDate!)}</AppTypography>
                </DialogContentText>
                <DialogContentText>
                    <AppTypography type={PDLTypography.labelsSmallSerif}>Lease Start Date: </AppTypography>
                    <AppTypography type={PDLTypography.paragraph}>{DateUtils.formattedShortDate(props.startDate!)}</AppTypography>
                </DialogContentText>
                <DialogContentText>
                    <AppTypography type={PDLTypography.labelsSmallSerif}>Lease End Date: </AppTypography>
                    <AppTypography type={PDLTypography.paragraph}>{DateUtils.formattedShortDate(props.endDate!)}</AppTypography>
                </DialogContentText>
                <DialogContentText>
                    <AppTypography type={PDLTypography.labelsSmallSerif}>Total Price: </AppTypography>
                    <AppTypography type={PDLTypography.paragraph}>${Math.round((props.price + props.taxes) * 100) / 100}</AppTypography>
                </DialogContentText>
                <DialogContentText>
                    <AppTypography type={PDLTypography.labelsSmallSerif}>Protection Plan: </AppTypography>
                    <AppTypography type={PDLTypography.paragraph}>{props.includeInsurance ? "Standard Protection Plan": "No Protection Plan"}</AppTypography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} style={{ fontFamily: AppFontFamily.INTER }}>
                    Cancel
                </Button>
                <Button onClick={props.onConfirm} style={{ fontFamily: AppFontFamily.INTER }}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
