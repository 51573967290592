import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RenterReview } from '../../../model/RenterReview';
import AppTheme from '../../../utils/AppTheme';
import DateUtils from '../../../utils/DateUtils';
import StringUtils from '../../../utils/StringUtils';
import RoundedImageWithText from '../../RoundedImageWithText';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import AppRating from './AppRating';

interface Props {
  rating: RenterReview;
  showDivider?: boolean;
  showImage?: boolean;
  showDate?: boolean;
  showHeader?: boolean;
}

const BikeRatingComment = ({
  rating,
  showDivider = true,
  showImage = false,
  showDate = true,
  showHeader = true,
}: Props) => {
  const classes = useStyles();

  const { renterUser } = rating;
  const formattedName = StringUtils.formatShortUserName(renterUser.user);

  // Render
  return (
    <>
      <Grid xl={12} xs={12} sx={{ textAlign: 'left' }}>
        {showImage && renterUser.user.avatarUrl ? (
          <RoundedImageWithText
            src={renterUser.user.avatarUrl}
            title={formattedName}
            titleTypography={{
              type: PDLTypography.smallHeading,
              customStyles: { fontSize: '1em' },
              customClasses: classes.mpZero,
            }}
            subtitleTypography={{
              type: PDLTypography.smallParagraph,
              customStyles: { fontSize: '0.813em', color: AppTheme.colors.gray_A8ACAD },
              customClasses: classes.mpZero,
            }}
            subtitle={DateUtils.formattedLongDate(new Date(rating.date))}
          />
        ) : showHeader ? (
          <div className={classes.header}>
            <AppTypography type={PDLTypography.mediumHeading}>{formattedName}</AppTypography>

            {showDate && (
              <AppTypography type={PDLTypography.smallParagraph} customClasses={classes.date}>
                {DateUtils.formattedLongDate(new Date(rating.date))}
              </AppTypography>
            )}
          </div>
        ) : undefined}
        <AppRating value={rating.rating} readOnly />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <AppTypography type={PDLTypography.subHeading} customClasses={classes.discriminator}>
            The bike
          </AppTypography>

          <AppTypography type={PDLTypography.paragraph}>{rating.bikeComment}</AppTypography>

          <AppTypography type={PDLTypography.subHeading} customClasses={classes.discriminator}>
            The host
          </AppTypography>

          <AppTypography type={PDLTypography.paragraph}>{rating.userComment}</AppTypography>
        </div>
      </Grid>
      {showDivider && <Divider sx={{ my: '2.5em' }} />}
    </>
  );
};

export default BikeRatingComment;

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.625em',
    marginBottom: '1.25em',
  },
  date: {
    color: AppTheme.colors.gray_A8ACAD,
  },
  discriminator: {
    color: AppTheme.colors.gray_A8ACAD,
    marginTop: '0.625em',
    marginBottom: '0.625em',
  },
  mpZero: {
    margin: 0,
    padding: 0,
  },
});
