import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReviewEntity from '../../../model/ReviewEntity';
import AppTheme from '../../../utils/AppTheme';
import DateUtils from '../../../utils/DateUtils';
import { UserOrigin } from '../../../utils/enums/UserOrigin';
import StringUtils from '../../../utils/StringUtils';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import AppRating from './AppRating';

interface Props {
  rating: ReviewEntity;
  showDivider?: boolean;
}

const AccountRatingComment = ({ rating, showDivider = true }: Props) => {
  const classes = useStyles();

  const pdlUser = rating.userOrigin === UserOrigin.RENTER ? rating.renterUser : rating.hostUser;
  // Render
  return (
    <>
      <Grid xl={12} xs={12} sx={{ textAlign: 'left' }}>
        <div className={classes.header}>
          <AppTypography
            type={PDLTypography.mediumHeading}
            customClasses={classes.title}
            children={StringUtils.formatShortUserName(pdlUser.user)}
          />
          <AppTypography
            type={PDLTypography.smallParagraph}
            customClasses={classes.date}
            children={DateUtils.formattedLongDate(new Date(rating.date))}
          />
        </div>
        <AppRating value={rating.rating} />
        <div className={classes.commentContainer}>
          <AppTypography type={PDLTypography.paragraph} children={rating.userComment} />
        </div>
      </Grid>
      {showDivider && <Divider />}
    </>
  );
};

export default AccountRatingComment;

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    marginTop: '0.625em',
    marginBottom: '0.938em',
  },
  date: {
    color: AppTheme.colors.gray_A8ACAD,
  },
  commentContainer: {
    marginTop: '0.625em',
    marginBottom: '1.875em',
  },
});
