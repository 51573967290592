import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppImage from './commons/AppImage';
import { AppTypography } from './Typography/AppTypography';
import { PDLTypography } from './Typography/PDLTypography';

interface Props {
  imageSrc: string;
  title: string;
}

const useStyles = makeStyles({
  imageStyles: {
    borderRadius: '30px',
    width: '100%',
    height: '100%',
    maxHeight: '25em',
    objectFit: 'cover',
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    marginTop: '1.5em',
  },
});
// composition component for host and render header reservation details
export const ReservationDetailsHeader = (props: Props) => {
  const { imageSrc, title } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container direction="column">
        <Grid item style={{ display: 'flex' }}>
          <div className={classes.imageContainer}>
            <div className={classes.imageStyles}>
              <AppImage src={imageSrc} className={classes.imageStyles} alt="Reservation" />
            </div>
          </div>
        </Grid>
      </Grid>
      <AppTypography type={PDLTypography.largeHeading} customClasses={classes.title}>
        {title}
      </AppTypography>
    </>
  );
};
