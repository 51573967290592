import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { COLORS } from '../../utils/AppTheme';
import { AppFontFamily } from '../../utils/AppFontFamily';

export interface BottomSheetProps {
  open: boolean;
  onClose: () => void;
  /** value in em units */
  bottomSpace: string;
  header?: JSX.Element;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

BottomSheet.defaultProps = {
  bottomSpace: '2em',
};

export default function BottomSheet(props: React.PropsWithChildren<BottomSheetProps>) {
  const { open, onClose, bottomSpace, header, children } = props;

  const [headerHeight, setHeaderHeight] = useState(0);
  const ref: React.LegacyRef<HTMLDivElement> | null = useRef(null);

  useEffect(() => {
    setHeaderHeight(ref?.current?.offsetHeight || 0);
  }, [open, header]);

  const finalHeader = header || (
    <div
      style={{
        margin: '0em 2em 2em 2em',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
      }}
    >
      <div
        style={{
          cursor: 'pointer',
          fontFamily: AppFontFamily.SHAPE,
          color: 'black'
        }}
        onClick={onClose}>
        Close
      </div>
    </div>
  );

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullWidth
      keepMounted
      onClose={onClose}
      PaperProps={{
        sx: {
          margin: 0,
          height: '100%',
          maxHeight: 'unset',
          justifyContent: 'flex-end',
          borderRadius: 0,
          backgroundColor: 'transparent',
          boxShadow: 'unset',
          width: '100%',
        },
        onClick: onClose,
      }}
    >
      <div
        style={{
          borderRadius: '1.90em 1.90em 0em 0em',
          paddingTop: '2em',
          paddingRight: '0em',
          backgroundColor: COLORS.white_FFFFFF,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ display: 'flex' }} ref={ref}>
          {finalHeader}
        </div>
        <div
          style={{
            maxHeight: `calc(90vh - ${headerHeight}px)`,
            overflowY: 'auto',
          }}
        >
          <div style={{ margin: '0em 2em' }}>
            {children}
            <div style={{ height: bottomSpace }} />
          </div>
        </div>
      </div>
    </Dialog >
  );
}
