import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import TopIcons from '../../external/pdl-common/components/commons/TopIcons';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';

interface Props {
  src: string;
  alt?: string;
  imgStyles?: React.CSSProperties;
  imgClassName?: string;
  iconsClassName?: string;
  showBackButton?: boolean;
  favourite?: Boolean;
  onClick?: () => void;
  onClickFav?: () => void;
  chipLabel?: string;
}

const ImageWithTopIcons = ({
  src,
  alt,
  imgStyles,
  imgClassName,
  iconsClassName,
  showBackButton = false,
  favourite,
  onClick,
  onClickFav,
  chipLabel
}: Props) => {
  const classes = useStyles();

  // Render
  return (
    <div style={{ position: 'relative', minHeight: '6em', height: '100%' }}>
      <AppImage
        src={src}
        alt={alt}
        className={imgClassName ? imgClassName : classes.image}
        style={imgStyles}
        onClick={onClick}
      />

      <TopIcons
        showBackButton={showBackButton}
        favourite={favourite}
        onClickFav={onClickFav}
        borderRadius={iconsClassName ? iconsClassName : classes.image}
      />
      {chipLabel &&
        <div className={classes.chipContainer}>
          <Chip label={chipLabel} className={classes.notAvailable} sx={{
            "& .MuiChip-label": {
              paddingLeft: "3px",
              paddingRight: "3px"
            }
          }} />
        </div>
      }
    </div>
  );
};

export default ImageWithTopIcons;

const useStyles = makeStyles({
  image: {
    borderRadius: '0.3em',
    width: '100%',
  },
  chipContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  notAvailable: {
    marginBottom: "5px",
    alignSelf: "flex-end",
    backgroundColor: 'rgba(255, 255, 255, 0) !important',
    color: AppTheme.colors.persianBlue_2238CB,
    fontFamily: AppFontFamily.SHAPE_BOLD + ' !important',
    fontSize: '0.6em !important',
  },
});
