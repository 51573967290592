import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { COLORS } from '../../external/pdl-common/utils/AppTheme';

interface Props {
  label?: string;
  labelStyle?: CSSProperties;
  description?: string;
  descriptionStyle?: CSSProperties;

  customStyle?: CSSProperties;

  /** Choose component motive:
   * "bikes" | "renting" | "maintenance" | "cycling tips"
   */
  motive?: string;
}

export default function BubbleTextAndLabel({
  label,
  description,
  labelStyle,
  descriptionStyle,
  customStyle,
  motive,
}: Props) {
  const theme = useTheme();

  const classes = useStyles();

  const smallBreakpoint = useMediaQuery(theme.breakpoints.up('sm'));
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up('md'));

  const getTextByMotive = (componentMotive: string, textOrLabel: number) => {
    if (componentMotive === 'bikes') {
      return textOrLabel === 1
        ? 'Bikes'
        : 'What you need to know about choosing the correct saddle';
    }

    if (componentMotive === 'renting') {
      return textOrLabel === 1
        ? 'Renting'
        : 'Our ultimate guide to picking the perfect rental bike';
    }

    if (componentMotive === 'maintenance') {
      return textOrLabel === 1
        ? 'Maintenance'
        : 'Must have items in a toolbox to keep your bike riding like new';
    }

    if (componentMotive === 'cycling tips') {
      return textOrLabel === 1
        ? 'Cycling tips'
        : 'Hear the best advide we have for beginners interested in cycling';
    }
  };

  return (
    <>
      <Grid
        container
        className={classes.container}
        style={customStyle}
        width={mediumBreakpoint ? '16em' : '14.5em'}
        height={'10em'}
        margin={
          mediumBreakpoint ? '0 1em 0 1em' : smallBreakpoint ? '0 0.8em 0 0.8em' : '0 0.5em 0 0.5em'
        }
      >
        <Grid item>
          <AppTypography
            type={PDLTypography.labelsSmallSerif}
            customStyles={{
              fontSize: mediumBreakpoint ? '1em' : smallBreakpoint ? '0.9em' : '0.85em',
              ...labelStyle,
            }}
            children={label || getTextByMotive(motive!, 1)}
          />
        </Grid>
        <Grid
          item
          mt={motive ? '.75em' : mediumBreakpoint ? '1.5em' : smallBreakpoint ? '1em' : '.4em'}
        >
          <AppTypography
            type={PDLTypography.smallHeading}
            customStyles={{
              fontSize: mediumBreakpoint ? '1em' : '0.8em',
              ...descriptionStyle,
            }}
            children={description || getTextByMotive(motive!, 2)}
          />
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles({
  container: {
    dispĺay: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: 'solid black 2px',
    borderRadius: '2.5em',
    padding: '1.2em .9em 1.2em 1.4em',
    backgroundColor: COLORS.white_FFFFFF,
  },
});
