import Logger from '../../../external/pdl-common/utils/Logger';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import { makeStyles } from '@mui/styles';

const logger = new Logger('InsuranceDescription');

interface Props {
  reservingPhase?: boolean;
}

const InsuranceDescription = (props: Props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        {props.reservingPhase ? (
          <AppTypography
            type={PDLTypography.paragraph}
            customClasses={classes.paragraph}
            children={
              'By selecting this Protection Plan, PDL CLUB agrees to waive your financial liability in the event of physical damage to, or theft of the bike, during your rental period, for any amount exceeding $200 and up to a maximum amount of $3.000.'
            }
          />
        ) : (
          <AppTypography
            type={PDLTypography.paragraph}
            customClasses={classes.paragraph}
            children={
              'PDL Club agrees to waive your financial liability in the event of physical damage to, or theft of, the bike during your rental period up to a maximum of $3000.'
            }
          />
        )}
        <AppTypography
          type={PDLTypography.paragraph}
          customClasses={classes.paragraph}
          children={
            'PDL Club will have sole discretion of what constitutes a valid case and the following exclusions apply:'
          }
        />
        <AppTypography
          type={PDLTypography.paragraph}
          customClasses={classes.subparagraph}
          children={'- Loss or theft of a bicycle if the bicycle was locked outside overnight;'}
        />
        <AppTypography
          type={PDLTypography.paragraph}
          customClasses={classes.subparagraph}
          children={
            '- Loss or damage to a bicycle occurring outside of your applicable rental period;'
          }
        />
        <AppTypography
          type={PDLTypography.paragraph}
          customClasses={classes.subparagraph}
          children={
            '- Loss or damage to a bicycle occurring as a result of competitive races or events; and'
          }
        />
        <AppTypography
          type={PDLTypography.paragraph}
          customClasses={classes.subparagraph}
          children={
            '- Loss or damage to a bicycle when in operation by any party other than you, the Renter.'
          }
        />
        {props.reservingPhase && (
          <AppTypography
            type={PDLTypography.paragraph}
            customClasses={classes.paragraph}
            children={
              'By confirming your rental, you acknowledge that PDL Club will hold an amount on your credit card equal to your deductible. This amount will remain on hold and will be released upon successful confirmation of business finalization.'
            }
          />
        )}
      </div>
    </>
  );
};
export default InsuranceDescription;
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '2em',
  },
  paragraph: {
    marginLeft: '0em',
    marginTop: '1em',
    textAlign: 'left',
  },
  subparagraph: {
    margin: '0.5em',
    textAlign: 'left',
  },
}));
