export enum PDLTypography {
  paragraph = 'paragraph',
  largeHeading = 'largeHeading',
  mediumHeading = 'mediumHeading',
  smallHeading = 'smallHeading',
  subHeading = 'subHeading',
  subHeadingSerif = 'subHeadingSerif',
  smallParagraph = 'smallParagraph',
  labelsSmallSerif = 'labelsSmallSerif',
  callToAction = 'callToAction',
  none = 'none',
}
