import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import BottomSheet from '../commons/BottomSheet';
import { Img } from 'react-image';
import armLength from '../../assets/img/armLenghMeasurement.jpeg';
import inseamLength from '../../assets/img/inseamLenghMeasurement.jpeg';
import sternum from '../../assets/img/sternumMeasurement.jpeg';
import height from '../../assets/img/heighMeasurement.jpeg';

interface Props {
  open: boolean;
  onClose: () => void;
  reset: (option: number) => void;
  option: number;
}

const DiagramBottomSheet = ({ open, onClose, reset, option }: Props) => {
  const data = [
    { title: 'Height', src: height },
    { title: 'Sternum', src: sternum },
    { title: 'Inseam length', src: inseamLength },
    { title: 'Arm length', src: armLength },
  ];

  return option !== -1 ? (
    <BottomSheet
      open={open}
      onClose={() => {
        reset(-1);
        onClose();
      }}
      bottomSpace="5em"
    >
      <AppTypography type={PDLTypography.largeHeading} children={data[option].title} />
      <div style={{ paddingTop: '2em' }} />
      <Img src={data[option].src} alt={`${data[option].title} measurement guide`} width={'100%'} />
    </BottomSheet>
  ) : (
    <></>
  );
};

export default DiagramBottomSheet;
