import { useEffect } from 'react';
import { AppLayout } from '../../core/AppLayout';
import AppHeader from '../../commons/AppHeader';
import { Grid, Box } from '@mui/material';
import SimpleButton from '../../commons/SimpleButton';
import AppTheme from '../../../utils/AppTheme';
import { MapWrapper } from '../../Map/MapWrapper';
import { SxStyles } from '../../../model/utils/SxStyles';
import { GeoSearch } from '../../Map/GeoSearch';
import { useState } from 'react';
import { MapMarker } from '../../Map/Markers/MapMarker';
import { useNavigate, useLocation } from 'react-router-dom';
import GeolocationService from '../../../services/GeolocationService';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { RequestStatus } from '../../../utils/RequestStatus';
import BikeService from '../../../services/BikeService';
import Logger from '../../../external/pdl-common/utils/Logger';
import { Bike } from '../../../external/pdl-common/model/Bike';
import { PdlLocation } from '../../../model/PdlLocation';
import AlertService from '../../../services/AlertService';
import { BikeStatus } from '../../../model/BikeStatus';
import { AlertDialog } from '../../commons/AlertDialog';
import { PartsDetailForm } from '../../../model/parts/PartsDetailForm';
import UserService from '../../../services/UserService';
import Navbar from '../../core/Navbar';

const logger = new Logger('HostBikeFirstStep');

export const HostBikeFirstStep = () => {
  // States we pass between views
  const location = useLocation().state as any;
  const bike: Bike | undefined = location?.bike;
  const partDetails: PartsDetailForm | undefined = location?.partDetails;
  const payoutMethod = location?.payoutMethod;
  const draftMode: boolean | undefined = location?.draftMode;

  const navigate = useNavigate();

  const [bikeLocation, setBikeLocation] = useState<PdlLocation | undefined>(undefined);
  const [showMarker, setShowMarker] = useState<boolean>(true);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [draftBike, setDraftBike] = useState<Bike | undefined>(undefined);
  const [userAdditionalInfo, setUserAdditionalInfo] = useState<boolean | undefined>(
    () => {
      const stored = localStorage.getItem("additionalInformationComplete")
      if (stored) {
        return JSON.parse(stored);
      }
      return false;
    }
  );

  useEffect(() => {
    if (!userAdditionalInfo) {
      checkUserAdditionalInfo();
    }

    if (!draftMode && !bike) {
      getDraftBike();
    }

    if (bike) {
      handleOnClickMap({ latlng: { lat: bike.location.latitude, lng: bike.location.longitude } });
    }
  }, []);

  const redirectToCreationStep = () => {
    switch (draftBike?.status) {
      case BikeStatus.DRAFT_FIRST_STEP:
        appNavigate(navigate, AppRoutes.HOST_BIKE_SECOND_STEP, null, {
          state: { bike: draftBike, draftMode: true },
        });
        break;

      case BikeStatus.DRAFT_SECOND_STEP:
        appNavigate(navigate, AppRoutes.HOST_BIKE_THIRD_STEP, null, {
          state: { bike: draftBike, draftMode: true },
        });
        break;

      case BikeStatus.DRAFT_THIRD_STEP:
        appNavigate(navigate, AppRoutes.PAYOUT_METHOD, null, {
          state: { bike: draftBike, draftMode: true },
        });
        break;

      default:
        break;
    }
  };

  const deleteDraftBike = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await BikeService.deleteDraftBike())
      .onSuccess(() => {
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error("Error when deleteing user's bike under construction", response.getContent());
        AlertService.showSnackCustomError(response.getContent());

        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getDraftBike = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const response = await BikeService.getDraftBike();
      const bike = response.getContent();
      setOpenAlertDialog(true);
      setDraftBike(bike);

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      if (error?.response?.status === 404) {
        // Expected, if the user does not have a draft bike.
        logger.info(`This error is expected. ${error?.response?.data?.message}`);

        setRequestStatus(RequestStatus.SUCCESS);
      } else {
        const errorMessage = 'Error when verifying if the user has bikes under construction';

        logger.error(errorMessage, error);
        AlertService.showSnackCustomError(errorMessage);

        setRequestStatus(RequestStatus.ERROR);
      }
    }
  };

  const checkUserAdditionalInfo = async () => {
    const stored = localStorage.getItem("additionalInformationComplete")
    const additionalInformationComplete = stored ? JSON.parse(stored) : false;

    if (!additionalInformationComplete) {
      (await UserService.checkUserAdditionalInfo())
        .onSuccess((response) => {
          const completed = response.getContent().complete;
          localStorage.setItem("additionalInformationComplete", JSON.stringify(completed));
          if (!completed) {
            appNavigate(navigate, AppRoutes.COMPLETE_ADDITIONAL_INFORMATION, null, {
              state: {
                ...location,
                navigateTo: AppRoutes.HOST_BIKE_FIRST_STEP,
                userAdditionalInfo,
              },
            });
          }
        })
        .onError((response) => {
          logger.error('Error fetching additional information from user', response.getContent());
          localStorage.setItem("additionalInformationComplete", "false");
        });
    }
  };

  const startCreationNewBike = async () => {
    if (bike) {
      appNavigate(navigate, AppRoutes.HOST_BIKE_CONFIRM_LOCATION, null, {
        state: { bike: bike, bikeLocation, partDetails, payoutMethod, draftMode },
      });
      return;
    } else {
      appNavigate(navigate, AppRoutes.HOST_BIKE_CONFIRM_LOCATION, null, {
        state: { bikeLocation },
      });
    }
  };

  const getAddressFromCoordinates = async (lat: number, lon: number) => {
    setRequestStatus(RequestStatus.LOADING);

    (await GeolocationService.getAddressFromCoordinates({ lat: lat, lng: lon }))
      .onSuccess(async (response) => {
        const newLocation = response.getContent();

        setBikeLocation({
          geocode: newLocation.display_name,
          latitude: newLocation.lat,
          longitude: newLocation.lon,
          streetAddress: newLocation.address.road,
          streetAddressDetails: '',
          city:
            newLocation.address.city ||
            newLocation.address.town ||
            newLocation.address.suburb ||
            newLocation.address.village ||
            newLocation.address.county ||
            newLocation.address.state_district,
          state: newLocation.address.state,
          countryCode: newLocation.address.country_code,
          zipCode: newLocation.address.postcode,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        setRequestStatus(RequestStatus.ERROR);
        AlertService.showSnackCustomError(response.getContent());
      });
  };

  const handleOnClickMap = async (e: { latlng: { lat: number; lng: number } }) => {
    setShowMarker(false);

    await getAddressFromCoordinates(e.latlng.lat, e.latlng.lng);

    setShowMarker(true);
  };

  return (
    <AppLayout
      noNavBar
      header={() => (
        <AppHeader
          content={
            bikeLocation
              ? [
                  bikeLocation.streetAddress,
                  bikeLocation.city,
                  bikeLocation.state,
                  bikeLocation.zipCode,
                ]
                  .filter(Boolean)
                  .join(', ')
              : 'Add your listing'
          }
          bottomElement={
            !bikeLocation ? (
              <Grid container maxWidth={'md'} flexDirection={'column'} px={3.5} py={3}>
                <Grid item>
                  <GeoSearch
                    placeholder={'Where is your bike located?'}
                    onClickLocation={(res) => getAddressFromCoordinates(+res.lat, +res.lon)}
                  />
                </Grid>

                <Grid item mt={1.5}>
                  <AppTypography
                    type={PDLTypography.smallParagraph}
                    children={'*Full address information will only be public to confirmed renters.'}
                    customStyles={{ color: AppTheme.colors.white_FFFFFF }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Box pb={2}></Box>
            )
          }
          onBack={
            bikeLocation
              ? () => setBikeLocation(undefined)
              : () => appNavigate(navigate, AppRoutes.HOST, null, {})
          }
        />
      )}
      content={() => (
        <>
          <Grid container style={{ flex: 1 }}>
            <Grid item xs={12} xl={12} style={{ display: 'flex' }}>
              <MapWrapper
                coordinates={
                  bikeLocation
                    ? { lat: bikeLocation.latitude, lng: bikeLocation.longitude }
                    : undefined
                }
                onClickMap={handleOnClickMap}
                isLoading={!showMarker || requestStatus == RequestStatus.LOADING}
              >
                {bikeLocation && showMarker && (
                  <MapMarker
                    lat={bikeLocation.latitude}
                    lng={bikeLocation.longitude}
                    draggable={true}
                    type={'OnlyIcon'}
                    onDrag={(lat, lng) => getAddressFromCoordinates(lat, lng)}
                  />
                )}
              </MapWrapper>
            </Grid>

            <Box sx={styles.stepsBox}>
              <Grid container maxWidth={'md'} sx={styles.stepsContainer}>
                <Grid item xs={7} xl={7}>
                  <AppTypography type={PDLTypography.subHeadingSerif} children={'Step 1 of 3'} />
                </Grid>
                <Grid item xs={5} xl={5}>
                  <SimpleButton
                    isLoading={requestStatus === RequestStatus.LOADING}
                    disabled={
                      !bikeLocation ||
                      requestStatus === RequestStatus.LOADING ||
                      requestStatus === RequestStatus.ERROR
                    }
                    onClick={startCreationNewBike}
                  >
                    Next
                  </SimpleButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Navbar />
          <AlertDialog
            title="Incomplete listing"
            content="Do you want to continue from where you left off?"
            confirmLabel="Resume"
            cancelLabel="Start new"
            open={openAlertDialog}
            onConfirm={() => {
              setOpenAlertDialog(false);
              redirectToCreationStep();
            }}
            onCancel={() => {
              setOpenAlertDialog(false);
              deleteDraftBike();
            }}
          />
        </>
      )}
    />
  );
};

const styles: SxStyles = {
  stepsBox: {
    position: 'fixed',
    bottom: 59,
    zIndex: 999,
    width: '100%',
    backgroundColor: AppTheme.colors.white_FFFFFF,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: AppTheme.colors.gray_E3E5E4,
  },
  stepsContainer: {
    m: '0 auto',
    alignItems: 'center',
    justifyContent: 'space-around',
    px: 5,
    py: 1,
  },
};
