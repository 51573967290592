// @ts-nocheck
import { Bike } from '../external/pdl-common/model/Bike';
import { BikeDetails } from '../model/BikeDetails';
import StringUtils from './StringUtils';
import DateUtils from '../utils/DateUtils';

export default class BikeUtils {
  public static getDefaultOrCustomProperty(bike: Bike, property: string): string {
    if (bike[property] === 'Other') {
      return bike[`custom${StringUtils.capitalizeText(property)}`];
    }
    return bike[property];
  }

  public static toBikeDetails = (bike: Bike): BikeDetails => {
    return {
      ...(bike as BikeDetails),
      make: bike.customMake || bike.make,
      color: bike.customColor || bike.color,
      condition: bike.customCondition || bike.condition,
    };
  };

  public static hasTierPricing = (bike: Bike): Boolean => {
    return bike.twoDayPrice || bike.threePlusDayPrice;
  }

  public static dailyPrice = (bike: Bike, startDate: Date, endDate: Date): Double => {
    const tripDuration = DateUtils.getFullDaysBetweenDates(startDate, endDate)
    if (!this.hasTierPricing(bike) || tripDuration == 1) {
      return bike.customPrice;
    }
    if (tripDuration == 2) {
      return bike.twoDayPrice;
    }
    return bike.threePlusDayPrice;
  }
}
