import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { AppTextField } from '../commons/AppTextField';
import BottomSheet from '../commons/BottomSheet';
import SimpleButton from '../commons/SimpleButton';
import { AppTypography, AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { Box } from '@mui/material';
import { RequestStatus } from '../../utils/RequestStatus';
import { useForm } from '../../hooks/useForm';
import CustomAuthService from '../../external/fox-typescript/services/CustomAuthService';
import AlertService from '../../services/AlertService';
import Logger from '../../external/pdl-common/utils/Logger';

interface Props {
  open: boolean;
  onClose: () => void;
}

const logger = new Logger('ForgotPasswordBottomSheet');

const ForgotPasswordBottomSheet = ({ open, onClose }: Props) => {
  const classes = useStyles();

  const [form, validations, handleCustomChange, formIsValid, clearForm] = useForm({ email: '' });
  const [submitStatus, setSubmitStatus] = useState<RequestStatus | undefined>(undefined);

  const handleOnSubmit = async () => {
    try {
      setSubmitStatus(RequestStatus.LOADING);

      await CustomAuthService.forgotPassword(form.email);
    } catch (error: any) {
      logger.error('Error submitting email', error);
      /*
       * The API returns a 404 if the email is not found.
       * We do not have to display an error message, since the following screen shows:
       * "If an account associated with that email exists, you will receive a password reset link."
       */
      if (error?.response?.status !== 404) {
        AlertService.showSnackInternalError();
      }
    } finally {
      setSubmitStatus(RequestStatus.SUCCESS);
    }
  };

  const handleOnClose = () => {
    clearForm();
    setSubmitStatus(undefined);
    onClose();
  };

  return (
    <>
      <BottomSheet open={open} onClose={handleOnClose} bottomSpace="5em">
        <div className={classes.titleContainer}>
          <AppTypography
            type={PDLTypography.largeHeading}
            children={submitStatus === RequestStatus.SUCCESS ? 'Sent!' : 'Reset Your Password'}
          />

          <Box className={classes.subTitle}>
            <AppTypography
              type={PDLTypography.paragraph}
              children={
                submitStatus === RequestStatus.SUCCESS ? (
                  <>
                    Please check your email. <br />
                    If an account associated with that email exists, you will receive a password
                    reset link.
                  </>
                ) : (
                  'A password reset link will be sent to your email. '
                )
              }
            />
          </Box>
        </div>

        {submitStatus !== RequestStatus.SUCCESS && (
          <>
            <AppTextField
              name={'email'}
              label={'Email'}
              inputProps={{ type: 'email' }}
              value={form.email}
              labelProps={labelProps}
              onInputChange={handleCustomChange}
              containerClasses={classes.textFieldContainer}
              restrict={'email'}
              realTimeValidation
              required
            />

            <SimpleButton
              children={'Submit'}
              sx={{ mt: 4 }}
              onClick={handleOnSubmit}
              disabled={submitStatus === RequestStatus.LOADING || !formIsValid()}
              isLoading={submitStatus === RequestStatus.LOADING}
            />
          </>
        )}
      </BottomSheet>
    </>
  );
};

export default ForgotPasswordBottomSheet;

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subTitle: {
    marginTop: '1.5em',
    marginBottom: '1em',
    textAlign: 'center',
  },
  textFieldContainer: {
    marginTop: '0.625em',
  },
});
