import { TextFieldProps } from '@mui/material';
import { Control, Controller, UseControllerProps } from 'react-hook-form';
import { AppAutocomplete, AppAutocompleteOptions } from './AppAutocomplete';

type HookFormCustomTypes = {
  label?: string;
  value?: string;
  options: AppAutocompleteOptions[];
  textField?: TextFieldProps;
  freeSolo?: boolean;
  setValue?: (value: string) => void;
  customOnChange?: () => void;
};

interface UseControllerPropsWithCustomTypes extends UseControllerProps, HookFormCustomTypes {
  control: Control<any>;
}

const AppAutocompleteHook = ({
  name,
  control,
  defaultValue,
  rules,
  options,
  textField,
  label = '',
  freeSolo = false,
  customOnChange
}: UseControllerPropsWithCustomTypes) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <AppAutocomplete
          label={label}
          value={value}
          onChange={(event: any, newValue: string) => {
            onChange(newValue);
            customOnChange && customOnChange()
          }}
          options={options}
          textField={textField}
          freeSolo={freeSolo}
        />
      )}
    />
  );
};

export default AppAutocompleteHook;
