import { makeStyles } from '@mui/styles';
import Image from '../../external/fox-typescript/core/Image';
import { COLORS, IAppTheme } from '../../utils/AppTheme';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import AppImage from '../../external/pdl-common/components/commons/AppImage';

interface Props {
  images: Image[];
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  carouselItem: {
    display: 'flex !important',
  },
  carouselImage: {
    width: 'calc(100% - 1em)',
    borderRadius: 4,
    objectFit: 'contain',
    backgroundColor: COLORS.black_000000,
    aspectRatio: '1.6',
  },
}));

export function ImageCarousel(props: Props) {
  const classes = useStyles();

  return (
    <Carousel
      itemsToShow={3.5}
      slidesToShow={{ xs: 1, sm: 1, md: 1, lg: 1 }}
      data={props.images}
      showIndexIndicator
      renderItem={(image, index) => {
        return (
          <div className={classes.carouselItem}>
            <AppImage src={image.url} className={classes.carouselImage} />
          </div>
        );
      }}
    />
  );
}
