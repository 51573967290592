import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import { ResponseData } from '../external/fox-typescript/core/ResponseData';
import { Measurement } from '../model/Measurement';
import { Flexibility } from '../model/measurements/Flexibility';
import { ShoeSize } from '../model/measurements/ShoeSize';
import { CrudService } from '../external/fox-typescript/services/CrudService';

class MeasurementsService implements CrudService<Measurement> {
  getAll(page: number, pageSize: number): Promise<ApiResponse<PagedResult<Measurement>>> {
    throw new Error('Method not implemented.');
  }
  create(instance: Measurement): Promise<ApiResponse<Measurement>> {
    throw new Error('Method not implemented.');
  }
  delete(instance: Measurement): Promise<ApiResponse<Measurement>> {
    throw new Error('Method not implemented.');
  }

  getShoeSizeTypes(): Promise<ApiResponse<ShoeSize[]>> {
    const response: ResponseData<ShoeSize[]> = {
      content: [
        { value: '1', description: 'US Women' },
        { value: '2', description: 'US Men' },
        { value: '3', description: 'Euro Women' },
        { value: '4', description: 'Euro Men' },
        { value: '5', description: 'UK Women' },
        { value: '6', description: 'UK Men' },
      ],
      statusCode: 200,
      success: true,
      responseCode: 'OK',
    };

    return Promise.resolve(ApiResponse.fromData(response));
  }

  getFlexibilityTypes(): Promise<ApiResponse<Flexibility[]>> {
    const response: ResponseData<Flexibility[]> = {
      content: [
        { value: '1', description: 'Fingers touch kneecaps' },
        { value: '2', description: 'Fingers touch middle of shins' },
        { value: '3', description: 'Fingers touch toes' },
        { value: '4', description: 'Fingers touch ground' },
        { value: '5', description: 'Knuckles flat on ground' },
        { value: '6', description: 'Fingers flat on ground' },
        { value: '7', description: 'Palms flat on ground' },
      ],
      statusCode: 200,
      success: true,
      responseCode: 'OK',
    };

    return Promise.resolve(ApiResponse.fromData(response));
  }
}

export default new MeasurementsService();
