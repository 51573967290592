import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../../external/pdl-common/components/Typography/PDLTypography';
import DateUtils from '../../../../external/pdl-common/utils/DateUtils';
import { Insurance } from '../../../../model/insurance/Insurance';
import BottomSheet from '../../../commons/BottomSheet';
import InsuranceDescription from '../../../views/Host/InsuranceDescription';

interface Props {
  open: boolean;
  onClose: () => void;
  insurance: Insurance;
}

const InsuranceDetailsBottomSheet = (props: Props) => {
  const classes = useStyles();

  const coverage = props.insurance.coverages.find(Boolean);

  return (
    <BottomSheet open={props.open} onClose={props.onClose} bottomSpace="5em">
      <div className={classes.container}>
        <AppTypography type={PDLTypography.largeHeading}>Protection Program</AppTypography>

        <div className={classes.list}>
          <AppTypography type={PDLTypography.mediumHeading} customClasses={classes.label}>
            Protection Plan
          </AppTypography>

          <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
            Plan name: {props.insurance.planName}
          </AppTypography>

          <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
            {`Effective start date: ${DateUtils.formattedChatDate(
              new Date(props.insurance.effectiveDate)
            )}`}
          </AppTypography>

          <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
            {`Effective end date: ${DateUtils.formattedChatDate(
              new Date(props.insurance.termEndDate)
            )}`}
          </AppTypography>
        </div>

        {coverage && (
          <div className={classes.list}>
            {coverage.description && (
              <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
                Description: {coverage.description}
              </AppTypography>
            )}

            <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
              {`Protection Fee: $${coverage.premium}`}
            </AppTypography>

            {coverage.limitPrice && (
              <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
                {`Maximum Refund: $${coverage.limitPrice}`}
              </AppTypography>
            )}

            {coverage.deductible && (
              <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
                {`Hold Charge / Deductible: $${coverage.deductible}`}
              </AppTypography>
            )}

            {coverage.securityDeposit && (
              <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
                {`Security deposit: $${coverage.securityDeposit}`}
              </AppTypography>
            )}
          </div>
        )}
        <InsuranceDescription />
      </div>
    </BottomSheet>
  );
};

export default InsuranceDetailsBottomSheet;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  label: {
    marginTop: '1em',
    textAlign: 'left',
  },
  buttonContainer: {
    margint: '0px auto',
    marginTop: '2em',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));
