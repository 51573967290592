import { useEffect, useState } from 'react';
import AppHeader from '../commons/AppHeader';
import { AppLayout } from '../core/AppLayout';
import InboxService from '../../services/InboxService';
import InboxItem from '../Inbox/InboxItem';
import { Container, Divider, useMediaQuery, useTheme } from '@mui/material';
import { SearchBar } from '../commons/SearchBar';
import { EmptyList } from '../commons/EmptyList';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { PdlChatChannel } from '../../model/PdlChatChannel';
import AlertService from '../../services/AlertService';
import Logger from '../../external/pdl-common/utils/Logger';
import { makeStyles } from '@mui/styles';
import { RequestStatus } from '../../utils/RequestStatus';
import { Debouncer } from '../../utils/Debouncer';
import { SxStyles } from '../../model/utils/SxStyles';
import useInterval from '../../hooks/useInterval';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import { Virtuoso } from 'react-virtuoso';
import LoadingSpinner from '../../external/pdl-common/components/commons/LoadingSpinner';
import { AlertDialog } from '../commons/AlertDialog';
import UserService from '../../services/UserService';

const logger = new Logger('Inbox');

const PAGE_SIZE = 10;
const REFRESH_TIME = 10 * 1000; // // 10 seconds when I'm in Inbox

export default function Inbox() {
  const classes = useStyles();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const [requestStatus, setRequestStatus] = useState<RequestStatus | undefined>(undefined);
  const [channels, setChannels] = useState<PdlChatChannel[]>([]);
  const [pageData, setPageData] = useState<{ number: number; totalPages: number }>({
    number: 0,
    totalPages: 0,
  });
  const [search, setSearch] = useState<string>('');
  const [totalElements, setTotalElements] = useState<number>(0);
  const [optOffSearchDialog, setOptOffSearchDialog] = useState<boolean>(false);
  const [searchDialogModalOpen, setSearchDialogModalOpen] = useState<boolean>(false);
  const [dialogCheckBoxCheck, setDialogCheckBoxCheck] = useState<boolean>(false);

  useEffect(() => {
    loadChannels();
    getUserOptOffAlertDialog();
  }, []);

  useEffect(() => {
    if (search !== '') {
      setRequestStatus(RequestStatus.LOADING);
      Debouncer.debounce('searchContent', loadChannels, 700);
    } else {
      loadChannels();
    }
  }, [search]);

  // Refresh chat channels every certain time.
  useInterval(() => {
    loadChannels();
  }, REFRESH_TIME);

  const getUserOptOffAlertDialog = async () => {
    const storedOptOff = localStorage.getItem("opt-off");
    if (storedOptOff == undefined || storedOptOff == "false") {
      try {
        const response = await UserService.getOptOffSerachDialog();
        setOptOffSearchDialog(response.getContent());
        localStorage.setItem("opt-off", String(response.getContent()));
      } catch (e) {
        logger.error('Error fetching user search dialog preference', e);
      }
    } else {
      setOptOffSearchDialog(Boolean(storedOptOff));
    }
  }

  const handleClickOnSearch = () => {
    if (optOffSearchDialog) {
      appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
    } else {
      setSearchDialogModalOpen(true);
    }
  }

  const handleNavigateToSearch = async () => {
    if (dialogCheckBoxCheck) {
      try {
        const response = await UserService.updateSearchDialogPreference()
        localStorage.setItem("opt-off", "true");
        appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
      } catch (e) {
        logger.error('Error updating user search dialog preference', e);
      }
    } else {
      appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
    }
  }

  const loadChannels = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const response = (await InboxService.getAllChannelsAsUser(0, PAGE_SIZE, search)).getContent();

      setChannels(response.content);
      setPageData({
        number: response.number,
        totalPages: response.totalPages,
      });
      setTotalElements(response.totalElements);

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      logger.error('Error fetching channels', error);

      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }

      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const loadMoreChannels = async () => {
    Debouncer.debounce(
      'loadMoreChannels',
      async () => {
        if (pageData.number + 1 >= pageData.totalPages) {
          return;
        }

        try {
          const response = (
            await InboxService.getAllChannelsAsUser(pageData.number + 1, PAGE_SIZE, search)
          ).getContent();

          setChannels([...channels, ...response.content]);
          setPageData({ ...pageData, number: pageData.number + 1 });
        } catch (error: any) {
          logger.error('Error loading more channels', error);

          if (error?.response?.data?.message) {
            AlertService.showSnackCustomError(error?.response?.data?.message);
          } else {
            AlertService.showSnackInternalError();
          }
        }
      },
      100
    );
  };

  const renderItem = (index: number, channel: PdlChatChannel) => {
    return (
      <div className={classes.lastMessageContainer} key={channel.externalId}>
        <InboxItem pdlChatChannel={channel} key={channel.externalId} />
        <Divider
          variant="middle"
          sx={{
            width: index < channels.length - 1 ? '100%' : '0',
            paddingTop: '.2em',
          }}
        />
      </div>
    );
  };

  const getHeader = () => {
    return (
      <AppHeader
        showBackButton={false}
        content="Inbox"
        bottomElement={
          <Container
            sx={{
              my: breakpoint ? '0em' : '1.5em',
              pr: breakpoint ? '0em' : '1.5em',
              maxWidth: 'md',
            }}
          >
            <SearchBar
              buttonDisabled
              value={search}
              placeholder={'Search by name'}
              onChange={(newValue) => setSearch(newValue)}
            />
          </Container>
        }
      />
    );
  };

  const getBody = () => {
    if (requestStatus && requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ height: '60vh', display: 'flex', flex: 1, alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={loadChannels} />
        </div>
      );
    }

    if (channels.length === 0 && requestStatus === RequestStatus.SUCCESS) {
      return (
        <>
          <EmptyList
            title={'No messages yet...'}
            subTitle={'Explore listings to send private messages to members of the comunity'}
            buttonText={'Start a search'}
            buttonOnClick={handleClickOnSearch}
          />
          <AlertDialog
            hideCancelButton
            title="Service availability"
            content={"Service only available in Miami."}
            open={searchDialogModalOpen}
            confirmLabel={"Continue"}
            onCheckboxChange={() => setDialogCheckBoxCheck(!dialogCheckBoxCheck)}
            onConfirm={() => {
              setSearchDialogModalOpen(false);
              handleNavigateToSearch();
            }}
            onCancel={() => {
              setSearchDialogModalOpen(false);
            }}
          />
        </>
      );
    }

    const Footer = () => {
      if (channels.length === totalElements) {
        return <></>;
      }
      return <LoadingSpinner />;
    };

    return (
      <Container sx={muiStyles.mainContainer}>
        <div className={classes.innerContainer}>
          <Virtuoso
            style={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            data={channels}
            endReached={loadMoreChannels}
            itemContent={renderItem}
            components={{ Footer }}
          />
        </div>
      </Container>
    );
  };

  return <AppLayout header={getHeader} content={getBody} fromHome />;
}

const muiStyles: SxStyles = {
  mainContainer: {
    flex: 1,
    display: 'flex',
  },
};

const useStyles = makeStyles({
  list: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxWidth: '100%',
  },
  innerContainer: {
    flex: 1,
    position: 'relative',
    height: 'calc(100vh - 15em)',
    margin: '0% 2.5%',
    paddingTop: '1em',
  },
  lastMessageContainer: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(1, 0, 14, 0.05)',
    },
    cursor: 'pointer',
  },
});
