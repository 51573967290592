import { Box, CircularProgress, Divider, Grid } from '@mui/material';
import { RawResult } from 'leaflet-geosearch/dist/providers/openStreetMapProvider';
import AppTheme from '../../external/pdl-common/utils/AppTheme';
import { SxStyles } from '../../model/utils/SxStyles';

interface Props {
  results: RawResult[];
  onClick: (result: RawResult) => void;
  isLoading?: boolean;
}

const SearchResults = (props: Props) => {
  return (
    <Grid item>
      <Box sx={styles.searchResultsContainer}>
        {props.isLoading && (
          <Box sx={styles.spinner}>
            <CircularProgress size={20} color="inherit" />
          </Box>
        )}

        {props.results.map((result, index) => (
          <span key={index} onClick={() => props.onClick(result)} style={{ cursor: 'pointer' }}>
            {result.display_name}
            <Divider sx={{ my: 1 }} />
          </span>
        ))}
      </Box>
    </Grid>
  );
};

export default SearchResults;

const styles: SxStyles = {
  searchResultsContainer: {
    backgroundColor: AppTheme.colors.white_FFFFFF,
    width: '90%',
    p: 1,
    mt: 1,
    maxHeight: 200,
    overflow: 'auto',
    zIndex: 99,
    position: 'relative',
    boxShadow: '3px 3px 10px 3px rgba(0, 0, 0, 0.5)',
    py: 2,
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5em',
    color: AppTheme.colors.persianBlue_2238CB,
  },
};
