import AppHeader from '../../../commons/AppHeader';
import { AppLayout } from '../../../core/AppLayout';
import { useParams } from 'react-router';
import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppTheme from '../../../../utils/AppTheme';
import { useEffect, useState } from 'react';
import SimpleButton from '../../../commons/SimpleButton';
import { Calendar, DateObject } from 'react-multi-date-picker';
import BlockoutDateCreation from '../../../../model/creation/BlockoutDateCreation';
import { BlockoutDates } from '../../../../model/BlockoutDates';
import { RequestStatus } from '../../../../utils/RequestStatus';
import Logger from '../../../../external/pdl-common/utils/Logger';
import BikeService from '../../../../services/BikeService';
import ReservationService from '../../../../services/ReservationService';
import AlertService from '../../../../services/AlertService';
import { useFeatureToggle } from '../../../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../../../external/pdl-common/utils/enums/Features';
import { AlertDialog } from '../../../commons/AlertDialog';
import { useNavigate } from 'react-router-dom';

const logger = new Logger('HostBikeEdit');

export default function HostBikeEditBlockoutDates() {
  const { externalId } = useParams();
  const classes = useStyles();

  const navigate = useNavigate();

  const [isUtcFormatEnabled] = useFeatureToggle(Features.IS_UTC_FORMAT_ENABLED);

  const [bikeBlockoutDates, setBikeBlockoutDates] = useState<BlockoutDates[]>([]);
  const [submittedDate, setSubmittedDate] = useState<DateObject[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [disabledDates, setDisabledDates] = useState<DateObject[]>([]);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  useEffect(() => {
    getBlockoutDates();
    getReservationDates();
  }, []);

  useEffect(() => {
    getSubmittedDatesAndMapIntoObjectDate();
  }, [bikeBlockoutDates]);

  const getSubmittedDatesAndMapIntoObjectDate = () => {
    let blockoutDatesArray: DateObject[] = [];
    if (bikeBlockoutDates) {
      bikeBlockoutDates.forEach((p) => blockoutDatesArray.push(new DateObject(p.startDate)));
    }
    setSubmittedDate(blockoutDatesArray);
  };

  const getBlockoutDates = async () => {
    if (!externalId) return;

    (await BikeService.getBlockoutDates(externalId))
      .onSuccess((response) => {
        setBikeBlockoutDates(response.getContent());
      })
      .onError((response) => {
        logger.error("Couldn't get blockout dates", response.getContent());
        AlertService.showSnackCustomError(response.getContent());
      });
  };

  const onBack = () => {
    if (isChanged) {
      setOpenAlertDialog(true);
    } else {
      navigate(-1);
    }
  }

  const getReservationDates = async () => {
    if (externalId) {
      (await ReservationService.getAllReservedDatesForBike(externalId))
        .onSuccess((response) => {
          setDisabledDates(response.getContent().map((d) => new DateObject(d)));
        })
        .onError((response) => {
          logger.error("Couldn't get reserved dates", response.getContent());
          AlertService.showSnackCustomError(response.getContent());
        });
    }
  };

  const mapDisabledDates = (date: DateObject) => {
    let contain = disabledDates.some(
      (e) => e.day === date.day && e.month.number === date.month.number && e.year === date.year
    );

    if (contain) {
      return {
        disabled: true,
        style: { color: 'white', backgroundColor: 'red', borderRadius: '1em' },
        onClick: () => AlertService.showSnackCustomError('Bike already reserved in that date'),
      };
    }
  };

  const submitBlockoutDates = async () => {
    if (submittedDate && externalId) {
      setRequestStatus(RequestStatus.LOADING);

      let newDates: string[] = [];
      submittedDate.forEach((p) =>
        isUtcFormatEnabled
          ? newDates.push(p.format('YYYY-MM-DDT00:00:00.000Z'))
          : newDates.push(new Date(p.format()).toISOString())
      );

      (await BikeService.addBlockoutDates(externalId, new BlockoutDateCreation(newDates)))
        .onSuccess(() => {
          AlertService.showSuccessMessage('Blockout dates edited successfully');
          setRequestStatus(RequestStatus.SUCCESS);
          setTimeout(() => {
            // Return to previous screen automatically after 3 seconds
            navigate(-1);
          }, 2000);
        })
        .onError((response) => {
          logger.error('Error editing blockout dates ', response.getContent());
          AlertService.showSnackCustomError(response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    }
  };

  const renderContent = () => {
    return (
      <>
        <Grid display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Calendar
            className={classes.calendar}
            multiple={true}
            value={submittedDate}
            onChange={(value: DateObject[]) => {
              if (!isChanged) setIsChanged(true);
              setSubmittedDate(value)
            }
            }

            format={'YYYY/MM/DD'}
            minDate={new Date()}
            disableMonthPicker={true}
            disableYearPicker={true}
            weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
            fullYear={false}
            numberOfMonths={1}
            mapDays={({ date }) => mapDisabledDates(date)}
          />
        </Grid>
        <Grid className={classes.footer}>
          <Divider />
          <Grid className={classes.button}>
            <SimpleButton
              onClick={() => submitBlockoutDates()}
              children={'Save blockout dates'}
              isLoading={requestStatus === RequestStatus.LOADING}
            />
          </Grid>
        </Grid>
        <AlertDialog
          title="Leave edit"
          content="Are you sure to leave edit? Your changes will be discarted"
          open={openAlertDialog}
          onConfirm={() => {
            setOpenAlertDialog(false);
            navigate(-1);
          }}
          onCancel={() => {
            setOpenAlertDialog(false);
          }}
        />
      </>
    );
  };

  return (
    <>
      <AppLayout
        header={() => <AppHeader content={'Blockout dates'} onBack={onBack} />}
        content={() => renderContent()}
      />
    </>
  );
}

const useStyles = makeStyles({
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80vw',
    marginLeft: '10vw',
    marginTop: '1em',
  },
  footer: {
    position: 'fixed',
    bottom: 69,
    width: '100vw',
  },
  calendar: {
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    backgroundColor: AppTheme.colors.white_FFFFFF,
    width: '90vw',
    maxWidth: '25em',
    marginTop: '1.5em',
    marginRight: '0.9em',
    boxShadow: 'none',
    '& div': {
      color: 'black',
      width: '100%',
      fontSize: '0.97em',
      '& .rmdp-day': {
        fontWeight: '400',
        width: '2.5em',
        height: '2.5em',
        '& span': {
          borderRadius: '5em !important',
        },
      },
      '& .rmdp-day.rmdp-selected': {
        '& .sd': {
          backgroundColor: AppTheme.colors.persianBlue_2238CB,
          color: AppTheme.colors.white_FFFFFF,
          borderRadius: '5em',
        },
      },
      '& .rmdp-day.rmdp-disabled': {
        color: '#aeb9bf',
        '& :hover': {
          color: '#aeb9bf',
        },
      },
      '& .rmdp-day.rmdp-today': {
        '& .sd': {
          backgroundColor: AppTheme.colors.white_FFFFFF,
          color: AppTheme.colors.black_000000,
          borderBottom: `solid 1px black`,
          borderRadius: '0em',
        },
      },
      '& .rmdp-day:hover': {
        '& .sd': {
          backgroundColor: AppTheme.colors.white_FFFFFF,
          color: AppTheme.colors.black_000000,
        },
        '& :hover': {
          backgroundColor: AppTheme.colors.white_FFFFFF,
          color: AppTheme.colors.black_000000,
        },
      },
    },
    '& .rmdp-arrow-container:hover': {
      backgroundColor: AppTheme.colors.white_FFFFFF,
      boxShadow: 'none',
      borderColor: 'red',
      '& i': {
        borderColor: AppTheme.colors.black_000000,
      },
    },
    '& .rmdp-arrow-container': {
      backgroundColor: AppTheme.colors.white_FFFFFF,
      boxShadow: 'none',
      '& i': {
        borderColor: AppTheme.colors.black_000000,
      },
    },
    '& .rmdp-arrow-container.rmdp-right': {
      marginRight: '0.2em',
    },
    '& .rmdp-arrow-container.rmdp-left': {
      marginLeft: '0.9em',
    },
    '& .rmdp-header-values': {
      fontSize: '0.8em',
      fontWeight: '700',
    },
    '& .rmdp-week': {
      marginBottom: '0.55em',
    },
    '& .rmdp-week-day': {
      width: '2.5em',
      height: '2.5em',
    },
  },
});
