import { Box, useMediaQuery } from '@mui/material';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { SxStyles } from '../../model/utils/SxStyles';
import { COLORS } from '../../utils/AppTheme';
import { FooterPdlClub } from '../commons/FooterPdlClub';
import TopNavBar from '../core/LandingPageNavBar';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import { AppAssets } from '../../assets';
import { AboutCard } from '../commons/AboutCard';
import { WelcomeToPdlClubAbout } from '../commons/WelcomeToPdlClubAbout';
import { useEffect } from 'react';

export default function About() {
  const breakpointWidth = useMediaQuery('(max-width: 1200px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {breakpointWidth ? (
        <Box sx={{ backgroundColor: COLORS.black_000000 }}>
          {/*Mobile page */}
          <TopNavBar showBackButton/>
          <Box sx={styles.initialBoxMobile}>
            <WelcomeToPdlClubAbout />
            <Box sx={{ paddingTop: '2em' }}>
              <AppImage
                src={AppAssets.WaitlistAboutCardBike}
                alt="Bike card"
                style={{ height: '515px', width: '256px' }}
              />
            </Box>
            <Box sx={{ paddingTop: '2em' }}>
              <AppImage
                src={AppAssets.WaitlistAboutCardSearch}
                alt="Search"
                style={{ height: '309.78px', width: '254px' }}
              />
            </Box>
            <Box sx={{ paddingTop: '2em' }}>
              <AppImage
                src={AppAssets.AboutPageInbox}
                alt="Inbox"
                style={{
                  height: '14.5em',
                  width: '15em',
                  border: `solid white 2px`,
                  borderRadius: '2.5em',
                }}
              />
            </Box>
            <Box sx={{ paddingTop: '2em' }}>
              <AppImage
                src={AppAssets.WaitlistAboutCardProfile}
                alt="Reservation review"
                style={{ height: '22.5em', width: '15em' }}
              />
            </Box>
          </Box>

          {/*  The Team */}
          <Box style={{ backgroundColor: COLORS.black_000000 }}>
            <Box sx={styles.teamBoxMobile}>
              <AppTypography
                type={PDLTypography.largeHeading}
                children={'The team'}
                customStyles={{ fontSize: '2em', color: COLORS.black_000000 }}
              />
              <AboutCard avatar={AppAssets.AboutTeam1} name={'Andreas Heuser'} position={'CEO'} />
              <AboutCard avatar={AppAssets.AboutTeam2} name={'Martín Crupi'} position={'COO'} />
              <AboutCard avatar={AppAssets.AboutTeam3} name={'Demian Bellumio'} position={'Co-founder'} />
              <AboutCard avatar={AppAssets.AboutTeam4} name={'Martin Pombo'} position={'CMO'} />
            </Box>
          </Box>

          {/*  Selected investors */}
          <Box sx={styles.boardBoxMobile}>
            <AppTypography
              type={PDLTypography.largeHeading}
              children={'Selected investors'}
              customStyles={{ fontSize: '2em', color: COLORS.white_FFFFFF }}
            />
            <AboutCard
              avatar={AppAssets.AboutBoard1}
              name={'Marcelo Claure'}
              position={'Chairman and CEO, Claure Capital'}
            />
            <AboutCard
              avatar={AppAssets.AboutBoard2}
              name={'Adriana Cisneros'}
              position={'CEO, Cisneros'}
            />
            <AboutCard
              avatar={AppAssets.AboutBoard3}
              name={'Jon Oringer'}
              position={'Founder and Executive Chairman, Shutterstock'}
            />
            <AboutCard
              avatar={AppAssets.AboutBoard4}
              name={'Carlos Domingo'}
              position={'Founder and CEO, Securitize'}
            />
            <AboutCard
              avatar={AppAssets.AboutBoard5}
              name={'David Garcia'}
              position={'CEO & Managing Partner, Borderless Capital'}
            />
            <AboutCard
              avatar={AppAssets.AboutBoard6}
              name={'Mike Byrne'}
              position={'Private Investor and Advisor'}
            />
          </Box>
          <FooterPdlClub showTopSection />
        </Box>
      ) : (
        <Box>
          {/*Desktop page */}
          <TopNavBar showBackButton/>
          <WelcomeToPdlClubAbout />
          <Box sx={styles.initialBox}>
            <AppImage
              src={AppAssets.WaitlistAboutCardBike}
              alt="Bike search"
              style={{ height: '28em', width: '15em', marginTop: '4em' }}
            />

            <AppImage
              src={AppAssets.WaitlistAboutCardSearch}
              alt="Search"
              style={{ height: '17em', width: '15em', marginTop: '2em' }}
            />

            <AppImage
              src={AppAssets.AboutPageInbox}
              alt="Inbox"
              style={{
                height: '14.5em',
                width: '15em',
                border: `solid white 2px`,
                borderRadius: '2.5em',
                marginTop: '7em',
              }}
            />

            <AppImage
              src={AppAssets.WaitlistAboutCardProfile}
              alt="Reservation review"
              style={{ height: '22.5em', width: '15em', marginTop: '1em' }}
            />
          </Box>

          {/*  The Team */}
          <Box style={{ backgroundColor: COLORS.black_000000 }}>
            <Box sx={styles.teamBox}>
              <AppTypography
                type={PDLTypography.largeHeading}
                children={'The team'}
                customStyles={{ fontSize: '2.8em', color: COLORS.black_000000 }}
              />
              <Box sx={styles.cards}>
                <AboutCard
                  size={'16vw'}
                  avatar={AppAssets.AboutTeam1}
                  name={'Andreas Heuser'}
                  position={'CEO'}
                  mt="3em"
                />
                <AboutCard
                  size={'16vw'}
                  avatar={AppAssets.AboutTeam2}
                  name={'Martín Crupi'}
                  position={'COO'}
                />
                <AboutCard
                  size={'16vw'}
                  avatar={AppAssets.AboutTeam3}
                  name={'Demian Bellumio'}
                  position={'Co-founder'}
                  mt="3em"
                />
                <AboutCard
                  size={'16vw'}
                  avatar={AppAssets.AboutTeam4}
                  name={'Martin Pombo'}
                  position={'CMO'}
                />
              </Box>
            </Box>
          </Box>

          {/*  Selected investors */}
          <Box sx={styles.boardBox}>
            <AppTypography
              type={PDLTypography.largeHeading}
              children={'Selected investors'}
              customStyles={{ fontSize: '2.8em', color: COLORS.white_FFFFFF }}
            />
            <Box sx={styles.cards} mt="-1em">
              <AboutCard
                size={'16vw'}
                avatar={AppAssets.AboutBoard1}
                name={'Marcelo Claure'}
                position={'Chairman and CEO, Claure Capital'}
                mt="3em"
              />
              <AboutCard
                size={'16vw'}
                avatar={AppAssets.AboutBoard2}
                name={'Adriana Cisneros'}
                position={'CEO, Cisneros'}
              />
              <AboutCard
                size={'16vw'}
                avatar={AppAssets.AboutBoard3}
                name={'Jon Oringer'}
                position={'Founder and Executive Chairman, Shutterstock'}
                mt="3em"
              />
            </Box>
            <Box sx={styles.cards} mt="-5em">
              <AboutCard
                size={'16vw'}
                avatar={AppAssets.AboutBoard4}
                name={'Carlos Domingo'}
                position={'Founder and CEO, Securitize'}
                mt="3em"
              />
              <AboutCard
                size={'16vw'}
                avatar={AppAssets.AboutBoard5}
                name={'David Garcia'}
                position={'CEO & Managing Partner, Borderless Capital'}
              />
              <AboutCard
                size={'16vw'}
                avatar={AppAssets.AboutBoard6}
                name={'Mike Byrne'}
                position={'CFO, Timeable'}
                mt="3em"
              />
            </Box>
          </Box>
          <FooterPdlClub showTopSection />
        </Box>
      )}
    </>
  );
}

const styles: SxStyles = {
  initialBoxMobile: {
    backgroundColor: COLORS.black_000000,
    height: '112em',
    textAlign: 'center',
  },
  teamBoxMobile: {
    height: '67.5em',
    backgroundColor: COLORS.white_FFFFFF,
    padding: '1.5em 0 1.5em 0',
    borderRadius: '2.5em 2.5em 0 0',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  boardBoxMobile: {
    height: '105em',
    backgroundColor: COLORS.black_000000,
    paddingTop: '2em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  initialBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: COLORS.black_000000,
    height: '30em',
    textAlign: 'center',
    padding: '0 8vw 0 8vw',
    overflow: 'hidden',
  },
  teamBox: {
    height: '30em',
    backgroundColor: COLORS.white_FFFFFF,
    alignItems: 'center',
    borderRadius: '2.5vw 2.5vw 0vw 0vw',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '3em 0 3em 0',
  },
  advisorBox: {
    height: '30em',
    backgroundColor: COLORS.gray_E3E5E4,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '3em 0 3em 0',
  },
  boardBox: {
    backgroundColor: COLORS.black_000000,
    height: '60em',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '1em 0em 1em 0em',
  },
  cards: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '67.5%',
  },
};
