import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import React from 'react';
import AppTheme from '../../utils/AppTheme';
import { AppFontFamily } from '../../utils/AppFontFamily';

interface Props {
  labels: string[];
  tabContent: JSX.Element[];
  value: number;
  setValue: (newValue: number) => void;
}

export const AppTabs = (props: Props) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    props.setValue(newValue);
  };

  return (
    <Box>
      <StyledTabs value={props.value} onChange={handleChange}>
        {props.labels.map((label, index) => (
          <StyledTab key={index} label={label} />
        ))}
      </StyledTabs>

      {props.tabContent.map((tab, index) => (
        <TabPanel key={index} value={props.value} index={index}>
          {tab}
        </TabPanel>
      ))}
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  );
};

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    variant="fullWidth"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: AppTheme.colors.persianBlue_2238CB,
  },
  backgroundColor: AppTheme.colors.black_01000E,
  paddingTop: '1.2em',
  paddingBottom: '1.2em',
});

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontSize: '0.9em',
    fontFamily: AppFontFamily.SHAPE_BOLD,
    marginRight: theme.spacing(1),
    color: AppTheme.colors.white_FFFFFF,
    '&.Mui-selected': {
      color: AppTheme.colors.white_FFFFFF,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    borderBottom: '1px solid #E3E5E4',
    margin: 0,
  })
);
