import { AppTabs } from '../commons/AppTabs';
import { AppLayout } from '../core/AppLayout';
import { EmptyList } from '../commons/EmptyList';
import { useState, useEffect } from 'react';
import Logger from '../../external/pdl-common/utils/Logger';
import { TripList } from '../Trips/TripList';
import { TripListType } from '../../types/TripListType';
import AppHeader from '../commons/AppHeader';
import ReservationService from '../../services/ReservationService';
import Reservation from '../../model/Reservation';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import { Bike } from '../../external/pdl-common/model/Bike';
import { TripWishlist } from '../Trips/TripWishlist';
import BikeService from '../../services/BikeService';
import { useSwipeable } from 'react-swipeable';
import UserService from '../../services/UserService';
import { AlertDialog } from '../commons/AlertDialog';
import { RideTab } from '../../utils/RideTab';

const logger = new Logger('Trips');

export default function Trips() {
  const navigate = useNavigate();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [upcomingTrips, setUpcomingTrips] = useState<Reservation[]>([]);
  const [wishlistTrips, setWishlistTrips] = useState<Bike[]>([]);
  const [historyTrips, setHistoryTrips] = useState<Reservation[]>([]);
  const [optOffSearchDialog, setOptOffSearchDialog] = useState<boolean>(false);
  const [searchDialogModalOpen, setSearchDialogModalOpen] = useState<boolean>(false);
  const [dialogCheckBoxCheck, setDialogCheckBoxCheck] = useState<boolean>(false);

  const [value, setValue] = useState(0);

  useEffect(() => {
    load();
  }, [value]);

  const handleClickOnSearch = () => {
    if (optOffSearchDialog) {
      appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
    } else {
      setSearchDialogModalOpen(true);
    }
  }

  const getUserOptOffAlertDialog = async () => {
    const storedOptOff = localStorage.getItem("opt-off");
    if (storedOptOff == undefined || storedOptOff == "false") {
      try {
        const response = await UserService.getOptOffSerachDialog();
        setOptOffSearchDialog(response.getContent());
        localStorage.setItem("opt-off", String(response.getContent()));
      } catch (e) {
        logger.error('Error fetching user search dialog preference', e);
      }
    } else {
      setOptOffSearchDialog(Boolean(storedOptOff));
    }
  }

  const handleNavigateToSearch = async () => {
    if (dialogCheckBoxCheck) {
      try {
        const response = await UserService.updateSearchDialogPreference()
        localStorage.setItem("opt-off", "true");
        appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
      } catch (e) {
        logger.error('Error updating user search dialog preference', e);
      }
    } else {
      appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
    }
  }

  const handlers = useSwipeable({
    onSwipedRight: () => {
      if (value > 0) {
        setValue(value - 1);
      }
    },
    onSwipedLeft: () => {
      if (value < 2) {
        setValue(value + 1);
      }
    },
  });

  const load = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      switch (value) {
        case RideTab.UPCOMING:
          const upcoming = await ReservationService.getUpcomingReservationsForRenter(0, 1000);
          setUpcomingTrips(upcoming.getContent().content);
          break;
        case RideTab.WISHLIST:
          const wishlist = await BikeService.getWishList(0, 1000);
          setWishlistTrips(wishlist.getContent().content);
          break;
        case RideTab.HISTORY:
          const history = await ReservationService.getReservationHistoryForRenter(0, 1000);
          setHistoryTrips(history.getContent().content);
          break;
      }
      getUserOptOffAlertDialog();

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      logger.error('Error loading content', error?.response?.data?.message || error);
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  return (
    <AppLayout
      fromHome
      header={() => <AppHeader showBackButton={false} content="Rides" />}
      content={() => {
        if (requestStatus === RequestStatus.ERROR) {
          return (
            <div style={{ height: '70vh' }}>
              <RequestStatusFeedBack status={requestStatus} onRetry={load} />
            </div>
          );
        }

        return (
          <div {...handlers}>
            <AppTabs
              labels={['Upcoming', 'Wishlist', 'History']}
              value={value}
              setValue={setValue}
              tabContent={[
                upcomingTrips.length === 0 ? (
                  <EmptyList
                    title={'No upcoming trips'}
                    subTitle={'Explore locations to book your next trip.'}
                    buttonText={'Start a search'}
                    buttonOnClick={handleClickOnSearch}
                  />
                ) : (
                  <TripList
                    title={'Coming up'}
                    reservationList={upcomingTrips}
                    listType={TripListType.BIKE_CARD}
                  />
                ),
                wishlistTrips.length === 0 ? (
                  <EmptyList
                    title={'Your wishlist is empty'}
                    subTitle={'Search for bikes and add the ones you like.'}
                  />
                ) : (
                  <TripWishlist title={'Wishlist'} bikeList={wishlistTrips} />
                ),
                historyTrips.length === 0 ? (
                  <EmptyList
                    title={'You have not made a reservation yet'}
                    subTitle={'Explore locations to book your next trip.'}
                    buttonText={'Start a search'}
                    buttonOnClick={handleClickOnSearch}
                  />
                ) : (
                  <TripList
                    title={'Past rides'}
                    reservationList={historyTrips}
                    listType={TripListType.BIKE_ITEM}
                  />
                ),
              ]}
            />
            <AlertDialog
              hideCancelButton
              title="Service availability"
              content={"Service only available in Miami."}
              open={searchDialogModalOpen}
              confirmLabel={"Continue"}
              onCheckboxChange={() => setDialogCheckBoxCheck(!dialogCheckBoxCheck)}
              onConfirm={() => {
                setSearchDialogModalOpen(false);
                handleNavigateToSearch();
              }}
              onCancel={() => {
                setSearchDialogModalOpen(false);
              }}
            />
          </div>
        );
      }}
    />
  );
}
