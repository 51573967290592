import { Box } from '@mui/material';
import { GenericCard } from '../core/GenericCard';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { CSSProperties, makeStyles } from '@mui/styles';
import AppTheme from '../../external/pdl-common/utils/AppTheme';

interface Props {
  category?: string;
  title?: string;
  imageUrl?: string;
  onClick?: () => void;
  style?: CSSProperties;
}

export const ContentCard = (props: Props) => {
  const classes = useStyles();

  return (
    <GenericCard
      imageUrl={props.imageUrl}
      onClick={props.onClick}
      imageHeight={205}
      className={classes.cardContainer}
      contentClassName={classes.cardContent}
      elevation={0}
      style={{ cursor: 'pointer', ...props.style }}
    >
      <Box>
        <AppTypography
          type={PDLTypography.paragraph}
          children={props.title}
          customStyles={{ marginTop: '0.5em', color: AppTheme.colors.white_FFFFFF }}
        />
      </Box>
    </GenericCard>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    maxWidth: '95%',
    height: 300,
    borderRadius: '30px !important',
    backgroundColor: AppTheme.colors.persianBlue_2238CB,
  },
  cardContent: {
    textAlign: 'left',
  },
});
