import { useNavigate, useParams } from 'react-router-dom';
import reviewService from '../../services/ReviewService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { LeaveReview } from './LeaveReview';

export function LeaveReviewRenter() {
  const navigate = useNavigate();
  const { externalId } = useParams();

  return (
    <LeaveReview
      userReviewTitle="Review the host"
      checkExistingReview={async (externalId) => {
        await reviewService.getRenterReviewForReservation(externalId);
      }}
      onSubmit={async (form) => await reviewService.createReviewFromRenter(externalId!, form)}
      handleGoBack={() => {
        appNavigate(navigate, AppRoutes.TRIPS_RESERVATION_DETAIL, {
          externalId: externalId!,
        });
      }}
      bikeReview
    />
  );
}
