import { Rating, RatingProps, styled } from '@mui/material';
import AppTheme from '../../../utils/AppTheme';

const CustomRating = styled(Rating)({
  color: AppTheme.colors.persianBlue_2238CB,
});

const AppRating = (props: RatingProps) => {
  return <CustomRating {...props} />;
};

export default AppRating;
