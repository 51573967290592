import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef } from 'react';
import { AccountType } from '../../../../model/AccountType';
import AppSelect from '../../../commons/AppSelect';
import { AppTextField } from '../../../commons/AppTextField';
import { CheckboxWithLabel } from '../../../commons/CheckboxWithLabel';
import { FileData, FileSelection } from '../../../commons/FileSelection';
import { AppTypography, AppTypographyProps } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';
import { BankInformationForm } from '../PayoutMethod';
import { ChangeEvent } from 'react';

interface Props {
  replace?: boolean;
  form: BankInformationForm;
  setForm: (form: BankInformationForm) => void;
  validations: { [P in keyof BankInformationForm]: boolean };
  setValidations: (validations: { [P in keyof BankInformationForm]: boolean }) => void;
  hasAccount: boolean;
}

const BankInformationComponent = ({
  replace,
  form,
  setForm,
  validations,
  setValidations,
  hasAccount,
}: Props) => {
  const confirmAccountConfirmationInput = useRef<{ validate: () => void }>(null);
  const classes = useStyles();

  useEffect(() => {
    if (form.confirmAccountNumber) {
      const isValid = confirmAccountConfirmationInput?.current?.validate();

      let updatedValidations = { ...validations };
      (updatedValidations as any).confirmAccountNumber = isValid;
      setValidations(updatedValidations);
    }
  }, [form.accountNumber]);

  const handleCustomChange = (
    inputName: string,
    inputValue: string | FileData | boolean | undefined,
    isValid: boolean
  ) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);

    let updatedValidations = { ...validations };
    (updatedValidations as any)[inputName] = isValid;
    setValidations(updatedValidations);
  };

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    handleCustomChange(e.target.name, e.target.value as string, e.target.value !== '-1');
  };

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    handleCustomChange(e.target.name, !value, !value);
  };

  return (
    <>
      {!hasAccount && (
        <>
          <AppTypography type={PDLTypography.largeHeading} children={'Account information'} />

          <div className={classes.selectContainer}>
            <AppSelect
              selectOne={false}
              props={{
                name: 'accountCountry',
                label: 'Country *',
                value: form.accountCountry,
                onChange: onSelectChange,
              }}
            >
              <MenuItem value={'US'}>United States</MenuItem>
            </AppSelect>
          </div>

          {/* Social securtity number */}
          <AppTextField
            name={'socialSecurityLast4'}
            label={'Social securtity number (last 4 digits) *'}
            value={form.socialSecurityLast4}
            labelProps={labelProps}
            inputProps={{ type: 'number' }}
            onInputChange={handleCustomChange}
            containerClasses={classes.textFieldContainer}
            realTimeValidation
            minLength={4}
            maxLength={4}
            customLengthValidationMessage={'Social securtity number should be 4 last digits only'}
            required
            restrict="onlyNumbers"
          />

          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1.5em' }}>
            <FileSelection
              labelProps={labelProps}
              label="Identity id *"
              data={form.document}
              setData={(file) => handleCustomChange('document', file, true)}
              onRemove={() => {
                handleCustomChange('document', undefined, false);
              }}
            />
          </div>

          {/* Identity id checkboxes */}
          <Box className={classes.identityIdCheckboxContainer}>
            <AppTypography type={PDLTypography.paragraph}>
              Please make sure the document you're about to upload meets the requirements below. If
              it does, please confirm by checking:
            </AppTypography>

            {/* Document shows legal name */}
            <CheckboxWithLabel
              label={'The document shows exactly this information:'}
              onChange={onCheckboxChange}
              name={'documentShowsLegalName'}
              checked={form.documentShowsLegalName}
              extrainfo={
                <AppTypography
                  type={PDLTypography.smallParagraph}
                  children={'Legal name of the person'}
                  customStyles={{ marginLeft: '.7em' }}
                />
              }
            />

            {/* Document is in color */}
            <CheckboxWithLabel
              label={'The uploaded document is in color'}
              onChange={onCheckboxChange}
              name={'documentIsInColor'}
              checked={form.documentIsInColor}
            />

            {/* Stripe terms of service checkbox */}
            <CheckboxWithLabel
              label={
                <span>
                  You agree to Stripe's{' '}
                  <a href="https://stripe.com/connect-account/legal" target="_blank">
                    terms of service.
                  </a>
                </span>
              }
              onChange={onCheckboxChange}
              name={'agreeWithStripeTOS'}
              checked={form.agreeWithStripeTOS}
            />
          </Box>
        </>
      )}

      {replace ? (
        <>
          {/* Add new bank information */}
          <div className={classes.flexColumn}>
            <AppTypography type={PDLTypography.largeHeading} children={'New bank information'} />
            <AppTypography
              type={PDLTypography.paragraph}
              children={'Add a new account to replace the current connected account.'}
              customStyles={{ marginTop: '1.25em' }}
            />
          </div>
        </>
      ) : (
        <>
          {/* Add first bank information */}
          <div style={{ marginTop: '1.5em' }}>
            <AppTypography type={PDLTypography.largeHeading} children={'Bank information'} />
          </div>
        </>
      )}

      {/* Account type */}
      <div className={classes.selectContainer}>
        <AppSelect
          selectOne={false}
          props={{
            name: 'accountType',
            label: 'Account type *',
            value: form.accountType,
            onChange: onSelectChange,
          }}
        >
          {Object.values(AccountType).map((type, index) => (
            <MenuItem value={type} key={type + index}>
              {type}
            </MenuItem>
          ))}
        </AppSelect>
      </div>

      <div className={classes.selectContainer}>
        <AppSelect
          selectOne={false}
          props={{
            name: 'bankCountry',
            label: 'Country *',
            value: form.bankCountry,
            onChange: onSelectChange,
          }}
        >
          <MenuItem value={'US'}>United States</MenuItem>
        </AppSelect>
      </div>

      {/* First Name */}
      <AppTextField
        name={'firstName'}
        label={'First Name *'}
        value={form.firstName}
        labelProps={labelProps}
        onInputChange={handleCustomChange}
        containerClasses={classes.textFieldContainer}
        realTimeValidation
        minLength={3}
        required
        restrict="onlyLettersAndSpecialCharacters"
      />

      {/* Last Name */}
      <AppTextField
        name={'lastName'}
        label={'Last Name *'}
        value={form.lastName}
        labelProps={labelProps}
        onInputChange={handleCustomChange}
        containerClasses={classes.textFieldContainer}
        realTimeValidation
        minLength={3}
        required
        restrict="onlyLettersAndSpecialCharacters"
      />

      {/* Currency */}
      <div className={classes.selectContainer}>
        <AppSelect
          selectOne={false}
          props={{
            name: 'currency',
            label: 'Currency *',
            value: form.currency,
            onChange: onSelectChange,
          }}
        >
          <MenuItem value={'usd'}>USD</MenuItem>
        </AppSelect>
      </div>

      {/* Routing Number */}
      <AppTextField
        name={'routingNumber'}
        label={'Routing Number *'}
        value={form.routingNumber}
        labelProps={labelProps}
        inputProps={{ type: 'number' }}
        onInputChange={handleCustomChange}
        containerClasses={classes.textFieldContainer}
        realTimeValidation
        minLength={9}
        maxLength={9}
        customLengthValidationMessage={'Routing number should be 9 digits only'}
        required
        restrict="onlyNumbers"
      />

      {/* Account Number */}
      <AppTextField
        name={'accountNumber'}
        label={'Account Number *'}
        value={form.accountNumber}
        labelProps={labelProps}
        inputProps={{ type: 'number' }}
        onInputChange={handleCustomChange}
        containerClasses={classes.textFieldContainer}
        realTimeValidation
        minLength={6}
        maxLength={17}
        customLengthValidationMessage={'Account number should be between 6 and 17 digits'}
        required
        restrict="onlyNumbers"
      />

      {/* Confirm Account Number */}
      <AppTextField
        ref={confirmAccountConfirmationInput}
        name={'confirmAccountNumber'}
        label={'Confirm Account Number *'}
        value={form.confirmAccountNumber}
        labelProps={labelProps}
        inputProps={{ type: 'number' }}
        onInputChange={handleCustomChange}
        containerClasses={classes.textFieldContainer}
        realTimeValidation
        minLength={6}
        maxLength={17}
        customLengthValidationMessage={'Account number should be between 6 and 17 digits'}
        required
        restrict="onlyNumbers"
        customValidation={(text) => {
          if (text !== form.accountNumber) {
            return {
              showError: true,
              isValid: false,
              message: 'Account number confirmation do not match',
            };
          }
          return { showError: false, isValid: true, message: '' };
        }}
      />
    </>
  );
};

export default BankInformationComponent;

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles({
  textFieldContainer: {
    marginTop: '0.625em',
  },
  selectContainer: {
    marginTop: '1em',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  identityIdCheckboxContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2em',
    marginBottom: '2em',
    alignItems: 'flex-start',
  },
});
