import { useState } from 'react';
import BottomSheet from '../commons/BottomSheet';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTextField } from '../commons/AppTextField';
import SimpleButton from '../commons/SimpleButton';
import { makeStyles } from '@mui/styles';
import { IAppTheme } from '../../utils/AppTheme';
import { Box } from '@mui/material';

interface Props {
  suggestedPrice: number | string;
  open: boolean;
  onClick: (newPrice?: number | string) => void;
  onClose: () => void;
  disabled?: boolean;
}

export const HostCustomPriceModal = (props: Props) => {
  const classes = useStyles();
  const [form, setForm] = useState({ newPrice: '' });

  const handleCustomChange = (inputName: string, inputValue: string) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);
  };

  return (
    <BottomSheet open={props.open} onClose={props.onClose}>
      <Box mb={'1.6em'}>
        <AppTypography type={PDLTypography.largeHeading}>Custom listing price</AppTypography>
      </Box>

      <AppTextField
        name="newPrice"
        value={form.newPrice}
        label="Add a price"
        onInputChange={(inputName: string, inputValue: string) =>
          handleCustomChange(inputName, inputValue)
        }
        labelProps={{ type: PDLTypography.subHeading, customClasses: classes.marginBottom }}
        inputProps={{ className: classes.marginBottom, type: 'price' }}
      />

      <AppTypography type={PDLTypography.smallParagraph}>
        {`Based on your bike’s details, we suggest listing it for $${props.suggestedPrice} a day.`}
      </AppTypography>

      <SimpleButton
        onClick={() => props.onClick(form.newPrice)}
        sx={{ marginTop: '1.6em' }}
        disabled={props.disabled}
      >
        <AppTypography type={PDLTypography.paragraph} customClasses={classes.button}>
          List Using Custom Price
        </AppTypography>
      </SimpleButton>
    </BottomSheet>
  );
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  marginBottom: {
    marginBottom: '.6em',
  },
  button: {
    color: theme.colors.white_FFFFFF,
  },
}));
