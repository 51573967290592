import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import environment from '../../../environment.json';
import ApiResponse from '../core/ApiResponse';
import { ResponseData } from '../core/ResponseData';
import AuthService from '../services/AuthService';
import AsyncAuthService from '../services/AsyncAuthService';
import EnvironmentService from '../services/EnvironmentService';

/**
 * @deprecated Use HttpClientV2 instead
 */
class HttpClient {
  private client: AxiosInstance;

  constructor(baseURL: string, requestTimeout?: number) {
    this.client = axios.create({
      baseURL: baseURL,
      timeout: requestTimeout || 5000,
    });

    this.generateConfig().then((config) => {
      if (!!config.headers?.Authorization) {
        axios.defaults.headers.common['Authorization'] = config.headers!.Authorization;
      }
      console.log(axios.defaults.headers.common['Authorization']);
    });
  }

  private getAuthorizationHeader(token: any): AxiosRequestConfig {
    return token ? { headers: { Authorization: 'Bearer ' + token } } : {};
  }

  private generateConfig(): Promise<AxiosRequestConfig> {
    return EnvironmentService.usesAsyncStorageStrategy()
      ? AsyncAuthService.getToken().then((token) => this.getAuthorizationHeader(token))
      : Promise.resolve(this.getAuthorizationHeader(AuthService.getToken()));
  }

  async get<T>(url: string, handleExceptions: boolean = false, callbackOnError?: (error: any) => void): Promise<ApiResponse<T>> {
    if (!handleExceptions) {
      return this.client.get(url, await this.generateConfig()).then((response) => ApiResponse.fromData(response.data));
    }

    return this.client
      .get(url, await this.generateConfig())
      .then((response) => ApiResponse.fromData(response.data as ResponseData<T>))
      .catch((error: any) => this.handleErrors(error, callbackOnError));
  }

  async getWithParams<T>(
    url: string,
    params: any,
    handleExceptions: boolean = false,
    callbackOnError?: (error: any) => void
  ): Promise<ApiResponse<T>> {
    if (!handleExceptions) {
      return this.client
        .get(url, { params: params, ...(await this.generateConfig()) })
        .then((response) => ApiResponse.fromData(response.data));
    }

    return this.client
      .get(url, { params: params, ...(await this.generateConfig()) })
      .then((response) => ApiResponse.fromData(response.data as ResponseData<T>))
      .catch((error: any) => this.handleErrors(error, callbackOnError));
  }

  async post<T, R>(
    url: string,
    payload: T,
    handleExceptions: boolean = false,
    callbackOnError?: (error: any) => void
  ): Promise<ApiResponse<R>> {
    if (!handleExceptions) {
      return this.client.post(url, payload, await this.generateConfig()).then((response) => ApiResponse.fromData(response.data));
    }

    return this.client
      .post(url, payload, await this.generateConfig())
      .then((response) => ApiResponse.fromData(response.data as ResponseData<R>))
      .catch((error: any) => this.handleErrors(error, callbackOnError));
  }

  async put<T, R>(
    url: string,
    payload: T,
    handleExceptions: boolean = false,
    callbackOnError?: (error: any) => void
  ): Promise<ApiResponse<R>> {
    if (!handleExceptions) {
      return this.client.put(url, payload, await this.generateConfig()).then((response) => ApiResponse.fromData(response.data));
    }

    return this.client
      .put(url, payload, await this.generateConfig())
      .then((response) => ApiResponse.fromData(response.data as ResponseData<R>))
      .catch((error: any) => this.handleErrors(error, callbackOnError));
  }

  async delete<T>(url: string, handleExceptions: boolean = false, callbackOnError?: (error: any) => void): Promise<ApiResponse<T>> {
    if (!handleExceptions) {
      return this.client.delete(url, await this.generateConfig()).then((response) => ApiResponse.fromData(response.data));
    }

    return this.client
      .delete(url, await this.generateConfig())
      .then((response) => ApiResponse.fromData(response.data as ResponseData<T>))
      .catch((error: any) => this.handleErrors(error, callbackOnError));
  }

  handleErrors<T>(error: any, callbackOnError?: (error: any) => void) {
    callbackOnError && callbackOnError(error);

    if (!error.response) {
      return ApiResponse.error(500, '', 'An internal error occur, please try again later') as unknown as ApiResponse<T>;
    }
    return ApiResponse.error(
      error.response.status,
      error.response.data.errorCode,
      error.response.data.message
    ) as unknown as ApiResponse<T>;
  }

  async getFile(url: string, config?: AxiosRequestConfig) {
    config  = config || await this.generateConfig();
    config.responseType = 'blob';
    return this.client.get(url, config).then((response) => response);
  }

  async postFile(url: string, data: {} = {}) {
    const config = await this.generateConfig();
    config.responseType = 'blob';
    return this.client.post(url, data, config).then((response) => response);
  }
}

/**
 * @deprecated Use HttpClientV2 instead
 */
export default new HttpClient(environment.baseURL, (environment as any).requestTimeout);
