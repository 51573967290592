import { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Destination } from '../../model/Destination';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import { GenericCard } from '../core/GenericCard';
import DestinationService from '../../services/DestinationService';
import Logger from '../../external/pdl-common/utils/Logger';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import AppTheme from '../../utils/AppTheme';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';

interface Props {}

const logger = new Logger('TopDestinations');

const MAX_DESTINATIONS = 5;

export const TopDestinations = (props: Props) => {
  const classes = useStyles();
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);

  useEffect(() => {
    getTopDestinations();
  }, []);

  const getTopDestinations = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await DestinationService.getAll(0, MAX_DESTINATIONS))
      .onSuccess((response) => {
        setDestinations(response.getContent().content);
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error trying to get the destinations', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  if (requestStatus !== RequestStatus.SUCCESS) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <RequestStatusFeedBack status={requestStatus} onRetry={() => getTopDestinations()} />
      </Box>
    );
  }

  return (
    <Container sx={{ py: 4 }}>
      <Box sx={{ pb: 4 }}>
        <AppTypography
          type={PDLTypography.largeHeading}
          children={'Top Destinations'}
          customStyles={{ color: AppTheme.colors.white_FFFFFF }}
        />
      </Box>

      <Carousel
        itemsToShow={Math.min(destinations.length, MAX_DESTINATIONS)}
        data={destinations}
        infinite={false}
        slidesToShow={{
          lg: 3.5,
          md: 2.5,
          sm: 1.6,
          xs: 1.5,
        }}
        renderItem={(destination, index) => (
          <GenericCard
            key={`${destination.externalId}-${index}`}
            buttonText={destination.name}
            imageUrl={destination.image.url}
            imageTitle={destination.disabledAt ? 'Coming soon' : ''}
            onClick={() => console.log('Navigate to destinations detail: ', destination.externalId)}
            buttonClassName={classes.cardButton}
            elevation={0}
            contentClassName={classes.content}
            className={classes.container}
            sx={{ cursor: 'pointer' }}
          />
        )}
      />
    </Container>
  );
};

const useStyles = makeStyles({
  container: {
    borderRadius: '0.25em',
  },
  cardButton: {
    // @ts-ignore
    textTransform: 'none !important',
    fontSize: '0.90em !important',
    fontFamily: AppFontFamily.SHAPE_BOLD + ' !important',
  },
  content: {
    paddingTop: '0px !important',
  },
});
