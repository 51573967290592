import { Box } from '@mui/material';
import InputBase from '@mui/material/InputBase/InputBase';
import { styled, SxProps } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { AppFontFamily } from '../../utils/AppFontFamily';

interface Props {
  name: string;
  value: string;
  disabled?: boolean;
  icon: JSX.Element;
  placeholder?: string;
  inputClasses?: string;
  inputSx?: SxProps;
  disableAutoFill?: boolean;
  onInputChange?: (inputName: string, inputValue: string) => void;
}

const BasicInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    minWidth: '100%',
    maxWidth: '70vw',
    width: '100vw',
    heigth: '4em',
    fontSize: '0.95em',
    paddingTop: '0.41em',
    paddingLeft: '0.4em',
    fontFamily: AppFontFamily.SHAPE + ' !important',
  },
}));

export const InputWithIcon = (props: Props) => {
  const { name, value, disabled, icon, placeholder, inputClasses, inputSx, disableAutoFill } =
    props;
  const classes = useStyles();

  function handleInputChange(inputElement: React.ChangeEvent<HTMLInputElement>) {
    let { name: inputName, value: inputValue } = inputElement.target;
    props.onInputChange && props.onInputChange(inputName, inputValue);
  }

  return (
    <Box className={classes.form}>
      {icon}
      <BasicInput
        autoFocus
        disabled={disabled ? true : false}
        name={name}
        value={value}
        placeholder={placeholder ? placeholder : ''}
        sx={inputSx ? inputSx : null}
        className={inputClasses ? inputClasses : ''}
        onChange={handleInputChange}
        autoComplete={disableAutoFill ? 'off' : 'on'}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '1.2em',
    paddingLeft: '0.5em',
  },
});
