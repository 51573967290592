import ObservableService from "../core/ObservableService";

export enum NotificationEvents {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

class NotificationService extends ObservableService<NotificationEvents> {
  info(message: string) {
    this.notifyObservers(NotificationEvents.INFO, message);
  }

  warn(message: string) {
    this.notifyObservers(NotificationEvents.WARNING, message);
  }

  error(message: string) {
    this.notifyObservers(NotificationEvents.ERROR, message);
  }
}

export default new NotificationService();
