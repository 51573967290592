import ReservationService from '../../services/ReservationService';
import {ReservationDropOffHostConfirmation} from "../commons/ReservationDropOffHostConfirmation";

export function ReservationPickUpConfirmation() {
    const handleOnConfirm = async (reservationExternalId: string, checkListExternalId: string) =>
        await ReservationService.pickUp(reservationExternalId, checkListExternalId);
    const handleOnCancel = async (reservationExternalId: string, checkListExternalId: string) =>
        await ReservationService.cancelPickUp(reservationExternalId, checkListExternalId);

    return <ReservationDropOffHostConfirmation confirmationType="pick-up" onConfirm={handleOnConfirm} onCancel={handleOnCancel}/>;
}
