import { Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IAppTheme } from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

const useStyles = makeStyles((theme: IAppTheme) => ({
  cell: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    padding: '3em 0em',
  },
  allTimeStatsContainer: {
    backgroundColor: theme.colors.black_01000E,
    display: 'flex',
  },
  allTimeStatsText: {
    color: theme.colors.white_FFFFFF,
  },
  allTimeStats: {
    backgroundColor: 'white',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1.5em',
    marginBottom: '2em',
  },
}));

interface Props {
  data: {
    rentals: number;
    revenue: number;
  };
}

export default function AllTimeStatsComponent(props: Props) {
  function Cell(props: { label: string; value: string | number }) {
    return (
      <div className={classes.cell}>
        <AppTypography type={PDLTypography.largeHeading} customStyles={{ textAlign: 'center' }}>
          {props.value}
        </AppTypography>
        <AppTypography type={PDLTypography.subHeadingSerif}> {props.label}</AppTypography>
      </div>
    );
  }

  const classes = useStyles();

  return (
    <div className={classes.allTimeStatsContainer}>
      <Container>
        <AppTypography type={PDLTypography.mediumHeading} customClasses={classes.allTimeStatsText}>
          All - time stats
        </AppTypography>
        <div className={classes.allTimeStats}>
          <Cell label="Rentals" value={props.data.rentals} />
          <Divider orientation="vertical" flexItem />
          <Cell label="Revenue" value={`$${props.data.revenue.toFixed(2)}`} />
        </div>
      </Container>
    </div>
  );
}
