import { AppAutocompleteOptions } from '../components/commons/AppAutocomplete';
import FormOptions from '../model/FormOptions';
import { BikeFilters, FilterOption } from '../model/utils/BikeFilters';

class FormUtils {
  public afterWritingFirstChar = (value: string) => value.length > 0;

  public afterChangingDate = (value: Date) => Math.abs(Date.now() - value.getTime()) > 1000;

  public afterAddingElement = (value: String[]) => value.length > 0;

  /**
   * This method is used to convert FormOptions properties into AppAutocompleteOptions array.
   * @param options FormOptions property. For example, FormOptions.size.
   * @returns AppAutocompleteOptions array.
   */
  public convertToAppAutocompleteOptions = (options: string[]): AppAutocompleteOptions[] => {
    return options.filter((o) => o !== 'Other').map((option) => ({ label: option, value: option }));
  };

  /**
   * This method is used to convert FormOptions properties into BikeFilters.
   * @param formOptions FormOptions.
   * @returns BikeFilters.
   */
  public convertToBikeFilters = (formOptions: FormOptions): BikeFilters => {
    const bikeTypes = this.groupBikeTypes(formOptions.types);
    const bikeSizes = this.groupBikeSizes(formOptions.sizes);
    const bikeFrameMaterials = this.groupBikeFrameMaterials(formOptions.frameMaterials);

    return { bikeTypes, bikeSizes, hideUnavailable: false };
  };

  private groupBikeTypes = (bikeTypes: string[]): FilterOption[] => {
    return bikeTypes.map((bikeType) => ({ label: bikeType, values: [bikeType] }));
  };

  private groupBikeFrameMaterials = (bikeFrameMaterials: string[]): FilterOption[] => {
    return bikeFrameMaterials.map((bikeFrameMaterial) => ({
      label: bikeFrameMaterial,
      values: [bikeFrameMaterial],
    }));
  };

  private groupBikeSizes = (bikeSizes: string[]): FilterOption[] => {
    return [
      {
        label: 'XX-Small',
        values: bikeSizes.filter((bs) => bs.substring(4).startsWith('XX-Small')),
      },
      { label: 'X-Small', values: bikeSizes.filter((bs) => bs.substring(4).startsWith('X-Small')) },
      { label: 'Small', values: bikeSizes.filter((bs) => bs.substring(4).startsWith('Small')) },
      { label: 'Medium', values: bikeSizes.filter((bs) => bs.substring(4).startsWith('Medium')) },
      { label: 'Large', values: bikeSizes.filter((bs) => bs.substring(4).startsWith('Large')) },
      { label: 'X-Large', values: bikeSizes.filter((bs) => bs.substring(4).startsWith('X-Large')) },
    ];
  };
}

export default new FormUtils();
