import Image from "../core/Image";
import GenericCrudService from "./GenericCrudService";
import environment from "../../../environment.json";
import ApiResponse from "../core/ApiResponse";
import HttpClient from "../utils/HttpClient";
import ImageUtils from "../utils/ImageUtils";
import { ImageResizingProperties } from "../core/ImageResizingProperties";

class ImageService extends GenericCrudService<Image> {
  obtainExtension(file: File) {
    return "." + file["name"].split(".").slice(-1).pop();
  }

  uploadImage(
    file: File | Blob,
    fileName: string,
    imageDTO: any,
    size?: number,
  ): Promise<ApiResponse<Image>> {
    const formData = new FormData();
    const fileExtension: string =
      file instanceof File ? this.obtainExtension(file) : "";
    formData.append("file", file, `${fileName}${fileExtension}`);

    return this.uploadImageToBackend(formData, imageDTO, size);
  }

  async uploadCompressImage(
    file: File,
    fileName: string,
    imageDTO: any,
    imageResizingProperties: ImageResizingProperties,
    size?: number,
  ): Promise<ApiResponse<Image>> {


    const resizedFileURI = await ImageUtils.resizeFile(file, imageResizingProperties) as string;
    const resizedFile = ImageUtils.createFileUsingBase64(resizedFileURI);

    const formData = new FormData();
    const fileExtension: string =
      resizedFile instanceof File ? this.obtainExtension(resizedFile) : "";
    formData.append("file", resizedFile, `${fileName}${fileExtension}`);

    return this.uploadImageToBackend(formData, imageDTO, size);
  }



  uploadImageToBackend(
    formData: FormData,
    imageDTO: any,
    size?: number,
  ): Promise<ApiResponse<Image>> {
    if (size) {
      return HttpClient.post(`${this.basePath}/upload-limit/${size}`, formData);
    } else {
      return HttpClient.post(`${this.basePath}/upload`, formData);
    }
  }
}

export default new ImageService(`${environment.baseURL}/images`); // TODO: check how to pass the environment in a better way
