import { Container, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllTimeEarnings } from '../../../model/AllTimeEarnings';
import { Bike } from '../../../external/pdl-common/model/Bike';
import { MonthlyBreakdown } from '../../../model/utils/MonthlyBreakdown';
import BikeService from '../../../services/BikeService';
import EarningService from '../../../services/EarningService';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { IAppTheme } from '../../../utils/AppTheme';
import Logger from '../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../utils/RequestStatus';
import AllTimeStatsComponent from '../../commons/AllTimeStatsComponent';
import AppHeader from '../../commons/AppHeader';
import AppMenuList, { Menu } from '../../commons/AppMenuList';
import MonthlyBreakdownComponent from '../../commons/MonthlyBreakdownComponent';
import RequestStatusFeedBack from '../../commons/RequestStatusFeedback';
import { AppLayout } from '../../core/AppLayout';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import AlertService from '../../../services/AlertService';

const logger = new Logger('Earnings');

const Earnings = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [allTimeEarnings, setAllTimeEarnings] = useState<AllTimeEarnings | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [monthlyBreakdown, setMonthlyBreakdown] = useState<MonthlyBreakdown[]>([]);
  const [bikes, setBikes] = useState<Bike[]>([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const responses = await Promise.all([
        EarningService.getMonthlyBreakdown(),
        BikeService.getBikeListing(0, 1000, ''),
        EarningService.getAllTimeEarnings(),
      ]);

      setMonthlyBreakdown(responses[0].getContent());
      setBikes(responses[1].getContent().content);
      setAllTimeEarnings(responses[2].getContent());

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      logger.error('Error fetching earnings', error);
      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackCustomError('Error fetching earnings');
      }
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const getMenus = (): Menu[] => {
    let menus: Menu[] = [];

    bikes.forEach((item) => {
      menus.push({
        text: item.title,
        action: () =>
          appNavigate(navigate, AppRoutes.EARNING_DETAILS, { externalId: item.externalId! }),
      });
    });

    return menus;
  };

  const getContent = (): JSX.Element => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={load} />
        </div>
      );
    }

    return (
      <>
        {/* All time stats */}
        {allTimeEarnings && <AllTimeStatsComponent data={allTimeEarnings} />}

        {/* Breakdown Per Bike */}
        <Container className={classes.quickLinksContainer} sx={muiStyles.sectionsContainer}>
          <AppTypography type={PDLTypography.largeHeading} customClasses={classes.sectionTitle}>
            Breakdown Per Bike
          </AppTypography>
          <AppMenuList menus={getMenus()} />
        </Container>

        {/* Monthly Breakdown */}
        <Container sx={muiStyles.sectionsContainer}>
          <MonthlyBreakdownComponent data={monthlyBreakdown} />
        </Container>
      </>
    );
  };

  return <AppLayout header={() => <AppHeader content="Earnings" />} content={() => getContent()} />;
};

export default Earnings;

const muiStyles = {
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  } as SxProps,
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  quickLinksContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
    backgroundColor: theme.colors.white_EEEEEC,
  },
}));
