import ApiResponse from '../core/ApiResponse';
import { AuthenticationToken } from '../core/AuthenticationToken';
import GenericResponse from '../core/GenericResponse';
import PublicAuthenticationToken from '../core/PublicAuthenticationToken';
import httpClient from '../utils/HttpClient';
import AlertService from './AlertService';

/**
 * @deprecated Use CustomAuthServiceV2 instead
 */
class CustomAuthService {
  getLoginMethod(email: string): Promise<ApiResponse<string>> {
    return httpClient.get(`/auth/login-method?email=${email}`);
  }

  validateLogin(accessUrl: string, email: string, accessKey: string): Promise<ApiResponse<PublicAuthenticationToken>> {
    return httpClient.post(`/auth${accessUrl}`, {
      email,
      password: accessKey,
    });
  }

  validateLoginWithEmailAndPassword(email: string, password: string): Promise<ApiResponse<PublicAuthenticationToken>> {
    return this.validateLogin('/login', email, password);
  }

  validateLoginWithCode(email: string, code: string): Promise<ApiResponse<AuthenticationToken>> {
    return httpClient.post(`/auth/login-with-code`, {
      email,
      code,
    });
  }

  startLoginWithCode(email: string) {
    return httpClient.post(`/auth/login-code`, {
      email,
    });
  }

  register(
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirmation: string,
    email: string
  ): Promise<ApiResponse<GenericResponse>> {
    return httpClient.post(`/auth/sign-up`, {
      firstName,
      lastName,
      password,
      passwordConfirmation,
      email,
    });
  }

  registerToCustomPath(
    firstName: string,
    lastName: string,
    password: string,
    passwordConfirmation: string,
    phone: string,
    email: string,
    customAuthPath: Function
  ): Promise<ApiResponse<GenericResponse>> {
    return httpClient.post(customAuthPath(), {
      firstName,
      lastName,
      password,
      passwordConfirmation,
      phone,
      email,
    });
  }

  forgotPassword(email: string) {
    return httpClient.post(`/auth/forgot-password`, {
      email,
    });
  }

  validateForgotPassword(email: string, validationCode: string, password: string) {
    return httpClient.post(`/auth/validate-forgot-password`, {
      email,
      validationCode,
      password,
    });
  }

  validateAccount(code: string, email: string) {
    return httpClient.get(`/auth/validate?code=${code}&email=${email}`);
  }

  oAuthLogout() {
    return httpClient.post(`/oauth/logout`, {});
  }

  updatePassword(email: string, password: string): Promise<ApiResponse<GenericResponse>> {
    return AlertService.handleRequest(
      httpClient.put(`/auth/update-password`, { email, password }),
      true,
      "Password updated successfully"
    )
  }
}

/**
 * @deprecated Use CustomAuthServiceV2 instead
 */
export default new CustomAuthService();
