import { Container, Paper, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { AppAssets } from '../../assets';
import AlertService from '../../services/AlertService';
import AppHeader from '../commons/AppHeader';
import SimpleButton from '../commons/SimpleButton';
import { AppLayout } from '../core/AppLayout';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import environment from '../../environment.json';

const InviteFriends = () => {
  const classes = useStyles();
  const location = useLocation().state as any;

  const inviteCode: string = (location?.inviteCode as string | undefined) || '';

  const copyText = (text: string) => {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // ref: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript/33928558#33928558
      const input = document.createElement('input');

      input.setAttribute('value', text);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    }
  };

  const getContent = (): JSX.Element => {
    return (
      <Container sx={{ p: '0 !important' }}>
        <div className={classes.imageContainer}>
          <Paper sx={muiStyles.paperStyles}>
            <AppTypography type={PDLTypography.smallHeading} children={'Your Invite Code'} />
            <AppTypography
              type={PDLTypography.largeHeading}
              children={inviteCode}
              customClasses={classes.inviteCode}
            />
            <AppTypography
              type={PDLTypography.paragraph}
              children={
                'PDL Club is still invite only. Invite your cyclist friends to join the community.'
              }
            />

            <SimpleButton
              sx={{ mb: 1.5, mt: 4 }}
              onClick={() => {
                copyText(inviteCode);
                AlertService.showSuccessMessage('Invite code copied to clipboard');
              }}
            >
              Copy to clipboard
            </SimpleButton>
            <SimpleButton
              sx={{ mb: 1.5, mt: 1.5 }}
              onClick={() => {
                let body = `I'm using PDL Club, a platform for peer-to-peer performance bike rentals.\n
                Enter ${environment.appURL} and use my invite code to sign up: ${inviteCode}`;

                // @ts-ignore
                window.location = `mailto:?subject=Join me on PDL Club
                  &body=${encodeURIComponent(body)}`;
              }}
            >
              Email
            </SimpleButton>
            {/* The button below was intended for mobile? */}
            {/* <SimpleButton sx={{ mb: 1.5, mt: 1.5 }}>Share</SimpleButton> */}
          </Paper>
        </div>
      </Container>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content={'Invite friends'} />}
      content={() => getContent()}
    />
  );
};

export default InviteFriends;

const muiStyles = {
  paperStyles: {
    width: '75%',
    maxWidth: '31.25em',
    backgroundColor: 'white',
    borderRadius: '1.875em',
    display: 'flex',
    flexDirection: 'column',
    padding: '1.25em',
    textAlign: 'center',
  } as SxProps,
};

const useStyles = makeStyles({
  imageContainer: {
    backgroundImage: `url("${AppAssets.InviteFriends}")`,
    width: '100%',
    height: 'calc(100vh - 6.25em)',
    '@media  screen and  (orientation:landscape)': {
      height: '100vh',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inviteCode: {
    marginTop: '0.3em',
    marginBottom: '0.3em',
    fontSize: '2.313em',
  },
});
