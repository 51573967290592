import { Box, checkboxClasses } from '@mui/material';
import AppTheme from '../../utils/AppTheme';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTypography } from '../Typography/AppTypography';
import { SxStyles } from '../../model/utils/SxStyles';
import { AppCheckbox } from '../commons/AppCheckbox';

interface Props {
  label: string;
  value: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
  key?: string;
}

export const FilterItem = (props: Props) => {
  return (
    <Box sx={styles.container}>
      <AppTypography
        type={PDLTypography.paragraph}
        children={props.label}
        customStyles={{ fontSize: '0.87em', marginTop: '0.5em' }}
      />

      <Box>
        <AppCheckbox
          key={props.label}
          value={props.value}
          checked={props.checked}
          style={{ padding: 4 }}
          onChange={(event) => {
            props.onChange(event.target.checked);
          }}
        />
      </Box>
    </Box>
  );
};

const styles: SxStyles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  checkbox: {
    [`&, &.${checkboxClasses.checked}`]: {
      color: AppTheme.colors.persianBlue_2238CB,
    },
  },
};
