import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import Reservation from '../../../model/Reservation';
import AlertService from '../../../services/AlertService';
import ReservationService from '../../../services/ReservationService';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import AppTheme, { IAppTheme } from '../../../utils/AppTheme';
import { Debouncer } from '../../../utils/Debouncer';
import Logger from '../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../utils/RequestStatus';
import StringUtils from '../../../utils/StringUtils';
import AppHeader from '../../commons/AppHeader';
import { CardWithCells } from '../../commons/CardWithCells';
import RequestStatusFeedBack from '../../commons/RequestStatusFeedback';
import { AppLayout } from '../../core/AppLayout';
import DateUtils from '../../../external/pdl-common/utils/DateUtils';

const logger = new Logger('ReservationHistory');
const PAGE_SIZE = 12;

const ReservationHistory = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [pageData, setPageData] = useState<{ number: number; totalPages: number }>({
    number: 0,
    totalPages: 0,
  });

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await ReservationService.getReservationHistoryForHost(0, PAGE_SIZE))
      .onSuccess((response) => {
        const content = response.getContent();
        setReservations(content.content);
        setPageData({
          number: content.number,
          totalPages: content.totalPages,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error loading content', response.getContent());
        AlertService.showSnackCustomError(response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const loadMoreReservations = async () => {
    Debouncer.debounce(
      'loadMoreReservations',
      async () => {
        if (pageData.number >= pageData.totalPages) {
          return;
        }

        (await ReservationService.getReservationHistoryForHost(pageData.number + 1, PAGE_SIZE))
          .onSuccess((response) => {
            setReservations([...reservations, ...response.getContent().content]);
            setPageData({ ...pageData, number: pageData.number + 1 });
          })
          .onError((response) => {
            logger.error('Error loading more reservations', response.getContent());
            setRequestStatus(RequestStatus.ERROR);
          });
      },
      100
    );
  };

  const renderItem = (r: Reservation) => {
    return (
      <CardWithCells
        key={r.externalId}
        className={classes.bikeCard}
        title={r.bike.title}
        imageUrl={r.bike.images && r.bike.images.length > 0 ? r.bike.images[0].url : ''}
        onClick={() =>
          appNavigate(navigate, AppRoutes.HOST_RESERVATION_DETAIL, {
            externalId: r.externalId!,
          })
        }
        cells={[
          {
            label: 'Rented by',
            value: StringUtils.formatShortUserName(r.renter.user),
          },
          {
            label: 'Dates',
            value: DateUtils.formattedRangeDates(new Date(r.startDate), new Date(r.endDate)),
          },
        ]}
        elevation={3}
      />
    );
  };

  const getContent = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={load} />
        </div>
      );
    }

    return (
      <>
        <div style={{ flex: 1, display: 'flex', padding: '1.875em 1em' }}>
          <div
            style={{
              flex: 1,
              position: 'relative',
            }}
          >
            {reservations.length > 0 ? (
              <InfiniteScroll
                dataLength={reservations.length}
                hasMore={pageData.number < pageData.totalPages}
                next={loadMoreReservations}
                scrollThreshold={1}
                className={classes.infiniteScroll}
                loader={
                  <div className={classes.loader}>
                    <CircularProgress color="inherit" />
                  </div>
                }
              >
                {reservations.map((reservation, index) => renderItem(reservation))}
              </InfiniteScroll>
            ) : (
              <p style={{ textAlign: 'center' }}>None of your bikes have been reserved yet.</p>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content="Reservation history" />}
      content={() => getContent()}
    />
  );
};

export default ReservationHistory;

const useStyles = makeStyles((theme: IAppTheme) => ({
  bikeCard: {
    width: '100%',
    maxWidth: '18.75em',
    marginBottom: '2em',
    cursor: 'pointer',
    paddingBottom: '0.7em',
  },
  list: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '2em',
    position: 'absolute',
    maxHeight: '100vh',
    overflowY: 'scroll',
    paddingBottom: '10em',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: AppTheme.colors.persianBlue_2238CB,
  },
  infiniteScroll: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '2em',
    justifyContent: 'center',
  },
}));
