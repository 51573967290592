import { Destination } from '../model/Destination';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import environment from '../environment.json';
import HttpClient from '../external/fox-typescript/utils/HttpClient';

class DestinationService extends GenericCrudService<Destination> {
  async create(instance: Destination): Promise<ApiResponse<Destination>> {
    throw new Error('Method not implemented.');
  }

  async delete(instance: Destination): Promise<ApiResponse<Destination>> {
    throw new Error('Method not implemented.');
  }

  async update(instance: Destination): Promise<ApiResponse<Destination>> {
    throw new Error('Method not implemented.');
  }

  async getAll(page: number, pageSize: number): Promise<ApiResponse<PagedResult<Destination>>> {
    return await HttpClient.get<PagedResult<Destination>>(
      `${environment.baseURL}/destination?page=${page}&pageSize=${pageSize}`,
      true
    );
  }
}

export default new DestinationService(`${environment.baseURL}/destination`);
