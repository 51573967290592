import { Button, Chip, Card, CardActions, CardContent, SxProps } from '@mui/material';
import { CSSProperties, makeStyles } from '@mui/styles';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import AppImage from '../../external/pdl-common/components/commons/AppImage';

export interface GenericCardProps {
  title?: string;
  imageTitle?: string;
  imageUrl?: string;
  hideImage?: boolean;
  imageHeight?: number;
  buttonText?: string;
  chipText?: string;
  notAvailable?: string;
  rightIcons?: () => JSX.Element;
  onClick?: () => void;

  // Styles for card container
  style?: CSSProperties;
  className?: string;
  sx?: SxProps;
  elevation?: number;

  // Styles for CardContent
  contentStyle?: CSSProperties;
  contentClassName?: string;

  // Styles for title
  titleStyle?: CSSProperties;
  titleClassName?: string;

  // Styles for button
  buttonStyle?: CSSProperties;
  buttonClassName?: string;

  children?: JSX.Element;

  rightIconsContainerStyles?: CSSProperties;
}

export function GenericCard(props: GenericCardProps) {
  const classes = useStyles();

  return (
    <Card
      className={`${classes.cardContainer} ${props.className || ''}`}
      style={props.style}
      sx={props.sx}
      elevation={props.elevation}
    >
      {!props.hideImage && (
        <div style={{ position: 'relative', opacity: props.notAvailable ? '0.5' : '1' }}>
          <div onClick={props.onClick}>
            <AppImage
              src={props.imageUrl || ''}
              height={props.imageHeight || defaultImageHeight}
              alt={`${props.title} image`}
            />

            {props.imageTitle && (
              <div className={classes.imageTitleContainer}>
                <AppTypography
                  type={PDLTypography.mediumHeading}
                  children={props.imageTitle}
                  customClasses={classes.imageTitle}
                />
              </div>
            )}

            {props.chipText && (
              <div className={classes.chipContainer}>
                <Chip label={props.chipText} className={classes.chip} />
              </div>
            )}

            {props.notAvailable && (
              <div className={classes.chipContainer}>
                <Chip label={props.notAvailable} className={classes.notAvailable} />
              </div>
            )}
          </div>

          {props.rightIcons && (
            <div style={props.rightIconsContainerStyles}>{props.rightIcons()}</div>
          )}
        </div>
      )}

      <CardContent
        className={`${classes.contentContainer} ${props.contentClassName || ''}`}
        onClick={props.onClick}
      >
        {props.title && (
          <>
            <AppTypography
              type={PDLTypography.largeHeading}
              children={props.title}
              customClasses={props.titleClassName || ''}
            />
          </>
        )}

        {props.children}

        {props.buttonText && (
          <CardActions>
            <Button
              size="small"
              onClick={props.onClick}
              className={`${classes.buttonText} ${props.buttonClassName || ''}`}
              style={props.buttonStyle}
            >
              {props.buttonText}
            </Button>
          </CardActions>
        )}
      </CardContent>
    </Card>
  );
}

export const defaultImageHeight = 140;

const useStyles = makeStyles({
  cardContainer: {
    maxWidth: 170,
    borderRadius: 30,
  },
  imageTitleContainer: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    height: '100%',
    width: '100%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '50%',
    zIndex: 2,
  },
  chip: {
    marginTop: 15,
    marginLeft: 15,
    backgroundColor: '#FFFFFF !important',
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.SHAPE_BOLD + ' !important',
    fontSize: '0.9em !important',
  },
  notAvailable: {
    marginTop: 150,
    marginLeft: 37,
    backgroundColor: '#FFFFFF !important',
    color: AppTheme.colors.persianBlue_2238CB,
    fontFamily: AppFontFamily.SHAPE_BOLD + ' !important',
    fontSize: '0.9em !important',
  },
  imageTitle: {
    color: '#FFFFFF',
  },
  contentContainer: {
    paddingBottom: '0px !important',
    justifyContent: 'flex-start !important',
  },
  buttonText: {
    width: '100%',
    color: '#01000E !important',
    fontSize: '0.90em',
  },
});
