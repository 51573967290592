import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import ReportIssueCreation from '../model/creation/ReportIssueCreation';
import { ReportIssue } from '../model/ReportIssue';
import RequestSupportCreation from '../model/creation/RequestSupportCreation';

class ReportIssueService {
  
  async createIssue(reportIssueCreation: ReportIssueCreation): Promise<ApiResponse<ReportIssue>> {
    return await HttpClient.post('/report-issue', reportIssueCreation, true);
  }
  async createSupport(requestSupportCreation: RequestSupportCreation): Promise<ApiResponse<string>> {
    return await HttpClient.post('/request-support', requestSupportCreation, true);
  }
}

export default new ReportIssueService();
