import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TopIcons from './TopIcons';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Bike } from '../../model/Bike';
import ImageGallery from './ImageGallery';
import { Box } from '@mui/system';

interface Props<T> {
  bike?: Bike;
  addToWishList?: () => Promise<void>;
  itemsToShow?: number;
  infinite?: boolean;
  showArrows?: boolean;
  slidesToShow?: {
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
  };
  showTopBar?: boolean;
  handleOnBack?: () => void;
  showIndexIndicator?: boolean;
  className?: string;
  data: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  emptyComponent?: JSX.Element;
  indexStyle?: string;
  initialSlide?: number;
  afterChange?: (index: number) => void;
  noOpenGallery?: boolean;
  prevArrow?: JSX.Element | undefined;
  nextArrow?: JSX.Element | undefined;
}

export function Carousel<T>(props: Props<T>) {
  const classes = useStyles();
  const sliderRef = useRef(null);

  const [totalElements, setTotalElements] = useState<number>(1);
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [openImageGallery, setOpenImageGallery] = useState<boolean>(false);

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      // @ts-ignore valid prop
      setTotalElements(sliderRef.current.props.children.length);
    }
  }, []);

  const settings: Settings = {
    infinite: props.infinite,
    slidesToShow: props.itemsToShow || 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: props.slidesToShow?.lg || 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: props.slidesToShow?.md || 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: props.slidesToShow?.sm || 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: props.slidesToShow?.xs || 1,
          slidesToScroll: 1,
        },
      },
    ],
    arrows: props.showArrows,
    prevArrow: props.prevArrow,
    nextArrow: props.nextArrow
  };

  if (!props.data || props.data.length === 0) {
    return props.emptyComponent || <></>;
  }

  return (
    <>
      <div style={{ position: 'relative' }} className={props.className}>
        <Slider
          adaptiveHeight
          {...settings}
          afterChange={(index: number) => {
            props.afterChange && props.afterChange(index);
            setCurrentIndex(index + 1);
          }}
          ref={sliderRef}
          className={classes.slider}
          initialSlide={props.initialSlide || 0}
        >
          {props.data.map((v, i) => (
            <Box key={i} onClick={() => setOpenImageGallery(!props.noOpenGallery)}>{props.renderItem(v, i)}</Box>
          ))}
        </Slider>
        {props.showTopBar && (
          <TopIcons
            showBackButton={true}
            favourite={props.bike?.favourite}
            onClickFav={() => props.addToWishList && props.addToWishList()}
            handleOnBack={() => props.handleOnBack && props.handleOnBack()}
          />
        )}
        {props.showIndexIndicator && (
          <div className={props.indexStyle ? props.indexStyle : classes.sliderIndexContainer}>
            <div>
              {currentIndex} of {totalElements}
            </div>
          </div>
        )}
      </div>

      {openImageGallery && (
        <ImageGallery
          data={props.data}
          onClose={() => setOpenImageGallery(false)}
          renderItem={props.renderItem}
          currentIndex={currentIndex}
          initialSlide={currentIndex - 1}
          itemsToShow={props.itemsToShow}
          showIndexIndicator={props.showIndexIndicator}
          infinite={props.infinite}
        />
      )}
    </>
  );
}

const useStyles = makeStyles({
  sliderIndexContainer: {
    color: 'white',
    backgroundColor: 'rgba(1, 0, 14, 0.5)',
    borderRadius: '3.125em',
    padding: '0.25em',
    border: '0.125em solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: '2.5em',
    right: '1.25em',
  },
  slider: {
    '& .slick-list': {
      paddingLeft: '0em',
      paddingBottom: '0.3em',
    },
  },
});
