import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

interface Props {
  confirmLabel?: string;
  cancelLabel?: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  content: string;
  onCheckboxChange?: () => void;
  hideCancelButton?: boolean;
}

export function AlertDialog(props: Props) {
  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <DialogTitle>
        <AppTypography type={PDLTypography.subHeading}>{props.title}</AppTypography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <AppTypography type={PDLTypography.paragraph}>{props.content}</AppTypography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.onCheckboxChange &&
          <FormControlLabel control={
            <Checkbox size='small' defaultValue={"false"} onChange={props.onCheckboxChange} />
          } componentsProps={
            {
              typography: {
                sx: {
                  fontSize: "small",
                  marginTop: "2px",
                  marginLeft: "-5px",
                  alignSelf: "center",
                  zIndex: 10
                }
              }
            }
          } label="Don't show me this again" />
        }
        {!props.hideCancelButton &&
          <Button onClick={props.onCancel} style={{ fontFamily: AppFontFamily.INTER }}>
            {props.cancelLabel || 'Cancel'}
          </Button>
        }
        <Button onClick={props.onConfirm} style={{ fontFamily: AppFontFamily.INTER }}>
          {props.confirmLabel || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
