import { AppLayout } from '../core/AppLayout';
import { Container, SxProps } from '@mui/material';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { COLORS, IAppTheme } from '../../utils/AppTheme';
import { AppAssets } from '../../assets';
import SimpleButton from '../commons/SimpleButton';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import TermsAndConditionsCheckbox from '../commons/TermsAndConditionsCheckbox';
import { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import AlertService from '../../services/AlertService';

const muiStyles = {
  mainContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    p: '0 !important',
  } as SxProps,
  sectionsContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '2em',
    position: 'relative',
  } as SxProps,
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  bodyContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  titleContainer: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url("${AppAssets.HostPresentation}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'start',
  },
  titleText: {
    fontSize: '2em',
    textAlign: 'center',
    color: COLORS.white_FFFFFF,
  },
  button: {
    position: 'absolute',
    bottom: '2em',
    minWidth: '70%',
    margin: '0% 15%',
  },
}));

const Section = (props: { title: string; content: string }) => {
  return (
    <>
      <AppTypography
        type={PDLTypography.mediumHeading}
        customStyles={{ marginTop: '1em', marginBottom: '0.5em' }}
      >
        {props.title}
      </AppTypography>
      <AppTypography type={PDLTypography.paragraph}>{props.content}</AppTypography>
    </>
  );
};

export default function HostWelcome() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [termCheckBox, setTermCheckbox] = useState<boolean>(false);
  const [showTermBox, setShowTermBox] = useState<boolean>(true);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const stored = localStorage.getItem("termsAndConditions")
    const storedTermsAndConditions = stored ? JSON.parse(stored) : false;

    try {
      if (storedTermsAndConditions) {
        setShowTermBox(!storedTermsAndConditions);
        localStorage.setItem("termsAndConditions", String(storedTermsAndConditions));
      } else {
        const termsAndConditions = (await UserService.getTermsAndConditionsAgreed()).getContent();
        setShowTermBox(!termsAndConditions);
        localStorage.setItem("termsAndConditions", String(termsAndConditions));
      }
    } catch (error: any) {
    }
  };

  const handleClickOnAddListing = async() => {
    try {
      await UserService.updateTermsAndConditionAgreed();
      appNavigate(navigate, AppRoutes.HOST_BIKE_FIRST_STEP, null);
    } catch (error: any) {
      AlertService.showSnackInternalError();
    }
  }

  return (
    <AppLayout
      fromHome
      content={() => (
        <Container sx={muiStyles.mainContainer}>
          <div className={classes.bodyContainer}>
            <Container sx={{ p: '0px !important' }}>
              <div className={classes.titleContainer}>
                <div style={{ flex: 3 }}></div>
                <div className={classes.title}>
                  <AppTypography
                    type={PDLTypography.largeHeading}
                    customClasses={classes.titleText}
                  >
                    List your bike
                  </AppTypography>
                  <AppTypography
                    type={PDLTypography.largeHeading}
                    customClasses={classes.titleText}
                  >
                    in 5 minutes
                  </AppTypography>
                </div>
              </div>
            </Container>
            <Container sx={muiStyles.sectionsContainer}>
              <AppTypography type={PDLTypography.largeHeading}>
                Share your passion and earn money
              </AppTypography>

              <Section
                title="How it works"
                content="PDL CLUB is a cycling community consisting of like minded cyclists with a passion for performance bicycles. Our mission is to provide more accessibility and knowledge to cyclists everywhere. PDL CLUB is aiming to be the first peer to peer bike sharing platform geared towards offering a higher standard of bicycles. At this time we are a by invitation only community. To get in, you need to be invited by a current member.
                Building trust and confidence is a key factor for the PDL community in securing a strong and safe environment."
              />
              <Section
                title=""
                content="As a PDL Club member, you have the opportunity to search for and rent bicycles from other verified members or additionally to become a host and share your own bicycle(s) on the platform."
              />
              <Section
                title=""
                content="After uploading the necessary information about your bike, PDL Club will evaluate and either approve your listing or in very seldom cases ask for more information in order to rule out any discrepancies that we have noticed."
              />

              <Section
                title="Extra income"
                content="Whether you have one bike that is not being 100% utilized or you are interested in listing multiple bikes as a business model, PDL Club is the perfect platform to earn an extra income with something you are passionate about."
              />
              <Section
                title=""
                content="PDL CLUB offers a smart, safe and profitable solution to encourage you to share your bike within a secure and controlled cycling community."
              />
              <Section title="" content="Don’t hesitate, start making extra money today!" />

              <Section
                title="Guaranteed protection"
                content="As a part of the PDL Club fee charged to the Host, an insurance is included guaranteeing up to $3000 in coverage for damage or theft. As an additional security, PDL Club will put a $250 hold on the Renter’s credit card in case of any discrepancies at the time of returning the bicycle. This hold will be removed only after the Host and Renter agree that the bike was returned in the same condition at the time of pick up."
              />
              <Section
                title=""
                content="Becoming a PDL CLUB member requires an ID Verification, traceable invitation, and some other security measures. This is to provide the safest community environment possible."
              />
            </Container>
            <Container sx={{ textAlign: 'center' }}>
              {showTermBox && 
                <TermsAndConditionsCheckbox
                  name={'hostTermsAccepted'}
                  checked={termCheckBox}
                  onChange={() => setTermCheckbox(!termCheckBox)}
                  hostTerm
                />
              }
            </Container>
            <div style={{ height: '7em' }} />
          </div>
          <div className={classes.button}>
            <SimpleButton
              onClick={handleClickOnAddListing}
              disabled={(!termCheckBox && showTermBox)}
            >
              Add Your First Listing
            </SimpleButton>
          </div>
        </Container>
      )}
    />
  );
}
