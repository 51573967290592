import { Checkbox, checkboxClasses, CheckboxProps } from '@mui/material';
import AppTheme from '../../utils/AppTheme';
import { SxStyles } from '../../model/utils/SxStyles';

export const AppCheckbox = (props: CheckboxProps) => {
  return <Checkbox sx={styles.checkbox} {...props} />;
};

const styles: SxStyles = {
  checkbox: {
    [`&, &.${checkboxClasses.checked}`]: {
      color: AppTheme.colors.persianBlue_2238CB,
    },
    p: 0,
  },
};
