export enum PDLTypography {
  paragraph = 'paragraph',
  largeHeading = 'largeHeading',
  mediumHeading = 'mediumHeading',
  smallHeading = 'smallHeading',
  subHeading = 'subHeading',
  subHeadingSerif = 'subHeadingSerif',
  smallParagraph = 'smallParagraph',
  labelsSmallSerif = 'labelsSmallSerif',
  callToAction = 'callToAction',

  // Portal
  portalLargeHeading = 'portalLargeHeading',
  portalSmallHeading = 'portalSmallHeading',
  portalItem = 'portalItem',
  portalDrawerItem = 'portalDrawerItem',
  portalLabel = 'portalLabel',
  portalHeader = 'portalHeader',

  none = 'none',
}
