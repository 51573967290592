import { MapContainer, TileLayer, useMapEvents, useMap } from 'react-leaflet';
import { DEFAULT_COORDINATES, DEFAULT_MAP_LAYER, googleMapsOptions } from '../../utils/MapUtils';
import 'leaflet/dist/leaflet.css';
import { CSSProperties } from '@mui/styles';
import enviroment from '../../environment.json';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { MapCoordinates } from '../../model/utils/MapCoordinates';
import { useFeatureToggle } from '../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../external/pdl-common/utils/enums/Features';

interface Props {
  onClickMap?: (e: { latlng: MapCoordinates }) => void;
  coordinates?: MapCoordinates;
  style?: CSSProperties;
  isLoading?: boolean;
}

export const MapWrapper: React.FunctionComponent<Props> = (props) => {
  const [isGoogleMapAvailable] = useFeatureToggle(Features.IS_GOOGLE_MAP_AVAILABLE);

  // Leaflet Map
  const Listener = () => {
    useMapEvents({
      click: (e) => {
        props.onClickMap && props.onClickMap(e);
      },
    });
    return null;
  };

  const RelocateCoordinates = ({ newCoordinates }: { newCoordinates: MapCoordinates }) => {
    const map = useMap();
    map.panTo({ lat: newCoordinates.lat, lng: newCoordinates.lng });
    return null;
  };

  // Google Maps
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: enviroment.googleMapKey,
  });

  // Google Maps - end

  return !isGoogleMapAvailable || enviroment.env == 'dev' ? (
    <MapContainer
      center={[DEFAULT_COORDINATES.lat, DEFAULT_COORDINATES.lng]}
      zoom={14}
      scrollWheelZoom={false}
      style={{ flex: 1, ...props.style }}
    >
      <TileLayer url={DEFAULT_MAP_LAYER} />

      {props.children}

      <Listener />

      {props.coordinates && !props.isLoading && (
        <RelocateCoordinates newCoordinates={props.coordinates} />
      )}
    </MapContainer>
  ) : isLoaded ? (
    <GoogleMap
      id="map"
      mapContainerStyle={{ flex: 1, ...props.style }}
      center={
        props.isLoading
          ? undefined
          : props.coordinates
            ? props.coordinates
            : { lat: DEFAULT_COORDINATES.lat, lng: DEFAULT_COORDINATES.lng }
      }
      zoom={11}
      options={googleMapsOptions}
      onClick={(e) => {
        props.onClickMap &&
          props.onClickMap({
            latlng: {
              lat: e!.latLng!.lat(),
              lng: e!.latLng!.lng(),
            },
          });
      }}
    >
      {!props.isLoading && props.children}
    </GoogleMap>
  ) : (
    <></>
  );
};
function setMarkers(arg0: () => { lat: any; lng: any; time: Date }[]) {
  throw new Error('Function not implemented.');
}
