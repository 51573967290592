import environment from "../../../environment.json";
import ApiResponse from "../core/ApiResponse";

enum Severity {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

class AlertService {
  private isWeb: boolean;

  constructor(isWeb: boolean) {
    this.isWeb = isWeb;
  };

  /**
   * This method is a requests wrapper to log messages to the client
   * @param request 
   * @param logError 
   * @param onSuccessLog 
   * @returns Promise<ApiResponse>
   */
  async handleRequest<T>(request: Promise<ApiResponse<T>>, logError?: boolean, onSuccessLog?: string): Promise<ApiResponse<T>> {
    return request.then(response => {
      this.handleLog(Severity.SUCCESS, !!onSuccessLog, onSuccessLog);
      return response;
    }).catch(err => {
      this.handleLog(Severity.ERROR, logError, err.response.data.message);
      return Promise.reject(err);
    });
  }

  /**
   * This method logs messages to the user if required
   * @param severity 
   * @param required 
   * @param message 
   */
  private handleLog(severity: Severity, required: boolean = false, message: string = ""): void {
    if (required) {
      this.isWeb ? this.sendMessage(message, severity) : alert(message);
    }
  }

  /**
   * This method takes a message and a severity and displays a SnackBar alert
   * @param message 
   * @param severity 
   */
  private sendMessage = (message: string, severity: Severity) => {
    const snackData = { text: message, severity: severity };
    document.dispatchEvent(
        new CustomEvent("snackMessage", { detail: { snackData } })
    );
  };
}

export default new AlertService((environment as any).isWeb);
