import Logger from '../../../../external/pdl-common/utils/Logger';
import BikeService from '../../../../services/BikeService';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Bike } from '../../../../external/pdl-common/model/Bike';
import { RequestStatus } from '../../../../utils/RequestStatus';
import RequestStatusFeedBack from '../../../commons/RequestStatusFeedback';
import { Box } from '@mui/material';
import HostBikeThirdStep from '../HostBikeThirdStep';

const logger = new Logger('HostBikeEditParts');

export default function HostBikeEditParts() {
  const { externalId } = useParams();

  const [bike, setBike] = useState<Bike | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);

  useEffect(() => {
    getBike();
  }, []);

  const getBike = async () => {
    if (externalId) {
      setRequestStatus(RequestStatus.LOADING);

      (await BikeService.getByExternalId(externalId))
        .onSuccess((response) => {
          setBike(response.getContent());
          setRequestStatus(RequestStatus.SUCCESS);
        })
        .onError((response) => {
          logger.error('Error fetching bike detail', response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    }
  };

  if (requestStatus !== RequestStatus.SUCCESS) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <RequestStatusFeedBack status={requestStatus} onRetry={getBike} />
      </Box>
    );
  }

  return bike ? <HostBikeThirdStep editMode editingBike={bike} /> : <></>;
}
