import { ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import { FullUser } from '../../external/pdl-common/model/FullUser';
import AlertService from '../../services/AlertService';
import UserService from '../../services/UserService';
import AppTheme from '../../utils/AppTheme';
import { RequestStatus } from '../../utils/RequestStatus';
import AppHeader from '../commons/AppHeader';
import { AppTextField } from '../commons/AppTextField';
import FormContainer from '../commons/FormContainer';
import SimpleButton from '../commons/SimpleButton';
import { AppLayout } from '../core/AppLayout';
import { AppTypography, AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import ChangePasswordBottomSheet from './ChangePasswordBottomSheet';
import postalCodes from 'postal-codes-js';
import UserAdditionalInformation from '../commons/UserAdditionalInformation';
import Logger from '../../external/pdl-common/utils/Logger';
import {AdditionalPersonalInformation} from "../../external/pdl-common/model/AdditionalPersonalInformation";

const logger = new Logger('PersonalInformation');

type PersonalInformationForm = {
  email: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
  countryCode: string;
  password: string;
};

const PersonalInformation = () => {
  const classes = useStyles();
  const location = useLocation().state as any;
  const user = location?.user as FullUser | undefined;
  const [formIsModified, setFormIsModified] = useState<boolean>(false);

  const [form, , handleCustomChange, formIsValid, , setValidations, setForm] =
    useForm<PersonalInformationForm>({
      email: user?.user.email || '',
      firstName: user?.user.firstName || '',
      lastName: user?.user.lastName || '',
      birthDate: user?.birthDate || '',
      password: user?.password || '',
      phoneNumber: user?.additionalPersonalInformation.phoneNumber || '',
      streetAddress: user?.additionalPersonalInformation.streetAddress || '',
      city: user?.additionalPersonalInformation.city || '',
      stateCode: user?.additionalPersonalInformation.stateCode || '',
      zipCode: user?.additionalPersonalInformation.zipCode || '',
      countryCode: user?.additionalPersonalInformation.countryCode || 'US',
    });
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);

  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false);

  useEffect(() => {
    setFormIsModified(
      form.email !== user?.user.email ||
        form.firstName !== user?.user.firstName ||
        form.lastName !== user?.user.lastName ||
        form.birthDate !== user?.birthDate ||
        form.streetAddress !== user?.additionalPersonalInformation.streetAddress ||
        form.city !== user?.additionalPersonalInformation.city ||
        form.stateCode !== user?.additionalPersonalInformation.stateCode ||
        form.countryCode !== user?.additionalPersonalInformation.countryCode ||
        form.zipCode !== user?.additionalPersonalInformation.zipCode ||
        form.phoneNumber !== user?.additionalPersonalInformation.phoneNumber
    );
  }, [form]);

  const handleSubmit = async () => {
    setRequestStatus(RequestStatus.LOADING);

    /* library failing with correct country and postal code combination, eg AR, B2930
        * disabling this for now shouldn't be a problem since country/zip code are selected automatically?
    const zipCodeValidation = postalCodes.validate(form.countryCode, form.zipCode);
    if (zipCodeValidation !== true) {
      setRequestStatus(RequestStatus.ERROR);
      AlertService.showSnackCustomError(zipCodeValidation as string);
      return;
    }
     */

      const additionalInformation = {
        phoneNumber: form.phoneNumber,
        streetAddress: form.streetAddress,
        city: form.city,
        stateCode: form.stateCode,
        zipCode: form.zipCode,
        countryCode: form.countryCode,
      } as AdditionalPersonalInformation;


      user!.additionalPersonalInformation = additionalInformation;
    user!.birthDate = form.birthDate;
    user!.user.firstName = form.firstName;
    user!.user.lastName = form.lastName;
    user!.user.email = form.email;

    const response = await UserService.updatePersonalInformation(user!);
    response
      .onSuccess(() => {
        localStorage.removeItem("additionalInformationComplete");
        AlertService.showSuccessMessage('Personal information successfully updated');
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        AlertService.showSnackCustomError(response.getContent());
        logger.error('Failed to update personal information', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getContent = (): JSX.Element => {
    return (
      <FormContainer
        BottomElement={
          <SimpleButton
            children={'Update Personal Information'}
            onClick={handleSubmit}
            isLoading={requestStatus === RequestStatus.LOADING}
            disabled={!formIsValid() || !formIsModified}
          />
        }
      >
        <AppTextField
          name={'email'}
          label={'Email'}
          value={form.email}
          labelProps={labelProps}
          onInputChange={handleCustomChange}
          inputProps={{ type: 'email' }}
          containerClasses={classes.textFieldContainer}
          realTimeValidation
          required
          restrict="email"
        />
        <AppTextField
          name={'firstName'}
          label={'First Name'}
          value={form.firstName}
          labelProps={labelProps}
          onInputChange={handleCustomChange}
          containerClasses={classes.textFieldContainer}
          realTimeValidation
          required
          restrict="onlyLettersAndSpecialCharacters"
        />
        <AppTextField
          name={'lastName'}
          label={'Last Name'}
          value={form.lastName}
          labelProps={labelProps}
          onInputChange={handleCustomChange}
          containerClasses={classes.textFieldContainer}
          realTimeValidation
          required
          restrict="onlyLettersAndSpecialCharacters"
        />
        <AppTextField
          name={'birthDate'}
          label={'Date of Birth'}
          value={form.birthDate}
          labelProps={labelProps}
          inputProps={{ type: 'date' }}
          onInputChange={handleCustomChange}
          containerClasses={classes.textFieldContainer}
          realTimeValidation
          required
        />

        <UserAdditionalInformation
            displayPhoneNumberField
          handleCustomChange={handleCustomChange}
          phoneNumber={form.phoneNumber}
          streetAddress={form.streetAddress}
          stateCode={form.stateCode}
          city={form.city}
          countryCode={form.countryCode}
          zipCode={form.zipCode}
          setForm={(updatedForm) =>
            setForm((previousForm) => {
              return { ...previousForm, ...updatedForm };
            })
          }
          setValidations={(updatedValidations) =>
            setValidations((previousValidations) => {
              return { ...previousValidations, ...updatedValidations };
            })
          }
          editMode
        />

        <AppTextField
          name={'password'}
          label={'Password'}
          value={form.password}
          labelProps={labelProps}
          inputProps={{ type: 'password', disabled: true }}
          onInputChange={handleCustomChange}
          containerClasses={classes.textFieldContainer}
        />

        {/* Change password */}
        <ButtonBase sx={{ marginTop: 1 }} onClick={() => setBottomSheetOpen(true)}>
          <AppTypography
            type={PDLTypography.subHeading}
            customStyles={{ color: AppTheme.colors.gray_A8ACAD }}
            children={'Change password'}
          />
        </ButtonBase>
      </FormContainer>
    );
  };

  return (
    <>
      <AppLayout
        header={() => <AppHeader content={'Personal information'} />}
        content={() => getContent()}
      />
      <ChangePasswordBottomSheet
        open={bottomSheetOpen}
        onClose={() => setBottomSheetOpen(false)}
        oldPassword={form.password}
      />
    </>
  );
};

export default PersonalInformation;

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles({
  textFieldContainer: {
    marginTop: '0.625em',
  },
});
