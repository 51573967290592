import Entity from '../external/fox-typescript/core/Entity';

export interface Content extends Entity {
  title: string;
  description: string;
  imageUrl?: string;
  videoUrl?: string;
  category: ContentCategory;
}

export enum ContentCategory {
  ARTICLE = 'ARTICLE',
  TUTORIAL = 'TUTORIAL',
}
