import { Autocomplete, Box, styled, TextField, TextFieldProps } from '@mui/material';
import AppTheme from '../../utils/AppTheme';
import { AppFontFamily } from '../../utils/AppFontFamily';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import React, { SyntheticEvent } from 'react';
import { makeStyles } from '@mui/styles';

export interface AppAutocompleteOptions {
  label: string;
  value: string;
}
interface Props {
  label?: string;
  value?: string;
  onChange?: (event: SyntheticEvent, newValue: string) => void;
  options: AppAutocompleteOptions[];
  textField?: TextFieldProps;
  freeSolo?: boolean;
}

const omitUpdate = (oldProps: Props, newProps: Props) => {
  if (
    oldProps.label === newProps.label &&
    oldProps.value === newProps.value &&
    oldProps.options === newProps.options &&
    oldProps.textField === newProps.textField &&
    oldProps.freeSolo === newProps.freeSolo &&
    oldProps.onChange === newProps.onChange
  ) {
    return true;
  }
  return false;
};

export const AppAutocomplete = React.memo((props: Props) => {
  const classes = useStyles();

  return (
    <>
      {props.label && (
        <Box className={classes.labelContainer}>
          <AppTypography type={PDLTypography.subHeading}>{props.label}</AppTypography>
        </Box>
      )}
      <CustomAutocomplete
        disablePortal
        options={props.options}
        freeSolo={props.freeSolo}
        value={props.value}
        onInputChange={(event, newValue) => {
          props.onChange && props.onChange(event, newValue);
        }}
        renderInput={(params) => <TextField {...params} {...props.textField} size="small" />}
        popupIcon={<KeyboardArrowDownIcon />}
        forcePopupIcon
        classes={{ paper: classes.paper }}
      />
    </>
  );
}, omitUpdate);

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiInputBase-input': {
    fontFamily: AppFontFamily.SHAPE,
    fontSize: '0.81em',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: AppTheme.colors.persianBlue_2238CB,
  },
});

const useStyles = makeStyles({
  labelContainer: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    fontFamily: AppFontFamily.SHAPE,
    fontSize: '.8em',
  },
});
