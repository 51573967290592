import { makeStyles } from '@mui/styles';
import { AppTypography, AppTypographyProps } from './Typography/AppTypography';
import { PDLTypography } from './Typography/PDLTypography';

interface SimpleCellTextProps {
  label: string;
  value: string | number;
  labelProps: AppTypographyProps;
  valueProps: AppTypographyProps;
}

const useStyle = makeStyles(() => ({
  cell: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

SimpleCellText.defaultProps = {
  labelProps: { type: PDLTypography.subHeadingSerif },
  valueProps: { type: PDLTypography.paragraph },
};

export function SimpleCellText(props: SimpleCellTextProps) {
  const classes = useStyle();

  const { customStyles: labelCustomStyles, ...labelProps } = props.labelProps;

  return (
    <div className={classes.cell}>
      <AppTypography
        customStyles={{ textAlign: 'start', marginBottom: '0.5em', ...labelCustomStyles }}
        {...labelProps}
      >
        {props.label}
      </AppTypography>
      <AppTypography {...props.valueProps}>{props.value}</AppTypography>
    </div>
  );
}
