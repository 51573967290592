import { useEffect, useState } from 'react';
import AuthService from '../external/fox-typescript/services/AuthService';
import environment from '../environment.json';
import DateUtils from '../external/pdl-common/utils/DateUtils';

export function useIsLogged(path: string): [boolean | undefined, (value: boolean) => void] {
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    checkIfLogged();
  }, [path]);

  const changeLoginStatus = (value: boolean) => {
    setIsLogged(value);
  };

  const checkIfLogged = () => {
    const isLogged = AuthService.isLogged();
    const tokenExpirationDate = AuthService.getTokenExpiration();
    const hasAccess = DateUtils.checkTimeZoneId(environment.timeZoneId);

    if (!hasAccess) {
      setIsLogged(false);
      return;
    }

    if (tokenExpirationDate) {
      const tokenHasExpired = DateUtils.isBeforeThan(tokenExpirationDate, new Date());
      setIsLogged(isLogged && !tokenHasExpired);
    } else {
      setIsLogged(isLogged);
    }
  };

  return [isLogged, changeLoginStatus];
}
