import { Box, Container, Fade, Grid, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { AppLayout } from '../core/AppLayout';
import AppTitle from '../commons/AppTitle';
import SimpleButton from '../commons/SimpleButton';
import { FluidContainer } from '../commons/FluidContainer';
import { SearchBar } from '../commons/SearchBar';
import { AppAssets } from '../../assets';
import { ContentList } from '../ContentLibrary/ContentList';
import AppTheme from '../../utils/AppTheme';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { BikeList } from '../Bikes/BikeList';
import { SxStyles } from '../../model/utils/SxStyles';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { TopDestinations } from '../Destinations/TopDestinations';
import FeatureToggleService from '../../external/pdl-common/service/FeatureToggle';
import bgImage1 from '../../assets/img/home-image.png';
import bgImage2 from '../../assets/img/home-image2.jpg';
import bgImage3 from '../../assets/img/home-image3.jpg';
import Logger from '../../external/pdl-common/utils/Logger';
import { ToggleItem } from '../../external/pdl-common/model/ToggleItem';
import { Features } from '../../external/pdl-common/utils/enums/Features';
import { COLORS } from '../../external/pdl-common/utils/AppTheme';
import UserService from '../../services/UserService';
import { AlertDialog } from '../commons/AlertDialog';

const SHOW_CALENDAR_AND_BOOK_ICONS = false;
const logger = new Logger('Explore');

export default function Explore() {
  const navigate = useNavigate();

  const [topDestinationsSectionToggle, setTopDestinationsSectionToggle] = useState<
    ToggleItem | undefined
  >(undefined);
  const [optOffSearchDialog, setOptOffSearchDialog] = useState<boolean>(false);
  const [searchDialogModalOpen, setSearchDialogModalOpen] = useState<boolean>(false);
  const [dialogCheckBoxCheck, setDialogCheckBoxCheck] = useState<boolean>(false);

  const images: string[] = [bgImage1, bgImage2, bgImage3];
  const [currentImage, setCurrentImage] = useState<number>(
    Math.floor(Math.random() * images.length)
  );

  useEffect(() => {
    getTopDestinationsSectionToggle();
    getUserOptOffAlertDialog();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev === 0 ? 1 : prev === 1 ? 2 : 0));
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getUserOptOffAlertDialog = async () => {
    const storedOptOff = localStorage.getItem("opt-off");
    if (storedOptOff == undefined || storedOptOff == "false") {
      try {
        const response = await UserService.getOptOffSerachDialog();
        setOptOffSearchDialog(Boolean(response.getContent()));
        localStorage.setItem("opt-off", String(response.getContent()));
      } catch (e) {
        logger.error('Error fetching user search dialog preference', e);
      }
    } else {
      setOptOffSearchDialog(Boolean(storedOptOff));
    }
  }

  const handleClickOnSearch = () => {
    if (optOffSearchDialog) {
      appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
    } else {
      setSearchDialogModalOpen(true);
    }
  }

  const handleNavigateToSearch = async () => {
    if (dialogCheckBoxCheck) {
      try {
        const response = await UserService.updateSearchDialogPreference()
        localStorage.setItem("opt-off", "true");
        appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
      } catch (e) {
        logger.error('Error updating user search dialog preference', e);
      }
    } else {
      appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
    }
  }


  const getTopDestinationsSectionToggle = async () => {
    try {
      const response = await FeatureToggleService.getFeatureToggleItem(
        Features.TOP_DESTINATIONS_SECTION
      );
      setTopDestinationsSectionToggle(response.getContent());
    } catch (e) {
      logger.error('Error fetching top destinations toogle', e);
    }
  };

  return (
    <AppLayout
      fromHome
      header={() => (
        <Container maxWidth="md" sx={styles.heroContainer}>
          {/* Background images */}
          <Box>
            <Fade appear={false} timeout={250} in={currentImage === 0} mountOnEnter unmountOnExit>
              <Box style={{ backgroundImage: `url(${bgImage1})` }} sx={styles.image} />
            </Fade>
            <Fade appear={false} timeout={250} in={currentImage === 1} mountOnEnter unmountOnExit>
              <Box
                sx={styles.image}
                style={{ backgroundImage: `url(${bgImage2})`, backgroundPosition: '70% 0%' }}
              />
            </Fade>
            <Fade appear={false} timeout={250} in={currentImage === 2} mountOnEnter unmountOnExit>
              <Box sx={styles.image} style={{ backgroundImage: `url(${bgImage3})` }} />
            </Fade>
          </Box>

          <Grid container alignItems={'center'}>
            <Grid item xs>
              <SearchBar
                placeholder={'Where are you riding?'}
                onClick={handleClickOnSearch}
                buttonDisabled
              />
            </Grid>

            {SHOW_CALENDAR_AND_BOOK_ICONS && (
              <>
                <IconButton type="submit" aria-label="book">
                  <AppAssets.BookOpenIcon style={{ marginRight: 5, marginLeft: 5 }} />
                </IconButton>

                <IconButton type="submit" aria-label="calendar">
                  <AppAssets.CalendarIcon style={{ marginRight: 5, marginLeft: 5 }} />
                </IconButton>
              </>
            )}
          </Grid>

          <Box sx={styles.infoContainer}>
            <AppTitle content={'Where will you ride next?'} sx={styles.infoTitle} />
            <SimpleButton
              theme="white"
              onClick={handleClickOnSearch}
              sx={styles.button}
            >
              Start a Search
            </SimpleButton>
          </Box>
        </Container>
      )}
      content={() => (
        <Box sx={{ backgroundColor: COLORS.white_FFFFFF, pt: '.5em' }}>
          {topDestinationsSectionToggle?.enabled && (
            <FluidContainer style={{ backgroundColor: AppTheme.colors.black_01000E }}>
              <TopDestinations />
            </FluidContainer>
          )}

          <FluidContainer style={{ margin: 0, padding: 0 }}>
            <Container>
              <BikeList />
            </Container>
          </FluidContainer>

          <FluidContainer style={{ backgroundColor: AppTheme.colors.white_EEEEEC }}>
            <Container>
              {/*<UpcomingEvents />*/}
              <ContentList />
            </Container>
          </FluidContainer>
          <AlertDialog
            hideCancelButton
            title="Service availability"
            content={"Service only available in Miami."}
            open={searchDialogModalOpen}
            confirmLabel={"Continue"}
            onCheckboxChange={() => setDialogCheckBoxCheck(!dialogCheckBoxCheck)}
            onConfirm={() => {
              setSearchDialogModalOpen(false);
              handleNavigateToSearch();
            }}
            onCancel={() => {
              setSearchDialogModalOpen(false);
            }}
          />
        </Box>
      )}
    />
  );
}

const styles: SxStyles = {
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    pt: '3.75em',
    '@media  screen and  (orientation:landscape)': {
      pt: '1em',
    },
    pb: '2.5em',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '35%',
  },
  infoTitle: {
    color: '#FFFFFF',
    fontSize: '2.35em',
    textAlign: 'center',
    fontFamily: AppFontFamily.SHAPE_BOLD,
    mt: '4em',
  },
  button: {
    width: '80%',
    mt: '2em',
  },
  image: {
    height: '100%',
    width: '100%',
    top: '0',
    left: '0',
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    filter: 'brightness(0.9)',
    zIndex: -1,
  },
};
