import { Dialog, IconButton, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router';
import { AppAssets } from '../../assets';
import { SxStyles } from '../../model/utils/SxStyles';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import AppTheme, { IAppTheme } from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

interface Props {
  text?: string;
  open: boolean;
  onClose?: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationModal({ text, onClose, open }: Props) {
  const navigate = useNavigate();
  const classes = useStyles();

  const defaultAction = () => {
    appNavigate(navigate, AppRoutes.MAIN, null);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={onClose || defaultAction}
      PaperProps={{
        sx: styles.container,
      }}
      classes={{ container: classes.container }}
    >
      <div style={{ textAlign: 'end' }}>
        <IconButton onClick={onClose || defaultAction}>
          <div style={{ cursor: 'pointer', fontSize: '0.7em', fontFamily: AppFontFamily.SHAPE, color: 'black' }} onClick={onClose}>
            Close
          </div>
        </IconButton>
      </div>
      <div style={{ textAlign: 'center', marginBottom: '2.5em', marginTop: '2.5em' }}>
        <AppAssets.Checkmark />
      </div>
      <div style={{ textAlign: 'center', wordBreak: 'break-word', marginBottom: '2em' }}>
        <AppTypography type={PDLTypography.largeHeading}>{text || 'OK'}</AppTypography>
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  container: {
    alignItems: 'end !important',
  },
}));

const styles: SxStyles = {
  container: {
    backgroundColor: AppTheme.colors.white_FFFFFF,
    borderRadius: '2em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '2em',
  },
};
