import { makeStyles } from '@mui/styles';
import { AppTypography } from '../../../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../../../external/pdl-common/components/Typography/PDLTypography';
import { TintQuote } from '../../../../model/insurance/Tint/TintQuote';
import { TintCoverage } from '../../../../model/insurance/Tint/TintCoverage';
import { TintCoverages } from '../../../../model/insurance/Tint/TintCoverages';

export interface Props {
  coverages: TintCoverages;
}

const InsuranceQuote = ({ coverages }: Props) => {
  const classes = useStyles();

  const { liability } = coverages;

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {liability.description && (
          <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
            Description: {liability.description}
          </AppTypography>
        )}

        <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
          {`Price (for rental period): $${liability.premium}`}
        </AppTypography>

        {liability.limit && (
          <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
            {`Maximum Refund: $${liability.limit}`}
          </AppTypography>
        )}

        {liability.deductible && (
          <AppTypography type={PDLTypography.paragraph} customClasses={classes.label}>
            {`Hold Charge / Deductible: $${liability.deductible}`}
          </AppTypography>
        )}
      </div>
    </div>
  );
};

export default InsuranceQuote;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '2em',
  },
  label: {
    marginTop: '1em',
    textAlign: 'left',
  },
  buttonContainer: {
    margint: '0px auto',
    marginTop: '2em',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));
