import { ButtonBase } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IosShareIcon from '@mui/icons-material/IosShare';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { useFeatureToggle } from '../../hooks/useFeatureToggle';
import { Features } from '../../utils/enums/Features';

interface Props {
  showBackButton?: boolean;
  favourite?: Boolean;
  borderRadius?: string;
  onClickShare?: () => void;
  onClickFav?: () => void;
  handleOnBack?: () => void;
}

const TopIcons = ({
  showBackButton,
  favourite,
  borderRadius,
  onClickShare,
  onClickFav,
  handleOnBack,
}: Props) => {
  const classes = useStyles();

  const [isShareIconAvailable] = useFeatureToggle(Features.IS_SHARE_ICON_AVAILABLE);

  return (
    <div className={`${classes.iconContainer} ${borderRadius}`}>
      {showBackButton ? (
        <ButtonBase onClick={handleOnBack}>
          <ArrowBackIcon />
        </ButtonBase>
      ) : (
        <div></div>
      )}
      <div>
        {isShareIconAvailable && (
          <ButtonBase onClick={onClickShare}>
            <IosShareIcon sx={muiStyles.shareIcon} />
          </ButtonBase>
        )}
        <ButtonBase onClick={onClickFav} sx={{ mr: '.7em', mt: '.3em' }}>
          <FavoriteIcon htmlColor={favourite ? 'red' : 'white'} />
        </ButtonBase>
      </div>
    </div>
  );
};

export default TopIcons;

const muiStyles = {
  shareIcon: {
    marginRight: '0.8em',
  },
};

const useStyles = makeStyles({
  iconContainer: {
    position: 'absolute',
    top: '0',
    color: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    background:
      'linear-gradient(0deg, transparent 0%, rgba(1, 0, 14, 0.12) 35%, rgba(1, 0, 14, 0.5) 100%);',
    paddingTop: '.5em',
    paddingBottom: '.5em',
    height: '2em',
  },
});
