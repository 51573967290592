import { Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bike } from '../../external/pdl-common/model/Bike';
import { COLORS } from '../../utils/AppTheme';
import AppHeader from '../commons/AppHeader';
import ListingLink from '../commons/ListingLink';
import NotificationModal from '../commons/NotificationModal';
import SimpleButton from '../commons/SimpleButton';
import { AppLayout } from '../core/AppLayout';
import { BikeReservationMessageSection } from './BikeReservationMessageSection';
import { BikeReservationPaymentSection } from './BikeReservationPaymentSection';
import Logger from '../../external/pdl-common/utils/Logger';
import ReservationCreation from '../../model/creation/ReservationCreation';
import ReservationService from '../../services/ReservationService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import AlertService from '../../services/AlertService';
import StripeService from '../../services/StripeService';
import { TintQuote } from '../../model/insurance/Tint/TintQuote';
import { TintDecision } from '../../model/insurance/Tint/TintDecision';
import environment from '../../environment.json';
import { AlertDialog } from '../commons/AlertDialog';
import InsuranceService from '../../services/InsuranceService';
import { useFeatureToggle } from '../../external/pdl-common/hooks/useFeatureToggle';
import TermsAndConditionsCheckbox from '../commons/TermsAndConditionsCheckbox';
import { useForm } from '../../hooks/useForm';
import { Features } from '../../external/pdl-common/utils/enums/Features';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import BikeUtils from '../../external/pdl-common/utils/BikeUtils';
import {ReservationSummaryDialog} from "../commons/ReservationSummaryDialog";
import {AdditionalPersonalInformation} from "../../external/pdl-common/model/AdditionalPersonalInformation";
import UserService from "../../services/UserService";
import {ReservationTemporaryAdditionalInformation} from "./ReservationTemporaryAdditionalInformationForm";

export interface CreateConfirmForm {
  termConditionsCheck: boolean;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
  countryCode: string;
}

type StateProps = {
  bike?: Bike;
  startDate?: Date;
  endDate?: Date;
  renterInsurancePolicyId?: string;
  renterSubscribedQuote?: TintQuote;
  reservationExternalId?: string;
  includeInsurance?: boolean;
  protectionPlanPrice?: number;
};

type LocationProps = {
  state: StateProps;
};

const logger = new Logger('BikeReservation');

export const BikeReservation = () => {
  const location = useLocation() as LocationProps;
  const classes = userStyles();
  const navigate = useNavigate();

  // Location state
  const bike = location.state?.bike;
  const startDate = location.state?.startDate;
  const endDate = location.state?.endDate;
  const renterInsurancePolicyId = location.state?.renterInsurancePolicyId;
  const renterSubscribedQuote = location.state?.renterSubscribedQuote;
  const externalId = location.state?.reservationExternalId;
  const includeInsurance = location.state?.includeInsurance || false;
  const insurancePrice = renterSubscribedQuote?.calculated_premium || 0;
  const protectionPlanPrice = location.state?.protectionPlanPrice || 0;

  // React state
  const [message, setMessage] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [taxes, setTaxes] = useState<number>(0);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [submitStatus, setSubmitStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [isInsuranceFeatureEnabled] = useFeatureToggle(Features.INSURANCE);
  const [price, setPrice] = useState<number>(0);
  const [tintDecision, setTintDecision] = useState<TintDecision | undefined>(undefined);
  const [idVerificationModalOpen, setIdVerificationModalOpen] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [temporaryAdditionalInformation, setTemporaryAdditionalInformation] = useState<AdditionalPersonalInformation>({});
  const [requireAdditionalInformation, setRequireAdditionalInformation] = useState<boolean>(false);
  const [additionalInformation, setAdditionalInformation] = useState<AdditionalPersonalInformation>({});

  // Form
  const [form, , handleCustomChange, formIsValid, , setValidations, setForm] = useForm<CreateConfirmForm>({
    termConditionsCheck: false,
    phoneNumber: temporaryAdditionalInformation?.phoneNumber || '',
    streetAddress: temporaryAdditionalInformation?.streetAddress || '',
    city: temporaryAdditionalInformation?.city || '',
    stateCode: temporaryAdditionalInformation?.stateCode || '',
    zipCode: temporaryAdditionalInformation?.zipCode || '',
    countryCode: temporaryAdditionalInformation?.countryCode || '',
  });

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    // If the price changes we recalculate the taxes
    if (price > 0) {
      getTaxes();
    }
  }, [price]);

  useEffect(() => {
    if (additionalInformation.countryCode?.toUpperCase() && additionalInformation.countryCode?.toUpperCase() !== 'US') {
      setRequireAdditionalInformation(true);
      getTemporaryAdditionalInformation();
    }
  }, [additionalInformation]);

  useEffect(() => {
    setForm(
        {
          termConditionsCheck: form.termConditionsCheck,
          phoneNumber: temporaryAdditionalInformation?.phoneNumber || '',
          streetAddress: temporaryAdditionalInformation?.streetAddress || '',
          city: temporaryAdditionalInformation?.city || '',
          stateCode: temporaryAdditionalInformation?.stateCode || '',
          zipCode: temporaryAdditionalInformation?.zipCode || '',
          countryCode: temporaryAdditionalInformation?.countryCode || '',
        }
    )

    setValidations({phoneNumber: true, termConditionsCheck: form.termConditionsCheck, countryCode: form.countryCode && form.countryCode?.toUpperCase() === 'US'})
  }, [temporaryAdditionalInformation]);

  const getTemporaryAdditionalInformation = async () => {
    try {
      const response = await UserService.getTemporaryAdditionalPersonalInformation();
      setTemporaryAdditionalInformation(response.getContent());
    } catch (error: any) {
      logger.error('Error getting temporary additional personal information', error);
    }
  }

  const getAdditionalInformation = async () => {
    try {
      const response = await UserService.getAdditionalPersonalInformation();
      setAdditionalInformation(response.getContent());
    } catch (error: any) {
      logger.error('Error getting additional personal information', error);
    }
  }

  const load = async () => {
    if (!bike || !startDate || !endDate) {
      appNavigate(navigate, AppRoutes.MAIN, null);
      return;
    }

    setPrice(getPrice(includeInsurance));

    try {
      setRequestStatus(RequestStatus.LOADING);

      await getAdditionalInformation();

      const response = await StripeService.getCurrentCreditCard();
      setPaymentMethod(response.getContent());

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (err: any) {
      if (err?.response?.status === 404) {
        setRequestStatus(RequestStatus.SUCCESS);
      } else {
        logger.error('Error getting stripe credit card', err);
        setRequestStatus(RequestStatus.ERROR);
      }
    }
  };

  const getTaxes = async () => {
    setRequestStatus(RequestStatus.LOADING);

    (await ReservationService.getTaxes(price))
      .onSuccess((response) => {
        setTaxes(response.getContent());
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error getting taxes', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const getPrice = (includeInsurancePrice: boolean = true) => {
    const dailyPrice = BikeUtils.dailyPrice(bike!, startDate!, endDate!);
    let calcPrice = DateUtils.getFullDaysBetweenDates(startDate!, endDate!) * dailyPrice;

    if (includeInsurancePrice) {
      if (protectionPlanPrice != 0) {
        calcPrice += protectionPlanPrice;
      } else {
        calcPrice += insurancePrice;
      }
    }

    return calcPrice;
  };

  // Method used to check the Tint's decision
  const checkTintDecision = async (policyId: string) => {
    try {
      setRequestStatus(RequestStatus.LOADING);
      // Default decision for dev and qa is APPROVED. You can change it to REJECTED to simulate an invalid response
      let decision = TintDecision.APPROVED;

      // If the environment is not equal to dev or qa we get the real Tint response
      if (environment.env === 'prod' || environment.env === 'preprod') {
        // We check that the user has a valid ID
        const decisionResponse = await InsuranceService.getInsurancePolicyDecision(policyId);
        decision = decisionResponse.getContent().decision;
      }

      setRequestStatus(RequestStatus.SUCCESS);
      setTintDecision(decision);

      if (decision === TintDecision.REJECTED) {
        // If the decision is rejected we show the error message and cancel the reservation process
        //For now, we will move this pop-up to the insurance view, if this persists, refactor redundancy of method.
        onSubmit();
        setIdVerificationModalOpen(true);
      } else if (decision === TintDecision.APPROVED) {
        // If the decision is approved we continue with the reservation process
        onSubmit();
      }
    } catch (error: any) {
      logger.error('Error checking Tint decision', error);
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const onBeforeSubmit = () => {
    // We check the Tint's decision before submiting the reservation
    setOpenConfirmationModal(true);
    /* UNCOMMENT if Tint were to be integrated again.
    if (isInsuranceFeatureEnabled) {
      if (renterInsurancePolicyId) {
        checkTintDecision(renterInsurancePolicyId);
      } else {
        if (includeInsurance) {
          onSubmit();
        }
        else {
          onSubmit();
          //For now, we will move this pop-up to the insurance view, if this persists, refactor redundancy of method.
          //setOpenConfirmationModal(true);
        }
      }
    } else {
      if (includeInsurance) {
        onSubmit();
      } else {
        onSubmit();
        //For now, we will move this pop-up to the insurance view, if this persists, refactor redundancy of method.
        //setOpenConfirmationModal(true);
      }
    }
     */
  };

  const onSubmit = async () => {
    if (bike!.externalId && paymentMethod) {
      const reservationCreation: ReservationCreation = {
        price: getPrice(false), // Price without insurance fee
        taxes,
        startDate: startDate!,
        endDate: endDate!,
        message: message,
        bikeExternalId: bike!.externalId,
        protectionPlanPrice: protectionPlanPrice ? protectionPlanPrice : 0,
        zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone, // TODO: remove this from here, already in CompleteAdditionalInformation
      };

      // If the identity verification was successfull we add the insurance information
      if (includeInsurance && isInsuranceFeatureEnabled) {
        reservationCreation.renterInsurancePolicyId = renterInsurancePolicyId;
        reservationCreation.renterSubscribedQuoteId = renterSubscribedQuote?.id;
        reservationCreation.externalId = externalId;
      }

      if (form.countryCode !== '') {
        reservationCreation.additionalPersonalInformation = {
          phoneNumber: form.phoneNumber,
          streetAddress: form.streetAddress,
          city: form.city,
          stateCode: form.stateCode,
          zipCode: form.zipCode,
          countryCode: form.countryCode,
        };
      }

      try {
        setSubmitStatus(RequestStatus.LOADING);

        await ReservationService.createReservation(reservationCreation);
        setNotificationOpen(true);
      } catch (error: any) {
        logger.error('Error creating reservation', error);
        if (error?.response?.data?.message) {
          AlertService.showSnackCustomError(error?.response?.data?.message);
        } else {
          AlertService.showSnackInternalError();
        }
      } finally {
        setSubmitStatus(RequestStatus.SUCCESS);
      }
    }
  };

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: boolean = e.target.value === 'true';
    handleCustomChange(e.target.name, !value, !value);
  };

  if (requestStatus && requestStatus !== RequestStatus.SUCCESS) {
    return (
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
        <RequestStatusFeedBack status={requestStatus} onRetry={load} />
      </div>
    );
  }

  return (
    <AppLayout
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
      content={() => {
        return (
          <>
            <div style={{ flex: 1, overflowY: 'auto' }}>
              <AppHeader
                content="Confirm and pay"
                showBackButton={submitStatus !== RequestStatus.LOADING}
              />
              <Container className={classes.container}>
                <ListingLink
                  title={bike!.title}
                  imageUrl={bike!.images && bike!.images.length > 0 ? bike!.images[0].url : ''}
                  cells={[
                    {
                      label: 'Dates',
                      value: DateUtils.formattedRangeDates(startDate!, endDate!),
                    },
                    {
                      label: 'Total cost',
                      value: `$${Math.round((price + taxes) * 100) / 100}`,
                    },
                  ]}
                />
                <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                <form>
                  <BikeReservationMessageSection
                    message={message}
                    onInputChange={(inputName: string, inputValue: string) =>
                      setMessage(inputValue)
                    }
                  />
                  <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                  <BikeReservationPaymentSection
                    totalPrice={price + taxes}
                    bikeDailyPrice={BikeUtils.dailyPrice(bike!, startDate!, endDate!)}
                    startDate={startDate!}
                    endDate={endDate!}
                    paymentMethod={paymentMethod}
                    onNewPaymentMethod={setPaymentMethod}
                    insuranceFee={insurancePrice}
                  />
                  <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                  <ReservationTemporaryAdditionalInformation
                      displayPhoneNumberField={false} // This is just a hack, should be removed
                      handleCustomChange={handleCustomChange}
                      phoneNumber={form.phoneNumber}
                      streetAddress={form.streetAddress}
                      stateCode={form.stateCode}
                      city={form.city}
                      countryCode={form.countryCode}
                      zipCode={form.zipCode}
                      setForm={(updatedForm) =>
                          setForm((previousForm) => {
                            return { ...previousForm, ...updatedForm };
                          })
                      }
                      setValidations={(updatedValidations) =>
                          setValidations((previousValidations) => {
                            return { ...previousValidations, ...updatedValidations };
                          })
                      }
                      editMode
                  />
                  <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
                  <TermsAndConditionsCheckbox
                      name={'termConditionsCheck'}
                      checked={form.termConditionsCheck}
                      disabled={submitStatus === RequestStatus.LOADING}
                      onChange={onCheckboxChange}
                  />
                </form>

                <NotificationModal
                  open={notificationOpen}
                  text="Your reservation has been confirmed."
                  onClose={() => {
                    setNotificationOpen(false);
                    appNavigate(navigate, AppRoutes.EXPLORE, null);
                  }}
                />
              </Container>
            </div>

            <div
              style={{
                padding: '1em 0em',
                borderTopWidth: 1,
                borderTopStyle: 'solid',
                borderTopColor: COLORS.gray_E3E5E4,
              }}
            >
              <Container>
                <SimpleButton
                  type="submit"
                  children={'Confirm and Pay'}
                  onClick={onBeforeSubmit}
                  disabled={
                    !paymentMethod ||
                    submitStatus === RequestStatus.LOADING ||
                    tintDecision === TintDecision.REJECTED ||
                      !formIsValid()
                      //(requireAdditionalInformation ? !formIsValid() : (form.termConditionsCheck !== true && (form.countryCode !== '')))
                  }
                  isLoading={submitStatus == RequestStatus.LOADING}
                />
              </Container>
            </div>

            {/* Identity verification modal */}
            <AlertDialog
              title="Identity verification"
              content={`We were unable to validate your identity, so unfortunately you cannot continue with the reservation process.
                        Please update your personal information and try again later.`}
              open={idVerificationModalOpen}
              onConfirm={() => {
                setIdVerificationModalOpen(false);
                appNavigate(navigate, AppRoutes.ACCOUNT, null);
              }}
              onCancel={() => {
                setIdVerificationModalOpen(false);
                appNavigate(navigate, AppRoutes.EXPLORE, null);
              }}
            />

            <ReservationSummaryDialog
              title='No Protection Plan'
              bike={bike}
              startDate={startDate}
              endDate={endDate}
              price={price}
              taxes={taxes}
              includeInsurance={includeInsurance}
              open={openConfirmationModal}
              onConfirm={() => {
                setOpenConfirmationModal(false);
                onSubmit();
              }}
              onCancel={() => {
                setOpenConfirmationModal(false);
              }}
            />
          </>
        );
      }}
    />
  );
};

const userStyles = makeStyles({
  container: {
    marginTop: '2em',
    marginBottom: '2em',
  },
});
