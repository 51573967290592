import {
  addDays,
  differenceInDays,
  differenceInHours,
  endOfDay,
  format,
  formatDistance,
  isBefore,
  subMinutes,
} from 'date-fns';

export default class DateUtils {
  private static isUtcFormatEnabled = true;

  public static formattedDateFromEpoch(epochTimestamp?: number): string {
    return !epochTimestamp ? '' : format(new Date(epochTimestamp), 'yyyy-MM-dd');
  }

  public static formattedDate(date?: Date): string {
    return date ? format(date, 'yyyy-MM-dd') : '';
  }

  /** Returns Date in the following format:
   * Nov 29,
   * Dec 31,
   * Oct 22,
   * etc..
   */
  public static formattedShortDate(date: Date, isUtc: boolean = true): string {
    if (isUtc && this.isUtcFormatEnabled) {
      const utcDate = date.toUTCString();
      return utcDate.substring(8, 12) + utcDate.substring(5, 7);
    }
    return format(date, 'MMM dd');
  }

  /** Returns Date in the following format:
   * Nov 29, 2020,
   * Dec 31, 2021,
   * Oct 22, 2019,
   * etc..
   */
  public static formattedLongDate(date: Date, isUtc: boolean = true): string {
    if (isUtc && this.isUtcFormatEnabled) {
      const utcDate = date.toUTCString();
      return `${utcDate.substring(8, 12)} ${utcDate.substring(5, 7)}, ${utcDate.substring(12, 16)}`;
    }
    return format(date, 'MMM dd, yyyy');
  }

  /** Returns Dates in the following format:
   * Nov 29 - Dec 31,
   * Oct 22 - Nov 02,
   * etc..
   */
  public static formattedRangeDates(
    firstDate: Date,
    secondDate: Date,
    longDate?: boolean,
    isUtc: boolean = true
  ): string {
    if (longDate) {
      return `${this.formattedShortDate(firstDate, isUtc)} - ${this.formattedLongDate(
        secondDate,
        isUtc
      )}`;
    }
    return `${this.formattedShortDate(firstDate, isUtc)} - ${this.formattedShortDate(
      secondDate,
      isUtc
    )}`;
  }

  public static getDateWithDifference(aDate: Date, numberOfDaysToAdd: number): Date {
    return addDays(aDate, numberOfDaysToAdd);
  }

  /** Returns Dates in the following format:
   * Mon, Nov 08, 10:05 hs/pm
   */
  public static formattedChatDate(date: Date, isUtc: boolean = true): string {
    if (isUtc && this.isUtcFormatEnabled) {
      const dateAsString = date.toUTCString();
      return `${dateAsString.slice(0, 11)}, ${dateAsString.slice(17, 22)} hs`;
    }
    return format(date, 'EE, MMM dd, H:mm a');
  }

  /** Returns Dates in the following format:
   * Mon, Nov 08, 10:05 hs
   */
  public static formattedChatDateFromString(date: string): string {
    return this.formattedChatDate(new Date(date));
  }

  public static getDaysBetweenDates(startDate: Date, endDate: Date): number {
    return Math.abs(differenceInDays(startDate, endDate));
  }

  public static getHoursBetweenDates(startDate: Date, endDate: Date): number {
    return Math.abs(differenceInHours(startDate, endDate));
  }

  /*
    We need to add 1 here because getDaysBetweenDates is not considering the extremes. 
    For example:
    Given startDate: 01/02/2021 and endDate: 06/02/2021
    getDaysBetweenDates returns 5 because is not considering that the reserve 
    starts at 00:00:00 of 01/02/2021 and ends at 23:59:00 of 06/02/2021.
  */
  public static getFullDaysBetweenDates(startDate: Date, endDate: Date): number {
    return this.getDaysBetweenDates(startDate, endDate) + 1;
  }

  public static endOfDay(date: Date): Date {
    return subMinutes(endOfDay(date), 1);
  }

  public static isBeforeThan(startDate: Date, endDate: Date): boolean {
    return isBefore(startDate, endDate);
  }

  public static stringToShortDate(date: string): string {
    const dateToReturn = new Date(date);
    return this.formattedShortDate(dateToReturn);
  }

  public static formattedDateOnlyMonthAndYear(date: Date) {
    return format(date, 'MMMM yyyy');
  }

  /**
   * Returns Dates in the following format:
   * 2 hours ago,
   * 15 minutes ago,
   * etc..
   */
  public static getTimeDistance = (date: Date) => {
    return `${formatDistance(new Date(), date).replace('about ', '')} ago`;
  };

  /**
   * @param time time in millis
   *
   * Returns text in the following format:
   * less than 1 second,
   * half a minute,
   * etc..
   */
  public static formatDistance = (time: number) => {
    return formatDistance(0, time, { includeSeconds: true });
  };

  public static formattedPortalChatDate(date: Date): string {
    return format(date, 'MMMM dd, Y • H:mm a');
  }

  public static customFormat(date: Date, formatter: string) {
    return format(date, formatter);
  }

  /**
   * This method is used to check if the current device is in the specified timezone id
   * @param timeZoneId to compare with the current device timezone
   * @returns true if match the the timezone id
   */
  public static checkTimeZoneId = (timeZoneId: string): boolean => {
    if (this.isUtcFormatEnabled) {
      return true;
    }

    const moment = require('moment-timezone');
    return moment().utcOffset() === moment().tz(timeZoneId).utcOffset();
  };

  /**
   * Return a date in UTC format
   * @param date
   * @param hours
   * @param minutes
   * @param seconds
   * @param miliSeconds
   * @returns an UTC date
   */
  public static dateToUTC = (
    date: Date,
    hours?: number,
    minutes?: number,
    seconds?: number,
    miliSeconds?: number
  ): Date => {
    if (this.isUtcFormatEnabled) {
      return new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          hours || date.getHours(),
          minutes || date.getMinutes(),
          seconds || date.getSeconds(),
          miliSeconds || date.getMilliseconds()
        )
      );
    }
    return date;
  };

  /**
   * Convert a date in any timezone to utc format
   * @param date to convert
   * @returns a date in utc format
   */
  public static toUtcTime = (date: Date): Date => {
    if (this.isUtcFormatEnabled) {
      const moment = require('moment-timezone');
      return moment(date.toISOString().slice(0, 10)).toDate();
    }
    return date;
  };

  /**
   * This method is used to convert a date into utc and then into a new date with a specific timezone
   * Eg. 2022 07 21 21:00:00 GMT-03:00 -> 2022 07 22 00:00:00 GMT-04:00
   * @param date date to convert
   * @param timezoneId timezone to be apply
   * @returns a "luxon" DateTime date object
   */
  public static toTimezone = (date: Date, timezoneId: string) => {
    const { DateTime } = require('luxon');

    const startDate = DateTime.fromJSDate(date).toUTC();

    return startDate.setZone(timezoneId).set({
      year: startDate.year,
      month: startDate.month,
      day: startDate.day,
      hour: startDate.hour,
      minute: startDate.minute,
      second: startDate.second,
      millisecond: startDate.millisecond,
    });
  };

  /**
   * This method is used to compare a date with the current date in a specified timezone
   * @param date date to compare
   * @param timezoneId timezone to be aply to the date and today
   * @returns a boolean state. True if the date is greater than the current date
   */
  public static greaterThanTodayInTimezone = (date: Date, timezoneId: string): boolean => {
    const { DateTime } = require('luxon');

    const newDate = this.toTimezone(date, timezoneId);
    const today = DateTime.fromJSDate(new Date()).setZone(timezoneId);

    return newDate.diff(today) < 0;
  };

  /**
   * This method is used to compare a date with the current date minus an amount of hours in a specified timezone
   * @param date date to compare
   * @param hours amount of hours to subtract from current date
   * @param timezoneId timezone to be aply to the date and today
   * @returns a boolean state. True if the date is greater than the current date
   */
  public static greaterThanTodayMinusHoursInTimezone = (date: Date, timezoneId: string, hours: number): boolean => {
    const { DateTime } = require('luxon');

    date.setHours(date.getHours() - hours);

    const newDate = this.toTimezone(date, timezoneId);
    const today = DateTime.fromJSDate(new Date()).setZone(timezoneId);


    return newDate.diff(today) < 0;
  };

}
