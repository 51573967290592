import { CreateProfileForm } from '../components/views/BlackCreateProfile';
import { WaitlistCreateProfileForm } from '../components/views/BlackWaitListCreateProfile';
import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PublicAuthenticationToken from '../external/fox-typescript/core/PublicAuthenticationToken';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import { SignInOtherUserForm } from '../model/SignInOtherUserForm';
import PasswordUpdate from '../model/update/PdlUser/PasswordUpdate';

class PdlAuthService {
  private baseUrl: string = '/pdl-auth';

  async signUp(form: CreateProfileForm): Promise<ApiResponse<string>> {
    return await HttpClient.post(`${this.baseUrl}/sign-up`, form);
  }

  async signUpUsingWaitlist(form: WaitlistCreateProfileForm): Promise<ApiResponse<string>> {
    return await HttpClient.post(`${this.baseUrl}/wait-list/sign-up`, form, true);
  }

  async changePassword(dto: PasswordUpdate): Promise<ApiResponse<String>> {
    return await HttpClient.put(`${this.baseUrl}/change-password`, dto, true);
  }

  async validateAccount(code: string, email: string) {
    return await HttpClient.get(`${this.baseUrl}/validate?code=${code}&email=${email}`);
  }
  async validateLoginOtherUser(form: SignInOtherUserForm): Promise<ApiResponse<PublicAuthenticationToken>> {
    return await HttpClient.post(`auth/login-as`, form);
  }

}
export default new PdlAuthService();
