import { Container, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SmallBike from '../../../model/SmallBike';
import BikeService from '../../../services/BikeService';
import EarningService from '../../../services/EarningService';
import { IAppTheme } from '../../../utils/AppTheme';
import Logger from '../../../external/pdl-common/utils/Logger';
import PaymentUtils from '../../../utils/PaymentUtils';
import { RequestStatus } from '../../../utils/RequestStatus';
import AllTimeStatsComponent from '../../commons/AllTimeStatsComponent';
import AppHeader from '../../commons/AppHeader';
import RequestStatusFeedBack from '../../commons/RequestStatusFeedback';
import { AppLayout } from '../../core/AppLayout';
import { MonthlyBreakdown } from '../../../model/utils/MonthlyBreakdown';
import MonthlyBreakdownComponent from '../../commons/MonthlyBreakdownComponent';
import AppImage from '../../../external/pdl-common/components/commons/AppImage';
import AlertService from '../../../services/AlertService';

const logger = new Logger('EarningDetails');

const EarningDetails = () => {
  const classes = useStyles();
  const { externalId } = useParams();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [smallBike, setSmallBike] = useState<SmallBike | undefined>(undefined);
  const [monthlyBreakdown, setMonthlyBreakdown] = useState<MonthlyBreakdown[]>([]);

  useEffect(() => {
    load();
  }, [externalId]);

  const load = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      if (externalId) {
        const responses = await Promise.all([
          EarningService.getMonthlyBreakdownByBikeExternalId(externalId),
          BikeService.getPublicBike(externalId),
        ]);

        setMonthlyBreakdown(responses[0].getContent());
        setSmallBike(responses[1].getContent());
      }

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      logger.error('Error fetching earnings', error);
      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackCustomError('Error fetching earnings');
      }
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const getContent = (): JSX.Element => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={load} />
        </div>
      );
    }

    return (
      <>
        {/* Bike image */}
        {smallBike && (
          <div className={classes.bikeImageContainer}>
            <AppImage
              src={smallBike.images && smallBike.images.length > 0 ? smallBike.images[0].url : ''}
              alt="bike"
              className={classes.bikeImage}
            />
          </div>
        )}

        {/* All time stats */}
        <AllTimeStatsComponent
          data={{
            rentals: PaymentUtils.getTotalRentals(monthlyBreakdown),
            revenue: PaymentUtils.getTotalRevenue(monthlyBreakdown),
          }}
        />

        {/* Monthly Breakdown*/}
        <Container sx={muiStyles.sectionsContainer}>
          <MonthlyBreakdownComponent data={monthlyBreakdown} />
        </Container>
      </>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content={smallBike?.title || 'Earnings'} />}
      content={() => getContent()}
    />
  );
};

export default EarningDetails;

const muiStyles = {
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  } as SxProps,
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  bikeImageContainer: {
    backgroundColor: theme.colors.black_01000E,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1.25em',
    paddingBottom: '2.5em',
  },
  bikeImage: {
    maxHeight: '15em',
    maxWidth: '20em',
    borderRadius: '1.875em',
  },
}));
