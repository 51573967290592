import { Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Reservation from '../../model/Reservation';
import ReservationService from '../../services/ReservationService';
import { IAppTheme } from '../../utils/AppTheme';
import Logger from '../../external/pdl-common/utils/Logger';
import AppHeader from '../commons/AppHeader';
import { AppTextField } from '../commons/AppTextField';
import ListingLink from '../commons/ListingLink';
import SimpleButton from '../commons/SimpleButton';
import { AppLayout } from '../core/AppLayout';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import NotificationModal from '../commons/NotificationModal';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { RequestStatus } from '../../utils/RequestStatus';
import { ReviewCreation } from '../../model/creation/ReviewCreation';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import ApiResponse from '../../external/fox-typescript/core/ApiResponse';
import ReviewEntity from '../../model/ReviewEntity';
import AlertService from '../../services/AlertService';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import StringUtils from '../../utils/StringUtils';
import AppRating from '../../external/pdl-common/components/commons/Ratings/AppRating';
import Navbar from '../core/Navbar';

interface Props {
  userReviewTitle: string;
  bikeReview: boolean;
  checkExistingReview: (externalId: string) => Promise<void>;
  onSubmit: (form: ReviewCreation) => Promise<ApiResponse<ReviewEntity>>;
  handleGoBack: () => void;
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  container: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },

  start: {
    fontSize: '2.5em !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '2em !important',
    },
  },
}));

const logger = new Logger('LeaveReview');

export function LeaveReview(props: Props) {
  const classes = useStyles();
  const { externalId } = useParams();
  const navigate = useNavigate();

  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [form, setForm] = useState<ReviewCreation>({ bikeComment: '', userComment: '', rating: 0 });
  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [submitStatus, setSubmitStatus] = useState(RequestStatus.SUCCESS);

  useEffect(() => {
    load();
  }, []);

  const handleSubmit = async () => {
    try {
      setSubmitStatus(RequestStatus.LOADING);
      await props.onSubmit(form);
      setNotificationModalOpen(true);
    } catch (error) {
      AlertService.showSnackInternalError();
      logger.error('Error when creating review', error);
    } finally {
      setSubmitStatus(RequestStatus.SUCCESS);
    }
  };

  const handleGoBack = () => {
    setNotificationModalOpen(false);
    props.handleGoBack();
  };

  const load = async () => {
    try {
      if (externalId) {
        setRequestStatus(RequestStatus.LOADING);
        const response = await ReservationService.getPublicReservation(externalId);
        setReservation(response.getContent());

        try {
          await props.checkExistingReview(externalId);
          appNavigate(navigate, AppRoutes.MAIN, null);
          return;
        } catch (error: any) {
          if (error.response.status != 404) {
            logger.error('Error trying to check for existing reviews', error);
            setRequestStatus(RequestStatus.ERROR);
            return;
          }
        }

        setRequestStatus(RequestStatus.SUCCESS);
      } else {
        appNavigate(navigate, AppRoutes.MAIN, null);
      }
    } catch (error: any) {
      logger.error('Error fetching reservation detail', error?.response?.data?.message || error);
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const handleCustomChange = (inputName: string, inputValue: string | number) => {
    let updatedForm = { ...form };
    (updatedForm as any)[inputName] = inputValue;
    setForm(updatedForm);
  };

  return (
    <AppLayout
      noNavBar
      header={() => <AppHeader content="Leave a review" />}
      content={() => {
        if (requestStatus == RequestStatus.LOADING || requestStatus == RequestStatus.ERROR) {
          return <RequestStatusFeedBack status={requestStatus} onRetry={load} />;
        }

        return (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
              <div
                style={{ position: 'absolute', width: '100%', height: '100%', overflow: 'auto' }}
              >
                <Container className={classes.container}>
                  <ListingLink
                    imageUrl={
                      reservation!.bike.images && reservation!.bike.images.length > 0
                        ? reservation!.bike.images[0].url
                        : ''
                    }
                    title={reservation!.bike.title}
                    cells={[
                      { label: 'Hosted by', value: StringUtils.formatShortUserNameAndLocation(reservation!.bike.owner, reservation!.bike) },
                      {
                        label: 'Dates',
                        value: DateUtils.formattedRangeDates(
                          new Date(reservation!.startDate),
                          new Date(reservation!.endDate)
                        ),
                      },
                    ]}
                  />

                  <Divider style={{ marginTop: '1em' }} />

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AppTypography
                      type={PDLTypography.smallHeading}
                      customStyles={{ marginBottom: '1em', marginTop: '1.5em' }}
                    >
                      Leave a rating
                    </AppTypography>
                    <AppRating
                      value={form.rating}
                      name="rating"
                      size="large"
                      classes={{ sizeLarge: classes.start }}
                      onChange={(event) => {
                        var input = event.target as HTMLInputElement;
                        handleCustomChange(input.name, parseInt(input.value));
                      }}
                    />

                    {props.bikeReview && (
                      <AppTextField
                        name="bikeComment"
                        value={form.bikeComment}
                        onInputChange={(inputName: string, inputValue: string) =>
                          handleCustomChange(inputName, inputValue)
                        }
                        inputProps={{ multiline: true, maxRows: 4, minRows: 4 }}
                        label="Review the bike"
                        labelProps={{
                          type: PDLTypography.smallHeading,
                          customStyles: { marginBottom: '1em', marginTop: '1.5em' },
                        }}
                      />
                    )}

                    <AppTextField
                      name="userComment"
                      value={form.userComment}
                      onInputChange={(inputName: string, inputValue: string) =>
                        handleCustomChange(inputName, inputValue)
                      }
                      inputProps={{ multiline: true, maxRows: 4, minRows: 4 }}
                      label={props.userReviewTitle}
                      labelProps={{
                        type: PDLTypography.smallHeading,
                        customStyles: { marginBottom: '1em', marginTop: '1.5em' },
                      }}
                    />
                  </div>
                </Container>
              </div>
            </div>

            <Divider />

            <Container>
              <div style={{ padding: '0.62em 0em 69px 0em' }}>
                <SimpleButton
                  onClick={handleSubmit}
                  disabled={
                    submitStatus == RequestStatus.LOADING ||
                    !form.rating ||
                    !form.userComment ||
                    (props.bikeReview && !form.bikeComment)
                  }
                  isLoading={submitStatus == RequestStatus.LOADING}
                >
                  Submit Review
                </SimpleButton>
              </div>
            </Container>
            <NotificationModal
              open={notificationModalOpen}
              text="Thank you for your review"
              onClose={handleGoBack}
            />
            <Navbar/>
          </div>
        );
      }}
    />
  );
}
