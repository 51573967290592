export enum BikeStatus {
  DRAFT_FIRST_STEP = 'DRAFT_FIRST_STEP',
  DRAFT_SECOND_STEP = 'DRAFT_SECOND_STEP',
  DRAFT_THIRD_STEP = 'DRAFT_THIRD_STEP',
  INSURANCE = 'INSURANCE',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  DISABLED = 'DISABLED',
}
