import { Container, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppTheme from '../../utils/AppTheme';

interface Props {
  children: React.ReactNode;
  BottomElement?: JSX.Element;
  childrenContainerStyle?: React.CSSProperties;
  bottomElementStyle?: React.CSSProperties;
  innerContainerStyle?: React.CSSProperties;
  bottomElementFullWidth?: boolean;
  divider?: boolean;
}

const FormContainer = ({
  children,
  BottomElement,
  childrenContainerStyle,
  bottomElementFullWidth,
  innerContainerStyle,
  bottomElementStyle,
  divider = true
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.mainContainer}>
        <div className={classes.innerContainer} style={innerContainerStyle}>
          <div style={{ margin: '0% 5%', ...childrenContainerStyle }}>
            {children}
            {divider &&
              <div style={{ height: '3em' }} />
            }
          </div>
        </div>
      </Container>
      {BottomElement && (
        <div className={classes.bottomElementContainer} style={bottomElementStyle}>
          {bottomElementFullWidth ? (
            BottomElement
          ) : (
            <div className={classes.bottomElement}>{BottomElement}</div>
          )}
        </div>
      )}
    </>
  );
};

export default FormContainer;

const useStyles = makeStyles({
  innerContainer: {
    overflowY: 'auto',
    width: '100%',
    height: '100%',
  },
  bottomElementContainer: {
    borderTop: `0.063em solid ${AppTheme.colors.gray_E3E5E4}`,
    display: 'flex !important',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '1em 5%',
  },
  bottomElement: {
    width: '100%',
    maxWidth: '20em',
    alignSelf: 'center',
  },
  mainContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 1,
    position: 'relative',
    padding: 0,
  },
});
