import { Container, Divider, Grid } from '@mui/material';
import { } from '@mui/material';
import { makeStyles } from '@mui/styles';
import StringUtils from '../../../../utils/StringUtils';
import { ReservationDetailsPerson } from '../Common/ReservationDetailsPerson';
import { AppAssets } from '../../../../assets';
import { ReservationDetailsHeader } from '../../../../external/pdl-common/components/ReservationDetailsHeader';
import { COLORS, IAppTheme } from '../../../../utils/AppTheme';
import React, { useEffect, useRef, useState } from 'react';
import { RequestStatus } from '../../../../utils/RequestStatus';
import Reservation from '../../../../model/Reservation';
import { useNavigate, useParams } from 'react-router-dom';
import reservationService from '../../../../services/ReservationService';
import { AppTypography } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';
import { AppLayout } from '../../../core/AppLayout';
import RequestStatusFeedBack from '../../../commons/RequestStatusFeedback';
import AppHeader from '../../../commons/AppHeader';
import { CallToActionWithIcon } from '../Common/CallToActionWithIcon';
import SimpleButton from '../../../commons/SimpleButton';
import { appNavigate, AppRoutes } from '../../../../utils/AppNavigation';
import { ImageCarousel } from '../../../commons/ImageCarousel';
import { CancelReservationBottomSheet } from '../../../commons/CancelReservationBottomSheet';
import NotificationModal from '../../../commons/NotificationModal';
import reviewService from '../../../../services/ReviewService';
import { RenterReview } from '../../../../model/RenterReview';
import { RenterReviewSection } from '../Common/RenterReviewSection';
import InboxService from '../../../../services/InboxService';
import AlertService from '../../../../services/AlertService';
import ChatUtils from '../../../../utils/ChatUtils';
import Logger from '../../../../external/pdl-common/utils/Logger';
import { SimpleCellText } from '../../../../external/pdl-common/components/SimpleCellText';
import InsuranceDetailsBottomSheet from '../Common/InsuranceDetailsBottomSheet';
import DateUtils from '../../../../external/pdl-common/utils/DateUtils';
import {AlertDialog} from "../../../commons/AlertDialog";

interface Props {
  setCustomNavBar: (navBar?: () => JSX.Element) => void;
}

const logger = new Logger('CancelReservation');

export default function BikeReservationDetailsRenter(props: Props) {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();

  const mounted = useRef(false);

  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [submitStatus, setSubmitStatus] = useState<RequestStatus | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [requestRenterReviewStatus, setRequestRenterReviewStatus] = useState<RequestStatus>(
    RequestStatus.LOADING
  );
  const [renterReview, setRenterReview] = useState<RenterReview>();
  const [cancelReservationModalOpen, setCancelReservationModalOpen] = useState<boolean>(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState<boolean>(false);
  const [insuranceDetailsModalOpen, setinsuranceDetailsModalOpen] = useState<boolean>(false);
  const [renterRefundAmount, setRenterRefundAmount] = useState<number | undefined>(undefined);
  const [searchDialogModalOpen, setSearchDialogModalOpen] = useState<boolean>(false);

  useEffect(() => {
    mounted.current = true;
    load();
    return () => {
      props.setCustomNavBar(undefined);
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const checkPickUpValidations = (reservation: Reservation): boolean => {
    return (
      (reservation.disabledPickUpAt == undefined)
      &&
      DateUtils.greaterThanTodayMinusHoursInTimezone(
        new Date(reservation.startDate),
        reservation.bike.location.timezoneId!,
          12
      ) &&
      !DateUtils.greaterThanTodayInTimezone(
        new Date(reservation.endDate),
        reservation.bike.location.timezoneId!
      ) &&
      !reservation.pickedUpAt
    );
  };


  const load = async () => {
    const reservationExternalId = params['externalId'];
    if (!reservationExternalId) {
      appNavigate(navigate, AppRoutes.MAIN, null);
      return;
    }

    try {
      setRequestStatus(RequestStatus.LOADING);
      const reservation = (
        await reservationService.getSemiPublicReservation(reservationExternalId)
      ).getContent();

      const renterRefundAmount = await reservationService.getRenterRefoundAmount(
        reservation.externalId!
      );
      setRenterRefundAmount(renterRefundAmount.getContent());

      if (mounted.current && checkPickUpValidations(reservation)) {
        props.setCustomNavBar(() => <PickUpButton reservation={reservation} />);
      }

      setReservation(reservation);
      setRequestStatus(RequestStatus.SUCCESS);

      loadRenterReview(reservation);
    } catch (error) {
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const loadRenterReview = async (reservation: Reservation) => {
    try {
      setRequestRenterReviewStatus(RequestStatus.LOADING);
      if (reservation.droppedOffAt) {
        const response = await reviewService.getRenterReviewForReservation(reservation.externalId!);
        setRenterReview(response.getContent());
      }
      setRequestRenterReviewStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      if (error.response.status == 404) {
        if (mounted.current) {
          props.setCustomNavBar(LeaveReviewButton);
        }
        setRequestRenterReviewStatus(RequestStatus.SUCCESS);
      } else {
        setRequestRenterReviewStatus(RequestStatus.ERROR);
      }
    }
  };

  const cancelReservation = async () => {
    const reservationExternalId = params['externalId'];

    if (reservationExternalId) {
      try {
        setSubmitStatus(RequestStatus.LOADING);

        await reservationService.cancelReservationAsRenter(reservationExternalId);

        if (reservation) {
          setReservation({ ...reservation, cancelledAt: new Date() });
        }

        setCancelReservationModalOpen(false);
        setNotificationModalOpen(true);
        setSubmitStatus(RequestStatus.SUCCESS);
      } catch (error: any) {
        logger.error('Error cancelling reservation', error);

        if (error?.response?.data?.message) {
          AlertService.showSnackCustomError(error?.response?.data?.message);
        } else {
          AlertService.showSnackInternalError();
        }

        setSubmitStatus(RequestStatus.ERROR);
      }
    }
  };

  const PickUpButton = (props: { reservation: Reservation }) => (
    <div className={classes.navBar}>
      <Container style={{ bottom: 59}}>
        <SimpleButton
          disabled={props.reservation?.pickUpCheckList?.externalId != undefined}
          onClick={() => {

            appNavigate(navigate, AppRoutes.RESERVATION_PICK_UP, {
              externalId: props.reservation.externalId!,
            });
          }}
        >
          {
            props.reservation.pickUpCheckList?.externalId != undefined ? 'Pick Up In Progress' : 'Start Pick-Up'
          }
        </SimpleButton>
      </Container>
    </div>
  );

  const LeaveReviewButton = () => (
    <div className={classes.navBar}>
      <Container>
        <SimpleButton
          onClick={() =>
            appNavigate(navigate, AppRoutes.TRIPS_LEAVE_REVIEW, {
              externalId: params['externalId']!,
            })
          }
        >
          Leave A Review
        </SimpleButton>
      </Container>
    </div>
  );

  const getReceipt = () => {
    const receiptUrl = reservation!.receiptUrl
      ? reservation!.receiptUrl
      : 'https://via.placeholder.com/400x600.png?text=Receipt+not+available';

    /*
     * Since we can't embed stripe's receipts page, we show it in a new tab.
     * Another alternative could be to use an API that takes a screenshot of the receipt page
     * and return it as an image URL, but we would have to pay to use it.
     * For example: https://www.screenshotapi.net/
     */

    window.open(receiptUrl, '_blank');

    /*
      return appNavigate(navigate, AppRoutes.RECEIPT_DETAIL, {
        url: encodeURIComponent(receiptUrl),
      });
    */
  };

  const navigateToHostChatChannel = async () => {
    const response = await InboxService.getByReservationExternalId(
      params['externalId'] || reservation?.externalId!
    );

    response
      .onSuccess((response) => {
        const pdlChatChannel = response.getContent();
        const { user: receiver } = ChatUtils.getReceiverMember(pdlChatChannel.channel);

        appNavigate(
          navigate,
          AppRoutes.CHAT,
          { externalId: pdlChatChannel.externalId! },
          { state: { pdlChatChannel, receiver } }
        );
      })
      .onError((response) => {
        AlertService.showSnackCustomError(response.getContent());
      });
  };

  const getCancellationFee = () => {
    if (renterRefundAmount) {
      return Math.round((reservation!.totalPrice - renterRefundAmount) * 100) / 100;
    }
    return 0;
  };

  const checkCancellationConditions = (): boolean => {
    // We check if today is not greater than the reservation end date
    // and the bike hasn't been dropped off
    return (
      !DateUtils.greaterThanTodayInTimezone(
        new Date(reservation?.endDate!),
        reservation?.bike.location.timezoneId!
      ) && reservation?.pickedUpAt === undefined
    );
  };

  const getHeader = () => {
    return (
      <AppHeader
        content="Reservation details"
        onBack={() => appNavigate(navigate, AppRoutes.TRIPS, null)}
        showBackButton={submitStatus !== RequestStatus.LOADING}
      />
    );
  };

  const getBody = () => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ height: '80vh' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={load} />
        </div>
      );
    }

    const location = reservation!.bike.location;

    return (
      <>
        <Container
          className={classes.container}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {/** Bike image with title */}
          <ReservationDetailsHeader
            imageSrc={
              reservation && reservation.bike.images && reservation.bike.images.length > 0
                ? reservation.bike.images[0].url
                : ''
            }
            title={reservation!.bike.title}
          />

          {/** Listing */}
          <Grid container columnSpacing={2} columns={2} sx={{ mt: '1em' }}>
            <Grid item xs={1}>
              <SimpleCellText
                label="Rental Dates"
                valueProps={{
                  type: PDLTypography.smallParagraph,
                  customStyles: { textDecoration: 'underline' },
                }}
                value={DateUtils.formattedRangeDates(
                  new Date(reservation!.startDate),
                  new Date(reservation!.endDate),
                  true
                )}
              />
            </Grid>
            <Grid item xs={1}>
              <SimpleCellText
                label="Pick-up and Return"
                value={[location.city, location.state, location.zipCode].filter(Boolean).join(', ')}
              />
            </Grid>
          </Grid>
          <CallToActionWithIcon
            text={'View Listing'}
            asset={<AppAssets.EyeIcon />}
            style={{ marginTop: '1em' }}
            onClick={() =>
              appNavigate(
                navigate,
                AppRoutes.BIKE_DETAIL,
                {
                  externalId: reservation!.bike.externalId!,
                }
                //{ state: { startDate: props.startDate, endDate: props.endDate } }
              )
            }
          />

          <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />

          {/** Pick-up details */}
          <AppTypography type={PDLTypography.mediumHeading}>Pick-up details</AppTypography>
          <Grid container columns={1} sx={{ mt: '1em' }}>
            <Grid item xs={1}>
              <SimpleCellText
                label="Pick-up Address"
                value={`${location.streetAddress}${location.streetAddressDetails ? ' ' + location.streetAddressDetails : ''
                  }, ${location.city}, ${location.state}, ${location.zipCode}`}
              />
            </Grid>
          </Grid>
          <CallToActionWithIcon
            text={'Get Directions'}
            asset={<AppAssets.MapPinIcon className={classes.mapPinIcon} />}
            style={{ marginTop: '1em' }}
            onClick={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${location.latitude!},${location.longitude!}`,
                '_blank'
              )
            }
          />

          <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />

          {/** Host Details */}
          <ReservationDetailsPerson
            title="Host details"
            imageSrc={reservation!.bike.owner.user.avatarUrl}
            imageSmallTitle="Hosted by"
            name={StringUtils.formatShortUserName(reservation!.bike.owner.user)}
            callToActionText="Message Host"
            callToActionIcon={<AppAssets.ChatBubble />}
            onClick={navigateToHostChatChannel}
          />

          <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />

          {/** Payment info */}
          <AppTypography type={PDLTypography.mediumHeading}>Payment info</AppTypography>
          <Grid container columns={1} sx={{ mt: '1em' }}>
            <Grid item xs={1}>
              <SimpleCellText
                label="Total Cost"
                value={`$${Math.round(reservation!.totalPrice * 100) / 100}`}
              />
            </Grid>
          </Grid>

          <CallToActionWithIcon
            text={'View Receipt'}
            asset={<AppAssets.Receipt />}
            style={{ marginTop: '1em' }}
            onClick={getReceipt}
          />

          <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />

          {/** Protection Plan */}
          {reservation?.renterInsurance && (
            <>
              <AppTypography type={PDLTypography.mediumHeading}>Protection Plan</AppTypography>

              <CallToActionWithIcon
                text={'View Protection Program'}
                asset={<AppAssets.EyeIcon />}
                style={{ marginTop: '1em' }}
                onClick={() => setinsuranceDetailsModalOpen(true)}
              />

              <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
            </>
          )}

          {/** Rental images */}
          {/** TODO: conditional render if the user did the pick up */}
          {reservation!.pickedUpAt && (
            <>
              <AppTypography type={PDLTypography.mediumHeading}>Rental images</AppTypography>
              <div style={{ marginTop: '1em' }}>
                <ImageCarousel images={reservation!.pickUpImages} />
              </div>

              <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
            </>
          )}
          {/** My review */}
          {/** TODO: conditional render if the user did the review */}
          <RenterReviewSection
            title="My review"
            requestRenterReviewStatus={requestRenterReviewStatus}
            renterReview={renterReview}
            onRetry={() => loadRenterReview(reservation!)}
          />

          {/** Support */}
          <AppTypography type={PDLTypography.mediumHeading}>Support</AppTypography>

          {checkCancellationConditions() && (
            <CallToActionWithIcon
              text={'Cancel Reservation'}
              asset={<AppAssets.CrossCircle />}
              style={{ marginTop: '1.5em' }}
              onClick={() => setCancelReservationModalOpen(true)}
            />
          )}

          {/* Insurance claim 
            * Commented due to client specification.
          {reservation?.pickedUpAt && reservation?.renterInsurance && (
            <InsuranceClaim reservation={reservation} claimInitiator={ClaimInitiator.RENTER} />
          )}
          */}

          <CallToActionWithIcon
            text={'Report an issue'}
            asset={<AppAssets.FlagIcon />}
            style={{ marginTop: '1.5em', marginBottom: '5em' }}
            onClick={() => setSearchDialogModalOpen(true)}
          />
        </Container>

        <CancelReservationBottomSheet
          reservation={reservation}
          open={cancelReservationModalOpen}
          onClose={() => setCancelReservationModalOpen(false)}
          onCancelReservation={cancelReservation}
          refundAmount={renterRefundAmount || 0}
          submitStatus={submitStatus}
          cancelationFee={getCancellationFee()}
        />

        <AlertDialog
            title="Report an Issue"
            content={"Report an issue if you find any small changes or disagreements in relation to what was previously reserved. Reporting an issue implies that you accept that, despite the finding, you will move forward with your business."}
            open={searchDialogModalOpen}
            confirmLabel={"Continue"}
            onConfirm={() => {
              setSearchDialogModalOpen(false);
              appNavigate(navigate, AppRoutes.REPORT_ISSUE, null, { state: reservation })
            }}
            onCancel={() => {
              setSearchDialogModalOpen(false);
            }}
        />

        <NotificationModal
          open={notificationModalOpen}
          onClose={() => {
            setNotificationModalOpen(false);
            appNavigate(navigate, AppRoutes.TRIPS, null);
          }}
          text="Your reservation has been canceled"
        />

        {reservation?.renterInsurance && (
          <InsuranceDetailsBottomSheet
            open={insuranceDetailsModalOpen}
            onClose={() => setinsuranceDetailsModalOpen(false)}
            insurance={reservation.renterInsurance}
          />
        )}
      </>
    );
  };

  return <AppLayout fromHome header={getHeader} content={getBody} />;
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  detailSection: {
    marginTop: '2em',
    marginBottom: '1em',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  title: {
    marginTop: '1.5em',
    marginBottom: '1em',
  },
  mapPinIcon: {
    '& path': {
      stroke: theme.colors.persianBlue_2238CB,
    },
  },
  navBar: {
    padding: '0.62em 0em 69px 0em',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: COLORS.gray_E3E5E4,
  },
}));
