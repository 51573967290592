import { ButtonBase, Grid } from '@mui/material';
import { makeStyles, CSSProperties } from '@mui/styles';
import AppImage from './commons/AppImage';
import { PartDetail } from '../model/PartDetail';
import StringUtils from '../utils/StringUtils';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { AppTypography } from './Typography/AppTypography';
import { PDLTypography } from './Typography/PDLTypography';
import { BikePartType } from '../utils/enums/BikePartType';
import ImageGallery from './commons/ImageGallery';
import { useState } from 'react';

interface Props {
  part: PartDetail;
}

const BikePartDetailItem = ({ part }: Props) => {
  const [imageGalleryOpen, setImageGalleryOpen] = useState<boolean>(false);
  const getMeasurementLabel = (measurement: number) => {
    if (measurement === 0) {
      return '';
    }
    // Only the handlebar is measured in cm, all other parts are measured in mm.
    return `${measurement} ${part.type === BikePartType.handlebar ? 'cm' : 'mm'}`;
  };
  const getCategoryLabel = (category: string) => {
    var categoryAdditionalLabel = '';
    if (category === '') {
      return '';
    }
    if (part.type === BikePartType.handlebar) {
      categoryAdditionalLabel = 'cm';
    }
    if (part.type === BikePartType.crankset || part.type === BikePartType.stem) {
      categoryAdditionalLabel = 'mm';
    }
    return `${category} ${categoryAdditionalLabel}`;
  };

  const classes = useStyles();
  const info = [
    part.brand,
    part.model,
    part.size || '',
    part.category ? getCategoryLabel(part.category) : '',
    part.additionalCategory || '',
    part.measurement ? getMeasurementLabel(part.measurement) : '',
    part.material || '',
    part.additionalInfo || '',
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <>
      <Grid container mx={1} sx={muiStyles.grid}>
        <Grid item xs={5}>
          <div className={classes.imgWithViewIcon}
            onClick={() => part.images.length > 0 && setImageGalleryOpen(true)}>
            <AppImage
              src={part.images && part.images.length > 0 ? part.images[0].url : ''}
              alt={part.brand}
            />

            {part.images.length > 0 && (
              <ButtonBase className={classes.buttonBase}>
                <RemoveRedEyeOutlinedIcon />
              </ButtonBase>
            )}
          </div>
        </Grid>
        <Grid item xs={7}>
          <div className={classes.partInfo}>
            <AppTypography
              type={PDLTypography.mediumHeading}
              customClasses={classes.title}
              children={StringUtils.formatPartDetailType(part.type)}
            />
            <br />
            <AppTypography
              type={PDLTypography.paragraph}
              customStyles={{ marginTop: '0.8em' }}
              children={info}
            />
          </div>
        </Grid>
      </Grid>

      {imageGalleryOpen && (
        <ImageGallery
          data={part.images}
          onClose={() => setImageGalleryOpen(false)}
          initialSlide={0}
          infinite={false}
        />
      )}
    </>
  );
};

export default BikePartDetailItem;

const muiStyles = {
  grid: {
    borderRadius: '0.8em',
    marginBottom: '0.5em',
    marginTop: '0.5em',
    borderColor: 'black',
    borderWidth: '2px',
    cursor: 'pointer',
  },
};

const useStyles = makeStyles({
  title: {
    textAlign: 'left',
    marginTop: 0,
    marginBottom: '0.5em',
    paddingRight: '0.2em',
  },

  partInfo: {
    paddingLeft: '0.5em',
    textAlign: 'left',
    marginLeft: '0.625em',
  },

  buttonBase: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    color: 'white',
  },

  imgWithViewIcon: {
    borderRadius: '0.8em',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    height: '8.125em',
    backgroundColor: 'white',
    display: 'flex',
  },
});
