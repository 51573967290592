import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { useLocation, useNavigate } from 'react-router';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme, { IAppTheme } from '../../utils/AppTheme';
import { DateRange } from 'react-date-range';
import SimpleButton from '../commons/SimpleButton';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { InputWithIcon } from '../commons/InputWithIcon';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Location from '../../external/fox-typescript/core/Location';
import ReservationService from '../../services/ReservationService';
import Logger from '../../external/pdl-common/utils/Logger';
import { addDays } from 'date-fns';
import { RequestStatus } from '../../utils/RequestStatus';
import AlertService from '../../services/AlertService';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { useFeatureToggle } from '../../external/pdl-common/hooks/useFeatureToggle';
import { Features } from '../../external/pdl-common/utils/enums/Features';
import DynamicPropertyService from '../../services/DynamicPropertyService';
import { Bike } from '../../external/pdl-common/model/Bike';
import { UserAdditionalInfoCheck } from '../../model/UserAdditionalInfoCheck';
import { AppLayout } from '../core/AppLayout';
import environment from '../../environment.json';

const logger = new Logger('ExploreCalendar');

export default function ExploreCalendar() {
  const [currentDayReservationEnabled, setCurrentDayReservationEnabled] = useState<boolean>(false);

  const initialDate = () => {
    return currentDayReservationEnabled ? new Date() : addDays(new Date(), 1);
  };

  const getStartDate = () => {
    const date = initialDate();
    date.setHours(0, 0, 0);
    return date;
  };

  const getEndDate = () => {
    const date = initialDate();
    date.setHours(23, 59, 58, 999);
    return date;
  };

  const maxDate = () => {
    return addDays(new Date(), 180);
  };

  const state: {
    location?: Location;
    startDate: Date;
    endDate: Date;
    externalId?: string;
    requestFromBikeDetail?: boolean;
    bike?: Bike;
    userAdditionalInfo?: boolean;
  } = (useLocation().state as any) || {};

  const navigate = useNavigate();
  const classes = useStyles();

  const [isUtcFormatEnabled] = useFeatureToggle(Features.IS_UTC_FORMAT_ENABLED);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.SUCCESS);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);
  const [reservation, setReservation] = useState([
    {
      startDate: state.startDate
        ? new Date(
          DateUtils.toTimezone(state.startDate, Intl.DateTimeFormat().resolvedOptions().timeZone)
        )
        : getStartDate(),
      endDate: state.endDate
        ? new Date(
          DateUtils.toTimezone(state.endDate, Intl.DateTimeFormat().resolvedOptions().timeZone)
        )
        : getEndDate(),
      key: 'selection',
    },
  ]);
  const [bike, setBike] = useState<Bike | undefined>(state.bike);
  const [userAdditionalInfo, setUserAdditionalInfo] = useState<boolean | undefined>(
    state.userAdditionalInfo
  );
  const [isInsuranceFeatureEnabled] = useFeatureToggle(Features.INSURANCE);

  useEffect(() => {
    if (environment.env === 'prod') {
      setCurrentDayReservationEnabled(false);
    } else {
      try {
        DynamicPropertyService.getCurrentDayReservationEnabled().then((response) => {
          setCurrentDayReservationEnabled(response.getContentOrThrowError());
        });
      } catch (err: any) {
        logger.error('There was a problem getting valid dates information.', err);
      }
    }
  }, []);

  useEffect(() => {
    if (state.externalId && isUtcFormatEnabled) {
      ReservationService.getAllUnavailableDatesForBike(state?.externalId)
        .then((response) => {
          const reservedDates = response.getContent().reservedDates;
          const blockoutDates = response.getContent().blockoutDates;
          const dates = reservedDates.concat(blockoutDates);
          setDisabledDates(dates.map((d) => DateUtils.toUtcTime(new Date(d))));
        })
        .catch((error) => logger.error(error, "Couldn't get reserved dates"));
    }
  }, [isUtcFormatEnabled]);

  const onClickNextButton = async () => {
    const startDate = reservation[0].startDate;
    const endDate = reservation[0].endDate;

    if (!state.externalId) {
      // This means that we are using explore calendar in the reservation flow.
      appNavigate(navigate, AppRoutes.SEARCH, null, {
        state: {
          searchLocation: state?.location,
          startDate: isUtcFormatEnabled ? DateUtils.dateToUTC(startDate, 0, 0, 0, 0) : startDate,
          endDate: isUtcFormatEnabled ? DateUtils.dateToUTC(endDate, 23, 59, 58, 999) : endDate,
        },
      });
    } else {
      // This means that we are using explore calendar to set startDate-endDate in BikeDetail view, so we return to this screen.
      setRequestStatus(RequestStatus.LOADING);

      (
        await ReservationService.checkBikeAvailable(
          state?.externalId!,
          isUtcFormatEnabled ? DateUtils.dateToUTC(startDate, 0, 0, 0, 0) : startDate,
          isUtcFormatEnabled ? DateUtils.dateToUTC(endDate, 23, 59, 58, 999) : endDate
        )
      )
        .onSuccess((response) => {
          if (!response.getContent()) {
            AlertService.showSnackCustomError('The bike is not available for the range provided');
            setRequestStatus(RequestStatus.ERROR);
            return;
          }

          if (userAdditionalInfo) {
            const route: AppRoutes = AppRoutes.INSURANCE

            appNavigate(navigate, route, null, {
              state: {
                startDate: isUtcFormatEnabled
                  ? DateUtils.dateToUTC(startDate, 0, 0, 0, 0)
                  : startDate,
                endDate: isUtcFormatEnabled
                  ? DateUtils.dateToUTC(endDate, 23, 59, 58, 999)
                  : endDate,
                bike,
              },
            });
          } else {
            const defaultState = {
              startDate: isUtcFormatEnabled
                ? DateUtils.dateToUTC(startDate, 0, 0, 0, 0)
                : startDate,
              endDate: isUtcFormatEnabled ? DateUtils.dateToUTC(endDate, 23, 59, 58, 999) : endDate,
              bike,
            };
            appNavigate(navigate, AppRoutes.COMPLETE_ADDITIONAL_INFORMATION, null, {
              state: {
                ...defaultState,
                userAdditionalInfo,
                navigateTo: AppRoutes.INSURANCE
              },
            });
          }
        })
        .onError((response) => {
          AlertService.showSnackCustomError(response.getContent());
          setRequestStatus(RequestStatus.ERROR);
        });
    }
  };

  const onClickBackButton = () => {
    if (!state?.externalId) {
      appNavigate(navigate, AppRoutes.EXPLORE_SEARCH, null);
    } else {
      navigate(-1);
    }
  };

  const getBody = () => {
    return (
      <Grid item container xs={12} className={classes.gridContainer}>
        <Grid className={classes.gridHeader}>
          <Box className={classes.textBox}>
            <InputWithIcon
              disabled
              name={'inputText'}
              value={state?.location?.geocode || ''}
              icon={<ChevronLeftIcon onClick={onClickBackButton} style={{ cursor: 'pointer' }} />}
              onInputChange={() => console.log('')}
            />
          </Box>
          <Divider variant="middle" />
          <>
            <Grid item xs={12} className={classes.calendarView}>
              <div>
                <AppTypography
                  type={PDLTypography.mediumHeading}
                  customClasses={classes.calendarText}
                >
                  When do you need it?
                </AppTypography>
                <div className={classes.calendarDate}>
                  <AppTypography
                    type={PDLTypography.smallParagraph}
                    customClasses={classes.calendarDateText}
                  >
                    {DateUtils.formattedRangeDates(
                      reservation[0].startDate,
                      reservation[0].endDate,
                      false,
                      false
                    )}
                  </AppTypography>
                </div>
              </div>
            </Grid>
            <Divider variant="middle" />
            <Grid className={classes.calendar}>
              <DateRange
                className={classes.dateRange}
                rangeColors={[AppTheme.colors.persianBlue_2238CB]}
                showMonthAndYearPickers={false}
                showDateDisplay={false}
                showMonthArrow={true}
                minDate={initialDate()}
                maxDate={maxDate()}
                color={'#ffffff'}
                editableDateInputs={true}
                onChange={(item) => {
                  const { endDate } = item.selection;

                  if (endDate) {
                    item.selection.endDate = DateUtils.endOfDay(endDate);
                  }
                  // @ts-ignore
                  setReservation([item.selection]);
                }}
                // @ts-ignore
                ranges={reservation}
                // Check if we should disabled rental dates or inform user about it
                disabledDates={disabledDates}
                weekdayDisplayFormat="EEEEE"
              />
            </Grid>
            <Grid className={classes.bottom}>
              <Divider />
              <Grid className={classes.button}>
                <SimpleButton
                  onClick={onClickNextButton}
                  children={state.requestFromBikeDetail ? 'Reserve' : 'Next'}
                  isLoading={requestStatus == RequestStatus.LOADING}
                  disabled={requestStatus == RequestStatus.LOADING}
                />
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
    );
  };

  return <AppLayout content={getBody} />;
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  gridContainer: {
    width: '100vw',
    backgroundColor: 'black',
  },
  gridHeader: {
    background: 'white',
    width: '100vw',
    height: '100vh',
    '@media  screen and  (orientation:landscape)': {
      height: '160vh',
    },
    borderRadius: '1.8em 1.8em 0 0',
    marginTop: '2em',
  },
  textBox: {
    marginTop: '1.5em',
    marginBottom: '1em',
  },
  calendarView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '10vw',
    marginTop: '1.5em',
    marginBottom: '1em',
  },
  calendarText: {
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontSize: '1.18em',
  },
  calendarDate: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '80vw',
    height: '3em',
    background: AppTheme.colors.white_EEEEEC,
    marginTop: '1em',
  },
  calendarDateText: {
    textAlign: 'left',
    fontFamily: AppFontFamily.SHAPE,
    fontSize: '0.81em',
    paddingLeft: '1.6em',
  },
  calendar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1em',
    marginBottom: '4em',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80vw',
    marginLeft: '10vw',
    marginTop: '1em',
  },
  bottom: {
    position: 'fixed',
    bottom: 70,
    width: '100vw',
  },
  dateRange: {
    '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
    width: '90vw',
    maxWidth: '30em',
    '& .rdrWeekDay': {
      color: AppTheme.colors.black_000000,
      fontWeight: '500',
      fontSize: '14px',
    },
    '& .rdrDayDisabled': {
      backgroundColor: AppTheme.colors.white_FFFFFF,
    },
    '& .rdrDayPassive span': {
      color: AppTheme.colors.white_FFFFFF,
    },
    '& .rdrDay span': {
      fontWeight: '400',
    },
    '& .rdrDayStartPreview.rdrDayEndPreview': {
      display: 'none',
    },
    [theme.breakpoints.down(395)]: {
      '& .rdrMonth': {
        padding: 0,
        width: 'initial',
      },
      '& .rdrPprevButton': {
        marginLeft: 0,
      },
      '& .rdrNextButton': {
        marginRight: 0,
      },
    },
  },
}));
