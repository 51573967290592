import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import Reservation from '../model/Reservation';
import GenericCrudService from '../external/fox-typescript/services/GenericCrudService';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import environment from '../environment.json';
import ReservationCreation from '../model/creation/ReservationCreation';
import { IPage } from '../external/fox-typescript/core/IPage';
import Image from '../external/fox-typescript/core/Image';
import { BikeDropOff } from '../model/utils/BikeDropOff';
import { ReservationCancellationAsHost } from '../model/update/reservation/ReservationCancellationAsHost';
import { UnavailableDatesDTO } from '../model/UnavailableDatesDTO';
import {PickUpDropOffCheckList} from "../external/pdl-common/model/PickUpDropOffCheckList";

class ReservationService extends GenericCrudService<Reservation> {
  async createReservation(
    reservationCreation: ReservationCreation
  ): Promise<ApiResponse<Reservation>> {
    return await HttpClient.post(`${this.basePath}/create-charge`, reservationCreation);
  }

  async getPublicReservation(externalId: string): Promise<ApiResponse<Reservation>> {
    return await HttpClient.get(`${this.basePath}/public/${externalId}`);
  }

  async getReservationCheckList(externalId: string): Promise<ApiResponse<PickUpDropOffCheckList>> {
    return await HttpClient.get(`${this.basePath}/drop-off-checklist/${externalId}`);
  }

  async getSemiPublicReservation(externalId: string): Promise<ApiResponse<Reservation>> {
    return await HttpClient.get(`${this.basePath}/semi-public/${externalId}`);
  }

  async getOutNowReservations(
    page: number,
    pageSize: number
  ): Promise<ApiResponse<IPage<Reservation>>> {
    return await HttpClient.get(`${this.basePath}/out-now?page=${page}&pageSize=${pageSize}`);
  }

  async getUpcomingReservationsForRenter(
    page: number,
    pageSize: number
  ): Promise<ApiResponse<PagedResult<Reservation>>> {
    return await HttpClient.get<PagedResult<Reservation>>(
      `${this.basePath}/upcoming/renter?page=${page}&pageSize=${pageSize}`
    );
  }

  async getUpcomingReservationsForHost(
    page: number,
    pageSize: number
  ): Promise<ApiResponse<PagedResult<Reservation>>> {
    return await HttpClient.get<PagedResult<Reservation>>(
      `${this.basePath}/upcoming/host?page=${page}&pageSize=${pageSize}`
    );
  }
  async getReservationHistoryForHost(
    page: number,
    pageSize: number
  ): Promise<ApiResponse<IPage<Reservation>>> {
    return await HttpClient.get<IPage<Reservation>>(
      `${this.basePath}/history/host?page=${page}&pageSize=${pageSize}`,
      true
    );
  }

  async getReservationHistoryForRenter(
    page: number,
    pageSize: number
  ): Promise<ApiResponse<IPage<Reservation>>> {
    return await HttpClient.get<IPage<Reservation>>(
      `${this.basePath}/history/renter?page=${page}&pageSize=${pageSize}`
    );
  }

  async dropOff(reservationExternalId: string, checkListExternalId: string) {
    await HttpClient.post(`/reservations/${reservationExternalId}/confirm-drop-off/${checkListExternalId}`, {});
  }

  async dropOffCheckList(externalId: string, images: Image[], checks: BikeDropOff) {
    await HttpClient.post(`/reservations/${externalId}/confirm-drop-off-host`, {
      imageExternalIds: images.map((i) => i.externalId!),
      ...checks,
    });
  }


  async pickUp(reservationExternalId: string, checkListExternalId: string) {
    await HttpClient.post(`/reservations/${reservationExternalId}/confirm-pick-up/${checkListExternalId}`, {});
  }

  async cancelPickUp(reservationExternalId: string, checkListExternalId: string) {
    await HttpClient.post(`/reservations/${reservationExternalId}/cancel-pick-up/${checkListExternalId}`, {});
  }

  async pickUpCheckList(externalId: string, images: Image[], checks: BikeDropOff) {
    await HttpClient.post(`/reservations/${externalId}/confirm-pick-up-renter`, {
      imageExternalIds: images.map((i) => i.externalId!),
      ...checks,
    });
  }

  async disablePickUp(externalId: string) {
    await HttpClient.post(`/reservations/disable-pickup/${externalId}`, {});
  }

  async cancelReservationAsRenter(externalId: string): Promise<ApiResponse<string>> {
    return await HttpClient.put(`${this.basePath}/${externalId}/cancel/as-renter`, {});
  }

  async cancelReservationAsHost(
    externalId: string,
    dto: ReservationCancellationAsHost
  ): Promise<ApiResponse<string>> {
    return await HttpClient.put(`${this.basePath}/${externalId}/cancel/as-host`, dto);
  }

  async getAllReservedDatesForBike(bikeExternalId: string): Promise<ApiResponse<string[]>> {
    return await HttpClient.get(`${this.basePath}/bike/${bikeExternalId}`, true);
  }

  async getAllUnavailableDatesForBike(bikeExternalId: string): Promise<ApiResponse<UnavailableDatesDTO>> {
    return await HttpClient.get(`${this.basePath}/bike/${bikeExternalId}/unavailable`);
  }

  async checkBikeAvailable(
    bikeExternalId: string,
    startDate: Date,
    endDate: Date
  ): Promise<ApiResponse<boolean>> {
    return await HttpClient.post(
      `${this.basePath}/bike/${bikeExternalId}/available`,
      {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
      true
    );
  }

  async getHostCancellationPenalty(externalId: string): Promise<ApiResponse<number>> {
    return await HttpClient.get(`${this.basePath}/host/cancellation-penalty/${externalId}`, true);
  }

  async getRenterRefoundAmount(externalId: string): Promise<ApiResponse<number>> {
    return await HttpClient.get(`${this.basePath}/renter/refund/${externalId}`);
  }

  async getRenterCancellationFee(
    totalPrice: number,
    bikeDailyPrice: number,
    insuranceFee: number = 0
  ): Promise<ApiResponse<number>> {
    return await HttpClient.get(
      `${this.basePath}/renter/cancellation-fee?totalPrice=${totalPrice}&bikeDailyPrice=${bikeDailyPrice}&insuranceFee=${insuranceFee}`,
      true
    );
  }

  async getTaxes(price: number): Promise<ApiResponse<number>> {
    return await HttpClient.get(`${this.basePath}/taxes?price=${price}`);
  }
}

export default new ReservationService(`${environment.baseURL}/reservations`);
