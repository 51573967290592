import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppFontFamily } from '../../utils/AppFontFamily';
import AppTheme from '../../utils/AppTheme';

interface Props {
  content: string;
  bottomElement?: JSX.Element;
  showBackButton: boolean;
  rightButton?: JSX.Element;
  minMarginTop?: boolean;
  onBack?: () => void;
}

AppHeader.defaultProps = {
  showBackButton: true,
};

export default function AppHeader({
  content,
  bottomElement,
  showBackButton,
  rightButton,
  minMarginTop,
  onBack,
}: Props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  // Render
  return (
    <Grid
      container
      className={classes.container}
      justifyContent={'center'}
      alignItems={breakpoint ? 'flex-start' : 'center'}
      direction={breakpoint || rightButton ? 'column' : 'row'}
    >
      <Box
        sx={
          rightButton
            ? {
              display: 'flex',
              alignItems: 'center',
              flexDirection: showBackButton ? 'row' : 'row-reverse',
              justifyContent: 'space-between',
              width: '100%',
              pt: 1,
            }
            : {}
        }
      >
        {showBackButton && (
          <Button
            sx={{ color: 'white' }}
            onClick={() => {
              if (onBack) {
                onBack();
              } else {
                navigate(-1);
              }
            }}
          >
            <ArrowBackIcon />
          </Button>
        )}

        {rightButton}
      </Box>

      <div
        style={{
          flexDirection: breakpoint ? 'column' : 'row',
          alignItems: breakpoint ? 'flex-start' : 'center',
          marginTop: breakpoint && minMarginTop ? '0.5em' : breakpoint && !showBackButton ? '2.5em' : 0,
        }}
        className={classes.headerContent}
      >
        <p className={classes.text}>{content}</p>
        {bottomElement}
      </div>
    </Grid >
  );
}

const useStyles = makeStyles({
  container: {
    minHeight: '6.25em',
    backgroundColor: AppTheme.colors.black_01000E,
    color: 'white',
    display: 'flex',
    zIndex: 999,
  },
  text: {
    fontFamily: AppFontFamily.SHAPE_BOLD,
    fontSize: '1.375em',
    margin: 0,
    marginLeft: '1.25em',
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
});
