import { Divider, Grid, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IAppTheme } from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { MonthlyBreakdown } from '../../model/utils/MonthlyBreakdown';
import DateUtils from '../../external/pdl-common/utils/DateUtils';

interface Props {
  data: MonthlyBreakdown[];
}

const MonthlyBreakdownComponent = ({ data }: Props) => {
  const classes = useStyles();

  return (
    <>
      {/* Title */}
      <AppTypography type={PDLTypography.largeHeading} customClasses={classes.sectionTitle}>
        Monthly breakdown
      </AppTypography>

      {data.length == 0 && (
        <AppTypography type={PDLTypography.paragraph} customStyles={{ textAlign: 'center' }}>
          Not data to display yet
        </AppTypography>
      )}

      {data.map((mb) => (
        <>
          {/* Month */}
          <AppTypography type={PDLTypography.subHeading} customClasses={classes.subheading}>
            {DateUtils.formattedDateOnlyMonthAndYear(new Date(mb.year, mb.month - 1, 1))}
          </AppTypography>

          {/* Rentals */}
          <Grid display={'flex'}>
            <Grid item xs={6} sx={muiStyles.innerGrid}>
              <AppTypography type={PDLTypography.paragraph} customClasses={classes.paragraph}>
                {mb.rentals}
              </AppTypography>

              <AppTypography type={PDLTypography.subHeadingSerif}>Rentals</AppTypography>
            </Grid>

            {/* Revenue */}
            <Grid item xs={6} sx={muiStyles.innerGrid}>
              <AppTypography type={PDLTypography.paragraph} customClasses={classes.paragraph}>
                {`$${mb.revenue.toFixed(2)}`}
              </AppTypography>

              <AppTypography type={PDLTypography.subHeadingSerif}>Revenue</AppTypography>
            </Grid>
          </Grid>

          <Divider sx={{ my: '1.25em' }} />
        </>
      ))}
    </>
  );
};

export default MonthlyBreakdownComponent;

const muiStyles = {
  innerGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  } as SxProps,
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  subheading: {
    marginBottom: theme.spacing(1),
  },
  paragraph: {
    marginBottom: theme.spacing(0.5),
  },
}));
