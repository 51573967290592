import { Box, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { COLORS } from '../../utils/AppTheme';

export const WelcomeToPdlClubAbout = () => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box
      className={classes.root}
      textAlign={md ? 'left' : 'center'}
      padding={md ? '5em 0 3em 0' : sm ? '3em 0 1.5em 0' : '1.5em 1.7em 0em 1.7em'}
    >
      <AppTypography
        type={PDLTypography.largeHeading}
        customStyles={{
          color: COLORS.white_FFFFFF,
          fontSize: md ? '5em' : sm ? '3.3em' : '1.6em',
        }}
      >
        Our Mission
      </AppTypography>
      <AppTypography
        type={PDLTypography.paragraph}
        customStyles={{
          color: COLORS.white_FFFFFF,
          marginTop: '.5em',
          fontSize: md ? '1em' : '.9em',
        }}
      >
        Enhance and provide the global cycling community with a tool to “keep riding” {sm && <br/>}
        wherever you are in the world. pdl club aims to serve as a peer to peer network {sm && <br/>}
        connecting passionate cyclists around the globe with quality performance {sm && <br/>}
        bicycles, offering them the same experience they are accustomed to with their {sm && <br/>}
        own bike at home.
      </AppTypography>
      <AppTypography
        type={PDLTypography.largeHeading}
        customStyles={{
          color: COLORS.white_FFFFFF,
          fontSize: md ? '5em' : sm ? '3.3em' : '1.6em',
          marginTop: '1em'
        }}
      >
        What is PDL CLUB?
      </AppTypography>
      <AppTypography
        type={PDLTypography.paragraph}
        customStyles={{
          color: COLORS.white_FFFFFF,
          marginTop: '.5em',
          fontSize: md ? '1em' : '.9em',
        }}
      >
        A community of cyclists looking for a higher standard in the bicycle rental {sm && <br/>}
        experience. Whether you are looking for an upgrade to your current bike or just {sm && <br/>}
        want one similar to your own, pdl club will match you with a like minded {sm && <br/>}
        passionate cyclist willing to trust others with their prized possessions! <br/>
        Future features will include guided rides and personalized training sessions.
      </AppTypography>
      <AppTypography
        type={PDLTypography.largeHeading}
        customStyles={{
          color: COLORS.white_FFFFFF,
          fontSize: md ? '5em' : sm ? '3.3em' : '1.6em',
          marginTop: '1em'
        }}
      >
        How does PDL CLUB work? 
      </AppTypography>
      <AppTypography
        type={PDLTypography.paragraph}
        customStyles={{
          color: COLORS.white_FFFFFF,
          marginTop: '.5em',
          fontSize: md ? '1em' : '.9em',
        }}
      >
        To enter the pdl club community you either need to get an invitation code from {sm && <br/>}
        a current active member or join the waitlist. In both scenarios you will be {sm && <br/>}
        requested to enter your personal information and then, after an easy and quick {sm && <br/>}
        process you will be welcomed as a PDL CLUB member.
        <br/>
        <br/>
        Once you become a PDL CLUB member you can start either listing your {sm && <br/>}
        bikes or renting one from another community member around the {sm && <br/>}
        world (we are starting with our home city of miami). {sm && <br/>}
        Any community member can act as both a host and a renter.
        <br/>
        <br/>
        As a controlled community we offer a high standard of security {sm && <br/>}
        through identity verification and a detailed description of every {sm && <br/>}
        bicycle uploaded onto the platform. <br/>
        Before a bicycle is activated in the pdl club plattform, it must meet certain {sm && <br/>}
        quality requirements and needs to be approved by our pdl club support team.
        <br/>
        <br/>
        What if something happens to your bike? rest easy, you're covered {sm && <br/>}
        with our protection plan of up to $3000 in damages.
        <br/>
        <br/>
        Building trust between our members is key to a successful and {sm && <br/>}
        enjoyable transaction!
        <br/>
        <br/>
        <AppTypography 
            type={PDLTypography.paragraph}
            onClick={() => appNavigate(navigate, AppRoutes.INVITE_CODE, null)} 
            customStyles={{ cursor: 'pointer', zIndex: 99, color: COLORS.persianBlue_2238CB}}
        >
            Sing up! 
        </AppTypography> and keep riding!
        </AppTypography>
    </Box>
    
  );
};

const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.black_000000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    heigth: '10em',
  },
});
