import { Avatar, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppImage from './commons/AppImage';
import { AppTypography, AppTypographyProps } from './Typography/AppTypography';
import { PDLTypography } from './Typography/PDLTypography';

interface Props {
  src: string;
  alt?: string;
  title: string;
  subtitle?: string;
  titleTypography?: AppTypographyProps;
  subtitleTypography?: AppTypographyProps;
}

const RoundedImageWithText = ({
  src,
  alt,
  title,
  subtitle,
  titleTypography = {
    type: PDLTypography.labelsSmallSerif,
  },
  subtitleTypography = {
    type: PDLTypography.smallHeading,
  },
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Avatar sx={muiStyles.profileImage}>
        <AppImage 
            src={src} alt={title}
            style={{ width: '3.125em', height: '3.125em', objectFit: 'cover' }}
        />
      </Avatar>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <AppTypography {...titleTypography}>{title}</AppTypography>
        {subtitle && <AppTypography {...subtitleTypography}>{subtitle}</AppTypography>}
      </div>
    </div>
  );
};

export default RoundedImageWithText;

const muiStyles = {
  profileImage: {
    width: '3.125em',
    height: '3.125em',
    marginRight: '1.25em',
  } as SxProps,
};

const useStyles = makeStyles({
  title: {
    fontSize: '1em',
    fontFamily: 'Opens Sans Bold',
  },
  subtitle: {
    fontSize: '0.813em',
    fontFamily: 'Opens Sans Light',
    color: 'grey',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.3em',
  },
  mpZero: {
    margin: 0,
    padding: 0,
  },
});
