import { CircularProgress, Divider } from '@mui/material';
import BikeRatingComment from '../../../../external/pdl-common/components/commons/Ratings/BikeRatingComment';
import { RenterReview } from '../../../../model/RenterReview';
import { COLORS } from '../../../../utils/AppTheme';
import { RequestStatus } from '../../../../utils/RequestStatus';
import RequestStatusFeedBack from '../../../commons/RequestStatusFeedback';
import { AppTypography } from '../../../Typography/AppTypography';
import { PDLTypography } from '../../../Typography/PDLTypography';

interface Props {
  title: string;
  requestRenterReviewStatus: RequestStatus;
  renterReview?: RenterReview;
  onRetry: () => void;
}

export function RenterReviewSection({
  requestRenterReviewStatus,
  renterReview,
  onRetry,
  title,
}: Props) {
  if (requestRenterReviewStatus == RequestStatus.LOADING) {
    return (
      <div style={{ color: COLORS.persianBlue_2238CB, textAlign: 'center' }}>
        <CircularProgress color="inherit" size={25} />
      </div>
    );
  }

  if (requestRenterReviewStatus == RequestStatus.ERROR) {
    return (
      <>
        <AppTypography type={PDLTypography.mediumHeading}>{title}</AppTypography>
        <RequestStatusFeedBack status={requestRenterReviewStatus} onRetry={onRetry} />
      </>
    );
  }

  if (!renterReview) {
    return <></>;
  }

  return (
    <>
      <AppTypography type={PDLTypography.mediumHeading}>{title}</AppTypography>
      <div style={{ marginTop: '1em' }}>
        <BikeRatingComment showHeader={false} showDivider={false} rating={renterReview} />
      </div>

      <Divider sx={{ mt: '1.5em', mb: '1.5em' }} />
    </>
  );
}
