import Image from '../../../external/fox-typescript/core/Image';

export default class BikeSecondStepCreation {
  public title: string;
  public description: string;
  public serialNumber: string;
  public listPrice: number = 0;
  public customPrice: number = 0;
  public marketPrice: number = 0;
  public make: string;
  public customMake: string = '';
  public type: string;
  public model: string;
  public frameMaterial: string;
  public year: number;
  public color: string;
  public customColor: string = '';
  public condition: string;
  public customCondition: string = '';
  public size: string;
  public topSaddleHeight: number;
  public bottomSaddleHeight: number;
  public images: Image[] = [];

  constructor(
    title: string,
    description: string,
    serialNumber: string,
    make: string,
    customMake: string,
    type: string,
    model: string,
    frameMaterial: string,
    year: number,
    color: string,
    customColor: string,
    condition: string,
    customCondition: string,
    size: string,
    topSaddleHeight: number,
    bottomSaddleHeight: number
  ) {
    this.title = title;
    this.description = description;
    this.serialNumber = serialNumber;
    this.make = make;
    this.customMake = customMake;
    this.type = type;
    this.model = model;
    this.frameMaterial = frameMaterial;
    this.year = year;
    this.color = color;
    this.customColor = customColor;
    this.condition = condition;
    this.customCondition = customCondition;
    this.size = size;
    this.topSaddleHeight = topSaddleHeight;
    this.bottomSaddleHeight = bottomSaddleHeight;
  }
}
