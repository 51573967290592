import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import PagedResult from '../external/fox-typescript/core/PagedResult';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import { FrequentQuestion } from '../model/FrequentQuestion';

class HelpCenterService {
  private baseUrl: string = '/faqs';

  async getAllFrequentQuestions(
    page?: number,
    pageSize?: number,
    keyword?: string
  ): Promise<ApiResponse<PagedResult<FrequentQuestion>>> {
    return await HttpClient.get<PagedResult<FrequentQuestion>>(
      `${this.baseUrl}/all?page=${page}&pageSize=${pageSize}&keyword=${keyword}`,
      true
    );
  }

  async getFrequentQuestionById(externalId: string): Promise<ApiResponse<FrequentQuestion>> {
    return await HttpClient.get(`${this.baseUrl}/${externalId}`, true);
  }
}

export default new HelpCenterService();
