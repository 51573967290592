import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { COLORS, IAppTheme } from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import AppImage from '../../external/pdl-common/components/commons/AppImage';

interface Props {
  title: string;
  imageUrl: string;
  cells: { label: string; value: string }[];
}

export default function ListingLink(props: Props) {
  const classes = useStyles();
  const [imageHeight, setImageHeight] = useState(0);
  const ref: React.LegacyRef<HTMLDivElement> | null = useRef(null);

  const { title, cells, imageUrl } = props;

  useEffect(() => {
    setImageHeight(ref?.current?.offsetHeight || 0);
  }, [ref]);

  return (
    <div className={classes.mainContainer}>
      <AppImage
        src={imageUrl}
        style={{
          height: imageHeight,
          width: imageHeight,
        }}
        className={classes.image}
      />
      <div style={{ width: `calc(100% - ${imageHeight}px)` }} ref={ref}>
        <div className={classes.subContainer}>
          <div className={classes.titleContainer}>
            <AppTypography
              type={PDLTypography.smallHeading}
              customStyles={{ whiteSpace: 'nowrap' }}
            >
              {title}
            </AppTypography>
          </div>

          <div className={classes.cellContainer}>
            {cells.map((c) => (
              <div className={classes.cell} key={c.label}>
                <AppTypography type={PDLTypography.labelsSmallSerif}>{c.label}</AppTypography>
                <AppTypography type={PDLTypography.smallParagraph}>{c.value}</AppTypography>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  mainContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: 'auto',
  },
  image: {
    objectFit: 'contain',
    backgroundColor: COLORS.black_000000,
    borderRadius: 4,
  },
  subContainer: {
    marginLeft: '1em',
    paddingBottom: '1em',
  },
  titleContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '0.5em',
  },
  cellContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1em',
    marginTop: '0.5em',
  },
}));
