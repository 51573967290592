import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  BottomNavigationActionProps,
  Paper,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppAssets } from '../../assets';
import Logger from '../../external/pdl-common/utils/Logger';
import useInterval from '../../hooks/useInterval';
import InboxService from '../../services/InboxService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import AppTheme from '../../utils/AppTheme';

interface Props { }

const logger = new Logger('Navbar');

const REFRESH_TIME = 30 * 1000; // 30 seconds when I'm out of inbox
const INBOX_REFRESH_TIME = 10 * 1000; // 10 seconds when I'm in Inbox

export default function Navbar(props: Props) {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false);

  const currentLocation = useLocation();

  useEffect(() => {
    if (currentLocation.pathname.startsWith(AppRoutes.EXPLORE)) {
      setIndex(0);
    } else if (currentLocation.pathname.startsWith(AppRoutes.TRIPS)) {
      setIndex(1);
    } else if (currentLocation.pathname.startsWith(AppRoutes.INBOX)) {
      setIndex(2);
    } else if (currentLocation.pathname.startsWith(AppRoutes.HOST)) {
      setIndex(3);
    } else if (currentLocation.pathname.startsWith(AppRoutes.ACCOUNT)) {
      setIndex(4);
    }
  }, [currentLocation]);

  useEffect(() => {
    getHasUnreadMessages();
  }, [])

  useInterval(() => {
    if (!hasUnreadMessages) {
      getHasUnreadMessages();
    }
  }, currentLocation.pathname.startsWith(AppRoutes.INBOX) ? INBOX_REFRESH_TIME : REFRESH_TIME);

  const getHasUnreadMessages = async () => {
    (await InboxService.getUnreadCount())
      .onSuccess((response) => {
        setHasUnreadMessages(response.getContent());
      })
      .onError((response) => {
        logger.error('Error checking for unread messages', response.getContent());
      });
  }

  const StyledNavigationAction = (props: BottomNavigationActionProps) => (
    <BottomNavigationAction
      sx={{
        '&.Mui-selected': {
          color: AppTheme.colors.persianBlue_2238CB,
          borderTopWidth: 3,
          borderStyle: 'solid',
          bottom: 1,
          '& svg': {
            '& path': {
              stroke: AppTheme.colors.persianBlue_2238CB,
            },
          },
        },
        minWidth: '0em',
      }}
      {...props}
    />
  );

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }} elevation={3}>
      <BottomNavigation
        sx={{
          justifyContent: 'space-around',
          height: 59,
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: AppTheme.colors.gray_E3E5E4,
        }}
        showLabels
        value={index}
        onChange={(event, newValue) => {
          setIndex(newValue);
        }}
      >
        <StyledNavigationAction
          label="Explore"
          icon={<AppAssets.Search />}
          onClick={() => appNavigate(navigate, AppRoutes.EXPLORE, null)}
        />
        <StyledNavigationAction
          label="Rides"
          icon={<AppAssets.Map />}
          onClick={() => appNavigate(navigate, AppRoutes.TRIPS, null)}
        />
        <StyledNavigationAction
          label="Inbox"
          icon={
            <Badge
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: AppTheme.colors.persianBlue_2238CB
                }
              }}
              variant='dot' invisible={!hasUnreadMessages}>
              <AppAssets.Inbox />
            </Badge>
          }
          onClick={() => appNavigate(navigate, AppRoutes.INBOX, null)}
        />
        <StyledNavigationAction
          label="Host"
          icon={<AppAssets.Bike />}
          onClick={() => appNavigate(navigate, AppRoutes.HOST, null)}
        />
        <StyledNavigationAction
          label="Account"
          icon={<AppAssets.Account />}
          onClick={() => appNavigate(navigate, AppRoutes.ACCOUNT, null)}
        />
      </BottomNavigation>
    </Paper>
  );
}
