import { Box, Rating, Typography } from '@mui/material';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { SxStyles } from '../../model/utils/SxStyles';
import AppTheme from '../../utils/AppTheme';

interface Props {
  rating: number | string;
  totalRides?: number | string;
}

export const BikeRating = (props: Props) => {
  return (
    <Box sx={styles.rating}>
      <Typography fontFamily={AppFontFamily.INTER} fontSize={'0.9em'}>
        {props.rating}
      </Typography>
      <Rating sx={styles.ratingStar} defaultValue={1} max={1} />
      <Typography fontFamily={AppFontFamily.INTER} fontSize={'0.9em'}>
        {`(${props.totalRides} rides)`}
      </Typography>
    </Box>
  );
};

const styles: SxStyles = {
  rating: {
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.INTER,
    fontSize: '0.9em',
    display: 'flex',
  },
  ratingStar: {
    color: AppTheme.colors.persianBlue_2238CB,
    fontSize: '1.25em',
    alignSelf: 'flex-start',
  },
};
