import { Divider, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import AppHeader from '../commons/AppHeader';
import AppSelect from '../commons/AppSelect';
import { AppTextField } from '../commons/AppTextField';
import SimpleButton from '../commons/SimpleButton';
import FlexibilityTableBottomSheet from './FlexibilityTableBottomSheet';
import { AppLayout } from '../core/AppLayout';
import { AppTypography, AppTypographyProps } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { useLocation } from 'react-router-dom';
import { FullUser } from '../../external/pdl-common/model/FullUser';
import MeasurementsService from '../../services/MeasurementsService';
import { ShoeSize } from '../../model/measurements/ShoeSize';
import { Flexibility } from '../../model/measurements/Flexibility';
import Logger from '../../external/pdl-common/utils/Logger';
import FormContainer from '../commons/FormContainer';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import { useForm } from '../../hooks/useForm';
import UserService from '../../services/UserService';
import AlertService from '../../services/AlertService';
import DiagramBottomSheet from './DiagramBottomSheet';

const logger = new Logger('MyMeasurements');

export interface MyMeasurementsForm {
  height: number | string;
  sternumNotch: number | string;
  inseamLength: number | string;
  armLength: number | string;
  flexibility: number | string;
  weight: number | string;
  shoeSize: number | string;
  shoeSizeType: number | string;
}

const MyMeasurements = () => {
  const classes = useStyles();
  const location = useLocation().state as any;
  const user = location?.user as FullUser | undefined;

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [submitStatus, setSubmitStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [shoeSizeTypes, setShoeSizeTypes] = useState<ShoeSize[]>([]);
  const [flexibilityTypes, setFlexibilityTypes] = useState<Flexibility[]>([]);
  const [form, validations, handleCustomChange, formIsValid] = useForm<MyMeasurementsForm>({
    height: user?.height || '',
    sternumNotch: user?.sternumNotch || '',
    inseamLength: user?.inseamLength || '',
    armLength: user?.armLength || '',
    flexibility: user?.flexibility || '-1',
    weight: user?.weight || '',
    shoeSize: user?.shoeSize || '',
    shoeSizeType: user?.shoeSizeType || '-1',
  });

  const [showFlexibilitySheet, setShowFlexibilitySheet] = useState<boolean>(false);
  const [showDiagramSheet, setShowDiagramSheet] = useState<boolean>(false);
  const [option, setOption] = useState<number>(-1);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const responses = await Promise.all([
        MeasurementsService.getShoeSizeTypes(),
        MeasurementsService.getFlexibilityTypes(),
      ]);

      setShoeSizeTypes(responses[0].getContent());
      setFlexibilityTypes(responses[1].getContent());

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (e: any) {
      logger.error('Error fetching measurement types', e);
      if (e?.response?.data?.message) {
        AlertService.showSnackCustomError(e?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  const onSubmit = async () => {
    try {
      setSubmitStatus(RequestStatus.LOADING);

      await UserService.updateMeasurements(form);

      AlertService.showSuccessMessage('Measurements updated successfully');
    } catch (error: any) {
      logger.error('Error updating measurements', error);

      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }
    } finally {
      setSubmitStatus(RequestStatus.SUCCESS);
    }
  };

  const openDiagram = (option: number) => {
    setOption(option);
    setShowDiagramSheet(true);
  };

  const getContent = (): JSX.Element => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return <RequestStatusFeedBack status={requestStatus} onRetry={load} />;
    }

    return (
      <>
        <FormContainer
          BottomElement={
            <SimpleButton
              children={'Update Measurements'}
              disabled={submitStatus === RequestStatus.LOADING || !formIsValid()}
              onClick={onSubmit}
              isLoading={submitStatus === RequestStatus.LOADING}
            />
          }
        >
          <div className={classes.headingContainer}>
            <AppTypography type={PDLTypography.mediumHeading} children={'Add your measurements'} />
          </div>
          <AppTypography
            type={PDLTypography.paragraph}
            children={'Your measurement help us to find the best bike recommendations for you.'}
          />
          <Divider sx={{ mt: 4 }} />
          <AppTextField
            name={'height'}
            label={'Height (In inches)'}
            value={form.height.toString()}
            labelProps={labelProps}
            onInputChange={handleCustomChange}
            containerClasses={classes.textFieldContainer}
            helperText={'Measure the distance from the floor to the top of the head.'}
            realTimeValidation
            required
            restrict={'onlyNumbersAndDots'}
            bottomRightElement={
              <AppTypography
                type={PDLTypography.smallParagraph}
                children={'View Height guide'}
                customStyles={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textAlign: 'right',
                }}
                onClick={() => openDiagram(0)}
              />
            }
          />
          <AppTextField
            name={'sternumNotch'}
            label={'Sternum Notch (In inches)'}
            value={form.sternumNotch.toString()}
            labelProps={labelProps}
            onInputChange={handleCustomChange}
            containerClasses={classes.textFieldContainer}
            helperText={
              'The sternal notch is the bony "V" or dip between the neck and collarbones. Measure the distance from the floor to the apex of the sternal notch.'
            }
            realTimeValidation
            required
            restrict={'onlyNumbersAndDots'}
            bottomRightElement={
              <AppTypography
                type={PDLTypography.smallParagraph}
                children={'View Sternum Notch Guide'}
                customStyles={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textAlign: 'right',
                }}
                onClick={() => openDiagram(1)}
              />
            }
          />

          <AppTextField
            name={'inseamLength'}
            label={'Inseam Length (In inches)'}
            value={form.inseamLength.toString()}
            labelProps={labelProps}
            onInputChange={handleCustomChange}
            containerClasses={classes.textFieldContainer}
            helperText={
              'Measure the distance from the floor to the uppermost part of the thigh or crotch.'
            }
            realTimeValidation
            required
            restrict={'onlyNumbersAndDots'}
            bottomRightElement={
              <AppTypography
                type={PDLTypography.smallParagraph}
                children={'View Inseam Length Guide'}
                customStyles={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textAlign: 'right',
                }}
                onClick={() => openDiagram(2)}
              />
            }
          />

          <AppTextField
            name={'armLength'}
            label={'Arm Length (In inches)'}
            value={form.armLength.toString()}
            labelProps={labelProps}
            onInputChange={handleCustomChange}
            containerClasses={classes.textFieldContainer}
            helperText={'Measure the distance from the shoulder to just past the wrist bone.'}
            realTimeValidation
            required
            restrict={'onlyNumbersAndDots'}
            bottomRightElement={
              <AppTypography
                type={PDLTypography.smallParagraph}
                children={'View Arm Length Guide'}
                customStyles={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textAlign: 'right',
                }}
                onClick={() => openDiagram(3)}
              />
            }
          />

          <div className={classes.selectContainer}>
            <AppSelect
              props={{
                name: 'flexibility',
                label: 'Flexibility',
                value: form.flexibility,
                onChange: (event) => {
                  const { name, value } = event.target;
                  handleCustomChange(name, value as string, !!value && value !== '-1');
                },
              }}
              bottomRightElement={
                <AppTypography
                  type={PDLTypography.smallParagraph}
                  children={'View Flexibility guide'}
                  customStyles={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    textAlign: 'right',
                  }}
                  onClick={() => setShowFlexibilitySheet(true)}
                />
              }
            >
              {flexibilityTypes.map((flexibilityType) => (
                <MenuItem value={flexibilityType.value}>
                  {flexibilityType.value} - {flexibilityType.description}
                </MenuItem>
              ))}
            </AppSelect>
          </div>

          <AppTextField
            name={'weight'}
            label={'Weight (In pounds)'}
            value={form.weight.toString()}
            labelProps={labelProps}
            onInputChange={handleCustomChange}
            containerClasses={classes.textFieldContainer}
            realTimeValidation
            required
            restrict={'onlyNumbersAndDots'}
          />

          <AppTextField
            name={'shoeSize'}
            label={'Shoe size'}
            value={form.shoeSize.toString()}
            labelProps={labelProps}
            onInputChange={handleCustomChange}
            containerClasses={classes.textFieldContainer}
            realTimeValidation
            required
            restrict={'onlyNumbersAndDots'}
          />

          <div className={classes.selectContainer}>
            <AppSelect
              props={{
                name: 'shoeSizeType',
                label: 'Shoe size type',
                value: form.shoeSizeType,
                onChange: (event) => {
                  const { name, value } = event.target;
                  handleCustomChange(name, value as string, !!value && value !== '-1');
                },
              }}
            >
              {shoeSizeTypes.map((shoeSize) => (
                <MenuItem value={shoeSize.value}>{shoeSize.description}</MenuItem>
              ))}
            </AppSelect>
          </div>
        </FormContainer>

        <FlexibilityTableBottomSheet
          open={showFlexibilitySheet}
          onClose={() => setShowFlexibilitySheet(false)}
          flexibilityTypes={flexibilityTypes}
        />

        <DiagramBottomSheet
          open={showDiagramSheet}
          onClose={() => setShowDiagramSheet(false)}
          reset={setOption}
          option={option}
        />
      </>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content={'My measurements'} />}
      content={() => getContent()}
    />
  );
};
export default MyMeasurements;

const labelProps: AppTypographyProps = {
  type: PDLTypography.subHeading,
  customStyles: {
    marginBottom: '0.7em',
    marginTop: '0.7em',
  },
};

const useStyles = makeStyles({
  headingContainer: {
    marginTop: '2em',
    marginBottom: '1em',
  },
  textFieldContainer: {
    marginTop: '0.625em',
    display: 'flex',
    flexDirection: 'column',
  },
  selectContainer: {
    marginTop: '1em',
  },
});
