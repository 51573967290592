import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Reservation from '../../model/Reservation';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { IAppTheme } from '../../utils/AppTheme';
import { RequestStatus } from '../../utils/RequestStatus';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import BottomSheet from './BottomSheet';
import SimpleButton from './SimpleButton';

interface Props {
  open: boolean;
  onClose: () => void;
  refundAmount: number;
  cancelationFee?: number;
  onCancelReservation: () => void;
  submitStatus?: RequestStatus;
  reservation?: Reservation;
  fromHost?: boolean;
}

export function CancelReservationBottomSheet(props: Props) {
  const classes = useStyles();
  const [reservation, setReservation] = useState<Reservation | undefined>(props.reservation);

  const getCancellationMessage = (): string => {
    return `If you cancel your trip now you will receive ${
      props.cancelationFee !== 0
        ? ' a partial refund of'
        : props.refundAmount === 0
        ? ' no refund'
        : ' a full refund of'
    }`;
  };

  const navigate = useNavigate();

  const onReportIssue = () => {
    appNavigate(navigate, AppRoutes.DISABLE_PICKUP_ISSUE, null, { state: reservation})
  }

  return (
    <BottomSheet open={props.open} onClose={props.onClose} bottomSpace="5em">
      <div className={classes.container}>
        <AppTypography type={PDLTypography.largeHeading}>
          Are you sure you want to cancel your reservation?
        </AppTypography>
        {props.fromHost ?
        <></>
        :
        <>
          <AppTypography type={PDLTypography.paragraph} customClasses={classes.marginTop}>
            {getCancellationMessage()}
          </AppTypography>
          {props.refundAmount !== 0 && (
            <AppTypography type={PDLTypography.smallHeading} customClasses={classes.marginTop}>
              ${props.refundAmount.toFixed(2)}
            </AppTypography>
          )}

          {props.cancelationFee !== 0 && (
            <AppTypography type={PDLTypography.paragraph} customClasses={classes.marginTop}>
              This is the total reservation cost minus the ${props.cancelationFee} cancellation fee.
            </AppTypography>
          )}
        </>
        }
        <div className={classes.buttonContainer}>
          <SimpleButton
            onClick={props.onCancelReservation}
            isLoading={props.submitStatus === RequestStatus.LOADING}
            disabled={props.submitStatus === RequestStatus.LOADING}
          >
            Yes, Cancel My Reservation
          </SimpleButton>
        </div>
        {(props.cancelationFee !== 0 || props.refundAmount === 0) &&
        (
        <AppTypography type={PDLTypography.paragraph} customClasses={classes.marginTop}>
          If your cancelation is related to a problem with the {props.fromHost ? "Renter" : "Host or bike rented"}, please let us know more. Contact us
        <AppTypography type={PDLTypography.callToAction} customClasses={classes.marginTop} customStyles={{ cursor: 'pointer'}} onClick={onReportIssue}>
          &nbsp;HERE
        </AppTypography>
        </AppTypography>
        )
        }
      </div>
    </BottomSheet>
  );
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '1em',
  },
  buttonContainer: {
    margint: '0px auto',
    marginTop: '2em',
  },
}));
