import { Box, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import Image from '../../external/fox-typescript/core/Image';
import { Bike } from '../../external/pdl-common/model/Bike';
import { BikePartType } from '../../model/BikePartType';
import BikePartCreation from '../../model/creation/BikePartCreation';
import { IAppTheme } from '../../utils/AppTheme';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import { AlertDialog } from '../commons/AlertDialog';
import { AppTextField } from '../commons/AppTextField';
import BottomSheet from '../commons/BottomSheet';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import SimpleButton from '../commons/SimpleButton';
import { UploadImage } from '../commons/UploadImage';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { PartDetail } from '../../external/pdl-common/model/PartDetail';
import { v4 as uuidv4 } from 'uuid';
import AppSelect from '../commons/AppSelect';
import AlertService from '../../services/AlertService';
import FormOptionsService from '../../services/FormOptionsService';
import StringUtils from '../../utils/StringUtils';
import { PartDetailForm } from '../../model/parts/PartDetailForm';
import { PartToEditInfo } from '../../model/parts/PartToEditInfo';
import { Field } from '../../model/parts/Field';

const logger = new Logger('BikePartDetailForm');

interface Props {
  open: boolean;
  onClose: (name: string, partDetail?: PartDetailForm) => void;
  partToEditInfo?: PartToEditInfo;
  bike: Bike;
  editMode?: boolean;
  bikePartsToUpdate: PartDetail[];
  setBikePartsToUpdate: (bikeParts: PartDetail[]) => void;
  bikePartsToCreate: BikePartCreation[];
  setBikePartsToCreate: (bikeParts: BikePartCreation[]) => void;
  setIsChanged?: (isChanged: boolean) => void;
}

const useStyles = makeStyles((theme: IAppTheme) => ({
  inputLabel: {
    marginTop: '1.5em',
  },
  button: {
    marginTop: '2em',
  },
}));

const dummyImage = (): Image => {
  return {
    externalId: uuidv4(),
    url: '',
    width: 0,
    height: 0,
    type: '',
    description: '',
  };
};

export default function BikePartDetailForm(props: Props) {
  const classes = useStyles();
  const [imagesExternalIds, setImagesExternalIds] = useState<string[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.SUCCESS);
  const [form, setForm] = useState<Partial<PartDetailForm>>({
    pictureUrl: undefined,
    pictureFile: undefined,
    brand: undefined,
    component: undefined,
    size: undefined,
    category: undefined,
    measurement: undefined,
    material: undefined,
    additionalInfo: undefined,
    additionalCategory: undefined
  });
  const [requiredFields, setRequieredFields] = useState<Partial<PartDetailForm>>({});
  const [changesMade, setChangesMade] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [images, setImages] = useState<Image[]>([dummyImage()]);
  const [dropDownOptions, setDropDownOptions] = useState<string[]>([]);
  const [additionalDropDownOptions, setAdditionalDropDownOptions] = useState<string[]>([]);

  useEffect(() => {
    loadDropDownOptions();
  }, []);

  useEffect(() => {
    loadExtraDropDownOptions();
  }, []);

  useEffect(() => {
    const updatedRequieredFields = { ...requiredFields };

    if (props.partToEditInfo?.part) {
      props
        .partToEditInfo!.fields!.filter((field) => field.required)
        .forEach(
          (field) =>
          ((updatedRequieredFields as any)[field.name] = (props.partToEditInfo?.part as any)[
            field.name
          ])
        );
    } else {
      props
        .partToEditInfo!.fields!.filter((field) => field.required)
        .forEach((field) => ((updatedRequieredFields as any)[field.name] = ''));

      if (props.partToEditInfo?.dropdown?.required) {
        updatedRequieredFields.category = '';
      }

      if (props.partToEditInfo?.additionalDropdown?.required) {
        updatedRequieredFields.additionalCategory = '';
      }
    }

    setRequieredFields(updatedRequieredFields);
  }, []);

  useEffect(() => {
    if (props.open) {
      if (props.partToEditInfo?.part) {
        setForm({
          pictureUrl: props.partToEditInfo.part.pictureUrl,
          pictureFile: props.partToEditInfo.part.pictureFile,
          brand: props.partToEditInfo.part.brand,
          component: props.partToEditInfo.part.component,
          measurement: props.partToEditInfo.part.measurement,
          size: props.partToEditInfo.part.size,
          model: props.partToEditInfo.part.model,
          material: props.partToEditInfo.part.material,
          category: props.partToEditInfo.part.category,
          additionalCategory: props.partToEditInfo.part.additionalCategory,
          additionalInfo: props.partToEditInfo.part.additionalInfo,
        });

        setImagesExternalIds(props.partToEditInfo.part.images.map((image) => image.externalId!));
        setImages([...props.partToEditInfo.part.images, ...images]);
      }
    } else {
      clearForm();
    }
  }, [props.open]);

  const mandatoryUploadFilesParts = [BikePartType.wheelset, BikePartType.powerMeter];

  const loadDropDownOptions = async () => {
    if (props.partToEditInfo?.dropdown) {
      (await FormOptionsService.getBikePartCategories(props.partToEditInfo.dropdown.name))
        .onSuccess((response) => {
          setDropDownOptions(response.getContent());
        })
        .onError((response) => {
          logger.error('Failed to load dropdown option', response.getContent());
          AlertService.showSnackCustomError(response.getContent());
        });
    }
  };

  const loadExtraDropDownOptions = async () => {
    if (props.partToEditInfo?.additionalDropdown) {
      (await FormOptionsService.getBikePartCategories(`${props.partToEditInfo.name}/${props.partToEditInfo.additionalDropdown.name}`))
        .onSuccess((response) => {
          setAdditionalDropDownOptions(response.getContent());
        })
        .onError((response) => {
          logger.error('Failed to load extra dropdown option', response.getContent());
          AlertService.showSnackCustomError(response.getContent());
        });
    }
  };

  const clearForm = () => {
    setForm({
      pictureUrl: undefined,
      pictureFile: undefined,
      brand: undefined,
      component: undefined,
      size: undefined,
      category: undefined,
      additionalCategory: undefined,
      material: undefined,
      measurement: undefined,
      additionalInfo: undefined,
    });
    setChangesMade(false);
  };

  const onImageUploaded = (image: Image) => {
    // set external id to bike details
    !changesMade && setChangesMade(true);
    const imagesExternalIdsUpdated = [...imagesExternalIds];
    imagesExternalIdsUpdated.push(image.externalId!);
    setImagesExternalIds(imagesExternalIdsUpdated);
  };

  const addToListing = async () => {
    const bikePartCreation = new BikePartCreation(
      props.partToEditInfo?.name || BikePartType.brake,
      form.brand || '',
      form.component || '',
      form.model || '',
      form.size || '',
      props.bike.externalId || '',
      form.material || '',
      form.category || '',
      form.additionalCategory || '',
      form.measurement || 0,
      form.additionalInfo || ''
    );

    bikePartCreation.imagesExternalIds = [...imagesExternalIds];

    let bikePart: PartDetail = {
      type: props.partToEditInfo?.name || BikePartType.brake,
      brand: form.brand || '',
      component: form.component || '',
      size: form.size || '',
      model: form.model || '',
      externalId: props.partToEditInfo?.part?.externalId || '',
      images: images.filter((i) => !!i.url),
      material: form.material,
      measurement: form.measurement,
      category: form.category,
      additionalCategory: form.additionalCategory,
      additionalInfo: form.additionalInfo,
    };

    try {
      if (props.editMode) {
        let updatedParts = props.bikePartsToUpdate;

        if (updatedParts) {
          const bikePartExists = updatedParts.find((p) => p.type === bikePart.type);

          if (bikePartExists) {
            updatedParts = updatedParts.map((p) => (p.type === bikePart.type ? bikePart : p));
          } else {
            updatedParts.push(bikePart);
          }
        } else {
          updatedParts = [];
          updatedParts.push(bikePart);
        }
        if (formChanged() && props.setIsChanged != undefined) {
          props.setIsChanged(true);
        }

        props.setBikePartsToUpdate(updatedParts);
      } else {
        let partsToCreate = props.bikePartsToCreate;

        if (partsToCreate) {
          const bikePartExists = partsToCreate.find((p) => p.type === bikePart.type);
          if (bikePartExists) {
            partsToCreate = partsToCreate.map((p) =>
              p.type === bikePartCreation.type ? bikePartCreation : p
            );
          } else {
            partsToCreate.push(bikePartCreation);
          }
        } else {
          partsToCreate = [];
          partsToCreate.push(bikePartCreation);
        }

        props.setBikePartsToCreate(partsToCreate);
      }

      if (formIsValid()) {
        props.onClose(props.partToEditInfo!.name, bikePart);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        logger.error(error?.response?.data?.message, error);
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        const err = 'Failed to update bike parts';

        logger.error(err, error);
        AlertService.showSnackCustomError(err);
      }
    }
  };

  const getFieldLabel = (field: Field) => {
    return `${field.label ? field.label : StringUtils.capitalizeText(field.name)} ${field.required ? '*' : ''
      }`;
  };

  const uploadImageLabel = (text: string) => {
    return `${text} ${mandatoryUploadFilesParts?.includes(props.partToEditInfo?.name || BikePartType.brake)
      ? '*'
      : ''
      }`;
  };

  const handleOnClose = () => {
    if (formChanged()) {
      setOpenAlertDialog(true);
      return;
    }
    props.onClose(props.partToEditInfo!.name);
  };

  const handleCustomChange = (inputName: string, inputValue: string | File | undefined) => {
    !changesMade && setChangesMade(true);
    let updatedForm = { ...form };
    let updatedRequiredFileds = { ...requiredFields };

    (updatedForm as any)[inputName] = inputValue;

    if (Object.keys(requiredFields).includes(inputName)) {
      (updatedRequiredFileds as any)[inputName] = inputValue;
    }

    setForm(updatedForm);
    setRequieredFields(updatedRequiredFileds);
  };

  const formIsValid = () => {
    if (
      imagesExternalIds.length == 0 &&
      mandatoryUploadFilesParts?.includes(props.partToEditInfo?.name || BikePartType.brake)
    ) {
      return false;
    }
    return !Object.values(requiredFields).includes('');
  };
  const formIsClean = () => {
    return (
      !form.brand &&
      !form.category &&
      !form.additionalCategory &&
      !form.component &&
      !form.externalId &&
      !form.images &&
      !form.material &&
      !form.measurement &&
      !form.model &&
      !form.pictureFile &&
      !form.pictureUrl &&
      !form.size &&
      !form.type &&
      !form.additionalInfo &&
      imagesExternalIds.length === 0
    );
  };

  const handleSelectOption = (event: any) => {
    handleCustomChange(event.target.name, event.target.value as string);
  };

  if (requestStatus === RequestStatus.ERROR) {
    return <RequestStatusFeedBack status={requestStatus} onRetry={loadDropDownOptions} />;
  }

  const formChanged = () => {
    return (!props.editMode && !formIsClean()) || (props.editMode && changesMade);
  };
  return (
    <>
      <BottomSheet open={props.open} onClose={handleOnClose} bottomSpace="5em">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <AppTypography type={PDLTypography.largeHeading} customStyles={{ marginBottom: '0.6em' }}>
            {props.partToEditInfo?.label}
          </AppTypography>
          {images.map((image, idx) => (
            <Box key={idx}>
              <Box style={{ marginBottom: '0.9em' }}>
                {!image.url && (
                  <AppTypography
                    type={PDLTypography.subHeading}
                    customClasses={classes.inputLabel}
                    customStyles={{ marginBottom: '1.1em' }}
                    children={uploadImageLabel('Upload image')}
                  />
                )}
              </Box>
              <UploadImage
                image={image}
                imageUrls={image.url ? [image.url] : undefined}
                onImageUploaded={onImageUploaded}
                handleSetImage={(image) => {
                  const newImages = [...images];
                  newImages.pop();
                  setImages([...newImages, image, dummyImage()]);
                }}
                imageFiles={form.pictureFile ? [form.pictureFile] : undefined}
                setImageFiles={(files) => {
                  handleCustomChange('pictureFile', files ? files[0] : undefined);
                }}
                onRemove={(imageIdx) => {
                  !changesMade && setChangesMade(true);
                  const newImages = images.filter((img, idx) => imageIdx !== idx);
                  const newImageExternalIds = imagesExternalIds.filter(
                    (externalId, idx) => imageIdx !== idx
                  );

                  setImages(newImages);
                  setImagesExternalIds(newImageExternalIds);
                }}
                imageIndex={idx}
                onImagLoading={() => setRequestStatus(RequestStatus.LOADING)}
                onImagLoadComplete={() => setRequestStatus(RequestStatus.SUCCESS)}
              />
            </Box>
          ))}
          {props.partToEditInfo!.fields!.map(
            (field) =>
              !field.displayAfterDropDown && (
                <Box sx={{ mt: 2 }} key={field.name}>
                  <AppTextField
                    name={field.name}
                    label={getFieldLabel(field)}
                    value={(form as any)[field.name] || ''}
                    onInputChange={handleCustomChange}
                    required={field.required}
                    inputProps={{
                      type: field.inputType ? field.inputType : '',
                    }}
                    labelProps={{
                      type: PDLTypography.subHeading,
                      customStyles: { marginBottom: '0.625em', marginTop: '1em' },
                    }}
                  />
                </Box>
              )
          )}

          {props.partToEditInfo?.additionalDropdown ? (
            <Box sx={{ mt: 2 }}>
              <AppSelect
                props={{
                  label: getFieldLabel(props.partToEditInfo.additionalDropdown),
                  name: 'additionalCategory',
                  value: form.additionalCategory,
                  onChange: handleSelectOption,
                }}
              >
                {additionalDropDownOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </AppSelect>
            </Box>
          ) : (
            <></>
          )}

          {props.partToEditInfo?.dropdown ? (
            <Box sx={{ mt: 2 }}>
              <AppSelect
                props={{
                  label: getFieldLabel(props.partToEditInfo.dropdown),
                  name: 'category',
                  value: form.category,
                  onChange: handleSelectOption,
                }}
              >
                {dropDownOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </AppSelect>
            </Box>
          ) : (
            <></>
          )}

          {props.partToEditInfo!.fields!.map(
            (field) =>
              field.displayAfterDropDown && (
                <Box sx={{ mt: 2 }} key={field.name}>
                  <AppTextField
                    name={field.name}
                    label={getFieldLabel(field)}
                    value={(form as any)[field.name] || ''}
                    onInputChange={handleCustomChange}
                    required={field.required}
                    inputProps={{
                      type: field.inputType ? field.inputType : '',
                    }}
                    labelProps={{
                      type: PDLTypography.subHeading,
                      customStyles: { marginBottom: '0.625em', marginTop: '1em' },
                    }}
                  />
                </Box>
              )
          )}

          <SimpleButton
            onClick={addToListing}
            style={{ marginTop: '2em' }}
            disabled={!formIsValid() || !formChanged() || (requestStatus === RequestStatus.LOADING)}
          >
            Add to listing
          </SimpleButton>
        </div>
      </BottomSheet>

      <AlertDialog
        title="Leave edit"
        content="Are you sure to leave edit? Your changes will be discarted"
        open={openAlertDialog}
        onConfirm={() => {
          setOpenAlertDialog(false);
          props.onClose(props.partToEditInfo!.name);
        }}
        onCancel={() => {
          setOpenAlertDialog(false);
        }}
      />
    </>
  );
}
