import { BikeCard } from '../Bikes/BikeCard';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import BikeItem from '../Bikes/BikeItem';
import StringUtils from '../../utils/StringUtils';
import { TripListType } from '../../types/TripListType';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTypography } from '../Typography/AppTypography';
import Reservation from '../../model/Reservation';
import { useNavigate } from 'react-router-dom';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import { makeStyles } from '@mui/styles';

interface Props {
  title: string;
  reservationList: Reservation[];
  listType: TripListType;
}

export const TripList = (props: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();

  const breakpoint = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div>
      <Container maxWidth="lg">
        <Box sx={{ m: 3 }}>
          <AppTypography type={PDLTypography.largeHeading} children={props.title} />
        </Box>

        <Grid
          container
          display={'flex'}
          direction={breakpoint ? 'row' : 'column'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={4}
          mb={5}
        >
          {props.listType === TripListType.BIKE_CARD
            ? props.reservationList.map((reservation, index) => (
                <BikeCard
                  externalId={reservation.bike.externalId}
                  key={`${reservation.externalId}-${index}`}
                  title={reservation.bike.title}
                  imageUrl={
                    reservation.bike.images && reservation.bike.images.length > 0
                      ? reservation.bike.images[0].url
                      : ''
                  }
                  hideDivider={true}
                  hostedBy={StringUtils.formatShortUserNameAndLocation(
                    reservation.bike.owner,
                    reservation.bike
                  )}
                  hostedDates={DateUtils.formattedRangeDates(
                    new Date(reservation.startDate),
                    new Date(reservation.endDate)
                  )}
                  onClick={() =>
                    appNavigate(navigate, AppRoutes.TRIPS_RESERVATION_DETAIL, {
                      externalId: reservation.externalId!,
                    })
                  }
                  favourite={reservation.bike.favourite}
                  style={{ cursor: 'pointer' }}
                />
              ))
            : props.reservationList.map((reservation, index) => (
                <div>
                  <BikeItem
                    key={`${reservation.externalId}-${index}`}
                    bike={reservation.bike}
                    reservation={reservation}
                    showReservationDates={true}
                    hidePrice={true}
                    containerStyle={{ cursor: 'pointer' }}
                    handleOnClickEvent={() =>
                      appNavigate(navigate, AppRoutes.TRIPS_RESERVATION_DETAIL, {
                        externalId: reservation.externalId!,
                      })
                    }
                    imgClassName={classes.bikeImage}
                  />
                </div>
              ))}
        </Grid>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  bikeImage: {
    borderRadius: '0.3em',
    width: '100%',
    minHeight: '6em',
    objectFit: 'cover',
  },
});
