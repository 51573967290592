import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AppFontFamily } from '../../utils/AppFontFamily';
import { GenericCard, GenericCardProps } from '../core/GenericCard';
import AppTheme from '../../utils/AppTheme';
import { SxStyles } from '../../model/utils/SxStyles';

interface Props extends GenericCardProps {
  cells: { label: string; value: string | number }[];
}

function Cell(props: { label: string; value: string | number }) {
  return (
    <Grid container item direction={'column'} xs>
      <Typography sx={styles.title}>{props.label}</Typography>
      <Typography sx={styles.subTitle}>{props.value}</Typography>
    </Grid>
  );
}

export function CardWithCells(props: Props) {
  const classes = useStyles();

  const { cells, ...genericCardProps } = props;

  return (
    <GenericCard
      imageHeight={200}
      className={classes.cardContainer}
      titleClassName={classes.cardTitle}
      contentClassName={classes.cardContent}
      {...genericCardProps}
    >
      <Grid container justifyContent={'space-between'} textAlign={'left'}>
        {cells.map((c, index) => (
          <Cell key={index} label={c.label} value={c.value} />
        ))}
      </Grid>
    </GenericCard>
  );
}

const useStyles = makeStyles({
  cardContainer: {
    maxWidth: '95%',
    height: 350,
    borderRadius: '30px !important',
    marginRight: 10,
    display: 'flex !important',
    flexDirection: 'column',
  },
  cardTitle: {
    color: AppTheme.colors.black_01000E,
    fontSize: '1.5em !important',
    fontFamily: AppFontFamily.SHAPE_BOLD + ' !important',
    textAlign: 'left',
    lineHeight: '1.2em !important',
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

const styles: SxStyles = {
  title: {
    color: AppTheme.colors.black_01000E,
    fontFamily: AppFontFamily.FREIGHT_TEXT_PRO_ITALIC + ' !important',
    fontSize: '0.90em',
    mt: 1,
  },
  subTitle: {
    color: AppTheme.colors.black_01000E,
    fontSize: '0.80em',
    fontFamily: AppFontFamily.SHAPE_MEDIUM + ' !important',
  },
};
