import { CrudService } from './CrudService';
import ApiResponse from '../core/ApiResponse';
import Entity from '../core/Entity';
import PagedResult from '../core/PagedResult';
import httpClient from '../utils/HttpClient';

/**
 * @deprecated Use GenericCrudServiceV2 instead
 */
export default class GenericCrudService<T extends Entity> implements CrudService<T> {
  constructor(protected basePath: string) {}

  get(id: string, context: { [key: string]: string | number } = {}): Promise<ApiResponse<T>> {
    return httpClient.get(`${this.getBasePath(context)}/${id}`);
  }

  getById(id: number, context: { [key: string]: string | number } = {}): Promise<ApiResponse<T>> {
    return httpClient.get(`${this.getBasePath(context)}/${id}`);
  }

  getByExternalId(externalId: string, context: { [key: string]: string | number } = {}): Promise<ApiResponse<T>> {
    return httpClient.get(`${this.getBasePath(context)}/${externalId}`);
  }

  getAllProjectsByExternalId(
    page: number,
    pageSize: number,
    context: { [key: string]: string | number } = {}
  ): Promise<ApiResponse<PagedResult<T>>> {
    return httpClient.get(`${this.getBasePath(context)}/projects-by-user-id/?page=${page}&pageSize=${pageSize}`);
  }

  getAll(page: number, pageSize: number, context: { [key: string]: string | number } = {}): Promise<ApiResponse<PagedResult<T>>> {
    return httpClient.get(`${this.getBasePath(context)}?page=${page}&pageSize=${pageSize}`);
  }

  create(instance: T, context: { [key: string]: string | number } = {}): Promise<ApiResponse<T>> {
    return httpClient.post(this.getBasePath(context), instance);
  }

  update(instance: T, context: { [key: string]: string | number } = {}): Promise<ApiResponse<T>> {
    return httpClient.put(`${this.getBasePath(context)}/${instance.externalId}`, instance);
  }

  delete(instance: T, context: { [key: string]: string | number } = {}): Promise<ApiResponse<T>> {
    return httpClient.delete(`${this.getBasePath(context)}/${instance.externalId}`);
  }

  getBasePath(context: { [key: string]: string | number }): string {
    return this.basePath;
  }
}
