import { PDLTypography } from '../Typography/PDLTypography';
import { AppTextField } from './AppTextField';

export interface LocationForm {
  streetAddress: string;
  suite: string;
  city: string;
  state: string;
  countryCode: string;
  zipCode: string;
}

interface Props {
  form: LocationForm;
  onInputChange: (inputName: string, inputValue: string, isValid: boolean) => void;
}

export function LocationForm(props: Props) {
  const { form, onInputChange } = props;

  return (
    <>
      <div style={{ marginBottom: '1.42em' }}>
        <AppTextField
          label={'Street Address *'}
          name={'streetAddress'}
          value={form.streetAddress}
          onInputChange={onInputChange}
          labelProps={{
            type: PDLTypography.subHeading,
            customStyles: { marginBottom: '0.5em' },
          }}
          realTimeValidation
          required
          restrict="onlyNumbersLettersAndSpaces"
        />
      </div>

      <div style={{ marginBottom: '1.42em' }}>
        <AppTextField
          label={'Apt, Suite, etc.'}
          name={'suite'}
          value={form.suite}
          onInputChange={onInputChange}
          labelProps={{
            type: PDLTypography.subHeading,
            customStyles: { marginBottom: '0.5em' },
          }}
          restrict="onlyNumbersLettersAndSpaces"
        />
      </div>

      <div style={{ marginBottom: '1.42em' }}>
        <AppTextField
          label={'City *'}
          name={'city'}
          value={form.city}
          onInputChange={onInputChange}
          labelProps={{
            type: PDLTypography.subHeading,
            customStyles: { marginBottom: '0.5em' },
          }}
          realTimeValidation
          required
          restrict="onlyLetters"
          // Temporarily disabled
          disabled
        />
      </div>

      <div style={{ marginBottom: '1.42em' }}>
        <AppTextField
          label={'State *'}
          name={'state'}
          value={form.state}
          onInputChange={onInputChange}
          labelProps={{
            type: PDLTypography.subHeading,
            customStyles: { marginBottom: '0.5em' },
          }}
          realTimeValidation
          required
          restrict="onlyLetters"
          minLength={3}
          // Temporarily disabled
          disabled
        />
      </div>

      <div>
        <AppTextField
          label={'Zip Code *'}
          name={'zipCode'}
          value={form.zipCode}
          onInputChange={onInputChange}
          labelProps={{
            type: PDLTypography.subHeading,
            customStyles: { marginBottom: '0.5em' },
          }}
          realTimeValidation
          required
          restrict="onlyNumbersAndLetters"
        />
      </div>
    </>
  );
}
