import { makeStyles } from '@mui/styles';
import Image from '../../external/fox-typescript/core/Image';
import ImageUtils from '../../external/fox-typescript/utils/ImageUtils';
import AppImage from '../../external/pdl-common/components/commons/AppImage';
import BottomSheet from '../commons/BottomSheet';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import { AppCamera } from './AppCamera';
import DynamicPropertyService from '../../services/DynamicPropertyService';
import { useEffect, useState } from 'react';
import AppCameraPro from './AppCameraPro';
import { Drawer } from '@mui/material';
import AlertService from '../../services/AlertService';
import environment from '../../environment.json';

interface Props {
  show: boolean;
  collectionOfImages: Image[];
  onClose: () => void;
  onImageUploaded?: (image: Image) => void;
  handleSetImage?: (image: Image) => void;
  callToService: (file: File) => Promise<Image | undefined>;
}

const useStyles = makeStyles({
  carouselSection: {
    marginTop: '1em',
  },
});

export const AddPhotoFromCamera: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const [cameraProEnabled, setCameraProEnabled] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (environment.env === 'prod') {
      setCameraProEnabled(true);
    } else {
      try {
        DynamicPropertyService.getCameraProEnabled().then((response) => {
          setCameraProEnabled(response.getContentOrThrowError());
        });
      } catch (err: any) {
        AlertService.showSnackInternalError();
      }
    }
  }, []);

  async function handleImageUpload(base64Image: string) {
    const fileImage = ImageUtils.createFileUsingBase64(base64Image);

    const image = await props.callToService(fileImage);

    if (image) {
      props.handleSetImage!(image);
      if (props.onImageUploaded) {
        props.onImageUploaded(image);
      }
    }
  }

  return (
    <>
      {cameraProEnabled == undefined ? (<></>) :
        cameraProEnabled ?
          /*(<Drawer open={props.show} style={{ zIndex: 1300 }} onClose={() => props.onClose()}>*/
          (<BottomSheet open={props.show} onClose={() => props.onClose()} bottomSpace={'1em'}>
            <div style={{ position: 'relative' }}>
              <AppCameraPro
                show={props.show}
                onClose={() => props.onClose()}
                onImageUploaded={(base64Image) => handleImageUpload(base64Image)}
              />
            </div>

            <div className={classes.carouselSection}>
              <Carousel
                itemsToShow={5}
                slidesToShow={{
                  sm: 3,
                }}
                infinite={false}
                data={props.collectionOfImages}
                renderItem={(image: Image) => (
                  <AppImage src={image.url} style={{ width: '100%', height: "200px", marginRight: '1em' }} />
                )}
              />
            </div>
            {/*</Drawer >) :*/}
          </BottomSheet >) :
          (<BottomSheet open={props.show} onClose={() => props.onClose()} bottomSpace={'1em'}>
            <div style={{ position: 'relative' }}>
              <AppCamera
                show={props.show}
                onClose={() => props.onClose()}
                onImageUploaded={(base64Image) => handleImageUpload(base64Image)}
              />
            </div>
            <div className={classes.carouselSection}>
              <Carousel
                itemsToShow={5}
                slidesToShow={{
                  sm: 3,
                }}
                infinite={false}
                data={props.collectionOfImages}
                renderItem={(image: Image) => (
                  <AppImage src={image.url} style={{ width: '100%', height: "200px", marginRight: '1em' }} />
                )}
              />
            </div>
          </BottomSheet >)
      }
    </>
  );
};
