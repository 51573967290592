import { Box, Button, ButtonGroup, Grid, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AppTheme, { IAppTheme } from '../../../utils/AppTheme';
import LandingPageNavBar from '../../core/LandingPageNavBar';
import backgroundImage from '../../../assets/img/LandingPage/home_background_image.png';
import bikeCard1 from '../../../assets/img/LandingPage/thirdSection/bike_card_1.png';
import bikeCard2 from '../../../assets/img/LandingPage/thirdSection/bike_card_2.png';
import bikeCard3 from '../../../assets/img/LandingPage/thirdSection/bike_card_3.png';
import bikeCard4 from '../../../assets/img/LandingPage/thirdSection/bike_card_4.png';
import bikeCard5 from '../../../assets/img/LandingPage/thirdSection/bike_card_5.png';
import sixthSectionBackground from '../../../assets/img/LandingPage/sixth_section_background.jpeg';
import { PDLTypography } from '../../../external/pdl-common/components/Typography/PDLTypography';
import { AppTypography } from '../../../external/pdl-common/components/Typography/AppTypography';
import { COLORS } from '../../../external/pdl-common/utils/AppTheme';
import BubbleTextAndLabel from '../../commons/BubbleTextAndLabel';
import { FooterPdlClub } from '../../commons/FooterPdlClub';
import SecondSection from './SecondSection';
import FourthSection from './FourthSection';
import { ArticleCarousel } from './ArticleCarousel';
import { useEffect } from 'react';
import { AppFontFamily } from '../../../utils/AppFontFamily';
import { appNavigate, AppRoutes } from '../../../utils/AppNavigation';
import { useNavigate } from 'react-router-dom';

export const LandingPageHome = () => {
  const theme = useTheme();

  const classes = useStyles();

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getBody = () => {
    return (
      <>
        <Grid container className={classes.mainContainer}>
          <Box className={classes.firstSectionBackground} />
          <Box className={classes.firstSectionOpacity} height="40%" />
          {/* Top section */}
          <Grid item className={classes.firstSection}>
            <AppTypography
              type={PDLTypography.largeHeading}
              customStyles={{
                zIndex: 9,
                color: COLORS.white_FFFFFF,
                fontSize: sm ? '5em' : '3.25em',
                marginTop: sm ? '5vh' : '-7vh'
              }}
            >
              Keep Riding.
            </AppTypography>
            <AppTypography
              type={PDLTypography.mediumHeading}
              customStyles={{
                zIndex: 9,
                color: COLORS.white_FFFFFF,
                fontSize: sm ? '1.5em' : '1.1em',
              }}
            >
              Explore the first peer-to-peer performance bike<br />
              rental marketplace.<br />
            </AppTypography>
            <Grid sx={styles.buttonContainer}>
              <ButtonGroup variant="contained" sx={styles.buttonGroup} disableElevation>
                <Button sx={styles.button} onClick={() => appNavigate(navigate, AppRoutes.SIGN_IN, null)}>
                  Sign In
                </Button>
                <Button sx={styles.button} onClick={() => appNavigate(navigate, AppRoutes.INVITE_CODE, null)}>
                  Sign Up
                </Button>
              </ButtonGroup>
            </Grid>
            <AppTypography
              type={PDLTypography.callToAction}
              customStyles={{
                cursor: 'pointer',
                color: COLORS.white_FFFFFF,
                fontSize: '1em',
                marginTop: '10vh',
                lineHeight: '240%'
              }}
              children={'About'}
              onClick={() => appNavigate(navigate, AppRoutes.ABOUT, null)}
            />
          </Grid>
          {/* Second section */}
          <SecondSection />

          {/* Third section */}
          <Grid
            item
            className={lg ? classes.thirdSection : classes.thirdSectionMobile}
            sx={{ padding: md ? '0' : '1em' }}
          >
            <Grid
              className={classes.thirdSectionDescription}
              sm={12}
              xs={12}
              pt={md ? '9vh' : sm ? '5vh' : '2vh'}
              textAlign={sm ? 'left' : 'center'}
            >
              <AppTypography
                type={PDLTypography.largeHeading}
                children={'Test ride bikes'}
                customStyles={{ fontSize: md ? '3.125em' : '2.4em' }}
              />
              <AppTypography
                type={PDLTypography.smallParagraph}
                children={`Pedal a mile on someone else's bike and find your perfect ride.`}
                customStyles={{ fontSize: '1em', marginTop: '1em' }}
              />
            </Grid>

            {/* DESKTOP OR MOBILE VERSION */}
            {lg ? (
              <Grid
                maxWidth="100%"
                display="flex"
                justifyContent="space-around"
                padding="2em 0 2em 0"
              >
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard1})`,
                    backgroundSize: 'cover',
                    width: lg ? '15vw' : '17vw',
                    height: lg ? '18vw' : '20vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    marginTop: '3em',
                  }}
                />
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard2})`,
                    backgroundSize: 'cover',
                    width: lg ? '15.5vw' : '17vw',
                    height: lg ? '18vw' : '19vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    marginTop: '6em',
                  }}
                />
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard3})`,
                    backgroundSize: 'cover',
                    width: lg ? '15.5vw' : '17vw',
                    height: lg ? '18vw' : '19vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    marginTop: '1em',
                  }}
                />
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard4})`,
                    backgroundSize: 'cover',
                    width: lg ? '15.5vw' : '17vw',
                    height: lg ? '18vw' : '19vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    marginTop: '3em',
                  }}
                />
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard5})`,
                    backgroundSize: 'cover',
                    width: lg ? '15vw' : '17vw',
                    height: lg ? '18vw' : '20vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    marginTop: '6em',
                  }}
                />
              </Grid>
            ) : (
              <Grid
                maxWidth="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard1})`,
                    backgroundSize: 'cover',
                    width: sm ? '30vw' : '45vw',
                    height: sm ? '38vw' : '55vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    mt: '1em',
                  }}
                />
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard2})`,
                    backgroundSize: 'cover',
                    width: sm ? '30vw' : '45vw',
                    height: sm ? '38vw' : '55vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    mt: '1em',
                  }}
                />
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard3})`,
                    backgroundSize: 'cover',
                    width: sm ? '30vw' : '45vw',
                    height: sm ? '38vw' : '55vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    mt: '1em',
                  }}
                />
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard4})`,
                    backgroundSize: 'cover',
                    width: sm ? '30vw' : '45vw',
                    height: sm ? '38vw' : '55vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    mt: '1em',
                  }}
                />
                <Box
                  sx={{
                    maxWidth: '100%',
                    backgroundImage: `url(${bikeCard5})`,
                    backgroundSize: 'cover',
                    width: sm ? '30vw' : '45vw',
                    height: sm ? '38vw' : '55vw',
                    border: 'solid black 2px',
                    borderRadius: '1.5em',
                    mt: '1em',
                  }}
                />
              </Grid>
            )}
          </Grid>

          {/* Fourth section */}
          <FourthSection />

          {/* Fifth section */}
          <Grid item className={classes.fifthSection}>
            <Grid
              className={classes.fifthSectionDescription}
              padding={sm ? '0' : '1em'}
              textAlign={sm ? 'left' : 'center'}
            >
              <AppTypography
                type={PDLTypography.largeHeading}
                customStyles={{
                  fontSize: md ? '3.125em' : sm ? '2.3em' : '1.6em',
                }}
              >
                Exclusive content for members
              </AppTypography>
              <AppTypography
                type={PDLTypography.smallParagraph}
                customStyles={{
                  fontSize: sm ? '1em' : '.9em',
                  marginTop: '1em',
                }}
              >
                Join PDL Club for free access to our guides and articles <br />
                put together buy vetted cyclist educators in our community. <br />
              </AppTypography>
            </Grid>

            <Box className={classes.fifthSectionContent} minHeight="35em">
              <ArticleCarousel ml={lg ? 5 : sm ? 15 : 10} />
              <ArticleCarousel ml={lg ? -5 : sm ? -25 : -25} />
              <ArticleCarousel ml={lg ? 2 : sm ? -2 : -6} />
            </Box>
          </Grid>

          {/* Sixth section */}
          <Grid
            className={classes.sixthSection}
            flexDirection={md ? 'row' : 'column'}
            justifyContent={md ? 'space-evenly' : 'space-around'}
          >
            <Box className={classes.sixthSectionOpacity} />

            <Box height={md ? '76%' : '35%'} mt={md ? 0 : '2em'}>
              <AppTypography
                type={PDLTypography.largeHeading}
                customStyles={{
                  color: COLORS.white_FFFFFF,
                  fontSize: md ? '3.125em' : sm ? '2.5em' : '2em',
                }}
              >
                Hear from <br />
                the community
              </AppTypography>
            </Box>

            <Box
              className={classes.sixthSectionContent}
              justifyContent={md ? 'space-between' : 'space-around'}
              mb={md ? 0 : '2em'}
            >
              <BubbleTextAndLabel
                label={`"PDL Club is an outstanding service with the potencial to make me extra cash each month. Every renter I've had treated my bike like it was their own."`}
                labelStyle={{ fontSize: md ? '1em' : sm ? '0.8em' : '0.75em' }}
                description="Natalie R."
                customStyle={{
                  width: lg ? '35.8em' : md ? '31em' : sm ? '28em' : '21em',
                  height: md ? '12em' : sm ? '10em' : '8em',
                  padding: sm ? '1.2em 3em 1.2em 3em' : '.3em .5em .3em 1em',
                }}
              />
              <BubbleTextAndLabel
                label={`"I'm thrilled to list my bike on PDL Club. Easy access to try others bikes is great for the cycling community, and breaks the barrier of entry for many people curious to start."`}
                labelStyle={{ fontSize: md ? '1em' : sm ? '0.8em' : '0.75em' }}
                description="Michael S."
                customStyle={{
                  width: lg ? '35.8em' : md ? '31em' : sm ? '28em' : '21em',
                  height: md ? '12em' : sm ? '10em' : '8em',
                  padding: sm ? '1.2em 3em 1.2em 3em' : '.3em .5em .3em 1em',
                }}
              />
              <BubbleTextAndLabel
                label={`"I've met and rode with many cyclists on my travels through PDL Club. It's so convenient to have a conection to the local cycling community anywhere I go."`}
                labelStyle={{ fontSize: md ? '1em' : sm ? '0.8em' : '0.75em' }}
                description="Jennifer B."
                customStyle={{
                  width: lg ? '35.8em' : md ? '31em' : sm ? '28em' : '21em',
                  height: md ? '12em' : sm ? '10em' : '8em',
                  padding: sm ? '1.2em 3em 1.2em 3em' : '.3em .5em .3em 1em',
                }}
              />
            </Box>
          </Grid>
          <FooterPdlClub showTopSection />
        </Grid>
      </>
    );
  };

  return (
    <>
      <LandingPageNavBar transparent />
      {getBody()}
    </>
  );
};
  const styles = {
    button: {
      cursor: 'pointer',
      zIndex: 30,
      backgroundColor: 'white',
      borderColor: 'transparent !important',
      color: AppTheme.colors.persianBlue_2238CB,
      px: 5,
      py: 1.2,
      borderRadius: '2em',
      fontSize: '1em',
      fontFamily: AppFontFamily.SHAPE_BOLD,
      fontWeight: 800,
      '&': {
        textTransform: 'none',
      },
      '&:hover': {
        color: AppTheme.colors.persianBlue_2238CB,
        backgroundColor: 'white',
      },
    },
    buttonGroup: {
      backgroundColor: 'transparent !important',
      borderColor: 'transparent !important',
      borderRadius: '2em',
    },
    buttonContainer: {
      mt: '43vh',
    },
  }

const useStyles = makeStyles((theme: IAppTheme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  mainContainer: {
    position: 'absolute',
    //zIndex: -1,
    width: '100vw',
    height: '100vh',
    maxWidth: '100%',
  },
  firstSection: {
    width: '100%',
    height: '56.5em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: '-3em',
  },
  firstSectionBackground: {
    backgroundImage: `url(${backgroundImage})`,
    position: 'absolute',
    zIndex: -100,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    minHeight: '60em',
    maxWidth: '100%',
  },
  firstSectionOpacity: {
    position: 'absolute',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(1, 0, 14, 0.46) 0%, rgba(1, 0, 14, 0) 100%);',
  },
  titleDescription: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  thirdSection: {
    width: '100vw',
    height: '45.2em',
    backgroundColor: COLORS.white_EEEEEC,
  },
  thirdSectionMobile: {
    width: '100vw',
    height: '310hv',
    backgroundColor: COLORS.white_EEEEEC,
  },
  thirdSectionDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fifthSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: COLORS.white_EEEEEC,
    overflowX: 'hidden',
    paddingTop: '1.5em',
  },
  fifthSectionDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fifthSectionContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  sixthSection: {
    backgroundImage: `url(${sixthSectionBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    minHeight: '45em',
    backgroundColor: 'black',
    overflowX: 'hidden',
    zIndex: '-2',
  },
  sixthSectionOpacity: {
    position: 'absolute',
    width: '100%',
    transform: 'rotate(-180deg)',
    background: 'linear-gradient(180deg, rgba(1, 0, 14, 0) 0%, rgba(0, 3, 14, 0.3) 100%)',
    zIndex: '-1',
    minHeight: '60em',
  },
  sixthSectionContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '41em',
    padding: '2em 0 2em 0',
  },
}));
