import { Container, SxProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllTimeEarnings } from '../../model/AllTimeEarnings';
import Reservation from '../../model/Reservation';
import BikeService from '../../services/BikeService';
import earningService from '../../services/EarningService';
import reservationService from '../../services/ReservationService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { IAppTheme } from '../../utils/AppTheme';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import AllTimeStatsComponent from '../commons/AllTimeStatsComponent';
import AppHeader from '../commons/AppHeader';
import AppMenuList from '../commons/AppMenuList';
import { CardWithCells } from '../commons/CardWithCells';
import RequestStatusFeedBack from '../commons/RequestStatusFeedback';
import SimpleButton from '../commons/SimpleButton';
import { AppLayout } from '../core/AppLayout';
import { Carousel } from '../../external/pdl-common/components/commons/Carousel';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import HostWelcome from './HostWelcome';
import DateUtils from '../../external/pdl-common/utils/DateUtils';

const muiStyles = {
  sectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  } as SxProps,
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  layout: {
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    position: 'absolute',
    bottom: '2em',
    minWidth: '70%',
    margin: '0% 15%',
  },
  scrollBody: {
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
    flexDirection: 'column',
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  quickLinksContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
    backgroundColor: theme.colors.white_EEEEEC,
  },
  emptyCarouselMessageContainer: {
    backgroundColor: theme.colors.white_EEEEEC,
    borderRadius: 4,
    height: '20vh',
    display: 'flex',
    justifyContent: 'center',
  },
  emptyCarouselMessage: {
    flex: 0.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  bikeCard: {
    maxWidth: '95%',
    height: 350,
    borderRadius: '30px !important',
    marginRight: 10,
    display: 'flex !important',
    flexDirection: 'column',
    cursor: 'pointer',
  },
}));

const logger = new Logger('Host');

export default function Host() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
  const [allTimeEarnings, setAllTimeEarnings] = useState<AllTimeEarnings | undefined>(undefined);
  const [outNowReservations, setOutNowReservations] = useState<Reservation[] | undefined>(
    undefined
  );
  const [upcomingReservations, setUpcomingReservations] = useState<Reservation[] | undefined>(
    undefined
  );
  const [showWelcome, setShowWelcome] = useState<boolean>(true);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setRequestStatus(RequestStatus.LOADING);

      const responses = await Promise.all([
        earningService.getAllTimeEarnings(),
        //TODO: to fix pagination
        reservationService.getOutNowReservations(0, 1000),
        reservationService.getUpcomingReservationsForHost(0, 1000),
        BikeService.checkIfUserHasListedBikes(),
      ]);

      setAllTimeEarnings(responses[0].getContent());
      setOutNowReservations(responses[1].getContent().content);
      setUpcomingReservations(responses[2].getContent().content);
      setShowWelcome(!responses[3].getContent());

      setRequestStatus(RequestStatus.SUCCESS);
    } catch (error: any) {
      logger.error('Error loading content', error?.response?.data?.message || error);
      setRequestStatus(RequestStatus.ERROR);
    }
  };

  function EmptyCarouselMessage(props: { content: string; className?: string }) {
    return (
      <div className={`${classes.emptyCarouselMessageContainer} ${props.className}`}>
        <div className={classes.emptyCarouselMessage}>
          <AppTypography type={PDLTypography.paragraph}>{props.content}</AppTypography>
        </div>
      </div>
    );
  }

  if (requestStatus !== RequestStatus.SUCCESS) {
    return (
      <div style={{ height: '80vh' }}>
        <RequestStatusFeedBack status={requestStatus} onRetry={load} />
      </div>
    );
  }

  if (showWelcome) {
    return <HostWelcome />;
  }

  return (
    <AppLayout
      fromHome
      className={classes.layout}
      header={() => <AppHeader content="Hosting Dashboard" showBackButton={false} />}
      content={() => {
        return (
          <>
            <div className={classes.scrollBody}>
              {/* All time stats */}
              <AllTimeStatsComponent data={allTimeEarnings ?? { rentals: 0, revenue: 0 }} />

              <Container sx={muiStyles.sectionsContainer}>
                {/** Section - Out now */}
                <AppTypography
                  type={PDLTypography.largeHeading}
                  customClasses={classes.sectionTitle}
                >
                  Out now
                </AppTypography>
                <Carousel
                  itemsToShow={3}
                  slidesToShow={{
                    sm: 1,
                  }}
                  data={outNowReservations!}
                  emptyComponent={
                    <EmptyCarouselMessage content="You have no bikes out at the moment." />
                  }
                  renderItem={(r, index) => (
                    <CardWithCells
                      key={index}
                      className={classes.bikeCard}
                      title={r.bike.title}
                      imageUrl={
                        r.bike.images && r.bike.images.length > 0 ? r.bike.images[0].url : ''
                      }
                      onClick={() =>
                        appNavigate(navigate, AppRoutes.HOST_RESERVATION_DETAIL, {
                          externalId: r.externalId!,
                        })
                      }
                      cells={[
                        {
                          label: 'Rented by',
                          value: `${r.renter.user.firstName} ${r.renter.user.lastName.charAt(0)}.`,
                        },
                        {
                          label: 'Dates',
                          value: DateUtils.formattedRangeDates(
                            new Date(r.startDate),
                            new Date(r.endDate)
                          ),
                        },
                      ]}
                      elevation={3}
                    />
                  )}
                />

                {/** Section - upcoming */}
                <AppTypography
                  type={PDLTypography.largeHeading}
                  customClasses={classes.sectionTitle}
                >
                  Upcoming
                </AppTypography>
                <Carousel
                  itemsToShow={3}
                  slidesToShow={{
                    sm: 1,
                  }}
                  className={classes.marginBottom}
                  data={upcomingReservations!}
                  emptyComponent={
                    <EmptyCarouselMessage
                      content="You have no upcoming reservations."
                      className={classes.marginBottom}
                    />
                  }
                  renderItem={(r, index) => (
                    <CardWithCells
                      key={index}
                      className={classes.bikeCard}
                      title={r.bike.title}
                      imageUrl={
                        r.bike.images && r.bike.images.length > 0 ? r.bike.images[0].url : ''
                      }
                      onClick={() =>
                        appNavigate(navigate, AppRoutes.HOST_RESERVATION_DETAIL, {
                          externalId: r.externalId!,
                        })
                      }
                      cells={[
                        {
                          label: 'Rented by',
                          value: `${r.renter.user.firstName} ${r.renter.user.lastName.charAt(0)}.`,
                        },
                        {
                          label: 'Dates',
                          value: DateUtils.formattedRangeDates(
                            new Date(r.startDate),
                            new Date(r.endDate)
                          ),
                        },
                      ]}
                      elevation={3}
                    />
                  )}
                />
              </Container>
              <Container className={classes.quickLinksContainer} sx={muiStyles.sectionsContainer}>
                {/** Section - Quick links */}
                <AppTypography
                  type={PDLTypography.largeHeading}
                  customClasses={classes.sectionTitle}
                >
                  Quick links
                </AppTypography>
                <AppMenuList
                  menus={[
                    {
                      text: 'My listings',
                      action: () => appNavigate(navigate, AppRoutes.HOST_BIKE_LISTINGS, null),
                    },
                    {
                      text: 'My ratings and reviews',
                      action: () => appNavigate(navigate, AppRoutes.HOST_RATINGS, null),
                    },
                    {
                      text: 'Reservation history',
                      action: () => appNavigate(navigate, AppRoutes.HOST_RESERVATION_HISTORY, null),
                    },
                    {
                      text: 'Earnings',
                      action: () => appNavigate(navigate, AppRoutes.EARNINGS, null),
                    },
                    {
                      text: 'Payout details',
                      action: () => appNavigate(navigate, AppRoutes.PAYOUT_METHOD, null),
                    },
                  ]}
                />

                {/** Extra space to avoid overlap with the float button */}
                <div style={{ height: '6em' }} />
              </Container>
            </div>

            {/** Float button */}
            <div className={classes.button}>
              <SimpleButton
                onClick={async () => {
                  //const response = await BikeService.getDraftBike();
                  //TODO if response.content contain a bike,
                  //open a modal to ask for continue or delete draft else, continue navigation normaly
                  //if user continue with draft, check status of bike to navigate
                  //response.getContent().status
                  //if user decline, call to delete bike
                  //const responseRemovedDraft = await BikeService.deleteDraftBike(response.getContent().externalId||'');
                  appNavigate(navigate, AppRoutes.HOST_BIKE_FIRST_STEP, null);
                }}
              >
                Add a New Listing
              </SimpleButton>
            </div>
          </>
        );
      }}
    />
  );
}
