import { CircularProgress, Container, Grid, SxProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Bike } from '../../external/pdl-common/model/Bike';
import { BikeCard } from '../Bikes/BikeCard';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { Search } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppTheme from '../../utils/AppTheme';
import { RequestStatus } from '../../utils/RequestStatus';
import { BikeFilters } from '../../model/utils/BikeFilters';
import Location from '../../external/fox-typescript/core/Location';
import DateUtils from '../../external/pdl-common/utils/DateUtils';
import BikeUtils from '../../external/pdl-common/utils/BikeUtils';

interface Props {
  bikes: Bike[];
  resultsContainer?: SxProps;
  startDate: Date;
  endDate: Date;
  hasMorePages: boolean;
  loadMoreBikes: () => Promise<void>;
  status: RequestStatus;
  filters?: BikeFilters;
  searchLocation?: Location;
}

const useStyles = makeStyles({
  bikeCard: {
    cursor: 'pointer',
    marginBottom: '1em',
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: AppTheme.colors.persianBlue_2238CB,
  },
});

export const SearchList = (props: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const renderItem = (bike: Bike, index: number) => {
    return (
      <BikeCard
        notAvailable={!bike.available}
        externalId={bike.externalId}
        key={`${bike.externalId}-${index}`}
        title={bike.title}
        size={bike.size}
        type={bike.type}
        rating={Math.round(bike.avgStars)}
        price={BikeUtils.dailyPrice(bike, props.startDate, props.endDate)}
        customPrice={bike.customPrice}
        totalPrice={
          DateUtils.getFullDaysBetweenDates(props.startDate, props.endDate) * BikeUtils.dailyPrice(bike, props.startDate, props.endDate)
        }
        totalRides={bike.totalRides}
        status={BikeUtils.getDefaultOrCustomProperty(bike, 'condition')}
        favourite={bike.favourite}
        className={classes.bikeCard}
        imageUrl={bike.images && bike.images.length > 0 ? bike.images[0].url : ''}
        discountIcon={bike.twoDayPrice != undefined || bike.threePlusDayPrice != undefined}
        onClick={() =>
          appNavigate(
            navigate,
            AppRoutes.BIKE_DETAIL,
            {
              externalId: bike.externalId!,
            },
            bike.available ?
              {
                state: {
                  startDate: props.startDate,
                  endDate: props.endDate,
                  filters: props.filters,
                  searchLocation: props.searchLocation,
                },
              }
              :
              {}
          )
        }
      />
    );
  };

  if (props.bikes.length === 0 && props.status !== RequestStatus.LOADING) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1em',
        }}
      >
        <Search fontSize="large" style={{ marginBottom: '0.2em' }} />
        <AppTypography type={PDLTypography.subHeadingSerif}>
          No results for the moment
        </AppTypography>
      </div>
    );
  } else if (props.bikes.length === 0 && props.status === RequestStatus.LOADING) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1em',
          color: AppTheme.colors.persianBlue_2238CB,
        }}
      >
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <Container maxWidth="md" sx={props.resultsContainer}>
      <Grid
        container
        gap={3}
        paddingTop={1}
        paddingBottom={10}
        alignContent={'center'}
        justifyContent={'center'}
      >
        <InfiniteScroll
          dataLength={props.bikes.length}
          hasMore={props.hasMorePages}
          next={props.loadMoreBikes}
          scrollThreshold={1}
          loader={
            <div className={classes.loader}>
              <CircularProgress color="inherit" />
            </div>
          }
        >
          {props.bikes.map((bike, index) => renderItem(bike, index))}
        </InfiniteScroll>
      </Grid>
    </Container>
  );
};
