import { v4 as uuidv4 } from 'uuid';
import { BikeDropOff } from '../../model/utils/BikeDropOff';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppTheme, { IAppTheme } from '../../utils/AppTheme';
import { SxStyles } from '../../model/utils/SxStyles';
import Reservation from '../../external/pdl-common/model/Reservation';
import Image from '../../external/fox-typescript/core/Image';
import ReservationService from '../../services/ReservationService';
import Logger from '../../external/pdl-common/utils/Logger';
import { AppLayout } from '../core/AppLayout';
import AppHeader from './AppHeader';
import {ButtonBase, Container, Link} from '@mui/material';
import { ReservationDetailsHeader } from '../../external/pdl-common/components/ReservationDetailsHeader';
import { BikeAttribute } from '../Bikes/BikeSize';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTypography } from '../Typography/AppTypography';
import { CheckboxWithInfo } from './CheckboxWithInfo';
import { UploadImage } from './UploadImage';
import { Box } from '@mui/system';
import SimpleButton from './SimpleButton';
import NotificationModal from './NotificationModal';
import { RequestStatus } from '../../utils/RequestStatus';
import RequestStatusFeedBack from './RequestStatusFeedback';
import AlertService from '../../services/AlertService';
import { AppTextField } from './AppTextField';
import ConfirmReservationDropOffBottomSheet from '../Reservation/common/ConfirmReservationDropOffBottomSheet';
import TintUtils, { ClaimInitiator } from '../../utils/TintUtils';
import UserService from '../../services/UserService';
import { FullUser } from '../../external/pdl-common/model/FullUser';
import {PickUpDropOffCheckList} from "../../external/pdl-common/model/PickUpDropOffCheckList";

interface Props {
    confirmationType: 'drop-off' | 'pick-up';
    onConfirm: (reservationExternalId: string, checkListExternalId: string) => Promise<void>;
    onCancel: (reservationExternalId: string, checkListExternalId: string) => Promise<void>;
}

const DEFAULT_ITEMS: BikeDropOff = {
    makeAndModel: false,
    bikeCondition: false,
    damage: false,
    parts: false,
    userComments: '',
};

const dummyImage = () => {
    return {
        externalId: uuidv4(),
        url: '',
        width: 0,
        height: 0,
        type: '',
        description: '',
    };
};

const logger = new Logger('HostBikeDropOff');

const descriptionConfig = {
    'drop-off': {
        title: 'Drop-off',
        text: 'drop-off',
        textWithSpace: 'drop off',
        makeAndModelDescription:
            'The bike is the same make and model as described in the listing and rented out.',
        conditionDescription: "The bike's condition matches the condition in which it was picked up.",
        damageDescription: 'The bike does not have any undisclosed damage.',
        partsDescription: 'The bike includes all parts described in the listing.',
        notificationMessage: 'Bike drop-off has been confirmed',
        cancelMessage: 'Bike drop-off has been cancelled',
    },
    'pick-up': {
        title: 'Pick-up',
        text: 'pick-up',
        textWithSpace: 'pick up',
        makeAndModelDescription: 'The bike is the same make and model as described in the listing.',
        conditionDescription: "The bike's condition matches the description in the listing.",
        damageDescription: 'The bike does not have any undisclosed damage.',
        partsDescription: 'The bike includes all parts described in the listing.',
        notificationMessage: 'Your pick-up has been confirmed',
        cancelMessage: 'Bike pick-up has been cancelled',
    },
};

export function ReservationDropOffHostConfirmation(props: Props) {
    const classes = useStyles();
    const {  reservationExternalId, dropOffExternalId} = useParams();

    const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
    //const [checkList, setCheckList] = useState<PickUpDropOffCheckList | undefined>(undefined);
    const [detailsItems, setDetailsItems] = useState<BikeDropOff>(DEFAULT_ITEMS);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [imagesBike, setImagesBike] = useState<Image[]>([dummyImage()]);
    const [imagesIdentity, setImagesIdentity] = useState<Image[]>([dummyImage()]);

    const [requestStatus, setRequestStatus] = useState(RequestStatus.LOADING);
    const [confirmationRequestStatus, setConfirmationRequestStatus] = useState(RequestStatus.SUCCESS);

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [pdlUser, setPdlUser] = useState<FullUser | undefined>(undefined);

    useEffect(() => {
        getReservationDetail();
    }, []);

    const getReservationDetail = async () => {
        try {
            setRequestStatus(RequestStatus.LOADING);
            const reservationResponse = await ReservationService.get(reservationExternalId!);
            //const reservationRe ReservationService.get(externalId!);
            const reservationContent: Reservation = reservationResponse.getContent();

            if(props.confirmationType === 'drop-off'){
                if (reservationContent?.dropOffCheckList?.externalId === dropOffExternalId) {
                    setReservation(reservationResponse.getContent());
                    setRequestStatus(RequestStatus.SUCCESS);
                } else {
                    setRequestStatus(RequestStatus.ERROR);
                    logger.error('Invalid checklist link', null);
                }
            } else {
                if (reservationContent?.pickUpCheckList?.externalId === dropOffExternalId) {
                    setReservation(reservationResponse.getContent());
                    setRequestStatus(RequestStatus.SUCCESS);
                } else {
                    setRequestStatus(RequestStatus.ERROR);
                    logger.error('Invalid checklist link', null);
                }
            }
        } catch (error) {
            setRequestStatus(RequestStatus.ERROR);
            logger.error('Error fetching the reservation detail', error);
        }
    };

    const handleOnConfirm = async () => {
        try {
            setConfirmationRequestStatus(RequestStatus.LOADING);


            const checkListId = props.confirmationType === 'drop-off' ? reservation!.dropOffCheckList!.externalId! : reservation!.pickUpCheckList!.externalId!

            await props.onConfirm(reservation!.externalId!, checkListId);
            setShowSuccessModal(true);
            setConfirmationRequestStatus(RequestStatus.SUCCESS);

        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || 'Error in reservation confirmation';

            logger.error('Error when executing the operation', errorMessage);
            AlertService.showSnackCustomError(errorMessage);

            setConfirmationRequestStatus(RequestStatus.SUCCESS);
        }
    };

    const handleOnCancel = async () => {
        try {
            setConfirmationRequestStatus(RequestStatus.LOADING);

            const checkListId = props.confirmationType === 'drop-off' ? reservation!.dropOffCheckList!.externalId! : reservation!.pickUpCheckList!.externalId!
            await props.onCancel(reservation!.externalId!, checkListId);
            setShowCancelModal(true);
            setConfirmationRequestStatus(RequestStatus.SUCCESS);

        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || 'Error in reservation cancelation';

            logger.error('Error when executing the operation', errorMessage);
            AlertService.showSnackCustomError(errorMessage);

            setConfirmationRequestStatus(RequestStatus.SUCCESS);
        }
    }

    const handleCopyToClipboard = () => {
        const url = window.location.href;
        navigator.clipboard.writeText(url);
        AlertService.showSuccessMessage('Link copied to clipboard!');
    }

    if (requestStatus == RequestStatus.LOADING || requestStatus == RequestStatus.ERROR) {
        return (
            <>
                <AppHeader content="" />
                <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                    <RequestStatusFeedBack status={requestStatus} onRetry={getReservationDetail} />
                </div>
            </>
        );
    }


    const { bike } = reservation!;

    return (
        <AppLayout
            header={() => (
                <AppHeader content={`Bike ${descriptionConfig[props.confirmationType].text}`} />
            )}
            content={() => (
                <>
                    {/** Bike image with title and size */}
                    <Container
                        className={classes.container}
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <ReservationDetailsHeader
                            imageSrc={bike.images && bike.images.length > 0 ? bike.images[0].url : ''}
                            title={bike.title}
                        />

                        <BikeAttribute label={'Size:'} value={bike?.size!} />

                        <AppTypography
                            type={PDLTypography.paragraph}
                            customStyles={{ marginBottom: '0.4em' }}
                        >
                            You can forward this form to another person to confirm the {props.confirmationType} for you.&nbsp;
                            <Link
                                style={{ cursor: 'pointer' }}
                                onClick={handleCopyToClipboard}
                                children={"Copy link to clipboard."}
                            />
                        </AppTypography>
                    </Container>

                    {/** Checklist */}
                    <Container
                        className={classes.container}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: AppTheme.colors.white_EEEEEC,
                        }}
                    >
                        <AppTypography
                            type={PDLTypography.largeHeading}
                            children={`${descriptionConfig[props.confirmationType].title} checklist`}
                            customStyles={{ marginBottom: '0.4em' }}
                        />

                        <CheckboxWithInfo
                            value={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.makeAndModel || false) : (reservation?.pickUpCheckList?.makeAndModel || false)}
                            checked={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.makeAndModel || false) : (reservation?.pickUpCheckList?.makeAndModel || false)}
                            disabled
                            label={'Make and model'}
                            info={descriptionConfig[props.confirmationType].makeAndModelDescription}
                        />

                        <CheckboxWithInfo
                            value={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.bikeCondition || false) : (reservation?.pickUpCheckList?.bikeCondition || false)}
                            checked={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.bikeCondition || false) : (reservation?.pickUpCheckList?.bikeCondition || false)}
                            disabled
                            label={'Condition'}
                            info={descriptionConfig[props.confirmationType].conditionDescription}
                        />

                        <CheckboxWithInfo
                            value={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.damage || false) : (reservation?.pickUpCheckList?.damage || false)}
                            checked={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.damage || false) : (reservation?.pickUpCheckList?.damage || false)}
                            disabled
                            label={'Damage'}
                            info={descriptionConfig[props.confirmationType].damageDescription}
                        />

                        <CheckboxWithInfo
                            value={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.parts || false) : (reservation?.pickUpCheckList?.parts || false)}
                            checked={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.parts || false) : (reservation?.pickUpCheckList?.parts || false)}
                            disabled
                            label={'Parts'}
                            info={descriptionConfig[props.confirmationType].partsDescription}
                        />
                    </Container>

                    {/** User comments */}
                    <Container
                        className={classes.container}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <AppTypography
                            type={PDLTypography.largeHeading}
                            children={'Additional Comments'}
                            customStyles={{ marginBottom: '0.4em' }}
                        />

                        <AppTextField
                            name="userComments"
                            disabled
                            //This is really bad, should be refactored, it is also used in the checklist
                            value={props.confirmationType !== "pick-up" ? (reservation?.dropOffCheckList?.userComments || "") : (reservation?.pickUpCheckList?.userComments || "")}
                            onInputChange={(inputName, inputValue, isValid) => {
                                setDetailsItems({ ...detailsItems, userComments: inputValue });
                            }}
                            inputProps={{ multiline: true, rows: 3 }}
                        />
                    </Container>

                    {/* Rental bike images */}
                    <Container
                        className={classes.container}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '2em',
                        }}
                    >
                        <AppTypography type={PDLTypography.largeHeading} children={'Rental bike images'} />
                        {/* REFACTOR REPEATED CODE */}
                        {reservation?.pickUpImages.map((image, index) => (
                            <UploadImage
                                readOnly
                                key={`${image.externalId}-${index}`}
                                imageUrls={image.url ? [image.url] : undefined}
                                image={image}
                                defaultImages
                                onRemove={(imageIdx) => {
                                    const newImages = imagesBike.filter((img, idx) => imageIdx !== idx);
                                    setImagesBike([...newImages]);
                                }}
                                imageIndex={index}
                                maxImage={3}
                            />
                        ))}
                        {reservation?.dropOffImages.map((image, index) => (
                            <UploadImage
                                readOnly
                                key={`${image.externalId}-${index}`}
                                imageUrls={image.url ? [image.url] : undefined}
                                image={image}
                                defaultImages
                                onRemove={(imageIdx) => {
                                    const newImages = imagesBike.filter((img, idx) => imageIdx !== idx);
                                    setImagesBike([...newImages]);
                                }}
                                imageIndex={index}
                                maxImage={3}
                            />
                        ))}
                    </Container>
                    <Container
                        className={classes.container}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '5em',
                        }}
                    >
                    </Container>
                    <Box sx={styles.confirmDropContainer}>
                        <Box sx={{ m: 'auto', width: '70%' }}>
                            <SimpleButton
                                onClick={handleOnConfirm}
                                disabled={confirmationRequestStatus == RequestStatus.LOADING}
                                isLoading={confirmationRequestStatus == RequestStatus.LOADING}
                                style={{ marginBottom: '1em'}}
                            >
                                Confirm {descriptionConfig[props.confirmationType].title}
                            </SimpleButton>
                            <SimpleButton
                                style={{ background: 'red', color: '#000', marginBottom: '1em' }}
                                onClick={handleOnCancel}
                                disabled={confirmationRequestStatus == RequestStatus.LOADING}
                                isLoading={confirmationRequestStatus == RequestStatus.LOADING}
                            >
                                Cancel {descriptionConfig[props.confirmationType].title}
                            </SimpleButton>
                        </Box>
                    </Box>

                    <NotificationModal
                        text={descriptionConfig[props.confirmationType].notificationMessage}
                        open={showSuccessModal}
                    />

                    <NotificationModal
                        text={descriptionConfig[props.confirmationType].cancelMessage}
                        open={showCancelModal}
                    />

                    {props.confirmationType === 'drop-off' && (
                        <ConfirmReservationDropOffBottomSheet
                            open={showConfirmModal}
                            onClose={() => setShowConfirmModal(false)}
                            onConfirm={handleOnConfirm}
                        />
                    )}
                </>
            )}
        />
    );
}

const styles: SxStyles = {
    centerContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        py: '1.8em',
    },
    confirmDropContainer: {
        position: 'fixed',
        backgroundColor: AppTheme.colors.white_FFFFFF,
        bottom: 59,
        width: '100%',
        py: 1.2,
        borderTop: '1px solid #E3E5E4',
    },
};

const useStyles = makeStyles((theme: IAppTheme) => ({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
    },
}));
