import { Cameraswitch, Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { Camera, CameraType } from 'react-camera-pro';
import { IAppTheme } from '../../utils/AppTheme';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import { ImagePreviewPro } from './ImagePreviewPro';

interface Props {
  show: boolean;
  onImageUploaded: (base64Image: string) => void;
  onClose: () => void;
}

const AppCameraPro = ({ show, onImageUploaded, onClose }: Props) => {
  const classes = useStyles();
  const [dataURI, setDataURI] = useState('');
  const [error, setError] = useState(false);
  const camera = useRef<CameraType>(null);
  const [image, setImage] = useState<string | null>(null);

  const isLandScapeOrientation = () => {
    return document.body.clientWidth > document.body.clientHeight;
  }

  const [orientationLandscape, setOrientationLandscape] = useState<boolean>(isLandScapeOrientation());
  const [photoTakenOrientationIsLandscape, setPhotoTakenOrientationIsLandscape] = useState<boolean | undefined>(undefined);

  const updateSCreenOrientation = () => {
    setOrientationLandscape(isLandScapeOrientation());
  }

  useEffect(() => (
    window.addEventListener('resize', updateSCreenOrientation),
    () => window.removeEventListener('resize', updateSCreenOrientation)
  ), [])


  const handleTakePhoto = (dataUri: any) => {
    setDataURI(dataUri);
  }

  const addContent = (accepted: boolean) => {
    if (accepted) {
      onImageUploaded(dataURI);
      setDataURI('');
      onClose();
    } else {
      setDataURI('');
    }
  }

  if (!show) {
    return null;
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', margin: '2em 0em' }}>
        <AppTypography type={PDLTypography.smallHeading}>An error occurred</AppTypography>
      </div>
    );
  }

  switch (dataURI) {
    case '':
      return (
        <>
          <div className={classes.wrapper}>
            <Camera ref={camera}
              facingMode={'environment'}
              errorMessages={{
                noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
                permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                switchCamera:
                  'It is not possible to switch camera to different one because there is only one video device accessible.',
                canvas: 'Canvas is not supported.',
              }}
            />
            <div className={classes.control}>
              <div
                style={{}}
                onClick={() => onClose()}
              >
                <Close style={{ fill: 'white' }} fontSize="large" />
              </div>
              <button className={classes.takePhotoButton}
                onClick={() => {
                  setPhotoTakenOrientationIsLandscape(isLandScapeOrientation());
                  if (camera.current) {
                    const photo = camera.current.takePhoto();
                    handleTakePhoto(photo);
                  }
                }}>
              </button>
              <div
                style={{}}
                onClick={() => {
                  if (camera.current) {
                    camera.current.switchCamera();
                  }
                }}
              >
                <Cameraswitch style={{ fill: 'white' }} fontSize="large" />
              </div>
            </div>
          </div>
        </>
      );
    default:
      return (
        <ImagePreviewPro
          rotateMessage={orientationLandscape !== photoTakenOrientationIsLandscape}
          fullScreen={false}
          src={dataURI}
          acceptOrRejectImage={(accepted: boolean) => addContent(accepted)}
        />
      );
  }
};

export default AppCameraPro;

const useStyles = makeStyles((theme: IAppTheme) => ({
  control: {
    position: 'fixed',
    display: 'flex',
    right: '0',
    width: '20%',
    minWidth: '130px',
    minHeight: '130px',
    height: '100%',
    background: 'black',
    zIndex: '10',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '50px',
    boxSizing: 'border-box',
    flexDirection: 'column-reverse',
    '@media (max-aspect-ratio: 1/1)': {
      flexDirection: 'row',
      bottom: '0',
      width: '100%',
      height: '20%',
    },
    '@media (max-width: 400px)': {
      padding: '10px'
    }
  },
  wrapper: {
    background: 'lightGrey',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '80%',
    height: '100%',
    zIndex: 1,
    '@media (max-aspect-ratio: 1/1)': {
      flexDirection: 'row',
      bottom: '0',
      width: '100%',
      height: '80%',
    },
  },
  takePhotoButton: {
    //background: `url('https://img.icons8.com/ios/50/000000/compact-camera.png')`,
    justifySelf: 'center',
    background: "gray",
    backgroundPosition: 'center',
    backgroundSize: '50px',
    backgroundRepeat: 'no-repeat',
    width: '80px',
    height: '80px',
    border: 'solid 4px black',
    borderRadius: '50%',
    zIndex: 2,
  }
}));
