import { Container, Divider, Grid, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { RenterReview } from '../../../model/RenterReview';
import AppHeader from '../../commons/AppHeader';
import { AppLayout } from '../../core/AppLayout';
import { AppTypography } from '../../Typography/AppTypography';
import { PDLTypography } from '../../Typography/PDLTypography';
import Logger from '../../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../../utils/RequestStatus';
import RequestStatusFeedBack from '../../commons/RequestStatusFeedback';
import { makeStyles } from '@mui/styles';
import { IAppTheme } from '../../../utils/AppTheme';
import ReviewService from '../../../services/ReviewService';
import { AppSimpleList } from '../../commons/AppSimpleList';
import { Debouncer } from '../../../utils/Debouncer';
import AppImage from '../../../external/pdl-common/components/commons/AppImage';
import DateUtils from '../../../external/pdl-common/utils/DateUtils';
import StarsAndRides from '../../commons/StarsAndRides';
import { FullUser } from '../../../external/pdl-common/model/FullUser';
import UserService from '../../../services/UserService';
import BikeRatingComment from '../../../external/pdl-common/components/commons/Ratings/BikeRatingComment';

const logger = new Logger('HostRatings');
const PAGE_SIZE = 5;

const HostRatings = () => {
  const classes = useStyles();

  const [user, setUser] = useState<FullUser | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.LOADING);
  const [reviews, setReviews] = useState<RenterReview[]>([]);
  const [pageData, setPageData] = useState<{ number: number; totalPages: number }>({
    number: 0,
    totalPages: 0,
  });

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    setRequestStatus(RequestStatus.LOADING);
    await getUser();
    await getReviews();
  };

  const getUser = async () => {
    (await UserService.getCurrentUser())
      .onSuccess((response) => {
        setUser({ ...response.getContent(), password: 'dfoioprkewpokfpoiewoe' });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error fetching user', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };
  const getReviews = async () => {
    (await ReviewService.getReviewsAsHost(0, PAGE_SIZE))
      .onSuccess((response) => {
        const content = response.getContent();
        setReviews(content.content);
        setPageData({
          number: content.number,
          totalPages: content.totalPages,
        });
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .onError((response) => {
        logger.error('Error fetching host reviews', response.getContent());
        setRequestStatus(RequestStatus.ERROR);
      });
  };

  const loadMoreReviews = async () => {
    Debouncer.debounce(
      'loadMoreReviews',
      async () => {
        if (pageData.number + 1 >= pageData.totalPages) {
          return;
        }

        (await ReviewService.getReviewsAsHost(pageData.number + 1, PAGE_SIZE))
          .onSuccess((response) => {
            setReviews([...reviews, ...response.getContent().content]);
            setPageData({ ...pageData, number: pageData.number + 1 });
          })
          .onError((response) => {
            logger.error('Error loading more reviews', response.getContent());
            setRequestStatus(RequestStatus.ERROR);
          });
      },
      100
    );
  };

  const renderItem = (review: RenterReview, index: number) => {
    return (
      <Container key={review.externalId}>
        {getCommentHeader(review)}
        <BikeRatingComment rating={review} showDate={false} />
      </Container>
    );
  };

  const getCommentHeader = (review: RenterReview) => {
    return (
      <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Bike image */}
        <Grid item xs={6}>
          <AppImage
            src={
              review.bike.images && review.bike.images.length > 0 ? review.bike.images[0].url : ''
            }
            alt="bike"
            className={classes.bikeImage}
          />
        </Grid>

        <Grid item xs={6} sx={muiStyles.reservationInfoGrid}>
          {/* Bike title */}
          <AppTypography type={PDLTypography.smallHeading}>{review.bike.title}</AppTypography>

          {/* Reservation date */}
          <AppTypography type={PDLTypography.paragraph} customStyles={{ marginTop: '0.625em' }}>
            {DateUtils.formattedRangeDates(
              new Date(review.reservation.startDate),
              new Date(review.reservation.endDate)
            )}
          </AppTypography>
        </Grid>
      </Grid>
    );
  };

  const getContent = (): JSX.Element => {
    if (requestStatus !== RequestStatus.SUCCESS) {
      return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
          <RequestStatusFeedBack status={requestStatus} onRetry={load} />
        </div>
      );
    }

    console.log('user: ' + JSON.stringify(user));

    return (
      <div style={{ flex: 1, display: 'flex', padding: '1.875em 1em' }}>
        <div
          style={{
            flex: 1,
            position: 'relative',
            minHeight: '100vh'
          }}
        >
          <StarsAndRides user={user!} hostInfo />
          <Divider sx={{ mb: 2.5 }} />

          {reviews.length > 0 ? (
            <AppSimpleList
              className={classes.list}
              data={reviews}
              loadMore={loadMoreReviews}
              renderItem={renderItem}
            />
          ) : (
            <p style={{ textAlign: 'center' }}>You don't have reviews yet.</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <AppLayout
      header={() => <AppHeader content="My ratings and reviews" />}
      content={() => getContent()}
    />
  );
};

export default HostRatings;

const muiStyles = {
  reservationInfoGrid: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1.875em',
  } as SxProps,
};

const useStyles = makeStyles((theme: IAppTheme) => ({
  bikeImage: {
    width: '100%',
    borderRadius: '0.25em',
    marginBottom: '0.75em',
  },
  list: {
    position: 'absolute',
    width: '100%',
    overflow: 'scroll',
    height: '100%',
  },
}));
