import { useNavigate } from 'react-router-dom';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';
import BottomSheet from './BottomSheet';

export function PasswordHelper(props: { open: boolean; onClose: () => void }) {
  const navigate = useNavigate();

  return (
    <BottomSheet open={props.open} onClose={props.onClose} bottomSpace={'5em'}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <AppTypography type={PDLTypography.largeHeading} customStyles={{ marginBottom: '1em' }}>
          Valid password format
        </AppTypography>
        <AppTypography type={PDLTypography.paragraph} customStyles={{ marginBottom: '1em' }}>
          Password should be between 8 and 40 characters. Contain at least: one digit, one lower
          case, one upper case.
        </AppTypography>
      </div>
    </BottomSheet>
  );
}
