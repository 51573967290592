import { Box, ButtonBase, Divider } from '@mui/material';
import BottomSheet from '../commons/BottomSheet';
import { FilterItem } from './FilterItem';
import { SxProps } from '@mui/system';
import { PDLTypography } from '../Typography/PDLTypography';
import { AppTypography } from '../Typography/AppTypography';
import { BikeFilters } from '../../model/utils/BikeFilters';

interface Props {
  show: boolean;
  selectedFilters: BikeFilters;
  filtersToShow?: BikeFilters;
  setShow: (newValue: boolean) => void;
  onFilterChange: (filters: BikeFilters) => void;
  onClearFilters: () => void;
}

export function FilterComponent(props: Props) {
  const selectedTypes = props.selectedFilters.bikeTypes;
  const selectedSizes = props.selectedFilters.bikeSizes;
  const selectedHideUnavailable = props.selectedFilters.hideUnavailable;

  return (
    <BottomSheet open={props.show} onClose={() => props.setShow(false)}>
      <Box sx={styles.titleContainer}>
        <AppTypography type={PDLTypography.largeHeading} children={'Filters'} />
        <ButtonBase onClick={props.onClearFilters}>
          <AppTypography
            type={PDLTypography.none}
            children={'Clear all'}
            customStyles={{ textDecoration: 'underline', fontSize: '0.9em' }}
          />
        </ButtonBase>
      </Box>
      <Divider sx={{ mb: '.7em' }} />

      {props.filtersToShow &&
          (
            <FilterItem
              key={"hideAvailable"}
              label={"Hide unavailable"}
              value={selectedHideUnavailable}
              checked={selectedHideUnavailable}
              onChange={(checked) => {
                if (checked) {
                  props.onFilterChange({
                    ...props.selectedFilters,
                    hideUnavailable: true,
                  });
                } else {
                  props.onFilterChange({
                    ...props.selectedFilters,
                    hideUnavailable: false,
                  });
                }
              }}
            />
            )}

      <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />

      <AppTypography type={PDLTypography.mediumHeading} children={'Bike type'} />

      {props.filtersToShow &&
        props.filtersToShow.bikeTypes.map((option, index) => {
          return (
            <FilterItem
              key={option.label}
              label={option.label}
              value={selectedTypes.map((t) => t.label).includes(option.label)}
              checked={selectedTypes.map((t) => t.label).includes(option.label)}
              onChange={(checked) => {
                if (checked) {
                  props.onFilterChange({
                    ...props.selectedFilters,
                    bikeTypes: [...selectedTypes, option],
                  });
                } else {
                  props.onFilterChange({
                    ...props.selectedFilters,
                    bikeTypes: selectedTypes.filter((o) => o.label !== option.label),
                  });
                }
              }}
            />
          );
        })}

      <Divider sx={{ marginTop: '30px', marginBottom: '24px' }} />

      <AppTypography type={PDLTypography.mediumHeading} children={'Bike size'} />

      {props.filtersToShow &&
        props.filtersToShow.bikeSizes.map((option, index) => {
          return (
            <FilterItem
              key={option.label}
              label={option.label}
              value={selectedSizes.map((s) => s.label).includes(option.label) || false}
              checked={selectedSizes.map((s) => s.label).includes(option.label) || false}
              onChange={(checked) => {
                if (checked) {
                  props.onFilterChange({
                    ...props.selectedFilters,
                    bikeSizes: [...selectedSizes, option],
                  });
                } else {
                  props.onFilterChange({
                    ...props.selectedFilters,
                    bikeSizes: selectedSizes.filter((o) => o.label !== option.label),
                  });
                }
              }}
            />
          );
        })}
    </BottomSheet>
  );
}

const styles = {
  titleContainer: {
    marginBottom: '.7em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  } as SxProps,
};
