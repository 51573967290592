import { AppBar, Box, Button, Container, Link, Toolbar, useMediaQuery } from '@mui/material';
import { COLORS } from '../../utils/AppTheme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppTypography } from '../../external/pdl-common/components/Typography/AppTypography';
import { PDLTypography } from '../../external/pdl-common/components/Typography/PDLTypography';
import { AppAssets } from '../../assets';
import { appNavigate, AppRoutes } from '../../../src/utils/AppNavigation';
import { useNavigate } from 'react-router-dom';

interface Props {
  transparent?: boolean;
  showBackButton?: boolean;
  onBack?: () => void;
}

export default function TopNavBar({ transparent, showBackButton, onBack }: Props) {
  const navigate = useNavigate();
  const mediumBreakpoint = useMediaQuery('(min-width: 1157px)');

  return (
    <>
      <AppBar position="relative" color={transparent ? 'transparent' : 'default'} style={{zIndex: 99}} elevation={0}>
        {showBackButton &&
          <Box
            sx={
                {
                  top: mediumBreakpoint ? '' : '1rem',
                  position: 'absolute',
                  width: '100%',
                  pt: 1,
                }
            }
          >
            <Button
              sx={{ color: 'white', zIndex:99}}
              onClick={() => {
                if (onBack) {
                  onBack();
                } else {
                  navigate(-1);
                }
              }}
            >
              <ArrowBackIcon style={{color: 'black'}}/>
            </Button>
          </Box>
        }
        <Container maxWidth={false} sx={{ height: mediumBreakpoint ? '6.75em' : '4.50em' }}>
          <Toolbar
            disableGutters
            sx={{ display: 'flex', height: '100%', justifyContent: 'center' }}
          >
            {/*
              <Link underline="none" component="button">
                <AppTypography
                  type={PDLTypography.callToAction}
                  customStyles={{
                    color: transparent ? COLORS.white_FFFFFF : COLORS.black_000000,
                    fontSize: mediumBreakpoint ? '1.125em' : '1em',
                  }}
                  children={'About'}
                  onClick={() => appNavigate(navigate, AppRoutes.ABOUT, null)}
                />
              </Link>

              <Link underline="none" component="button">
                <AppTypography
                  type={PDLTypography.callToAction}
                  customStyles={{
                    color: transparent ? COLORS.white_FFFFFF : COLORS.black_000000,
                    fontSize: mediumBreakpoint ? '1.125em' : '1em',
                  }}
                  children={'Blog'}
                />
              </Link>
              */}
            <Box sx={{ textAlign: 'center', paddingTop: '15px' }}>
              <Link onClick={() => appNavigate(navigate, AppRoutes.HOME, null)}>
                {mediumBreakpoint ? (
                  <AppAssets.PDL_CLUB
                    fill={transparent ? COLORS.white_FFFFFF : COLORS.black_000000}
                    cursor="pointer"
                    width={'12.75em'}
                  />
                ) : (
                  <AppAssets.PDL_CLUB
                    fill={transparent ? COLORS.white_FFFFFF : COLORS.black_000000}
                    cursor="pointer"
                  />
                )}
              </Link>
            </Box>

          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
