import environment from '../environment.json';
import { FullUser } from '../external/pdl-common/model/FullUser';
import Reservation from '../external/pdl-common/model/Reservation';

export enum ClaimInitiator {
  HOST = 'host',
  RENTER = 'renter',
}

export default class TintUtils {
  public static getClaimFormUrl = (
    reservation: Reservation,
    pdlUser: FullUser,
    initiator: ClaimInitiator
  ): string => {
    const { user, phoneNumber } = pdlUser;

    return this.generateClaimFormUrl(
      reservation.externalId!,
      initiator,
      user.firstName,
      user.lastName,
      user.email,
      phoneNumber
    );
  };

  private static generateClaimFormUrl = (
    reservationId: string,
    initiator: ClaimInitiator,
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string
  ): string => {
    let params = `?reservationId=${reservationId}`;

    params = this.concatenateParam(
      params,
      'firstName',
      firstName ? encodeURIComponent(firstName) : ''
    );
    params = this.concatenateParam(
      params,
      'lastName',
      lastName ? encodeURIComponent(lastName) : ''
    );
    params = this.concatenateParam(params, 'email', email ? encodeURIComponent(email) : '');
    params = this.concatenateParam(params, 'phone', phone ? encodeURIComponent(phone) : '');

    if (environment.env === 'prod') {
      return `https://pdl-club-${initiator}-case.paperform.co/${params}`;
    }

    return `https://sandbox-pdl-club-${initiator}-case.paperform.co/${params}`;
  };

  private static concatenateParam = (text: string, param: string, value?: string): string => {
    if (value) {
      return text + `&${param}=${value}`;
    }

    return text;
  };
}
