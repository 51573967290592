import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import FormOptions from '../model/FormOptions';
import environment from '../environment.json';

class FormOptionsService {
  private basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async getFormOptions(): Promise<ApiResponse<FormOptions>> {
    return await HttpClient.get<FormOptions>(`${this.basePath}`, true);
  }

  async getBikePartCategories(partCategory: string): Promise<ApiResponse<string[]>> {
    return await HttpClient.get<string[]>(`${this.basePath}/bike-part/${partCategory}`, true);
  }
}

export default new FormOptionsService(`${environment.baseURL}/form-options`);
