import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setTimeout } from 'timers';
import { AppAssets } from '../../assets';
import { SxStyles } from '../../model/utils/SxStyles';
import AlertService from '../../services/AlertService';
import PdlAuthService from '../../services/PdlAuthService';
import { appNavigate, AppRoutes } from '../../utils/AppNavigation';
import AppTheme, { COLORS } from '../../utils/AppTheme';
import Logger from '../../external/pdl-common/utils/Logger';
import { RequestStatus } from '../../utils/RequestStatus';
import { AppTypography } from '../Typography/AppTypography';
import { PDLTypography } from '../Typography/PDLTypography';

const logger = new Logger('BlackValidateAccount');

const BlackValidateAccount = () => {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const code: string = searchParams.get('code') || '';
  const email: string = searchParams.get('email') || '';

  const [submitStatus, setSubmitStatus] = useState<RequestStatus | undefined>(undefined);

  useEffect(() => {
    if (code && email) {
      onSubmit();
    }
  }, [code, email]);

  const onSubmit = async () => {
    try {
      setSubmitStatus(RequestStatus.LOADING);

      await PdlAuthService.validateAccount(code, encodeURIComponent(email));

      setSubmitStatus(RequestStatus.SUCCESS);

      setTimeout(() => {
        appNavigate(navigate, AppRoutes.SIGN_IN, null);
      }, 4000);
    } catch (error: any) {
      logger.error('Error validating account', error);

      if (error?.response?.data?.message) {
        AlertService.showSnackCustomError(error?.response?.data?.message);
      } else {
        AlertService.showSnackInternalError();
      }

      setSubmitStatus(RequestStatus.ERROR);
    }
  };

  const getMessage = (): JSX.Element => {
    switch (submitStatus) {
      case RequestStatus.SUCCESS:
        return (
          <>
            Thank you for validating your account! <br />
            You will be redirected to Sign In...
          </>
        );
      case RequestStatus.ERROR:
        return (
          <>
            It looks like there was an error validating your account. <br />
            <br />
            {'You can go back to '}
            <span
              className={classes.link}
              onClick={() => appNavigate(navigate, AppRoutes.SPLASH_SCREEN, null)}
            >
              home page.
            </span>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <Grid container spacing={8} columns={1} sx={muiStyle.gridHeader}>
      <Grid item xs={12} sm={6} sx={muiStyle.gridHeader}>
        <AppAssets.PDL_CLUB fill="white" />
        <AppTypography type={PDLTypography.largeHeading} customClasses={classes.title}>
          Validate your account
        </AppTypography>
      </Grid>

      <Grid item xs={12} sm={12} sx={{ mx: 4 }}>
        <Grid sx={muiStyle.gridBody}>
          <AppTypography type={PDLTypography.mediumHeading} customClasses={classes.subTitle}>
            {getMessage()}
          </AppTypography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlackValidateAccount;

const useStyles = makeStyles({
  title: {
    marginTop: '1.7em',
    color: COLORS.white_FFFFFF,
  },
  subTitle: {
    textAlign: 'center',
    color: COLORS.white_FFFFFF,
  },
  link: {
    color: AppTheme.colors.persianBlue_2238CB,
    cursor: 'pointer',
  },
});

const muiStyle: SxStyles = {
  gridHeader: {
    marginTop: '0em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: COLORS.black_000000,
  },
  gridBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '40em',
  },
};
