import ApiResponse from '../external/fox-typescript/core/ApiResponse';
import HttpClient from '../external/fox-typescript/utils/HttpClient';
import { InsurancePolicyCreation } from '../model/insurance/InsurancePolicyCreation';
import { TintDecisionResponse } from '../model/insurance/Tint/TintDecisionResponse';
import { TintPolicyCreationResponse } from '../model/insurance/Tint/TintPolicyCreationResponse';
import { InsuranceProduct } from '../model/InsurancePolicy';

class InsuranceService {
  private basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  async getHostInsuranceProducts(): Promise<ApiResponse<InsuranceProduct[]>> {
    return await HttpClient.get(`${this.basePath}/host/products`);
  }

  async getRenterInsuranceProducts(): Promise<ApiResponse<InsuranceProduct[]>> {
    return await HttpClient.get(`${this.basePath}/renter/products`);
  }

  async createDraftInsurancePolicy(
    dto: InsurancePolicyCreation
  ): Promise<ApiResponse<TintPolicyCreationResponse>> {
    return await HttpClient.post(`${this.basePath}/policy`, dto, true);
  }

  async getInsurancePolicyDecision(policyId: string): Promise<ApiResponse<TintDecisionResponse>> {
    return await HttpClient.get(`${this.basePath}/policy/${policyId}/decision`);
  }
}

export default new InsuranceService(`/insurance`);
